import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vendor-central-accounts',
  templateUrl: './vendor-central-accounts.component.html',
  styleUrls: ['./vendor-central-accounts.component.css'],
})
export class VendorCentralAccountsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
