import { JsonObject, JsonProperty } from 'json2typescript';
import { Product } from './product-model';
import { Vendor } from './vendor-model';
import { BaseObject } from './base-object-model';
import { Status } from './status-model';
import { Supplier } from './supplier-model';
import { PublicationApproved } from './publication-approved-model';

@JsonObject('Publication')
export class Publication extends BaseObject {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('product', Product, true) product: Product = undefined;
  @JsonProperty('vendor', Vendor, false) vendor: Vendor = undefined;
  @JsonProperty('amazonPrice', Number, true) amazonPrice: number = undefined;
  @JsonProperty('newPrice', Number, true) newPrice: number = undefined;
  @JsonProperty('publishedPrice', Number, true) publishedPrice: number = undefined;
  @JsonProperty('originalPurchasePrice', Number, true) originalPurchasePrice: number = undefined;
  @JsonProperty('status', Number, true) statusCode: number = undefined;
  @JsonProperty('newPriceStatus', Status, true) newPriceStatus: Status = undefined;
  @JsonProperty('pendingApprovalSince', String, true) pendingApprovalSince: string = undefined;
  @JsonProperty('suspendedAt', String, true) suspendedAt: string = undefined;
  @JsonProperty('lastPriceUpdate', String, true) lastPriceUpdate: string = undefined;
  @JsonProperty('lastOrderDate', String, true) lastOrderDate: string = undefined;
  @JsonProperty('newQuantity', Number, true) newQuantity: number = undefined;
  @JsonProperty('publishedQuantity', Number, true) publishedQuantity: number = undefined;
  @JsonProperty('supplier', Supplier, true) supplier: Supplier = undefined;
  @JsonProperty('publicationApprovedHistory', [PublicationApproved], true) publicationApprovedHistory: PublicationApproved[] = undefined;
  @JsonProperty('percentageDifference', Number, true) percentageDifference: number = undefined;
  @JsonProperty('email', String, true) email: string = undefined;
}
