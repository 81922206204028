import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConverterService } from '../../../core/converter.service';
import { Vendor, VendorsFromLotOutput } from '../../../models/vendor-model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../../../environment.service';
import { Status } from '../../../models/status-model';
import { isNilty } from '../../../core/utils.service';

@Injectable()
export class VendorsService {
  parentVendors: Vendor[];
  vendors: Vendor[];
  checkTypes: Status[];
  paymentTerms: Status[];
  showOnlyParents = true;

  lotEditVendors: Vendor[] = undefined;
  lotEditParentVendors: Vendor[] = undefined;

  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  getVendors(): Observable<Vendor[]> {
    return this.http.get(this.environmentService.getRestEndpoint('vendors')).pipe(
      map((vendorsResp: Vendor[]) => {
        this.vendors = [];
        for (let i = 0; i < vendorsResp.length; i++) {
          this.vendors[i] = this.converter.fromJSONtoObj(vendorsResp[i], Vendor);
        }

        this.parentVendors = this.vendors.filter((vendor: Vendor) => vendor.parent);

        return this.vendors;
      })
    );
  }

  getVendor(vendorId: number): Observable<Vendor> {
    let path = this.environmentService.getRestEndpoint('vendors');

    if (!this.environmentService.dev) {
      path += '/' + vendorId;
    }

    return this.http.get(path).pipe(
      map((vendorResp: Vendor) => {
        const vendor = this.converter.fromJSONtoObj(vendorResp, Vendor);

        if (this.environmentService.dev) {
          return this.vendors[vendorId - 1];
        }

        return vendor;
      })
    );
  }

  getChildrenVendors(parentId: number): Observable<Vendor[]> {
    let path = this.environmentService.getRestEndpoint('vendorsChildren');

    if (!this.environmentService.dev) {
      path += '/' + parentId;
    }

    return this.http.get(path).pipe(
      map((childrenResp: Vendor[]) => {
        const children: Vendor[] = [];
        for (let i = 0; i < childrenResp.length; i++) {
          children[i] = this.converter.fromJSONtoObj(childrenResp[i], Vendor);
        }
        return children;
      })
    );
  }

  postVendor(vendor: Vendor): Observable<boolean> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = this.converter.fromObjtoJSON(vendor);
    return this.http.post(this.environmentService.getRestEndpoint('vendors'), body, { headers }).pipe(map((resp: any) => resp));
  }

  deleteVendor(vendorId: number): Observable<boolean> {
    let path = this.environmentService.getRestEndpoint('vendors');

    if (!this.environmentService.dev) {
      path += '/' + vendorId;
    }

    return this.http.delete(path).pipe(map(() => true));
  }

  getVendorsFromLot(lotId: number): Observable<VendorsFromLotOutput> {
    let path = this.environmentService.getRestEndpoint('vendors');

    if (!this.environmentService.dev) {
      path += '/lot=' + lotId;
    }

    return this.http.get(path).pipe(
      map((vendorsResp: VendorsFromLotOutput) => {
        const response = this.converter.fromJSONtoObj(vendorsResp, VendorsFromLotOutput);
        this.lotEditVendors = response.vendors;
        this.lotEditParentVendors = response.parents;

        return response;
      })
    );
  }

  getPublicationCheckTypes(): Observable<Status[]> {
    if (!isNilty(this.checkTypes)) {
      return of(this.checkTypes);
    }
    return this.http.get(this.environmentService.getRestEndpoint('vendors') + '/publication-check-types').pipe(
      map((resp: Status[]) => {
        this.checkTypes = [];
        for (let i = 0; i < resp.length; i++) {
          this.checkTypes[i] = this.converter.fromJSONtoObj(resp[i], Status);
        }
        return this.checkTypes;
      })
    );
  }

  getVendorPaymentTerms(): Observable<Status[]> {
    if (!isNilty(this.paymentTerms)) {
      return of(this.paymentTerms);
    }
    return this.http.get(this.environmentService.getRestEndpoint('vendors') + '/payment-terms').pipe(
      map((resp: Status[]) => {
        this.paymentTerms = [];
        for (let i = 0; i < resp.length; i++) {
          this.paymentTerms[i] = this.converter.fromJSONtoObj(resp[i], Status);
        }
        return this.paymentTerms;
      })
    );
  }
}
