import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ConverterService } from '../../../core/converter.service';
import { Observable, Subject } from 'rxjs';
import { RemittanceFilters } from '../../../models/filters/remittance-filters-model';
import { Remittance } from '../../../models/remittance-model';
import { EnvironmentService } from '../../../environment.service';
import { map } from 'rxjs/operators';
import { RemittanceDetail } from '../../../models/remittance-detail-model';

@Injectable()
export class RemittanceService {
  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  resultsNumber = new Subject<number>();

  remittanceFilters: RemittanceFilters;

  getRemittanceDetailsCodes(): Observable<string[]> {
    return this.http.get(this.environmentService.getRestEndpoint('remittanceDetailsCodes')).pipe(map((resp: string[]) => resp));
  }

  getFilteredRemittances(filters: RemittanceFilters): Observable<Remittance[]> {
    return this.http
      .post(this.environmentService.getRestEndpoint('remittances'), this.createFilterBody(filters), { observe: 'response' })
      .pipe(
        map((resp: HttpResponse<any>) => {
          this.resultsNumber.next(+resp.headers.get('Total-Length'));
          return resp.body;
        }),
        map((resp: Remittance[]) => resp.map((r) => this.converter.fromJSONtoObj(r, Remittance)))
      );
  }

  getFilteredRemittanceDetails(filters: RemittanceFilters): Observable<RemittanceDetail[]> {
    return this.http
      .post(this.environmentService.getRestEndpoint('remittanceDetails'), this.createFilterBody(filters), { observe: 'response' })
      .pipe(
        map((resp: HttpResponse<any>) => {
          this.resultsNumber.next(+resp.headers.get('Total-Length'));
          return resp.body;
        }),
        map((resp: Remittance[]) => resp.map((r) => this.converter.fromJSONtoObj(r, RemittanceDetail)))
      );
  }

  private createFilterBody(filters: RemittanceFilters): string {
    return this.converter.fromObjtoJSON(filters);
  }
}
