import { Component, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { VendorsService } from '../../../../../container/configuration/vendors/vendors.service';
import { isNilty } from '../../../../../core/utils.service';
import { ChargebackFilters, chargebackFiltersMap } from '../../../../../models/filters/chargeback-filters-model';
import { MatChipsData } from '../../../../../models/mat-chips-data-model';
import { Vendor } from '../../../../../models/vendor-model';
import { PenaltiesService } from '../penalties.service';

@Component({
  selector: 'app-cachargeback-list-filters',
  templateUrl: './chargeback-list-filters.component.html',
  styleUrls: ['./chargeback-list-filters.component.css'],
})
export class ChargebackListFiltersComponent implements OnInit {
  constructor(private vendorsService: VendorsService, private penaltiesService: PenaltiesService) {}

  filterForm: FormGroup;
  public openFilter = false;

  filtersMap: MatChipsData[] = [];

  vendors: Vendor[] = [];

  @Output() onFilter = new Subject<ChargebackFilters>();

  ngOnInit() {
    this.filterForm = new FormGroup({
      code: new FormControl(),
      issueType: new FormControl(),
      vendor: new FormControl(),
      poNumber: new FormControl(),
      ean: new FormControl(),
      asin: new FormControl(),
      charged: new FormControl(),
    });

    if (!isNilty(this.penaltiesService.chargebackFilters !== null)) {
      this.filterForm.patchValue(this.penaltiesService.chargebackFilters);
      this.updateFiltersMap();
    }

    this.vendorsService.getVendors().subscribe((vendors: Vendor[]) => (this.vendors = vendors));
  }

  onSubmitFilter() {
    this.saveFiltersToChargebackService();
    this.updateFiltersMap();
    this.onFilter.next(this.penaltiesService.chargebackFilters);
  }

  resetFilters() {
    this.filterForm.reset();
    this.saveFiltersToChargebackService();
    this.updateFiltersMap();
    this.onFilter.next(this.penaltiesService.chargebackFilters);
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeevent() {
    this.openFilter = false;
  }

  saveFiltersToChargebackService() {
    this.penaltiesService.chargebackFilters.code = isNilty(this.filterForm.value.code) ? null : this.filterForm.value.code;
    this.penaltiesService.chargebackFilters.issueType = isNilty(this.filterForm.value.issueType) ? null : this.filterForm.value.issueType;
    this.penaltiesService.chargebackFilters.vendor = this.filterForm.value.vendor;
    this.penaltiesService.chargebackFilters.poNumber = isNilty(this.filterForm.value.poNumber) ? null : this.filterForm.value.poNumber;
    this.penaltiesService.chargebackFilters.ean = isNilty(this.filterForm.value.ean) ? null : this.filterForm.value.ean;
    this.penaltiesService.chargebackFilters.asin = isNilty(this.filterForm.value.asin) ? null : this.filterForm.value.asin;
    this.penaltiesService.chargebackFilters.charged = this.filterForm.value.charged;
    this.penaltiesService.chargebackFilters.pageNumber = null;
    this.penaltiesService.chargebackFilters.pageSize = null;
    this.penaltiesService.chargebackFilters.sortBy = null;
    this.penaltiesService.chargebackFilters.sortDirection = null;
  }

  updateFiltersMap() {
    this.filtersMap = chargebackFiltersMap(
      this.filterForm.value.code,
      this.filterForm.value.issueType,
      this.filterForm.value.vendor,
      this.filterForm.value.poNumber,
      this.filterForm.value.ean,
      this.filterForm.value.asin,
      this.filterForm.value.charged
    );
  }
}
