<mat-toolbar id="subsectionMenu" color="accent">
  <span class="fill-remaining-space text-center">
    <button class="btn-main-payments" routerLink="documents" routerLinkActive="active">
      <i class="fa fa-file padding-bottom-1" aria-hidden="true"></i>
      Documents
    </button>
    <button class="btn-main-payments" routerLink="requests" routerLinkActive="active">
      <i class="fa fa-list-ul padding-bottom-1" aria-hidden="true"></i>
      Payment requests
    </button>
  </span>
</mat-toolbar>
