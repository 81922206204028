import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConverterService } from '@core/converter.service';
import { isNilty } from '@core/utils.service';
import { LotFilters } from '@models/filters/lot-filters-model';
import { PoFilters } from '@models/filters/po-filters-model';
import { GenerateShipmentsRecap } from '@models/generate-shipments-recap-model';
import { Lot, NewLotInput } from '@models/lot-model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../environment.service';
import { GenerateOrderedSummary } from '@models/generate-ordered-summary-model';

@Injectable()
export class LotsService {
  lots: Lot[] = [];

  currentLot = new Lot();

  lotSubject = new Subject<Lot>();

  currentLotId: number;

  lotFilters: LotFilters = null;

  resultsNumber = new Subject<number>();

  constructor(
    private http: HttpClient,
    private converter: ConverterService,
    private snackBar: MatSnackBar,
    private environmentService: EnvironmentService
  ) {}

  resetLotId() {
    this.currentLotId = undefined;
    this.currentLot = undefined;
  }

  searchByName(name: string): Observable<Lot[]> {
    return this.http.get(this.environmentService.getRestEndpoint('searchLotsByName') + name).pipe(
      map((r: Lot[]) => {
        if (isNilty(r)) {
          return [];
        }
        return r.map((it) => this.converter.fromJSONtoObj(it, Lot));
      })
    );
  }

  searchOpenLotsByName(name: string): Observable<Lot[]> {
    return this.http.get(this.environmentService.getRestEndpoint('searchOpenLotsByName') + '?name=' + name).pipe(
      map((resp: Lot[]) => {
        if (isNilty(resp)) {
          return [];
        }
        return resp.map((it) => this.converter.fromJSONtoObj(it, Lot));
      })
    );
  }

  saveNewLot(newLot: NewLotInput): Observable<number> {
    const path = this.environmentService.getRestEndpoint('newLot');
    const body = this.converter.fromObjtoJSON(newLot);
    return this.http.post(path, body).pipe(map((resp: Lot) => this.converter.fromJSONtoObj(resp, Lot).id));
  }

  getFilteredLots(filters: LotFilters): Observable<Lot[]> {
    const path = this.environmentService.getRestEndpoint('lots');
    const body = this.converter.fromObjtoJSON(filters);

    return this.http.post(path, body, { observe: 'response' }).pipe(
      map((resp) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((lots: Lot[]) => {
        this.lots = [];
        for (let i = 0; i < lots.length; i++) {
          this.lots[i] = this.converter.fromJSONtoObj(lots[i], Lot);
        }
        return this.lots;
      })
    );
  }

  getLot(): Observable<Lot> {
    if (this.environmentService.dev) {
      return this.http.get(this.environmentService.getRestEndpoint('lots')).pipe(
        map((lots: Lot[]) => {
          this.currentLot = this.converter.fromJSONtoObj(lots[0], Lot);
          this.lotSubject.next(this.currentLot);
          return this.currentLot;
        })
      );
    } else {
      const url: string = this.environmentService.getRestEndpoint('lots') + '/' + this.currentLotId;
      return this.http.get(url).pipe(
        map((lot: Lot) => {
          this.currentLot = this.converter.fromJSONtoObj(lot, Lot);
          this.lotSubject.next(this.currentLot);
          return this.currentLot;
        })
      );
    }
  }

  private movePosToLot(lotId: number, pos: number[], isSplit: boolean): Observable<Lot> {
    const path =
      (isSplit ? this.environmentService.getRestEndpoint('movePosToLot') : this.environmentService.getRestEndpoint('addPosToLot')) +
      '?lotId=' +
      lotId;
    return this.http.post(path, pos).pipe(map((resp: Lot) => this.converter.fromJSONtoObj(resp, Lot)));
  }

  private moveFilteredPosToLot(lotId: number, pos: number[], isSplit: boolean, filters: PoFilters): Observable<Lot> {
    const path =
      (isSplit
        ? this.environmentService.getRestEndpoint('moveFilteredPosToLot')
        : this.environmentService.getRestEndpoint('addFilteredPosToLot')) +
      '?lotId=' +
      lotId;

    const body = this.converter.fromObjtoJSON(filters);
    return this.http.post(path, body).pipe(map((resp: Lot) => this.converter.fromJSONtoObj(resp, Lot)));
  }

  addPosToLot(filters: PoFilters, selectedPosIds: number[], mainChecker: boolean, isSplit: boolean, lotId: number): Observable<void> {
    if (mainChecker) {
      return this.moveFilteredPosToLot(lotId, selectedPosIds, isSplit, filters).pipe(
        map((responseLot: Lot) => {
          this.snackBar.open('Created lot named ' + responseLot.name + '.', 'CLOSE')._dismissAfter(3000);
        })
      );
    } else {
      return this.movePosToLot(lotId, selectedPosIds, isSplit).pipe(
        map((responseLot: Lot) => {
          this.snackBar.open(`Created lot named ${responseLot.name} with ${selectedPosIds.length} POs.`, 'CLOSE')._dismissAfter(3000);
        })
      );
    }
  }

  removePosFromLot(filters: PoFilters, selectedPosIds: number[], mainChecker: boolean, lotId: number): Observable<void> {
    const call = mainChecker ? this.removePosFromLotFromFilters(filters, lotId) : this.removePosFromLotFromIds(selectedPosIds, lotId);
    return call.pipe(
      map(() => {
        this.snackBar.open('The selected POs have been removed from this Lot.', 'CLOSE')._dismissAfter(3000);
      })
    );
  }

  private removePosFromLotFromIds(selectedPosIds: number[], lotId: number): Observable<any> {
    const path = this.environmentService.getRestEndpoint('removePosFromLot') + '?lotId=' + lotId;
    return this.http.post(path, selectedPosIds);
  }
  private removePosFromLotFromFilters(filters: PoFilters, lotId: number): Observable<any> {
    const path = this.environmentService.getRestEndpoint('removeFilteredPosFromLot') + '?lotId=' + lotId;
    const body = this.converter.fromObjtoJSON(filters);
    return this.http.post(path, body);
  }

  changeLotStatus(status: string): Observable<any> {
    let path = this.environmentService.getRestEndpoint('lots') + '/' + this.currentLotId + '/change-status=' + status;

    if (this.environmentService.dev) {
      path = this.environmentService.getRestEndpoint('lots');
    }

    return this.http.get(path);
  }

  deleteLot(): Observable<any> {
    let path = this.environmentService.getRestEndpoint('lots');

    if (!this.environmentService.dev) {
      path += '/' + this.currentLotId;
    }
    return this.http.delete(path);
  }

  generateOrdered(entity: string, supplierIds: number[] | null): Observable<any> {
    const path = this.environmentService.getRestEndpoint('generateOrderedQuantities') + '?entity=' + entity + '&lotId=' + this.currentLotId;
    return this.http.post(path, supplierIds);
  }

  generateOrderedForConfiguration(lotId: number, lotConfigurationId: number, generateOrderedConfigurationIds: number[]): Observable<any> {
    const path = this.environmentService.getRestEndpoint('generateOrderedQuantitiesForConfiguration');
    const body = { lotId, configurationId: lotConfigurationId, generateOrderedConfigurationIds };
    return this.http.post(path, body);
  }

  hasFulfilments(lotId: number): Observable<boolean> {
    const path = isDevMode()
      ? this.environmentService.getRestEndpoint('hasFulfilments')
      : this.environmentService.getRestEndpoint('hasFulfilments') + '/' + lotId;
    return this.http.get(path).pipe(map((resp: { hasFulfilments: boolean }) => (isNilty(resp) ? true : resp.hasFulfilments)));
  }

  getShipmentGenerateRecap(lotId: number): Observable<GenerateShipmentsRecap> {
    const path = this.environmentService.getRestEndpoint('shipmentGenerateRecap') + lotId;
    return this.http.get(path).pipe(map((resp: GenerateShipmentsRecap) => this.converter.fromJSONtoObj(resp, GenerateShipmentsRecap)));
  }

  getGenerateOrderedResultSummaries(lotId: number): Observable<GenerateOrderedSummary[]> {
    const path = this.environmentService.getRestEndpoint('generateOrderedSummaries') + lotId;
    return this.http
      .get(path)
      .pipe(map((resp: GenerateOrderedSummary[]) => resp.map((it) => this.converter.fromJSONtoObj(it, GenerateOrderedSummary))));
  }
}
