import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export class ManualInvoiceModalData {
  invoiceNumber: string;
  invoiceDate: Date;
}

@Component({
  selector: 'app-manual-invoice-modal',
  templateUrl: './manual-invoice-modal.component.html',
  styleUrls: ['./manual-invoice-modal.component.css'],
})
export class ManualInvoiceModalComponent implements OnInit {
  manualInvoiceForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<ManualInvoiceModalComponent>, @Inject(MAT_DIALOG_DATA) public data: ManualInvoiceModalData) {}

  ngOnInit() {
    this.manualInvoiceForm = new FormGroup({
      invoiceNumber: new FormControl(),
      invoiceDate: new FormControl(),
    });
  }

  onClose() {
    this.dialogRef.close();
  }

  onSubmit() {
    const output = new ManualInvoiceModalData();
    output.invoiceNumber = this.manualInvoiceForm.value.invoiceNumber;
    output.invoiceDate = this.manualInvoiceForm.value.invoiceDate;
    this.dialogRef.close(output);
  }
}
