<app-payment-request-list-filters (onFilter)="onFilter($event)"></app-payment-request-list-filters>

<div *ngIf="hasChangePaymentRequestStatus">
  <mat-toolbar class="bar-custom">
    <div class="container">
      <div class="row">
        <div class="col-md-2">
          <button [disabled]="!bulkActionsActive" class="box-shadow-none btn-custom" [matMenuTriggerFor]="changeStatusMenu">
            Change Status
          </button>
          <mat-menu #changeStatusMenu="matMenu">
            <button mat-menu-item (click)="changeStatus('PARTIALLY_PAID')">Partially Paid</button>
            <button mat-menu-item (click)="changeStatus('PAID')">Paid</button>
          </mat-menu>
        </div>
      </div>
    </div>
  </mat-toolbar>
</div>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <hr class="divisor-title" />
      <div class="square"></div>
      <h2 class="po-element-custom title-custom">Payment Requests</h2>
    </div>
  </div>
</div>

<div class="container table-outer">
  <mat-paginator [length]="resultsNumber | async" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()"> </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table multiTemplateDataRows [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="check" sticky>
        <mat-header-cell *matHeaderCellDef class="checker-col">
          <mat-checkbox (change)="toggleAll($event.checked)" [checked]="mainChecker"></mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let pr" class="checker-col">
          <mat-checkbox (change)="onSelected($event.checked, pr)" [checked]="pr.checked"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header="id" class="id-column"> ID </mat-header-cell>
        <mat-cell *matCellDef="let pr" class="id-column">
          {{ pr.id }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <mat-header-cell *matHeaderCellDef mat-sort-header="createdAt" class="date-column"> Create At </mat-header-cell>
        <mat-cell *matCellDef="let pr" class="date-column">
          {{ pr.createdAt | myDate }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="supplier">
        <mat-header-cell *matHeaderCellDef mat-sort-header="supplier" class="supplier-code-column"> Supplier </mat-header-cell>
        <mat-cell *matCellDef="let pr" class="supplier-code-column" [matTooltip]="pr.supplier.name">
          {{ pr.supplier.code }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="documents">
        <mat-header-cell *matHeaderCellDef class="list-column"> Documents </mat-header-cell>
        <mat-cell *matCellDef="let pr" class="list-column" [matTooltip]="joinDocumentNumber(pr)">
          <p>{{ joinDocumentNumber(pr) }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="shipments">
        <mat-header-cell *matHeaderCellDef class="list-column"> Shipments </mat-header-cell>
        <mat-cell *matCellDef="let pr" class="list-column" [matTooltip]="joinShipmentsOrderId(pr)">
          <p>{{ joinShipmentsOrderId(pr) }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header="status" class="status-column"> Status </mat-header-cell>
        <mat-cell *matCellDef="let pr" class="status-column">
          <p>{{ pr.status.description }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="paymentTaxable">
        <mat-header-cell *matHeaderCellDef class="value-column-title"> Payment Taxable </mat-header-cell>
        <mat-cell *matCellDef="let pr" class="value-column">
          <p>{{ pr.taxable | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="paymentVat">
        <mat-header-cell *matHeaderCellDef class="value-column-title"> Payment Vat </mat-header-cell>
        <mat-cell *matCellDef="let pr" class="value-column">
          <p>{{ pr.vat | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="paymentValue">
        <mat-header-cell *matHeaderCellDef class="value-column-title"> Payment Value </mat-header-cell>
        <mat-cell *matCellDef="let pr" class="value-column">
          <p>{{ pr.value | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="paymentQuantity">
        <mat-header-cell *matHeaderCellDef class="quantity-column-title"> Payment Quantity </mat-header-cell>
        <mat-cell *matCellDef="let pr" class="quantity-column">
          <p>{{ pr.quantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="shipmentsPurchasePrice">
        <mat-header-cell *matHeaderCellDef class="value-column-title"> Shipments Purchase Price </mat-header-cell>
        <mat-cell *matCellDef="let pr" class="value-column">
          <p>{{ pr.shipmentsPurchasePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="shipmentsValue">
        <mat-header-cell *matHeaderCellDef class="value-column-title"> Shipments Value </mat-header-cell>
        <mat-cell *matCellDef="let pr" class="value-column">
          <p>{{ pr.shipmentsValue | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="shipmentsQuantity">
        <mat-header-cell *matHeaderCellDef class="quantity-column-title"> Shipments Quantity </mat-header-cell>
        <mat-cell *matCellDef="let pr" class="quantity-column">
          <p>{{ pr.shipmentsQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="latestPaymentDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="latestPaymentDate" class="date-column"> Latest Payment Date </mat-header-cell>
        <mat-cell *matCellDef="let pr" class="date-column">
          <p>{{ pr.latestPaymentDate | myDate }}</p>
          <button
            *ngIf="pr.status.code === 'PENDING'"
            class="box-shadow-none actions-button btn-fixed margin-left-6"
            matTooltip="Change date"
            (click)="changeLatestPaymentDate(pr.id, pr.latestPaymentDate)"
          >
            <i class="fa fa-edit" aria-hidden="true"></i>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="latestPickupDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="latestPickupDate" class="date-column"> Latest Pickup Date </mat-header-cell>
        <mat-cell *matCellDef="let pr" class="date-column">
          <p>{{ pr.latestPickupDate | myDate }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="hasAdjustment">
        <mat-header-cell class="boolean-column" *matHeaderCellDef>Has Adjustment</mat-header-cell>
        <mat-cell class="boolean-column" *matCellDef="let pd">
          <p><i [ngClass]="pd.adjustments?.length > 0 | booleanIconPipe" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions" stickyEnd>
        <mat-header-cell class="actions-column center" *matHeaderCellDef>Actions</mat-header-cell>
        <mat-cell class="actions-column" *matCellDef="let pr">
          <ng-container *ngIf="hasPaymentManagement && pr.status.editable">
            <button class="box-shadow-none actions-button btn-fixed" matTooltip="Delete payment request" (click)="delete(pr)">
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
            <button class="box-shadow-none actions-button btn-fixed margin-left-6" matTooltip="Add adjustment" (click)="addAdjustment(pr)">
              <i class="fa fa-plus" aria-hidden="true"></i>
            </button>
          </ng-container>

          <ng-container *ngIf="showApproveButton(pr)">
            <button class="box-shadow-none actions-button btn-fixed margin-left-6" matTooltip="Approve reqeust" (click)="approve(pr)">
              <i class="fa fa-thumbs-up" aria-hidden="true"></i>
            </button>
          </ng-container>

          <ng-container *ngIf="hasChangePaymentRequestStatus">
            <ng-container *ngIf="pr.status.code === 'PROCESSING'">
              <button class="box-shadow-none actions-button btn-fixed margin-left-6" matTooltip="Cancel request" (click)="cancel(pr)">
                <i class="fa fa-times" aria-hidden="true"></i>
              </button>
              <button class="box-shadow-none actions-button btn-fixed margin-left-6" matTooltip="Validate request" (click)="validate(pr)">
                <i class="fa fa-check" aria-hidden="true"></i>
              </button>
            </ng-container>

            <button
              class="box-shadow-none actions-button btn-fixed margin-left-6"
              matTooltip="Download payment details"
              [disabled]="pr.status.approvable"
              (click)="generateDetails(pr)"
            >
              <i class="fa fa-download" aria-hidden="true"></i>
            </button>
          </ng-container>

          <ng-container *ngIf="isAdmin">
            <ng-container *ngIf="pr.status.code === 'VALID'">
              <button
                class="box-shadow-none actions-button btn-fixed margin-left-6"
                matTooltip="Change stutus to pending"
                (click)="changeValidToPending(pr)"
              >
                <i class="fa fa-undo" aria-hidden="true"></i>
              </button>
            </ng-container>
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="showAdjustments" stickyEnd>
        <mat-header-cell class="details-column center" *matHeaderCellDef>Show Adjustments </mat-header-cell>
        <mat-cell class="details-column" *matCellDef="let pr">
          <p>
            <i
              [ngClass]="selectedRow?.id === pr.id ? 'fa fa-chevron-circle-up' : 'fa fa-chevron-circle-down'"
              class="expand-icon"
              (click)="toggle(pr)"
            ></i>
          </p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <mat-cell class="background-light" *matCellDef="let pr" [attr.colspan]="displayedColumns.length">
          <div class="element-detail" [@detailExpand]="pr.id == selectedRow?.id ? 'expanded' : 'collapsed'">
            <div class="margin-lr-0 padding-top-10 padding-bottom-10 width-100per">
              <app-adjustments-table
                [adjustments]="pr.adjustments"
                [isEditable]="pr.status.editable"
                (adjustmentDeleted)="adjustmentDeleted($event)"
              ></app-adjustments-table>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns" [class.example-expanded-row]="selectedRow?.id === row.id"> </mat-row>
      <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="collapsed-detail-row"></mat-row>
    </mat-table>
  </div>
</div>
