import { Warehouse } from '../warehouse-model';
import { PaginationAndSorting } from './pagination-and-sorting-model';
import { MatChipsData } from '../mat-chips-data-model';
import { Vendor } from '../vendor-model';
import { Category } from '../category-model';
import { Brand } from '../brand-model';
import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter, fromDateToFiltersString } from '../date-converter';

@JsonObject('PoFilters')
export class PoFilters extends PaginationAndSorting {
  @JsonProperty('vendors', [Number], true) vendors: number[] = undefined;
  @JsonProperty('parent', Number, true) parent: number = undefined;
  @JsonProperty('lotName', String, true) lotName: string = undefined;
  @JsonProperty('lotDate', DateConverter, true) lotDate: Date = undefined;
  @JsonProperty('eans', String, true) eans: string = undefined;
  @JsonProperty('purchaseOrder', String, true) purchaseOrder: string = undefined;
  @JsonProperty('from', DateConverter, true) from: Date = undefined;
  @JsonProperty('to', DateConverter, true) to: Date = undefined;
  @JsonProperty('destinationId', Number, true) destinationId: number = undefined;
  @JsonProperty('categoryId', Number, true) categoryId: number = undefined;
  @JsonProperty('status', String, true) status: string = undefined;
  @JsonProperty('hasLot', Boolean, true) hasLot: boolean = undefined;
  @JsonProperty('brand', String, true) brand: string = undefined;
  @JsonProperty('acceptedDifferentFromPoa', Boolean, true) acceptedDifferentFromPoa: boolean = undefined;
  @JsonProperty('obsoleteWithAccepted', Boolean, true) obsoleteWithAccepted: boolean = undefined;

  @JsonProperty('latestDeliveryDateFrom', DateConverter, true) latestDeliveryDateFrom: Date = undefined;
  @JsonProperty('latestDeliveryDateTo', DateConverter, true) latestDeliveryDateTo: Date = undefined;

  @JsonProperty('lotId', Number, true) lotId: number = undefined;

  @JsonProperty('isAdr', Boolean, true) isAdr: boolean = undefined;

  constructor() {
    super();
    this.sortBy = 'id';
    this.sortDirection = 'desc';
  }
}

export const poFiltersMap = (
  vendors?: Vendor[],
  parent?: Vendor,
  lotName?: string,
  lotDate?: Date,
  ean?: string,
  po?: string,
  dateFrom?: Date,
  dateTo?: Date,
  destination?: Warehouse,
  category?: Category,
  status?: string,
  hasLot?: string,
  brand?: Brand,
  acceptedDifferentFromPoa?: boolean,
  obsoleteWithOrdered?: boolean,

  latestDeliveryDateFrom?: Date,
  latestDeliveryDateTo?: Date,
  isAdr?: boolean
): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (vendors !== null && vendors !== undefined) {
    map.push(new MatChipsData('Vendors', vendors.length));
  }
  if (parent !== null && parent !== undefined) {
    map.push(new MatChipsData('Parent', parent.code));
  }
  if (lotName !== null && lotName !== undefined) {
    map.push(new MatChipsData('Lot Name', lotName));
  }
  if (lotDate !== null && lotDate !== undefined) {
    map.push(new MatChipsData('Lot Date', fromDateToFiltersString(lotDate)));
  }
  if (ean !== null && ean !== undefined) {
    map.push(new MatChipsData('EAN', ean));
  }
  if (po !== null && po !== undefined) {
    map.push(new MatChipsData('PO', po));
  }
  if (dateFrom !== null && dateFrom !== undefined) {
    map.push(new MatChipsData('From', fromDateToFiltersString(dateFrom)));
  }
  if (dateTo !== null && dateTo !== undefined) {
    map.push(new MatChipsData('To', fromDateToFiltersString(dateTo)));
  }
  if (destination !== null && destination !== undefined) {
    map.push(new MatChipsData('Destination', destination.code));
  }
  if (category !== null && category !== undefined) {
    map.push(new MatChipsData('Category', category.name));
  }
  if (status !== null && status !== undefined) {
    map.push(new MatChipsData('Status', status));
  }
  if (hasLot !== null && hasLot !== undefined) {
    map.push(new MatChipsData('Has Lot', hasLot));
  }
  if (brand !== null && brand !== undefined) {
    map.push(new MatChipsData('Brand', brand.name));
  }
  if (latestDeliveryDateFrom) {
    map.push(new MatChipsData('Latest From', fromDateToFiltersString(latestDeliveryDateFrom)));
  }
  if (latestDeliveryDateTo) {
    map.push(new MatChipsData('Latest To', fromDateToFiltersString(latestDeliveryDateTo)));
  }
  if (acceptedDifferentFromPoa) {
    map.push(new MatChipsData('Accepted != POA', 'yes'));
  }
  if (obsoleteWithOrdered) {
    map.push(new MatChipsData('Obsolete with ordered', 'yes'));
  }
  if (isAdr) {
    map.push(new MatChipsData('Is ADR', 'yes'));
  }
  return map;
};
