<div class="row main-content">
  <div class="col-xs-12 margin-bottom-60 form-element">
    <div class="row">
      <div class="col-xs-12">
        <mat-form-field class="width-100per">
          <input
            matInput
            class="text-uppercase"
            type="text"
            id="name"
            [disabled]="(hasShipmentManagement | async) === (false || null || undefined)"
            [(ngModel)]="carrier.name"
            placeholder="Name"
            maxlength="14"
          />
        </mat-form-field>
      </div>
    </div>

    <ng-container *ngIf="editMode">
      <ng-container *ngIf="hasShipmentManagement | async">
        <div class="row">
          <div class="col-xs-8">
            <mat-form-field class="width-100per">
              <app-mat-select [(ngModel)]="supplier" filterKey="description" placeholder="Supplier">
                <mat-option [value]="null">None</mat-option>
                <mat-option [value]="supplier" *appMatSelectFor="let supplier of suppliers">{{ supplier.description }} </mat-option>
              </app-mat-select>
            </mat-form-field>
          </div>

          <div class="col-xs-4">
            <button type="button" [disabled]="!supplier" class="btn-product add-button" (click)="addContract()">
              <i class="fa fa-plus padding-bottom-1" aria-hidden="true"></i> Add Contract
            </button>
          </div>
        </div>
      </ng-container>

      <mat-list dense class="contracts-list">
        <mat-list-item *ngFor="let contract of contracts; let i = index">
          <p>
            <button
              type="button"
              *ngIf="hasShipmentManagement | async"
              class="box-shadow-none actions-button btn-fixed margin-right-6"
              matTooltip="Remove"
              (click)="removeContract(i)"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
            {{ contract.supplier.code }} - {{ contract.supplier.name }}
          </p>
        </mat-list-item>
      </mat-list>
    </ng-container>

    <div class="row">
      <div class="col-xs-12 text-left margin-top-20">
        <mat-checkbox
          class="text-blue font-Titillium"
          id="trackingMore"
          [disabled]="(hasShipmentManagement | async) === (false || null || undefined)"
          [(ngModel)]="carrier.trackingMore"
          >Trackingmore</mat-checkbox
        >
      </div>
    </div>

    <div class="row form-buttons">
      <ng-container *ngIf="hasShipmentManagement | async">
        <button
          type="button"
          (click)="onSubmit()"
          [disabled]="!carrier.name || carrier.name === '' || (oldName === carrier.name && oldTrackingMore === carrier.trackingMore)"
          class="btn-product margin-right-6"
        >
          Save
        </button>
        <button *ngIf="editMode" type="button" (click)="onDelete()" class="btn-product margin-right-6">Delete!</button>
      </ng-container>
      <button type="button" (click)="onCancel()" class="btn-product">Back</button>
    </div>
  </div>
</div>
