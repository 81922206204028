<div [ngClass]="{ 'black-panel': openFilter, panel: !openFilter }">
  <mat-expansion-panel class="filter-position" [attr.expanded]="openFilter" (opened)="openEvent()" (closed)="closeEvent()">
    <mat-expansion-panel-header class="filt-custom">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-9">
            <mat-chip-list>
              <mat-chip *ngFor="let f of filtersMap">{{ f.filter }}</mat-chip>
            </mat-chip-list>
          </div>
          <div class="col-md-2">
            <mat-panel-title class="filter-title">
              Filters
            </mat-panel-title>
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>

    <form [formGroup]="filterForm" (ngSubmit)="onFilter()">
      <div class="container border-bottom-orange col-md-12 text-blue margin-top-5 product-filters">
        <div class="row center">
          <div class="col-xs-1"></div>

          <div class="col-xs-2">
            <mat-form-field>
              <input type="text" matInput formControlName="brand" [matAutocomplete]="brand" placeholder="Brand" />
            </mat-form-field>
          </div>
          <mat-autocomplete #brand [displayWith]="brandDisplayFn">
            <mat-option *ngFor="let brand of filteredBrands | async" [value]="brand">
              {{ brand.name }}
            </mat-option>
          </mat-autocomplete>

          <div class="col-xs-2">
            <mat-form-field>
              <input type="text" matInput formControlName="season" [matAutocomplete]="season" placeholder="Season" />
            </mat-form-field>
          </div>
          <mat-autocomplete #season [displayWith]="seasonDisplayFn">
            <mat-option *ngFor="let season of filteredSeasons | async" [value]="season">
              {{ season.period }}
            </mat-option>
          </mat-autocomplete>

          <div class="col-xs-2">
            <mat-form-field>
              <input matInput formControlName="ean" placeholder="EAN/SKU/Article Code" />
            </mat-form-field>
          </div>

          <div class="col-xs-2">
            <mat-form-field>
              <input matInput formControlName="status" placeholder="Status" />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="col-md-12 padding-top-10 padding-bottom-10">
        <div class="row">
          <div class="col-xs-3"></div>
          <div class="col-xs-3 center">
            <button class="btn-custom" type="submit">Filter</button>
          </div>
          <div class="col-xs-3 center">
            <button class="btn-custom" type="button" (click)="resetFilters()">Reset</button>
          </div>
          <div class="col-xs-3"></div>
        </div>
      </div>
    </form>
  </mat-expansion-panel>
</div>
