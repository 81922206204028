import { Component, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { Category } from '../../../../../../models/category-model';
import { VendorsService } from '../../../../../../container/configuration/vendors/vendors.service';
import { InventoryService } from '../../../../../../container/inventory/inventory.service';
import { isNilty } from '../../../../../../core/utils.service';
import { Contract } from '../../../../../../models/contract.model';
import { MatChipsData } from '../../../../../../models/mat-chips-data-model';
import { Status } from '../../../../../../models/status-model';
import { Supplier } from '../../../../../../models/supplier-model';
import { Vendor } from '../../../../../../models/vendor-model';
import { ShipmentsService } from '../../../../../../shared/shipments.service';
import { ClaimHeaderFilters, claimHeaderFiltersMap } from '../../.././../../../models/filters/claim-header-filters-model';
import { ClaimService } from '../../claim.service';

@Component({
  selector: 'app-claim-headers-list-filters',
  templateUrl: './claim-headers-list-filters.component.html',
  styleUrls: ['./claim-headers-list-filters.component.css'],
})
export class ClaimHeadersListFiltersComponent implements OnInit {
  constructor(
    private vendorsService: VendorsService,
    private inventoryService: InventoryService,
    private claimService: ClaimService,
    private shipmentService: ShipmentsService
  ) {}

  filterForm: FormGroup;
  public openFilter = false;

  filtersMap: MatChipsData[] = [];

  vendors: Vendor[] = [];
  suppliers: Supplier[] = [];
  categories: Category[] = [];
  status: Status[] = [];
  contracts: Contract[] = [];

  @Output() onFilter = new Subject<ClaimHeaderFilters>();

  ngOnInit() {
    this.filterForm = new FormGroup({
      invoiceNumber: new FormControl(),
      invoiceDateStart: new FormControl(),
      invoiceDateEnd: new FormControl(),
      remittanceDateStart: new FormControl(),
      remittanceDateEnd: new FormControl(),
      vendor: new FormControl(),
      vendorChild: new FormControl(),
      supplier: new FormControl(),
      carrierContracts: new FormControl(),
      trackingNumber: new FormControl(),
      scStatus: new FormControl(),
      pcStatus: new FormControl(),
      disputeId: new FormControl(),
      caseId: new FormControl(),
      deliveryDate: new FormControl(),
      hasPod: new FormControl(),
      hasRemittance: new FormControl(),
      hasProbableNC: new FormControl(),
      debitNoteYear: new FormControl(),
      debitNoteMonth: new FormControl(),
      countryOfDestination: new FormControl(),
      category: new FormControl(),
      ean: new FormControl(),
      po: new FormControl(),
      settlement: new FormControl(),
      botEnabled: new FormControl(),
      dueDateFrom: new FormControl(),
      dueDateTo: new FormControl(),
    });

    if (!isNilty(this.claimService.claimHeaderFilters)) {
      this.filterForm.patchValue(this.claimService.claimHeaderFilters);
      this.updateFiltersMap();
    }

    this.vendorsService.getVendors().subscribe((vendors: Vendor[]) => (this.vendors = vendors));

    this.inventoryService.getAllSuppliers().subscribe((suppliers: Supplier[]) => (this.suppliers = suppliers));
    this.inventoryService.getAllCategories().subscribe((categories: Category[]) => (this.categories = categories));

    this.claimService.getClaimHeaderStatus().subscribe((status: Status[]) => (this.status = status));

    this.shipmentService.getAllContracts().subscribe((contracts: Contract[]) => (this.contracts = contracts));
  }

  onSubmitFilter() {
    this.saveFiltersToChargebackService();
    this.updateFiltersMap();
    this.onFilter.next(this.claimService.claimHeaderFilters);
  }

  resetFilters() {
    this.filterForm.reset();
    this.saveFiltersToChargebackService();
    this.updateFiltersMap();
    this.onFilter.next(this.claimService.claimHeaderFilters);
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeevent() {
    this.openFilter = false;
  }

  saveFiltersToChargebackService() {
    this.claimService.claimHeaderFilters.invoiceNumber = isNilty(this.filterForm.value.invoiceNumber)
      ? null
      : this.filterForm.value.invoiceNumber;
    this.claimService.claimHeaderFilters.invoiceDateStart = this.filterForm.value.invoiceDateStart;
    this.claimService.claimHeaderFilters.invoiceDateEnd = this.filterForm.value.invoiceDateEnd;
    this.claimService.claimHeaderFilters.remittanceDateStart = this.filterForm.value.remittanceDateStart;
    this.claimService.claimHeaderFilters.remittanceDateEnd = this.filterForm.value.remittanceDateEnd;
    this.claimService.claimHeaderFilters.vendor = isNilty(this.filterForm.value.vendor) ? null : this.filterForm.value.vendor.id;
    this.claimService.claimHeaderFilters.supplier = this.filterForm.value.supplier;
    this.claimService.claimHeaderFilters.carrierContracts = isNilty(this.filterForm.value.carrierContracts)
      ? null
      : this.filterForm.value.carrierContracts.map((carrierContract) => carrierContract.id);
    this.claimService.claimHeaderFilters.trackingNumber = isNilty(this.filterForm.value.trackingNumber)
      ? null
      : this.filterForm.value.trackingNumber;
    this.claimService.claimHeaderFilters.scStatus = this.filterForm.value.scStatus;
    this.claimService.claimHeaderFilters.pcStatus = this.filterForm.value.pcStatus;
    this.claimService.claimHeaderFilters.disputeId = isNilty(this.filterForm.value.disputeId) ? null : this.filterForm.value.disputeId;
    this.claimService.claimHeaderFilters.caseId = isNilty(this.filterForm.value.caseId) ? null : this.filterForm.value.caseId;
    this.claimService.claimHeaderFilters.deliveryDate = this.filterForm.value.deliveryDate;
    this.claimService.claimHeaderFilters.hasPod = this.filterForm.value.hasPod;
    this.claimService.claimHeaderFilters.hasRemittance = this.filterForm.value.hasRemittance;
    this.claimService.claimHeaderFilters.hasProbableNC = this.filterForm.value.hasProbableNC;
    this.claimService.claimHeaderFilters.debitNoteYear = this.filterForm.value.debitNoteYear;
    this.claimService.claimHeaderFilters.debitNoteMonth = this.filterForm.value.debitNoteMonth;
    this.claimService.claimHeaderFilters.countryOfDestination = this.filterForm.value.countryOfDestination;
    this.claimService.claimHeaderFilters.category = isNilty(this.filterForm.value.category) ? null : this.filterForm.value.category.id;
    this.claimService.claimHeaderFilters.ean = this.filterForm.value.ean;
    this.claimService.claimHeaderFilters.pageNumber = null;
    this.claimService.claimHeaderFilters.pageSize = null;
    this.claimService.claimHeaderFilters.sortBy = null;
    this.claimService.claimHeaderFilters.sortDirection = null;
    this.claimService.claimHeaderFilters.po = this.filterForm.value.po;
    this.claimService.claimHeaderFilters.settlement = this.filterForm.value.settlement;
    this.claimService.claimHeaderFilters.botEnabled = this.filterForm.value.botEnabled;
    this.claimService.claimHeaderFilters.dueDateFrom = this.filterForm.value.dueDateFrom;
    this.claimService.claimHeaderFilters.dueDateTo = this.filterForm.value.dueDateTo;
  }

  updateFiltersMap() {
    this.filtersMap = claimHeaderFiltersMap(
      this.filterForm.value.invoiceNumber,
      this.filterForm.value.invoiceDateStart,
      this.filterForm.value.invoiceDateEnd,
      this.filterForm.value.remittanceDateStart,
      this.filterForm.value.remittanceDateEnd,
      this.filterForm.value.vendor,
      this.filterForm.value.supplier,
      this.filterForm.value.carrierContracts,
      this.filterForm.value.trackingNumber,
      this.filterForm.value.scStatus,
      this.filterForm.value.pcStatus,
      this.filterForm.value.disputeId,
      this.filterForm.value.caseId,
      this.filterForm.value.deliveryDate,
      this.filterForm.value.hasPod,
      this.filterForm.value.hasRemittance,
      this.filterForm.value.hasProbableNC,
      this.filterForm.value.debitNoteYear,
      this.filterForm.value.debitNoteMonth,
      this.filterForm.value.countryOfDestination,
      this.filterForm.value.category,
      this.filterForm.value.ean,
      this.filterForm.value.po,
      this.filterForm.value.settlement,
      this.filterForm.value.botEnabled,
      this.filterForm.value.dueDateFrom,
      this.filterForm.value.dueDateTo
    );
  }
}
