import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { LotEditKpi } from '../../../../models/lot-edit-kpi';
import { PosService } from '../../../../shared/pos.service';

@Component({
  selector: 'app-po-kpi',
  templateUrl: './po-kpi.component.html',
  styleUrls: ['./po-kpi.component.css'],
})
export class PoKpiComponent implements OnInit {
  constructor(private router: Router, private posService: PosService) {}

  kpiData: Observable<LotEditKpi>;

  ngOnInit() {
    this.kpiData = this.posService.kpiData;
  }

  goBack() {
    this.router.navigate(['/lots']);
  }
}
