<mat-toolbar id="subsectionMenu" color="accent">
  <span class="fill-remaining-space text-center">
    <ng-container>
      <button *ngIf="hasAdministration | async" class="btn-configuration" [routerLink]="['/configuration/categories']" routerLinkActive="active">
        <i class="fa fa-list-alt padding-bottom-1" aria-hidden="true"></i>
        Categories
      </button>
      <button *ngIf="hasAdministration | async" class="btn-configuration" [routerLink]="['/configuration/vendors']" routerLinkActive="active">
        <i class="fa fa-pencil-square-o padding-bottom-1" aria-hidden="true"></i>
        Vendors
      </button>
      <button *ngIf="hasVendorAdministration | async " class="btn-configuration" [routerLink]="['/configuration/vendor-central-accounts']" routerLinkActive="active">
        <i class="fa fa-pencil-square-o padding-bottom-1" aria-hidden="true"></i>
        Vendor Central Account
      </button>
      <button *ngIf="hasAdministration | async" class="btn-configuration" [routerLink]="['/configuration/warehouses']" routerLinkActive="active">
        <i class="fa fa-list-ul padding-bottom-1" aria-hidden="true"></i>
        Warehouses
      </button>
      <button *ngIf="hasAdministration | async" class="btn-configuration" [routerLink]="['/configuration/transit-times']" routerLinkActive="active">
        <i class="fa fa-map padding-bottom-1" aria-hidden="true"></i>
        Transit times
      </button>
      <button *ngIf="hasAdministration | async" class="btn-configuration" [routerLink]="['/configuration/country-holidays']" routerLinkActive="active">
        <i class="fa fa-globe padding-bottom-1" aria-hidden="true"></i>
        Country holidays
      </button>
      <button *ngIf="hasAdministration | async" class="btn-configuration" [routerLink]="['/configuration/publication-rules']" routerLinkActive="active">
        <i class="fa fa-gavel padding-bottom-1" aria-hidden="true"></i>
        Publication Rules
      </button>
      <button *ngIf="hasAdministration | async" class="btn-configuration" [routerLink]="['/configuration/lot-configurations']" routerLinkActive="active">
        <i class="fa fa-gavel padding-bottom-1" aria-hidden="true"></i>
        Lot Configurations
      </button>
    </ng-container>
    <button *ngIf="hasConfiguration | async" class="btn-configuration" [routerLink]="['/configuration/params']" routerLinkActive="active">
      <i class="fa fa-wrench" aria-hidden="true"></i>
      Configuration params
    </button>
  </span>
</mat-toolbar>
