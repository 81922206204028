import * as moment from 'moment';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { encode } from 'base64-arraybuffer';

export const hideLoaderHeaders: () => HttpHeaders = () => new HttpHeaders({ 'Hide-Loader': 'true' });
export const isLoaderHidden: (headers: HttpHeaders) => boolean = (headers) => headers.get('Hide-Loader') === 'true';

export const isNil = (item: any): boolean => item === null || item === undefined;

/**
 * Permette di verificare se il parametro in input sia vuoto (stringa, array o oggetto)
 *
 * @param item
 * @returns true: se vuoto, false: altrimenti
 */
export const isEmpty = (item: any): boolean => {
  if (typeof item === 'string') {
    return item === '';
  } else if (typeof item === 'object') {
    if (Array.isArray(item)) {
      return item.length === 0;
    } else {
      return Object.keys(item).length === 0;
    }
  }
};

/**
 * Permette di verificare se il parametro in input sia nullo, undefined o vuoto (stringa, array o oggetto)
 *
 * @param item
 * @returns true: se nullo, undefined o vuoto, false: altrimenti
 */
export const isNilty = (item: any): boolean => item === null || item === undefined || isEmpty(item);

/**
 * Permette di verificare l'uguaglianza tra due oggetti
 *
 * @param obj: oggetto sorgente
 * @param obj1: oggetto da confrontare
 * @param {string[]} keyToCheck
 * @param {boolean} exclude
 * @returns {boolean} true: se sono uguali, false: altrimenti
 */

export const isEqual = (obj: any, obj1: any, keyToCheck: string[] = [], exclude = true): boolean => {
  let isequal = true;
  if (typeof obj === typeof obj1) {
    if (typeof obj === 'object' && !isNil(obj) && !moment.isMoment(obj)) {
      if (!Array.isArray(obj)) {
        for (const key of Object.keys(obj)) {
          if (obj1.hasOwnProperty(key)) {
            if (
              ((exclude === true && keyToCheck.indexOf(key) === -1) || (exclude === false && keyToCheck.indexOf(key) > -1)) &&
              isequal === true
            ) {
              isequal = isEqual(obj[key], obj1[key], keyToCheck, exclude);
            }
          } else {
            isequal = false;
          }
        }
      } else {
        if (obj.length === obj1.length) {
          obj.forEach((elem, index) => {
            if (isequal === true) {
              isequal = isEqual(elem, obj1[index], keyToCheck, exclude);
            }
          });
        } else {
          isequal = false;
        }
      }
    } else {
      if (!moment.isMoment(obj)) {
        isequal = obj === obj1;
      } else {
        isequal = moment(obj).isSame(obj1);
      }
    }
  } else {
    isequal = false;
  }
  return isequal;
};

/**
 * Permette di clonare un oggetto tipizzato
 *
 * @param objToClone: oggetto da clonare
 * @param classIntoClone: classe diversa dall'oggetto da clonare
 */
export const cloneClass = (objToClone: any, classIntoClone?: any): any => {
  let objCloned;
  if (!isNil(objToClone)) {
    if (typeof objToClone === 'object' && !moment.isMoment(objToClone)) {
      if (!Array.isArray(objToClone)) {
        const classConstructor = !isNilty(classIntoClone) ? classIntoClone : objToClone.constructor;
        objCloned = new classConstructor();
        for (const key of Object.keys(objToClone)) {
          if (objCloned.hasOwnProperty(key)) {
            objCloned[key] = cloneClass(objToClone[key], classIntoClone);
          }
        }
      } else {
        objCloned = [];
        for (const elem of objToClone) {
          objCloned.push(cloneClass(elem, classIntoClone));
        }
      }
    } else {
      objCloned = objToClone;
    }
  }

  return objCloned;
};

export const compareNumberOrString = (a: number | string, b: number | string, isAsc: boolean) => (a < b ? -1 : 1) * (isAsc ? 1 : -1);
export const compareDates = (a: string, b: string, isAsc: boolean) =>
  (moment(a, 'DD/MM/YYYY HH:mm').isBefore(moment(b, 'DD/MM/YYYY HH:mm')) ? -1 : 1) * (isAsc ? 1 : -1);

export const getDaysDifference = (from: Date, to: Date): number => moment(to).diff(moment(from), 'days');

export const getBase64EncodedFileData = (file: File): Observable<string> =>
  new Observable(observer => {
    const reader = new FileReader();

    reader.onload = () => {
      const { result } = reader;
      const data = result as ArrayBuffer; // <--- FileReader gives us the ArrayBuffer
      const base64Encoded = encode(data); // <--- convert ArrayBuffer to base64 string

      observer.next(base64Encoded);
      observer.complete();
    };

    reader.onerror = () => {
      observer.error(reader.error);
    };

    reader.readAsArrayBuffer(file);
  });
