<app-log-discarded-value-filters (onFilter)="onFilter($event)"></app-log-discarded-value-filters>

<div class="container table-outer">
  <mat-paginator #paginator [length]="resultsNumber | async" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()">
  </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table multiTemplateDataRows [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="supplier">
        <mat-header-cell class="supplier-column" *matHeaderCellDef mat-sort-header="supplier">Supplier</mat-header-cell>
        <mat-cell class="supplier-column" *matCellDef="let ldv">{{ ldv.supplier?.code }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="stockLocation" sticky>
        <mat-header-cell class="stock-location-column" *matHeaderCellDef mat-sort-header="stockLocation">Stock Location</mat-header-cell>
        <mat-cell class="stock-location-column" *matCellDef="let ldv">{{ ldv.stockLocation?.name }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="vendor">
        <mat-header-cell class="vendor-column" *matHeaderCellDef mat-sort-header="vendor"> Vendor </mat-header-cell>
        <mat-cell class="vendor-column" *matCellDef="let ldv">{{ ldv.vendor?.code }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="destination" sticky>
        <mat-header-cell class="destination-column" *matHeaderCellDef mat-sort-header="destination"> Destination </mat-header-cell>
        <mat-cell class="destination-column" *matCellDef="let ldv">{{ ldv.destination?.code }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell class="code-column" *matHeaderCellDef mat-sort-header="type"> Type </mat-header-cell>
        <mat-cell class="code-column" *matCellDef="let ldv">{{ ldv.type.description }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="discardedValue">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="discardedValue"> Discarded Value </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let ldv"
          ><p>{{ ldv.discardedValue | myCurrency }}</p></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
        <mat-cell *matCellDef="let ldv">{{ ldv.description }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="showDetails" stickyEnd>
        <mat-header-cell class="details-column center" *matHeaderCellDef>Details</mat-header-cell>
        <mat-cell class="details-column" *matCellDef="let ldv">
          <p>
            <i
              [ngClass]="selectedRow?.id === ldv.id ? 'fa fa-chevron-circle-up' : 'fa fa-chevron-circle-down'"
              class="expand-icon"
              (click)="toggle(ldv)"
            ></i>
          </p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <mat-cell class="background-light" *matCellDef="let ldv" [attr.colspan]="displayedColumns.length">
          <div class="element-detail" [@detailExpand]="ldv.id == selectedRow?.id ? 'expanded' : 'collapsed'">
            <div *ngIf="ldv.id == selectedRow?.id" class="margin-lr-0 padding-top-10 padding-bottom-10 width-100per">
              <app-log-discarded-value-detail [logDiscardedValueId]="ldv.id"></app-log-discarded-value-detail>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns" [class.example-expanded-row]="selectedRow?.id === row.id"> </mat-row>
      <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="collapsed-detail-row"></mat-row>
    </mat-table>
  </div>
</div>
