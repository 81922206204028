<h2 mat-dialog-title>Shipment Edit</h2>

<mat-dialog-content [formGroup]="carrierForm">
  <div>
    <mat-form-field>
      <app-mat-select filterKey="name" placeholder="Contract" formControlName="contract">
        <mat-option [value]="null">None</mat-option>
        <mat-option [value]="contract" *appMatSelectFor="let contract of contracts">{{ contract.name }}</mat-option>
      </app-mat-select>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field>
      <input matInput maxlength="30" placeholder="Tracking number" formControlName="trackingNumber" />
    </mat-form-field>
  </div>

  <div>
    <mat-form-field>
      <input matInput [matDatepicker]="expectedDeliveryDate" placeholder="Expected delivery date" formControlName="expectedDeliveryDate" />
      <mat-datepicker-toggle matSuffix [for]="expectedDeliveryDate"></mat-datepicker-toggle>
      <mat-datepicker #expectedDeliveryDate></mat-datepicker>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field>
      <input matInput [matDatepicker]="deliveryDate" placeholder="Delivery date" formControlName="deliveryDate" />
      <mat-datepicker-toggle matSuffix [for]="deliveryDate"></mat-datepicker-toggle>
      <mat-datepicker #deliveryDate></mat-datepicker>
    </mat-form-field>
  </div>

  <ng-container *ngIf="hasTransit">
    <div>
      <mat-form-field>
        <input matInput [matDatepicker]="incomingDate" placeholder="Incoming date" formControlName="incomingDate" />
        <mat-datepicker-toggle matSuffix [for]="incomingDate"></mat-datepicker-toggle>
        <mat-datepicker #incomingDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input matInput type="number" placeholder="Preparation days" formControlName="preparationDays" />
      </mat-form-field>
    </div>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions>
  <button class="btn-product margin-right-38" [disabled]="carrierForm.invalid" (click)="onSubmit()">Save</button>
  <button class="btn-product" (click)="onClose()">Close</button>
</mat-dialog-actions>
