<div class="row">
  <ng-container *ngIf="!dataSource || dataSource?.data.length === 0">
    <div class="col-md-12 small-table-title text-center">
      <h4>No active publications found</h4>
    </div>
  </ng-container>

  <div class="col-md-6 small-table-title border-bottom-title" [ngClass]="{ 'display-none': !dataSource || dataSource?.data.length === 0 }">
    <h4>Active publications</h4>
  </div>
  <div class="col-md-6 fixed-height-40 border-bottom-title" [ngClass]="{ 'display-none': !dataSource || dataSource?.data.length === 0 }">
    <mat-paginator class="border-bottom-title" id="little-paginator" #paginator [pageSize]="5" [pageSizeOptions]="[5]"> </mat-paginator>
  </div>

  <div
    class="col-md-12 fixed-table-5-rows padding-right-0 padding-left-0"
    [ngClass]="{ 'display-none': !dataSource || dataSource?.data.length === 0 }"
  >
    <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-40">
      <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
        <ng-container matColumnDef="vendor">
          <mat-header-cell *matHeaderCellDef mat-sort-header="vendor">Vendor</mat-header-cell>
          <mat-cell *matCellDef="let p">
            <p>{{ p?.vendor?.code }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="country">
          <mat-header-cell *matHeaderCellDef mat-sort-header="country">Country</mat-header-cell>
          <mat-cell *matCellDef="let p">
            <p>{{ p?.vendor?.country }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="updatedAt">
          <mat-header-cell *matHeaderCellDef mat-sort-header="updatedAt">Updated at</mat-header-cell>
          <mat-cell *matCellDef="let p">
            <p>{{ p.updatedAt }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="amazonPrice">
          <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="amazonPrice">Price</mat-header-cell>
          <mat-cell class="value-column" *matCellDef="let p">
            <p>{{ p.amazonPrice | myCurrency }}</p>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>
</div>
