import { Component, OnInit, ViewChild } from '@angular/core';
import { Vendor } from '../../../../models/vendor-model';
import { VendorsService } from '../vendors.service';
import { ChildVendorsDialog } from './child-vendors-dialog/child-vendors-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-vendors-list',
  templateUrl: './vendors-list.component.html',
  styleUrls: ['./vendors-list.component.css'],
})
export class VendorsListComponent implements OnInit {
  dataSource: MatTableDataSource<Vendor>;

  displayedColumns = [
    'code',
    'name',
    'country',
    'brandonGln',
    'defaultOrderAcceptanceMode',
    'sendSoftFirstorder',
    'brandonWarehouseCode',
    'showChildren',
    'edit',
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  showOnlyParents: boolean;

  constructor(private vendorsService: VendorsService, private dialog: MatDialog) {}

  ngOnInit() {
    this.showOnlyParents = this.vendorsService.showOnlyParents;

    this.dataSource = new MatTableDataSource([]);

    this.vendorsService.getVendors().subscribe(() => {
      if (this.vendorsService.showOnlyParents) {
        this.dataSource.data = this.vendorsService.parentVendors;
      } else {
        this.dataSource.data = this.vendorsService.vendors;
      }
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  onChangeParentChoice(event) {
    this.vendorsService.showOnlyParents = event.checked;

    this.paginator.pageIndex = 0;
    if (this.vendorsService.showOnlyParents) {
      this.dataSource.data = this.vendorsService.parentVendors;
    } else {
      this.dataSource.data = this.vendorsService.vendors;
    }
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  showChildren(vendor: Vendor) {
    const dialogRef = this.dialog.open(ChildVendorsDialog, {
      height: '80%',
      minWidth: '85vw',
      data: { vendor },
    });
  }
}
