import { PaginationAndSorting } from './pagination-and-sorting-model';
import { Supplier } from '../supplier-model';
import { MatChipsData } from '../mat-chips-data-model';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('SupplierAvailabilityFilters')
export class SupplierAvailabilityFilters extends PaginationAndSorting {
  @JsonProperty('ean', String, true) ean: string;
  @JsonProperty('supplierId', Number, true) supplierId: number;
  @JsonProperty('lotId', Number, true) lotId: number;
  @JsonProperty('excludedIds', [Number], true) excludedIds: number[];

  constructor() {
    super();
    this.pageNumber = 0;
    this.pageSize = 10;
  }

  copy(filters: SupplierAvailabilityFilters): SupplierAvailabilityFilters {
    this.ean = filters.ean;
    this.supplierId = filters.supplierId;
    this.lotId = filters.lotId;
    this.excludedIds = filters.excludedIds;
    return this;
  }
}

export const lsaFiltersMap = (ean?: string, supplier?: Supplier): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (ean !== null && ean !== undefined) {
    map.push(new MatChipsData('EAN', ean));
  }
  if (supplier !== null && supplier !== undefined) {
    map.push(new MatChipsData('Supplier', supplier.name));
  }
  return map;
};
