/* eslint-disable */
// These are needed to do some logic when updating statuses

export enum ShipmentStatusEnum {
  NEW = 'NEW',
  PREPARING = 'PREPARING',
  READY = 'READY',
  ASN_CREATED = 'ASN_CREATED',
  DDT_CREATED = 'DDT_CREATED',
  SHIPPED = 'SHIPPED',
  TRANSIT = 'TRANSIT',
  PARTIALLY_DELIVERED = 'PARTIALLY_DELIVERED',
  DELIVERED = 'DELIVERED',
  CANCELED = 'CANCELED',
  INCOMING = 'INCOMING',
  RETURNED = 'RETURNED',
}

export const SPO_CANCELED_STATUS = 6;
