import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';
import { ShipmentItem } from './shipment-item-model';

@JsonObject('CartonItem')
export class CartonItem extends BaseObject {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('boxQuantity', Number, true) boxQuantity: number = undefined;
  @JsonProperty('shipmentItem', ShipmentItem, true) shipmentItem: ShipmentItem = undefined;
}
