<app-pos-list-filters (onPoFilter)="applyFilter($event)"></app-pos-list-filters>

<div class="bulk-actions-div">
  <mat-toolbar class="bar-custom">
    <div class="container">
      <div class="row">
        <div class="col-md-2">
          <button class="box-shadow-none btn-custom" routerLink="/pos">Show POs</button>
        </div>
        <div class="col-md-8">
          <button
            class="box-shadow-none btn-custom margin-right-6"
            [disabled]="!bulkActionsActive"
            [matMenuTriggerFor]="exportPoItemsChoice"
          >
            Export PO-Items
          </button>
          <mat-menu #exportPoItemsChoice="matMenu">
            <button mat-menu-item (click)="exportPoItems('excel')">Save as Excel</button>
            <button mat-menu-item (click)="exportPoItems('csv')">Save as CSV</button>
          </mat-menu>
        </div>
      </div>
    </div>
  </mat-toolbar>
</div>

<app-pos-list-details-kpi></app-pos-list-details-kpi>

<div class="container outer-table">
  <mat-paginator #paginator [length]="resultsNumber | async" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()">
  </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <div>
        <ng-container matColumnDef="check" sticky>
          <mat-header-cell *matHeaderCellDef class="checker-col">
            <mat-checkbox (change)="toggleAll($event.checked)" [ngModel]="mainChecker"></mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let po; let i = index" class="checker-col">
            <mat-checkbox (change)="onSelected($event.checked, po)" [ngModel]="po.checked"></mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="poId" sticky>
          <mat-header-cell *matHeaderCellDef mat-sort-header="poId" class="po-code-column"> PO </mat-header-cell>
          <mat-cell *matCellDef="let poItem" class="po-code-column"
            ><a [routerLink]="['/pos', poItem.purchaseOrder.id]">{{ poItem.purchaseOrder.poId }}</a></mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="vendor">
          <mat-header-cell *matHeaderCellDef mat-sort-header="vendor" class="vendor-column"> Vendor </mat-header-cell>
          <mat-cell *matCellDef="let poItem" class="vendor-column">{{ poItem.purchaseOrder.vendor.code }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="parentVendor">
          <mat-header-cell *matHeaderCellDef mat-sort-header="parentVendor" class="vendor-column">
            Parent <br />
            Vendor
          </mat-header-cell>
          <mat-cell *matCellDef="let poItem" class="vendor-column"> {{ poItem.purchaseOrder.vendor?.parentVendor?.code }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="destination">
          <mat-header-cell *matHeaderCellDef mat-sort-header="destination" class="destination-column"> Destination </mat-header-cell>
          <mat-cell
            *matCellDef="let poItem"
            [matTooltip]="
              poItem.purchaseOrder.destination ? poItem.purchaseOrder.destination.code + ' - ' + poItem.purchaseOrder.destination.name : ''
            "
            matTooltipPosition="below"
            class="destination-column"
          >
            <p>
              {{
                poItem.purchaseOrder.destination
                  ? poItem.purchaseOrder.destination.code + ' - ' + poItem.purchaseOrder.destination.name
                  : ''
              }}
            </p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="orderedOn">
          <mat-header-cell *matHeaderCellDef mat-sort-header="orderedOn" class="date-column"> Ordered on </mat-header-cell>
          <mat-cell *matCellDef="let poItem" class="date-column">{{ poItem.purchaseOrder.orderedOn }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="earliestDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header="earliestDate" class="date-column">
            Delivery window <br />
            start
          </mat-header-cell>
          <mat-cell *matCellDef="let poItem" class="date-column">{{ poItem.purchaseOrder.earliestDate }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="latestDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header="latestDate" class="date-column">
            Delivery window <br />
            end
          </mat-header-cell>
          <mat-cell *matCellDef="let poItem" class="date-column">{{ poItem.purchaseOrder.latestDate }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef mat-sort-header="title" class="title-column"> Title </mat-header-cell>
          <mat-cell *matCellDef="let poItem" [matTooltip]="poItem.product.title" matTooltipPosition="below" class="title-column">
            <p>{{ poItem.product.title }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="category">
          <mat-header-cell *matHeaderCellDef mat-sort-header="category" class="category-column"> Category </mat-header-cell>
          <mat-cell
            *matCellDef="let poItem"
            [matTooltip]="poItem.product.category?.name"
            matTooltipPosition="below"
            class="category-column"
          >
            <p>{{ poItem.product.category?.name }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ean" sticky>
          <mat-header-cell *matHeaderCellDef mat-sort-header="ean" class="ean-column"> EAN </mat-header-cell>
          <mat-cell *matCellDef="let poItem" class="ean-column">
            <a [routerLink]="['/inventory/products', poItem.product.id, 'details']">{{ poItem.product.ean }}</a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sku">
          <mat-header-cell *matHeaderCellDef mat-sort-header="sku" class="sku-column"> SKU </mat-header-cell>
          <mat-cell class="sku-column" *matCellDef="let poItem" [matTooltip]="poItem.product.sku" matTooltipPosition="below">
            <p>{{ poItem.product.sku }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="brand">
          <mat-header-cell *matHeaderCellDef mat-sort-header="brand" class="category-column"> Brand </mat-header-cell>
          <mat-cell
            *matCellDef="let poItem"
            class="category-column"
            [matTooltip]="poItem.product.brand ? poItem.product.brand.name : ''"
            matTooltipPosition="below"
          >
            <p>{{ poItem.product.brand ? poItem.product.brand.name : '' }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="backorder">
          <mat-header-cell *matHeaderCellDef mat-sort-header="backorder" class="boolean-column"> Backorder </mat-header-cell>
          <mat-cell class="boolean-column" *matCellDef="let poItem">
            <p><i [ngClass]="poItem.backorder | booleanIconPipe" aria-hidden="true"></i></p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="expectedDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header="expectedDate" class="date-column"> Expected Date </mat-header-cell>
          <mat-cell *matCellDef="let poItem" class="date-column">{{ poItem.expectedDate }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="submittedQuantity">
          <mat-header-cell *matHeaderCellDef mat-sort-header="submittedQuantity" class="quantity-column-title">
            Submitted
            <br />
            Quantity
          </mat-header-cell>
          <mat-cell class="quantity-column" *matCellDef="let poItem">
            <p>{{ poItem.submittedQuantity }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="acceptedQuantity">
          <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="acceptedQuantity">
            Accepted <br />
            Quantity
          </mat-header-cell>
          <mat-cell class="quantity-column" *matCellDef="let poItem">
            <p>{{ poItem.acceptedQuantity }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastPoaQuantity">
          <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="lastPoaQuantity">
            Last POA <br />
            Quantity
          </mat-header-cell>
          <mat-cell class="quantity-column" *matCellDef="let poItem">
            <p>{{ poItem.lastPoaQuantity }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="obsoleteWithAccepted">
          <mat-header-cell class="bigger-boolean-column" *matHeaderCellDef> Obsolete with accepted</mat-header-cell>
          <mat-cell class="bigger-boolean-column" *matCellDef="let poItem">
            <p>
              <i [ngClass]="poItem.obsoleteWithAcceptedGreaterThanZero ? 'fa fa-check' : 'fa fa-times sel-icon'" aria-hidden="true"></i>
            </p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="unitCost">
          <mat-header-cell class="value-column-title-small" *matHeaderCellDef mat-sort-header="unitCost"> Unit Cost </mat-header-cell>
          <mat-cell class="value-column-small" *matCellDef="let poItem">
            <p>{{ poItem.unitCost | myCurrency }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="totalCost">
          <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="totalCost"> Total Cost </mat-header-cell>
          <mat-cell class="value-column" *matCellDef="let poItem">
            <p>{{ poItem.totalCost | myCurrency }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lotName">
          <mat-header-cell *matHeaderCellDef mat-sort-header="lotName" class="lot-name-column"> Lot </mat-header-cell>
          <mat-cell *matCellDef="let poItem" class="lot-name-column">
            <a
              *ngIf="poItem.lot"
              [routerLink]="['/lot-edit', poItem.lot.id, 'po']"
              [matTooltip]="poItem.lot ? poItem.lot.name : ''"
              matTooltipPosition="below"
              >{{ poItem.lot.name }}</a
            >
            <p *ngIf="!poItem.lot">None</p>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </div>
    </mat-table>
  </div>
</div>
