<div class="row">
  <div class="col-md-12">
    <mat-paginator
      #paginator
      [length]="resultsNumber | async"
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20, 50, 100]"
      (page)="loadPage()"
    ></mat-paginator>
  </div>
</div>

<div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
  <mat-table multiTemplateDataRows [dataSource]="dataSource" matSort (matSortChange)="onSort()">
    <ng-container matColumnDef="sscc">
      <mat-header-cell *matHeaderCellDef mat-sort-header="sscc" class="sscc-column"> SSCC </mat-header-cell>
      <mat-cell *matCellDef="let carton" class="sscc-column">
        <p>{{ carton.sscc }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="totalQuantity">
      <mat-header-cell *matHeaderCellDef mat-sort-header="totalQuantity" class="quantity-column-title"> Total quantity </mat-header-cell>
      <mat-cell *matCellDef="let carton" class="quantity-column">
        <p>{{ carton.totalQuantity }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="totalValue">
      <mat-header-cell *matHeaderCellDef mat-sort-header="totalValue" class="value-column-title"> Total value </mat-header-cell>
      <mat-cell *matCellDef="let carton" class="value-column">
        <p>{{ carton.totalValue | myCurrency }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="length">
      <mat-header-cell *matHeaderCellDef mat-sort-header="length" class="value-column-title"> Length </mat-header-cell>
      <mat-cell *matCellDef="let carton" class="value-column">
        <p>{{ carton.length?.value | myMeasure }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="height">
      <mat-header-cell *matHeaderCellDef mat-sort-header="height" class="value-column-title"> Height </mat-header-cell>
      <mat-cell *matCellDef="let carton" class="value-column">
        <p>{{ carton.height?.value | myMeasure }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="width">
      <mat-header-cell *matHeaderCellDef mat-sort-header="width" class="value-column-title"> Width </mat-header-cell>
      <mat-cell *matCellDef="let carton" class="value-column">
        <p>{{ carton.width?.value | myMeasure }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="grossWeight">
      <mat-header-cell *matHeaderCellDef mat-sort-header="grossWeight" class="value-column-title"> Gross Weight </mat-header-cell>
      <mat-cell *matCellDef="let carton" class="value-column">
        <p>{{ carton.grossWeight?.value | myMeasure }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <mat-header-cell *matHeaderCellDef mat-sort-header="createdAt" class="date-time-column"> Created at </mat-header-cell>
      <mat-cell *matCellDef="let carton" class="date-time-column">
        <p>{{ carton.createdAt }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="trackingNumber">
      <mat-header-cell *matHeaderCellDef mat-sort-header="trackingNumber" class="tracking-number-column"> Tracking Number </mat-header-cell>
      <mat-cell *matCellDef="let carton" class="tracking-number-column">
        <p>{{ carton.trackingNumber }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="printBarcode">
      <mat-header-cell *matHeaderCellDef> </mat-header-cell>
      <mat-cell *matCellDef="let carton">
        <p>
          <button
            class="box-shadow-none actions-button"
            [disabled]="!printBarcode"
            matTooltip="Print SSCC barcode"
            (click)="printSSCCBarcode(carton)"
          >
            <i class="fa fa-barcode margin-right-6" aria-hidden="true"></i> Barcode
          </button>
        </p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="printLabel">
      <mat-header-cell *matHeaderCellDef> </mat-header-cell>
      <mat-cell *matCellDef="let carton">
        <p>
          <button
            class="box-shadow-none actions-button"
            [disabled]="!carton.labelUrl"
            matTooltip="Print LDV label"
            (click)="printLDVLabel(carton)"
          >
            <i class="fa fa-tag margin-right-6" aria-hidden="true"></i> Label
          </button>
        </p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="printBarcodeAndLabel">
      <mat-header-cell *matHeaderCellDef> </mat-header-cell>
      <mat-cell *matCellDef="let carton">
        <p>
          <button
            class="box-shadow-none actions-button"
            [disabled]="!printBarcode || !carton.labelUrl"
            matTooltip="Print SSCC and LDV label"
            (click)="printSSCCAndLdvLabel(carton)"
          >
            <i class="fa fa-tag margin-right-6" aria-hidden="true"></i> SSCC + LDV
          </button>
        </p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="showDetails" stickyEnd>
      <mat-header-cell class="details-column" *matHeaderCellDef></mat-header-cell>
      <mat-cell class="details-column" *matCellDef="let carton">
        <p>
          <i
            [ngClass]="selectedRow === carton ? 'fa fa-chevron-circle-up' : 'fa fa-chevron-circle-down'"
            class="expand-icon"
            (click)="toggle(carton)"
          ></i>
        </p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <mat-cell class="background-light" *matCellDef="let carton" [attr.colspan]="displayedColumns.length">
        <div class="example-element-detail" [@detailExpand]="carton == selectedRow ? 'expanded' : 'collapsed'">
          <div class="row margin-lr-0 padding-top-10 padding-bottom-10 width-100per">
            <div class="col-md-12">
              <app-carton-items-table [cartonItems]="carton.cartonItems"></app-carton-items-table>
            </div>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns" [class.example-expanded-row]="selectedRow === row"></mat-row>

    <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="collapsed-detail-row"></mat-row>
    <mat-row *matRowDef="let row; columns: []" style="border: none; min-height: 0; max-height: 0"></mat-row>
  </mat-table>
</div>
