import { Component, OnInit } from '@angular/core';
import { isNilty } from '../../../../../../core/utils.service';
import { PaymentAdjustment } from '../../../../../../models/payment-adjustment';
import { Status } from '../../../../../../models/status-model';
import { PaymentRequestService } from '../../payment-request.service';

@Component({
  selector: 'app-add-adjustment-modal',
  templateUrl: './add-adjustment-modal.component.html',
  styleUrls: ['./add-adjustment-modal.component.css'],
})
export class AddAdjustmentModalComponent implements OnInit {
  constructor(private paymentRequestService: PaymentRequestService) {}

  types: Status[] = [];
  form = new PaymentAdjustment();

  ngOnInit() {
    this.paymentRequestService.getAdjustmentTypes().subscribe((types: Status[]) => (this.types = types));
  }

  isDisabled(): boolean {
    return isNilty(this.form.type) || isNilty(this.form.value);
  }
}
