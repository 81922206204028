<div class="container padding-top-15">
  <mat-expansion-panel class="ship-exp-panel" id="shipment-accordion">
    <mat-expansion-panel-header class="exp-panel-header">
      <mat-panel-title class="exp-panel-title">
        <div class="row" style="width: 100%;">
          <div class="col-md-4 exp-panel-label">
            Show packaging/tracking details
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="pack-properties-panel text-blue">
      <div class="row">
        <div class="col-md-1">
          <button
            *ngIf="hasShipmentManagement | async"
            class="box-shadow-none btn-edit-actions btn-fixed"
            matTooltip="Edit packaging informations"
            (click)="editPackaging()"
          >
            <i class="fa fa-edit sel-icon" aria-hidden="true"></i>
          </button>
        </div>

        <div class="col-md-2">
          <p><b>Number of pallets: </b> {{ shipment.numberOfPallets != null ? shipment.numberOfPallets : ' - ' }}</p>
        </div>

        <div class="col-md-2">
          <p>
            <b>Number of cartons: </b>
            {{ shipment.numberOfCartons != null || shipment.numberOfCartons == 0 ? shipment.numberOfCartons : ' - ' }}
          </p>
        </div>

        <div class="col-md-2">
          <p><b>Weight: </b> {{ shipment.weight != null || shipment.weight == 0.0 ? shipment.weight + ' Kg' : ' - ' }}</p>
        </div>

        <div class="col-md-2">
          <p><b>ASN code: </b> {{ shipment.asnCode ? shipment.asnCode : ' - ' }}</p>
        </div>

        <div class="col-md-2">
          <p><b>DDT number: </b> {{ shipment.ddtNumber ? shipment.ddtNumber : ' - ' }}</p>
        </div>
      </div>

      <hr class="small-margin-hr" />

      <div class="row">
        <div class="col-md-1">
          <button class="box-shadow-none btn-edit-actions btn-fixed" mattooltip="Edit carrier informations" (click)="editCarrier()">
            <i class="fa fa-edit sel-icon" aria-hidden="true"></i>
          </button>
        </div>

        <div class="col-md-2">
          <p>
            <b>Carrier contract: </b>
            {{ shipment.contract ? shipment.contract?.name : ' - ' }}
          </p>
        </div>

        <div class="col-md-2">
          <p>
            <b>Tracking number: </b>
            {{ shipment.trackingNumber || shipment.trackingNumber == '' ? shipment.trackingNumber : ' - ' }}
          </p>
        </div>

        <div class="col-md-2">
          <p>
            <b>Expected delivery date: </b>
            {{ shipment.expectedDeliveryDate ? (shipment.expectedDeliveryDate | myDate) : ' - ' }}
          </p>
        </div>

        <div class="col-md-2">
          <p><b>Delivery date: </b> {{ shipment.deliveryDate ? (shipment.deliveryDate | myDate) : ' - ' }}</p>
        </div>

        <div class="col-md-2" *ngIf="shipment.transitWarehouse">
          <p><b>Incoming date: </b> {{ shipment.incomingDate ? (shipment.incomingDate | myDate) : ' - ' }}</p>
        </div>
      </div>
      <div class="row" *ngIf="shipment.transitWarehouse">
        <div class="col-md-1"></div>
        <div class="col-md-2">
          <p><b>Preparation days: </b> {{ shipment.preparationDays ? shipment.preparationDays : ' - ' }}</p>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</div>
