import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inventory-menu',
  templateUrl: './inventory-menu.component.html',
  styleUrls: ['./inventory-menu.component.css'],
})
export class InventoryMenuComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
