import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { InventoryService } from '../inventory.service';
import { SupplierDiscount } from '../../../models/supplier-discount-model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { fromEvent, Observable } from 'rxjs';
import { isNilty } from '../../../core/utils.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { SupplierDiscountFilters } from '../../../models/filters/supplier-discount-filters';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-supplier-discount',
  templateUrl: './supplier-discount.component.html',
  styleUrls: ['./supplier-discount.component.css'],
})
export class SupplierDiscountComponent implements OnInit {
  constructor(
    private inventoryService: InventoryService,
    private router: Router,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar
  ) {}

  supplierId: number;

  dataSource: MatTableDataSource<SupplierDiscount>;

  filterText: string;

  displayedColumns = ['supplierName', 'supplierCode', 'productEan', 'discountedPrice', 'validFrom', 'validTo', 'valid'];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  @ViewChild('filterInput', { static: true }) filterInput: ElementRef;

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.resultsNumber = this.inventoryService.resultsNumber;
      if (this.inventoryService.supplierDiscountFilters === null) {
        this.inventoryService.supplierDiscountFilters = new SupplierDiscountFilters();
        this.inventoryService.supplierDiscountFilters.filter = null;
        this.inventoryService.supplierDiscountFilters.pageNumber = 0;
        this.inventoryService.supplierDiscountFilters.pageSize = 10;
        this.inventoryService.supplierDiscountFilters.sortBy = null;
        this.inventoryService.supplierDiscountFilters.sortDirection = null;
      }

      this.filterText = isNilty(this.inventoryService.supplierDiscountFilters.filter)
        ? ''
        : this.inventoryService.supplierDiscountFilters.filter;

      fromEvent(this.filterInput.nativeElement, 'keyup')
        .pipe(
          map((event: any) => event.target.value),
          debounceTime(1000),
          distinctUntilChanged()
        )
        .subscribe((text: string) => {
          this.filterText = text;
          this.inventoryService.supplierDiscountFilters.filter = text;
          this.inventoryService.supplierDiscountFilters.pageNumber = 0;
          this.loadSupplierDiscount();
        });

      this.dataSource = new MatTableDataSource<SupplierDiscount>([]);

      this.supplierId = +params['suppId'];
      this.loadSupplierDiscount();
    });
  }

  loadSupplierDiscount() {
    this.inventoryService
      .getSupplierDiscountFromSupplier(this.supplierId, this.inventoryService.supplierDiscountFilters)
      .subscribe((supplierDiscounts: SupplierDiscount[]) => {
        this.dataSource.data = supplierDiscounts;
      });
  }

  loadPage() {
    this.inventoryService.supplierDiscountFilters.pageNumber = this.paginator.pageIndex;
    this.inventoryService.supplierDiscountFilters.pageSize = this.paginator.pageSize;
    this.loadSupplierDiscount();
  }

  onSort() {
    this.inventoryService.supplierDiscountFilters.sortBy = this.sort.active;
    this.inventoryService.supplierDiscountFilters.sortDirection = this.sort.direction;
    this.loadSupplierDiscount();
  }
}
