import { Component, OnInit } from '@angular/core';
import { InventoryService } from '../../inventory.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GenericErrorModalComponent } from '../../../modal/generic-error-modal/generic-error-modal.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Supplier } from '../../../../models/supplier-model';
import { StockLocation } from '../../../../models/stock-location-model';
import { WarehousesService } from '../../../configuration/warehouses/warehouses.service';
import { GenericConfirmationModalComponent } from '../../../modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { isNilty } from '../../../../core/utils.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-stock-locations-edit',
  templateUrl: './stock-locations-edit.component.html',
  styleUrls: ['./stock-locations-edit.component.css'],
})
export class StockLocationsEditComponent implements OnInit {
  suppliers: Supplier[] = [];
  stockLocForm: FormGroup;

  stockLocId: number;
  currentStockLoc = new StockLocation();
  editMode = false;
  hasBeenSaved = false;

  constructor(
    private inventoryService: InventoryService,
    private route: ActivatedRoute,
    private warehousesService: WarehousesService,
    private router: Router,
    private dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit() {
    this.stockLocForm = new FormGroup({
      name: new FormControl('', Validators.required),
      code: new FormControl('', Validators.required),
      address: new FormControl(),
      province: new FormControl(''),
      city: new FormControl(''),
      postalCode: new FormControl('', Validators.required),
      countryCode: new FormControl('', Validators.required),
      supplier: new FormControl('', Validators.required),
      setByDefault: new FormControl(),
      transitWarehouse: new FormControl(),
      upsShipperNumber: new FormControl(),
      enableWarehouseFulfilment: new FormControl(),
    });

    this.route.params.subscribe((params: Params) => {
      this.stockLocId = +params['id'];
      this.editMode = params['id'] != null;
      this.hasBeenSaved = this.editMode;
      if (this.editMode) {
        this.getStockLocations();
      }
    });
    this.inventoryService.getAllSuppliers().subscribe((supp: Supplier[]) => {
      this.suppliers = supp;
    });
  }

  getStockLocations() {
    this.inventoryService.getStockLocationById(this.stockLocId).subscribe((stock: StockLocation) => {
      this.currentStockLoc = stock;
      this.changeFormValues(this.currentStockLoc);
      if (this.currentStockLoc.setByDefault) {
        this.stockLocForm.controls.supplier.disable();
        this.stockLocForm.controls.setByDefault.disable();
      }
    });
  }

  changeFormValues(stockLoc: StockLocation) {
    this.stockLocForm.setValue({
      name: stockLoc.name,
      code: stockLoc.code,
      address: stockLoc.address ? stockLoc.address : '',
      province: stockLoc.province ? stockLoc.province : '',
      city: stockLoc.city ? stockLoc.city : '',
      postalCode: stockLoc.postalCode ? stockLoc.postalCode : '',
      countryCode: stockLoc.countryCode ? stockLoc.countryCode : '',
      supplier: stockLoc.supplier.id,
      setByDefault: stockLoc.setByDefault ? stockLoc.setByDefault : false,
      transitWarehouse: stockLoc.transitWarehouse ? stockLoc.transitWarehouse : false,
      upsShipperNumber: stockLoc.upsShipperNumber ? stockLoc.upsShipperNumber : '',
      enableWarehouseFulfilment: stockLoc.enableWarehouseFulfilment ? stockLoc.enableWarehouseFulfilment : false,
    });
  }

  onCancel() {
    this.router.navigate(['inventory', 'stock-locations']);
  }

  onSubmit(exit: boolean) {
    if (this.editMode) {
      this.currentStockLoc.id = this.stockLocId;
    } else {
      this.currentStockLoc.id = null;
    }

    this.currentStockLoc.name = this.stockLocForm.controls.name.value;
    this.currentStockLoc.code = this.stockLocForm.controls.code.value;
    this.currentStockLoc.address = this.stockLocForm.controls.address.value;
    this.currentStockLoc.province = this.stockLocForm.controls.province.value;
    this.currentStockLoc.city = this.stockLocForm.controls.city.value;
    this.currentStockLoc.postalCode = this.stockLocForm.controls.postalCode.value;
    this.currentStockLoc.countryCode = this.stockLocForm.controls.countryCode.value;
    this.currentStockLoc.setByDefault = this.stockLocForm.controls.setByDefault.value
      ? this.stockLocForm.controls.setByDefault.value
      : false;
    this.currentStockLoc.transitWarehouse = this.stockLocForm.controls.transitWarehouse.value
      ? this.stockLocForm.controls.transitWarehouse.value
      : false;
    this.currentStockLoc.upsShipperNumber = this.stockLocForm.controls.upsShipperNumber.value;
    this.currentStockLoc.enableWarehouseFulfilment = this.stockLocForm.controls.enableWarehouseFulfilment.value;

    console.log(this.currentStockLoc);

    if (!isNilty(this.stockLocForm.controls.supplier.value)) {
      this.currentStockLoc.supplier = this.suppliers.find((it) => it.id === this.stockLocForm.controls.supplier.value);
    } else {
      this.currentStockLoc.supplier = null;
    }

    this.inventoryService.postStockLocation(this.currentStockLoc.toDTO()).subscribe((resp: StockLocation) => {
      this.currentStockLoc = resp;
      this.stockLocId = this.currentStockLoc.id;
      this.snackbar.open('Changes have been saved.', 'CLOSE')._dismissAfter(3000);
      this.hasBeenSaved = true;
      this.changeFormValues(resp);
      if (exit) {
        this.router.navigate(['inventory', 'stock-locations']);
      } else {
        this.getStockLocations();
      }
    });
  }

  onDelete() {
    let message = '';
    if (this.currentStockLoc.setByDefault) {
      message = 'This Stock Location is set by default and cannot be deleted.';
      this.dialog.open(GenericErrorModalComponent, {
        width: '350px',
        data: message,
      });
    } else {
      message = 'Deleting stock location ' + this.currentStockLoc.name;

      const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
        width: '400px',
        data: message,
      });

      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.inventoryService.deleteStockLocation(this.stockLocId).subscribe(() => {
            this.snackbar.open('Stock location has been deleted', 'CLOSE')._dismissAfter(3000);
            this.router.navigate(['inventory', 'stock-locations']);
          });
        }
      });
    }
  }
}
