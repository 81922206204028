import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Publication } from '../../../models/publication-model';
import { Observable } from 'rxjs';
import { PublicationFilters } from '../../../models/filters/publication-filters-model';
import { PublicationStatus, publicationStatusList } from '../../../models/publication-status';
import { addCheckers } from '../../../shared/checkers.service';
import { PublicationsService } from './publications.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ImportExportService } from '@shared/import-export.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ENTITIES } from 'src/app/entities';
import { PublicationApprovedHistoryComponent } from './publication-approved-history/publication-approved-history.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-publications',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.css'],
})
export class PublicationsComponent implements OnInit {
  constructor(
    private publicationsService: PublicationsService,
    private importExportService: ImportExportService,
    public snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  dataSource: MatTableDataSource<Publication>;
  currentFileUpload: File;

  displayedColumns = [
    'check',
    'vendorCode',
    'vendorCountry',
    'productEan',
    'title',
    'category',
    'brand',
    'amazonPrice',
    'newPrice',
    'publishedPrice',
    'purchasePrice',
    'status',
    'percentageDifference',
    'newPriceStatus',
    'pendingApprovalSince',
    'supplier',
    'suspendedAt',
    'lastPriceUpdate',
    'lastOrderDate',
    'newQuantity',
    'publishedQuantity',
    'email',
    'showDetails',
  ];

  mainChecker = false;
  bulkActionsActive = false;
  dataPublications: any[] = [];
  publicationStatus: PublicationStatus[];
  selectedRow: Publication;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('getFile', { static: false }) getFile: ElementRef;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  resultsNumber: Observable<number>;

  ngOnInit() {
    this.resultsNumber = this.publicationsService.resultsNumber;
    this.publicationStatus = publicationStatusList;

    if (this.publicationsService.publicationFilters === null) {
      this.publicationsService.publicationFilters = new PublicationFilters();
    }
    this.resetCheckers();
    this.dataSource = new MatTableDataSource([]);
    this.loadPublications(this.publicationsService.publicationFilters);
  }

  loadPublications(filters: PublicationFilters) {
    this.publicationsService.getFilteredPublications(filters).subscribe((publications: Publication[]) => {
      this.dataPublications = addCheckers(publications, this.publicationsService.selectedPublications, this.mainChecker);
      this.dataSource.data = this.dataPublications;
    });
  }

  loadPage() {
    this.publicationsService.publicationFilters.pageNumber = this.paginator.pageIndex;
    this.publicationsService.publicationFilters.pageSize = this.paginator.pageSize;
    this.loadPublications(this.publicationsService.publicationFilters);
  }

  onSort() {
    this.publicationsService.publicationFilters.sortBy = this.sort.active;
    this.publicationsService.publicationFilters.sortDirection = this.sort.direction;
    this.loadPublications(this.publicationsService.publicationFilters);
  }

  onSelected(value: boolean, selectedShipment: any) {
    const selectedIndex = this.dataPublications.indexOf(selectedShipment);
    this.dataPublications[selectedIndex].checked = value;
    if (value !== this.mainChecker) {
      this.publicationsService.addPublicationIdToSelected(this.dataPublications[selectedIndex].id);
    } else {
      this.publicationsService.removePublicationIdFromSelected(this.dataPublications[selectedIndex].id);
    }
    this.bulkActionsActive = value || this.mainChecker;
  }

  toggleAll(value: boolean) {
    this.mainChecker = value;
    this.publicationsService.publicationMainChecker = this.mainChecker;
    for (const i of this.dataPublications) {
      i.checked = value;
    }

    this.publicationsService.selectedPublications = [];
    this.bulkActionsActive = value;
  }

  onFilter(filters) {
    this.paginator.pageIndex = 0;
    this.resetCheckers();
    this.loadPublications(filters);
  }

  resetCheckers() {
    this.publicationsService.selectedPublications = [];
    this.mainChecker = false;
    this.bulkActionsActive = false;
  }

  changeStatus(status: number) {
    const mainChecker = this.mainChecker;

    this.publicationsService
      .changeStatus(status, this.publicationsService.selectedPublications, this.publicationsService.publicationFilters, mainChecker)
      .subscribe(() => {
        this.onFilter(this.publicationsService.publicationFilters);
      });
  }

  changeNewPriceStatus(status: string) {
    const mainChecker = this.mainChecker;

    this.publicationsService
      .changeNewPriceStatus(status, this.publicationsService.selectedPublications, this.publicationsService.publicationFilters, mainChecker)
      .subscribe(() => {
        this.onFilter(this.publicationsService.publicationFilters);
      });
  }

  export(format: string) {
    this.importExportService
      .exportPendingApprovaPublications(
        this.publicationsService.selectedPublications,
        format,
        this.mainChecker,
        this.publicationsService.publicationFilters
      )
      .subscribe((resp) => {
        console.log(resp);
      });
  }

  importFile() {
    window.alert(
      `---------------------------- ATTENTION ------------------------------
      \nselect a file with
      \nFormat: .csv\nTemplate: Vendor Code; Product Ean; New Price; New Price Status`
    );
    this.getFile.nativeElement.click();
  }

  fileChanged(event) {
    this.currentFileUpload = event.target.files[0];
    const substring: any[] = this.currentFileUpload.name.split('.');
    const extension: string = substring[substring.length - 1].toLowerCase();

    if (extension === 'csv') {
      this.snackBar.open('Uploading', '')._dismissAfter(3000);
      this.importExportService.uploadFile(this.currentFileUpload, ENTITIES.UPDATE_PENDING_APPROVAL_PUBLICATION).subscribe(() => {});
    } else {
      this.snackBar.open('Wrong File Extension. Allowed format .csv')._dismissAfter(4000);
    }
  }

  showApprovedHistory(publication: Publication) {
    const dialogRef = this.dialog.open(PublicationApprovedHistoryComponent, {
      height: '50%',
      minWidth: '55vw',
      data: { publication },
    });
  }
}
