<div class="container shipment-kpi-container">
  <div class="col-md-4 background-blue text-white font-row padding-top-15">
    <p class="key-table-fixed">
      <b class="margin-right-6"> Order ID: </b>
      {{ shipment.orderId }}
    </p>
    <p class="key-table-fixed">
      <b class="margin-right-6"> Status: </b>
      {{ shipment.status?.name }}
      <b class="margin-left-20">Cross UK border: </b>
      <i [ngClass]="shipment?.crossUkBorder | booleanIconPipe" aria-hidden="true"></i>
      <b class="margin-left-20">Extra: </b>
      <i [ngClass]="shipment?.isExtra | booleanIconPipe" aria-hidden="true"></i>
      <b class="margin-left-20"> ASN sent: </b>
      <i [ngClass]="shipment?.sentAsn ? 'fa fa-check' : 'fa fa-times sel-icon'" aria-hidden="true"></i>
    </p>
    <p class="key-table-fixed">
      <b class="margin-right-6"> Payment Status: </b>
      {{ shipment.paymentStatus?.description }}
    </p>
    <p class="key-table-fixed">
      <b class="margin-right-6"> Outbound Status: </b>
      {{ shipment.outboundStatus }}
    </p>
    <p class="key-table-fixed">
      <b class="margin-right-6"> Origin: </b>
      {{ shipment.origin ? shipment.origin.code + ' - ' + shipment.origin.name : ' - ' }}
      <i class="fa fa-edit edit-transit-warehouse" *ngIf="shipment?.status?.value <= 5" (click)="setOrigin()"></i>
    </p>
    <p class="key-table-fixed">
      <b class="margin-right-6"> Transit warehouse: </b>
      {{ shipment.transitWarehouse ? shipment.transitWarehouse.code + ' - ' + shipment.transitWarehouse.name : ' - ' }}
      <i class="fa fa-edit edit-transit-warehouse" (click)="setTransitWarehouse()"></i>
    </p>
    <p class="key-table-fixed">
      <b class="margin-right-6"> Supplier sends SSCC: </b>
      <i [ngClass]="shipment?.origin?.supplier?.sendSSCC ? 'fa fa-check' : 'fa fa-times sel-icon'" aria-hidden="true"></i>
    </p>
    <p class="key-table-fixed">
      <b class="margin-right-6"> Destination: </b>
      {{ shipment.destination ? shipment.destination.code + ' - ' + shipment.destination.name : ' - ' }}
    </p>
    <p class="key-table-fixed">
      <b class="margin-right-6"> Vendor: </b>
      {{ shipment.vendor ? shipment.vendor.code + ' - ' + shipment.vendor.name : ' - ' }}
    </p>
    <p class="key-table-fixed"><b class="margin-right-6"> Shipped at: </b> {{ shipment.shipmentDate ? shipment.shipmentDate : ' - ' }}</p>
    <p class="key-table-fixed">
      <b class="margin-right-6"> Latest ready date: </b>
      {{ shipment.latestReadyDate ? shipment.latestReadyDate : ' - ' }}
    </p>
    <p>
      <b class="margin-right-6"> Delivery window: </b>
      {{ shipment.latestDeliveryDate ? shipment.latestDeliveryDate : '' }} -
      {{ shipment.earliestDeliveryDate ? shipment.earliestDeliveryDate : '' }}
    </p>
    <p class="key-table-fixed">
      <b class="margin-right-6"> Backorder: </b>
      <i [ngClass]="shipment.backorder ? 'fa fa-check' : 'fa fa-times sel-icon'" aria-hidden="true"></i>
    </p>
    <p class="key-table-fixed">
      <b class="margin-right-6"> Uk Interco Invoice: </b>
      <i [ngClass]="shipment.ukIntercoInvoice ? 'fa fa-check' : 'fa fa-times sel-icon'" aria-hidden="true"></i>
      <ng-container *ngIf="shipment.ukIntercoInvoice">
        <b class="margin-left-20"> Valid Data: </b>
        <i [ngClass]="shipment.ukIntercoInvoiceValidData ? 'fa fa-check' : 'fa fa-times sel-icon'" aria-hidden="true"></i>
        <b class="margin-left-20"> Status: </b>
        {{ shipment.ukIntercoInvoiceStatus.description }}
      </ng-container>
    </p>
    <p class="key-table-fixed">
      <b class="margin-right-6"> Sent to supplier: </b>
      <i [ngClass]="shipment?.documentsSentToSupplier ? 'fa fa-check' : 'fa fa-times sel-icon'" aria-hidden="true"></i>
    </p>
  </div>

  <div class="col-md-2 background-light-blue text-white font-row">
    <h4 class="h-padding-box">
      <b>Submitted</b>
    </h4>
    <p><b class="margin-right-6">Quantity: </b> {{ (kpiData | async)?.submittedQuantity }}</p>
    <p><b class="margin-right-6">Value: </b> {{ (kpiData | async)?.submittedValue | myCurrency }}</p>
  </div>

  <div class="col-md-2 background-blue text-white font-row">
    <h4 class="h-padding-box">
      <b>Requested</b>
    </h4>
    <p><b class="margin-right-6">Quantity: </b> {{ (kpiData | async)?.requestedQuantity }}</p>
    <p><b class="margin-right-6">Value: </b> {{ (kpiData | async)?.requestedValue | myCurrency }}</p>
  </div>

  <div class="col-md-2 background-light-blue text-white font-row">
    <h4 class="h-padding-box">
      <b>Accepted</b>
    </h4>
    <p><b class="margin-right-6">Quantity: </b> {{ (kpiData | async)?.acceptedQuantity }}</p>
    <p><b class="margin-right-6"> Value: </b> {{ (kpiData | async)?.acceptedValue | myCurrency }}</p>
  </div>

  <div class="col-md-2 background-blue text-white font-row">
    <h4 class="h-padding-box">
      <b>Shipped</b>
    </h4>
    <p><b class="margin-right-6">Quantity: </b> {{ (kpiData | async)?.shippedQuantity }}</p>
    <p><b class="margin-right-6"> Value: </b> {{ (kpiData | async)?.shippedValue | myCurrency }}</p>

    <h4 class="h-padding-box">
      <b class="margin-right-6 modal-link" (click)="showSupplierClaimDetails()"> Supplier claims: </b>
    </h4>
    <p class="key-table-fixed">Quantity: {{ showSupplierClaimQuantity() }}</p>
    <p class="key-table-fixed">Value: {{ showSupplierClaimValue() | myCurrency }}</p>
  </div>
</div>
