import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContainerComponent } from './container/container.component';
import { LotsListComponent } from './container/main-section/lots-list/lots-list.component';
import { PosListComponent } from './container/main-section/main-pos/pos-list/pos-list.component';
import { MainPosComponent } from './container/main-section/main-pos/main-pos.component';
import { SinglePoDetailsComponent } from './shared/single-po-details/single-po-details.component';
import { PosListDetailsComponent } from './container/main-section/main-pos/pos-list-details/pos-list-details.component';
import { AppPreloadingStrategy } from './app-loading-strategy';
import { AdminAuthGuardService } from './security/admin-auth-guard.service';
import { MainSposModule } from './container/main-section/main-spos/main-spos.module';
import { MainShipmentsModule } from './container/main-section/main-shipments/main-shipments.module';
import { MainRemittancesModule } from './container/main-section/main-remittances/main-remittances.module';
import { InventoryModule } from './container/inventory/inventory.module';
import { LotEditModule } from './container/lot-edit-section/lot-edit.module';
import { ConfigurationModule } from './container/configuration/configuration.module';
import { MainInvoicesModule } from './container/main-section/main-invoices/main-invoices.module';
import { MainDeductionsModule } from './container/main-section/main-deductions/main-deductions.module';
import { MainPaymentsModule } from './container/main-section/main-payments/main-payments.module';

const routes: Routes = [
  {
    path: '',
    component: ContainerComponent,
    children: [
      { path: 'lots', component: LotsListComponent },
      {
        path: 'pos',
        component: MainPosComponent,
        children: [
          { path: '', component: PosListComponent, pathMatch: 'full' },
          { path: 'details', component: PosListDetailsComponent },
          { path: ':id', component: SinglePoDetailsComponent },
        ],
      },
      {
        path: 'spos',
        loadChildren: () => import('./container/main-section/main-spos/main-spos.module').then((m) => MainSposModule),
        data: { preload: true },
      },
      {
        path: 'remittances',
        loadChildren: () => import('./container/main-section/main-remittances/main-remittances.module').then((m) => MainRemittancesModule),
      },
      {
        path: 'inventory',
        loadChildren: () => import('./container/inventory/inventory.module').then((m) => InventoryModule),
        data: { preload: true },
      },
      {
        path: 'shipments',
        loadChildren: () => import('./container/main-section/main-shipments/main-shipments.module').then((m) => MainShipmentsModule),
        data: { preload: true },
      },
      {
        path: 'lot-edit/:id',
        loadChildren: () => import('./container/lot-edit-section/lot-edit.module').then((m) => LotEditModule),
        data: { preload: true },
      },
      {
        path: 'configuration',
        loadChildren: () => import('./container/configuration/configuration.module').then((m) => ConfigurationModule),
        canActivate: [AdminAuthGuardService],
      },
      {
        path: 'invoices',
        loadChildren: () => import('./container/main-section/main-invoices/main-invoices.module').then((m) => MainInvoicesModule),
        data: { preload: false },
      },
      {
        path: 'deductions',
        loadChildren: () => import('./container/main-section/main-deductions/main-deductions.module').then((m) => MainDeductionsModule),
        data: { preload: false },
      },
      {
        path: 'payments',
        loadChildren: () => import('./container/main-section/main-payments/main-payments.module').then((m) => MainPaymentsModule),
        data: { preload: false },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: AppPreloadingStrategy, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [AdminAuthGuardService],
})
export class AppRoutingModule {}
