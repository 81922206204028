import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from 'json2typescript';
import { isNil } from '../core/utils.service';

@JsonObject('SupplierPaymentTerms')
export class SupplierPaymentTerms {
  @JsonProperty('code', String, false) code: string = undefined;
  @JsonProperty('description', String, false) description: string = undefined;
  @JsonProperty('value', Number, false) value: number = undefined;
  @JsonProperty('percentage', Number, false) percentage: number = undefined;
}

@JsonConverter
export class SupplierPaymentTermsConverter implements JsonCustomConvert<SupplierPaymentTerms> {
  jsonConvert: JsonConvert;

  constructor() {
    this.jsonConvert = new JsonConvert();
  }

  serialize(status: SupplierPaymentTerms): string {
    return !isNil(status) ? status.code : undefined;
  }

  deserialize(value: string): SupplierPaymentTerms {
    return this.jsonConvert.deserialize(value, SupplierPaymentTerms) as SupplierPaymentTerms;
  }
}
