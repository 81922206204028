import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { Status } from '../../../../../models/status-model';
import { InventoryService } from '../../../../../container/inventory/inventory.service';
import { PaymentDocument } from '../../../../../models/payment-document-model';
import { Supplier } from '../../../../../models/supplier-model';
import { PaymentDocumentService } from '../payment-document.service';
import { ShipmentsService } from '../../../../../shared/shipments.service';
import { Shipment } from '../../../../../models/shipment-model';
import { getBase64EncodedFileData } from '../../../../../core/utils.service';
import { MatDialog } from '@angular/material/dialog';
import { GenericConfirmationModalComponent } from '../../../../modal/generic-confirmation-modal/generic-confirmation-modal.component';

@Component({
  selector: 'app-payment-document-edit',
  templateUrl: './payment-document-edit.component.html',
  styleUrls: ['./payment-document-edit.component.css'],
})
export class PaymentDocumentEditComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private inventoryService: InventoryService,
    private paymentDocumentService: PaymentDocumentService,
    private shipmentService: ShipmentsService,
    private location: Location,
    private dialog: MatDialog
  ) {}

  @ViewChild('getDocument', { static: true }) getDocument: ElementRef;
  paymentDocumentForm: FormGroup;
  editMode = false;
  paymentDocumentId: number;
  currentPaymentDocument = new PaymentDocument();
  suppliers: Supplier[];
  documentTypes: Status[];
  shipments: Shipment[];
  document: File;

  ngOnInit() {
    this.paymentDocumentForm = new FormGroup({
      documentNumber: new FormControl('', Validators.required),
      supplier: new FormControl('', Validators.required),
      documentDate: new FormControl('', Validators.required),
      documentType: new FormControl('', Validators.required),
      value: new FormControl(null, Validators.required),
      vat: new FormControl(null, Validators.required),
      quantity: new FormControl(null, Validators.required),
      shipments: new FormControl(null),
    });

    this.inventoryService.getAllSuppliers().subscribe((suppliers: Supplier[]) => {
      this.suppliers = suppliers;
      this.setSupplier();
    });

    this.paymentDocumentService.getPaymentDocumentTypes().subscribe((documentTypes: Status[]) => {
      this.documentTypes = documentTypes;
      this.setDocumentType();
    });

    this.route.params.subscribe((params: Params) => {
      this.paymentDocumentId = +params['id'];
      this.editMode = params['id'] != null;
      if (this.editMode) {
        this.paymentDocumentService.getPaymentDocumentById(this.paymentDocumentId).subscribe((paymentDocument: PaymentDocument) => {
          this.currentPaymentDocument = paymentDocument;
          this.changeFormValues(this.currentPaymentDocument);
        });
      }
    });
  }

  setSupplier() {
    if (this.currentPaymentDocument.supplier) {
      this.paymentDocumentForm.controls['supplier'].patchValue(
        this.suppliers.find((it) => it.id === this.currentPaymentDocument.supplier.id)
      );
      this.changeSupplier();
    }
  }

  setDocumentType() {
    if (this.currentPaymentDocument.documentType) {
      this.paymentDocumentForm.controls['documentType'].patchValue(
        this.documentTypes.find((it) => it.code === this.currentPaymentDocument.documentType.code)
      );
    }
  }

  changeSupplier() {
    const supplier = this.paymentDocumentForm.controls.supplier.value;
    if (supplier) {
      this.shipmentService.getShipmentsBySupplierAndPaymentStatusPending(supplier.id).subscribe((shipments: Shipment[]) => {
        this.shipments = shipments;
        this.paymentDocumentForm.controls['shipments'].patchValue([]);
      });
    }
  }

  changeFormValues(paymentDocument: PaymentDocument) {
    this.paymentDocumentForm.setValue({
      documentNumber: paymentDocument.documentNumber,
      supplier: paymentDocument.supplier,
      documentType: paymentDocument.documentType,
      documentDate: paymentDocument.documentDate,
      value: paymentDocument.value,
      vat: paymentDocument.vat,
      quantity: paymentDocument.quantity,
      shipments: paymentDocument.shipments,
    });
  }

  onCancel() {
    this.location.back();
  }

  onSubmit() {
    getBase64EncodedFileData(this.document).subscribe((file) => {
      if (this.editMode) {
        this.currentPaymentDocument.id = this.paymentDocumentId;
      } else {
        this.currentPaymentDocument.id = null;
      }
      this.currentPaymentDocument.documentNumber = this.paymentDocumentForm.controls.documentNumber.value;
      this.currentPaymentDocument.supplier = this.paymentDocumentForm.controls.supplier.value;
      this.currentPaymentDocument.documentType = this.paymentDocumentForm.controls.documentType.value;
      this.currentPaymentDocument.documentDate = this.paymentDocumentForm.controls.documentDate.value;
      this.currentPaymentDocument.value = this.paymentDocumentForm.controls.value.value;
      this.currentPaymentDocument.vat = this.paymentDocumentForm.controls.vat.value;
      this.currentPaymentDocument.quantity = this.paymentDocumentForm.controls.quantity.value;
      this.currentPaymentDocument.shipments = this.paymentDocumentForm.controls.shipments.value;

      const shipmentIds = this.currentPaymentDocument.shipments.map((s) => s.id);

      this.paymentDocumentService.checkShipments(shipmentIds).subscribe((exists) => {
        if (!exists) {
          this.checkPaymentTerms(file);
        } else {
          const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
            width: '350px',
            data: 'One or more shipments are already present in another payment document.',
          });

          dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
              this.checkPaymentTerms(file);
            }
          });
        }
      });
    });
  }

  checkPaymentTerms(file) {
    if (this.currentPaymentDocument.supplier.paymentTerms.value > 7) {
      const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
        width: '350px',
        data: 'This supplier has payment terms greater than 7 days.',
      });

      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.savePaymentDocument(file);
        }
      });
    } else {
      this.savePaymentDocument(file);
    }
  }

  savePaymentDocument(file) {
    this.paymentDocumentService.savePaymentDocument(this.currentPaymentDocument, file).subscribe(() => {
      this.location.back();
    });
  }

  uploadDocumentFake() {
    this.getDocument.nativeElement.click();
  }

  uploadDocument(event) {
    this.document = event.target.files[0];
  }
}
