import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SupplierClaim } from '../../../../models/supplier-claim-model';

@Component({
  selector: 'app-supplier-claim-detail-modal',
  templateUrl: './supplier-claim-detail-modal.component.html',
  styleUrls: ['./supplier-claim-detail-modal.component.css'],
})
export class SupplierClaimDetailModalComponent implements OnInit {
  supplierClaims: SupplierClaim[] = [];

  constructor(
    public dialogRef: MatDialogRef<SupplierClaimDetailModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { supplierClaims: SupplierClaim[] }
  ) {}

  ngOnInit() {
    this.supplierClaims = this.data.supplierClaims;
  }

  close() {
    this.dialogRef.close();
  }

  getQuantity(type: string): number {
    return this.supplierClaims.reduce((value, sc) => {
      if (sc.type.startsWith(type)) {
        return value + sc.quantity;
      }
      return value;
    }, 0);
  }

  getValue(type: string): number {
    return this.supplierClaims.reduce((value, sc) => {
      if (sc.type.startsWith(type)) {
        return value + sc.value;
      }
      return value;
    }, 0);
  }
}
