import { Component, Input, ViewChild } from '@angular/core';
import { StockItem } from '../../../../../../models/stock-item-model';
import { compareNumberOrString, isNilty } from '../../../../../../core/utils.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-stock-items-table',
  templateUrl: './stock-items-table.component.html',
  styleUrls: ['./stock-items-table.component.css'],
})
export class StockItemsTableComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() set stockItems(values: StockItem[]) {
    if (!isNilty(values)) {
      this.dataSource = new MatTableDataSource(values);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  dataSource: MatTableDataSource<StockItem>;
  displayedColumns = ['stockLocation', 'physicalQuantity', 'availableQuantity'];

  constructor() {}

  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        default:
          return compareNumberOrString(a[sort.active], b[sort.active], isAsc);
      }
    });
  }
}
