import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LogDiscardedValueFilters } from '../../models/filters/log-discarded-value-filters-model';
import { isNilty } from '../../core/utils.service';
import { FulfilmentFilters } from '../../models/filters/fulfilment-filters-model';
import { PoFilters } from '../../models/filters/po-filters-model';
import { ShipmentFilters } from '../../models/filters/shipment-filters-model';
import { setFiltersFromForm, SpoFilters } from '../../models/filters/spo-filters-model';
import { SupplierAvailabilityFilters } from '../../models/filters/suppliers-availability-filters-model';

@Injectable()
export class LotEditService {
  poFilters: PoFilters = null;
  selectedPos: number[] = [];
  posMainChecker = false;

  selectedPoItems: number[] = [];
  poItemsMainChecker = false;

  fulfilmentFilters: FulfilmentFilters = undefined;
  selectedFulfilments: number[] = [];
  fulfilmentsMainChecker = false;

  spoFilters: SpoFilters = undefined;
  selectedGroupedSpoItems: number[] = [];
  selectedSpoItems: number[] = [];
  spoItemsMainChecker = false;
  selectedSpos: number[] = [];
  sposMainChecker = false;

  lsaFilters: SupplierAvailabilityFilters = undefined;
  selectedLsas: number[] = [];

  shipmentFilters: ShipmentFilters = undefined;
  selectedShipments: number[] = [];
  shipmentMainChecker = false;

  selectedShipmentItems: number[] = [];
  shipmentItemsMainChecker = false;

  logDiscardedValueFilters: LogDiscardedValueFilters = undefined;

  initPoFilters(lotId: number) {
    if (isNilty(this.poFilters)) {
      this.poFilters = new PoFilters();
      this.poFilters.lotId = lotId;
    }
  }

  addPoIdToSelected(id: number) {
    this.selectedPos.push(id);
    this.selectedPos.filter((v, i, a) => a.indexOf(v) === i);
  }
  removePoIdFromSelected(id: number) {
    const index = this.selectedPos.indexOf(id);
    this.selectedPos.splice(index, 1);
  }

  addPoItemIdToSelected(id: number) {
    this.selectedPoItems.push(id);
    this.selectedPoItems.filter((v, i, a) => a.indexOf(v) === i);
  }
  removePoItemIdFromSelected(id: number) {
    const index = this.selectedPoItems.indexOf(id);
    this.selectedPoItems.splice(index, 1);
  }

  addFulfilmentIdToSelected(id: number) {
    this.selectedFulfilments.push(id);
    this.selectedFulfilments.filter((v, i, a) => a.indexOf(v) === i);
  }
  removeFulfilmentIdFromSelected(id: number) {
    const index = this.selectedFulfilments.indexOf(id);
    this.selectedFulfilments.splice(index, 1);
  }

  addSpoItemIdToSelected(groupId: number, spoItemsIds: number[]) {
    this.selectedSpoItems.push(...spoItemsIds);
    this.selectedGroupedSpoItems.push(groupId);
    this.selectedGroupedSpoItems.filter((v, i, a) => a.indexOf(v) === i);
    this.selectedSpoItems.filter((v, i, a) => a.indexOf(v) === i);
  }
  removeSpoItemIdFromSelected(groupId: number, spoItemsIds: number[]) {
    const groupedIndex = this.selectedGroupedSpoItems.indexOf(groupId);
    this.selectedGroupedSpoItems.splice(groupedIndex, 1);
    spoItemsIds.forEach((id: number) => {
      const index = this.selectedSpoItems.indexOf(id);
      this.selectedGroupedSpoItems.splice(index, 1);
    });
  }

  addSpoIdToSelected(id: number) {
    this.selectedSpos.push(id);
    this.selectedSpos.filter((v, i, a) => a.indexOf(v) === i);
  }
  removeSpoIdFromSelected(id: number) {
    const index = this.selectedSpos.indexOf(id);
    this.selectedSpos.splice(index, 1);
  }

  addLsaIdToSelected(id: number) {
    this.selectedLsas.push(id);
    this.selectedLsas.filter((v, i, a) => a.indexOf(v) === i);
  }
  removeLsaIdFromSelected(id: number) {
    const index = this.selectedLsas.indexOf(id);
    this.selectedLsas.splice(index, 1);
  }

  addShipmentIdToSelected(id: number) {
    this.selectedShipments.push(id);
    this.selectedShipments.filter((v, i, a) => a.indexOf(v) === i);
  }
  removeShipmentIdFromSelected(id: number) {
    const index = this.selectedShipments.indexOf(id);
    this.selectedShipments.splice(index, 1);
  }

  addShipmentItemIdToSelected(id: number) {
    this.selectedShipmentItems.push(id);
    this.selectedShipmentItems.filter((v, i, a) => a.indexOf(v) === i);
  }
  removeShipmentItemIdFromSelected(id: number) {
    const index = this.selectedShipmentItems.indexOf(id);
    this.selectedShipmentItems.splice(index, 1);
  }

  resetAllFilters() {
    this.poFilters = null;
    this.selectedPos = [];
    this.posMainChecker = false;

    this.selectedPoItems = [];
    this.poItemsMainChecker = false;

    this.fulfilmentFilters = undefined;
    this.selectedFulfilments = [];
    this.fulfilmentsMainChecker = false;

    this.spoFilters = undefined;
    this.selectedSpoItems = [];
    this.spoItemsMainChecker = false;
    this.shipmentFilters = undefined;

    this.selectedShipments = [];
    this.shipmentMainChecker = false;
    this.selectedShipmentItems = [];
    this.lsaFilters = null;
    this.selectedLsas = [];
  }

  saveSpoFiltersToService(filterForm: FormGroup) {
    setFiltersFromForm(filterForm, this.spoFilters);
  }

  saveShipmentFiltersToService(filterForm: FormGroup) {
    this.shipmentFilters.shipment = filterForm.value.shipment;
    this.shipmentFilters.asnCode = filterForm.value.asnCode;
    this.shipmentFilters.ean = filterForm.value.ean;
    this.shipmentFilters.supplierId = isNilty(filterForm.value.supplier) ? null : filterForm.value.supplier.id;
    this.shipmentFilters.originId = isNilty(filterForm.value.origin) ? null : filterForm.value.origin.id;
    this.shipmentFilters.po = isNilty(filterForm.value.po) ? null : filterForm.value.po.split('\n');
    this.shipmentFilters.vendorId = filterForm.value.vendor ? filterForm.value.vendor.id : null;
    this.shipmentFilters.destinationId = isNilty(filterForm.value.destination) ? null : filterForm.value.destination.id;
    this.shipmentFilters.status = filterForm.value.status;
    this.shipmentFilters.paymentStatus = isNilty(filterForm.value.paymentStatus) ? null : filterForm.value.paymentStatus.code;
    this.shipmentFilters.payment = filterForm.value.payment;
    this.shipmentFilters.lotId = isNilty(filterForm.value.lot) ? null : filterForm.value.lot.id;
    this.shipmentFilters.orderIds = isNilty(filterForm.value.orderIds) ? null : filterForm.value.orderIds.split('\n');
    this.shipmentFilters.carrierContracts = isNilty(filterForm.value.carrierContracts)
      ? null
      : filterForm.value.carrierContracts.map((c) => c.id);
    this.shipmentFilters.trackingNumber = filterForm.value.trackingNumber;
    this.shipmentFilters.latestPickupDateFrom = filterForm.value.latestPickupDateFrom;
    this.shipmentFilters.latestPickupDateTo = filterForm.value.latestPickupDateTo;
    this.shipmentFilters.noInvoice = filterForm.value.noInvoice;
    this.shipmentFilters.crossUkBorder = filterForm.value.crossUkBorder;
    this.shipmentFilters.ukIntercoInvoice = filterForm.value.ukIntercoInvoice;
    this.shipmentFilters.ukIntercoInvoiceValidData = filterForm.value.ukIntercoInvoiceValidData;
    this.shipmentFilters.ukIntercoInvoiceStatus = filterForm.value.ukIntercoInvoiceStatus;
    this.shipmentFilters.isAdr = filterForm.value.isAdr;
    this.shipmentFilters.documentsSentToSupplier = filterForm.value.documentsSentToSupplier;
    this.shipmentFilters.categoryId = filterForm.value.category ? filterForm.value.category.id : null;
    this.shipmentFilters.outboundStatus = filterForm.value.outboundStatus;
    this.shipmentFilters.shippedAtFrom = null;
    this.shipmentFilters.shippedAtTo = null;
    this.shipmentFilters.lotFrom = null;
    this.shipmentFilters.lotTo = null;
    this.shipmentFilters.pageNumber = null;
    this.shipmentFilters.pageSize = null;
    this.shipmentFilters.sortDirection = null;
    this.shipmentFilters.sortBy = null;
  }

  savePoFiltersToService(filterForm: FormGroup, lotId: number) {
    this.poFilters = new PoFilters();

    this.poFilters.lotId = lotId;

    this.poFilters.vendors = !isNilty(filterForm.value.vendors) ? filterForm.value.vendors.map((it) => it.id) : null;
    this.poFilters.parent = !isNilty(filterForm.value.parent) ? filterForm.value.parent.id : null;
    this.poFilters.lotDate = null;
    this.poFilters.eans = filterForm.value.eans;
    this.poFilters.purchaseOrder = filterForm.value.purchaseOrder;
    this.poFilters.from = filterForm.value.from;
    this.poFilters.to = filterForm.value.to;
    this.poFilters.destinationId = !isNilty(filterForm.value.destination) ? filterForm.value.destination.id : null;
    this.poFilters.categoryId = !isNilty(filterForm.value.category) ? filterForm.value.category.id : null;
    this.poFilters.status = filterForm.value.status;
    this.poFilters.hasLot = null;
    this.poFilters.brand = !isNilty(filterForm.value.brand) ? filterForm.value.brand.name : null;
    this.poFilters.acceptedDifferentFromPoa = filterForm.value.acceptedDifferentFromPoa ? true : null;
    this.poFilters.obsoleteWithAccepted = filterForm.value.obsoleteWithAccepted ? true : null;
    this.poFilters.isAdr = filterForm.value.isAdr ? true : null;

    this.poFilters.latestDeliveryDateFrom = filterForm.value.latestDeliveryDateFrom;
    this.poFilters.latestDeliveryDateTo = filterForm.value.latestDeliveryDateTo;
  }
}
