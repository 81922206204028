<app-claims-list-filters (onFilter)="onFilter($event)"></app-claims-list-filters>

<div>
  <mat-toolbar class="bar-custom">
    <div class="container">
      <div class="row">
        <div class="col-md-2">
          <button class="box-shadow-none btn-custom" [routerLink]="['/deductions/claims']">Show Claim Headers</button>
        </div>
      </div>
    </div>
  </mat-toolbar>
</div>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <hr class="divisor-title" />
      <div class="square"></div>
      <h2 class="po-element-custom title-custom">Claims</h2>
    </div>
  </div>
</div>

<div class="container table-outer">
  <mat-paginator [length]="resultsNumber | async" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()"> </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="invoiceNumber" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="invoiceNumber" class="invoice-number-column"
          >Remittance Number
        </mat-header-cell>
        <mat-cell *matCellDef="let c" class="invoice-number-column">
          <a [routerLink]="['/invoices', c.claimHeader?.invoice?.id]">{{ c.invoiceNumber }}</a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="invoiceDate" sticky>
        <mat-header-cell class="invoice-date-column" *matHeaderCellDef mat-sort-header="invoiceDate">Remittance Date </mat-header-cell>
        <mat-cell class="invoice-date-column" *matCellDef="let c">
          <p>{{ c.remittanceDetail?.remittance?.paymentDate }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="suffix">
        <mat-header-cell class="suffix-column" *matHeaderCellDef mat-sort-header="suffix">Suffix</mat-header-cell>
        <mat-cell class="suffix-column" *matCellDef="let c">
          <p>{{ c.suffix }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell class="status-column" *matHeaderCellDef mat-sort-header="status">Status</mat-header-cell>
        <mat-cell class="status-column" *matCellDef="let c">
          <p>{{ c.status.description }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="disputeId">
        <mat-header-cell class="dispute-id-column" *matHeaderCellDef mat-sort-header="disputeId">Dispute ID </mat-header-cell>
        <mat-cell class="dispute-id-column" *matCellDef="let c">
          <p>{{ c.disputeId }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="disputeBy">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef>Dispute By</mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let c">
          <p>{{ getDisputeBy(c) }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="caseIds">
        <mat-header-cell class="case-ids-column" *matHeaderCellDef>Case IDs</mat-header-cell>
        <mat-cell class="case-ids-column" *matCellDef="let c">
          <p>{{ c.caseIds | join }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="openedBy">
        <mat-header-cell class="date-column" *matHeaderCellDef>Opened By</mat-header-cell>
        <mat-cell class="date-column" *matCellDef="let c">
          <p>{{ getOpenedBy(c) }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="vendor">
        <mat-header-cell *matHeaderCellDef class="vendor-column"> Vendor </mat-header-cell>
        <mat-cell *matCellDef="let c" class="vendor-column">
          <p *ngIf="c.claimHeader?.invoice?.shipment?.vendor?.parent">
            {{ c.claimHeader?.invoice?.shipment?.vendor?.code }} - {{ c.claimHeader?.invoice?.shipment?.vendor?.name }}
          </p>
          <p *ngIf="c.claimHeader?.invoice?.shipment?.vendor?.parent === false">
            {{ c.claimHeader?.invoice?.shipment?.vendor?.parentVendor?.code }} -
            {{ c.claimHeader?.invoice?.shipment?.vendor?.parentVendor?.name }}
          </p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="shipmentCode">
        <mat-header-cell *matHeaderCellDef class="shipment-code-column"> Shipment Code </mat-header-cell>
        <mat-cell *matCellDef="let c" class="shipment-code-column">
          <a [routerLink]="['/shipments', c.claimHeader?.invoice?.shipment?.id]">{{ c.claimHeader?.invoice?.shipment?.shipmentCode }}</a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="carrier">
        <mat-header-cell *matHeaderCellDef class="carrier-column"> Carrier </mat-header-cell>
        <mat-cell *matCellDef="let ch" class="carrier-column">
          <p>{{ ch.claimHeader?.invoice?.shipment?.contract?.name }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="supplier">
        <mat-header-cell *matHeaderCellDef class="supplier-column"> Supplier </mat-header-cell>
        <mat-cell *matCellDef="let ch" class="supplier-column">
          <p>{{ ch.claimHeader?.invoice?.shipment?.origin?.supplier?.name }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="trackingNumber">
        <mat-header-cell *matHeaderCellDef class="tracking-number-column"> Tracking Number </mat-header-cell>
        <mat-cell *matCellDef="let ch" class="tracking-number-column">
          <p>{{ ch.claimHeader?.invoice?.shipment?.trackingNumber }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="deliveryDate">
        <mat-header-cell *matHeaderCellDef class="delivery-date-column"> Delivery Date </mat-header-cell>
        <mat-cell *matCellDef="let ch" class="delivery-date-column">
          <p>{{ ch.claimHeader?.invoice?.shipment?.deliveryDate | myDate }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="amountPaid">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="amountPaid">Amount </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let c">
          <p>{{ c.amountPaid | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="claimQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef>Claim Quantity</mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let c">
          <p>{{ claimQuantity(c) }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="hasRemittance">
        <mat-header-cell class="boolean-column" *matHeaderCellDef>Has Remittance</mat-header-cell>
        <mat-cell class="boolean-column" *matCellDef="let c">
          <p><i [ngClass]="c.remittanceDetail | booleanIconPipe" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="hasPod">
        <mat-header-cell class="boolean-column" *matHeaderCellDef>Has POD</mat-header-cell>
        <mat-cell class="boolean-column" *matCellDef="let c">
          <p><i [ngClass]="c.claimHeader?.invoice?.shipment?.podUrls?.length > 0 | booleanIconPipe" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="settlement2022">
        <mat-header-cell class="boolean-column" *matHeaderCellDef>Settlement 2022</mat-header-cell>
        <mat-cell class="boolean-column" *matCellDef="let c">    
          <p><i [ngClass]="c.claimHeader?.settlement | booleanIconPipe" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="export">
        <mat-header-cell class="actions-show-column" *matHeaderCellDef></mat-header-cell>
        <mat-cell class="actions-show-column" *matCellDef="let c">
          <button class="btn-product btn-fixed" style="width: 110px !important; float: right" (click)="export(c)">export</button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="edit" stickyEnd>
        <mat-header-cell *matHeaderCellDef class="single-line-edit-column">
          <p>Edit claim</p>
        </mat-header-cell>
        <mat-cell class="single-line-edit-column" *matCellDef="let ch">
          <p *ngIf="ch.code.endsWith('C')">
            <button class="box-shadow-none actions-button btn-fixed" matTooltip="Edit claim" (click)="edit(ch)">
              <i class="fa fa-edit" aria-hidden="true"></i>
            </button>
          </p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="showDetails" stickyEnd>
        <mat-header-cell *matHeaderCellDef class="single-line-edit-column">
          <p>Show Details</p>
        </mat-header-cell>
        <mat-cell class="single-line-edit-column" *matCellDef="let ch">
          <p *ngIf="ch.code.endsWith('C')">
            <button class="box-shadow-none actions-button btn-fixed" matTooltip="Show details" (click)="showDetails(ch.id)">
              <i class="fa fa-external-link" aria-hidden="true"></i>
            </button>
          </p>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
