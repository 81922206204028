<div>
  <div class="row">
    <div class="col-xs-12 text-blue font-Titillium">
      <h2 mat-dialog-title>Product EAN: {{ data.publication.product.ean }}</h2>
    </div>
  </div>

  <div mat-dialog-content>
    <div class="table-container">
      <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]"> </mat-paginator>

      <mat-table #listingTable [dataSource]="dataSource">
        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef> Approved Date </mat-header-cell>
          <mat-cell *matCellDef="let p"> {{ p.approvedDate }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="price">
          <mat-header-cell *matHeaderCellDef class="value-column"> Approved Price </mat-header-cell>
          <mat-cell *matCellDef="let p" class="value-column"> {{ p.price }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
          <mat-cell *matCellDef="let p"> {{ p.email }} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12" style="text-align: right; align-content: right">
      <button class="btn-custom" (click)="closeDialog()">Close</button>
    </div>
  </div>
</div>
