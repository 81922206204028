import { Component, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { InventoryService } from '../../../../../inventory/inventory.service';
import { isNilty } from '../../../../../../core/utils.service';
import { MatChipsData } from '../../../../../../models/mat-chips-data-model';
import { Supplier } from '../../../../../../models/supplier-model';
import { PaymentRequestService } from '../../payment-request.service';
import { PaymentRequestFilters, paymentRequestFiltersMap } from '../../../../../../models/filters/payment-request-filters-model';
import { Status } from '../../../../../../models/status-model';

@Component({
  selector: 'app-payment-request-list-filters',
  templateUrl: './payment-request-list-filters.component.html',
  styleUrls: ['./payment-request-list-filters.component.css'],
})
export class PaymentRequestListFiltersComponent implements OnInit {
  constructor(private inventoryService: InventoryService, private paymentRequestService: PaymentRequestService) {}

  filterForm: FormGroup;
  public openFilter = false;

  filtersMap: MatChipsData[] = [];

  suppliers: Supplier[] = [];
  paymentRequestStatus: Status[] = [];

  @Output() onFilter = new Subject<PaymentRequestFilters>();

  ngOnInit() {
    this.filterForm = new FormGroup({
      paymentRequestId: new FormControl(),
      createdAt: new FormControl(),
      supplier: new FormControl(),
      documentNumbers: new FormControl(),
      shipments: new FormControl(),
      status: new FormControl(),
      latestPaymentDate: new FormControl(),
      latestPickupDate: new FormControl(),
      createdBy: new FormControl(),
      hasAdjustment: new FormControl(),
    });

    if (!isNilty(this.paymentRequestService.paymentRequestFilters !== null)) {
      this.filterForm.patchValue(this.paymentRequestService.paymentRequestFilters);
      this.updateFiltersMap();
    }

    this.inventoryService.getAllSuppliers().subscribe((suppliers: Supplier[]) => (this.suppliers = suppliers));

    this.paymentRequestService.getPaymentRequestStatus().subscribe((s: Status[]) => (this.paymentRequestStatus = s));
  }

  onSubmitFilter() {
    this.saveFilters();
    this.updateFiltersMap();
    this.onFilter.next(this.paymentRequestService.paymentRequestFilters);
  }

  resetFilters() {
    this.filterForm.reset();
    this.saveFilters();
    this.updateFiltersMap();
    this.onFilter.next(this.paymentRequestService.paymentRequestFilters);
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeevent() {
    this.openFilter = false;
  }

  saveFilters() {
    this.paymentRequestService.paymentRequestFilters.paymentRequestId = this.filterForm.value.paymentRequestId;
    this.paymentRequestService.paymentRequestFilters.createdAt = this.filterForm.value.createdAt;
    this.paymentRequestService.paymentRequestFilters.supplier = this.filterForm.value.supplier;
    this.paymentRequestService.paymentRequestFilters.documentNumbers = isNilty(this.filterForm.value.documentNumbers)
      ? null
      : this.filterForm.value.documentNumbers.split('\n');
    this.paymentRequestService.paymentRequestFilters.shipments = isNilty(this.filterForm.value.shipments)
      ? null
      : this.filterForm.value.shipments.split('\n');
    this.paymentRequestService.paymentRequestFilters.status = this.filterForm.value.status;
    this.paymentRequestService.paymentRequestFilters.latestPaymentDate = this.filterForm.value.latestPaymentDate;
    this.paymentRequestService.paymentRequestFilters.latestPickupDate = this.filterForm.value.latestPickupDate;
    this.paymentRequestService.paymentRequestFilters.createdBy = this.filterForm.value.createdBy;
    this.paymentRequestService.paymentRequestFilters.hasAdjustment = this.filterForm.value.hasAdjustment;

    this.paymentRequestService.paymentRequestFilters.pageNumber = null;
    this.paymentRequestService.paymentRequestFilters.pageSize = null;
    this.paymentRequestService.paymentRequestFilters.sortBy = null;
    this.paymentRequestService.paymentRequestFilters.sortDirection = null;
  }

  updateFiltersMap() {
    this.filtersMap = paymentRequestFiltersMap(
      this.filterForm.value.paymentRequestId,
      this.filterForm.value.createdAt,
      this.filterForm.value.supplier,
      isNilty(this.filterForm.value.documentNumbers) ? null : this.filterForm.value.documentNumbers.split('\n'),
      isNilty(this.filterForm.value.shipments) ? null : this.filterForm.value.shipments.split('\n'),
      this.filterForm.value.status,
      this.filterForm.value.latestPaymentDate,
      this.filterForm.value.latestPickupDate,
      this.filterForm.value.createdBy,
      this.filterForm.value.hasAdjustment
    );
  }
}
