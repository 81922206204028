import { JsonObject, JsonProperty } from 'json2typescript';
import { Product } from './product-model';
import { StockLocation } from './stock-location-model';
import { BaseObject } from './base-object-model';

@JsonObject('StockItem')
export class StockItem extends BaseObject {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('product', Product, false) product: Product = undefined;
  @JsonProperty('stockLocation', StockLocation, true) stockLocation: StockLocation = undefined;
  @JsonProperty('physicalQuantity', Number, true) physicalQuantity: number = undefined;
  @JsonProperty('availableQuantity', Number, true) availableQuantity: number = undefined;
}
