<app-main-invoices-filters (onFilter)="onFilter($event)"></app-main-invoices-filters>

<div class="bulk-actions-div">
  <mat-toolbar class="bar-custom">
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <button [disabled]="!bulkActionsActive" (click)="sendInvoices()" class="box-shadow-none btn-custom margin-right-6">
            Send Invoices
          </button>
        </div>
      </div>
    </div>
  </mat-toolbar>
</div>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <hr class="divisor-title" style="width: calc(100% - 15rem)" />
      <div class="square" style="right: 15rem"></div>
      <h2 class="po-element-custom title-custom">Invoices</h2>
    </div>
  </div>
</div>

<div class="container table-outer">
  <mat-paginator #paginator [length]="resultsNumber | async" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()">
  </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="check" sticky>
        <mat-header-cell *matHeaderCellDef class="checker-col">
          <mat-checkbox (change)="toggleAll($event.checked)" [ngModel]="mainChecker"></mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let spo; let i = index" class="checker-col">
          <mat-checkbox (change)="onSelected($event.checked, spo)" [ngModel]="spo.checked"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="invoiceNumber" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="invoiceNumber" class="invoice-number-column"> Invoice number </mat-header-cell>
        <mat-cell *matCellDef="let i" class="invoice-number-column" [matTooltip]="i.invoiceNumber">
          <a [routerLink]="['/invoices', i.id]">{{ i.invoiceNumber }}</a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="asnCode" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="asnCode" class="asn-column"> ASN </mat-header-cell>
        <mat-cell *matCellDef="let i" class="asn-column">
          <p>{{ i.shipment.asnCode }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="shipmentCode">
        <mat-header-cell *matHeaderCellDef mat-sort-header="shipmentCode" class="shipment-code-column"> Shipment code </mat-header-cell>
        <mat-cell *matCellDef="let i" class="shipment-code-column">
          <a [routerLink]="['/shipments', i.shipment.id]">{{ i.shipment.shipmentCode }}</a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="supplier">
        <mat-header-cell *matHeaderCellDef mat-sort-header="supplier" class="code-column"> Supplier </mat-header-cell>
        <mat-cell *matCellDef="let i" class="code-column" [matTooltip]="i.shipment.origin.supplier.name">
          <p>{{ i.shipment.origin.supplier.code }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="destination">
        <mat-header-cell *matHeaderCellDef mat-sort-header="destination" class="code-column"> Destination </mat-header-cell>
        <mat-cell *matCellDef="let i" class="code-column" [matTooltip]="i.shipment.destination.name">
          <p>{{ i.shipment.destination.code }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="vendor">
        <mat-header-cell *matHeaderCellDef mat-sort-header="vendor" class="vendor-column"> Vendor </mat-header-cell>
        <mat-cell *matCellDef="let i" class="vendor-column">
          <p>{{ i.shipment.vendor.code }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="invoiceDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="invoiceDate" class="date-column"> Date </mat-header-cell>
        <mat-cell *matCellDef="let i" class="date-column">
          <p>{{ i.invoiceDate }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="senderVatNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header="senderVatNumber" class="invoice-number-column">
          Sender VAT number
        </mat-header-cell>
        <mat-cell *matCellDef="let i" class="invoice-number-column">
          <p>{{ i.senderVatNumber }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="receiverVatNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header="receiverVatNumber" class="invoice-number-column">
          Receiver VAT number
        </mat-header-cell>
        <mat-cell *matCellDef="let i" class="invoice-number-column">
          <p>{{ i.receiverVatNumber }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="amazonStatus">
        <mat-header-cell *matHeaderCellDef mat-sort-header="paymentTerms" class="code-column"> Amazon status </mat-header-cell>
        <mat-cell *matCellDef="let i" class="code-column">
          <p>{{ i.amazonStatus }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="paymentTerms">
        <mat-header-cell *matHeaderCellDef mat-sort-header="paymentTerms" class="code-column"> Payment terms </mat-header-cell>
        <mat-cell *matCellDef="let i" class="code-column">
          <p>{{ i.paymentTerms }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="billToEntity">
        <mat-header-cell *matHeaderCellDef mat-sort-header="billToEntity" class="destination-column"> Bill to </mat-header-cell>
        <mat-cell *matCellDef="let i" class="destination-column" [matTooltip]="i.billToEntity">
          <p>{{ i.billToEntity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="billToCity">
        <mat-header-cell *matHeaderCellDef mat-sort-header="billToCity" class="invoice-number-column"> City </mat-header-cell>
        <mat-cell *matCellDef="let i" class="invoice-number-column" [matTooltip]="i.billToCity">
          <p>{{ i.billToCity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="billToCountryCode">
        <mat-header-cell *matHeaderCellDef mat-sort-header="billToCountryCode" class="boolean-column"> Country </mat-header-cell>
        <mat-cell *matCellDef="let i" class="boolean-column">
          <p>{{ i.billToCountryCode }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="sendingDate">
        <mat-header-cell class="date-time-column" *matHeaderCellDef mat-sort-header="sendingDate"> Sending Date </mat-header-cell>
        <mat-cell class="date-time-column" *matCellDef="let i">
          <p>{{ i.sendingDate }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="dueDate">
        <mat-header-cell class="date-time-column" *matHeaderCellDef mat-sort-header="dueDate"> Due Date </mat-header-cell>
        <mat-cell class="date-time-column" *matCellDef="let i">
          <p>{{ i.dueDate }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="remittanceNumber">
        <mat-header-cell class="date-time-column" *matHeaderCellDef mat-sort-header="dueDate"> Remittance Number </mat-header-cell>
        <mat-cell class="date-time-column" *matCellDef="let i">
          <p>{{ getRemittanceNumber(i) }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="remittanceDate">
        <mat-header-cell class="date-time-column" *matHeaderCellDef mat-sort-header="remittanceDate"> Remittance Date </mat-header-cell>
        <mat-cell class="date-time-column" *matCellDef="let i">
          <p>{{ getRemittanceDate(i) }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="sentToAmazon">
        <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="sentToAmazon" class="boolean-column">
          Sent to Amazon
        </mat-header-cell>
        <mat-cell class="boolean-column" *matCellDef="let i" class="boolean-column">
          <p><i [ngClass]="i.sentToAmazon | booleanIconPipe" aria-hidden="true"></i></p
        ></mat-cell>
      </ng-container>

      <ng-container matColumnDef="probableNC">
        <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="probableNC" class="boolean-column">
          Probable NC
        </mat-header-cell>
        <mat-cell class="boolean-column" *matCellDef="let i" class="boolean-column">
          <p><i [ngClass]="i.probableNC | booleanIconPipe" aria-hidden="true"></i></p
        ></mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
