import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { isNilty } from '../../../../../../core/utils.service';
import { PaymentRequestService } from '../../payment-request.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { CUSTOM_DATE_FORMATS } from '../../../../../../shared/custom-date-formats';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-change-latest-payment-date-modal',
  templateUrl: './change-latest-payment-date-modal.component.html',
  styleUrls: ['./change-latest-payment-date-modal.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export class ChangeLatestPaymentDateModalComponent {
  paymentRequestId = this.data.prId;
  oldDate = this.data.prLpd;

  @Output() newItemEvent = new EventEmitter<string>();

  constructor(
    private paymentRequestService: PaymentRequestService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ChangeLatestPaymentDateModalComponent>
  ) {}

  changeLatestPaymentDate(newDate: any) {
    this.paymentRequestService.changeLatestPaymentDate(this.paymentRequestId, newDate).subscribe(
      () => {
        this.snackBar.open('Latest Payment Date changed.', '')._dismissAfter(2000);
        this.data.prLpd = newDate;
        this.dialogRef.close(newDate);
      },
      () => this.dialogRef.close()
    );
  }

  isDisabled(): boolean {
    return isNilty(this.data.prLpd);
  }
}
