import { JsonObject, JsonProperty } from 'json2typescript';
import { ClaimDetail } from './claim-detail-model';
import { DateConverter } from './date-converter';
import { Invoice } from './invoice-model';
import { RemittanceDetail } from './remittance-detail-model';
import { Status } from './status-model';

@JsonObject('ClaimHeaderInvoice')
export class ClaimHeaderInvoice {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('invoice', Invoice, true) invoice: Invoice = undefined;
  @JsonProperty('settlement', Boolean, true) settlement: boolean = undefined;
}

@JsonObject('Claim')
export class Claim {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('invoiceNumber', String, true) invoiceNumber: string = undefined;
  @JsonProperty('invoiceDate', String, true) invoiceDate: string = undefined;
  @JsonProperty('code', String, true) code: string = undefined;
  @JsonProperty('suffix', String, true) suffix: string = undefined;
  @JsonProperty('status', Status, true) status: Status = undefined;
  @JsonProperty('amountPaid', Number, true) amountPaid: number = undefined;
  @JsonProperty('payeeCode', String, true) payeeCode: string = undefined;
  @JsonProperty('countryCode', String, true) countryCode: string = undefined;
  @JsonProperty('currency', String, true) currency: string = undefined;
  @JsonProperty('amountRemaining', Number, true) amountRemaining: number = undefined;
  @JsonProperty('discountTaken', String, true) discountTaken: string = undefined;
  @JsonProperty('partiallyPaid', Boolean, true) partiallyPaid: boolean = undefined;
  @JsonProperty('invoiceDescription', String, true) invoiceDescription: string = undefined;
  @JsonProperty('disputeId', String, true) disputeId: string = undefined;
  @JsonProperty('disputeDate', DateConverter, true) disputeDate: Date = undefined;
  @JsonProperty('caseIds', [String], true) caseIds: string[] = undefined;
  @JsonProperty('caseDate', DateConverter, true) caseDate: Date = undefined;
  @JsonProperty('claimHeader', ClaimHeaderInvoice, true) claimHeader: ClaimHeaderInvoice = undefined;
  @JsonProperty('remittanceDetail', RemittanceDetail, true) remittanceDetail: RemittanceDetail = undefined;
  @JsonProperty('claimDetails', [ClaimDetail], true) claimDetails: ClaimDetail[] = undefined;
  @JsonProperty('disputeCreatedByBot', Boolean, true) disputeCreatedByBot: boolean = undefined;
  @JsonProperty('caseCreatedByBot', Boolean, true) caseCreatedByBot: boolean = undefined;
}
