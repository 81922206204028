import { NgModule } from '@angular/core';
import { MainInvoicesComponent } from './main-invoices.component';
import { MainInvoicesFiltersComponent } from './main-invoices-filters/main-invoices-filters.component';
import { SharedModule } from '../../../shared.module';
import { MainInvoicesRoutingModule } from './main-invoices-routing.module';
import { InvoiceService } from './invoice.service';
import { SingleInvoiceDetailComponent } from '../../../shared/single-invoice-detail/single-invoice-detail.component';
import { SingleInvoiceKpiComponent } from '../../../shared/single-invoice-detail/single-invoice-kpi/single-invoice-kpi.component';

@NgModule({
  imports: [SharedModule, MainInvoicesRoutingModule],
  declarations: [MainInvoicesComponent, MainInvoicesFiltersComponent, SingleInvoiceDetailComponent, SingleInvoiceKpiComponent],
  providers: [InvoiceService],
  entryComponents: [],
})
export class MainInvoicesModule {}
