import { JsonProperty } from 'json2typescript';
import { JsonObject } from 'json2typescript/index';
import { MatChipsData } from '../mat-chips-data-model';
import { PaginationAndSorting } from './pagination-and-sorting-model';
import { DateConverter, fromDateToFiltersString } from '@models/date-converter';

@JsonObject('PublicationFilters')
export class PublicationFilters extends PaginationAndSorting {
  @JsonProperty('eans', String, true) eans: string = undefined;
  @JsonProperty('vendors', [Number], true) vendors: number[] = undefined;
  @JsonProperty('productStatus', String, true) productStatus: string = undefined;
  @JsonProperty('status', Number, true) publicationStatus: number = undefined;
  @JsonProperty('ids', [Number], true) ids: number[] = undefined;
  @JsonProperty('newPriceStatus', String, true) newPriceStatus: string = undefined;
  @JsonProperty('pendingApprovalDateFrom', DateConverter, true) pendingApprovalDateFrom: Date = undefined;
  @JsonProperty('pendingApprovalDateTo', DateConverter, true) pendingApprovalDateTo: Date = undefined;
}

export const publicationFiltersMap = (
  eans?: string,
  vendor?: string,
  productStatus?: string,
  status?: number,
  newPriceStatus?: string,
  pendingApprovalDateFrom?: Date,
  pendingApprovalDateTo?: Date
): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (eans !== null && eans !== undefined) {
    map.push(new MatChipsData('EAN', eans));
  }
  if (productStatus !== null && productStatus !== undefined) {
    map.push(new MatChipsData('Product status', productStatus));
  }
  if (vendor !== null && vendor !== undefined) {
    map.push(new MatChipsData('Vendors', vendor));
  }
  if (status !== null && status !== undefined) {
    map.push(new MatChipsData('Status', EnumHelper.GetDescription(PublicationStatus, status)));
  }
  if (newPriceStatus !== null && newPriceStatus !== undefined) {
    map.push(new MatChipsData('New Price Status', newPriceStatus));
  }
  if (pendingApprovalDateFrom || pendingApprovalDateTo) {
    map.push(
      new MatChipsData(
        'Pending Approved Date',
        fromDateToFiltersString(pendingApprovalDateFrom) + ' - ' + fromDateToFiltersString(pendingApprovalDateTo)
      )
    );
  }
  return map;
};

enum PublicationStatus {
  Online = 1,
  OnlineDescription = 'Online',
  Suspended = 2,
  SuspendedDescription = 'Suspended',
  Offline = 3,
  OfflineDescription = 'Offline',
  toPublish = 4,
  toPublishDescription = 'toPublish',
}

class EnumHelper {
  static GetDescription(e: any, id: number): string {
    return e[e[id].toString() + 'Description'];
  }
}
