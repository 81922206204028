import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from './date-converter';
import { PaymentDocument } from './payment-document-model';
import { PaymentRequestStatus, PaymentRequestStatusConverter } from './payment-request-status-model';
import { Status, StatusConverter } from './status-model';
import { Supplier } from './supplier-model';

@JsonObject('PaymentAdjustment')
export class PaymentAdjustmentSimple {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('type', StatusConverter, true) type: Status = undefined;
  @JsonProperty('value', Number, true) value: number = undefined;
  @JsonProperty('causal', String, true) causal: string = undefined;
}

@JsonObject('PaymentRequest')
export class PaymentRequest {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('supplier', Supplier, true) supplier: Supplier = undefined;
  @JsonProperty('latestPaymentDate', DateConverter, true) latestPaymentDate: Date = undefined;
  @JsonProperty('latestPickupDate', DateConverter, true) latestPickupDate: Date = undefined;
  @JsonProperty('status', PaymentRequestStatusConverter, true) status: PaymentRequestStatus = undefined;
  @JsonProperty('documents', [PaymentDocument], true) documents: PaymentDocument[] = undefined;
  @JsonProperty('createdBy', String, true) createdBy: string = undefined;
  @JsonProperty('createdAt', DateConverter, true) createdAt: Date = undefined;
  @JsonProperty('adjustments', [PaymentAdjustmentSimple], true) adjustments: PaymentAdjustmentSimple[] = undefined;
  @JsonProperty('taxable', Number, true) taxable: number = undefined;
  @JsonProperty('vat', Number, true) vat: number = undefined;
  @JsonProperty('value', Number, true) value: number = undefined;
  @JsonProperty('quantity', Number, true) quantity: number = undefined;
  @JsonProperty('shipmentsPurchasePrice', Number, true) shipmentsPurchasePrice: number = undefined;
  @JsonProperty('shipmentsValue', Number, true) shipmentsValue: number = undefined;
  @JsonProperty('shipmentsQuantity', Number, true) shipmentsQuantity: number = undefined;
}
