import { Component, OnInit, ViewChild } from '@angular/core';
import { ClaimDetail } from '../../../../../models/claim-detail-model';
import { Observable } from 'rxjs';
import { ClaimService } from '../claim.service';
import { ClaimFilters } from '../../../../../models/filters/claim-filters-model';
import { isNilty } from '../../../../../core/utils.service';
import { ClaimDetailFilters } from '../../../../../models/filters/claim-detail-filters-model';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-claim-details-list',
  templateUrl: './claim-details-list.component.html',
  styleUrls: ['./claim-details-list.component.css'],
})
export class ClaimDetailsListComponent implements OnInit {
  dataSource: MatTableDataSource<ClaimDetail>;

  displayedColumns = [
    'invoiceNumber',
    'poNumber',
    'asin',
    'ean',
    'title',
    'invoiceQuantity',
    'receivedQuantity',
    'claimQuantity',
    'amount',
  ];

  selectedRow: ClaimDetail;
  claimId: number;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private claimService: ClaimService, private route: ActivatedRoute, private location: Location) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.claimId = Number(params.get('claimId'));

      this.resultsNumber = this.claimService.resultsNumber;

      if (isNilty(this.claimService.claimDetailFilters)) {
        this.claimService.claimDetailFilters = new ClaimDetailFilters();
      }
      this.claimService.claimDetailFilters.pageNumber = 0;
      this.dataSource = new MatTableDataSource([]);
      this.loadClaimDetails(this.claimService.claimDetailFilters);
    });
  }

  loadClaimDetails(filters: ClaimDetailFilters) {
    this.claimService.getFilteredClaimDetails(this.claimId, filters).subscribe((ch: ClaimDetail[]) => {
      this.dataSource.data = ch;
    });
  }

  loadPage() {
    this.claimService.claimDetailFilters.pageNumber = this.paginator.pageIndex;
    this.claimService.claimDetailFilters.pageSize = this.paginator.pageSize;
    this.loadClaimDetails(this.claimService.claimDetailFilters);
  }

  onSort() {
    this.claimService.claimDetailFilters.sortBy = this.sort.active;
    this.claimService.claimDetailFilters.sortDirection = this.sort.direction;
    this.loadClaimDetails(this.claimService.claimDetailFilters);
  }

  onFilter(filters: ClaimFilters) {
    this.paginator.pageIndex = 0;
    this.loadClaimDetails(filters);
  }

  back() {
    this.location.back();
  }
}
