<div class="row padding-right-30">
  <ng-container *ngIf="!dataSource || dataSource?.data.length === 0">
    <div class="col-md-12 small-table-title text-center">
      <h4>No POs found</h4>
    </div>
  </ng-container>

  <div
    class="col-md-12 padding-right-0 small-table-title border-bottom-title"
    [ngClass]="{ 'display-none': !dataSource || dataSource?.data.length === 0 }"
  >
    <h4>Last POs</h4>
  </div>
  <!--<div class="col-md-5">-->
  <!--<mat-paginator id="little-paginator" #paginator [length]="resultsNumber | async" [pageSize]="5" [pageSizeOptions]="[5]">-->
  <!--</mat-paginator>-->
  <!--</div>-->

  <div
    class="col-md-12 fixed-table-5-rows padding-left-0 padding-right-0"
    [ngClass]="{ 'display-none': !dataSource || dataSource?.data.length === 0 }"
  >
    <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-40">
      <mat-table #listingTable [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
        <ng-container matColumnDef="poCode">
          <mat-header-cell *matHeaderCellDef mat-sort-header="poCode">PO Id</mat-header-cell>
          <mat-cell *matCellDef="let poi">
            <p>{{ poi.poCode }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="orderedOn">
          <mat-header-cell *matHeaderCellDef mat-sort-header="orderedOn">Ordered on</mat-header-cell>
          <mat-cell *matCellDef="let poi">
            <p>{{ poi.orderedOn }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="vendor">
          <mat-header-cell *matHeaderCellDef mat-sort-header="vendor">Vendor</mat-header-cell>
          <mat-cell *matCellDef="let poi">
            <p>{{ poi.vendor.code }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="destination">
          <mat-header-cell *matHeaderCellDef mat-sort-header="destination">Destination</mat-header-cell>
          <mat-cell *matCellDef="let poi">
            <p>{{ poi.destination.code }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="submittedQuantity">
          <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="submittedQuantity">Submitted</mat-header-cell>
          <mat-cell class="quantity-column" *matCellDef="let poi">
            <p>{{ poi.submittedQuantity }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="acceptedQuantity">
          <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="acceptedQuantity">Accepted</mat-header-cell>
          <mat-cell class="quantity-column" *matCellDef="let poi">
            <p>{{ poi.acceptedQuantity }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="unitCost">
          <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="unitCost">Unit cost</mat-header-cell>
          <mat-cell class="value-column" *matCellDef="let poi">
            <p>{{ poi.unitCost | myCurrency }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="totalCost">
          <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="totalCost">Total cost</mat-header-cell>
          <mat-cell class="value-column" *matCellDef="let poi">
            <p>{{ poi.totalCost | myCurrency }}</p>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>
</div>
