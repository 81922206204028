import { JsonObject, JsonProperty } from 'json2typescript';
import { Vendor } from './vendor-model';
import { VendorCentralAccountDTO } from './vendor-central-account-dto-model';

@JsonObject('VendorCentralAccount')
export class VendorCentralAccount {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('vendors', [Vendor], false) vendors: Vendor[] = undefined;
  @JsonProperty('botEnabled', Boolean, true) botEnabled: boolean = undefined;

  toDTO(): VendorCentralAccountDTO {
    const dto = new VendorCentralAccountDTO();

    dto.id = this.id;
    dto.name = this.name;
    dto.vendors = this.vendors.map((vendor) => vendor.id);
    dto.botEnabled = this.botEnabled;

    return dto;
  }
}
