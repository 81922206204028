import { Component, Input, ViewChild } from '@angular/core';
import { CatalogItemWithStock } from '../../../../../models/catalog-item-model';
import { compareNumberOrString, isNilty } from '../../../../../core/utils.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InventoryService } from '../../../inventory.service';
import { MatSnackBar } from '@angular/material/snack-bar';

const BASE_HEIGHT = 78;
const DETAILS_HEIGHT = 270;

@Component({
  selector: 'app-catalog-items-table',
  templateUrl: './catalog-items-table.component.html',
  styleUrls: ['./catalog-items-table.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: DETAILS_HEIGHT + 'px', minHeight: DETAILS_HEIGHT + 'px' })),
      transition('expanded <=> collapsed', animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CatalogItemsTableComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() set items(values: CatalogItemWithStock[]) {
    if (!isNilty(values)) {
      this.tableHeight = BASE_HEIGHT + (values.length > 5 ? 5 : values.length) * 48;
      this.collapsedTableHeight = this.tableHeight;
      this.expandedTableHeight = this.tableHeight + DETAILS_HEIGHT + 5;
      this.dataSource = new MatTableDataSource(values);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  dataSource: MatTableDataSource<CatalogItemWithStock>;
  displayedColumns = [
    'name',
    'sku',
    'countryOfOrigin',
    'unNumber',
    'updatedAt',
    'physicalQuantity',
    'availableQuantity',
    'extraQuantity',
    'purchasePrice',
    'latestSpo',
    'lowestSpo',
    'status',
    'showDetails',
  ];
  tableHeight: number;
  collapsedTableHeight: number;
  expandedTableHeight: number;

  selectedRow: CatalogItemWithStock;

  constructor(
    private inventoryService: InventoryService,
    private snackBar: MatSnackBar
  ) {}

  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'supplier':
          return compareNumberOrString(a.supplier.name, b.supplier.name, isAsc);
        default:
          return compareNumberOrString(a[sort.active], b[sort.active], isAsc);
      }
    });
  }

  toggle(row: CatalogItemWithStock) {
    this.selectedRow = this.selectedRow === row ? null : row;
    this.tableHeight = this.selectedRow ? this.expandedTableHeight : this.collapsedTableHeight;
  }

  deleteUnNumber(row: CatalogItemWithStock) {
    this.inventoryService.deleteUnNumber(row.id).subscribe(() => {
      this.snackBar.open('Un Number deleted', 'CLOSE')._dismissAfter(2000);
      row.unNumber = undefined;
    });
  }
}
