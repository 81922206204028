import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { MarkupConstraintsComponent } from './publication-rule-edit/markup-constraints/markup-constraints.component';
// eslint-disable-next-line max-len
import { PredicatesEditComponent } from './publication-rule-edit/markup-constraints/markup-constraints-edit/predicates-edit/predicates-edit.component';
import { PublicationRuleEditComponent } from './publication-rule-edit/publication-rule-edit.component';
import { PublicationRuleListComponent } from './publication-rule-list/publication-rule-list.component';
import { PublicationRuleComponent } from './publication-rule.component';
import { PublicationRuleService } from './publication-rule.service';
// eslint-disable-next-line max-len
import { MarkupConstraintsEditComponent } from './publication-rule-edit/markup-constraints/markup-constraints-edit/markup-constraints-edit.component';
import { ConstraintsComponent } from './publication-rule-edit/constraints/constraints.component';
import { MarginConstraintsComponent } from './publication-rule-edit/margin-constraints/margin-constraints.component';
// eslint-disable-next-line max-len
import { MarginConstraintsEditComponent } from './publication-rule-edit/margin-constraints/margin-constraints-edit/margin-constraints-edit.component';
// eslint-disable-next-line max-len
import { MarginPredicatesEditComponent } from './publication-rule-edit/margin-constraints/margin-constraints-edit/margin-predicates-edit/margin-predicates-edit.component';
import { PrioritySupplierListComponent } from './publication-rule-edit/priority-supplier-list/priority-supplier-list.component';
// eslint-disable-next-line max-len
import { PrioritySupplierListEditComponent } from './publication-rule-edit/priority-supplier-list/priority-supplier-list-edit/priority-supplier-list-edit.component';

@NgModule({
  declarations: [
    PublicationRuleComponent,
    PublicationRuleEditComponent,
    PublicationRuleListComponent,
    MarkupConstraintsComponent,
    MarkupConstraintsEditComponent,
    PredicatesEditComponent,
    ConstraintsComponent,
    MarginConstraintsComponent,
    MarginConstraintsEditComponent,
    MarginPredicatesEditComponent,
    PrioritySupplierListComponent,
    PrioritySupplierListEditComponent,
  ],
  imports: [SharedModule],
  providers: [PublicationRuleService],
  entryComponents: [],
})
export class PublicationRulesModule {}
