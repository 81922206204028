<mat-expansion-panel class="rule-edit-panel">
  <mat-expansion-panel-header class="constraints-header">
    <mat-panel-title class="rule-edit-panel-title"
      ><i class="fa fa-link margin-right-15" aria-hidden="true"></i>Constraints</mat-panel-title
    >
  </mat-expansion-panel-header>

  <div class="row padding-top-15" *ngIf="constraints.length === 0">
    <div class="col-md-12 description-legend bigger-description-legend">No constraints defined.</div>
  </div>

  <app-predicates-edit [_predicates]="constraints"></app-predicates-edit>
</mat-expansion-panel>
