import { Component, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { SpoFilters, spoFiltersMap } from '../../../models/filters/spo-filters-model';
import { FormControl, FormGroup } from '@angular/forms';
import { MatChipsData } from '../../../models/mat-chips-data-model';
import { isNilty } from '../../../core/utils.service';

@Component({
  selector: 'app-single-spo-filters',
  templateUrl: './single-spo-filters.component.html',
  styleUrls: ['./single-spo-filters.component.css'],
})
export class SingleSpoFiltersComponent implements OnInit {
  @Output() onFilter = new Subject<SpoFilters>();

  filterForm: FormGroup;

  openFilter = false;

  filters: SpoFilters;
  filtersMap: MatChipsData[] = [];

  ngOnInit() {
    this.filterForm = new FormGroup({
      ean: new FormControl(),
      po: new FormControl(),
      unitCostFrom: new FormControl(),
      unitCostTo: new FormControl(),
      latestDateFrom: new FormControl(),
      latestDateTo: new FormControl(),
      hasOrderedQuantity: new FormControl(false),
      obsoleteWithAccepted: new FormControl(false),
    });
    this.filters = new SpoFilters();
  }

  onSubmitFilter() {
    this.setFilters();
    this.onFilter.next(this.filters);
    this.updateFiltersMap();
  }

  setFilters() {
    this.filters = new SpoFilters();
    this.filters.ean = this.filterForm.value.ean !== '' ? this.filterForm.value.ean : undefined;
    this.filters.po = this.filterForm.value.po !== '' ? this.filterForm.value.po : undefined;
    this.filters.unitCostFrom = !isNilty(this.filterForm.value.unitCostFrom) ? this.filterForm.value.unitCostFrom : undefined;
    this.filters.unitCostTo = !isNilty(this.filterForm.value.unitCostTo) ? this.filterForm.value.unitCostTo : undefined;
    this.filters.latestDateFrom = this.filterForm.value.latestDateFrom;
    this.filters.latestDateTo = this.filterForm.value.latestDateTo;
    this.filters.hasOrderedQuantity = this.filterForm.value.hasOrderedQuantity;
    this.filters.obsoleteWithAccepted = this.filterForm.value.obsoleteWithAccepted ? true : undefined;
  }

  resetFilters() {
    this.filterForm.reset();
    this.setFilters();
    this.onFilter.next(this.filters);
    this.updateFiltersMap();
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeEvent() {
    this.openFilter = false;
  }

  updateFiltersMap() {
    this.filtersMap = spoFiltersMap(
      undefined,
      this.filterForm.value.ean,
      this.filterForm.value.po,
      undefined,
      undefined,
      undefined,
      this.filterForm.value.unitCostFrom,
      this.filterForm.value.unitCostTo,
      undefined,
      undefined,
      this.filterForm.value.hasOrderedQuantity,
      this.filterForm.value.obsoleteWithAccepted,
      undefined,
      this.filterForm.value.dateFrom,
      this.filterForm.value.dateTo
    );
  }
}
