<div [ngClass]="{ 'black-panel': openFilter, panel: !openFilter }">
  <mat-expansion-panel class="filter-position" [attr.expanded]="openFilter" (opened)="openEvent()" (closed)="closeEvent()">
    <mat-expansion-panel-header class="filt-custom">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-9">
            <mat-chip-list>
              <mat-chip *ngFor="let f of filtersMap">{{ f.filter }}</mat-chip>
            </mat-chip-list>
          </div>
          <div class="col-md-2">
            <mat-panel-title class="filter-title"> Filters </mat-panel-title>
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>

    <div class="row filters-row">
      <form>
        <div class="col-md-12 text-blue margin-top-5 border-bottom-orange">
          <div class="row">
            <div class="col-md-2"></div>

            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select
                  filterKey="name"
                  name="brand"
                  [(ngModel)]="filters.brand"
                  placeholder="Brand"
                  [automaticSearch]="false"
                  (filterChange)="filterBrands($event)"
                >
                  <mat-option [value]="null">None</mat-option>
                  <mat-option *appMatSelectFor="let brand of brands | async; maxRows: 200" [value]="brand.name">
                    {{ brand.name }}
                  </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select
                  filterKey="period"
                  [(ngModel)]="filters.seasonId"
                  name="season"
                  placeholder="Season"
                  (ngModelChange)="filters.season = findElement(seasons, $event)"
                >
                  <mat-option [value]="null">None</mat-option>
                  <mat-option *appMatSelectFor="let season of seasons" [value]="season.id">
                    {{ season.period }}
                  </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select
                  filterKey="description"
                  [(ngModel)]="filters.supplierId"
                  name="supplier"
                  placeholder="Supplier"
                  (ngModelChange)="filters.supplier = findElement(suppliers, $event)"
                >
                  <mat-option [value]="null">None</mat-option>
                  <mat-option *appMatSelectFor="let supplier of suppliers" [value]="supplier.id">
                    {{ supplier.description }}
                  </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select
                  filterKey="name"
                  [(ngModel)]="filters.categoryId"
                  name="category"
                  placeholder="Category"
                  (ngModelChange)="filters.category = findElement(categories, $event)"
                >
                  <mat-option [value]="null">None</mat-option>
                  <mat-option *appMatSelectFor="let cat of categories" [value]="cat.id">
                    {{ cat.name }}
                  </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2"></div>
          </div>

          <div class="row">
            <div class="col-md-2"></div>

            <div class="col-md-2">
              <mat-form-field matTooltip="EAN/UPC/SKU/Article Code/Catalog Items SKU">
                <textarea
                  matInput
                  name="codes"
                  [(ngModel)]="filters.codes"
                  placeholder="EAN/UPC/SKU/Article Code/Catalog Items SKU"
                ></textarea>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select name="status" [(ngModel)]="filters.status" placeholder="Product status">
                  <mat-option [value]="null"> None </mat-option>
                  <mat-option class="text-capitalize" [value]="s" *appMatSelectFor="let s of productStatus">{{ s }} </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <mat-select name="hasPo" [(ngModel)]="filters.hasPo" id="hasPo" placeholder="Has PO">
                  <mat-option [value]="null">All</mat-option>
                  <mat-option [value]="true">True</mat-option>
                  <mat-option [value]="false">False</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-4"></div>
          </div>
        </div>

        <div class="col-md-12 border-top-white">
          <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-2 center">
              <button class="box-shadow-none btn-custom" type="submit" (click)="onFilter()">Filter</button>
            </div>
            <div class="col-md-2 center">
              <button class="box-shadow-none btn-custom" type="button" (click)="resetFilters()">Reset</button>
            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
      </form>

      <mat-divider></mat-divider>
    </div>
  </mat-expansion-panel>
</div>
