import { JsonObject, JsonProperty } from 'json2typescript';
import { isNil, isNilty } from '../../core/utils.service';
import { DateConverter, fromDateToFiltersString } from '../date-converter';
import { MatChipsData } from '../mat-chips-data-model';
import { Status, StatusConverter } from '../status-model';
import { PaginationAndSorting } from './pagination-and-sorting-model';

@JsonObject('PaymentDocumentFilters')
export class PaymentDocumentFilters extends PaginationAndSorting {
  @JsonProperty('documentNumber', String, true) documentNumber: string = undefined;
  @JsonProperty('supplier', String, true) supplier: string = undefined;
  @JsonProperty('documentDate', DateConverter, true) documentDate: Date = undefined;
  @JsonProperty('status', StatusConverter, true) status: Status = undefined;
  @JsonProperty('shipments', [String], true) shipments: string[] = undefined;
}

export const paymentDocumentFiltersMap = (
  documentNumber: string,
  supplier: string,
  documentDate: Date,
  status: Status,
  shipments: string[]
): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (!isNilty(documentNumber)) {
    map.push(new MatChipsData('Document Number', documentNumber));
  }
  if (!isNilty(supplier)) {
    map.push(new MatChipsData('Supplier', supplier));
  }
  if (!isNil(documentDate)) {
    map.push(new MatChipsData('Document Date', fromDateToFiltersString(documentDate)));
  }
  if (!isNilty(status)) {
    map.push(new MatChipsData('Status', status.description));
  }
  if (!isNilty(shipments)) {
    map.push(new MatChipsData('Shipments', shipments.join(',')));
  }

  return map;
};
