import { Component, Input, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { InvoiceService } from '../../../container/main-section/main-invoices/invoice.service';
import { AuthorizationService } from '../../../core/authorization.service';
import { Invoice } from '../../../models/invoice-model';
import { Permissions } from '../../../models/permission-model';
import { Claim } from '@models/claim-model';

@Component({
  selector: 'app-single-invoice-kpi',
  templateUrl: './single-invoice-kpi.component.html',
  styleUrls: ['./single-invoice-kpi.component.css'],
})
export class SingleInvoiceKpiComponent implements OnInit {
  invoice: Invoice;
  probableNC = false;
  hasInvoiceManagement: Observable<boolean>;

  // eslint-disable-next-line
  @Input('invoice') set _invoice(inv: Invoice) {
    this.invoice = inv;
    this.probableNC = this.invoice.probableNC;
  }

  @Input() claims: Claim[];

  constructor(private authorizationService: AuthorizationService, private invoiceService: InvoiceService, public snackBar: MatSnackBar) {}

  ngOnInit() {
    this.probableNC = this.invoice.probableNC;
    this.hasInvoiceManagement = this.authorizationService.hasPermission(Permissions.InvoiceManagement);
  }

  onProbableNC(event: MatCheckboxChange) {
    this.invoiceService.setProbableNC(this.invoice.id, event.checked).subscribe(() => {
      this.probableNC = event.checked;
      this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
    });
  }

  computeTotalAmount() {
    if (this.invoice.shipment.totalShippedValue > 0) {
      return this.invoice.shipment.totalShippedValue;
    } else {
      return this.invoice.shipment.totalAcceptedValue;
    }
  }

  computeRemaining(claims: Claim[], type: 'SC' | 'PC') {
    if (claims.length > 0) {
      const last = claims[claims.length - 1];
      if (last.code === type) {
        return last.amountPaid;
      }
    }
    return 0;
  }

  claimQuantity(claims: Claim[]) {
    if (claims.length > 0) {
      const last = claims[claims.length - 1];
      if (last.claimDetails) {
        return last.claimDetails.reduce((sum, claimDetail) => sum + claimDetail.claimQuantity, 0);
      }
      return 0;
    }
  }
}
