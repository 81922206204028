import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { addCheckers } from '../../../../../shared/checkers.service';
import { isNilty } from '../../../../../core/utils.service';
import { PaymentDocumentService } from '../payment-document.service';
import { PaymentDocument } from '../../../../../models/payment-document-model';
import { PaymentDocumentFilters } from '../../../../../models/filters/payment-document-filters-model';
import { Router } from '@angular/router';
import { PaymentRequestService } from '../../main-payment-requests/payment-request.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthorizationService } from '../../../../../core/authorization.service';
import { Permissions } from '../../../../../models/permission-model';

@Component({
  selector: 'app-payment-document-list',
  templateUrl: './payment-document-list.component.html',
  styleUrls: ['./payment-document-list.component.css'],
})
export class PaymentDocumentListComponent implements OnInit {
  dataSource: MatTableDataSource<any>;

  displayedColumns = [
    'documentNumber',
    'supplier',
    'documentType',
    'documentDate',
    'status',
    'value',
    'quantity',
    'shipments',
    'shipmentsValue',
    'shipmentsQuantity',
    'downloadDocument',
  ];

  selectedIds: number[] = [];
  mainChecker = false;
  bulkActionsActive = false;
  resultsNumber: Observable<number>;

  hasPaymentManagement: Observable<boolean>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private paymentDocumentService: PaymentDocumentService,
    private paymentRequestService: PaymentRequestService,
    private router: Router,
    private snackBar: MatSnackBar,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit() {
    this.hasPaymentManagement = this.authorizationService.hasPermission(Permissions.PaymentManagement);

    this.hasPaymentManagement.subscribe((value) => {
      if (value) {
        this.displayedColumns.splice(0, 0, 'check');
        this.displayedColumns.push('actions');
      }
    });

    this.resultsNumber = this.paymentDocumentService.resultsNumber;

    if (isNilty(this.paymentDocumentService.paymentDocumentFilters)) {
      this.paymentDocumentService.paymentDocumentFilters = new PaymentDocumentFilters();
    }

    this.paginator.pageSize = this.paymentDocumentService.paymentDocumentFilters.pageSize;
    this.paginator.pageIndex = this.paymentDocumentService.paymentDocumentFilters.pageNumber;

    this.dataSource = new MatTableDataSource([]);
    this.loadPaymentDocuments(this.paymentDocumentService.paymentDocumentFilters);
  }

  loadPaymentDocuments(filters: PaymentDocumentFilters) {
    this.paymentDocumentService.getFiltered(filters).subscribe((ch: PaymentDocument[]) => {
      this.dataSource.data = addCheckers(ch, this.selectedIds, this.mainChecker);
    });
  }

  loadPage() {
    this.paymentDocumentService.paymentDocumentFilters.pageNumber = this.paginator.pageIndex;
    this.paymentDocumentService.paymentDocumentFilters.pageSize = this.paginator.pageSize;
    this.loadPaymentDocuments(this.paymentDocumentService.paymentDocumentFilters);
  }

  onSort() {
    this.paymentDocumentService.paymentDocumentFilters.sortBy = this.sort.active;
    this.paymentDocumentService.paymentDocumentFilters.sortDirection = this.sort.direction;
    this.loadPaymentDocuments(this.paymentDocumentService.paymentDocumentFilters);
  }

  onFilter(filters: PaymentDocumentFilters) {
    this.paginator.pageIndex = 0;
    this.loadPaymentDocuments(filters);
  }

  onSelected(value: boolean, selectedClaim: any) {
    const selectedIndex = this.dataSource.data.findIndex((a) => a.id === selectedClaim.id);
    this.dataSource.data[selectedIndex].checked = value;
    if (this.mainChecker) {
      this.mainChecker = false;
    }
    if (value !== this.mainChecker) {
      this.selectedIds.push(this.dataSource.data[selectedIndex].id);
      this.selectedIds.filter((v, i, a) => a.indexOf(v) === i);
    } else {
      const index = this.selectedIds.indexOf(this.dataSource.data[selectedIndex].id);
      this.selectedIds.splice(index, 1);
    }
    this.bulkActionsActive = this.dataSource.data.some((a) => a.checked) || this.mainChecker;
  }

  toggleAll(value: boolean) {
    this.mainChecker = value;
    this.dataSource.data.forEach((a) => (a.checked = value));
    this.selectedIds = [];
    this.bulkActionsActive = value;
  }

  resetCheckers() {
    this.selectedIds = [];
    this.mainChecker = false;
    this.bulkActionsActive = false;
  }

  newPaymentDocument() {
    this.router.navigate(['payments', 'documents', 'new']);
  }

  createPaymentRequest() {
    let request: Observable<any>;
    if (this.mainChecker) {
      request = this.paymentRequestService.createPaymentRequestFromFilters(this.paymentDocumentService.paymentDocumentFilters);
    } else {
      request = this.paymentRequestService.createPaymentRequest(this.selectedIds);
    }

    request.subscribe(() => {
      this.resetCheckers();
      this.snackBar.open('Payment Request created.', 'CLOSE')._dismissAfter(2000);
      this.loadPaymentDocuments(this.paymentDocumentService.paymentDocumentFilters);
    });
  }

  joinShipmentsOrderId(pd: PaymentDocument): string {
    return pd.shipments.map((s) => s.orderId).join(', ');
  }

  computeShipmentsValue(pd: PaymentDocument): number {
    return pd.shipments.reduce((v, s) => {
      if (s.totalShippedQuantity > 0) {
        return v + s.totalPurchasePrice;
      } else {
        return v + s.totalAcceptedPurchasePrice;
      }
    }, 0);
  }

  computeShipmentsQuantity(pd: PaymentDocument): number {
    return pd.shipments.reduce((v, s) => {
      if (s.totalShippedQuantity > 0) {
        return v + s.totalShippedQuantity;
      } else {
        return v + s.totalAcceptedQuantity;
      }
    }, 0);
  }

  downloadDocument(pd: PaymentDocument) {
    window.open(pd.documentUrl, '_blank');
  }

  delete(pd: PaymentDocument) {
    this.paymentDocumentService.deletePaymentDocument(pd.id).subscribe(() => {
      this.snackBar.open('Payment document ' + pd.documentNumber + '.', 'CLOSE')._dismissAfter(2000);
      this.loadPaymentDocuments(this.paymentDocumentService.paymentDocumentFilters);
    });
  }
}
