import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { PublicationApproved } from '@models/publication-approved-model';
import { Publication } from '@models/publication-model';
import { PublicationsService } from '../publications.service';

@Component({
  selector: 'app-publication-approved-history',
  templateUrl: './publication-approved-history.component.html',
  styleUrls: ['./publication-approved-history.component.css'],
})
export class PublicationApprovedHistoryComponent implements OnInit {
  dataSource: MatTableDataSource<PublicationApproved>;
  displayedColumns = ['date', 'price', 'email'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    public dialogRef: MatDialogRef<PublicationApprovedHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public publicationsService: PublicationsService
  ) {}

  ngOnInit(): void {
    this.publicationsService.getPublication(this.data.publication.id).subscribe((resp: Publication) => {
      this.dataSource = new MatTableDataSource(resp.publicationApprovedHistory);
      this.dataSource.paginator = this.paginator;
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
