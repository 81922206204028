<div>
  <div class="row">
    <div class="col-xs-8 text-blue font-Titillium">
      <h2 mat-dialog-title>{{ data.vendorCentralAccount.name }}</h2>
    </div>
    <div class="col-xs-4" style="text-align: right; align-content: right">
      <button class="btn-custom" (click)="closeDialog()">Close</button>
    </div>
  </div>

  <div mat-dialog-content>
    <div class="table-container">
      <div class="table-header">
        <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search" />
        </mat-form-field>
      </div>

      <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]"> </mat-paginator>

      <mat-table #listingTable [dataSource]="dataSource">
        <ng-container matColumnDef="code">
          <mat-header-cell *matHeaderCellDef> Code </mat-header-cell>
          <mat-cell *matCellDef="let vendor"> {{ vendor.code }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
          <mat-cell *matCellDef="let vendor"> {{ vendor.name }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="country">
          <mat-header-cell *matHeaderCellDef> Country </mat-header-cell>
          <mat-cell *matCellDef="let vendor"> {{ vendor.country }} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>
</div>
