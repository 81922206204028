import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PaymentAdjustment } from '../../../../../../models/payment-adjustment';
import { isNilty } from '../../../../../../core/utils.service';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../../../../../../core/authorization.service';
import { Permissions } from '../../../../../../models/permission-model';

@Component({
  selector: 'app-adjustments-table',
  templateUrl: './adjustments-table.component.html',
  styleUrls: ['./adjustments-table.component.css'],
})
export class AdjustmentsTableComponent implements OnInit {
  @Input() set adjustments(values: PaymentAdjustment[]) {
    if (!isNilty(values)) {
      this.dataSource = new MatTableDataSource(values);
    }
  }
  @Input() isEditable: boolean;

  @Output() adjustmentDeleted = new EventEmitter<number>();

  dataSource: MatTableDataSource<PaymentAdjustment>;
  displayedColumns = [
    'type',
    'value',
    'causal'
  ];
  hasPaymentManagement: Observable<boolean>;

  constructor(
    private authorizationService: AuthorizationService
  ) { }

  ngOnInit() {
    this.hasPaymentManagement = this.authorizationService.hasPermission(Permissions.PaymentManagement);

    this.hasPaymentManagement.subscribe((value) => {
      if (value) {
        this.displayedColumns.push('actions');
      }
    });
  }

  delete(adjustmentId) {
    this.adjustmentDeleted.emit(adjustmentId);
  }
}
