import { Component, Input, OnInit } from '@angular/core';
import { MarginConstraint } from '@models/publication-rule';

@Component({
  selector: 'app-margin-constraints',
  templateUrl: './margin-constraints.component.html',
  styleUrls: ['./margin-constraints.component.css'],
})
export class MarginConstraintsComponent implements OnInit {
  @Input() marginConstraints: MarginConstraint[];

  constructor() {}

  ngOnInit(): void {}
}
