<app-warehouse-fulfilment-filters (onFilter)="onFilter($event)"></app-warehouse-fulfilment-filters>

<app-warehouse-fulfilment-kpi></app-warehouse-fulfilment-kpi>

<app-warehouse-fulfilment-bulk-actions
  (onDelete)="delete()"
  [bulkActionsActive]="bulkActionsActive"
  (onExport)="export($event)"
></app-warehouse-fulfilment-bulk-actions>

<div class="container table-outer">
  <mat-paginator #paginator [length]="resultsNumber | async" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()">
  </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="check" sticky>
        <mat-header-cell *matHeaderCellDef class="checker-col">
          <mat-checkbox (change)="toggleAll($event.checked)" [ngModel]="mainChecker"></mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let ful; let i = index" class="checker-col">
          <mat-checkbox (change)="onSelected($event.checked, ful)" [ngModel]="ful.checked"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="poId" sticky>
        <mat-header-cell class="po-code-column" *matHeaderCellDef mat-sort-header="poId"> PO ID </mat-header-cell>
        <mat-cell class="po-code-column" *matCellDef="let ful">{{ ful.purchaseOrderItem.purchaseOrder.poId }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="vendor">
        <mat-header-cell class="vendor-column" *matHeaderCellDef mat-sort-header="vendor"> Vendor </mat-header-cell>
        <mat-cell class="vendor-column" *matCellDef="let ful">{{
          ful.purchaseOrderItem.purchaseOrder.vendor ? ful.purchaseOrderItem.purchaseOrder.vendor.code : ''
        }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="destination">
        <mat-header-cell class="destination-column" *matHeaderCellDef mat-sort-header="destination"> Destination </mat-header-cell>
        <mat-cell
          class="destination-column"
          *matCellDef="let ful"
          [matTooltip]="
            ful.purchaseOrderItem.purchaseOrder.destination
              ? ful.purchaseOrderItem.purchaseOrder.destination.code + ' - ' + ful.purchaseOrderItem.purchaseOrder.destination.name
              : ''
          "
          matTooltipPosition="below"
        >
          <p>
            {{
              ful.purchaseOrderItem.purchaseOrder.destination
                ? ful.purchaseOrderItem.purchaseOrder.destination.code + ' - ' + ful.purchaseOrderItem.purchaseOrder.destination.name
                : ''
            }}
          </p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ean" sticky>
        <mat-header-cell class="ean-column" *matHeaderCellDef mat-sort-header="ean"> EAN </mat-header-cell>
        <mat-cell class="ean-column" *matCellDef="let ful">
          <a [routerLink]="['/inventory/products', ful.purchaseOrderItem.product.id, 'details']">{{ ful.purchaseOrderItem.product.ean }}</a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actualEan">
        <mat-header-cell class="ean-column" *matHeaderCellDef mat-sort-header="actualEan"> Actual EAN </mat-header-cell>
        <mat-cell class="ean-column" *matCellDef="let ful">{{ ful.actualEan }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="actualSupplierCode">
        <mat-header-cell class="ean-column" *matHeaderCellDef mat-sort-header="actualSupplierCode"> Actual Supplier Code </mat-header-cell>
        <mat-cell class="ean-column" *matCellDef="let ful">{{ ful.actualSupplierCode }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="title">
        <mat-header-cell class="title-column" *matHeaderCellDef mat-sort-header="title"> Title </mat-header-cell>
        <mat-cell class="title-column" *matCellDef="let ful">
          <p>{{ ful.purchaseOrderItem.product.title }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="stockLocation">
        <mat-header-cell class="origin-column" *matHeaderCellDef mat-sort-header="stockLocation">
          Stock <br />
          location
        </mat-header-cell>
        <mat-cell class="origin-column" *matCellDef="let ful">
          <p>{{ ful.stockLocation.name }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="crossUkBorder">
        <mat-header-cell class="extra-flag-column" *matHeaderCellDef> Cross UK </mat-header-cell>
        <mat-cell class="extra-flag-column" *matCellDef="let ful">
          <i [ngClass]="ful.crossUkBorder | booleanIconPipe" aria-hidden="true"></i>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="submittedQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="submittedQuantity">
          Submitted <br />
          quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let ful">
          <p>{{ ful.purchaseOrderItem.submittedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="availableQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="availableQuantity">
          Available <br />
          quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let ful">
          <p>{{ ful.availableQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="unitCost">
        <mat-header-cell class="value-column-title-small" *matHeaderCellDef mat-sort-header="unitCost">
          Unit <br />
          cost
        </mat-header-cell>
        <mat-cell class="value-column-title" *matCellDef="let ful">
          <p>{{ ful.purchaseOrderItem.unitCost | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="totalSubmittedCost">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="totalSubmittedCost">
          Total <br />
          submitted cost
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let ful">
          <p>{{ ful.purchaseOrderItem.totalCost | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="totalAvailableCost">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="totalAvailableCost">
          Total <br />
          available cost
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let ful">
          <p>{{ ful.availableValue | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
