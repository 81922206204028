import { Injectable, isDevMode } from '@angular/core';
import { Publication } from '../../../models/publication-model';
import { PublicationFilters } from '../../../models/filters/publication-filters-model';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConverterService } from '../../../core/converter.service';
import { PublicationStatusPipe } from '../../../shared/my-pipes';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../../../environment.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Status } from '@models/status-model';

@Injectable()
export class PublicationsService {
  publications: Publication[] = [];
  publicationFilters: PublicationFilters = null;
  publicationMainChecker = false;
  selectedPublications: number[] = [];
  resultsNumber = new Subject<number>();

  constructor(
    private http: HttpClient,
    private converter: ConverterService,
    private snackBar: MatSnackBar,
    private publicationStatusPipe: PublicationStatusPipe,
    private environmentService: EnvironmentService
  ) {}

  getPublication(id: number): Observable<Publication> {
    const path = this.environmentService.getRestEndpoint('publications') + '/' + id;

    return this.http.get(path).pipe(map((resp: Publication) => this.converter.fromJSONtoObj(resp, Publication)));
  }

  getFilteredPublications(filters: PublicationFilters): Observable<Publication[]> {
    const body = this.converter.fromObjtoJSON(filters);

    return this.http.post(this.environmentService.getRestEndpoint('publications'), body, { observe: 'response' }).pipe(
      map((resp) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((publResp: Publication[]) => {
        const filteredPubl: Publication[] = [];
        for (let i = 0; i < publResp.length; i++) {
          filteredPubl[i] = this.converter.fromJSONtoObj(publResp[i], Publication);
        }
        return filteredPubl;
      })
    );
  }

  getPublicationNewPriceStatus(): Observable<Status[]> {
    const path = this.environmentService.getRestEndpoint('publications') + '/new-price-status';
    return this.http.get(path).pipe(map((resp: Status[]) => resp.map((r) => this.converter.fromJSONtoObj(r, Status))));
  }

  addPublicationIdToSelected(id: number) {
    this.selectedPublications.push(id);
    this.selectedPublications.filter((v, i, a) => a.indexOf(v) === i);
  }
  removePublicationIdFromSelected(id: number) {
    const index = this.selectedPublications.indexOf(id);
    this.selectedPublications.splice(index, 1);
  }

  changeStatus(status: number, pubs: number[], filters: PublicationFilters, mainChecker: boolean) {
    if (mainChecker) {
      return this.changePublicationsStatusFromFilters(status, pubs, filters);
    } else {
      return this.changePublicationsStatus(status, pubs);
    }
  }

  changePublicationsStatus(status: number, publications: number[]): Observable<void> {
    let path = this.environmentService.getRestEndpoint('publications');

    if (!this.environmentService.dev) {
      path += '/change-status=' + status;
    }
    console.log(path);

    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(publications);

    return this.http.post(path, body, { headers }).pipe(
      map(() => {
        this.snackBar.open('State changed to ' + this.publicationStatusPipe.transform(status), 'CLOSE')._dismissAfter(2000);
        return;
      })
    );
  }

  changePublicationsStatusFromFilters(status: number, publications: number[], filters: PublicationFilters): Observable<void> {
    filters.ids = publications;
    const body = this.converter.fromObjtoJSON(filters);

    const path = isDevMode()
      ? this.environmentService.getRestEndpoint('publications')
      : this.environmentService.getRestEndpoint('publications') + '/change-status-from-filters=' + status;

    return this.http.post(path, body).pipe(
      map(() => {
        this.snackBar.open('State changed to ' + this.publicationStatusPipe.transform(status), 'CLOSE')._dismissAfter(2000);
        return;
      })
    );
  }

  changeNewPriceStatus(status: string, selectedPublications: number[], filters: PublicationFilters, mainChecker: boolean) {
    if (mainChecker) {
      return this.changePublicationsNewPriceStatusFromFilters(status, selectedPublications, filters);
    } else {
      return this.changePublicationsNewPriceStatus(status, selectedPublications);
    }
  }

  changePublicationsNewPriceStatus(status: string, selectedPublications: number[]): Observable<void> {
    const path = this.environmentService.getRestEndpoint('publications') + '/change-new-price-status=' + status;

    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(selectedPublications);
    return this.http.post(path, body, { headers }).pipe(
      map(() => {
        this.snackBar.open('New Price Status ' + status)._dismissAfter(2000);
        return;
      })
    );
  }

  changePublicationsNewPriceStatusFromFilters(status: string, selectedPubs: number[], filters: PublicationFilters): Observable<void> {
    filters.ids = selectedPubs;
    const path = this.environmentService.getRestEndpoint('publications') + '/change-new-price-status-from-filters=' + status;

    const body = this.converter.fromObjtoJSON(filters);
    return this.http.post(path, body).pipe(
      map(() => {
        this.snackBar.open('New Price Status ' + status)._dismissAfter(2000);
        return;
      })
    );
  }
}
