<div class="dialog-container">
  <h2 mat-dialog-title>Edit Claim</h2>

  <mat-dialog-content>
    <div class="row">
      <div class="col-xs-6">
        <mat-form-field class="width-100per">
          <input matInput [(ngModel)]="modalData.form.disputeId" placeholder="Dispute ID" />
        </mat-form-field>
      </div>

      <div class="col-xs-6">
        <mat-form-field class="width-100per">
          <input matInput [(ngModel)]="modalData.form.disputeDate" [matDatepicker]="disputeDate" placeholder="Dispute Date" />
          <mat-datepicker-toggle matSuffix [for]="disputeDate"></mat-datepicker-toggle>
          <mat-datepicker #disputeDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="row" *ngIf="modalData.showCreditNote">
      <div class="col-xs-12">
        <mat-checkbox [(ngModel)]="modalData.form.creditNote">Credit Note</mat-checkbox>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-4">
        <mat-form-field class="width-100per">
          <input #caseIdInput matInput placeholder="Case ID" />
        </mat-form-field>
      </div>

      <div class="col-xs-2">
        <button [disabled]="caseIdInput.value === ''" class="btn-product add-button" (click)="addCaseId(caseIdInput.value)">
          <i class="fa fa-plus padding-bottom-1" aria-hidden="true"></i> Add
        </button>
      </div>

      <div class="col-xs-6">
        <mat-form-field class="width-100per">
          <input matInput [(ngModel)]="modalData.form.caseDate" [matDatepicker]="caseDate" placeholder="Case Date" />
          <mat-datepicker-toggle matSuffix [for]="caseDate"></mat-datepicker-toggle>
          <mat-datepicker #caseDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <mat-list dense class="case-ids-list">
      <mat-list-item *ngFor="let caseId of modalData.form.caseIds; let i = index">
        <p>
          <button class="box-shadow-none actions-button btn-fixed margin-right-6" matTooltip="Remove" (click)="removeCaseId(i)">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
          {{ caseId }}
        </p>
      </mat-list-item>
    </mat-list>

    <div class="row">
      <div class="col-xs-6">
        <mat-form-field>
          <app-mat-select placeholder="Status" [(ngModel)]="modalData.form.status" filterKey="description">
            <mat-option [value]="null">None</mat-option>
            <mat-option [value]="st.code" *appMatSelectFor="let st of status">
              {{ st.description }}
            </mat-option>
          </app-mat-select>
        </mat-form-field>
      </div>

      <div class="col-xs-6">
        <button class="btn-delete" (click)="onDelete()" [mat-dialog-close]="null">Delete Claim</button>
      </div>
    </div>
  </mat-dialog-content>

  <div mat-dialog-actions>
    <button class="btn-product margin-right-6" [disabled]="isDisabled()" [mat-dialog-close]="modalData.form">Save</button>
    <button class="btn-product" cdkFocusInitial [mat-dialog-close]="null">Cancel</button>
  </div>
</div>
