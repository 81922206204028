<div [ngClass]="{ 'black-panel': openFilter, panel: !openFilter }">
  <mat-expansion-panel class="mat-elevation-z8 filter-position" [attr.expanded]="openFilter" (opened)="openEvent()" (closed)="closeevent()">
    <mat-expansion-panel-header class="filt-custom">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-9">
            <mat-chip-list>
              <mat-chip *ngFor="let f of filtersMap">{{ f.filter }}</mat-chip>
            </mat-chip-list>
          </div>
          <div class="col-md-2">
            <mat-panel-title class="filter-title"> Filters </mat-panel-title>
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>

    <div class="row filters-row">
      <form [formGroup]="filterForm" (submit)="onSubmitFilter()">
        <div class="col-md-12 text-blue margin-top-5 border-bottom-orange">
          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="invoiceNumber" placeholder="Invoice Number" />
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select
                  filterKey="name"
                  formControlName="vendorCentralAccount"
                  id="vendorCentralAccount"
                  placeholder="Vendor Central Account"
                >
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="vendor" *appMatSelectFor="let vendor of vendorAccounts">{{ vendor.name }}</mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select formControlName="actionType" placeholder="Action">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="action.code" *appMatSelectFor="let action of actionsType">{{ action.description }}</mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select formControlName="statusType" placeholder="Status">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="status.code" *appMatSelectFor="let status of statusType">{{ status.description }}</mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field class="small-mat-form-field">
                <input matInput formControlName="dateFrom" [matDatepicker]="dateFrom" placeholder="From Date" />
                <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
                <mat-datepicker #dateFrom></mat-datepicker>
              </mat-form-field>

              <mat-form-field class="small-mat-form-field margin-left-10">
                <input matInput formControlName="dateTo" [matDatepicker]="dateTo" placeholder="To Date" />
                <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
                <mat-datepicker #dateTo></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="disputeId" placeholder="Dispute Id" />
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="caseId" placeholder="Case Id" />
              </mat-form-field>
            </div>
          </div>

          <div class="col-md-12 border-top-white">
            <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-2 center">
                <button type="submit" class="box-shadow-none btn-custom" style="float: right">Filter</button>
              </div>
              <div class="col-md-2 center">
                <button type="button" (click)="resetFilters()" class="box-shadow-none btn-custom">Reset</button>
              </div>
              <div class="col-md-4"></div>
            </div>
          </div>
        </div>
      </form>

      <mat-divider></mat-divider>
    </div>
  </mat-expansion-panel>
</div>
