import { Component, OnDestroy } from '@angular/core';
import { InventoryService } from '../inventory.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
})
export class ProductsComponent implements OnDestroy {
  constructor(private inventoryService: InventoryService) {}

  ngOnDestroy() {
    if (environment.envName !== 'mock') {
      this.inventoryService.products = [];
    }
  }
}
