<div class="container main-content" style="margin-top: 3%">
  <form [formGroup]="paymentDocumentForm">
    <div class="row">
      <div class="col-md-3"></div>

      <div class="col-md-6 padding-bottom-20" [ngClass]="{ 'edit-border-bottom': editMode }">
        <div class="row">
          <legend>Payment Document</legend>

          <div class="row">
            <div class="col-md-6">
              <mat-form-field>
                <app-mat-select
                  id="supplier"
                  filterKey="description"
                  formControlName="supplier"
                  placeholder="Supplier"
                  (selectionChange)="changeSupplier()"
                >
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="s" *appMatSelectFor="let s of suppliers">
                    {{ s.description }}
                  </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-form-field>
                <app-mat-select id="documentType" filterKey="description" formControlName="documentType" placeholder="Document Type">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="dt" *appMatSelectFor="let dt of documentTypes">
                    {{ dt.description }}
                  </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <mat-form-field>
                <input matInput formControlName="documentNumber" placeholder="Document Number" />
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-form-field>
                <input matInput formControlName="documentDate" [matDatepicker]="documentDate" placeholder="Document Date" />
                <mat-datepicker-toggle matSuffix [for]="documentDate"></mat-datepicker-toggle>
                <mat-datepicker #documentDate></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <div class="row margin-top-20">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-3">
                  <button type="button" (click)="uploadDocumentFake()" class="btn-custom">Choose document</button>
                </div>
                <div class="col-md-9">
                  <span>{{ document?.name }}</span>
                </div>
                <input #getDocument style="display: none" type="file" onclick="this.value = null" (change)="uploadDocument($event)" />
              </div>
            </div>
          </div>

          <div class="row margin-top-20">
            <div class="col-md-4">
              <mat-form-field>
                <input matInput type="number" formControlName="value" placeholder="Value" />
              </mat-form-field>
            </div>

            <div class="col-md-4">
              <mat-form-field>
                <input matInput type="number" formControlName="vat" placeholder="Vat" />
              </mat-form-field>
            </div>

            <div class="col-md-4">
              <mat-form-field>
                <input matInput type="number" formControlName="quantity" placeholder="Quantity" />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <mat-form-field>
                <app-mat-select id="shipments" filterKey="orderId" formControlName="shipments" placeholder="Shipments" [multiple]="true">
                  <mat-option [value]="s" *appMatSelectFor="let s of shipments">
                    {{ s.orderId }} - {{ s.latestPickupDate }} -
                    {{ s.totalShippedQuantity > 0 ? s.totalShippedQuantity : s.totalAcceptedQuantity }}
                  </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="row">
    <div class="col-md-3"></div>

    <div class="col-md-6 text-center">
      <button type="submit" [disabled]="!paymentDocumentForm.valid || !document" (click)="onSubmit()" class="btn-product margin-right-20">
        Save
      </button>
      <button type="button" (click)="onCancel()" class="btn-product">Back</button>
    </div>
  </div>
</div>
