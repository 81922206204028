import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConverterService } from '../../../core/converter.service';
import { Vendor } from '../../../models/vendor-model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../../../environment.service';
import { Status } from '../../../models/status-model';
import { LotConfiguration } from '../../../models/lot-configuration-model';
import { GenerateOrderedConfigurationDto, LotConfigurationDTO } from '../../../models/lot-configuration-dto-model';

@Injectable()
export class LotConfigurationsService {
  parentVendors: Vendor[];
  vendors: Vendor[];
  checkTypes: Status[];
  paymentTerms: Status[];
  showOnlyParents = true;

  lotEditVendors: Vendor[] = undefined;
  lotEditParentVendors: Vendor[] = undefined;

  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  getLotConfigurations(): Observable<LotConfiguration[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('lotConfigurations'))
      .pipe(
        map((lotConfigurations: LotConfiguration[]) =>
          lotConfigurations.map((lotConfiguration) => this.converter.fromJSONtoObj(lotConfiguration, LotConfiguration))
        )
      );
  }

  getLotConfiguration(lotConfigurationId: number): Observable<LotConfiguration> {
    const path = this.environmentService.getRestEndpoint('lotConfigurations') + '/' + lotConfigurationId;

    return this.http
      .get(path)
      .pipe<LotConfiguration>(
        map((lotConfiguration: LotConfiguration) => this.converter.fromJSONtoObj(lotConfiguration, LotConfiguration))
      );
  }

  getGenerateOrderedConfigurations(lotConfigurationId: number): Observable<GenerateOrderedConfigurationDto[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('lotGenerateOrderedConfigurations') + lotConfigurationId)
      .pipe(
        map((lotConfigurations: GenerateOrderedConfigurationDto[]) =>
          lotConfigurations.map((l) => this.converter.fromJSONtoObj(l, GenerateOrderedConfigurationDto))
        )
      );
  }

  saveLotConfiguration(lotConfiguration: LotConfigurationDTO): Observable<boolean> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = this.converter.fromObjtoJSON(lotConfiguration);
    return this.http.post(this.environmentService.getRestEndpoint('lotConfigurations'), body, { headers }).pipe(map((resp: any) => resp));
  }
}
