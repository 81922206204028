<div class="dialog-container">
  <h1 mat-dialog-title>Select a stock location</h1>

  <div mat-dialog-content>
    <mat-form-field class="origin-select">
      <app-mat-select [(ngModel)]="selectedStockLocationId" filterKey="name">
        <mat-option [value]="null">-</mat-option>
        <mat-option *appMatSelectFor="let s of stockLocations" [value]="s.id">{{ s.code }} - {{ s.name }}</mat-option>
      </app-mat-select>
    </mat-form-field>
  </div>

  <div mat-dialog-actions>
    <div class="row width-100per">
      <div class="col-md-12 text-center width-100per">
        <button class="btn-product" (click)="onSubmit()">Save</button>
        <button class="btn-product" (click)="onClose()">Cancel</button>
      </div>
    </div>
  </div>
</div>
