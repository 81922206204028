<div class="row header-container">
  <div class="row">
    <div class="col-md-2">
      <button class="box-shadow-none btn-custom" [disabled]="configurationsMap.length == 0" (click)="new()">
        <i class="fa fa-plus padding-bottom-1 padding-right-5" aria-hidden="true"></i>New parameter
      </button>
    </div>
    <div class="col-md-10 text-right">
      <button class="box-shadow-none btn-custom" (click)="save()">Save</button>
    </div>
  </div>
</div>

<div class="container padding-top-60">
  <div class="row">
    <div class="col-md-12">
      <div class="row configuration-row" *ngFor="let row of configurations; let i = index" [ngClass]="{ 'even-background': i % 2 === 0 }">
        <ng-container *ngIf="!row.new">
          <div class="col-md-2" style="padding-top: 25px; font-size: 16px;">
            <i class="fa fa-info-circle margin-right-6" aria-hidden="true" [matTooltip]="row.description"></i>
            {{ row.type }}
          </div>

          <div class="col-md-9">
            <div class="row">
              <div class="col-md-2 padding-top-10" *ngFor="let param of row.parameters">
                <mat-form-field class="param-form-field" *ngIf="param.values && param.values.length !== 0">
                  <app-mat-select [placeholder]="param.key" [(ngModel)]="param.value">
                    <mat-option *appMatSelectFor="let s of param.values" [value]="s">{{ s }}</mat-option>
                  </app-mat-select>
                </mat-form-field>

                <mat-form-field class="param-form-field" *ngIf="!param.values || param.values.length === 0">
                  <input matInput [placeholder]="param.key" [(ngModel)]="param.value" />
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="col-md-1 text-right padding-top-18">
            <button (click)="delete(i)" class="box-shadow-none btn-custom smaller-icon-button" matTooltip="Delete configuration">
              <i class="fa fa-trash padding-bottom-1" aria-hidden="true"></i>
            </button>
          </div>
        </ng-container>

        <ng-container *ngIf="row.new">
          <div class="col-md-2 padding-top-4">
            <mat-form-field class="param-form-field">
              <app-mat-select placeholder="Type" [(ngModel)]="row.type">
                <mat-option *appMatSelectFor="let conf of configurationsMap" [value]="conf.type" [matTooltip]="conf.description">{{
                  conf.type
                }}</mat-option>
              </app-mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-2 add-div">
            <button class="box-shadow-none btn-custom" (click)="add(row)">
              <i class="fa fa-plus padding-bottom-1 padding-right-5" aria-hidden="true"></i>Add
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
