import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConverterService } from '@core/converter.service';
import { VendorCentralAccount } from '@models/vendor-central-account-model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../../../environment.service';
import { VendorCentralAccountDTO } from '@models/vendor-central-account-dto-model';

@Injectable({
  providedIn: 'root',
})
export class VendorCentralAccountsService {
  vendorCentralAccounts: VendorCentralAccount[];

  vendorAccountSelectedRow: VendorCentralAccount;

  resultsNumber = new Subject<number>();

  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  getVendorAccountAccounts(): Observable<VendorCentralAccount[]> {
    const path = this.environmentService.getRestEndpoint('vendorCentralAccounts');

    return this.http
      .get(path)
      .pipe(map((resp: VendorCentralAccount[]) => resp.map((v) => this.converter.fromJSONtoObj(v, VendorCentralAccount))));
  }

  saveVendorCentralAccount(vendorCentralAccountDTO: VendorCentralAccountDTO): Observable<boolean> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const path = this.environmentService.getRestEndpoint('vendorCentralAccounts');
    const body = this.converter.fromObjtoJSON(vendorCentralAccountDTO);

    return this.http.post(path, body, { headers }).pipe(map((resp: any) => resp));
  }

  getVendorCentralAccount(vendorCentralAccountId: number): Observable<VendorCentralAccount> {
    const path = this.environmentService.getRestEndpoint('vendorCentralAccounts') + '/' + vendorCentralAccountId;

    return this.http.get(path).pipe(map((resp: VendorCentralAccount) => this.converter.fromJSONtoObj(resp, VendorCentralAccount)));
  }

  deleteVendor(vendorCentralAccountId: number): Observable<boolean> {
    const path = this.environmentService.getRestEndpoint('vendorCentralAccounts') + '/' + vendorCentralAccountId;

    return this.http.delete(path).pipe(map(() => true));
  }
}
