import { Component, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { isNilty } from '../../../../core/utils.service';
import { PublicationFilters, publicationFiltersMap } from '../../../../models/filters/publication-filters-model';
import { MatChipsData } from '../../../../models/mat-chips-data-model';
import { PublicationStatus, publicationStatusList } from '../../../../models/publication-status';
import { Vendor } from '../../../../models/vendor-model';
import { VendorsService } from '../../../configuration/vendors/vendors.service';
import { PublicationsService } from '../publications.service';
import { Status } from '@models/status-model';

@Component({
  selector: 'app-publications-filters',
  templateUrl: './publications-filters.component.html',
  styleUrls: ['./publications-filters.component.css'],
})
export class PublicationsFiltersComponent implements OnInit {
  constructor(private vendorsService: VendorsService, private publicationsService: PublicationsService) {}

  filters: PublicationFilters;
  vendors: Vendor[] = [];
  public openFilter = false;
  filtersMap: MatChipsData[] = [];

  publicationsStatus: PublicationStatus[];
  publicationsNewPriceStatus: Status[] = [];

  @Output() onFilterEvent = new Subject<PublicationFilters>();

  ngOnInit() {
    this.publicationsStatus = publicationStatusList;

    this.filters = isNilty(this.publicationsService.publicationFilters)
      ? new PublicationFilters()
      : this.publicationsService.publicationFilters;

    this.createFiltersMap();

    this.vendorsService.getVendors().subscribe((vendors: Vendor[]) => {
      this.vendors = vendors;
    });
    this.publicationsService.getPublicationNewPriceStatus().subscribe((resp: Status[]) => (this.publicationsNewPriceStatus = resp));
  }

  vendorChange(event) {
    if (event.value.indexOf(null) !== -1) {
      this.filters.vendors = [];
    }
  }

  onFilter() {
    this.saveFiltersToService();
    this.createFiltersMap();
    this.onFilterEvent.next(this.filters);
  }

  saveFiltersToService() {
    this.publicationsService.publicationFilters = this.filters;
  }

  resetFilters() {
    this.filtersMap = [];
    this.filters = new PublicationFilters();
    this.onFilterEvent.next(this.filters);
  }

  createFiltersMap() {
    this.filtersMap = publicationFiltersMap(
      this.filters.eans === '' ? null : this.filters.eans,
      isNilty(this.filters.vendors) ? null : this.filters.vendors.length.toString(),
      this.filters.productStatus,
      this.filters.publicationStatus,
      this.filters.newPriceStatus,
      this.filters.pendingApprovalDateFrom,
      this.filters.pendingApprovalDateTo
    );
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeEvent() {
    this.openFilter = false;
  }
}
