import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConverterService } from '@core/converter.service';
import { DisputeBotLog } from '@models/dispute-bot-log-model';
import { DisputeBotLogFilters } from '@models/filters/dispute-bot-filters-model';
import { Status } from '@models/status-model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../../../../environment.service';

@Injectable({
  providedIn: 'root',
})
export class DisputeBotLogService {
  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  disputeBotLogs: DisputeBotLog[] = [];
  selectedDisputeBotLogs: number[] = [];

  resultsNumber = new Subject<number>();
  totalDisputeBotLogs: number;

  disputeBotLogFilters: DisputeBotLogFilters;

  getDisputeBotLogActionsType(): Observable<Status[]> {
    const path = this.environmentService.getRestEndpoint('disputeBotLogs') + '/actions-type';
    return this.http.get(path).pipe(map((actions: Status[]) => actions.map((a) => this.converter.fromJSONtoObj(a, Status))));
  }

  getDisputeBotLogStatusType(): Observable<Status[]> {
    const path = this.environmentService.getRestEndpoint('disputeBotLogs') + '/status-type';
    return this.http.get(path).pipe(map((actions: Status[]) => actions.map((a) => this.converter.fromJSONtoObj(a, Status))));
  }

  getFilteredDisputeBotLogs(filters: DisputeBotLogFilters): Observable<DisputeBotLog[]> {
    const path = this.environmentService.getRestEndpoint('disputeBotLogs');
    const body = this.converter.fromObjtoJSON(filters);

    return this.http.post(path, body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.totalDisputeBotLogs = +resp.headers.get('Total-Length');
        this.resultsNumber.next(this.totalDisputeBotLogs);
        return resp.body;
      }),
      map((botResp: DisputeBotLog[]) => botResp.map((r) => this.converter.fromJSONtoObj(r, DisputeBotLog)))
    );
  }
}
