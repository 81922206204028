<div class="container">
  <div class="row title-row">
    <div class="col-md-8">
      <h3>{{ product?.title }}</h3>
    </div>

    <div class="col-md-4 text-right padding-top-16">
      <button class="btn-custom margin-right-6" [routerLink]="['/inventory/products/', product?.id, 'edit']">Edit</button>
      <button class="btn-custom" (click)="goBack()">Back</button>
    </div>
  </div>

  <div class="row padding-top-10">
    <div class="col-md-2 background-blue text-white padding-bottom-50 details-box">
      <p><b>EAN:</b> {{ product?.ean }}</p>
      <p><b>UPC:</b> {{ product?.upc }}</p>
      <p><b>ASIN:</b> {{ product?.asin }}</p>
      <p><b>SKU:</b> {{ product?.sku }}</p>
    </div>

    <div class="col-md-2 background-light-blue text-white padding-bottom-50 details-box">
      <p><b>Brand:</b> {{ product?.brand?.name }}</p>
      <p><b>Category:</b> {{ product?.category?.name }}</p>
      <p><b>Season:</b> {{ product?.season?.period }}</p>
      <p><b>Wholesale price:</b> {{ product?.wholesalePrice | myCurrency }}</p>
      <p><b>Retail price:</b> {{ product?.retailPrice | myCurrency }}</p>
    </div>

    <div class="col-md-2 background-blue text-white padding-bottom-50 details-box">
      <p><b>Article code:</b> {{ product?.articleCode }}</p>
      <p><b>Size:</b> {{ product?.size }}</p>
      <p><b>Color:</b> {{ product?.color }}</p>
      <p><b>Color code:</b> {{ product?.colorCode }}</p>
    </div>

    <div class="col-md-2 background-light-blue text-white padding-bottom-50 details-box">
      <p><b>Export HS code:</b> {{ product?.commodity?.exportHsCode }}</p>
      <p><b>Import HS code:</b> {{ product?.commodity?.importHsCode }}</p>
      <p><b>Vat:</b> {{ product?.commodity?.vat | percentage }}</p>
      <p><b>Duty:</b> {{ product?.commodity?.duty | percentage }}</p>
    </div>

    <div class="col-md-2 background-blue text-white padding-bottom-50 details-box">
      <p><b>Status:</b> {{ product?.status }}</p>
      <p><b>Created at:</b> {{ product?.createdAt }}</p>
      <p><b>Updated at:</b> {{ product?.updatedAt }}</p>
    </div>

    <div class="col-md-2 background-light-blue text-white padding-bottom-50 details-box">
      <p><b>Amazon Quantity:</b> {{ productDetails?.amazonQuantitySellable }}</p>
      <p><b>Has POs:</b> <i style="margin-left: 6px" [ngClass]="product?.hasPo | booleanIconPipe" aria-hidden="true"></i></p>
      <p><b>Sent to NAV:</b> <i style="margin-left: 6px" [ngClass]="product?.sentToNav | booleanIconPipe" aria-hidden="true"></i></p>
    </div>
  </div>

  <div class="row padding-top-10">
    <div class="col-md-4 background-blue text-white padding-bottom-50 details-box">
      <h4 class="h-padding-box"><b>Last 30 days</b></h4>
      <p><b>Submitted quantity:</b> {{ productDetails?.lastThirtyDaysQuantity?.submittedQuantity || 0 }}</p>
      <p><b>Accepted quantity:</b> {{ productDetails?.lastThirtyDaysQuantity?.acceptedQuantity || 0 }}</p>
    </div>

    <div class="col-md-4 background-light-blue text-white padding-bottom-50 details-box">
      <h4 class="h-padding-box"><b>Last 90 days</b></h4>
      <p><b>Submitted quantity:</b> {{ productDetails?.lastNinetyDaysQuantity?.submittedQuantity || 0 }}</p>
      <p><b>Accepted quantity:</b> {{ productDetails?.lastNinetyDaysQuantity?.acceptedQuantity || 0 }}</p>
    </div>

    <div class="col-md-4 background-blue text-white padding-bottom-40 details-box">
      <h4 class="h-padding-box"><b>This Year</b></h4>
      <p><b>Submitted quantity:</b> {{ productDetails?.yearQuantity?.submittedQuantity || 0 }}</p>
      <p><b>Accepted quantity:</b> {{ productDetails?.yearQuantity?.acceptedQuantity || 0 }}</p>
    </div>
  </div>

  <div class="row padding-top-5" *ngIf="productDetails.keepaProductDetail">
    <app-keepa-details-accordion [_keepaDetails]="productDetails.keepaProductDetail"></app-keepa-details-accordion>
  </div>

  <div class="row margin-top-16" style="padding-left: 5px" *ngIf="product?.commodity?.descriptions">
    <h4>Comodity description</h4>
    <p *ngFor="let description of product?.commodity?.descriptions">
      {{ description }}
    </p>
  </div>

  <div class="row margin-top-16">
    <div class="col-md-12">
      <app-catalog-items-table [items]="productDetails?.catalogItems"></app-catalog-items-table>
    </div>
  </div>

  <div class="row">
    <div class="col-md-7">
      <app-last-pos-table [pois]="productDetails?.lastPoItems"></app-last-pos-table>
    </div>

    <div class="col-md-5">
      <app-active-publications-table [publications]="productDetails?.activePublications"></app-active-publications-table>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <app-supplier-discount-product [supplierDiscount]="productDetails?.supplierDiscount"></app-supplier-discount-product>
    </div>
  </div>
</div>
