<div class="container main-content" style="margin-top: 3%">
  <form [formGroup]="productForm" (ngSubmit)="onSubmit()" id="form-product">
    <div class="row">
      <div class="col-md-3"></div>

      <div class="col-md-6 padding-bottom-20 edit-border-bottom">
        <div class="row">
          <div class="col-md-12">
            <legend>Product</legend>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <mat-form-field style="width: 100%">
              <input matInput type="text" id="title" formControlName="title" placeholder="Title" />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <mat-form-field>
              <input matInput type="text" id="ean" formControlName="ean" placeholder="EAN*" />
            </mat-form-field>
          </div>

          <div class="col-md-6 text-right">
            <mat-form-field>
              <input matInput type="text" id="sku" formControlName="sku" placeholder="SKU" />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <mat-form-field>
              <input matInput type="number" id="wholesalePrice" formControlName="wholesalePrice" placeholder="Wholesale Price" />
            </mat-form-field>
          </div>

          <div class="col-md-6 text-right">
            <mat-form-field>
              <input matInput type="number" id="retailPrice" formControlName="retailPrice" placeholder="Retail Price" />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <mat-form-field>
              <input matInput type="text" id="articleCode" formControlName="articleCode" placeholder="Article Code" />
            </mat-form-field>
          </div>

          <div class="col-md-6 text-right">
            <mat-form-field>
              <input matInput type="text" id="size" formControlName="size" placeholder="Size" />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <mat-form-field>
              <input matInput type="text" id="color" formControlName="color" placeholder="Color" />
            </mat-form-field>
          </div>

          <div class="col-md-6 text-right">
            <mat-form-field>
              <input matInput type="text" id="colorCode" formControlName="colorCode" placeholder="Color Code" />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <mat-form-field>
              <app-mat-select
                filterKey="name"
                formControlName="brand"
                placeholder="Brand"
                [automaticSearch]="false"
                (filterChange)="filterBrands($event)"
              >
                <mat-option [value]="null">None</mat-option>
                <mat-option *appMatSelectFor="let brand of brands | async; maxRows: 200" [value]="brand">
                  {{ brand.name }}
                </mat-option>
              </app-mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-6 text-right">
            <mat-form-field>
              <app-mat-select filterKey="period" formControlName="season" placeholder="Season">
                <mat-option [value]="null">None</mat-option>
                <mat-option *appMatSelectFor="let season of seasons" [value]="season">
                  {{ season.period }}
                </mat-option>
              </app-mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <mat-form-field>
              <app-mat-select filterKey="name" formControlName="category" placeholder="Category">
                <mat-option [value]="null">None</mat-option>
                <mat-option *appMatSelectFor="let cat of categories" [value]="cat">
                  {{ cat.name }}
                </mat-option>
              </app-mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-6 text-right">
            <mat-form-field>
              <mat-select type="text" id="status" formControlName="status" placeholder="Status">
                <mat-option *ngFor="let status of productStatus" [value]="status">{{ status }} </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6 text-center">
        <button type="submit" [disabled]="!productForm.valid" class="btn-custom margin-right-btn">Save</button>
        <button
          routerLinkActive="active-conf"
          *ngIf="editMode && isAdmin | async"
          type="button"
          (click)="onDelete()"
          class="btn-custom margin-right-btn"
        >
          Delete!
        </button>
        <button type="button" (click)="onCancel()" class="btn-custom margin-right-btn">Back</button>
      </div>
    </div>
  </form>
</div>
