import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { LotEditService } from '../lot-edit.service';
import { LotsService } from '../../../shared/lots.service';
import { ImportExportService } from '../../../shared/import-export.service';
import { SupplierAvailabilityService } from './supplier-availability.service';
import { SupplierAvailabilityFilters } from '../../../models/filters/suppliers-availability-filters-model';
import { LotSupplierAvailability } from '../../../models/lot-supplier-availability-model';
import { addCheckers } from '../../../shared/checkers.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-spo',
  templateUrl: './supplier-availabilities.component.html',
  styleUrls: ['./supplier-availabilities.component.css'],
})
export class SupplierAvailabilitiesComponent implements OnInit {
  dataSource: MatTableDataSource<LotSupplierAvailability>;

  displayedColumns = [
    'check',
    'ean',
    'title',
    'supplier',
    'purchasePrice',
    'submittedQuantity',
    'submittedValue',
    'requestedQuantity',
    'requestedValue',
    'availableQuantity',
    'availableValue',
  ];

  mainChecker = false;

  bulkActionsActive = false;

  dataLsas: any[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private availabilityService: SupplierAvailabilityService,
    private lotsService: LotsService,
    private lotEditService: LotEditService,
    private importExportService: ImportExportService
  ) {}

  ngOnInit() {
    this.resultsNumber = this.availabilityService.resultsNumber;
    this.lotsService.getLot().subscribe();

    if (this.lotEditService.lsaFilters === undefined || this.lotEditService.lsaFilters === null) {
      this.lotEditService.lsaFilters = new SupplierAvailabilityFilters();
      this.lotEditService.lsaFilters.lotId = this.lotsService.currentLotId;
    }
    this.loadLsas(this.lotEditService.lsaFilters);
  }

  loadLsas(filters: SupplierAvailabilityFilters) {
    this.availabilityService.getFilteredSuppAvailabilities(filters).subscribe((lsas: LotSupplierAvailability[]) => {
      this.dataLsas = addCheckers(lsas, this.lotEditService.selectedLsas, this.mainChecker);
      this.dataSource = new MatTableDataSource(this.dataLsas);
    });
  }

  loadPage() {
    this.lotEditService.lsaFilters.pageNumber = this.paginator.pageIndex;
    this.lotEditService.lsaFilters.pageSize = this.paginator.pageSize;
    this.loadLsas(this.lotEditService.lsaFilters);
  }

  onSort() {
    this.lotEditService.lsaFilters.sortBy = this.sort.active;
    this.lotEditService.lsaFilters.sortDirection = this.sort.direction;
    this.loadLsas(this.lotEditService.lsaFilters);
  }

  onSelected(value: boolean, selectedLsa: any) {
    const selectedIndex = this.dataLsas.indexOf(selectedLsa);
    this.dataLsas[selectedIndex].checked = value;
    if (value !== this.mainChecker) {
      this.lotEditService.addLsaIdToSelected(this.dataLsas[selectedIndex].id);
    } else {
      this.lotEditService.removeLsaIdFromSelected(this.dataLsas[selectedIndex].id);
    }
    this.bulkActionsActive = value || this.mainChecker;
  }

  toggleAll(value: boolean) {
    this.mainChecker = value;
    for (const i of this.dataLsas) {
      i.checked = value;
    }

    this.lotEditService.selectedLsas = [];
    this.bulkActionsActive = value;
  }

  onFilter() {
    this.paginator.pageIndex = 0;
    this.resetCheckers();
    this.loadLsas(this.lotEditService.lsaFilters);
  }

  resetCheckers() {
    this.lotEditService.selectedLsas = [];
    this.mainChecker = false;
    this.bulkActionsActive = false;
  }

  export(format: string) {
    this.importExportService
      .exportLsas(this.lotEditService.selectedLsas, format, this.lotEditService.lsaFilters, this.mainChecker)
      .subscribe((resp) => {
        console.log(resp);
      });
  }
}
