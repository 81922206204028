import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from './date-converter';
import { Vendor } from './vendor-model';

@JsonObject('Chargeback')
export class Chargeback {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('code', String, true) code: string = undefined;
  @JsonProperty('financialCharge', Number, true) financialCharge: number = undefined;
  @JsonProperty('currency', String, true) currency: string = undefined;
  @JsonProperty('quantity', Number, true) quantity: number = undefined;
  @JsonProperty('vendorCode', String, true) vendorCode: string = undefined;
  @JsonProperty('issueType', String, true) issueType: string = undefined;
  @JsonProperty('creationDate', String, true) creationDate: string = undefined;
  @JsonProperty('disputeBy', DateConverter, true) disputeBy: Date = undefined;
  @JsonProperty('debitNoteNumber', String, true) debitNoteNumber: string = undefined;
  @JsonProperty('reversalNumber', String, true) reversalNumber: string = undefined;
  @JsonProperty('notes', String, true) notes: string = undefined;
  @JsonProperty('status', String, true) status: string = undefined;
  @JsonProperty('earlyDays', Number, true) earlyDays: number = undefined;
  @JsonProperty('shipOrDeliveryWindowEnd', String, true) shipOrDeliveryWindowEnd: string = undefined;
  @JsonProperty('daysLate', Number, true) daysLate: number = undefined;
  @JsonProperty('productName', String, true) productName: string = undefined;
  @JsonProperty('orderType', String, true) orderType: string = undefined;
  @JsonProperty('poNumber', String, true) poNumber: string = undefined;
  @JsonProperty('freightTerms', String, true) freightTerms: string = undefined;
  @JsonProperty('upc', String, true) upc: string = undefined;
  @JsonProperty('shipOrDeliveryWindowStart', String, true) shipOrDeliveryWindowStart: string = undefined;
  @JsonProperty('routingRequestCreationDate', String, true) routingRequestCreationDate: string = undefined;
  @JsonProperty('isd', String, true) isd: string = undefined;
  @JsonProperty('nonComplianceSubType', String, true) nonComplianceSubType: string = undefined;
  @JsonProperty('scac', String, true) scac: string = undefined;
  @JsonProperty('ean', String, true) ean: string = undefined;
  @JsonProperty('actualDate', String, true) actualDate: string = undefined;
  @JsonProperty('fulfilmentCentre', String, true) fulfilmentCentre: string = undefined;
  @JsonProperty('productGroup', String, true) productGroup: string = undefined;
  @JsonProperty('carrier', String, true) carrier: string = undefined;
  @JsonProperty('arn', String, true) arn: string = undefined;
  @JsonProperty('orderDate', String, true) orderDate: string = undefined;
  @JsonProperty('defectRate', Number, true) defectRate: number = undefined;
  @JsonProperty('asin', String, true) asin: string = undefined;
  @JsonProperty('reportingDate', String, true) reportingDate: string = undefined;
  @JsonProperty('vendor', Vendor, true) vendor: Vendor = undefined;
  @JsonProperty('charged', Boolean, true) charged: boolean = undefined;
}
