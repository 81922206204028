import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ConverterService } from '../core/converter.service';
import { Fulfilment } from '../models/fulfilment-model';
import { FulfilmentFilters, fulfilmentsFiltersToPath } from '../models/filters/fulfilment-filters-model';
import { GenericErrorModalComponent } from '../container/modal/generic-error-modal/generic-error-modal.component';
import { FulfilmentKpi, getFulfilmentsKpiFromHeaders } from '../models/fulfilment-kpi';
import { StockLocation } from '../models/stock-location-model';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../environment.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class FulfilmentService {
  constructor(
    private http: HttpClient,
    private converter: ConverterService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private environmentService: EnvironmentService
  ) {}

  resultsNumber = new Subject<number>();

  kpiData = new Subject<FulfilmentKpi>();

  getFilteredFulfilments(filters: FulfilmentFilters) {
    const path = this.environmentService.getRestEndpoint('fulfilments');
    const body = this.converter.fromObjtoJSON(filters);

    return this.http.post(path, body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.kpiData.next(getFulfilmentsKpiFromHeaders(resp.headers));
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((fulfilmentsResp: Fulfilment[]) => {
        const fulfils = [];
        for (let i = 0; i < fulfilmentsResp.length; i++) {
          fulfils[i] = this.converter.fromJSONtoObj(fulfilmentsResp[i], Fulfilment);
        }
        return fulfils;
      })
    );
  }

  deleteFulfilments(selectedIds: number[], filters: FulfilmentFilters, mainChecker: boolean, currentLotId: number): Observable<boolean> {
    if (mainChecker) {
      return this.deleteFilteredFulfilments(selectedIds, filters, currentLotId);
    } else {
      return this.deleteFulfilmentsByIds(selectedIds);
    }
  }
  deleteFulfilmentsByIds(selectedIds: number[]): Observable<boolean> {
    let path = this.environmentService.getRestEndpoint('fulfilments');
    if (!this.environmentService.dev) {
      path += '/delete';
    }

    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(selectedIds);

    console.log(path);

    return this.http.post(path, body, { headers }).pipe(
      map((resp: string) => {
        if (resp === '0') {
          return true;
        } else {
          this.dialog.open(GenericErrorModalComponent, {
            width: '350px',
            data: resp,
          });
        }
      })
    );
  }
  deleteFilteredFulfilments(selectedIds: number[], filters: FulfilmentFilters, currentLotId: number): Observable<boolean> {
    const path =
      this.environmentService.getRestEndpoint('fulfilments') + '/delete-filtered' + fulfilmentsFiltersToPath(filters, currentLotId);

    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(selectedIds);

    return this.http.post(path, body, { headers }).pipe(
      map((resp: string) => {
        if (resp === '0') {
          return true;
        } else {
          this.dialog.open(GenericErrorModalComponent, {
            width: '350px',
            data: resp,
          });
        }
      })
    );
  }

  getStockLocationsFromFulfilments(lotId: number): Observable<StockLocation[]> {
    let path = this.environmentService.getRestEndpoint('stockLocationsFromFulfilments');

    if (!this.environmentService.dev) {
      path += '?lot=' + lotId;
    }

    return this.http.get(path).pipe(
      map((stockLocations: StockLocation[]) => {
        const sl: StockLocation[] = [];
        for (let i = 0; i < stockLocations.length; i++) {
          sl[i] = this.converter.fromJSONtoObj(stockLocations[i], StockLocation);
        }
        return sl;
      })
    );
  }
}
