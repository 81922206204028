<app-main-remittances-filters (onFilter)="onFilter($event)"></app-main-remittances-filters>

<div class="bulk-actions-div">
  <mat-toolbar class="bar-custom">
    <div class="container">
      <div class="row">
        <div class="col-md-2">
          <button class="box-shadow-none btn-custom" routerLink="details">Show details</button>
        </div>
      </div>
    </div>
  </mat-toolbar>
</div>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <hr class="divisor-title" style="width: calc(100% - 20rem);" />
      <div class="square" style="right: 20rem;"></div>
      <h2 class="po-element-custom title-custom">Remittances</h2>
    </div>
  </div>
</div>

<div class="container table-outer">
  <mat-paginator [length]="resultsNumber | async" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()">
  </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="paymentNumber" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="paymentNumber" class="shipment-code-column"> Payment number </mat-header-cell>
        <mat-cell *matCellDef="let r" class="shipment-code-column">
          <!--          <a [routerLink]="['/spos', spo.id]">{{ spo.code }}</a>-->
          {{ r.paymentNumber }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="vendor" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="vendor" class="long-vendor-column"> Vendor </mat-header-cell>
        <mat-cell *matCellDef="let r" class="long-vendor-column">
          <p>{{ r.vendor.code + ' - ' + r.vendor.name }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="paymentTo">
        <mat-header-cell *matHeaderCellDef mat-sort-header="paymentTo" class="shipment-code-column"> Payment to </mat-header-cell>
        <mat-cell *matCellDef="let r" class="shipment-code-column">
          <p>{{ r.paymentTo }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="amazonSupplierNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header="amazonSupplierNumber" class="code-column">
          Amazon supplier number
        </mat-header-cell>
        <mat-cell *matCellDef="let r" class="code-column">
          <p>{{ r.amazonSupplierNumber }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="supplierSiteName">
        <mat-header-cell *matHeaderCellDef mat-sort-header="supplierSiteName" class="supplier-site-name-column">
          Supplier site name
        </mat-header-cell>
        <mat-cell *matCellDef="let r" class="supplier-site-name-column">
          <p>{{ r.supplierSiteName }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="paymentDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="paymentDate" class="date-column"> Payment date </mat-header-cell>
        <mat-cell *matCellDef="let r" class="date-column">
          <p>{{ r.paymentDate }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="paymentCurrency">
        <mat-header-cell *matHeaderCellDef mat-sort-header="paymentCurrency" class="date-column"> Payment currency </mat-header-cell>
        <mat-cell *matCellDef="let r" class="date-column">
          <p>{{ r.paymentCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="paymentAmount">
        <mat-header-cell *matHeaderCellDef mat-sort-header="paymentAmount" class="value-column-title"> Payment amount </mat-header-cell>
        <mat-cell *matCellDef="let r" class="value-column">
          <p>{{ r.paymentAmount | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
