import { Component, Input, ViewChild } from '@angular/core';
import { compareNumberOrString, isNilty } from '../../../../../../core/utils.service';
import { PriceChange } from '../../../../../../models/products-catalog-model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-price-history-table',
  templateUrl: './price-history-table.component.html',
  styleUrls: ['./price-history-table.component.css'],
})
export class PriceHistoryTableComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() set prices(values: PriceChange[]) {
    if (!isNilty(values)) {
      this.dataSource = new MatTableDataSource(values);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  dataSource: MatTableDataSource<PriceChange>;
  displayedColumns = ['date', 'price'];

  constructor() {}

  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        default:
          return compareNumberOrString(a[sort.active], b[sort.active], isAsc);
      }
    });
  }
}
