import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SuperAuthGuardService } from '../../security/super-auth-guard.service';
import { CategoriesComponent } from './categories/categories.component';
import { ConfigurationParamsComponent } from './configuration-params/configuration-params.component';
import { ConfigurationComponent } from './configuration.component';
import { CountryHolidaysComponent } from './country-holidays/country-holidays.component';
import { LotConfigurationEditComponent } from './lot-configurations/lot-configuration-edit/lot-configuration-edit.component';
import { LotConfigurationsListComponent } from './lot-configurations/lot-configuration-list/lot-configurations-list.component';
import { LotConfigurationsComponent } from './lot-configurations/lot-configurations.component';
import { PublicationRuleEditComponent } from './publication-rules/publication-rule-edit/publication-rule-edit.component';
import { PublicationRuleListComponent } from './publication-rules/publication-rule-list/publication-rule-list.component';
import { PublicationRuleComponent } from './publication-rules/publication-rule.component';
import { TransitTimeComponent } from './transit-time/transit-time.component';
import { VendorsEditComponent } from './vendors/vendors-edit/vendors-edit.component';
import { VendorsListComponent } from './vendors/vendors-list/vendors-list.component';
import { VendorsComponent } from './vendors/vendors.component';
import { WarehouseEditComponent } from './warehouses/warehouse-edit/warehouse-edit.component';
import { WarehouseListComponent } from './warehouses/warehouse-list/warehouse-list.component';
import { WarehousesComponent } from './warehouses/warehouses.component';
import { VendorCentralAccountsComponent } from './vendor-central-accounts/vendor-central-accounts.component';
// eslint-disable-next-line max-len
import { VendorCentralAccountsListComponent } from './vendor-central-accounts/vendor-central-accounts-list/vendor-central-accounts-list.component';
// eslint-disable-next-line max-len
import { VendorCentralAccountsEditComponent } from './vendor-central-accounts/vendor-central-accounts-edit/vendor-central-accounts-edit.component';

const configurationRoutes: Routes = [
  {
    path: '',
    component: ConfigurationComponent,
    children: [
      {
        path: 'warehouses',
        component: WarehousesComponent,
        children: [
          { path: '', component: WarehouseListComponent, pathMatch: 'full' },
          { path: 'edit/:id', component: WarehouseEditComponent },
          { path: 'new', component: WarehouseEditComponent },
        ],
      },
      {
        path: 'publication-rules',
        component: PublicationRuleComponent,
        children: [
          { path: '', component: PublicationRuleListComponent, pathMatch: 'full' },
          { path: 'edit/:id', component: PublicationRuleEditComponent },
          { path: 'new', component: PublicationRuleEditComponent },
        ],
      },
      { path: 'categories', component: CategoriesComponent },
      {
        path: 'vendors',
        component: VendorsComponent,
        children: [
          { path: '', component: VendorsListComponent, pathMatch: 'full' },
          { path: 'edit/:id', component: VendorsEditComponent },
          { path: 'new', component: VendorsEditComponent },
        ],
      },
      {
        path: 'vendor-central-accounts',
        component: VendorCentralAccountsComponent,
        children: [
          { path: '', component: VendorCentralAccountsListComponent, pathMatch: 'full' },
          { path: 'edit/:id', component: VendorCentralAccountsEditComponent },
          { path: 'new', component: VendorCentralAccountsEditComponent },
        ],
      },
      { path: 'transit-times', component: TransitTimeComponent },
      { path: 'country-holidays', component: CountryHolidaysComponent },
      {
        path: 'lot-configurations',
        component: LotConfigurationsComponent,
        children: [
          { path: '', component: LotConfigurationsListComponent, pathMatch: 'full' },
          { path: 'edit/:id', component: LotConfigurationEditComponent },
          { path: 'new', component: LotConfigurationEditComponent },
        ],
      },
      { path: 'params', component: ConfigurationParamsComponent, canActivate: [SuperAuthGuardService] },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(configurationRoutes)],
  exports: [RouterModule],
  providers: [SuperAuthGuardService],
})
export class ConfigurationRoutingModule {}
