import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { getDaysDifference, isNil, isNilty } from '../../../../../core/utils.service';
import { Chargeback } from '../../../../../models/chargeback-model';
import { ChargebackFilters } from '../../../../../models/filters/chargeback-filters-model';
import { PenaltiesService } from '../penalties.service';

@Component({
  selector: 'app-chargebacks-list',
  templateUrl: './chargebacks-list.component.html',
  styleUrls: ['./chargebacks-list.component.css'],
})
export class ChargebackListComponent implements OnInit {
  dataSource: MatTableDataSource<Chargeback>;

  displayedColumns = [
    'code',
    'charged',
    'issueType',
    'creationDate',
    'financialCharge',
    'quantity',
    'vendorName',
    'remainingDays',
    'productName',
    'poNumber',
    'ean',
    'asin',
    'fulfilmentCentre',
    'carrier',
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private penaltiesService: PenaltiesService) {}

  ngOnInit() {
    this.resultsNumber = this.penaltiesService.resultsNumber;

    if (isNilty(this.penaltiesService.chargebackFilters)) {
      this.penaltiesService.chargebackFilters = new ChargebackFilters();
    }
    this.penaltiesService.chargebackFilters.pageNumber = 0;
    this.dataSource = new MatTableDataSource([]);
    this.loadChargebacks(this.penaltiesService.chargebackFilters);
  }

  loadChargebacks(filters: ChargebackFilters) {
    this.penaltiesService.getFilteredChargebacks(filters).subscribe((ch: Chargeback[]) => {
      this.dataSource.data = ch;
    });
  }

  loadPage() {
    this.penaltiesService.chargebackFilters.pageNumber = this.paginator.pageIndex;
    this.penaltiesService.chargebackFilters.pageSize = this.paginator.pageSize;
    this.loadChargebacks(this.penaltiesService.chargebackFilters);
  }

  onSort() {
    this.penaltiesService.chargebackFilters.sortBy = this.sort.active;
    this.penaltiesService.chargebackFilters.sortDirection = this.sort.direction;
    this.loadChargebacks(this.penaltiesService.chargebackFilters);
  }

  onFilter(filters: ChargebackFilters) {
    this.paginator.pageIndex = 0;
    this.loadChargebacks(filters);
  }

  getRemainingDays(disputeBy: Date | null): string {
    if (isNil(disputeBy)) {
      return 'n/d';
    }
    return getDaysDifference(new Date(), disputeBy).toString();
  }
}
