import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConverterService } from '../../../core/converter.service';
import { EnvironmentService } from '../../../environment.service';
import { Observable } from 'rxjs';
import { CountryHolidays } from '../../../models/country-holidays-model';
import { map } from 'rxjs/operators';

@Injectable()
export class CountryHolidaysService {
  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  getCountryHolidays(): Observable<CountryHolidays[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('countryHolidays'))
      .pipe(map((resp: CountryHolidays[]) => resp.map((it) => this.converter.fromJSONtoObj(it, CountryHolidays))));
  }

  saveCountryHolidays(ch: CountryHolidays): Observable<any> {
    ch.setHolidays();
    const body = this.converter.fromObjtoJSON(ch);
    return this.http.post(this.environmentService.getRestEndpoint('saveCountryHolidays'), body);
  }
}
