import { Component, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

import { Warehouse } from '../../../../../models/warehouse-model';
import { VendorsService } from '../../../../configuration/vendors/vendors.service';
import { Vendor } from '../../../../../models/vendor-model';
import { WarehousesService } from '../../../../configuration/warehouses/warehouses.service';
import { PosService } from '../../../../../shared/pos.service';
import { PoFilters } from '../../../../../models/filters/po-filters-model';
import { MatChipsData } from '../../../../../models/mat-chips-data-model';
import { Category } from '../../../../../models/category-model';
import { CategoriesService } from '../../../../configuration/categories/categories.service';
import { InventoryService } from '../../../../inventory/inventory.service';
import { Brand } from '../../../../../models/brand-model';
import { isNilty } from '../../../../../core/utils.service';
import {
  createFullPoFiltersFormGroup,
  setBrandOnPoFilters,
  setCategoryOnPoFilters,
  setDestinationOnPoFilters,
  setLatestDeliveryOnPoFilters,
  setVendorsOnPoFilters,
  updatePoFiltersMap,
} from '../../../../../shared/po-filters-handler';

@Component({
  selector: 'app-pos-list-filters',
  templateUrl: './pos-list-filters.component.html',
  styleUrls: ['./pos-list-filters.component.css'],
})
export class PosListFiltersComponent implements OnInit {
  constructor(
    private posService: PosService,
    private vendorsService: VendorsService,
    private warehousesService: WarehousesService,
    private categoriesService: CategoriesService,
    private inventoryService: InventoryService
  ) {}

  filters: PoFilters;

  warehouses: Warehouse[] = [];

  vendors: Vendor[] = [];
  parents: Vendor[] = [];

  categories: Category[] = [];

  brandList: Brand[] = [];
  brands: Subject<Brand[]> = new Subject();

  public openFilter = false;

  @Output() onPoFilter = new Subject<PoFilters>();

  @Input() poEanDestsearched: Subject<void>;

  filtersMap: MatChipsData[];

  filterForm: FormGroup;

  ngOnInit() {
    this.filterForm = createFullPoFiltersFormGroup();

    if (!isNilty(this.poEanDestsearched)) {
      this.poEanDestsearched.subscribe(() => {
        this.resetFilters();
        this.filtersMap = updatePoFiltersMap(this.posService.poFilters, this.filterForm);
      });
    }

    if (!isNilty(this.posService.poFilters)) {
      this.filterForm.patchValue(this.posService.poFilters);
      this.filtersMap = setLatestDeliveryOnPoFilters(this.posService.poFilters, this.filterForm);
    }

    if (this.vendorsService.vendors !== undefined && this.vendorsService.parentVendors !== undefined) {
      this.vendors = this.vendorsService.vendors;
      this.parents = this.vendorsService.parentVendors;
      this.filtersMap = setVendorsOnPoFilters(this.vendors, this.parents, this.posService.poFilters, this.filterForm);
    } else {
      this.vendorsService.getVendors().subscribe((vendorsResp: Vendor[]) => {
        this.vendors = vendorsResp;
        this.parents = this.vendorsService.parentVendors;
        this.filtersMap = setVendorsOnPoFilters(this.vendors, this.parents, this.posService.poFilters, this.filterForm);
      });
    }

    if (this.warehousesService.warehouses !== undefined) {
      this.warehouses = this.warehousesService.warehouses;
      this.filtersMap = setDestinationOnPoFilters(this.warehouses, this.posService.poFilters, this.filterForm);
    } else {
      this.warehousesService.getWarehouses().subscribe((warehouses: Warehouse[]) => {
        this.warehouses = warehouses;
        this.filtersMap = setDestinationOnPoFilters(this.warehouses, this.posService.poFilters, this.filterForm);
      });
    }

    if (this.categoriesService.categories !== undefined) {
      this.categories = this.categoriesService.categories;
      this.filtersMap = setCategoryOnPoFilters(this.categories, this.posService.poFilters, this.filterForm);
    } else {
      this.categoriesService.getCategories('', 10000, 0).subscribe((c: Category[]) => {
        this.categories = c;
        this.filtersMap = setCategoryOnPoFilters(this.categories, this.posService.poFilters, this.filterForm);
      });
    }

    const brandString = this.posService.poFilters.brand ? this.posService.poFilters.brand : undefined;

    this.inventoryService.getBrands(brandString).subscribe((brands: Brand[]) => {
      this.brands.next(brands);
      this.brandList = brands;
      this.filtersMap = setBrandOnPoFilters(this.brandList, this.posService.poFilters, this.filterForm);
    });
  }

  saveFiltersToPoService() {
    this.posService.savePoFilters(this.filterForm);
  }

  onSubmitFilter() {
    this.saveFiltersToPoService();
    console.log(this.posService.poFilters);
    this.filtersMap = updatePoFiltersMap(this.posService.poFilters, this.filterForm);
    this.onPoFilter.next(this.posService.poFilters);
  }

  resetFilters() {
    this.filterForm.reset();
    this.filterForm.patchValue({ status: 'New' });
    this.saveFiltersToPoService();
    this.filtersMap = updatePoFiltersMap(this.posService.poFilters, this.filterForm);
  }

  resetFiltersAndUpdatePos() {
    this.filterForm.reset();
    this.filterForm.patchValue({ status: 'New' });
    this.saveFiltersToPoService();
    this.filtersMap = updatePoFiltersMap(this.posService.poFilters, this.filterForm);
    this.onPoFilter.next(this.posService.poFilters);
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeEvent() {
    this.openFilter = false;
  }

  selectedParent(event) {
    const parent = event.value;
    if (parent != null) {
      this.filterForm.controls['vendors'].setValue(null);
    }
  }

  selectedChildren(event) {
    const children = event.value;
    if (children != null) {
      this.filterForm.controls['parent'].setValue(null);
    }
  }

  filterBrands(filterString: { filterText: string; filterKey: string }) {
    this.inventoryService.getBrands(filterString.filterText).subscribe((resp: Brand[]) => {
      this.brands.next(resp);
      this.brandList = resp;
    });
  }

  vendorChange(event) {
    if (event.value.indexOf(null) !== -1) {
      this.filters.vendors = [];
    }
  }
}
