<mat-toolbar class="lot-edit-bulk-actions">
  <div class="container">
    <div class="row">
      <div class="col-md-2">
        <button class="box-shadow-none btn-custom" (click)="importExcel()">Import response</button>
        <input #getFile type="file" style="display: none;" onclick="this.value = null" (change)="fileChanged($event)" />
      </div>

      <div class="col-md-10 text-right">
        <button [disabled]="!bulkActionsActive" [matMenuTriggerFor]="exportChoice" class="box-shadow-none btn-custom">
          Export selected rows
        </button>
        <mat-menu #exportChoice="matMenu">
          <button mat-menu-item (click)="exportExcel('excel')">Save as Excel</button>
          <button mat-menu-item (click)="exportExcel('csv')">Save as CSV</button>
        </mat-menu>
      </div>
    </div>
  </div>
</mat-toolbar>
