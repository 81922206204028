<h1 mat-dialog-title></h1>
<div class="dialog-container">
  <div mat-dialog-content>
    <div class="row padding-left-15 padding-right-15">
      {{ message }}
    </div>

    <div class="row padding-top-15 padding-left-15 padding-right-15">{{ question }}</div>
  </div>
</div>
<div mat-dialog-actions>
  <div class="row width-100per">
    <div class="col-md-12 text-center width-100per">
      <button class="btn-product margin-right-15" [mat-dialog-close]="true">Confirm</button>
      <button class="btn-product" [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
    </div>
  </div>
</div>
