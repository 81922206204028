<ng-container *ngFor="let constraint of constraints">
  <mat-expansion-panel
    class="rule-edit-panel"
    style="margin-top: 10px; margin-bottom: 10px"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header class="constraints-header">
      <mat-panel-title class="rule-edit-panel-title">
        <i class="fa fa-link margin-right-15" aria-hidden="true"></i>{{ constraint.description }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="row padding-top-15">
      <div class="col-xs-8">
        <mat-form-field class="margin-right-15">
          <input matInput placeholder="Days to extend" [(ngModel)]="constraint.daysToExtend" type="number" />
        </mat-form-field>

        <mat-form-field>
          <app-mat-select filterKey="description" id="vendors" [(ngModel)]="constraint.vendors" placeholder="Vendors" [multiple]="true">
            <mat-option *ngFor="let vendor of vendors" [value]="vendor">{{ vendor.description }} </mat-option>
          </app-mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-4 text-right padding-top-15">
        <button class="btn-custom smaller-icon-button margin-left-5" matTooltip="Delete" (click)="deleteConstraint(constraint)">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </mat-expansion-panel>
</ng-container>

<div class="row constraint-row clickable-constraint-row" matTooltip="Add new constraint" (click)="addConstraint()">
  <div class="col-md-12 text-right">Add constraint</div>
</div>
