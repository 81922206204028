export const addCheckers = (dataArray: any[], selectedData: number[] | string[], mainChecker: boolean): any[] => {
  const newData: any[] = [];
  for (let i = 0; i < dataArray.length; i++) {
    newData[i] = { ...dataArray[i], checked: mainChecker };
  }
  return verifyChecked(newData, selectedData, mainChecker);
};

const verifyChecked = (dataArray: any[], selectedData: number[] | string[], mainChecker: boolean): any[] => {
  for (const id of selectedData) {
    for (const i of dataArray) {
      if (i.id === id) {
        i.checked = !mainChecker;
      }
    }
  }
  return dataArray;
};

export const onSelectedChecker = (value: boolean, selectedPo: any, data: any[], mainChecker: boolean, selectedIds: number[]) => {
  const selectedIndex = data.indexOf(selectedPo);
  data[selectedIndex].checked = value;
  if (value !== mainChecker) {
    addIdToSelected(data[selectedIndex].id, selectedIds);
  } else {
    removeIdFromSelected(data[selectedIndex].id, selectedIds);
  }
  return value || selectedIds.length > 0 || mainChecker;
};
const addIdToSelected = (id: number, ids: number[]) => {
  ids.push(id);
  ids.filter((v, i, a) => a.indexOf(v) === i);
};
const removeIdFromSelected = (id: number, ids: number[]) => {
  const index = ids.indexOf(id);
  ids.splice(index, 1);
};

export const toggleAllCheckers = (value: boolean, data: any[], selectedIds: number[]) => {
  for (const i of data) {
    i.checked = value;
  }
  selectedIds.splice(0, selectedIds.length);
  return value;
};
