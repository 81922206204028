import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SpoFilters } from '../../../../models/filters/spo-filters-model';
import { GroupedSpoItem } from '../../../../models/spo-item-model';
import { addCheckers } from '../../../../shared/checkers.service';
import { ImportExportService } from '../../../../shared/import-export.service';
import { LotsService } from '../../../../shared/lots.service';
import { SposService } from '../../../../shared/spos.service';
import { LotEditService } from '../../lot-edit.service';

@Component({
  selector: 'app-spo-items',
  templateUrl: './spo-items.component.html',
  styleUrls: ['./spo-items.component.css'],
})
export class SpoItemsComponent implements OnInit {
  dataSource: MatTableDataSource<GroupedSpoItem>;

  displayedColumns = [
    'check',
    'spoCode',
    'orderId',
    'vendor',
    'destination',
    'extra',
    'crossUkBorder',
    'earliestDeliveryDate',
    'latestDeliveryDate',
    'latestPickupDate',
    'ean',
    'actualEan',
    'supplier',
    'obsoleteWithAccepted',
    'purchasePrice',
    'requestedQuantity',
    'confirmedQuantity',
    'orderedQuantity',
    'margin',
    'percentMargin',
    'virtualMargin',
    'virtualPercentMargin',
    'requestedValue',
    'confirmedValue',
    'confirmedPurchasePrice',
    'virtualConfirmedPurchasePrice',
    'orderedValue',
    'orderedPurchasePrice',
    'virtualOrderedPurchasePrice',
    'virtualConfirmedMargin',
    'virtualConfirmedPercentMargin',
    'status',
  ];

  mainChecker = false;

  bulkActionsActive = false;

  dataSpo: any[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private router: Router,
    private sposService: SposService,
    private lotsService: LotsService,
    private lotEditService: LotEditService,
    private importExportService: ImportExportService
  ) {}

  ngOnInit() {
    this.resultsNumber = this.sposService.resultsNumber;
    this.lotsService.getLot().subscribe();

    if (this.lotEditService.spoFilters === undefined) {
      this.lotEditService.spoFilters = new SpoFilters();
    }
    this.dataSource = new MatTableDataSource([]);
    this.resetCheckers();
    this.loadSpoItems(this.lotEditService.spoFilters);
  }

  loadSpoItems(filters: SpoFilters) {
    this.sposService.getFilteredGroupedSpoItems(this.lotsService.currentLotId, filters).subscribe((spos: GroupedSpoItem[]) => {
      this.dataSpo = addCheckers(spos, this.lotEditService.selectedGroupedSpoItems, this.mainChecker);
      this.dataSource.data = this.dataSpo;
    });
  }

  loadPage() {
    this.lotEditService.spoFilters.pageNumber = this.paginator.pageIndex;
    this.lotEditService.spoFilters.pageSize = this.paginator.pageSize;
    this.loadSpoItems(this.lotEditService.spoFilters);
  }

  onSort() {
    this.lotEditService.spoFilters.sortBy = this.sort.active;
    this.lotEditService.spoFilters.sortDirection = this.sort.direction;
    this.loadSpoItems(this.lotEditService.spoFilters);
  }

  onSelected(value: boolean, selectedSpo: any) {
    selectedSpo.checked = value;
    if (value !== this.mainChecker) {
      this.lotEditService.addSpoItemIdToSelected(selectedSpo.id, selectedSpo.spoiIds);
    } else {
      this.lotEditService.removeSpoItemIdFromSelected(selectedSpo.id, selectedSpo.spoiIds);
    }
    this.bulkActionsActive = value || this.mainChecker;
  }

  toggleAll(value: boolean) {
    this.mainChecker = value;
    this.lotEditService.spoItemsMainChecker = this.mainChecker;
    for (const i of this.dataSpo) {
      i.checked = value;
    }

    this.lotEditService.selectedGroupedSpoItems = [];
    this.lotEditService.selectedSpoItems = [];
    this.bulkActionsActive = value;
  }

  onFilter(filters: SpoFilters) {
    this.paginator.pageIndex = 0;
    this.resetCheckers();
    this.loadSpoItems(filters);
  }

  resetCheckers() {
    this.lotEditService.selectedGroupedSpoItems = [];
    this.lotEditService.selectedSpoItems = [];
    this.mainChecker = false;
    this.bulkActionsActive = false;
  }

  export(format: string) {
    this.importExportService
      .exportSpoItems(
        this.lotEditService.selectedGroupedSpoItems,
        format,
        this.lotEditService.spoFilters,
        this.mainChecker,
        this.lotsService.currentLotId
      )
      .subscribe(() => {});
  }

  openDetails(spoId) {
    this.router.navigate(['lot-edit', this.lotsService.currentLotId, 'spo', spoId]);
  }
}
