<app-warehouse-list-filters (onFilter)="onFilter($event)"></app-warehouse-list-filters>

<mat-toolbar class="bar-custom">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <button class="btn-custom" [routerLink]="['new']">New Warehouse</button>
      </div>
    </div>
  </div>
</mat-toolbar>

<div class="container table-outer">
  <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]"> </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="name" sticky>
        <mat-header-cell class="stock-location-name-column" *matHeaderCellDef mat-sort-header="name"> Name </mat-header-cell>
        <mat-cell class="stock-location-name-column" *matCellDef="let warehouse" [matTooltip]="warehouse.name" matTooltipPosition="below">
          <p>{{ warehouse.name }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="code" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="code"> Code </mat-header-cell>
        <mat-cell *matCellDef="let warehouse"> {{ warehouse.code }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="marketplace">
        <mat-header-cell class="gln-column" *matHeaderCellDef mat-sort-header="marketplace"> Marketplace </mat-header-cell>
        <mat-cell class="gln-column" *matCellDef="let warehouse"> {{ warehouse.marketplace }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="glnCode">
        <mat-header-cell class="gln-column" *matHeaderCellDef mat-sort-header="glnCode"> GLN Code </mat-header-cell>
        <mat-cell class="gln-column" *matCellDef="let warehouse">
          <p>{{ warehouse.glnCode }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="brandonGln">
        <mat-header-cell class="gln-column" *matHeaderCellDef mat-sort-header="brandonGln"> Brandon GLN </mat-header-cell>
        <mat-cell class="gln-column" *matCellDef="let warehouse">
          <p>{{ warehouse.brandonGln }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="minimalValue">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="minimalValue">Minimal Value</mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let warehouse">
          <p>{{ warehouse.minimalValue }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="address">
        <mat-header-cell class="stock-location-address-column" *matHeaderCellDef mat-sort-header="address">Address</mat-header-cell>
        <mat-cell
          class="stock-location-address-column"
          *matCellDef="let warehouse"
          [matTooltip]="warehouse.address"
          matTooltipPosition="below"
        >
          <p>{{ warehouse.address }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="city">
        <mat-header-cell class="stock-location-city-column" *matHeaderCellDef mat-sort-header="city">City</mat-header-cell>
        <mat-cell class="stock-location-city-column" *matCellDef="let warehouse" [matTooltip]="warehouse.city" matTooltipPosition="below">
          <p>{{ warehouse.city }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="region">
        <mat-header-cell *matHeaderCellDef mat-sort-header="region">Region</mat-header-cell>
        <mat-cell *matCellDef="let warehouse" [matTooltip]="warehouse.region" matTooltipPosition="below">
          <p>{{ warehouse.region }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="country">
        <mat-header-cell *matHeaderCellDef mat-sort-header="country">Country</mat-header-cell>
        <mat-cell *matCellDef="let warehouse" [matTooltip]="warehouse.country" matTooltipPosition="below">
          <p>{{ warehouse.country }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="phone">
        <mat-header-cell class="phone-column" *matHeaderCellDef mat-sort-header="phone">Phone</mat-header-cell>
        <mat-cell class="phone-column" *matCellDef="let warehouse" [matTooltip]="warehouse.phone" matTooltipPosition="below">
          <p>{{ warehouse.phone }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="edit" stickyEnd>
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let warehouse">
          <button class="btn-product btn-fixed" [routerLink]="['edit', warehouse.id]">Edit</button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
