<div [ngClass]="{ 'black-panel': openFilter, panel: !openFilter }">
  <mat-expansion-panel class="mat-elevation-z8 filter-position" [attr.expanded]="openFilter" (opened)="openEvent()" (closed)="closeEvent()">
    <mat-expansion-panel-header class="filt-custom">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-9">
            <mat-chip-list>
              <mat-chip *ngFor="let f of filtersMap">{{ f.filter }}</mat-chip>
            </mat-chip-list>
          </div>
          <div class="col-md-2">
            <mat-panel-title class="filter-title">
              Filters
            </mat-panel-title>
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>

    <div class="row filters-row">
      <form [formGroup]="filterForm" (submit)="onSubmitFilter()">
        <div class="col-md-12 text-blue margin-top-5 border-bottom-orange">
          <div class="row">
            <div class="col-md-1"></div>

            <div class="col-md-2">
              <mat-form-field>
                <textarea matInput formControlName="eans" placeholder="EAN"></textarea>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select filterKey="name" formControlName="category" placeholder="Category">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option *appMatSelectFor="let cat of categories" [value]="cat">
                    {{ cat.name }}
                  </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select
                  filterKey="name"
                  formControlName="brand"
                  placeholder="Brand"
                  [automaticSearch]="false"
                  (filterChange)="filterBrands($event)"
                >
                  <mat-option [value]="null">None</mat-option>
                  <mat-option *appMatSelectFor="let brand of brands | async; maxRows: 200" [value]="brand">
                    {{ brand.name }}
                  </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2 padding-top-15">
              <mat-checkbox formControlName="acceptedDifferentFromPoa">Only Accepted != POA</mat-checkbox>
            </div>

            <div class="col-md-2 padding-top-15">
              <mat-checkbox formControlName="obsoleteWithAccepted">Obsolete with accepted</mat-checkbox>
            </div>
          </div>
        </div>

        <div class="col-md-12 border-top-white">
          <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-2 center">
              <button class="btn-custom padding-left-right-70" type="submit">Filter</button>
            </div>
            <div class="col-md-2 center">
              <button class="btn-custom padding-left-right-70" type="button" (click)="resetFilters()">Reset</button>
            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
      </form>

      <mat-divider></mat-divider>
    </div>
  </mat-expansion-panel>
</div>
