import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GenerateOrderedConfigurationDto } from '@models/lot-configuration-dto-model';
import { GenericConfirmationModalComponent } from '@modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { isNilty } from '@core/utils.service';

@Component({
  selector: 'app-generate-ordered-configurations',
  templateUrl: './generate-ordered-configurations.component.html',
  styleUrls: ['./generate-ordered-configurations.component.scss'],
})
export class GenerateOrderedConfigurationsComponent {
  @Input() generateOrderedConfigurations: GenerateOrderedConfigurationDto[];

  newConfig: GenerateOrderedConfigurationDto = undefined;

  constructor(private dialog: MatDialog) {}

  addGenerateOrderedConfig() {
    this.newConfig = new GenerateOrderedConfigurationDto();
  }

  confirmGenerateOrderedConfiguration() {
    this.generateOrderedConfigurations.push({ ...this.newConfig });
    this.generateOrderedConfigurations = this.generateOrderedConfigurations.sort((a, b) => (a.margin > b.margin ? -1 : 1));
    this.newConfig = undefined;
  }

  removeGenerateOrderedConfig(index: number) {
    this.dialog
      .open(GenericConfirmationModalComponent, { data: 'Deleting GenerateOrdered configuration.' })
      .afterClosed()
      .subscribe((r: boolean) => {
        if (r) {
          this.generateOrderedConfigurations.splice(index, 1);
        }
      });
  }
}
