import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LogDiscardedValueService } from '../../../../shared/log-discarded-value.service';
import { LogDiscardedValueDetail } from '../../../../models/log-discarded-value-detail-model';
import { PaginationAndSorting } from '../../../../models/filters/pagination-and-sorting-model';

@Component({
  selector: 'app-log-discarded-value-detail',
  templateUrl: './log-discarded-value-detail.component.html',
  styleUrls: ['./log-discarded-value-detail.component.css'],
})
export class LogDiscardedValueDetailComponent implements OnInit {
  dataSource: MatTableDataSource<LogDiscardedValueDetail>;
  displayedColumns = ['poItem', 'ean', 'unitCost', 'physicalQuantity', 'purchasePrice', 'discardedQuantity', 'discardedValue'];
  resultsNumber: number;
  filters: PaginationAndSorting;

  @Input() logDiscardedValueId: number;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private logDiscardedValueService: LogDiscardedValueService) {}

  ngOnInit() {
    this.resultsNumber = 0;
    this.filters = new PaginationAndSorting();
    this.dataSource = new MatTableDataSource<LogDiscardedValueDetail>([]);
    this.loadLogDiscardedValue();
  }

  loadLogDiscardedValue() {
    this.logDiscardedValueService
      .getFilteredLogDiscardedValueDetail(this.logDiscardedValueId, this.filters)
      .subscribe((data: [number, LogDiscardedValueDetail[]]) => {
        this.resultsNumber = data[0];
        this.dataSource.data = data[1];
      });
  }

  loadPage() {
    this.filters.pageNumber = this.paginator.pageIndex;
    this.filters.pageSize = this.paginator.pageSize;
    this.loadLogDiscardedValue();
  }

  onSort() {
    this.filters.sortBy = this.sort.active;
    this.filters.sortDirection = this.sort.direction;
    this.loadLogDiscardedValue();
  }
}
