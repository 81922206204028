import { Warehouse } from '../warehouse-model';
import { Vendor } from '../vendor-model';
import { MatChipsData } from '../mat-chips-data-model';
import { Supplier } from '../supplier-model';
import { JsonObject, JsonProperty } from 'json2typescript';
import { isNilty } from '../../core/utils.service';
import { FormGroup } from '@angular/forms';
import { Status } from '../status-model';
import { StockLocation } from '../stock-location-model';
import { PaginationAndSorting } from './pagination-and-sorting-model';

@JsonObject('LogDiscardedValueFilters')
export class LogDiscardedValueFilters extends PaginationAndSorting {
  @JsonProperty('lotId', Number, true) lotId: number = undefined;
  @JsonProperty('supplierId', Number, true) supplierId: number = undefined;
  @JsonProperty('stockLocationId', Number, true) stockLocationId: number = undefined;
  @JsonProperty('vendorId', Number, true) vendorId: number = undefined;
  @JsonProperty('destinationId', Number, true) destinationId: number = undefined;
  @JsonProperty('type', String, true) type: string = undefined;
  @JsonProperty('po', String, true) po: string = undefined;
  @JsonProperty('ean', String, true) ean: string = undefined;
}

export const ldvFiltersMap = (
  supplier?: Supplier,
  stockLocation?: StockLocation,
  vendor?: Vendor,
  destination?: Warehouse,
  type?: Status,
  po?: string,
  ean?: string
): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (!isNilty(supplier)) {
    map.push(new MatChipsData('Supplier', supplier.name));
  }
  if (!isNilty(stockLocation)) {
    map.push(new MatChipsData('Stock Location', stockLocation.name));
  }
  if (!isNilty(vendor)) {
    map.push(new MatChipsData('Vendor', vendor.name));
  }
  if (!isNilty(destination)) {
    map.push(new MatChipsData('Destination', destination.name));
  }
  if (!isNilty(type)) {
    map.push(new MatChipsData('Type', type.description));
  }
  if (!isNilty(po)) {
    map.push(new MatChipsData('PO', po));
  }
  if (!isNilty(type)) {
    map.push(new MatChipsData('ean', ean));
  }
  return map;
};

export const setFiltersFromForm = (filterForm: FormGroup, ldvFilters: LogDiscardedValueFilters) => {
  ldvFilters.supplierId = filterForm.value.supplier ? filterForm.value.supplier.id : undefined;
  ldvFilters.stockLocationId = filterForm.value.stockLocation ? filterForm.value.stockLocation.id : undefined;
  ldvFilters.vendorId = filterForm.value.vendor ? filterForm.value.vendor.id : undefined;
  ldvFilters.destinationId = filterForm.value.destination ? filterForm.value.destination.id : undefined;
  ldvFilters.type = filterForm.value.type ? filterForm.value.type.code : undefined;
  ldvFilters.po = filterForm.value.po ? filterForm.value.po : undefined;
  ldvFilters.ean = filterForm.value.ean ? filterForm.value.ean : undefined;

  ldvFilters.pageNumber = undefined;
  ldvFilters.pageSize = undefined;
  ldvFilters.sortDirection = undefined;
  ldvFilters.sortBy = undefined;
};
