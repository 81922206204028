import { JsonObject, JsonProperty } from 'json2typescript';
import { JobExecutionStatus } from './job-execution-status';

export enum OrderedFromLsaGenerationStep {
  STARTING = 'STARTING',
  FETCHING_SPO_ITEMS = 'FETCHING_SPO_ITEMS',
  RETRIEVING_COMMODITIES = 'RETRIEVING_COMMODITIES',
  PREPARING_MODEL = 'PREPARING_MODEL',
  OPTIMIZING = 'OPTIMIZING',
  MAXIMIZATION_EXECUTED = 'MAXIMIZATION_EXECUTED',
  UPDATING_SPOS = 'UPDATING_SPOS',
  UPDATING_CATALOGS = 'UPDATING_CATALOGS',
  UPDATING_POS = 'UPDATING_POS',
}

@JsonObject('GenerateOrderedFromLsaJobStatus')
export class GenerateOrderedFromLsaJobStatus extends JobExecutionStatus {
  @JsonProperty('generationStatus', String, true) generationStatus: string = undefined;
  @JsonProperty('totalSpoItems', Number, true) totalSpoItems: number = undefined;
  @JsonProperty('retrievedCommodities', Number, true) retrievedCommodities: number = undefined;
  @JsonProperty('optimizerTimeLimit', Number, true) optimizerTimeLimit: number = undefined;
  @JsonProperty('maximizationTime', Number, true) maximizationTime: number = undefined;
}
