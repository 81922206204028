import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from 'json2typescript';
import { isNil } from '../core/utils.service';

@JsonObject('Status')
export class Status {
  @JsonProperty('code', String, false) code: string = undefined;
  @JsonProperty('description', String, false) description: string = undefined;
}

@JsonConverter
export class StatusConverter implements JsonCustomConvert<Status> {
  jsonConvert: JsonConvert;

  constructor() {
    this.jsonConvert = new JsonConvert();
  }

  serialize(status: Status): string {
    return !isNil(status) ? status.code : undefined;
  }

  deserialize(value: string): Status {
    return this.jsonConvert.deserialize(value, Status) as Status;
  }
}
