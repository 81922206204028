import { Component, OnInit, ViewChild } from '@angular/core';

import { Po } from '../../../../models/po-model';
import { ImportExportService } from '../../../../shared/import-export.service';
import { LotsService } from '../../../../shared/lots.service';
import { PosService } from '../../../../shared/pos.service';
import { PoFilters } from '../../../../models/filters/po-filters-model';
import { LotEditService } from '../../lot-edit.service';
import { Observable } from 'rxjs';
import { SendPoaModalComponent } from '../../send-poa-modal/send-poa-modal.component';
import { addCheckers } from '../../../../shared/checkers.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NewLotModalComponent } from '../../../modal/new-lot-modal/new-lot-modal.component';
import { isNilty } from '../../../../core/utils.service';
import { GenericConfirmationModalComponent } from '../../../modal/generic-confirmation-modal/generic-confirmation-modal.component';

@Component({
  selector: 'app-po',
  templateUrl: './po.component.html',
  styleUrls: ['./po.component.css'],
})
export class PoComponent implements OnInit {
  dataSource;

  displayedColumns = [
    'check',
    'poId',
    'vendor',
    'destination',
    'orderedOn',
    'earliestDate',
    'latestDate',
    'submittedQuantity',
    'submittedValue',
    'acceptedQuantity',
    'acceptedValue',
    'lastPoaQuantity',
    'status',
  ];

  mainChecker = false;

  bulkActionsActive = false;

  dataPos: any[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private lotsService: LotsService,
    private posService: PosService,
    private lotEditService: LotEditService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private importExportService: ImportExportService
  ) {}

  ngOnInit() {
    this.lotEditService.initPoFilters(this.lotsService.currentLotId);
    this.resultsNumber = this.posService.resultsNumber;

    this.lotsService.getLot().subscribe(() => {
      this.lotEditService.poFilters.lotId = this.lotsService.currentLotId;
      this.resetPagination();
      this.resetCheckers();
      this.dataSource = new MatTableDataSource([]);
      this.loadPos(this.lotEditService.poFilters);
    });
  }

  loadPos(filters: PoFilters) {
    this.posService.getFilteredPos(filters).subscribe((pos: Po[]) => {
      this.dataPos = addCheckers(pos, this.lotEditService.selectedPos, this.mainChecker);
      this.dataSource.data = this.dataPos;
    });
  }

  loadPage() {
    this.lotEditService.poFilters.pageNumber = this.paginator.pageIndex;
    this.lotEditService.poFilters.pageSize = this.paginator.pageSize;
    this.loadPos(this.lotEditService.poFilters);
  }

  onSort() {
    this.lotEditService.poFilters.sortBy = this.sort.active;
    this.lotEditService.poFilters.sortDirection = this.sort.direction;
    this.loadPos(this.lotEditService.poFilters);
  }

  onSelected(value: boolean, selectedPo: any) {
    const selectedIndex = this.dataPos.indexOf(selectedPo);
    this.dataPos[selectedIndex].checked = value;
    if (value !== this.mainChecker) {
      this.lotEditService.addPoIdToSelected(this.dataPos[selectedIndex].id);
    } else {
      this.lotEditService.removePoIdFromSelected(this.dataPos[selectedIndex].id);
    }
    this.bulkActionsActive = value || this.mainChecker;
  }

  toggleAll(value: boolean) {
    this.mainChecker = value;
    this.lotEditService.posMainChecker = this.mainChecker;
    for (const i of this.dataPos) {
      i.checked = value;
    }

    this.lotEditService.selectedPos = [];
    this.bulkActionsActive = value;
  }

  exportPos(format: string) {
    if (this.lotEditService.selectedPos.length === 0 && !this.mainChecker) {
      this.exportAllPos(format);
    } else {
      this.importExportService
        .exportPos(this.lotEditService.selectedPos, format, this.mainChecker, this.lotEditService.poFilters)
        .subscribe((resp) => {
          console.log(resp);
        });
    }
  }

  exportAllPos(format: string) {
    this.importExportService.exportPos(this.lotEditService.selectedPos, format, true, this.lotEditService.poFilters).subscribe((resp) => {
      console.log(resp);
    });
  }

  onMoveToLot() {
    this.dialog
      .open(NewLotModalComponent, {
        width: '350px',
        data: { currentLotId: this.lotsService.currentLotId },
      })
      .afterClosed()
      .subscribe((resp: number) => {
        if (!isNilty(resp)) {
          console.log('new lot id is ' + resp);
          this.lotsService
            .addPosToLot(this.lotEditService.poFilters, this.lotEditService.selectedPos, this.mainChecker, true, resp)
            .subscribe(() => this.loadPos(this.lotEditService.poFilters));
        }
      });
  }

  onRemoveFromLot() {
    this.dialog
      .open(GenericConfirmationModalComponent, { data: 'The selected POs will be removed from this Lot and put back in status New.' })
      .afterClosed()
      .subscribe((resp: boolean) => {
        if (resp) {
          this.lotsService
            .removePosFromLot(
              this.lotEditService.poFilters,
              this.lotEditService.selectedPos,
              this.mainChecker,
              this.lotsService.currentLotId
            )
            .subscribe(() => this.loadPos(this.lotEditService.poFilters));
        }
      });
  }

  applyFilter(filters: PoFilters) {
    this.paginator.pageIndex = 0;
    this.dataPos = [];
    this.resetCheckers();
    this.loadPos(filters);
  }

  changePosStatus(event: { status: string; submitted: boolean }) {
    const selectedPos = this.lotEditService.selectedPos;
    const mainChecker = this.mainChecker;

    if (event.status === 'Closed') {
      const dialogRef = this.dialog.open(SendPoaModalComponent, {
        width: '700px',
        data: {
          mainChecker,
          selected: this.lotEditService.selectedPos.length,
          totalNumber: this.posService.totalPos,
        },
      });

      dialogRef.afterClosed().subscribe((result: string) => {
        if (result === 'send') {
          this.posService.closeAndSendPoa(selectedPos, this.lotEditService.poFilters, mainChecker, event.submitted).subscribe(() => {
            this.applyFilter(this.lotEditService.poFilters);
          });
        } else if (result === 'dontSend') {
          this.posService.changeStatus(event.status, selectedPos, this.lotEditService.poFilters, mainChecker).subscribe(() => {
            this.applyFilter(this.lotEditService.poFilters);
          });
        } else {
          this.mainChecker = true;
          return;
        }
      });
    } else {
      return this.posService.changeStatus(event.status, selectedPos, this.lotEditService.poFilters, mainChecker).subscribe(() => {
        this.applyFilter(this.lotEditService.poFilters);
      });
    }
  }

  resetCheckers() {
    this.lotEditService.selectedPos = [];
    this.mainChecker = false;
    this.bulkActionsActive = false;
  }

  private resetPagination() {
    this.lotEditService.poFilters.sortBy = undefined;
    this.lotEditService.poFilters.sortDirection = undefined;
    this.lotEditService.poFilters.pageNumber = 0;
    this.lotEditService.poFilters.pageSize = 10;
  }
}
