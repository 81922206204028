<app-main-remittances-filters (onFilter)="onFilter($event)"></app-main-remittances-filters>

<div class="bulk-actions-div">
  <mat-toolbar class="bar-custom">
    <div class="container">
      <div class="row">
        <div class="col-md-2">
          <button class="box-shadow-none btn-custom" routerLink="/remittances">Show remittances</button>
        </div>
      </div>
    </div>
  </mat-toolbar>
</div>

<div class="container table-outer">
  <mat-paginator #paginator [length]="resultsNumber | async" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()">
  </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="paymentNumber" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="paymentNumber" class="invoice-number-column"> Payment number </mat-header-cell>
        <mat-cell *matCellDef="let r" class="invoice-number-column">{{ r.remittance?.paymentNumber }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="code" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="code" class="code-column"> Code </mat-header-cell>
        <mat-cell *matCellDef="let r" class="code-column">
          <p>{{ r.code }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="invoiceNumber" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="invoiceNumber" class="shipment-code-column">
          Remittance number
        </mat-header-cell>
        <mat-cell *matCellDef="let r" class="shipment-code-column">
          <p>{{ r.invoiceNumber }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="invoiceReference" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="invoiceReference" class="invoice-number-column"> Invoice </mat-header-cell>
        <mat-cell *matCellDef="let r" class="invoice-number-column">
          <a *ngIf="r.invoice" [routerLink]="['/invoices', r.invoice?.id]">{{ r.invoice?.invoiceNumber }}</a>
          <p *ngIf="!r.invoice">-</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="invoiceDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="invoiceDate" class="date-column"> Invoice date </mat-header-cell>
        <mat-cell *matCellDef="let r" class="date-column">{{ r.invoiceDate }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="invoiceDescription">
        <mat-header-cell *matHeaderCellDef mat-sort-header="invoiceDescription" class="long-title-column"> Description </mat-header-cell>
        <mat-cell *matCellDef="let r" class="long-title-column" [matTooltip]="r.invoiceDescription">
          <p>{{ r.invoiceDescription }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="discountTaken">
        <mat-header-cell *matHeaderCellDef mat-sort-header="discountTaken" class="code-column"> Discount taken </mat-header-cell>
        <mat-cell *matCellDef="let r" class="code-column">
          <p>{{ r.discountTaken }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="amountPaid">
        <mat-header-cell *matHeaderCellDef mat-sort-header="amountPaid" class="value-column-title"> Amount paid </mat-header-cell>
        <mat-cell *matCellDef="let r" class="value-column">
          <p>{{ r.amountPaid | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="amountRemaining">
        <mat-header-cell *matHeaderCellDef mat-sort-header="amountRemaining" class="value-column-title"> Amount remaining </mat-header-cell>
        <mat-cell *matCellDef="let r" class="value-column">
          <p>{{ r.amountRemaining | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
