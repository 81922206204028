import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { SpoFilters } from '../../../../models/filters/spo-filters-model';
import { GroupedSpoItem } from '../../../../models/spo-item-model';
import { addCheckers } from '../../../../shared/checkers.service';
import { ImportExportService } from '../../../../shared/import-export.service';
import { LotsService } from '../../../../shared/lots.service';
import { SposService } from '../../../../shared/spos.service';

@Component({
  selector: 'app-main-spos-list-details',
  templateUrl: './main-spos-list-details.component.html',
  styleUrls: ['./main-spos-list-details.component.css'],
})
export class MainSposListDetailsComponent implements OnInit {
  dataSource: MatTableDataSource<GroupedSpoItem>;

  displayedColumns = [
    'check',
    'spoCode',
    'orderId',
    'parentVendor',
    'supplier',
    'destination',
    'extra',
    'crossUkBorder',
    'earliestDeliveryDate',
    'latestDeliveryDate',
    'latestPickupDate',
    'ean',
    'obsoleteWithAccepted',
    'purchasePrice',
    'requestedQuantity',
    'confirmedQuantity',
    'orderedQuantity',
    'margin',
    'percentMargin',
    'requestedValue',
    'confirmedValue',
    'confirmedPurchasePrice',
    'virtualConfirmedPurchasePrice',
    'orderedValue',
    'orderedPurchasePrice',
    'virtualOrderedPurchasePrice',
    'virtualConfirmedMargin',
    'virtualConfirmedPercentMargin',
    'status',
  ];

  mainChecker = false;

  bulkActionsActive = false;

  dataSpo: any[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private sposService: SposService, private lotsService: LotsService, private importExportService: ImportExportService) {}

  ngOnInit() {
    this.lotsService.currentLotId = undefined;
    this.lotsService.currentLot = undefined;
    this.resultsNumber = this.sposService.resultsNumber;

    if (this.sposService.spoFilters === undefined) {
      this.sposService.spoFilters = new SpoFilters();
    }
    this.resetCheckers();
    this.dataSource = new MatTableDataSource([]);
    this.loadSpoItems(this.sposService.spoFilters);
  }

  loadSpoItems(filters: SpoFilters) {
    this.sposService.getFilteredGroupedSpoItems(undefined, filters).subscribe((spos: GroupedSpoItem[]) => {
      this.dataSpo = addCheckers(spos, this.sposService.selectedGroupedSpoItems, this.mainChecker);
      this.dataSource.data = this.dataSpo;
    });
  }

  loadPage() {
    this.sposService.spoFilters.pageNumber = this.paginator.pageIndex;
    this.sposService.spoFilters.pageSize = this.paginator.pageSize;
    this.loadSpoItems(this.sposService.spoFilters);
  }

  onSort() {
    this.sposService.spoFilters.sortBy = this.sort.active;
    this.sposService.spoFilters.sortDirection = this.sort.direction;
    this.loadSpoItems(this.sposService.spoFilters);
  }

  onSelected(value: boolean, selectedSpo: any) {
    const selectedIndex = this.dataSpo.indexOf(selectedSpo);
    this.dataSpo[selectedIndex].checked = value;
    if (value !== this.mainChecker) {
      this.sposService.addSpoItemIdToSelected(this.dataSpo[selectedIndex].id);
    } else {
      this.sposService.removeSpoItemIdFromSelected(this.dataSpo[selectedIndex].id);
    }
    this.bulkActionsActive = value || this.mainChecker;
  }

  toggleAll(value: boolean) {
    this.mainChecker = value;
    this.sposService.spoItemsMainChecker = this.mainChecker;
    for (const i of this.dataSpo) {
      i.checked = value;
    }
    this.sposService.selectedGroupedSpoItems = [];
    this.bulkActionsActive = value;
  }

  onFilter(filters: SpoFilters) {
    this.paginator.pageIndex = 0;
    this.resetCheckers();
    this.loadSpoItems(filters);
  }

  resetCheckers() {
    this.sposService.selectedGroupedSpoItems = [];
    this.mainChecker = false;
    this.bulkActionsActive = false;
  }

  export(format: string) {
    this.importExportService
      .exportSpoItems(this.sposService.selectedGroupedSpoItems, format, this.sposService.spoFilters, this.mainChecker, undefined)
      .subscribe((resp) => {
        console.log(resp);
      });
  }
}
