import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { SingleShipmentDetailsModule } from '../../../shared/single-shipment-detail/single-shipment-details.module';
import { ShipmentsDetailsKpiComponent } from './shipments-list-details/shipments-details-kpi/shipments-details-kpi.component';
// eslint-disable-next-line
import { ShipmentsListDetailsBulkActionsComponent } from './shipments-list-details/shipments-list-details-bulk-actions/shipments-list-details-bulk-actions.component';
import { ShipmentsListDetailsComponent } from './shipments-list-details/shipments-list-details.component';
import { ShipmentsListFiltersComponent } from './shipments-list-filters/shipments-list-filters.component';
import { ShipmentsKpiComponent } from './shipments-list/shipments-kpi/shipments-kpi.component';
import { ShipmentsListBulkActionsComponent } from './shipments-list/shipments-list-bulk-actions/shipments-list-bulk-actions.component';
import { ShipmentsListComponent } from './shipments-list/shipments-list.component';

@NgModule({
  declarations: [
    ShipmentsListComponent,
    ShipmentsListFiltersComponent,
    ShipmentsListBulkActionsComponent,
    ShipmentsKpiComponent,
    ShipmentsListDetailsComponent,
    ShipmentsListDetailsBulkActionsComponent,
    ShipmentsDetailsKpiComponent
  ],
  imports: [SharedModule, SingleShipmentDetailsModule],
})
export class ShipmentsModule {}
