/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { LoaderComponent } from '@core/loader/loader.component';
import { LoaderService } from '@core/loader/loader.service';
import { ImportExportService } from '@shared/import-export.service';
import { LogDiscardedValueService } from '@shared/log-discarded-value.service';
import { LotsService } from '@shared/lots.service';
import { PublicationStatusPipe } from '@shared/my-pipes';
import { PosService } from '@shared/pos.service';
import { ShipmentsService } from '@shared/shipments.service';
import { PoaDetailsComponent } from '@shared/single-po-details/poa-history-modal/poa-details/poa-details.component';
import { PoaHistoryModalComponent } from '@shared/single-po-details/poa-history-modal/poa-history-modal.component';
import { PoaItemHistoryModalComponent } from '@shared/single-po-details/poa-history-modal/poa-item-history-modal/poa-item-history-modal.component';
import { SinglePoDetailsFiltersComponent } from '@shared/single-po-details/single-po-details-filters/single-po-details-filters.component';
import { SinglePoDetailsComponent } from '@shared/single-po-details/single-po-details.component';
import { SingleSpoDetailsModule } from '@shared/single-spo-detail/single-spo-details.module';
import { SposService } from '@shared/spos.service';
import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared.module';
import { CategoriesService } from './configuration/categories/categories.service';
import { VendorsService } from './configuration/vendors/vendors.service';
import { WarehousesService } from './configuration/warehouses/warehouses.service';
import { ContainerComponent } from './container.component';
import { HeaderComponent } from './header/header.component';
import { InventoryService } from './inventory/inventory.service';
import { PublicationsService } from './inventory/publications/publications.service';
import { LotEditService } from './lot-edit-section/lot-edit.service';
import { LotsListFiltersComponent } from './main-section/lots-list/lots-list-filters/lots-list-filters.component';
import { LotsListComponent } from './main-section/lots-list/lots-list.component';
import { ClaimService } from './main-section/main-deductions/main-claim-headers/claim.service';
import { MainPosComponent } from './main-section/main-pos/main-pos.component';
import { PosListDetailsKpiComponent } from './main-section/main-pos/pos-list-details/pos-list-details-kpi/pos-list-details-kpi.component';
import { PosListDetailsComponent } from './main-section/main-pos/pos-list-details/pos-list-details.component';
import { PosListFiltersComponent } from './main-section/main-pos/pos-list/pos-list-filters/pos-list-filters.component';
import { PosListComponent } from './main-section/main-pos/pos-list/pos-list.component';
import { GenericErrorModalComponent } from './modal/generic-error-modal/generic-error-modal.component';
import { IncomingDateModalComponent } from './modal/incoming-date-modal/incoming-date-modal.component';
import { NewLotModalComponent } from './modal/new-lot-modal/new-lot-modal.component';
@NgModule({
  imports: [SharedModule, AppRoutingModule, SingleSpoDetailsModule],
  declarations: [
    HeaderComponent,
    LotsListComponent,
    ContainerComponent,
    LotsListFiltersComponent,
    PosListComponent,
    PosListFiltersComponent,
    SinglePoDetailsComponent,
    PoaHistoryModalComponent,
    PoaItemHistoryModalComponent,
    MainPosComponent,
    PosListDetailsComponent,
    NewLotModalComponent,
    GenericErrorModalComponent,
    SinglePoDetailsFiltersComponent,
    PosListDetailsKpiComponent,
    PoaDetailsComponent,
    LoaderComponent,
    IncomingDateModalComponent,
  ],
  providers: [
    InventoryService,
    VendorsService,
    ImportExportService,
    PosService,
    LotsService,
    WarehousesService,
    LoaderService,
    ShipmentsService,
    SposService,
    CategoriesService,
    LotEditService,
    PublicationStatusPipe,
    PublicationsService,
    LogDiscardedValueService,
    ClaimService,
  ],
  entryComponents: [
    NewLotModalComponent,
    GenericErrorModalComponent,
    PoaHistoryModalComponent,
    PoaItemHistoryModalComponent,
    IncomingDateModalComponent,
  ],
})
export class ContainerModule {}
