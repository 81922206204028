import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { WarehousesService } from '../warehouses.service';
import { Warehouse } from '../../../../models/warehouse-model';
import { GenericErrorModalComponent } from '../../../modal/generic-error-modal/generic-error-modal.component';
import { GenericConfirmationModalComponent } from '../../../modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-warehouse-edit',
  templateUrl: './warehouse-edit.component.html',
  styleUrls: ['./warehouse-edit.component.css'],
})
export class WarehouseEditComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private warehousesService: WarehousesService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  warehouseForm: FormGroup;
  editMode = false;
  warehouseId: number;
  currentWarehouse = new Warehouse();

  ngOnInit() {
    this.warehouseForm = new FormGroup({
      name: new FormControl('', Validators.required),
      code: new FormControl('', Validators.required),
      marketplace: new FormControl('', Validators.required),
      gln: new FormControl('', Validators.required),
      brandonGln: new FormControl('', Validators.required),
      businessName: new FormControl(''),
      address: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      postalCode: new FormControl('', Validators.required),
      region: new FormControl(''),
      country: new FormControl('', Validators.required),
      countryCode: new FormControl('', Validators.required),
      phone: new FormControl(''),
      mail: new FormControl(''),
      minimalValue: new FormControl(null),
    });

    this.route.params.subscribe((params: Params) => {
      this.warehouseId = +params['id'];
      this.editMode = params['id'] != null;
      if (this.editMode) {
        this.warehousesService.getWarehouse(this.warehouseId).subscribe((warehouse: Warehouse) => {
          this.currentWarehouse = warehouse;
          this.changeFormValues(this.currentWarehouse);
        });
      }
    });
  }

  changeFormValues(warehouse: Warehouse) {
    this.warehouseForm.setValue({
      name: warehouse.name ? warehouse.name : null,
      code: warehouse.code ? warehouse.code : null,
      marketplace: warehouse.marketplace ? warehouse.marketplace : null,
      gln: warehouse.glnCode ? warehouse.glnCode : null,
      brandonGln: warehouse.brandonGln ? warehouse.brandonGln : null,
      businessName: warehouse.businessName ? warehouse.businessName : null,
      address: warehouse.address ? warehouse.address : null,
      city: warehouse.city ? warehouse.city : null,
      postalCode: warehouse.postalCode ? warehouse.postalCode : null,
      region: warehouse.region ? warehouse.region : null,
      country: warehouse.country ? warehouse.country : null,
      countryCode: warehouse.countryCode ? warehouse.countryCode : null,
      phone: warehouse.phone ? warehouse.phone : null,
      mail: warehouse.mail ? warehouse.mail : null,
      minimalValue: warehouse.minimalValue ? warehouse.minimalValue : null,
    });
  }

  onCancel() {
    this.router.navigate(['configuration', 'warehouses']);
  }

  onSubmit() {
    if (this.editMode) {
      this.currentWarehouse.id = this.warehouseId;
    } else {
      this.currentWarehouse.id = null;
    }

    this.currentWarehouse.name = this.warehouseForm.value.name;
    this.currentWarehouse.code = this.warehouseForm.value.code;
    this.currentWarehouse.marketplace = this.warehouseForm.value.marketplace;
    this.currentWarehouse.glnCode = this.warehouseForm.value.gln;
    this.currentWarehouse.brandonGln = this.warehouseForm.value.brandonGln;
    this.currentWarehouse.businessName = this.warehouseForm.value.businessName;
    this.currentWarehouse.address = this.warehouseForm.value.address;
    this.currentWarehouse.city = this.warehouseForm.value.city;
    this.currentWarehouse.postalCode = this.warehouseForm.value.postalCode;
    this.currentWarehouse.region = this.warehouseForm.value.region;
    this.currentWarehouse.country = this.warehouseForm.value.country;
    this.currentWarehouse.countryCode = this.warehouseForm.value.countryCode;
    this.currentWarehouse.phone = this.warehouseForm.value.phone;
    this.currentWarehouse.mail = this.warehouseForm.value.mail;
    this.currentWarehouse.minimalValue = this.warehouseForm.value.minimalValue;

    this.warehousesService.postWarehouse(this.currentWarehouse).subscribe(() => {
      this.router.navigate(['configuration', 'warehouses']);
    });
  }

  onDelete() {
    const message = 'Deleting warehouse ' + this.currentWarehouse.code + ' - ' + this.currentWarehouse.name;

    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '600px',
      data: message,
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.warehousesService
          .deleteWarehouse(this.warehouseId)
          .toPromise()
          .then(() => {
            console.log('Warehouse deleted.');
            this.router.navigate(['configuration', 'warehouses']);
          })
          .catch(() => {
            const errorRef = this.dialog.open(GenericErrorModalComponent, {
              width: '350px',
              data: 'This Warehouse has associated POs and cannot be deleted.',
            });
          });
      }
    });
  }
}
