<div [ngClass]="{ 'black-panel': openFilter, panel: !openFilter }">
  <mat-expansion-panel class="filter-position" [attr.expanded]="openFilter" (opened)="openEvent()" (closed)="closeEvent()">
    <mat-expansion-panel-header style="max-height: 35px" class="filt-custom">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-9">
            <mat-chip-list>
              <mat-chip *ngFor="let f of filtersMap">{{ f.filter }}</mat-chip>
            </mat-chip-list>
          </div>
          <div class="col-md-2">
            <mat-panel-title class="filter-title"> Filters </mat-panel-title>
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>

    <div class="row filters-row">
      <form>
        <div class="col-md-12 text-blue margin-top-5 border-bottom-orange">
          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <textarea matInput name="eans" [(ngModel)]="filters.eans" placeholder="EAN"></textarea>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select
                  filterKey="description"
                  [(ngModel)]="filters.vendors"
                  name="vendor"
                  id="vendor"
                  placeholder="Vendor"
                  multiple
                  (change)="vendorChange($event)"
                >
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="vendor.id" *appMatSelectFor="let vendor of vendors">{{ vendor.description }}</mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <mat-select matInput [(ngModel)]="filters.productStatus" name="product-status" placeholder="Product status">
                  <mat-option [value]="null"> None </mat-option>
                  <mat-option [value]="'Active'"> Active </mat-option>
                  <mat-option [value]="'Suspended'"> Suspended </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <mat-select [(ngModel)]="filters.publicationStatus" id="status" name="status" placeholder="Status">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option *ngFor="let s of publicationsStatus" [value]="s.code">{{ s.description }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <mat-select [(ngModel)]="filters.newPriceStatus" id="newPriceStatus" name="newPriceStatus" placeholder="New Price Status">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option *ngFor="let nps of publicationsNewPriceStatus" [value]="nps.code">{{ nps.description }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Pending Approval Date</mat-label>
                <mat-date-range-input [rangePicker]="pendingApprovalDatePicker">
                  <input matStartDate [(ngModel)]="filters.pendingApprovalDateFrom" placeholder="Start date" />
                  <input matEndDate [(ngModel)]="filters.pendingApprovalDateTo" placeholder="End date" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="pendingApprovalDatePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #pendingApprovalDatePicker></mat-date-range-picker>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-md-12 border-top-white">
          <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-2 center">
              <button class="box-shadow-none btn-custom" style="float: right" type="submit" (click)="onFilter()">Filter</button>
            </div>
            <div class="col-md-2 center">
              <button class="box-shadow-none btn-custom" type="button" (click)="resetFilters()">Reset</button>
            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
      </form>

      <mat-divider></mat-divider>
    </div>
  </mat-expansion-panel>
</div>
