<div class="main-content">
  <form [formGroup]="publicationRuleForm" (ngSubmit)="onSubmit()">
    <div class="col-xs-12 margin-bottom-60">
      <div class="col-xs-2"></div>
      <div class="col-xs-8">
        <div class="row">
          <div class="col-xs-6">
            <mat-form-field>
              <mat-select id="type" formControlName="type" placeholder="Type">
                <mat-option [value]="'Supplier'">Supplier</mat-option>
                <!-- TODO CHANGE when we add new RoleTypes -->
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-xs-6">
            <mat-form-field>
              <app-mat-select filterKey="description" formControlName="status" id="status" placeholder="Status">
                <mat-option [value]="status.code" *appMatSelectFor="let status of status">{{ status.description }} </mat-option>
              </app-mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-6">
            <mat-form-field>
              <app-mat-select filterKey="name" formControlName="childId" id="childId" placeholder="Child Code">
                <mat-option [value]="null">None</mat-option>
                <mat-option [value]="vendor.id" *appMatSelectFor="let vendor of vendors">{{ vendor.code }}</mat-option>
              </app-mat-select>
            </mat-form-field>
          </div>

          <div class="col-xs-3">
            <mat-form-field>
              <app-mat-select filterKey="strategy" formControlName="strategy" id="strategy" placeholder="Strategy">
                <mat-option *ngFor="let strategy of strategies" [value]="strategy" (click)="choice = strategy">{{ strategy }}</mat-option>
              </app-mat-select>
            </mat-form-field>
          </div>

          <div class="col-xs-3">
            <mat-form-field>
              <input matInput type="text" id="strategyPercentage" formControlName="strategyPercentage" placeholder="%" />
            </mat-form-field>
          </div>
        </div>

        <ng-container>
          <div class="row padding-bottom-20">
            <div class="col-md-12">
              <app-priority-supplier-list
                (saveButtonDisabled)="onDisableSaveButton($event)"
                [prioritySuppliers]="jsonRule.prioritySuppliers"
              ></app-priority-supplier-list>
            </div>
          </div>
        </ng-container>

        <ng-container>
          <div class="row padding-bottom-20">
            <div class="col-md-12">
              <app-constraints [constraints]="jsonRule.constraints"></app-constraints>
            </div>
          </div>
        </ng-container>

        <ng-container>
          <div class="row padding-bottom-20">
            <div class="col-md-12" *ngIf="choice == 'Markup'" disabled>
              <app-markup-constraints [markupConstraints]="jsonRule.markupConstraints"></app-markup-constraints>
            </div>
          </div>
        </ng-container>

        <ng-container>
          <div class="row padding-bottom-20">
            <div class="col-md-12" *ngIf="choice == 'Margin'" disabled>
              <app-margin-constraints [marginConstraints]="jsonRule.marginConstraints"></app-margin-constraints>
            </div>
          </div>
        </ng-container>

        <div class="row">
          <div class="col-xs-4">
            <mat-form-field>
              <input matInput type="text" id="qtyThreshold" formControlName="qtyThreshold" placeholder="Quantity Threshold" />
            </mat-form-field>
          </div>

          <div class="col-xs-4">
            <mat-form-field>
              <input matInput type="text" id="unitCostThreshold" formControlName="unitCostThreshold" placeholder="Unit Cost Threshold" />
            </mat-form-field>
          </div>

          <div class="col-xs-4">
            <mat-form-field>
              <input matInput type="text" id="priceVariation" formControlName="priceVariation" placeholder="Price Variation (%)" />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-6">
            <mat-checkbox class="text-blue font-Titillium" id="sendFlatFile" formControlName="sendFlatFile">Send Flat File</mat-checkbox>
          </div>
        </div>

        <div class="col-xs-4 col-xs-offset-4">
          <div class="row form-buttons">
            <button type="submit" [disabled]="!publicationRuleForm.valid || disabledSave" class="btn-product">Save</button>
            <button *ngIf="editMode" type="button" (click)="onDelete()" class="btn-product margin-left-10">Delete!</button>
            <button type="button" (click)="onCancel()" class="btn-product margin-left-10">Back</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
