import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InventoryService } from '../../../container/inventory/inventory.service';
import { StockLocation } from '../../../models/stock-location-model';
import { StockLocationWithoutSupplier } from '../../../models/stock-location-without-supplier-model';

@Component({
  selector: 'app-origin-modal',
  templateUrl: './origin-modal.component.html',
  styleUrls: ['./origin-modal.component.css'],
})
export class OriginModalComponent implements OnInit {
  stockLocations: StockLocationWithoutSupplier[] = [];

  selectedStockLocationId: number;

  constructor(
    public dialogRef: MatDialogRef<OriginModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      origin: StockLocation;
    },
    private inventoryService: InventoryService
  ) {}

  ngOnInit() {
    this.selectedStockLocationId = this.data.origin.id;

    this.inventoryService.getStockLocationsBySupplierId(this.data.origin.supplier.id).subscribe((stockLocations: StockLocation[]) => {
      this.stockLocations = stockLocations;
    });
  }

  onClose() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close(this.selectedStockLocationId);
  }
}
