<mat-toolbar class="bar-custom">
  <div class="container">
    <div class="row">
      <div class="col-md-10">
        <button class="btn-custom" [routerLink]="['new']">New Vendor Central Account</button>
      </div>
    </div>
  </div>
</mat-toolbar>

<div class="container table-outer">
  <div class="table-header">
    <mat-form-field style="min-width: 100%">
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search" />
    </mat-form-field>
  </div>
  <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]"> </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="name">
        <mat-header-cell class="name-column" *matHeaderCellDef mat-sort-header="name"> Name </mat-header-cell>
        <mat-cell class="name-column" *matCellDef="let vca"
          ><p>{{ vca.name }}</p></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="hasBot">
        <mat-header-cell class="boolean-column" *matHeaderCellDef>Activated BOT</mat-header-cell>
        <mat-cell class="boolean-column" *matCellDef="let v">
          <p><i [ngClass]="v.botEnabled | booleanIconPipe" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="showVendors" stickyEnd>
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let vca">
          <button
            class="btn-product btn-fixed"
            style="width: 100px !important; float: right"
            *ngIf="vca.vendors"
            (click)="showVendors(vca)"
          >
            Show Vendors
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="edit">
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let vca">
          <button class="btn-product btn-fixed" style="margin-left: 15px" [routerLink]="['edit', vca.id]">Edit</button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
