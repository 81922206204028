import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { isNilty } from '@core/utils.service';
import { DisputeBotLogFilters } from '@models/filters/dispute-bot-filters-model';
import { DisputeBotLogService } from '../dispute-bot-log.service';
import { DisputeBotLog } from '@models/dispute-bot-log-model';

@Component({
  selector: 'app-dispute-bot-logs-list',
  templateUrl: './dispute-bot-logs-list.component.html',
  styleUrls: ['./dispute-bot-logs-list.component.css'],
})
export class DisputeBotLogsListComponent implements OnInit {
  constructor(private disputeBotLogService: DisputeBotLogService) {}

  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  displayedColumns = [
    'requestId',
    'invoiceNumber',
    'accountName',
    'date',
    'action',
    'status',
    'caseId',
    'caseDate',
    'disputeId',
    'disputeDate',
    'message',
  ];

  mainChecker = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit(): void {
    this.resultsNumber = this.disputeBotLogService.resultsNumber;

    if (isNilty(this.disputeBotLogService.disputeBotLogFilters)) {
      this.disputeBotLogService.disputeBotLogFilters = new DisputeBotLogFilters();
    }

    this.paginator.pageSize = this.disputeBotLogService.disputeBotLogFilters.pageSize;
    this.paginator.pageIndex = this.disputeBotLogService.disputeBotLogFilters.pageNumber;

    this.loadDisputeBotLog(this.disputeBotLogService.disputeBotLogFilters);
  }

  loadDisputeBotLog(filters: DisputeBotLogFilters) {
    this.disputeBotLogService.getFilteredDisputeBotLogs(filters).subscribe((bot: DisputeBotLog[]) => {
      this.dataSource.data = bot;
    });
  }

  onSort() {
    this.disputeBotLogService.disputeBotLogFilters.sortBy = this.sort.active;
    this.disputeBotLogService.disputeBotLogFilters.sortDirection = this.sort.direction;
    this.loadDisputeBotLog(this.disputeBotLogService.disputeBotLogFilters);
  }

  loadPage() {
    this.disputeBotLogService.disputeBotLogFilters.pageNumber = this.paginator.pageIndex;
    this.disputeBotLogService.disputeBotLogFilters.pageSize = this.paginator.pageSize;
    this.loadDisputeBotLog(this.disputeBotLogService.disputeBotLogFilters);
  }

  applyFilter(filters: DisputeBotLogFilters) {
    this.disputeBotLogService.disputeBotLogFilters.pageNumber = 0;
    this.loadDisputeBotLog(filters);
  }
}
