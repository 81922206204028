<ng-container *ngFor="let constraint of constraints">
  <mat-expansion-panel
    class="rule-edit-panel"
    style="margin-top: 10px; margin-bottom: 10px"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header class="constraints-header">
      <mat-panel-title class="rule-edit-panel-title"
        ><i class="fa fa-link margin-right-15" aria-hidden="true"></i>{{ constraint.description }}</mat-panel-title
      >
    </mat-expansion-panel-header>

    <div class="row padding-top-15">
      <mat-form-field>
        <input matInput placeholder="Margin (%)" [(ngModel)]="constraint.margin" type="number" [disabled]="!constraint.marginEnabled" />
      </mat-form-field>
    </div>

    <app-margin-predicates-edit
      [_predicates]="constraint.predicates"
      [marginEnabled]="constraint.marginEnabled"
    ></app-margin-predicates-edit>

    <div class="col-xs-4 col-xs-offset-10">
      <div class="row padding-top-15">
        <div class="row form-buttons">
          <button
            *ngIf="constraint.marginEnabled; else disable"
            type="button"
            matTooltip="click if you want disable current Margin Constraint"
            (click)="constraint.marginEnabled = false"
            class="btn-product"
          >
            Disable
          </button>
          <ng-template #disable>
            <button
              type="button"
              matTooltip="click if you want enable current Margin Constraint"
              (click)="constraint.marginEnabled = true"
              class="btn-product"
            >
              Enable
            </button>
          </ng-template>

          <button type="button" (click)="deleteConstraint(constraint)" class="btn-product margin-left-10">Delete</button>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</ng-container>

<div class="row constraint-row clickable-constraint-row" matTooltip="Add new constraint" (click)="addConstraint()">
  <div class="col-md-12 text-right">Add constraint</div>
</div>
