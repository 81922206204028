<h1 mat-dialog-title>{{ message }}</h1>
<div mat-dialog-content>
  <div class="text-center">{{ switchQuestion }}<mat-slide-toggle class="margin-left-15" [(ngModel)]="switchStatus"></mat-slide-toggle></div>
</div>
<div mat-dialog-actions>
  <div class="row width-100per">
    <div class="col-12 text-center confirm-text margin-bottom-24">{{ question }}</div>
    <div class="col-md-12 text-center width-100per">
      <button class="btn-product margin-right-15" (click)="changeStatus(true, switchStatus)">Confirm</button>
      <button class="btn-product" [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
    </div>
  </div>
</div>
