import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { FulfilmentService } from '../../../shared/fulfilment.service';
import { LotsService } from '../../../shared/lots.service';
import { LotEditService } from '../lot-edit.service';
import { FulfilmentFilters } from '../../../models/filters/fulfilment-filters-model';
import { Fulfilment } from '../../../models/fulfilment-model';
import { ImportExportService } from '../../../shared/import-export.service';
import { addCheckers } from '../../../shared/checkers.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-warehouse-fulfilment',
  templateUrl: './warehouse-fulfilment.component.html',
  styleUrls: ['./warehouse-fulfilment.component.css'],
})
export class WarehouseFulfilmentComponent implements OnInit {
  dataSource: MatTableDataSource<Fulfilment>;

  displayedColumns = [
    'check',
    'poId',
    'vendor',
    'destination',
    'crossUkBorder',
    'ean',
    'actualEan',
    'actualSupplierCode',
    'stockLocation',
    'submittedQuantity',
    'availableQuantity',
    'unitCost',
    'totalSubmittedCost',
    'totalAvailableCost',
  ];

  mainChecker = false;

  bulkActionsActive = false;

  dataFulfilment: any[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private fulfilmentService: FulfilmentService,
    private lotsService: LotsService,
    private lotEditService: LotEditService,
    private importExportService: ImportExportService
  ) {}

  ngOnInit() {
    this.resultsNumber = this.fulfilmentService.resultsNumber;
    this.lotsService.getLot().subscribe();

    if (this.lotEditService.fulfilmentFilters === undefined) {
      this.lotEditService.fulfilmentFilters = new FulfilmentFilters();
      this.lotEditService.fulfilmentFilters.lot = this.lotsService.currentLotId;
    }
    this.resetCheckers();
    this.dataSource = new MatTableDataSource([]);
    this.loadFulfilments(this.lotEditService.fulfilmentFilters);
  }

  loadFulfilments(filters: FulfilmentFilters) {
    this.fulfilmentService.getFilteredFulfilments(filters).subscribe((fulfilments: Fulfilment[]) => {
      this.dataFulfilment = addCheckers(fulfilments, this.lotEditService.selectedFulfilments, this.mainChecker);
      this.dataSource.data = this.dataFulfilment;
    });
  }

  loadPage() {
    this.lotEditService.fulfilmentFilters.pageNumber = this.paginator.pageIndex;
    this.lotEditService.fulfilmentFilters.pageSize = this.paginator.pageSize;
    this.loadFulfilments(this.lotEditService.fulfilmentFilters);
  }

  onSort() {
    this.lotEditService.fulfilmentFilters.sortBy = this.sort.active;
    this.lotEditService.fulfilmentFilters.sortDirection = this.sort.direction;
    this.loadFulfilments(this.lotEditService.fulfilmentFilters);
  }

  onSelected(value: boolean, selectedFul: any) {
    const selectedIndex = this.dataFulfilment.indexOf(selectedFul);
    this.dataFulfilment[selectedIndex].checked = value;
    if (value !== this.mainChecker) {
      this.lotEditService.addFulfilmentIdToSelected(this.dataFulfilment[selectedIndex].id);
    } else {
      this.lotEditService.removeFulfilmentIdFromSelected(this.dataFulfilment[selectedIndex].id);
    }
    this.bulkActionsActive = value || this.mainChecker;
  }

  toggleAll(value: boolean) {
    this.mainChecker = value;
    this.lotEditService.fulfilmentsMainChecker = this.mainChecker;
    for (const i of this.dataFulfilment) {
      i.checked = value;
    }

    this.lotEditService.selectedFulfilments = [];
    this.bulkActionsActive = value;
  }

  onFilter(filters: FulfilmentFilters) {
    this.paginator.pageIndex = 0;
    this.resetCheckers();
    this.loadFulfilments(filters);
  }

  resetCheckers() {
    this.lotEditService.selectedFulfilments = [];
    this.mainChecker = false;
    this.bulkActionsActive = false;
  }

  delete() {
    this.fulfilmentService
      .deleteFulfilments(
        this.lotEditService.selectedFulfilments,
        this.lotEditService.fulfilmentFilters,
        this.mainChecker,
        this.lotsService.currentLotId
      )
      .subscribe((resp: boolean) => {
        if (resp) {
          this.paginator.pageIndex = 0;
          this.resetCheckers();
          this.loadFulfilments(this.lotEditService.fulfilmentFilters);
        } else {
          return;
        }
      });
  }

  export(format: string) {
    this.importExportService
      .exportFulfilment(
        this.lotEditService.selectedFulfilments,
        format,
        this.lotEditService.fulfilmentFilters,
        this.mainChecker,
        this.lotsService.currentLotId
      )
      .subscribe((resp) => {
        console.log(resp);
      });
  }
}
