import { Component, OnInit } from '@angular/core';
import { CountryHolidays } from '../../../models/country-holidays-model';
import { CountryHolidaysService } from './country-holidays.service';
import { ConfigurationParamsService } from '../configuration-params/configuration-params.service';
import { DateRange } from '../../../models/date-range-model';
import { isNil } from '../../../core/utils.service';
import { MatDialog } from '@angular/material/dialog';
import { GenericErrorModalComponent } from '../../modal/generic-error-modal/generic-error-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-country-holidays',
  templateUrl: './country-holidays.component.html',
  styleUrls: ['./country-holidays.component.css'],
})
export class CountryHolidaysComponent implements OnInit {
  constructor(
    private countryHolidaysService: CountryHolidaysService,
    private configurationsService: ConfigurationParamsService,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar
  ) {}

  countryHolidaysArray: CountryHolidays[];
  brandonClosingDates: DateRange[];

  ngOnInit() {
    this.loadCountryHolidays();
    this.loadBrandonClosingDays();
  }

  private loadCountryHolidays() {
    this.countryHolidaysService.getCountryHolidays().subscribe((resp) => {
      this.countryHolidaysArray = resp;
      this.countryHolidaysArray.forEach((it) => it.setHolidaysString());
    });
  }

  save(ch: CountryHolidays) {
    this.countryHolidaysService.saveCountryHolidays(ch).subscribe(() => this.loadCountryHolidays());
  }

  private loadBrandonClosingDays() {
    this.configurationsService.getBrandonClosingDays().subscribe((resp: DateRange[]) => (this.brandonClosingDates = resp));
  }

  addNewClosingRange() {
    this.brandonClosingDates.push(new DateRange(undefined, undefined));
  }

  saveClosingDays() {
    if (this.validateClosingDays()) {
      this.configurationsService.saveBrandonClosingDays(this.brandonClosingDates).subscribe(() => {
        this.matSnackBar.open('Closing days have been saved.')._dismissAfter(3000);
        this.loadBrandonClosingDays();
      });
    } else {
      this.dialog.open(GenericErrorModalComponent, {
        width: '350px',
        data: 'Some of the dates are missing. Cannot save.',
      });
    }
  }

  private validateClosingDays(): boolean {
    for (const range of this.brandonClosingDates) {
      if (isNil(range.begin) || isNil(range.begin)) {
        return false;
      }
    }
    return true;
  }

  removeClosingDays(index: number) {
    this.brandonClosingDates.splice(index, 1);
  }

  resetClosingDays() {
    this.loadBrandonClosingDays();
  }
}
