import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Supplier } from '../../../models/supplier-model';
import { InventoryService } from '../inventory.service';
import { CatalogItem } from '../../../models/catalog-item-model';
import { Observable, Subject } from 'rxjs';
import { CatalogItemsFilters } from '../../../models/filters/catalog-items-filters-model';
import { addCheckers } from '../../../shared/checkers.service';
import { GenericConfirmationModalComponent } from '../../modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { isNilty } from '../../../core/utils.service';
import { AuthorizationService } from '../../../core/authorization.service';
import { Permissions } from '../../../models/permission-model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-supplier-products',
  templateUrl: './supplier-products.component.html',
  styleUrls: ['./supplier-products.component.css'],
})
export class SupplierProductsComponent implements OnInit {
  @Output() onChangeSupplierProductsStatus = new Subject<string>();

  supplier: Supplier = new Supplier();

  dataSource: MatTableDataSource<CatalogItem>;

  displayedColumns = [
    'check',
    'ean',
    'sku',
    'title',
    'countryOfOrigin',
    'unNumber',
    'brand',
    'category',
    'season',
    'size',
    'colorCode',
    'color',
    'wholesalePrice',
    'retailPrice',
    'physicalQuantity',
    'availableQuantity',
    'purchasePrice',
    'updatedAt',
    'status',
  ];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  mainChecker = false;

  bulkActionsActive = false;

  dataCatalog: any[] = [];

  hasCatalogUpdate: Observable<boolean>;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private inventoryService: InventoryService,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit() {
    this.resultsNumber = this.inventoryService.resultsNumber;
    this.mainChecker = this.inventoryService.mainChecker;

    if (isNilty(this.inventoryService.catalogItemsFilters)) {
      this.inventoryService.catalogItemsFilters = new CatalogItemsFilters();
    }

    this.route.params.subscribe((param: Params) => {
      const id = param['suppId'];
      this.resetCheckers();
      this.dataSource = new MatTableDataSource([]);
      this.inventoryService.getSupplierById(+id).subscribe((supplierResp: Supplier) => {
        this.supplier = supplierResp;
        this.loadSupplierProducts();
      });
    });

    this.hasCatalogUpdate = this.authorizationService.hasPermission(Permissions.CatalogUpdate);
  }

  loadSupplierProducts() {
    this.inventoryService.catalogItemsFilters.supplierId = this.supplier.id;
    this.inventoryService
      .getSupplierProducts(this.supplier.id, this.inventoryService.catalogItemsFilters)
      .subscribe((catalogResp: CatalogItem[]) => {
        this.dataCatalog = addCheckers(catalogResp, this.inventoryService.selectedCatalogItems, this.mainChecker);
        this.dataSource.data = this.dataCatalog;
      });
  }

  loadPage() {
    this.inventoryService.catalogItemsFilters.pageNumber = this.paginator.pageIndex;
    this.inventoryService.catalogItemsFilters.pageSize = this.paginator.pageSize;
    this.loadSupplierProducts();
  }

  onSort() {
    this.inventoryService.catalogItemsFilters.sortBy = this.sort.active;
    this.inventoryService.catalogItemsFilters.sortDirection = this.sort.direction;
    this.loadSupplierProducts();
  }

  goBack() {
    this.router.navigate(['inventory/suppliers']);
  }

  onFilter(filters) {
    this.inventoryService.catalogItemsFilters = filters;
    this.paginator.pageIndex = 0;
    this.loadSupplierProducts();
  }

  onSelected(value: boolean, selectedCatalogItem: any) {
    const selectedIndex = this.dataCatalog.indexOf(selectedCatalogItem);
    this.dataCatalog[selectedIndex].checked = value;
    if (value !== this.mainChecker) {
      this.inventoryService.addIdToSelected(this.dataCatalog[selectedIndex].id);
    } else {
      this.inventoryService.removeIdFromSelected(this.dataCatalog[selectedIndex].id);
    }
    this.bulkActionsActive = value || this.mainChecker;
  }

  toggleAll(value: boolean) {
    this.mainChecker = value;
    this.inventoryService.mainChecker = value;
    for (const i of this.dataCatalog) {
      i.checked = value;
    }

    this.inventoryService.selectedCatalogItems = [];
    this.bulkActionsActive = value;
  }

  resetCheckers() {
    this.mainChecker = false;
    this.bulkActionsActive = false;
    this.inventoryService.selectedCatalogItems = [];
  }

  delete() {
    if (this.mainChecker) {
      this.inventoryService.countCatalogItems(this.inventoryService.catalogItemsFilters).subscribe((resp: number) => {
        this.callDelete(resp);
      });
    } else {
      this.callDelete(this.inventoryService.selectedCatalogItems.length);
    }
  }

  private callDelete(size: number) {
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '350px',
      data: 'You are about to delete ' + size + ' Catalog items.',
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.inventoryService.deleteCatalogItems(this.inventoryService.catalogItemsFilters, this.mainChecker).subscribe((resp: boolean) => {
          if (resp) {
            this.paginator.pageIndex = 0;
            this.resetCheckers();
            this.loadSupplierProducts();
          } else {
            return;
          }
        });
      } else {
        console.log('Rows not deleted.');
      }
    });
  }

  changeCatalogItemStatus(status: string) {
    this.inventoryService
      .changeCatalogItemStatus(status, this.inventoryService.selectedCatalogItems, this.supplier.id, this.mainChecker)
      .subscribe(() => {
        this.resetCheckers();
        this.loadSupplierProducts();
      });
  }

  getSupplierDiscount(id: number) {
    this.router.navigate(['inventory/supplier-discount', id]);
  }
}
