<app-cachargeback-list-filters (onFilter)="onFilter($event)"></app-cachargeback-list-filters>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <hr class="divisor-title" />
      <div class="square"></div>
      <h2 class="po-element-custom title-custom">Chargebacks</h2>
    </div>
  </div>
</div>

<div class="container table-outer">
  <mat-paginator [length]="resultsNumber | async" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()">
  </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="code" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="code" class="code-column">Code </mat-header-cell>
        <mat-cell *matCellDef="let c" class="code-column">
          <p>{{ c.code }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="issueType">
        <mat-header-cell class="issue-type-column" *matHeaderCellDef mat-sort-header="issueType">Issue Type </mat-header-cell>
        <mat-cell class="issue-type-column" *matCellDef="let c">
          <p>{{ c.issueType }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="creationDate">
        <mat-header-cell class="date-column" *matHeaderCellDef mat-sort-header="creationDate">Creation Date </mat-header-cell>
        <mat-cell class="date-column" *matCellDef="let c">
          <p>{{ c.creationDate }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="financialCharge">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="financialCharge">Financial Charge</mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let c">
          <p>{{ c.financialCharge | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="quantity">Quantity </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let c">
          <p>{{ c.quantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="vendorName">
        <mat-header-cell class="vendor-name-column" *matHeaderCellDef mat-sort-header="vendorName">Vendor Name </mat-header-cell>
        <mat-cell class="vendor-name-column" *matCellDef="let c">
          <p>{{ c.vendor?.name }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="remainingDays">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef>Remaining Days </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let c">
          <p>{{ getRemainingDays(c.disputeBy) }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="productName">
        <mat-header-cell class="product-name-column" *matHeaderCellDef mat-sort-header="productName">Product Name </mat-header-cell>
        <mat-cell class="product-name-column" *matCellDef="let c">
          <p>{{ c.productName }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="poNumber">
        <mat-header-cell class="po-number-column" *matHeaderCellDef mat-sort-header="poNumber">Po Number </mat-header-cell>
        <mat-cell class="po-number-column" *matCellDef="let c">
          <p>{{ c.poNumber }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ean">
        <mat-header-cell class="ean-column" *matHeaderCellDef mat-sort-header="ean">Ean </mat-header-cell>
        <mat-cell class="ean-column" *matCellDef="let c">
          <p>{{ c.ean }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="asin">
        <mat-header-cell class="asin-column" *matHeaderCellDef mat-sort-header="asin">Asin </mat-header-cell>
        <mat-cell class="asin-column" *matCellDef="let c">
          <p>{{ c.asin }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="fulfilmentCentre">
        <mat-header-cell class="fulfilment-centre-column" *matHeaderCellDef mat-sort-header="fulfilmentCentre">
          Fulfilment Centre
        </mat-header-cell>
        <mat-cell class="fulfilment-centre-column" *matCellDef="let c">
          <p>{{ c.fulfilmentCentre }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="carrier">
        <mat-header-cell class="carrier-column" *matHeaderCellDef mat-sort-header="carrier">Carrier </mat-header-cell>
        <mat-cell class="carrier-column" *matCellDef="let c">
          <p>{{ c.carrier }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="charged">
        <mat-header-cell *matHeaderCellDef mat-sort-header="charged" class="boolean-column">
          Charged
        </mat-header-cell>
        <mat-cell *matCellDef="let c" class="boolean-column">
          <p><i [ngClass]="c.charged | booleanIconPipe" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
