<div [ngClass]="{ 'black-panel': openFilter, panel: !openFilter }">
  <mat-expansion-panel class="filter-position" [attr.expanded]="openFilter" (opened)="openEvent()" (closed)="closeevent()">
    <mat-expansion-panel-header class="filt-custom">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-9">
            <mat-chip-list>
              <mat-chip *ngFor="let f of filtersMap">{{ f.filter }}</mat-chip>
            </mat-chip-list>
          </div>
          <div class="col-md-2">
            <mat-panel-title class="filter-title"> Filters </mat-panel-title>
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>

    <div class="row" style="padding-top: 15px">
      <form [formGroup]="filterForm" (submit)="onSubmitFilter()">
        <div class="container border-bottom-orange col-md-12 text-blue margin-top-5">
          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="invoiceNumber" placeholder="Invoice Number" />
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Invoice Date range</mat-label>
                <mat-date-range-input [rangePicker]="invoiceDate">
                  <input matStartDate formControlName="invoiceDateStart" placeholder="Start date" />
                  <input matEndDate formControlName="invoiceDateEnd" placeholder="End date" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="invoiceDate"></mat-datepicker-toggle>
                <mat-date-range-picker #invoiceDate></mat-date-range-picker>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Remittance Date range</mat-label>
                <mat-date-range-input [rangePicker]="remittanceDate">
                  <input matStartDate formControlName="remittanceDateStart" placeholder="Start date" />
                  <input matEndDate formControlName="remittanceDateEnd" placeholder="End date" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="remittanceDate"></mat-datepicker-toggle>
                <mat-date-range-picker #remittanceDate></mat-date-range-picker>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select filterKey="description" formControlName="vendor" id="vendor" placeholder="Vendor">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="vendor" *appMatSelectFor="let vendor of vendors">{{ vendor.description }} </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select filterKey="description" formControlName="supplier" id="supplier" placeholder="Supplier">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="supplier.code" *appMatSelectFor="let supplier of suppliers">
                    {{ supplier.description }}
                  </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select
                  filterKey="name"
                  formControlName="carrierContracts"
                  id="carrierContracts"
                  placeholder="Carrier Contract"
                  [multiple]="true"
                >
                  <mat-option [value]="contract" *appMatSelectFor="let contract of contracts">
                    {{ contract.name }}
                  </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="trackingNumber" placeholder="Tracking Number" />
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="disputeId" placeholder="Dispute ID" />
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="caseId" placeholder="Case ID" />
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="deliveryDate" [matDatepicker]="deliveryDate" placeholder="Delivery Date" />
                <mat-datepicker-toggle matSuffix [for]="deliveryDate"></mat-datepicker-toggle>
                <mat-datepicker #deliveryDate></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select formControlName="hasPod" placeholder="Has Pod">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="true">True</mat-option>
                  <mat-option [value]="false">False</mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select formControlName="hasRemittance" placeholder="Has Remittance">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="true">True</mat-option>
                  <mat-option [value]="false">False</mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select formControlName="hasProbableNC" placeholder="Has Probable NC">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="true">True</mat-option>
                  <mat-option [value]="false">False</mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select formControlName="scStatus" placeholder="SC Status" [multiple]="true">
                  <mat-option [value]="st.code" *appMatSelectFor="let st of status">
                    {{ st.description }}
                  </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select formControlName="pcStatus" placeholder="PC Status" [multiple]="true">
                  <mat-option [value]="st.code" *appMatSelectFor="let st of status | psStatusPipe">
                    {{ st.description }}
                  </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <input matInput type="number" formControlName="debitNoteYear" placeholder="Debit Note Year" />
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <input matInput type="number" formControlName="debitNoteMonth" placeholder="Debit Note Month" />
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="countryOfDestination" placeholder="Country of Destination" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select filterKey="description" formControlName="category" id="category" placeholder="Category">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="category" *appMatSelectFor="let category of categories">{{ category.name }} </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="ean" placeholder="EAN" />
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="po" placeholder="PO" />
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select formControlName="settlement" placeholder="Settlement 2022">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="true">True</mat-option>
                  <mat-option [value]="false">False</mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select formControlName="botEnabled" placeholder="Bot Enabled">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="true">True</mat-option>
                  <mat-option [value]="false">False</mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-form-field class="small-mat-form-field">
                <input matInput formControlName="dueDateFrom" [matDatepicker]="dueDateFrom" placeholder="From Due Date" />
                <mat-datepicker-toggle matSuffix [for]="dueDateFrom"></mat-datepicker-toggle>
                <mat-datepicker #dueDateFrom></mat-datepicker>
              </mat-form-field>

              <mat-form-field class="small-mat-form-field margin-left-10">
                <input matInput formControlName="dueDateTo" [matDatepicker]="dueDateTo" placeholder="To Due Date" />
                <mat-datepicker-toggle matSuffix [for]="dueDateTo"></mat-datepicker-toggle>
                <mat-datepicker #dueDateTo></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-md-12 border-top-white">
          <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-2 center">
              <button class="btn-custom padding-left-right-70" type="submit">Filter</button>
            </div>
            <div class="col-md-2 center">
              <button class="btn-custom padding-left-right-70" type="button" (click)="resetFilters()">Reset</button>
            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
</div>
