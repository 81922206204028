import { Component, OnInit, ViewChild } from '@angular/core';
import { InvoiceService } from '../../container/main-section/main-invoices/invoice.service';
import { Invoice } from '../../models/invoice-model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { InvoiceItem } from '../../models/invoice-item-model';
import { Observable } from 'rxjs';
import { InvoiceItemsFilters } from '../../models/filters/invoice-items-filters-model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { addCheckers } from '../checkers.service';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isNil, isNilty } from '../../core/utils.service';
import { MatDialog } from '@angular/material/dialog';
import { GenericErrorModalComponent } from '../../container/modal/generic-error-modal/generic-error-modal.component';
import { AuthorizationService } from '../../core/authorization.service';
import { Permissions } from '../../models/permission-model';
import { ImportExportService } from '../import-export.service';
import { GenericConfirmationModalComponent } from '../../container/modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { Claim } from '@models/claim-model';
import { ClaimService } from '@container/main-section/main-deductions/main-claim-headers/claim.service';
import { ClaimHeaderFilters } from '@models/filters/claim-header-filters-model';
import { ClaimDetail } from '@models/claim-detail-model';

@Component({
  selector: 'app-single-invoice-detail',
  templateUrl: './single-invoice-detail.component.html',
  styleUrls: ['./single-invoice-detail.component.css'],
})
export class SingleInvoiceDetailComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  invId: number;

  invoice: Invoice = new Invoice();

  claims: Claim[] = [];

  filters = new InvoiceItemsFilters();

  dataSource: MatTableDataSource<InvoiceItem>;

  resultsNumber: Observable<number>;

  dataInvoiceItem: any[];
  selectedInvoiceItems: number[] = [];
  mainChecker = false;
  bulkActionsActive = false;

  displayedColumns = ['ean', 'actualEan', 'title', 'shippedQuantity', 'shippedValue', 'taxRate'];

  hasInvoiceManagement: Observable<boolean>;
  hasPodUrls: boolean;

  constructor(
    private invoiceService: InvoiceService,
    private route: ActivatedRoute,
    private location: Location,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private authorizationService: AuthorizationService,
    private importExportService: ImportExportService,
    private claimService: ClaimService,
    private router: Router
  ) {}

  ngOnInit() {
    this.resultsNumber = this.invoiceService.resultsNumber;
    this.route.params.subscribe((param: Params) => {
      this.invId = +param['id'];
      this.filters = new InvoiceItemsFilters();
      this.dataSource = new MatTableDataSource<InvoiceItem>([]);
      this.getInvoice();
      this.getInvoiceItems();
    });

    this.hasInvoiceManagement = this.authorizationService.hasPermission(Permissions.InvoiceManagement);
  }

  showClaim() {
    this.claimService.claimHeaderFilters = new ClaimHeaderFilters();
    this.claimService.claimHeaderFilters.invoiceNumber = this.invoice.invoiceNumber;
    this.router.navigate(['deductions', 'claims']);
  }

  getInvoice() {
    this.invoiceService.getSingleInvoice(this.invId).subscribe((resp: Invoice) => {
      this.invoice = resp;
      this.hasPodUrls = this.invoice.shipment.podUrls != null && this.invoice.shipment.podUrls.length > 0;

      this.invoiceService.getClaims(this.invoice.invoiceNumber).subscribe((claims: Claim[]) => {
        if (claims) {
          this.claims = claims;
        }
      });
    });
  }

  getInvoiceItems() {
    this.filters.invoiceId = this.invId;
    this.invoiceService.getFilteredInvoiceItems(this.filters).subscribe((resp: InvoiceItem[]) => {
      this.dataInvoiceItem = addCheckers(resp, this.selectedInvoiceItems, this.mainChecker);
      this.dataSource.data = this.dataInvoiceItem;
    });
  }

  loadPage() {
    this.filters.pageNumber = this.paginator.pageIndex;
    this.filters.pageSize = this.paginator.pageSize;
    this.getInvoiceItems();
  }

  onSort() {
    this.filters.sortBy = this.sort.active;
    this.filters.sortDirection = this.sort.direction;
    this.getInvoiceItems();
  }

  onSelected(value: boolean, selectedInvoice: any) {
    const selectedIndex = this.dataInvoiceItem.indexOf(selectedInvoice);
    this.dataInvoiceItem[selectedIndex].checked = value;
    if (value !== this.mainChecker) {
      this.selectedInvoiceItems.push(this.dataInvoiceItem[selectedIndex].id);
      this.selectedInvoiceItems.filter((v, i, a) => a.indexOf(v) === i);
    } else {
      const index = this.selectedInvoiceItems.indexOf(this.dataInvoiceItem[selectedIndex].id);
      this.selectedInvoiceItems.splice(index, 1);
    }
    this.bulkActionsActive = value || this.mainChecker;
  }

  toggleAll(value: boolean) {
    this.mainChecker = value;
    for (const i of this.dataInvoiceItem) {
      i.checked = value;
    }
    this.selectedInvoiceItems = [];
    this.bulkActionsActive = value;
  }

  sendInvoice() {
    const filters = new InvoiceItemsFilters();
    filters.invoiceId = this.invoice.id;
    let errorMessage: string;
    if (this.invoice.billToCountryCode !== 'IT') {
      if (!isNil(this.invoice.shipment.deliveryDate)) {
        this.invoiceService.sendInvoice([this.invoice.id]).subscribe(() => {
          this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
          return 'Done';
        });
      } else {
        errorMessage = 'Invoice cannot be sent because the linked Shipment has no delivery date.';
      }
    } else {
      errorMessage = 'Current Invoice is billed to Italy and cannot be sent via EDI.';
    }
    if (!isNilty(errorMessage)) {
      this.dialog.open(GenericErrorModalComponent, { data: errorMessage });
    }
  }

  exportInvoice() {
    this.importExportService.exportInvoice(this.invoice.invoiceNumber).subscribe((resp) => console.log(resp));
  }

  exportInvoiceXml() {
    this.importExportService.exportInvoiceXml(this.invoice.invoiceNumber).subscribe((resp) => console.log(resp));
  }

  goBack() {
    this.location.back();
  }

  deleteInvoice() {
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '400px',
      data: 'Deleting invoice ' + this.invoice.invoiceNumber,
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.invoiceService.deleteInvoice(this.invoice.id).subscribe(() => {
          this.location.back();
          this.snackBar.open('Deleted invoice ' + this.invoice.invoiceNumber, 'CLOSE')._dismissAfter(5000);
        });
      }
    });
  }

  downloadPods() {
    this.invoiceService.downloadPods(this.invoice.shipment.id).subscribe((data) => {
      const podsBlob = new Blob([data.body], { type: 'application/zip' });
      const url = window.URL.createObjectURL(podsBlob);
      const anchor = document.createElement('a');
      const content = data.headers.get('content-disposition').split(';')[1].split('filename')[1].split('=')[1];
      anchor.download = content.trim().replace(/['"]+/g, '');
      anchor.href = url;
      anchor.click();
    });
  }
}
