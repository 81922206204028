import { Component, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { VendorsService } from '@container/configuration/vendors/vendors.service';
import { WarehousesService } from '@container/configuration/warehouses/warehouses.service';
import { InventoryService } from '@container/inventory/inventory.service';
import { isNilty } from '@core/utils.service';
import { SpoFilters, spoFiltersMap } from '@models/filters/spo-filters-model';
import { MatChipsData } from '@models/mat-chips-data-model';
import { SpoStatus } from '@models/spo-model';
import { Supplier } from '@models/supplier-model';
import { Vendor } from '@models/vendor-model';
import { Warehouse } from '@models/warehouse-model';
import { CommonsService } from '@shared/commons.service';
import { SpoStatusPipe } from '@shared/my-pipes';
import { SposService } from '@shared/spos.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-main-spos-filters',
  templateUrl: './main-spos-filters.component.html',
  styleUrls: ['./main-spos-filters.component.css'],
})
export class MainSposFiltersComponent implements OnInit {
  constructor(
    private vendorsService: VendorsService,
    private warehousesService: WarehousesService,
    private inventoryService: InventoryService,
    private sposService: SposService,
    private commonsService: CommonsService,
    private spoStatusPipe: SpoStatusPipe
  ) {}

  warehouses: Warehouse[] = [];

  vendors: Vendor[] = [];
  parentVendors: Vendor[] = [];

  suppliers: Supplier[] = [];

  @Output() onFilter = new Subject<SpoFilters>();

  filterForm: FormGroup;

  public openFilter = false;

  filtersMap: MatChipsData[] = [];

  spoStatusArray: SpoStatus[];

  ngOnInit() {
    this.commonsService.getSpoStatuses().subscribe((r) => (this.spoStatusArray = r));

    this.filterForm = new FormGroup({
      parent: new FormControl(),
      destination: new FormControl(),
      ean: new FormControl(),
      po: new FormControl(),
      supplier: new FormControl(),
      lotName: new FormControl(),
      unitCostFrom: new FormControl(),
      unitCostTo: new FormControl(),
      orderId: new FormControl(),
      status: new FormControl(),
      acceptBindingOrders: new FormControl(),
      hasOrderedQuantity: new FormControl(),
      obsoleteWithAccepted: new FormControl(),
      crossUkBorder: new FormControl(),
      isExtra: new FormControl(),
      isAdr: new FormControl(),
      poOrderedOnFrom: new FormControl(),
      poOrderedOnTo: new FormControl(),
      lotDateFrom: new FormControl(),
      lotDateTo: new FormControl(),
    });

    if (this.sposService.spoFilters !== undefined) {
      this.filterForm.patchValue(this.sposService.spoFilters);
    }

    if (this.vendorsService.vendors === undefined) {
      this.vendorsService.getVendors().subscribe((vendorsResp: Vendor[]) => {
        this.parentVendors = vendorsResp.filter((it) => it.parent);
        this.setParent();
      });
    } else {
      this.parentVendors = this.vendorsService.vendors.filter((it) => it.parent);
      this.setParent();
    }

    if (this.warehousesService.lotEditWarehouses === undefined) {
      this.warehousesService.getWarehouses().subscribe((warehouses: Warehouse[]) => {
        this.warehouses = warehouses;
        this.setDestination();
      });
    } else {
      this.warehouses = this.warehousesService.lotEditWarehouses;
      this.setDestination();
    }

    this.inventoryService.getAllSuppliers().subscribe((suppliersResp: Supplier[]) => {
      this.suppliers = suppliersResp;
      this.setSupplier();
    });
  }

  setParent() {
    if (this.sposService.spoFilters !== null && this.sposService.spoFilters.parent !== null) {
      this.filterForm.controls['parent'].patchValue(this.parentVendors.find((it) => it.id === this.sposService.spoFilters.parent));
      this.updateFiltersMap();
    }
  }

  setDestination() {
    if (this.sposService.spoFilters !== null && this.sposService.spoFilters.destination !== null) {
      this.filterForm.controls['destination'].patchValue(this.warehouses.find((it) => it.id === this.sposService.spoFilters.destination));
      this.updateFiltersMap();
    }
  }

  setSupplier() {
    if (this.sposService.spoFilters !== null && this.sposService.spoFilters.supplier !== null) {
      this.filterForm.controls['supplier'].patchValue(this.suppliers.find((it) => it.id === this.sposService.spoFilters.supplier));
      this.updateFiltersMap();
    }
  }

  saveFiltersToService() {
    this.sposService.saveSpoFiltersToService(this.filterForm);
  }

  onSubmitFilter() {
    this.saveFiltersToService();
    this.onFilter.next(this.sposService.spoFilters);
    this.updateFiltersMap();
  }

  resetFilters() {
    this.filterForm.reset();
    this.saveFiltersToService();
    this.onFilter.next(this.sposService.spoFilters);
    this.updateFiltersMap();
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeevent() {
    this.openFilter = false;
  }

  updateFiltersMap() {
    const status = !isNilty(this.filterForm.value.status) ? this.spoStatusPipe.transform(this.filterForm.value.status) : null;
    this.filtersMap = spoFiltersMap(
      this.filterForm.value.parent,
      this.filterForm.value.ean,
      this.filterForm.value.po,
      this.filterForm.value.destination,
      this.filterForm.value.supplier,
      this.filterForm.value.lotName,
      this.sposService.spoFilters.unitCostFrom,
      this.sposService.spoFilters.unitCostTo,
      this.sposService.spoFilters.orderId,
      this.sposService.spoFilters.acceptBindingOrders,
      this.sposService.spoFilters.hasOrderedQuantity,
      this.sposService.spoFilters.obsoleteWithAccepted,
      this.sposService.spoFilters.crossUkBorder,
      this.sposService.spoFilters.isExtra,
      this.sposService.spoFilters.isAdr,
      status,
      this.filterForm.value.poOrderedOnFrom,
      this.filterForm.value.poOrderedOnTo,
      this.filterForm.value.lotDateFrom,
      this.filterForm.value.lotDateTo
    );
  }
}
