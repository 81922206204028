import { JsonObject, JsonProperty } from 'json2typescript';
import { PoItem } from './po-item-model';

@JsonObject('LogDiscardedValueDetail')
export class LogDiscardedValueDetail {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('poItem', PoItem, true) poItem: PoItem = undefined;
  @JsonProperty('ean', String, true) ean: string = undefined;
  @JsonProperty('unitCost', Number, true) unitCost: number = undefined;
  @JsonProperty('physicalQuantity', Number, true) physicalQuantity: number = undefined;
  @JsonProperty('purchasePrice', Number, true) purchasePrice: number = undefined;
  @JsonProperty('discardedQuantity', Number, true) discardedQuantity: number = undefined;
  @JsonProperty('discardedValue', Number, true) discardedValue: number = undefined;
}
