import { Component, Input, ViewChild } from '@angular/core';
import { CartonItem } from '../../../../models/carton-item-model';
import { compareDates, compareNumberOrString, isNilty } from '../../../../core/utils.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-carton-items-table',
  templateUrl: './carton-items-table.component.html',
  styleUrls: ['./carton-items-table.component.css'],
})
export class CartonItemsTableComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() set cartonItems(values: CartonItem[]) {
    if (!isNilty(values)) {
      this.dataSource = new MatTableDataSource(values);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  dataSource: MatTableDataSource<CartonItem>;
  displayedColumns = ['title', 'ean', 'actualEan', 'poId', 'vendor', 'brand', 'submittedQuantity', 'shippedQuantity', 'boxQuantity'];

  constructor() {}

  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'title':
          return compareNumberOrString(
            a.shipmentItem.purchaseOrderItem.product.title,
            b.shipmentItem.purchaseOrderItem.product.title,
            isAsc
          );
        case 'ean':
          return compareNumberOrString(a.shipmentItem.purchaseOrderItem.product.ean, b.shipmentItem.purchaseOrderItem.product.ean, isAsc);
        case 'actualEan':
          return compareNumberOrString(a.shipmentItem.actualEan, b.shipmentItem.actualEan, isAsc);
        case 'poId':
          return compareNumberOrString(
            a.shipmentItem.purchaseOrderItem.purchaseOrder.poId,
            b.shipmentItem.purchaseOrderItem.purchaseOrder.poId,
            isAsc
          );
        case 'vendor':
          return compareNumberOrString(
            a.shipmentItem.purchaseOrderItem.purchaseOrder.vendor.name,
            b.shipmentItem.purchaseOrderItem.purchaseOrder.vendor.name,
            isAsc
          );
        case 'brand':
          return compareNumberOrString(
            a.shipmentItem.purchaseOrderItem.product.brand.name,
            b.shipmentItem.purchaseOrderItem.product.brand.name,
            isAsc
          );
        case 'submittedQuantity':
          return compareNumberOrString(
            a.shipmentItem.purchaseOrderItem.submittedQuantity,
            b.shipmentItem.purchaseOrderItem.submittedQuantity,
            isAsc
          );
        case 'shippedQuantity':
          return compareNumberOrString(a.shipmentItem.shippedQuantity, b.shipmentItem.shippedQuantity, isAsc);
        case 'boxQuantity':
          return compareNumberOrString(a.boxQuantity, b.boxQuantity, isAsc);
        default:
          return compareNumberOrString(a[sort.active], b[sort.active], isAsc);
      }
    });
  }
}
