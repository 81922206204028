import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Warehouse } from '../../../../models/warehouse-model';
import { TransitTime } from '../../../../models/transit-time-model';
import { isNil, isNilty } from '../../../../core/utils.service';
import { WarehousesService } from '../../warehouses/warehouses.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { GenericConfirmationModalComponent } from '../../../modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-stock-locations-transit-times',
  templateUrl: './stock-locations-transit-times.component.html',
  styleUrls: ['./stock-locations-transit-times.component.css'],
})
export class StockLocationsTransitTimesComponent implements OnInit {
  constructor(private warehousesService: WarehousesService, private matDialog: MatDialog) {}

  @ViewChild('filterInput', { static: true }) filterInput: ElementRef;
  filterText: string;

  private allWarehouses: Warehouse[];

  availableWarehouses: Warehouse[];
  transitTimes: TransitTime[];

  dataSource = new MatTableDataSource<TransitTime>();
  displayedColumns = ['destination', 'country', 'transitTimeWorkingDays', 'edit'];

  isEditTransitTime = false;
  editingDestinationId: number = undefined;

  newTransitTime: TransitTime;

  @Output() saveTransitTime = new EventEmitter<TransitTime>();
  @Output() delete = new EventEmitter<number>();

  @Input() set _transitTimes(tt: TransitTime[]) {
    this.transitTimes = tt;
    this.dataSource.data = [...this.transitTimes];
    this.filterWarehouses();
  }

  ngOnInit() {
    this.warehousesService.getWarehouses().subscribe((resp) => {
      this.allWarehouses = resp;
      this.filterWarehouses();
    });

    fromEvent(this.filterInput.nativeElement, 'keyup')
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(600),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        this.filterTransitTimes(text);
      });
  }

  filterTransitTimes(text: string) {
    const lcText = text.toLowerCase();
    this.dataSource.data = this.transitTimes.filter(
      (it) => it.destination.code.toLowerCase().indexOf(lcText) !== -1 || it.destination.name.toLowerCase().indexOf(lcText) !== -1
    );
  }

  private filterWarehouses() {
    // Available warehouses are the ones that don't have an existing transit time
    if (!isNilty(this.allWarehouses) && !isNil(this.transitTimes)) {
      this.availableWarehouses = this.allWarehouses.filter((w) => isNilty(this.transitTimes.find((tt) => tt.destination.id === w.id)));
    }
  }

  addTransitTime() {
    this.newTransitTime = new TransitTime();
  }

  saveNewTransitTime() {
    this.saveTransitTime.emit(this.newTransitTime);
    this.newTransitTime = undefined;
  }

  deleteTransitTime(t: TransitTime) {
    this.matDialog
      .open(GenericConfirmationModalComponent, { data: 'Deleting transit time for Destination ' + t.destination.code + '.' })
      .afterClosed()
      .subscribe((resp: boolean) => {
        if (resp) {
          this.delete.emit(t.id);
        }
      });
  }

  edit(t: TransitTime) {
    this.editingDestinationId = t.destination.id;
    this.isEditTransitTime = true;
  }

  saveEdit(t: TransitTime) {
    this.saveTransitTime.emit(t);
    this.editingDestinationId = undefined;
    this.isEditTransitTime = false;
  }
}
