import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { FulfilmentJobStatus } from '../models/job-status/fulfilment-job-status-model';
import { AmbraJob } from '../models/job-status/job-execution-status';
import { ConverterService } from '../core/converter.service';
import { map } from 'rxjs/operators';
import { hideLoaderHeaders, isNilty } from '../core/utils.service';
import { GenerateOrderedFromLsaJobStatus } from '../models/job-status/generate-ordered-from-lsa-status-model';
import { GenerateOrderedFromSpoJobStatus } from '../models/job-status/generate-ordered-from-spo-status-model';

@Injectable()
export class AmbraJobService {
  constructor(private http: HttpClient, private environmentService: EnvironmentService, private converter: ConverterService) {}

  private getJobStatus(lotId: number, jobType: string): Observable<any> {
    return this.http.get(this.environmentService.getRestEndpoint('getJobStatus') + `?type=${jobType}&lotId=${lotId}`, {
      headers: hideLoaderHeaders(),
    });
  }

  getFulfilmentJobStatus(lotId: number): Observable<FulfilmentJobStatus> {
    return this.getJobStatus(lotId, AmbraJob.GENERATE_FULFILMENT).pipe(
      map((s) => {
        if (isNilty(s)) {
          return null;
        }
        return this.converter.fromJSONtoObj(s, FulfilmentJobStatus);
      })
    );
  }

  getGenerateOrderedForLsaStatus(lotId: number): Observable<GenerateOrderedFromLsaJobStatus> {
    return this.getJobStatus(lotId, AmbraJob.GENERATE_ORDERED_FROM_LSA).pipe(
      map((s) => {
        if (isNilty(s)) {
          return null;
        }
        return this.converter.fromJSONtoObj(s, GenerateOrderedFromLsaJobStatus);
      })
    );
  }

  getGenerateOrderedForSpoStatus(lotId: number): Observable<GenerateOrderedFromSpoJobStatus> {
    return this.getJobStatus(lotId, AmbraJob.GENERATE_ORDERED_FROM_SPO).pipe(
      map((s) => {
        if (isNilty(s)) {
          return null;
        }
        return this.converter.fromJSONtoObj(s, GenerateOrderedFromSpoJobStatus);
      })
    );
  }
}
