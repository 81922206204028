import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { LogDiscardedValueDetailComponent } from './log-discarded-value-detail/log-discarded-value-detail.component';
import { LogDiscardedValueFiltersComponent } from './log-discarded-value-filters/log-discarded-value-filters.component';
import { LogDiscardedValueComponent } from './log-discarded-value/log-discarded-value.component';

@NgModule({
  declarations: [LogDiscardedValueComponent, LogDiscardedValueFiltersComponent, LogDiscardedValueDetailComponent],
  imports: [SharedModule],
})
export class LogDiscardedValueModule {}
