import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from './date-converter';

@JsonObject('DateRange')
export class DateRange {
  @JsonProperty('begin', DateConverter, true) begin: Date = undefined;
  @JsonProperty('end', DateConverter, true) end: Date = undefined;

  constructor(begin: Date, end: Date) {
    this.begin = begin;
    this.end = end;
  }
}
