import { JsonObject, JsonProperty } from 'json2typescript';
import { Vendor } from './vendor-model';

@JsonObject('Remittance')
export class Remittance {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('paymentNumber', String, true) paymentNumber: string = undefined;
  @JsonProperty('vendor', Vendor, true) vendor: Vendor = undefined;
  @JsonProperty('paymentTo', String, true) paymentTo: string = undefined;
  @JsonProperty('amazonSupplierNumber', String, true) amazonSupplierNumber: string = undefined;
  @JsonProperty('supplierSiteName', String, true) supplierSiteName: string = undefined;
  @JsonProperty('paymentDate', String, true) paymentDate: string = undefined;
  @JsonProperty('paymentCurrency', String, true) paymentCurrency: string = undefined;
  @JsonProperty('paymentAmount', Number, true) paymentAmount: number = undefined;
}
