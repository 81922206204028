import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';
import { Lot } from './lot-model';
import { Supplier } from './supplier-model';
import { Vendor } from './vendor-model';
import { Warehouse } from './warehouse-model';

@JsonObject('Spo')
export class Spo extends BaseObject {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('code', String, true) code: string = undefined;
  @JsonProperty('orderId', String, true) orderId: string = undefined;
  @JsonProperty('earliestDeliveryDate', String, true) earliestDeliveryDate: string = undefined;
  @JsonProperty('latestDeliveryDate', String, true) latestDeliveryDate: string = undefined;
  @JsonProperty('latestPickupDate', String, true) latestPickupDate: string = undefined;
  @JsonProperty('supplier', Supplier, true) supplier: Supplier = undefined;
  @JsonProperty('destination', Warehouse, true) destination: Warehouse = undefined;
  @JsonProperty('vendor', Vendor, true) vendor: Vendor = undefined;
  @JsonProperty('lot', Lot, true) lot: Lot = undefined;
  @JsonProperty('requestedQuantity', Number, true) requestedQuantity: number = undefined;
  @JsonProperty('requestedValue', Number, true) requestedValue: number = undefined;
  @JsonProperty('requestedPurchasePrice', Number, true) requestedPurchasePrice: number = undefined;
  @JsonProperty('virtualRequestedPurchasePrice', Number, true) virtualRequestedPurchasePrice: number = undefined;
  @JsonProperty('confirmedQuantity', Number, true) confirmedQuantity: number = undefined;
  @JsonProperty('confirmedValue', Number, true) confirmedValue: number = undefined;
  @JsonProperty('confirmedPurchasePrice', Number, true) confirmedPurchasePrice: number = undefined;
  @JsonProperty('virtualConfirmedPurchasePrice', Number, true) virtualConfirmedPurchasePrice: number = undefined;
  @JsonProperty('orderedQuantity', Number, true) orderedQuantity: number = undefined;
  @JsonProperty('orderedValue', Number, true) orderedValue: number = undefined;
  @JsonProperty('orderedPurchasePrice', Number, true) orderedPurchasePrice: number = undefined;
  @JsonProperty('virtualOrderedPurchasePrice', Number, true) virtualOrderedPurchasePrice: number = undefined;
  @JsonProperty('margin', Number, true) margin: number = undefined;
  @JsonProperty('percentMargin', Number, true) percentMargin: number = undefined;
  @JsonProperty('virtualMargin', Number, true) virtualMargin: number = undefined;
  @JsonProperty('virtualPercentMargin', Number, true) virtualPercentMargin: number = undefined;
  @JsonProperty('payment', Boolean, true) payment: boolean = undefined;
  @JsonProperty('status', Number, true) spoStatus: number = undefined;
  @JsonProperty('isExtra', Boolean, true) isExtra: boolean = undefined;
  @JsonProperty('crossUkBorder', Boolean, true) crossUkBorder: boolean = undefined;
  @JsonProperty('virtualConfirmedMargin', Number, true) virtualConfirmedMargin: number = undefined;
  @JsonProperty('virtualConfirmedPercentMargin', Number, true) virtualConfirmedPercentMargin: number = undefined;
}

@JsonObject('SpoStatus')
export class SpoStatus {
  @JsonProperty('code', Number, true) code: number = undefined;
  @JsonProperty('description', String, true) description: string = undefined;
  @JsonProperty('setFromFrontend', Boolean, true) setFromFrontend = false;
}
