import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { LotsService } from '../../../../shared/lots.service';
import { LotEditService } from '../../lot-edit.service';
import { LogDiscardedValue } from '../../../../models/log-discarded-value-model';
import { LogDiscardedValueFilters } from '../../../../models/filters/log-discarded-value-filters-model';
import { LogDiscardedValueService } from '../../../../shared/log-discarded-value.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-log-discarded-value',
  templateUrl: './log-discarded-value.component.html',
  styleUrls: ['./log-discarded-value.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class LogDiscardedValueComponent implements OnInit {
  dataSource: MatTableDataSource<LogDiscardedValue>;
  displayedColumns = ['supplier', 'stockLocation', 'vendor', 'destination', 'type', 'discardedValue', 'description', 'showDetails'];
  resultsNumber: Observable<number>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  selectedRow: LogDiscardedValue;

  constructor(
    private logDiscardedValueService: LogDiscardedValueService,
    private lotsService: LotsService,
    private lotEditService: LotEditService
  ) {}

  ngOnInit() {
    this.resultsNumber = this.logDiscardedValueService.resultsNumber;

    if (this.lotEditService.logDiscardedValueFilters === undefined) {
      this.lotEditService.logDiscardedValueFilters = new LogDiscardedValueFilters();
    }

    this.dataSource = new MatTableDataSource<LogDiscardedValue>([]);
    this.loadLogDiscardedValue(this.lotEditService.logDiscardedValueFilters);
  }

  loadLogDiscardedValue(filters: LogDiscardedValueFilters) {
    filters.lotId = this.lotsService.currentLotId;
    this.logDiscardedValueService.getFilteredLogDiscardedValue(filters).subscribe((data: LogDiscardedValue[]) => {
      this.dataSource.data = data;
    });
  }

  loadPage() {
    this.lotEditService.logDiscardedValueFilters.pageNumber = this.paginator.pageIndex;
    this.lotEditService.logDiscardedValueFilters.pageSize = this.paginator.pageSize;
    this.loadLogDiscardedValue(this.lotEditService.logDiscardedValueFilters);
  }

  onSort() {
    this.lotEditService.logDiscardedValueFilters.sortBy = this.sort.active;
    this.lotEditService.logDiscardedValueFilters.sortDirection = this.sort.direction;
    this.loadLogDiscardedValue(this.lotEditService.logDiscardedValueFilters);
  }

  onFilter(filters: LogDiscardedValueFilters) {
    this.paginator.pageIndex = 0;
    this.loadLogDiscardedValue(filters);
  }

  toggle(row: LogDiscardedValue) {
    this.selectedRow = this.selectedRow === row ? null : row;
  }
}
