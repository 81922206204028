<div class="row padding-left-40 padding-right-40">
  <div class="col-md-12 fixed-height-40 border-bottom-title">
    <mat-paginator class="border-bottom-title" id="little-paginator" #paginator [pageSize]="5" [pageSizeOptions]="[5]"> </mat-paginator>
  </div>

  <div class="col-md-12 fixed-table-5-rows padding-right-0 padding-left-0">
    <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-40">
      <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef mat-sort-header="title" class="title-column"> Title </mat-header-cell>
          <mat-cell *matCellDef="let ci" class="title-column">
            <p>{{ ci.shipmentItem.purchaseOrderItem.product.title }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ean" sticky>
          <mat-header-cell *matHeaderCellDef class="ean-column" mat-sort-header="ean"> EAN </mat-header-cell>
          <mat-cell *matCellDef="let ci" class="ean-column">
            <a [routerLink]="['/inventory/products', ci.shipmentItem.purchaseOrderItem.product.id, 'details']">{{
              ci.shipmentItem.purchaseOrderItem.product.ean
            }}</a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actualEan">
          <mat-header-cell *matHeaderCellDef class="ean-column" mat-sort-header="actualEan"> Actual EAN </mat-header-cell>
          <mat-cell *matCellDef="let ci" class="ean-column">{{ ci.shipmentItem.actualEan }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="poId">
          <mat-header-cell *matHeaderCellDef mat-sort-header="po" class="po-code-column"> PO </mat-header-cell>
          <mat-cell *matCellDef="let ci" class="po-code-column">{{ ci.shipmentItem.purchaseOrderItem.purchaseOrder.poId }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="vendor">
          <mat-header-cell class="vendor-column" *matHeaderCellDef mat-sort-header="vendor"> Vendor </mat-header-cell>
          <mat-cell
            class="vendor-column"
            *matCellDef="let ci"
            [matTooltip]="ci.shipmentItem.purchaseOrderItem.purchaseOrder.vendor.name"
            matTooltipPosition="below"
            >{{ ci.shipmentItem.purchaseOrderItem.purchaseOrder.vendor.code }}</mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="brand">
          <mat-header-cell class="category-column" *matHeaderCellDef mat-sort-header="brand"> Brand </mat-header-cell>
          <mat-cell class="category-column" *matCellDef="let ci">
            <p>{{ ci.shipmentItem.purchaseOrderItem.product.brand ? ci.shipmentItem.purchaseOrderItem.product.brand.name : '' }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="submittedQuantity">
          <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="submittedQuantity">
            Submitted <br />
            quantity
          </mat-header-cell>
          <mat-cell class="quantity-column" *matCellDef="let ci">
            <p>{{ ci.shipmentItem.purchaseOrderItem.submittedQuantity }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="shippedQuantity">
          <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="shippedQuantity">
            Shipped <br />
            quantity
          </mat-header-cell>
          <mat-cell class="quantity-column" *matCellDef="let ci">
            <p>{{ ci.shipmentItem.purchaseOrderItem.shippedQuantity }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="boxQuantity">
          <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="boxQuantity">
            Box <br />
            quantity
          </mat-header-cell>
          <mat-cell class="quantity-column" *matCellDef="let ci">
            <p>{{ ci.boxQuantity }}</p>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>
</div>
