<div class="main-content">
  <form [formGroup]="vendorForm" (ngSubmit)="onSubmit()">
    <div class="container">
      <div class="row">
        <div class="col-xs-2"></div>
        <div class="col-xs-4">
          <div class="row form-element">
            <div class="form-group">
              <mat-form-field>
                <input matInput type="text" id="code" formControlName="code" placeholder="Code" />
              </mat-form-field>
            </div>
          </div>

          <div class="row form-element">
            <div class="form-group">
              <mat-form-field>
                <input matInput type="text" id="name" formControlName="name" placeholder="Name" />
              </mat-form-field>
            </div>
          </div>

          <div class="row form-element">
            <div class="form-group">
              <mat-form-field>
                <input
                  matInput
                  type="text"
                  id="brandonWarehouseCode"
                  formControlName="brandonWarehouseCode"
                  placeholder="Brandon Warehouse Code"
                />
              </mat-form-field>
            </div>
          </div>

          <div class="row form-element">
            <div class="form-group">
              <mat-form-field>
                <input matInput type="text" id="country" formControlName="country" placeholder="Country" />
              </mat-form-field>
            </div>
          </div>

          <div class="row form-element">
            <div class="form-group">
              <mat-form-field style="font-size: 13px">
                <mat-select
                  id="defaultOrderAcceptanceMode"
                  formControlName="defaultOrderAcceptanceMode"
                  placeholder="Default Order Acceptance Mode"
                >
                  <mat-option [value]="'backorder'">Backorder</mat-option>
                  <mat-option [value]="'firstorder'">Firstorder</mat-option>
                  <mat-option [value]="'default'">Default</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row form-element padding-right-9-perc">
            <div class="form-group">
              <mat-checkbox class="text-blue font-Titillium" id="sendSoftFirstorder" formControlName="sendSoftFirstorder"
                >Send Soft Firstorder</mat-checkbox
              >
            </div>
          </div>
        </div>

        <div class="col-xs-4">
          <div class="row form-element">
            <div class="form-group">
              <mat-form-field>
                <input matInput type="text" id="brandonGln" formControlName="brandonGln" placeholder="Vendor GLN" />
              </mat-form-field>
            </div>
          </div>

          <div class="row form-element">
            <div class="form-group">
              <mat-form-field>
                <mat-select id="paymentTerms" formControlName="paymentTerms" placeholder="Payment Terms">
                  <mat-option [value]="pt" *ngFor="let pt of paymentTerms">{{ pt.description }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row form-element">
            <div class="form-group">
              <mat-form-field>
                <app-mat-select
                  filterKey="name"
                  formControlName="vendorCentralAccount"
                  id="vendorCentralAccount"
                  placeholder="VendorCentralAccount"
                >
                  <mat-option [value]="null">None</mat-option>
                  <mat-option *appMatSelectFor="let vendorCentralAccount of vendorCentralAccounts" [value]="vendorCentralAccount"
                    >{{ vendorCentralAccount.name }}
                  </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row form-element">
            <div class="form-group">
              <mat-slide-toggle formControlName="parent" class="text-blue">Is Parent Code</mat-slide-toggle>
            </div>
          </div>

          <div class="row form-element parent-vendor">
            <div class="form-group">
              <mat-form-field *ngIf="!vendorForm.value.parent" style="font-size: 13px" [@fadeInOut]>
                <mat-select id="parentVendor" formControlName="parentVendor" placeholder="Parent Code">
                  <mat-option [value]="vendor.id" *ngFor="let vendor of parentVendors">{{ vendor.code }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-2"></div>
        <div class="col-xs-4">
          <div class="row form-element">
            <mat-form-field>
              <mat-select id="publicationCheckType" formControlName="publicationCheckType" placeholder="Publication Check Type">
                <mat-option [value]="type" *ngFor="let type of checkTypes">{{ type.description }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="col-xs-4 col-xs-offset-4">
        <div class="row form-buttons">
          <button type="submit" [disabled]="!vendorForm.valid" class="btn-product">Save</button>
          <button *ngIf="editMode" type="button" (click)="onDelete()" class="btn-product">Delete!</button>
          <button type="button" (click)="onCancel()" class="btn-product">Back</button>
        </div>
      </div>
    </div>
  </form>
</div>
