import { JsonObject } from 'json2typescript';
import { JsonProperty } from 'json2typescript';

export enum JobStatus {
  STARTING = 'STARTING',
  RUNNING = 'RUNNING',
  TROUBLE = 'TROUBLE',
}

export enum AmbraJob {
  GENERATE_FULFILMENT = 'GENERATE_FULFILMENT',
  GENERATE_ORDERED_FROM_LSA = 'GENERATE_ORDERED_FROM_LSA',
  GENERATE_ORDERED_FROM_SPO = 'GENERATE_ORDERED_FROM_SPO',
}

@JsonObject('JobExecutionStatus')
export class JobExecutionStatus {
  @JsonProperty('job', String, true) job: string = undefined;
  @JsonProperty('lotId', Number, true) lotId: number = undefined;
  @JsonProperty('status', String, true) status: string = undefined;
  @JsonProperty('startedAt', String, true) startedAt: string = undefined;
  @JsonProperty('lastStepStartedAt', String, true) lastStepStartedAt: string = undefined;
}
