import { PaginationAndSorting } from './pagination-and-sorting-model';
import { MatChipsData } from '../mat-chips-data-model';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('CatalogItemsFilters')
export class CatalogItemsFilters extends PaginationAndSorting {
  @JsonProperty('ids', [Number], true) ids: number[] = [];
  @JsonProperty('supplierId', Number, true) supplierId: number = undefined;
  @JsonProperty('brandId', Number, true) brandId: number = undefined;
  @JsonProperty('seasonId', Number, true) seasonId: number = undefined;
  @JsonProperty('ean', String, true) ean: string = undefined;
  @JsonProperty('status', String, true) status: string = undefined;
}

export const catalogItemsFiltersMap = (brand?: string, season?: string, ean?: string, status?: string): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (brand != null) {
    map.push(new MatChipsData('Brand', brand));
  }
  if (season != null) {
    map.push(new MatChipsData('Season', season));
  }
  if (ean != null) {
    map.push(new MatChipsData('EAN', ean));
  }
  if (status != null) {
    map.push(new MatChipsData('Status', status));
  }
  return map;
};
