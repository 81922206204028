import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ConverterService } from '../core/converter.service';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Po } from '../models/po-model';
import { PoFilters } from '../models/filters/po-filters-model';
import { LotEditKpi } from '../models/lot-edit-kpi';
import { PaginationAndSorting } from '../models/filters/pagination-and-sorting-model';
import { map } from 'rxjs/operators';
import { PoItemWithLot } from '../models/po-item-with-lot-model';
import { PoItem } from '../models/po-item-model';
import { EnvironmentService } from '../environment.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup } from '@angular/forms';
import { isNilty } from '../core/utils.service';

@Injectable()
export class PosService {
  constructor(
    private http: HttpClient,
    private converter: ConverterService,
    private snackBar: MatSnackBar,
    private environmentService: EnvironmentService
  ) {}

  pos: Po[] = [];
  poItems: PoItemWithLot[] = [];

  poFilters: PoFilters = null;

  selectedPos: number[] = [];
  selectedPoItems: number[] = [];

  totalPos: number;
  totalPoItems: number;

  mainChecker = false;

  kpiData = new Subject<LotEditKpi>();

  resultsNumber = new Subject<number>();

  paginationAndSorting: PaginationAndSorting = null;

  getPo(poId: number): Observable<Po> {
    let path = this.environmentService.getRestEndpoint('pos') + '/' + poId;
    if (this.environmentService.dev) {
      path = this.environmentService.getRestEndpoint('singlePo');
    }
    return this.http.get(path).pipe(map((poResp: Po) => this.converter.fromJSONtoObj(poResp, Po)));
  }

  getFilteredPos(filters: PoFilters): Observable<Po[]> {
    const path = this.environmentService.getRestEndpoint('pos');
    const body = this.converter.fromObjtoJSON(filters);

    return this.http.post(path, body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.totalPos = +resp.headers.get('Total-Length');
        this.resultsNumber.next(this.totalPos);
        this.getKpiFromHeaders(resp.headers);
        return resp.body;
      }),
      map((poResp: Po[]) => {
        this.pos = [];
        for (let i = 0; i < poResp.length; i++) {
          this.pos[i] = this.converter.fromJSONtoObj(poResp[i], Po);
        }
        return this.pos;
      })
    );
  }

  changeStatus(status: string, pos: number[], filters: PoFilters, mainChecker: boolean) {
    if (mainChecker) {
      return this.changePosStatusFromFilters(status, filters);
    } else {
      return this.changePosStatus(status, pos);
    }
  }

  changePosStatus(status: string, pos: number[]): Observable<void> {
    let path = this.environmentService.getRestEndpoint('pos');

    if (!this.environmentService.dev) {
      path += '/change-status=' + status;
    }

    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(pos);

    return this.http.post(path, body, { headers }).pipe(
      map(() => {
        this.snackBar.open('State changed to ' + status, 'CLOSE')._dismissAfter(2000);
        return;
      })
    );
  }

  changePosStatusFromFilters(status: string, filters: PoFilters): Observable<void> {
    const path = this.environmentService.getRestEndpoint('pos') + '/change-status-from-filters=' + status;

    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = this.converter.fromObjtoJSON(filters);

    return this.http.post(path, body, { headers }).pipe(
      map(() => {
        this.snackBar.open('State changed to ' + status, 'CLOSE')._dismissAfter(2000);
        return;
      })
    );
  }

  getKpiFromHeaders(header: HttpHeaders) {
    const kpi = new LotEditKpi();
    kpi.submittedQuantity = +header.get('submittedQuantity');
    kpi.submittedValue = +header.get('submittedValue');
    kpi.acceptedQuantity = +header.get('acceptedQuantity');
    kpi.acceptedValue = +header.get('acceptedValue');
    kpi.cancelledQuantity = +header.get('cancelledQuantity');
    kpi.cancelledValue = +header.get('cancelledValue');
    kpi.receivedQuantity = +header.get('receivedQuantity');
    kpi.receivedValue = +header.get('receivedValue');
    this.kpiData.next(kpi);
  }

  closeAndSendPoa(pos: number[], filters: PoFilters, mainChecker: boolean, submitted: boolean): Observable<void> {
    if (mainChecker) {
      return this.closeAndSendPoaFromFilters(filters, submitted);
    } else {
      return this.closeAndSendPoaFromIds(pos, submitted);
    }
  }

  private closeAndSendPoaFromIds(pos: number[], submitted: boolean) {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(pos);

    const path = this.environmentService.getRestEndpoint('closeAndSendPoaFromIds') + '?submitted=' + submitted;

    return this.http.post(path, body, { headers }).pipe(map(() => {}));
  }

  private closeAndSendPoaFromFilters(filters: PoFilters, submitted: boolean) {
    const path = this.environmentService.getRestEndpoint('closeAndSendPoaFromFromFilters') + '?submitted=' + submitted;

    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = this.converter.fromObjtoJSON(filters);

    return this.http.post(path, body, { headers }).pipe(
      map(() => {
        this.snackBar.open('Status changed to CLOSED', 'CLOSE')._dismissAfter(2000);
        return;
      })
    );
  }

  getPosByPoEanDest(poEanDest: string): Observable<Po[]> {
    let path = this.environmentService.getRestEndpoint('searchPoEanDest');

    if (!this.environmentService.dev) {
      const pageNumber = this.paginationAndSorting.pageNumber ? this.paginationAndSorting.pageNumber : 0;
      const pageSize = this.paginationAndSorting.pageSize ? this.paginationAndSorting.pageSize : 10;
      const sortBy = this.paginationAndSorting.sortBy ? this.paginationAndSorting.sortBy : 'orderedOn';
      const sortDirection = this.paginationAndSorting.sortDirection ? this.paginationAndSorting.sortDirection : 'desc';

      path +=
        '?po-ean-dest=' +
        poEanDest +
        '&page-number=' +
        pageNumber +
        '&page-size=' +
        pageSize +
        '&sort-by=' +
        sortBy +
        '&sort-direction=' +
        sortDirection;
    }

    return this.http.get(path, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.totalPos = +resp.headers.get('Total-Length');
        this.resultsNumber.next(this.totalPos);
        this.getKpiFromHeaders(resp.headers);
        return resp.body;
      }),
      map((poResp: Po[]) => {
        this.pos = [];
        for (let i = 0; i < poResp.length; i++) {
          this.pos[i] = this.converter.fromJSONtoObj(poResp[i], Po);
        }
        return this.pos;
      })
    );
  }

  getFilteredPoItems(filters: PoFilters): Observable<PoItemWithLot[]> {
    const path = this.environmentService.getRestEndpoint('poItems');
    const body = this.converter.fromObjtoJSON(filters);

    return this.http.post(path, body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.totalPoItems = +resp.headers.get('Total-Length');
        this.resultsNumber.next(this.totalPoItems);
        this.getKpiFromHeaders(resp.headers);
        return resp.body;
      }),
      map((poItemsResp: PoItem[]) => {
        this.poItems = [];
        for (let i = 0; i < poItemsResp.length; i++) {
          this.poItems[i] = this.converter.fromJSONtoObj(poItemsResp[i], PoItemWithLot);
        }
        return this.poItems;
      })
    );
  }

  addPoItemIdToSelected(id: number) {
    this.selectedPoItems.push(id);
    this.selectedPoItems.filter((v, i, a) => a.indexOf(v) === i);
  }
  removePoItemIdFromSelected(id: number) {
    const index = this.selectedPoItems.indexOf(id);
    this.selectedPoItems.splice(index, 1);
  }

  savePoFilters(filterForm: FormGroup) {
    this.poFilters = new PoFilters();

    this.poFilters.vendors = filterForm.value.vendors?.map((it) => it.id);
    this.poFilters.parent = filterForm.value.parent?.id;
    this.poFilters.lotName = filterForm.value.lotName;
    this.poFilters.lotDate = filterForm.value.lotDate;
    this.poFilters.eans = filterForm.value.eans;
    this.poFilters.purchaseOrder = filterForm.value.purchaseOrder;
    this.poFilters.from = filterForm.value.from;
    this.poFilters.to = filterForm.value.to;
    this.poFilters.destinationId = isNilty(filterForm.value.destination) ? null : filterForm.value.destination.id;
    this.poFilters.categoryId = isNilty(filterForm.value.category) ? null : filterForm.value.category.id;
    this.poFilters.status = filterForm.value.status;
    this.poFilters.hasLot = filterForm.value.hasLot;
    this.poFilters.brand = filterForm.value.brand ? filterForm.value.brand.name : null;
    this.poFilters.acceptedDifferentFromPoa = filterForm.value.acceptedDifferentFromPoa ? true : null;
    this.poFilters.obsoleteWithAccepted = filterForm.value.obsoleteWithAccepted ? true : null;
    this.poFilters.isAdr = filterForm.value.isAdr ? true : null;

    this.poFilters.latestDeliveryDateFrom = filterForm.value.latestDeliveryDateFrom;
    this.poFilters.latestDeliveryDateTo = filterForm.value.latestDeliveryDateTo;

    this.poFilters.pageNumber = null;
    this.poFilters.pageSize = null;
    this.poFilters.sortBy = null;
    this.poFilters.sortDirection = null;
  }
}
