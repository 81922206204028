<div class="main-content">
  <form [formGroup]="vendorCentralAccountForm" (ngSubmit)="onSubmit()">
    <div class="col-xs-12 margin-bottom-60">
      <div class="col-xs-2"></div>
      <div class="col-xs-8">
        <div class="row">
          <div class="col-xs-6">
            <mat-form-field>
              <input matInput type="text" id="name" formControlName="name" placeholder="Name" />
            </mat-form-field>
          </div>

          <div class="col-xs-6">
            <div class="form-group">
              <mat-slide-toggle class="text-blue" id="setByDefault" formControlName="botEnabled">Bot Enabled</mat-slide-toggle>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <mat-form-field>
              <app-mat-select filterKey="description" formControlName="vendors" id="vendors" placeholder="Vendors" [multiple]="true">
                <mat-option *appMatSelectFor="let vendor of vendors" [value]="vendor">{{ vendor.description }} </mat-option>
              </app-mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="col-xs-4 col-xs-offset-4">
          <div class="row form-buttons">
            <button type="submit" [disabled]="!vendorCentralAccountForm.valid" class="btn-product">Save</button>
            <button *ngIf="editMode" type="button" (click)="onDelete()" class="btn-product margin-left-10">Delete!</button>
            <button type="button" (click)="onBack()" class="btn-product margin-left-10">Back</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
