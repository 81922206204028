import { JsonObject, JsonProperty } from 'json2typescript';
import { isNilty } from '../../core/utils.service';
import { MatChipsData } from '../mat-chips-data-model';
import { PaginationAndSorting } from './pagination-and-sorting-model';

@JsonObject('ChargebackFilters')
export class ChargebackFilters extends PaginationAndSorting {
  @JsonProperty('code', String, true) code: string = undefined;
  @JsonProperty('issueType', String, true) issueType: string = undefined;
  @JsonProperty('vendor', String, true) vendor: string = undefined;
  @JsonProperty('poNumber', String, true) poNumber: string = undefined;
  @JsonProperty('ean', String, true) ean: string = undefined;
  @JsonProperty('asin', String, true) asin: string = undefined;
  @JsonProperty('charged', Boolean, true) charged: boolean = undefined;
}

export const chargebackFiltersMap = (
  code: string,
  issueType: string,
  vendor: string,
  poNumber: string,
  ean: string,
  asin: string,
  charged: string
): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (!isNilty(code)) {
    map.push(new MatChipsData('Code', code));
  }
  if (!isNilty(issueType)) {
    map.push(new MatChipsData('IssueType', issueType));
  }
  if (!isNilty(vendor)) {
    map.push(new MatChipsData('Vendor', vendor));
  }
  if (!isNilty(poNumber)) {
    map.push(new MatChipsData('Po', poNumber));
  }
  if (!isNilty(ean)) {
    map.push(new MatChipsData('EAN', ean));
  }
  if (!isNilty(asin)) {
    map.push(new MatChipsData('ASIN', asin));
  }
  if (!isNilty(charged)) {
    map.push(new MatChipsData('Charged', charged));
  }

  return map;
};
