<div [ngClass]="{ 'black-panel': openFilter, panel: !openFilter }">
  <mat-expansion-panel class="mat-elevation-z8 filter-position" [attr.expanded]="openFilter" (opened)="openEvent()" (closed)="closeEvent()">
    <mat-expansion-panel-header class="filt-custom">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-9">
            <mat-chip-list>
              <mat-chip *ngFor="let f of filtersMap">{{ f.filter }}</mat-chip>
            </mat-chip-list>
          </div>
          <div class="col-md-2">
            <mat-panel-title class="filter-title"> Filters </mat-panel-title>
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>

    <div class="row filters-row">
      <form [formGroup]="filterForm" (submit)="onSubmitFilter()">
        <div class="col-md-12 text-blue margin-top-5 border-bottom-orange">
          <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select
                  formControlName="parent"
                  id="parent"
                  placeholder="Parent Code"
                  (selectionChange)="selectedParent($event)"
                  filterKey="description"
                >
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="vendor" *appMatSelectFor="let vendor of parents">{{ vendor.description }}</mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="purchaseOrder" placeholder="PO" />
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field class="small-mat-form-field">
                <input matInput formControlName="from" [matDatepicker]="dateFrom" placeholder="From date" />
                <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
                <mat-datepicker #dateFrom></mat-datepicker>
              </mat-form-field>

              <mat-form-field class="small-mat-form-field margin-left-10">
                <input matInput formControlName="to" [matDatepicker]="dateTo" placeholder="To date" />
                <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
                <mat-datepicker #dateTo></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="lotDate" [matDatepicker]="lotDate" placeholder="Lot Date" />
                <mat-datepicker-toggle matSuffix [for]="lotDate"></mat-datepicker-toggle>
                <mat-datepicker #lotDate></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <textarea matInput formControlName="eans" placeholder="EAN"></textarea>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select
                  formControlName="vendors"
                  filterKey="description"
                  id="vendors"
                  placeholder="Vendor"
                  (selectionChange)="selectedChildren($event)"
                  multiple
                  (change)="vendorChange($event)"
                >
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="vendor" *appMatSelectFor="let vendor of vendors">{{ vendor.description }}</mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select formControlName="destination" placeholder="Destination" filterKey="description">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option *appMatSelectFor="let warehouse of warehouses" [value]="warehouse" style="width: unset">
                    {{ warehouse.description }}
                  </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="lotName" placeholder="Lot Name" />
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <mat-select formControlName="hasLot" id="hasLot" placeholder="Has Lot">
                  <mat-option [value]="null">All</mat-option>
                  <mat-option [value]="true">True</mat-option>
                  <mat-option [value]="false">False</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select formControlName="category" placeholder="Category" filterKey="name" id="category">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option *appMatSelectFor="let cat of categories" [value]="cat">
                    {{ cat.name }}
                  </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-1"></div>

            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select
                  filterKey="name"
                  formControlName="brand"
                  placeholder="Brand"
                  [automaticSearch]="false"
                  (filterChange)="filterBrands($event)"
                >
                  <mat-option [value]="null">None</mat-option>
                  <mat-option *appMatSelectFor="let brand of brands | async; maxRows: 200" [value]="brand">
                    {{ brand.name }}
                  </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <mat-select formControlName="status" id="status" placeholder="Status">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="'New'">New</mat-option>
                  <mat-option [value]="'Open'">Open</mat-option>
                  <mat-option [value]="'Closed'">Closed</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Latest Delivery Date</mat-label>
                <mat-date-range-input [rangePicker]="latestDeliveryDatePicker">
                  <input matStartDate formControlName="latestDeliveryDateFrom" placeholder="Start date" />
                  <input matEndDate formControlName="latestDeliveryDateTo" placeholder="End date" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="latestDeliveryDatePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #latestDeliveryDatePicker></mat-date-range-picker>
              </mat-form-field>
            </div>

            <div class="col-md-2 padding-top-15">
              <mat-checkbox formControlName="acceptedDifferentFromPoa">Only Accepted != POA</mat-checkbox>
            </div>

            <div class="col-md-2 padding-top-15">
              <mat-checkbox formControlName="obsoleteWithAccepted">Obsolete with accepted</mat-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-2 padding-top-15">
              <mat-checkbox formControlName="isAdr">Is ADR</mat-checkbox>
            </div>
          </div>
        </div>

        <div class="col-md-12 border-top-white">
          <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-2 center">
              <button class="btn-custom padding-left-right-70" type="submit">Filter</button>
            </div>
            <div class="col-md-2 center">
              <button class="btn-custom padding-left-right-70" type="button" (click)="resetFiltersAndUpdatePos()">Reset</button>
            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
      </form>

      <mat-divider></mat-divider>
    </div>
  </mat-expansion-panel>
</div>
