<div>
  <mat-paginator #paginator [length]="resultsNumber" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()">
  </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="poItem">
        <mat-header-cell class="code-column" *matHeaderCellDef mat-sort-header="poItem">Po Item</mat-header-cell>
        <mat-cell class="code-column" *matCellDef="let ldvd">{{ ldvd.poItem?.purchaseOrder?.poId }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="ean" sticky>
        <mat-header-cell class="ean-column" *matHeaderCellDef mat-sort-header="ean">Ean</mat-header-cell>
        <mat-cell class="ean-column" *matCellDef="let ldvd">{{ ldvd.ean }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="unitCost" sticky>
        <mat-header-cell class="value-column" *matHeaderCellDef mat-sort-header="unitCost"> Unit cost </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let ldvd">{{ ldvd.unitCost | myCurrency }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="physicalQuantity" sticky>
        <mat-header-cell class="quantity-column" *matHeaderCellDef mat-sort-header="physicalQuantity"> Physical Quantity </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let ldvd">{{ ldvd.physicalQuantity }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="purchasePrice" sticky>
        <mat-header-cell class="value-column" *matHeaderCellDef mat-sort-header="purchasePrice"> Purchase Price </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let ldvd">{{ ldvd.purchasePrice | myCurrency }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="discardedQuantity">
        <mat-header-cell class="quantity-column" *matHeaderCellDef mat-sort-header="discardedQuantity"> Quantity </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let ldvd">{{ ldvd.discardedQuantity }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="discardedValue">
        <mat-header-cell class="value-column" *matHeaderCellDef mat-sort-header="discardedValue"> Value </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let ldvd">{{ ldvd.discardedValue | myCurrency }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
