import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-ignore-fulfilment-parameters-modal',
  template: `
    <h1 mat-dialog-title>Generate Fulfilment for PREBUY Lot</h1>
    <div class="dialog-container">
      <div mat-dialog-content>
        <div class="row padding-left-15 padding-right-15">
          Do you want to generate the Fulfilment ignoring the configured parameters?
          <br />
          By choosing <i>Ignore</i>, Ambra will skip configured margin thresholds, unit cost thresholds and transit cutoffs.
        </div>
      </div>
    </div>
    <div mat-dialog-actions>
      <div class="row width-100per">
        <div class="col-md-12 text-center width-100per">
          <button class="btn-product margin-right-15" [mat-dialog-close]="false">Use</button>
          <button class="btn-product margin-right-15" [mat-dialog-close]="true">Ignore</button>
          <button class="btn-product" [mat-dialog-close]="null">Cancel</button>
        </div>
      </div>
    </div>
  `,
  styles: [''],
})
export class IgnoreFulfilmentParametersModalComponent {
  constructor(
    public dialogRef: MatDialogRef<IgnoreFulfilmentParametersModalComponent>,
    // eslint-disable-next-line
    @Inject(MAT_DIALOG_DATA) public data: string | {}
  ) {}
}
