import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';
import { DeliveryWindowConstraint } from './delivery-window-constraint-model';
import { GenerateOrderedConfigurationDto, LotConfigurationDTO } from './lot-configuration-dto-model';
import { Supplier } from './supplier-model';
import { Vendor } from './vendor-model';

@JsonObject('LotConfiguration')
export class LotConfiguration extends BaseObject {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('lotName', String, true) lotName: string = undefined;
  @JsonProperty('cronExpression', String, true) cronExpression: string = undefined;
  @JsonProperty('vendors', [Vendor], true) vendors: Vendor[] = undefined;
  @JsonProperty('deliveryWindowConstraints', [DeliveryWindowConstraint], true) deliveryWindowConstraints: DeliveryWindowConstraint[] = [];
  @JsonProperty('thresholdValue', Number, true) thresholdValue: number = undefined;
  @JsonProperty('notIntegratedSuppliers', [Supplier], true) notIntegratedSuppliers: Supplier[] = undefined;
  @JsonProperty('disabled', Boolean, true) disabled: boolean = undefined;
  @JsonProperty('generateOrderedIterations', Number, true) generateOrderedIterations: number = undefined;
  @JsonProperty('generateOrderedDecreaseMarginPerIteration', Number, true) generateOrderedDecreaseMarginPerIteration: number = undefined;

  get vendorsList(): string {
    return this.vendors.map((vendor) => vendor.code).join(', ');
  }

  toDTO(generateOrderedConfigurations: GenerateOrderedConfigurationDto[]): LotConfigurationDTO {
    const dto = new LotConfigurationDTO();
    dto.id = this.id;
    dto.name = this.name;
    dto.lotName = this.lotName;
    dto.cronExpression = this.cronExpression;
    dto.vendors = this.vendors.map((vendor) => vendor.id);
    dto.deliveryWindowConstraints = this.deliveryWindowConstraints.map((constraint) => constraint.toDTO());
    dto.thresholdValue = this.thresholdValue;
    dto.notIntegratedSuppliers = this.notIntegratedSuppliers.map((supplier) => supplier.id);
    dto.disabled = this.disabled;
    dto.generateOrderedIterations = this.generateOrderedIterations;
    dto.generateOrderedDecreaseMarginPerIteration = this.generateOrderedDecreaseMarginPerIteration;
    dto.generateOrderedConfigurations = generateOrderedConfigurations;
    return dto;
  }
}
