<hr />
<div class="productDetails container" id="title-stock-items">
  <div class="row margin-top-10">
    <div class="col-xs-12">
      <div class="row padding-bottom-10">
        <div class="col-xs-9">
          <h3 class="big-title">{{ stockLocation?.name }}</h3>
        </div>
        <div class="col-xs-1">
          <button class="btn-custom" (click)="goBack()">Back</button>
        </div>
        <div class="col-xs-2"></div>
      </div>

      <div class="row padding-top-20">
        <div class="col-xs-3 background-blue text-white padding-bottom-50 details-box">
          <p><b>Address: </b> {{ stockLocation?.address }}</p>
        </div>

        <div class="col-xs-3 background-light-blue text-white padding-bottom-50 details-box">
          <p><b>Code: </b> {{ stockLocation?.code }}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<hr />

<div class="container table-outer">
  <div class="row">
    <div class="col-md-1"></div>

    <div class="col-md-10">
      <mat-paginator
        #paginator
        [length]="resultsNumber | async"
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 20, 50, 100]"
        (page)="loadPage()"
      >
      </mat-paginator>

      <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
        <mat-table #listingTable [dataSource]="dataSource" matSort (matSortChange)="onSort()">
          <ng-container matColumnDef="ean">
            <mat-header-cell class="ean-column" *matHeaderCellDef mat-sort-header="ean"> EAN </mat-header-cell>
            <mat-cell class="ean-column" *matCellDef="let stockItem"> {{ stockItem.product?.ean }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="title">
            <mat-header-cell class="title-column" *matHeaderCellDef mat-sort-header="title"> Title </mat-header-cell>
            <mat-cell class="title-column" *matCellDef="let stockItem" [matTooltip]="stockItem.product?.title" matTooltipPosition="below"
              ><p>{{ stockItem.product?.title }}</p></mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="physicalQuantity">
            <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="physicalQuantity">
              Physical quantity
            </mat-header-cell>
            <mat-cell class="quantity-column" *matCellDef="let stockItem"
              ><p>{{ stockItem.physicalQuantity }}</p></mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="availableQuantity">
            <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="availableQuantity">
              Available quantity
            </mat-header-cell>
            <mat-cell class="quantity-column" *matCellDef="let stockItem"
              ><p>{{ stockItem.availableQuantity }}</p></mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="updatedAt">
            <mat-header-cell class="date-time-column" *matHeaderCellDef mat-sort-header="updatedAt">Updated at</mat-header-cell>
            <mat-cell class="date-time-column" *matCellDef="let stockItem">
              <p>{{ stockItem.updatedAt }}</p>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
    </div>
  </div>
</div>
