<div [ngClass]="{ 'black-panel': openFilter, panel: !openFilter }">
  <mat-expansion-panel class="mat-elevation-z8 filter-position" [attr.expanded]="openFilter" (opened)="openEvent()" (closed)="closeEvent()">
    <mat-expansion-panel-header class="filt-custom">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-9">
            <mat-chip-list>
              <mat-chip *ngFor="let f of filtersMap">{{ f.filter }}</mat-chip>
            </mat-chip-list>
          </div>
          <div class="col-md-2">
            <mat-panel-title class="filter-title">
              Filters
            </mat-panel-title>
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>

    <div class="row filters-row">
      <form [formGroup]="filterForm" (submit)="onSubmitFilter()">
        <div class="col-md-12 text-blue margin-top-5 border-bottom-orange">
          <div class="row">
            <div class="col-md-1"></div>

            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="paymentNumber" placeholder="Payment Number" />
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select filterKey="description" formControlName="vendor" id="vendor" placeholder="Vendor">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="vendor.id" *appMatSelectFor="let vendor of vendors">{{ vendor.description }}</mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select filterKey="" formControlName="code" placeholder="Code">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option *appMatSelectFor="let code of remittanceDetailsCodes" [value]="code">
                    {{ code }}
                  </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="invoiceNumber" placeholder="Invoice Number" />
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field class="small-mat-form-field">
                <input matInput formControlName="dateFrom" [matDatepicker]="dateFrom" placeholder="From date" />
                <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
                <mat-datepicker #dateFrom></mat-datepicker>
              </mat-form-field>

              <mat-form-field class="small-mat-form-field margin-left-10">
                <input matInput formControlName="dateTo" [matDatepicker]="dateTo" placeholder="To date" />
                <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
                <mat-datepicker #dateTo></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-md-12 border-top-white">
          <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-2 center">
              <button type="submit" class="box-shadow-none btn-custom" style="float: right;">Filter</button>
            </div>
            <div class="col-md-2 center">
              <button type="button" (click)="resetFilters()" class="box-shadow-none btn-custom">Reset</button>
            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
      </form>

      <mat-divider></mat-divider>
    </div>
  </mat-expansion-panel>
</div>
