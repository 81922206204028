import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ConverterService } from '../../../../core/converter.service';
import { EnvironmentService } from '../../../../environment.service';
import { ChargebackFilters } from '../../../../models/filters/chargeback-filters-model';
import { Subject, Observable } from 'rxjs';
import { Chargeback } from '../../../../models/chargeback-model';
import { map } from 'rxjs/operators';

@Injectable()
export class PenaltiesService {
  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  resultsNumber = new Subject<number>();

  chargebackFilters: ChargebackFilters;

  getFilteredChargebacks(filters: ChargebackFilters): Observable<Chargeback[]> {
    return this.http
      .post(this.environmentService.getRestEndpoint('chargebacks'), this.createFilterBody(filters), { observe: 'response' })
      .pipe(
        map((resp: HttpResponse<any>) => {
          this.resultsNumber.next(+resp.headers.get('Total-Length'));
          return resp.body;
        }),
        map((resp: Chargeback[]) => resp.map((r) => this.converter.fromJSONtoObj(r, Chargeback)))
      );
  }

  private createFilterBody(filters: ChargebackFilters): string {
    return this.converter.fromObjtoJSON(filters);
  }
}
