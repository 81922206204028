<app-main-shipments-list-filters (onFilter)="applyFilter($event)"></app-main-shipments-list-filters>

<div class="bulk-actions-div">
  <mat-toolbar class="bar-custom">
    <div class="container">
      <div class="row">
        <div class="col-md-2">
          <button class="box-shadow-none btn-custom" routerLink="/shipments">Show Shipments</button>
        </div>

        <div class="col-md-10">
          <button class="box-shadow-none btn-custom" [disabled]="!bulkActionsActive" [matMenuTriggerFor]="exportShipmentItemsChoice">
            Export Shipment-Items
          </button>
          <mat-menu #exportShipmentItemsChoice="matMenu">
            <button mat-menu-item (click)="export('excel')">Save as Excel</button>
            <button mat-menu-item (click)="export('csv')">Save as CSV</button>
          </mat-menu>
        </div>
      </div>
    </div>
  </mat-toolbar>
</div>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <hr class="divisor-title" style="width: calc(100% - 26rem);" />
      <div class="square" style="right: 26rem;"></div>
      <h2 class="po-element-custom title-custom">Shipments Details</h2>
    </div>
  </div>
</div>

<div class="container table-outer">
  <mat-paginator
    #paginator
    [length]="resultsNumber | async"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 20, 50, 100]"
    class="container"
    (page)="loadPage()"
  >
  </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="check" sticky>
        <mat-header-cell *matHeaderCellDef class="checker-col">
          <mat-checkbox (change)="toggleAll($event.checked)" [ngModel]="mainChecker"></mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let shipmentItem; let i = index" class="checker-col">
          <mat-checkbox (change)="onSelected($event.checked, shipmentItem)" [ngModel]="shipmentItem.checked"> </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="week">
        <mat-header-cell class="week-column" *matHeaderCellDef mat-sort-header="week">Week</mat-header-cell>
        <mat-cell class="week-column" *matCellDef="let shipmentItem">
          <p>{{ shipmentItem.shipment.week }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lot">
        <mat-header-cell *matHeaderCellDef mat-sort-header="lot" class="lot-name-column"> Lot </mat-header-cell>
        <mat-cell class="lot-name-column" *matCellDef="let shipmentItem"
          ><a [routerLink]="['/lot-edit', shipmentItem.purchaseOrderItem.lot.id, 'po']">
            {{ shipmentItem.purchaseOrderItem.lot.name }}
          </a></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="shipmentCode" sticky>
        <mat-header-cell
          class="shipment-code-column"
          *matHeaderCellDef
          mat-sort-header="shipment"
          style="flex: 0 0 115px !important; white-space: nowrap !important;"
        >
          Shipment code
        </mat-header-cell>
        <mat-cell
          class="shipment-code-column"
          *matCellDef="let shipmentItem"
          style="flex: 0 0 115px !important; white-space: nowrap !important;"
        >
          <a [routerLink]="['/shipments', shipmentItem.shipment.id]">{{ shipmentItem.shipment.shipmentCode }}</a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="orderId" sticky>
        <mat-header-cell class="shipment-order-id-column" *matHeaderCellDef mat-sort-header="orderId"> Order ID </mat-header-cell>
        <mat-cell
          class="shipment-order-id-column"
          *matCellDef="let ship"
          [matTooltip]="ship ? ship.shipment.orderId : ''"
          matTooltipPosition="below"
        >
          {{ ship.shipment.orderId }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="origin">
        <mat-header-cell class="origin-column" *matHeaderCellDef mat-sort-header="origin"> Origin </mat-header-cell>
        <mat-cell class="origin-column" *matCellDef="let shipmentItem">
          <p>
            {{ shipmentItem.shipment.origin ? shipmentItem.shipment.origin.code + ' - ' + shipmentItem.shipment.origin.name : '' }}
          </p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="extra">
        <mat-header-cell class="extra-flag-column" *matHeaderCellDef> Extra </mat-header-cell>
        <mat-cell class="extra-flag-column" *matCellDef="let shipmentItem">
          <i [ngClass]="shipmentItem.shipment.isExtra | booleanIconPipe" aria-hidden="true"></i>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="crossUkBorder">
        <mat-header-cell class="extra-flag-column" *matHeaderCellDef> Cross UK </mat-header-cell>
        <mat-cell class="extra-flag-column" *matCellDef="let shipmentItem">
          <i [ngClass]="shipmentItem.shipment.crossUkBorder | booleanIconPipe" aria-hidden="true"></i>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell class="shipment-status-column" *matHeaderCellDef mat-sort-header="status"> Status </mat-header-cell>
        <mat-cell class="shipment-status-column" *matCellDef="let shipmentItem"> {{ shipmentItem.shipment.status?.name }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="paymentStatus">
        <mat-header-cell class="shipment-status-column" *matHeaderCellDef mat-sort-header="paymentStatus"> Payment Status </mat-header-cell>
        <mat-cell class="shipment-status-column" *matCellDef="let shipmentItem">
          {{ shipmentItem.shipment.paymentStatus?.description }}</mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="ean" sticky>
        <mat-header-cell *matHeaderCellDef class="ean-column" mat-sort-header="ean"> EAN </mat-header-cell>
        <mat-cell *matCellDef="let shipmentItem" class="ean-column">
          <a [routerLink]="['/inventory/products', shipmentItem.purchaseOrderItem.product.id, 'details']">{{
            shipmentItem.purchaseOrderItem.product.ean
          }}</a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actualEan" sticky>
        <mat-header-cell *matHeaderCellDef class="ean-column" mat-sort-header="actualEan"> Actual EAN </mat-header-cell>
        <mat-cell *matCellDef="let shipmentItem" class="ean-column">
          {{ shipmentItem.actualEan }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="asin">
        <mat-header-cell *matHeaderCellDef mat-sort-header="asin" class="asin-column"> ASIN </mat-header-cell>
        <mat-cell class="asin-column" *matCellDef="let shipmentItem">
          <p>{{ shipmentItem.purchaseOrderItem.product.asin }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="title">
        <mat-header-cell class="title-column" *matHeaderCellDef mat-sort-header="title"> Title </mat-header-cell>
        <mat-cell class="title-column" *matCellDef="let shipmentItem">
          <p>{{ shipmentItem.purchaseOrderItem.product.title }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="poId">
        <mat-header-cell class="po-code-column" *matHeaderCellDef mat-sort-header="po"> PO </mat-header-cell>
        <mat-cell class="po-code-column" *matCellDef="let shipmentItem"> {{ shipmentItem.purchaseOrderItem.purchaseOrder.poId }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="brand">
        <mat-header-cell *matHeaderCellDef mat-sort-header="brand" class="category-column"> Brand </mat-header-cell>
        <mat-cell *matCellDef="let shipmentItem" class="category-column">
          <p>{{ shipmentItem.purchaseOrderItem.product.brand ? shipmentItem.purchaseOrderItem.product.brand.name : '' }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="category">
        <mat-header-cell *matHeaderCellDef mat-sort-header="category" class="category-column"> Category </mat-header-cell>
        <mat-cell *matCellDef="let shipmentItem" class="category-column">
          <p>
            {{ shipmentItem.purchaseOrderItem.product.category ? shipmentItem.purchaseOrderItem.product.category.name : '' }}
          </p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="confirmedQuantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header="confirmedQuantity" class="quantity-column-title">
          Accepted quantity
        </mat-header-cell>
        <mat-cell *matCellDef="let shipmentItem" class="quantity-column">
          <p>{{ shipmentItem.confirmedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="confirmedValue">
        <mat-header-cell *matHeaderCellDef mat-sort-header="confirmedValue" class="value-column-title"> Accepted value </mat-header-cell>
        <mat-cell *matCellDef="let shipmentItem" class="value-column">
          <p>{{ shipmentItem.confirmedValue | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="confirmedPurchasePrice">
        <mat-header-cell *matHeaderCellDef mat-sort-header="confirmedPurchasePrice" class="value-column-title">
          Accepted purchase price
        </mat-header-cell>
        <mat-cell *matCellDef="let shipmentItem" class="value-column">
          <p>{{ shipmentItem.confirmedPurchasePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="virtualConfirmedPurchasePrice">
        <mat-header-cell *matHeaderCellDef mat-sort-header="virtualConfirmedPurchasePrice" class="value-column-title">
          Virtual Accepted purchase price
        </mat-header-cell>
        <mat-cell *matCellDef="let shipmentItem" class="value-column">
          <p>{{ shipmentItem.virtualConfirmedPurchasePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="shippedQuantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header="shippedQuantity" class="quantity-column-title">
          Shipped quantity
        </mat-header-cell>
        <mat-cell *matCellDef="let shipmentItem" class="quantity-column">
          <p>{{ shipmentItem.shippedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="shippedValue">
        <mat-header-cell *matHeaderCellDef mat-sort-header="shippedValue" class="value-column-title"> Shipped value </mat-header-cell>
        <mat-cell *matCellDef="let shipmentItem" class="value-column">
          <p>{{ shipmentItem.shippedValue | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="shippedPurchasePrice">
        <mat-header-cell *matHeaderCellDef mat-sort-header="shippedPurchasePrice" class="value-column-title">
          Shipped purchase price
        </mat-header-cell>
        <mat-cell *matCellDef="let shipmentItem" class="value-column">
          <p>{{ shipmentItem.shippedPurchasePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="virtualShippedPurchasePrice">
        <mat-header-cell *matHeaderCellDef mat-sort-header="virtualShippedPurchasePrice" class="value-column-title">
          Virtual Shipped purchase price
        </mat-header-cell>
        <mat-cell *matCellDef="let shipmentItem" class="value-column">
          <p>{{ shipmentItem.virtualShippedPurchasePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="backorder">
        <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="backorder"> Backorder </mat-header-cell>
        <mat-cell class="boolean-column" *matCellDef="let shipmentItem">
          <p><i [ngClass]="shipmentItem.shipment.backorder | booleanIconPipe" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="vendor">
        <mat-header-cell class="vendor-column" *matHeaderCellDef mat-sort-header="vendor"> Vendor </mat-header-cell>
        <mat-cell
          class="vendor-column"
          *matCellDef="let shipmentItem"
          [matTooltip]="shipmentItem.purchaseOrderItem.purchaseOrder.vendor.name"
          matTooltipPosition="below"
        >
          {{ shipmentItem.purchaseOrderItem.purchaseOrder.vendor.code }}</mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="destination">
        <mat-header-cell class="destination-column" *matHeaderCellDef mat-sort-header="destination"> Destination </mat-header-cell>
        <mat-cell class="destination-column" *matCellDef="let shipmentItem">
          <p>{{ shipmentItem.shipment.destination?.code + '-' + shipmentItem.shipment.destination?.name }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="latestDeliveryDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="latestDeliveryDate" class="date-column"> Latest delivery date </mat-header-cell>
        <mat-cell *matCellDef="let shipmentItem" class="date-column">{{ shipmentItem.shipment.latestDeliveryDate }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="latestPickupDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="latestPickupDate" class="date-column"> Latest pickup date </mat-header-cell>
        <mat-cell *matCellDef="let shipmentItem" class="date-column">{{ shipmentItem.shipment.latestPickupDate }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="requestForPayment">
        <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="requestForPayment">
          Request for payment
        </mat-header-cell>
        <mat-cell class="boolean-column" *matCellDef="let shipmentItem">
          <p><i [ngClass]="shipmentItem.shipment.requestForPayment | booleanIconPipe" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="requestForPaymentDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="requestForPaymentDate" class="date-column">
          Request payment date
        </mat-header-cell>
        <mat-cell *matCellDef="let shipmentItem" class="date-column">{{ shipmentItem.shipment.requestForPaymentDate }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="payment">
        <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="payment"> Payment </mat-header-cell>
        <mat-cell class="boolean-column" *matCellDef="let shipmentItem">
          <p><i [ngClass]="shipmentItem.shipment.payment | booleanIconPipe" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="paymentDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="paymentDate" class="date-column"> Payment date </mat-header-cell>
        <mat-cell *matCellDef="let shipmentItem" class="date-column">{{ shipmentItem.shipment.paymentDate }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="shipmentDate">
        <mat-header-cell class="date-column" *matHeaderCellDef mat-sort-header="shipmentDate"> Shipped at </mat-header-cell>
        <mat-cell class="date-column" *matCellDef="let shipmentItem">{{ shipmentItem.shipment.shipmentDate }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="asnCode">
        <mat-header-cell class="asn-column" *matHeaderCellDef mat-sort-header="asnCode"> ASN Code </mat-header-cell>
        <mat-cell
          class="asn-column"
          *matCellDef="let shipmentItem"
          [matTooltip]="shipmentItem.shipment ? shipmentItem.shipment.asnCode : ''"
          matTooltipPosition="below"
        >
          <p>{{ shipmentItem.shipment.asnCode ? shipmentItem.shipment.asnCode : '' }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="sentAsn">
        <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="sentAsn"> ASN sent </mat-header-cell>
        <mat-cell class="boolean-column" *matCellDef="let shipmentItem">
          <p><i [ngClass]="shipmentItem.shipment.sentAsn | booleanIconPipe" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="invoiceNumber">
        <mat-header-cell class="ddt-column" *matHeaderCellDef mat-sort-header="invoiceNumber"> Invoice Number </mat-header-cell>
        <mat-cell
          class="ddt-column"
          *matCellDef="let shipmentItem"
          [matTooltip]="shipmentItem.shipment?.invoice ? shipmentItem.shipment.invoice?.invoiceNumber : ''"
          matTooltipPosition="below"
        >
          <p>{{ ship?.invoice ? ship.invoice?.invoiceNumber : '' }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="deliveryDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="deliveryDate" class="date-column"> Delivery date </mat-header-cell>
        <mat-cell *matCellDef="let shipmentItem" class="date-column">{{ shipmentItem.shipment.deliveryDate | myDate }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ddtNumber">
        <mat-header-cell class="ddt-column" *matHeaderCellDef mat-sort-header="ddtNumber"> DDT Number </mat-header-cell>
        <mat-cell
          class="ddt-column"
          *matCellDef="let shipmentItem"
          [matTooltip]="shipmentItem.shipment ? shipmentItem.shipment.ddtNumber : ''"
          matTooltipPosition="below"
        >
          <p>{{ shipmentItem.shipment.ddtNumber ? shipmentItem.shipment.ddtNumber : '' }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="hasTransit">
        <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="hasTransit"> Has Transit </mat-header-cell>
        <mat-cell class="boolean-column" *matCellDef="let shipmentItem">
          <p><i [ngClass]="!shipmentItem.shipment.origin?.supplier.shipsToAmazon | booleanIconPipe" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ukIntercoInvoice">
        <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="ukIntercoInvoice">
          Requires Uk Interco Invoice
        </mat-header-cell>
        <mat-cell class="boolean-column" *matCellDef="let shipmentItem">
          <p><i [ngClass]="shipmentItem.shipment.ukIntercoInvoice | booleanIconPipe" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ukIntercoInvoiceValidData">
        <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="ukIntercoInvoiceValidData">
          Valid UK Interco Data
        </mat-header-cell>
        <mat-cell class="boolean-column" *matCellDef="let shipmentItem">
          <p><i [ngClass]="shipmentItem.shipment.ukIntercoInvoiceValidData | booleanIconPipe" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ukIntercoInvoiceStatus">
        <mat-header-cell class="shipment-status-column" *matHeaderCellDef mat-sort-header="ukIntercoInvoiceStatus">
          UK Interco Status
        </mat-header-cell>
        <mat-cell class="shipment-status-column" *matCellDef="let shipmentItem">
          <p>{{ shipmentItem.shipment.ukIntercoInvoiceStatus.description }}</p>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
