<mat-toolbar class="lot-edit-bulk-actions">
  <div class="container">
    <div class="row">
      <div class="col-xs-10">
        <button
          *ngIf="hasLotManagement | async"
          [disabled]="!bulkActionsActive"
          [matMenuTriggerFor]="changeLotMenu"
          class="box-shadow-none btn-custom margin-right-6"
        >
          Lot handling
          <i class="fa fa-caret-down margin-left-6" aria-hidden="true"></i>
        </button>

        <mat-menu #changeLotMenu="matMenu">
          <button mat-menu-item [disabled]="!bulkActionsActive" (click)="moveToLot()">
            <i class="fa fa-share margin-right-6" aria-hidden="true"></i>Move to Lot
          </button>
          <button mat-menu-item [disabled]="!bulkActionsActive" (click)="removeFromLot()">
            <i class="fa fa-times margin-right-6" aria-hidden="true"></i>Remove from Lot
          </button>
        </mat-menu>

        <button
          *ngIf="hasPoStateChange | async"
          [disabled]="!bulkActionsActive"
          [matMenuTriggerFor]="changeStatusMenu"
          class="box-shadow-none btn-custom"
        >
          Change Status
        </button>
        <mat-menu #changeStatusMenu="matMenu">
          <button mat-menu-item (click)="changePosStatus('New')">New</button>
          <button mat-menu-item (click)="changePosStatus('Open')">Open</button>
          <button mat-menu-item (click)="changePosStatus('Closed')">Closed</button>
          <button *ngIf="hasSendPoaSubmitted | async" mat-menu-item (click)="changePosStatus('Closed', true)">Closed (submitted)</button>
        </mat-menu>
      </div>

      <div class="col-xs-2 text-right">
        <ng-container [ngSwitch]="bulkActionsActive">
          <button *ngSwitchCase="true" [matMenuTriggerFor]="exportPosChoice" class="box-shadow-none btn-custom">
            <i class="fa fa-external-link margin-right-6" aria-hidden="true"></i> Export selected POs
          </button>
          <mat-menu #exportPosChoice="matMenu">
            <button mat-menu-item (click)="exportExcel('excel')">Save as Excel</button>
            <button mat-menu-item (click)="exportExcel('csv')">Save as CSV</button>
          </mat-menu>
          <button *ngSwitchCase="false" [matMenuTriggerFor]="exportAllPosChoice" class="box-shadow-none btn-custom" style="float: right">
            <i class="fa fa-external-link margin-right-6" aria-hidden="true"></i> Export all POs
          </button>
          <mat-menu #exportAllPosChoice="matMenu">
            <button mat-menu-item (click)="exportExcel('excel')">Save as Excel</button>
            <button mat-menu-item (click)="exportExcel('csv')">Save as CSV</button>
          </mat-menu>
        </ng-container>
      </div>
    </div>
  </div>
</mat-toolbar>
