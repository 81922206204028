<h2 mat-dialog-title>
  <span class="dialog-title">Supplier Claim Detail</span>
  <span class="dialog-close-icon padding-right-30">
    <i class="fa fa-times exit-icon margin-bottom-10" (click)="close()" style="cursor: pointer;"></i>
  </span>
</h2>

<div mat-dialog-content>
  <div class="row text-blue"><h4>Downconfirmed</h4></div>
  <div class="row">
    <div class="col-md-4"><div class="row">Quantity:</div></div>
    <div class="col-md-8"><div class="row">{{ getQuantity('DOWNCONFIRMED') }}</div></div>
  </div>
  <div class="row">
    <div class="col-md-4"><div class="row">Value:</div></div>
    <div class="col-md-8"><div class="row">{{ getValue('DOWNCONFIRMED') | myCurrency }}</div></div>
  </div>

  <br>

  <div class="row text-blue"><h4>Po Not On Time</h4></div>
  <div class="row">
    <div class="col-md-4"><div class="row">Quantity:</div></div>
    <div class="col-md-8"><div class="row">{{ getQuantity('PO_NOT_ON_TIME') }}</div></div>
  </div>
  <div class="row">
    <div class="col-md-4"><div class="row">Value:</div></div>
    <div class="col-md-8"><div class="row">{{ getValue('PO_NOT_ON_TIME') | myCurrency }}</div></div>
  </div>

  <br>

  <div class="row text-blue"><h4>Po Not Filled</h4></div>
  <div class="row">
    <div class="col-md-4"><div class="row">Quantity:</div></div>
    <div class="col-md-8"><div class="row">{{ getQuantity('PO_NOT_FILLED') }}</div></div>
  </div>
  <div class="row">
    <div class="col-md-4"><div class="row">Value:</div></div>
    <div class="col-md-8"><div class="row">{{ getValue('PO_NOT_FILLED') | myCurrency }}</div></div>
  </div>

  <br>

  <div class="row text-blue"><h4>PiD</h4></div>
    <div class="row">
    <div class="col-md-4"><div class="row">Quantity:</div></div>
    <div class="col-md-8"><div class="row">{{ getQuantity('CARTON_INFORMATION_COMPLIANCE') }}</div></div>
  </div>
  <div class="row">
    <div class="col-md-4"><div class="row">Value:</div></div>
    <div class="col-md-8"><div class="row">{{ getValue('CARTON_INFORMATION_COMPLIANCE') | myCurrency }}</div></div>
  </div>
</div>
