import { JsonObject, JsonProperty } from 'json2typescript';
import { Status } from './status-model';

@JsonObject('DisputeBotLog')
export class DisputeBotLog {
  @JsonProperty('id', Number, false) id: number = undefined;

  @JsonProperty('requestId', String, true) requestId: string = undefined;
  @JsonProperty('accountName', String, true) accountName: string = undefined;
  @JsonProperty('invoiceNumber', String, true) invoiceNumber: string = undefined;
  @JsonProperty('action', Status, true) action: Status = undefined;
  @JsonProperty('status', Status, true) status: Status = undefined;
  @JsonProperty('date', String, true) date: string = undefined;
  @JsonProperty('disputeId', String, true) disputeId: string = undefined;
  @JsonProperty('disputeDate', String, true) disputeDate: string = undefined;
  @JsonProperty('caseId', String, true) caseId: string = undefined;
  @JsonProperty('caseDate', String, true) caseDate: string = undefined;
  @JsonProperty('message', String, true) message: string = undefined;
}
