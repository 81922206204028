<div class="dialog-container">
  <h2 mat-dialog-title>Add Adjustment</h2>

  <mat-dialog-content>
    <div class="row">
      <div class="col-xs-6">
        <mat-form-field>
          <app-mat-select placeholder="Type" [(ngModel)]="form.type" filterKey="description">
            <mat-option [value]="t" *appMatSelectFor="let t of types">
              {{ t.description }}
            </mat-option>
          </app-mat-select>
        </mat-form-field>
      </div>

      <div class="col-xs-6">
        <mat-form-field class="width-100per">
          <input matInput type="number" [(ngModel)]="form.value" placeholder="Value" />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <mat-form-field class="width-100per">
          <input matInput maxlength="255" [(ngModel)]="form.causal" placeholder="Causal" />
        </mat-form-field>
      </div>
    </div>

  </mat-dialog-content>

  <div mat-dialog-actions>
    <button class="btn-product margin-right-6" [disabled]="isDisabled()" [mat-dialog-close]="form">Save</button>
    <button class="btn-product" cdkFocusInitial [mat-dialog-close]="null">Cancel</button>
  </div>
</div>
