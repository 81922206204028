import { Directive, EventEmitter, Injectable, Input, Output } from '@angular/core';
import { isNilty } from '../../../core/utils.service';
import * as moment from 'moment';
import { Moment } from 'moment';
import { JobExecutionStatus, JobStatus } from '../../../models/job-status/job-execution-status';
import { Observable } from 'rxjs';

@Directive()
export abstract class GenericProgressBarDirective {
  @Input() updatedStatus: Observable<JobExecutionStatus>;

  @Output() jobCompleted = new EventEmitter<void>();
  @Output() jobStuck = new EventEmitter<void>();
  @Output() jobRetry = new EventEmitter<void>();

  abstract jobStatus: JobExecutionStatus;
  abstract oldStatus: JobExecutionStatus;

  abstract TOTAL_STEPS: number;
  // eslint-disable-next-line
  JOB_STATUS = JobStatus;

  currentTry = 0;
  STUCK_RETRIES = 25;
  currentStuckTry = 0;
  stuckRetry = false;

  lastStepStart: Moment;
  lastStepMinutes: string;
  lastStepSeconds: string;
  lastStepInterval;

  currentIconRotation = 0;
  iconRotation = '';

  abstract getInternalJobStatus(job: JobExecutionStatus): string;

  initProgressBar() {
    this.startIconRotation();
    this.updatedStatus.subscribe((s) => {
      this.stuckRetry = false;
      this.oldStatus = this.jobStatus;
      this.jobStatus = s;

      if (!isNilty(this.oldStatus) && isNilty(this.jobStatus)) {
        this.completeJob();
        return;
      }

      if (this.getInternalJobStatus(this.oldStatus) !== this.getInternalJobStatus(this.jobStatus)) {
        this.resetTimers();
      }

      if (this.jobStatus?.status === this.JOB_STATUS.TROUBLE) {
        clearInterval(this.lastStepInterval);
        this.currentStuckTry++;
        if (this.currentStuckTry >= this.STUCK_RETRIES) {
          this.jobStuck.emit();
          this.stuckRetry = true;
        }
      }
    });
  }

  private startIconRotation() {
    setInterval(() => {
      this.currentIconRotation = (this.currentIconRotation + 10) % 360;
      this.iconRotation = `rotate(${this.currentIconRotation}deg)`;
    }, 100);
  }

  private completeJob() {
    clearInterval(this.lastStepInterval);
    this.jobCompleted.emit();
  }

  private resetTimers() {
    if (!isNilty(this.jobStatus.lastStepStartedAt)) {
      this.lastStepStart = moment(this.jobStatus.lastStepStartedAt, 'YYYY-MM-DD HH:mm:ss');
    }

    clearInterval(this.lastStepInterval);

    this.lastStepInterval = setInterval(() => {
      const currentTime = moment();
      this.lastStepMinutes = currentTime.diff(this.lastStepStart, 'minute').valueOf().toString();
      const seconds = (currentTime.diff(this.lastStepStart, 'second').valueOf() % 60).toString();

      if (seconds.length === 1) {
        this.lastStepSeconds = `0${seconds}`;
      } else {
        this.lastStepSeconds = seconds;
      }
    }, 1000);
  }

  private retry() {
    this.currentStuckTry = 0;
    this.stuckRetry = false;
    this.jobRetry.emit();
  }
}
