import { JsonObject, JsonProperty } from 'json2typescript';
import { Product } from './product-model';
import { CatalogItemNoProduct } from './products-catalog-model';
import { StockItem } from './stock-item-model';

@JsonObject('CatalogItem')
export class CatalogItem extends CatalogItemNoProduct {
  @JsonProperty('product', Product, false) product: Product = undefined;
}

@JsonObject('CatalogItemWithStock')
export class CatalogItemWithStock extends CatalogItemNoProduct {
  @JsonProperty('stockItems', [StockItem], true) stockItems: StockItem[] = undefined;
}
