<div class="container main-content" style="margin-top: 3%">
  <form [formGroup]="supplierForm">
    <div class="row">
      <div class="col-md-3"></div>

      <div class="col-md-6 padding-bottom-20" [ngClass]="{ 'edit-border-bottom': editMode }">
        <div class="row">
          <div class="col-md-12">
            <legend>Supplier</legend>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div>
                <mat-form-field>
                  <input matInput type="text" id="code" maxlength="4" formControlName="code" placeholder="Code" ng-readonly="editMode" />
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div>
                <mat-form-field>
                  <input matInput type="text" id="name" formControlName="name" placeholder="Name" />
                </mat-form-field>
              </div>
            </div>

            <div class="col-md-6 text-right">
              <div>
                <mat-form-field>
                  <input matInput type="text" id="businessName" formControlName="businessName" placeholder="Business Name" />
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div>
                <mat-form-field>
                  <input matInput type="text" id="iban" formControlName="iban" placeholder="Iban" />
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-6 text-right">
              <div>
                <mat-form-field>
                  <input matInput type="number" id="score" formControlName="score" placeholder="Score" />
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="row padding-top-20 edit-border-bottom padding-bottom-20">
            <div class="col-md-6">
              <mat-slide-toggle
                class="text-blue font-Titillium"
                id="updatedCatalog"
                formControlName="updatedCatalog"
                matTooltip="If true, the LSAs for this Supplier will have the accepted quantities filled up"
              >
                Updated Catalog
              </mat-slide-toggle>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <mat-form-field>
                <input matInput type="number" id="minimalValue" formControlName="minimalValue" placeholder="Minimal Value" />
              </mat-form-field>
            </div>

            <div class="col-md-6 text-right">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  id="minimalShipmentValue"
                  formControlName="minimalShipmentValue"
                  placeholder="Minimal Shipment Value"
                />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  id="downconfirmThreshold"
                  formControlName="downconfirmThreshold"
                  placeholder="Downconfirm Threshold"
                />
              </mat-form-field>
            </div>
          </div>

          <div class="row padding-top-20">
            <div class="col-md-6">
              <mat-slide-toggle class="text-blue font-Titillium" id="shipsToAmazon" formControlName="shipsToAmazon">
                Ships to Amazon</mat-slide-toggle
              >
            </div>

            <div class="col-md-6 padding-left-22-perc">
              <mat-checkbox
                class="text-blue font-Titillium"
                id="takesFirstorder"
                formControlName="takesFirstorder"
                matTooltip="The Supplier will be considered in fulfilling firstorder POs"
                >Takes Firstorder
              </mat-checkbox>
            </div>
          </div>

          <div class="row padding-top-20">
            <div class="col-md-6">
              <mat-slide-toggle
                class="text-blue font-Titillium"
                id="transit"
                formControlName="transit"
                matTooltip="If true, when sending the SPOs to Jimbo will be used the latestPickupDate instead of the latestDeliveryDate"
              >
                Transit</mat-slide-toggle
              >
            </div>

            <div class="col-md-6 padding-left-22-perc">
              <mat-checkbox
                class="text-blue font-Titillium"
                id="takesBackorder"
                formControlName="takesBackorder"
                matTooltip="The Supplier will be considered in fulfilling backorder POs"
                >Takes Backorder</mat-checkbox
              >
            </div>
          </div>

          <div class="row padding-top-20">
            <div class="col-md-6">
              <mat-slide-toggle
                class="text-blue font-Titillium"
                id="uniqueSupplier"
                formControlName="uniqueSupplier"
                matTooltip="If true, all the requested quantities in the LSAs will be considered available"
                >Unique
              </mat-slide-toggle>
            </div>

            <div class="col-md-6 padding-left-22-perc">
              <mat-checkbox
                class="text-blue font-Titillium"
                id="takesSoftFirstorder"
                formControlName="takesSoftFirstorder"
                matTooltip="The Supplier will be considered in fulfilling soft-firstorder POs"
                >Takes Soft Firstorder
              </mat-checkbox>
            </div>
          </div>

          <div class="row padding-top-20">
            <div class="col-md-6">
              <mat-slide-toggle
                class="text-blue font-Titillium"
                id="acceptBindingOrders"
                formControlName="acceptBindingOrders"
                matTooltip="If true, the orders sent to Jimbo are binding (no confirmation required)"
                >Accept binding orders</mat-slide-toggle
              >
            </div>
          </div>

          <div class="row padding-top-20">
            <div class="col-md-6">
              <mat-slide-toggle
                class="text-blue font-Titillium"
                id="canPickupToday"
                formControlName="canPickupToday"
                matTooltip="If true, the spo's latest pickup date can be today"
                >Can Pickup Today</mat-slide-toggle
              >
            </div>
          </div>

          <div class="row padding-top-20">
            <div class="col-md-6">
              <mat-form-field>
                <app-mat-select
                  filterKey="name"
                  formControlName="defaultTransitWarehouse"
                  id="defaultTransitWarehouse"
                  placeholder="Default Transit Warehouse"
                >
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="tw" *appMatSelectFor="let tw of transitWarehouse">
                    {{ tw.name }}
                  </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row padding-top-20" *ngIf="currentSupplier.packingListFieldsPosition">
            <div class="col-md-12">
              <app-map-edit
                name="PackingList Positions"
                [_map]="currentSupplier.packingListFieldsPosition"
                [_allowedFields]="packingListFields"
              ></app-map-edit>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3"></div>

      <div class="col-md-6 padding-bottom-10">
        <div class="row">
          <div class="col-md-4">
            <mat-form-field>
              <input matInput type="text" maxlength="3" id="ssccPrefix" formControlName="ssccPrefix" placeholder="SSCC Prefix" />
            </mat-form-field>
          </div>
          <div class="col-md-4 padding-top-15">
            <mat-checkbox class="text-blue font-Titillium" (change)="sendSSCCChanged($event)" id="sendSSCC" formControlName="sendSSCC"
              >Send SSCC</mat-checkbox
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3"></div>

      <div class="col-md-6 padding-bottom-20 edit-border-bottom">
        <div class="row">
          <div class="col-md-4">
            <mat-form-field>
              <input matInput type="text" maxlength="4" id="asnPrefix" formControlName="asnPrefix" placeholder="ASN Prefix" />
            </mat-form-field>
          </div>
          <div class="col-md-4 padding-top-15">
            <mat-checkbox class="text-blue font-Titillium" (change)="sendAsnChanged($event)" id="sendsAsn" formControlName="sendsAsn"
              >Sends ASN</mat-checkbox
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3"></div>

      <div class="col-md-6 padding-bottom-20 edit-border-bottom">
        <div class="row">
          <div class="col-md-12">
            <mat-form-field class="chip-list">
              <mat-chip-list #receiversList>
                <mat-chip
                  *ngFor="let r of shipmentDocumentationReceivers"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="removeEmailReceiver(r)"
                >
                  {{ r }}
                  <i class="fa fa-times" matChipRemove aria-hidden="true"></i>
                </mat-chip>
                <input
                  placeholder="Shipment documentation receivers"
                  [formControl]="receiverEmailInput"
                  [matChipInputFor]="receiversList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="addEmailReceiver($event)"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
        </div>
        <div class="row padding-top-15">
          <div class="col-md-5">
            <mat-form-field>
              <mat-select formControlName="shipmentDocumentMethod" id="shipmentDocumentMethod" placeholder="Shipment documentation method">
                <mat-option [value]="sdm" *ngFor="let sdm of shipmentDocumentMethods">{{ sdm.description }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3"></div>

      <div class="col-md-6 padding-bottom-20 edit-border-bottom">
        <mat-form-field class="chip-list">
          <mat-chip-list #packingListReceiversList>
            <mat-chip
              *ngFor="let r of packingListErrorReceivers"
              [selectable]="true"
              [removable]="true"
              (removed)="removePackingListEmailReceiver(r)"
            >
              {{ r }}
              <i class="fa fa-times" matChipRemove aria-hidden="true"></i>
            </mat-chip>
            <input
              placeholder="Packing list error receivers"
              [formControl]="receiverPackingListEmailInput"
              [matChipInputFor]="packingListReceiversList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addPackingListEmailReceiver($event)"
            />
          </mat-chip-list>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3"></div>

      <div class="col-md-6 padding-bottom-20 edit-border-bottom">
        <div class="row padding-bottom-20">
          <div class="col-md-12">
            <mat-form-field class="chip-list">
              <mat-chip-list #expiringShipmentAlertReceiversList>
                <mat-chip
                  *ngFor="let r of expiringShipmentAlertReceivers"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="removeExpiringShipmentAlertReceiver(r)"
                >
                  {{ r }}
                  <i class="fa fa-times" matChipRemove aria-hidden="true"></i>
                </mat-chip>
                <input
                  placeholder="Expiring shipment alert receivers"
                  [formControl]="receiverExpiringShipmentAlert"
                  [matChipInputFor]="expiringShipmentAlertReceiversList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="addExpiringShipmentAlertReceiver($event)"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <mat-form-field>
              <input
                matInput
                type="number"
                id="expiringShipmentAlertDaysBefore"
                formControlName="expiringShipmentAlertDaysBefore"
                placeholder="Expiring Shipment Alert Days Before"
              />
            </mat-form-field>
          </div>

          <div class="col-md-6">
            <mat-checkbox
              class="text-blue font-Titillium"
              id="expiringShipmentAlertTemplateWithDestination"
              formControlName="templateWithDestination"
              matTooltip="Expiring Shipment Alert Template With Destination"
              >Template with destination
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3"></div>

      <div class="col-md-6 padding-bottom-20 edit-border-bottom">
        <div class="row">
          <div class="col-md-6">
            <mat-form-field>
              <input
                matInput
                type="number"
                id="paymentCategoryThreshold"
                formControlName="paymentCategoryThreshold"
                placeholder="Payment Category Threshold"
              />
            </mat-form-field>
          </div>

          <div class="col-md-6">
            <mat-form-field>
              <input
                matInput
                type="number"
                id="paymentExecutiveThreshold"
                formControlName="paymentExecutiveThreshold"
                placeholder="Payment Executive Threshold"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3"></div>

      <div class="col-md-6">
        <mat-form-field class="chip-list">
          <mat-chip-list #categoryManagerReceiverList>
            <mat-chip
              *ngFor="let r of categoryManagerReceivers"
              [selectable]="true"
              [removable]="true"
              (removed)="removeCategoryManagerReceiver(r)"
            >
              {{ r }}
              <i class="fa fa-times" matChipRemove aria-hidden="true"></i>
            </mat-chip>
            <input
              placeholder="Category manager receivers"
              [formControl]="receiverCategoryManager"
              [matChipInputFor]="categoryManagerReceiverList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addCategoryManagerReceiver($event)"
            />
          </mat-chip-list>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3"></div>

      <div class="col-md-6 padding-bottom-20">
        <mat-form-field class="chip-list">
          <mat-chip-list #executiveManagerReceiverList>
            <mat-chip
              *ngFor="let r of executiveManagerReceivers"
              [selectable]="true"
              [removable]="true"
              (removed)="removeExecutiveManagerReceiver(r)"
            >
              {{ r }}
              <i class="fa fa-times" matChipRemove aria-hidden="true"></i>
            </mat-chip>
            <input
              placeholder="Executive manager receivers"
              [formControl]="receiverExecutiveManager"
              [matChipInputFor]="executiveManagerReceiverList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addExecutiveManagerReceiver($event)"
            />
          </mat-chip-list>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3"></div>

      <div class="col-md-6 padding-bottom-20">
        <mat-form-field class="chip-list">
          <mat-chip-list #PaymentRemittanceReceiverList>
            <mat-chip
              *ngFor="let r of paymentRemittanceReceivers"
              [selectable]="true"
              [removable]="true"
              (removed)="removePaymentRemittanceReceiver(r)"
            >
              {{ r }}
              <i class="fa fa-times" matChipRemove aria-hidden="true"></i>
            </mat-chip>
            <input
              placeholder="Payment remittance receivers"
              [formControl]="receiverPaymentRemittance"
              [matChipInputFor]="PaymentRemittanceReceiverList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addPaymentRemittanceReceiver($event)"
            />
          </mat-chip-list>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3"></div>

      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6">
            <mat-form-field>
              <mat-select formControlName="paymentTerms" id="paymentTerms" placeholder="Payment Terms">
                <mat-option [value]="pt" *ngFor="let pt of paymentTerms">{{ pt.description }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-6">
            <mat-form-field>
              <input matInput type="number" id="discount" formControlName="discount" placeholder="Discount (%)" min="0" max="100" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3"></div>

      <div class="col-md-6 padding-bottom-20 edit-border-bottom">
        <div class="row">
          <div class="col-md-6">
            <mat-form-field>
              <input
                matInput
                type="number"
                id="shippingCosts"
                formControlName="shippingCosts"
                placeholder="Shipping Costs (%)"
                min="0"
                max="100"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="row" *ngIf="!editMode">
    <div class="col-md-3"></div>

    <div class="col-md-6 padding-bottom-20 edit-border-bottom">
      <form *ngIf="!editMode" [formGroup]="stockLocForm">
        <legend>Default Stock Location</legend>

        <div class="row">
          <div class="col-md-6">
            <div>
              <mat-form-field>
                <input matInput type="text" id="namesl" formControlName="name" placeholder="Name" />
              </mat-form-field>
            </div>
          </div>

          <div class="col-md-6 text-right">
            <div>
              <mat-form-field>
                <input matInput type="text" id="codesl" formControlName="code" placeholder="Code" />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <mat-form-field>
              <input matInput type="text" id="countryCode" formControlName="countryCode" placeholder="Country Code" />
            </mat-form-field>
          </div>

          <div class="col-md-6 text-right">
            <mat-form-field>
              <input matInput type="text" id="address" formControlName="address" placeholder="Address" />
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="row padding-bottom-20 edit-border-bottom" *ngIf="hasAdministration | async">
    <div class="col-md-3"></div>

    <div class="col-md-4" *ngIf="!showJimboMessagesSelect">
      <button class="btn-product" (click)="editJimboMessages()"><i class="fa fa-cog" aria-hidden="true"></i> Edit Jimbo Messages</button>
    </div>

    <div class="col-md-4" *ngIf="showJimboMessagesSelect">
      <mat-form-field>
        <mat-select
          [(ngModel)]="selectedMessageTypes"
          name="jimboMessages"
          placeholder="Jimbo Messages"
          multiple
          (ngModelChange)="messagesChanged()"
        >
          <mat-option [value]="type" *ngFor="let type of jimboMessageTypes">{{ type }}</mat-option>
        </mat-select>
      </mat-form-field>

      <button class="btn-product margin-left-10 edit-button" [disabled]="!canSaveMessages" (click)="saveJimboMessages()" matTooltip="Save">
        <i class="fa fa-floppy-o" aria-hidden="true"></i>
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-md-3"></div>

    <div class="col-md-6 text-center">
      <button
        type="submit"
        [disabled]="!supplierForm.valid || (!editMode && !stockLocForm.valid)"
        (click)="onSubmit()"
        class="btn-product margin-right-20"
      >
        Save
      </button>
      <button *ngIf="editMode" type="button" (click)="onDelete()" class="btn-product margin-right-20">Delete!</button>
      <button type="button" (click)="onCancel()" class="btn-product">Back</button>
    </div>
  </div>
</div>
