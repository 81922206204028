import { Component, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatChipsData } from '../../../../../../models/mat-chips-data-model';
import { VendorsService } from '../../../../../configuration/vendors/vendors.service';
import { Vendor } from '../../../../../../models/vendor-model';
import { isNilty } from '../../../../../../core/utils.service';
import { ClaimService } from '../../claim.service';
import { claimFiltersMap, ClaimFilters } from '../../../../../../models/filters/claim-filters-model';
import { InventoryService } from '../../../../../inventory/inventory.service';
import { Status } from '../../../../../../models/status-model';
import { Supplier } from '../../../../../../models/supplier-model';
import { Contract } from '../../../../../../models/contract.model';
import { ShipmentsService } from '../../../../../../shared/shipments.service';

@Component({
  selector: 'app-claims-list-filters',
  templateUrl: './claims-list-filters.component.html',
  styleUrls: ['./claims-list-filters.component.css'],
})
export class ClaimsListFiltersComponent implements OnInit {
  constructor(
    private vendorsService: VendorsService,
    private inventoryService: InventoryService,
    private claimService: ClaimService,
    private shipmentService: ShipmentsService
  ) {}

  filterForm: FormGroup;
  public openFilter = false;

  filtersMap: MatChipsData[] = [];

  status: Status[] = [];
  vendors: Vendor[] = [];
  suppliers: Supplier[] = [];
  contracts: Contract[] = [];

  @Output() onFilter = new Subject<ClaimFilters>();

  ngOnInit() {
    this.filterForm = new FormGroup({
      invoiceNumber: new FormControl(),
      invoiceDate: new FormControl(),
      suffix: new FormControl(),
      amount: new FormControl(),
      vendor: new FormControl(),
      vendorChild: new FormControl(),
      supplier: new FormControl(),
      carrierContracts: new FormControl(),
      trackingNumber: new FormControl(),
      status: new FormControl(),
      disputeId: new FormControl(),
      caseId: new FormControl(),
      deliveryDate: new FormControl(),
      hasPod: new FormControl(),
      hasRemittance: new FormControl(),
      po: new FormControl(),
      settlement: new FormControl(),
    });

    if (!isNilty(this.claimService.claimFilters !== null)) {
      this.filterForm.patchValue(this.claimService.claimFilters);
      this.updateFiltersMap();
    }

    this.vendorsService.getVendors().subscribe((vendors: Vendor[]) => (this.vendors = vendors));

    this.inventoryService.getAllSuppliers().subscribe((suppliers: Supplier[]) => (this.suppliers = suppliers));

    this.claimService.getClaimStatus().subscribe((status: Status[]) => (this.status = status));

    this.shipmentService.getAllContracts().subscribe((contracts: Contract[]) => (this.contracts = contracts));
  }

  onSubmitFilter() {
    this.saveFiltersToChargebackService();
    this.updateFiltersMap();
    this.onFilter.next(this.claimService.claimFilters);
  }

  resetFilters() {
    this.filterForm.reset();
    this.saveFiltersToChargebackService();
    this.updateFiltersMap();
    this.onFilter.next(this.claimService.claimFilters);
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeevent() {
    this.openFilter = false;
  }

  saveFiltersToChargebackService() {
    this.claimService.claimFilters.invoiceNumber = isNilty(this.filterForm.value.invoiceNumber)
      ? null
      : this.filterForm.value.invoiceNumber;
    this.claimService.claimFilters.invoiceDate = this.filterForm.value.invoiceDate;
    this.claimService.claimFilters.suffix = isNilty(this.filterForm.value.suffix) ? null : this.filterForm.value.suffix;
    this.claimService.claimFilters.amount = isNilty(this.filterForm.value.amount) ? null : this.filterForm.value.amount;
    this.claimService.claimFilters.vendor = isNilty(this.filterForm.value.vendor) ? null : this.filterForm.value.vendor.id;
    this.claimService.claimFilters.supplier = this.filterForm.value.supplier;
    this.claimService.claimFilters.carrierContracts = isNilty(this.filterForm.value.carrierContracts)
      ? null
      : this.filterForm.value.carrierContracts.map((carrierContract) => carrierContract.id);
    this.claimService.claimFilters.trackingNumber = isNilty(this.filterForm.value.trackingNumber)
      ? null
      : this.filterForm.value.trackingNumber;
    this.claimService.claimFilters.status = this.filterForm.value.status;
    this.claimService.claimFilters.disputeId = isNilty(this.filterForm.value.disputeId) ? null : this.filterForm.value.disputeId;
    this.claimService.claimFilters.caseId = isNilty(this.filterForm.value.caseId) ? null : this.filterForm.value.caseId;
    this.claimService.claimFilters.deliveryDate = this.filterForm.value.deliveryDate;
    this.claimService.claimFilters.hasPod = this.filterForm.value.hasPod;
    this.claimService.claimFilters.settlement = this.filterForm.value.settlement;
    this.claimService.claimFilters.hasRemittance = this.filterForm.value.hasRemittance;
    this.claimService.claimFilters.po = this.filterForm.value.po;
    this.claimService.claimFilters.pageNumber = null;
    this.claimService.claimFilters.pageSize = null;
    this.claimService.claimFilters.sortBy = null;
    this.claimService.claimFilters.sortDirection = null;
  }

  updateFiltersMap() {
    this.filtersMap = claimFiltersMap(
      this.filterForm.value.invoiceNumber,
      this.filterForm.value.invoiceDate,
      this.filterForm.value.suffix,
      this.filterForm.value.amount,
      this.filterForm.value.vendor,
      this.filterForm.value.supplier,
      this.filterForm.value.carrierContracts,
      this.filterForm.value.trackingNumber,
      this.filterForm.value.status,
      this.filterForm.value.disputeId,
      this.filterForm.value.caseId,
      this.filterForm.value.deliveryDate,
      this.filterForm.value.hasPod,
      this.filterForm.value.hasRemittance,
      this.filterForm.value.po,
      this.filterForm.value.settlement
    );
  }
}
