<app-supplier-products-filters (onFilterEvent)="onFilter($event)"></app-supplier-products-filters>

<div class="supplierDetails container">
  <div class="row margin-top-20">
    <div class="col-md-10" style="padding: 0;">
      <h3 class="big-title">{{ supplier.name }}</h3>
    </div>
    <div class="col-md-2 text-right">
      <button class="btn-custom" (click)="goBack()">Back</button>
    </div>
  </div>

  <div class="row padding-top-20">
    <div class="col-md-3 background-blue text-white padding-bottom-50 details-box">
      <p><b>Phone:</b> {{ supplier.phone }}</p>
    </div>
    <div class="col-md-3 background-blue text-white padding-bottom-50 details-box">
      <p><b>Minimal Value:</b> {{ supplier.minimalValue | myCurrency }}</p>
    </div>
    <div class="col-md-3 background-light-blue text-white padding-bottom-50 details-box">
      <p><b>Ships To Amazon:</b> <i class="margin-left-15" [ngClass]="supplier.shipsToAmazon | booleanIconPipe" aria-hidden="true"></i></p>
    </div>
  </div>
</div>

<hr />

<mat-toolbar class="bulkActionsBar">
  <div class="container">
    <div class="row">
      <div class="col-md-2">
        <button [disabled]="!bulkActionsActive" class="box-shadow-none btn-custom" (click)="delete()">Delete</button>
      </div>

      <div class="col-xs-2">
        <button
          *ngIf="hasCatalogUpdate | async"
          [disabled]="!bulkActionsActive"
          [matMenuTriggerFor]="changeStatusMenu"
          class="box-shadow-none btn-custom"
        >
          Change Status
        </button>
        <mat-menu #changeStatusMenu="matMenu">
          <button mat-menu-item (click)="changeCatalogItemStatus('Active')">Active</button>
          <button mat-menu-item (click)="changeCatalogItemStatus('Disabled')">Disabled</button>
        </mat-menu>
      </div>

      <div class="col-xs-2">
        <div class="col-md-2">
          <button class="btn-custom" (click)="getSupplierDiscount(supplier.id)">Supplier Discount</button>
        </div>
      </div>
    </div>
  </div>
</mat-toolbar>

<div class="container table-outer">
  <mat-paginator #paginator [length]="resultsNumber | async" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()">
  </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="check">
        <mat-header-cell *matHeaderCellDef class="checker-col">
          <mat-checkbox (change)="toggleAll($event.checked)" [ngModel]="mainChecker"></mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let ci; let i = index" class="checker-col">
          <mat-checkbox (change)="onSelected($event.checked, ci)" [ngModel]="ci.checked"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ean">
        <mat-header-cell *matHeaderCellDef mat-sort-header="ean" class="ean-column"> EAN </mat-header-cell>
        <mat-cell *matCellDef="let ci" class="ean-column"> {{ ci.product.ean }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="sku">
        <mat-header-cell *matHeaderCellDef mat-sort-header="sku" class="sku-column"> SKU </mat-header-cell>
        <mat-cell class="sku-column" *matCellDef="let ci" [matTooltip]="ci.sku" matTooltipPosition="below">
          <p>{{ ci.sku }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="title">
        <mat-header-cell class="title-column" *matHeaderCellDef mat-sort-header="title"> Title </mat-header-cell>
        <mat-cell class="title-column" *matCellDef="let ci" [matTooltip]="ci.product.title" matTooltipPosition="below">
          <p>{{ ci.product.title }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="brand">
        <mat-header-cell class="category-column" *matHeaderCellDef mat-sort-header="brand"> Brand </mat-header-cell>
        <mat-cell class="category-column" *matCellDef="let ci">
          <p>{{ ci.product.brand ? ci.product.brand.name : '' }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="category">
        <mat-header-cell class="category-column" *matHeaderCellDef mat-sort-header="category"> Category </mat-header-cell>
        <mat-cell class="category-column" *matCellDef="let ci">
          <p>{{ ci.product.category ? ci.product.category.name : '' }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="season">
        <mat-header-cell class="category-column" *matHeaderCellDef mat-sort-header="season"> Season </mat-header-cell>
        <mat-cell class="category-column" *matCellDef="let ci">
          <p>{{ ci.product.season ? ci.product.season.period : '' }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="size">
        <mat-header-cell class="color-code-column" *matHeaderCellDef mat-sort-header="size"> Size </mat-header-cell>
        <mat-cell class="color-code-column" *matCellDef="let ci"> {{ ci.product.size }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="colorCode">
        <mat-header-cell class="color-code-column" *matHeaderCellDef mat-sort-header="colorCode"> Color Code </mat-header-cell>
        <mat-cell class="color-code-column" *matCellDef="let ci"> {{ ci.product.colorCode }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="color">
        <mat-header-cell class="category-column" *matHeaderCellDef mat-sort-header="color"> Color </mat-header-cell>
        <mat-cell class="category-column" *matCellDef="let ci">
          <p>{{ ci.product.color }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="wholesalePrice">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="wholesalePrice"> Wholesale Price </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let ci">
          <p>{{ ci.product.wholesalePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="retailPrice">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="retailPrice"> Retail Price </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let ci">
          <p>{{ ci.product.retailPrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="physicalQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="physicalQuantity">
          Physical <br />
          quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let ci">
          <p>{{ ci.physicalQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="availableQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="availableQuantity">
          Available <br />
          quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let ci">
          <p>{{ ci.availableQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="purchasePrice">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="purchasePrice"> Purchase Price </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let ci">
          <p>{{ ci.purchasePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="updatedAt">
        <mat-header-cell class="date-time-column" *matHeaderCellDef mat-sort-header="updatedAt">Updated at </mat-header-cell>
        <mat-cell class="date-time-column" *matCellDef="let ci">
          <p>{{ ci.updatedAt }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell class="shipment-status-column" *matHeaderCellDef mat-sort-header="status">Status </mat-header-cell>
        <mat-cell class="shipment-status-column" *matCellDef="let ci">
          <p>{{ ci.status }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="countryOfOrigin">
        <mat-header-cell *matHeaderCellDef class="code-column"> Country </mat-header-cell>
        <mat-cell class="code-column" *matCellDef="let row" matTooltipPosition="below">
          <p>{{ row?.countryOfOrigin }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="unNumber">
        <mat-header-cell *matHeaderCellDef class="code-column"> UN Number </mat-header-cell>
        <mat-cell class="code-column" *matCellDef="let row" matTooltipPosition="below">
          <p>{{ row?.unNumber }}</p>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
