import { Component, OnDestroy } from '@angular/core';
import { InventoryService } from '../inventory.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.css'],
})
export class SuppliersComponent implements OnDestroy {
  constructor(private inventoryService: InventoryService) {}

  ngOnDestroy() {
    if (environment.envName !== 'mock') {
      this.inventoryService.suppliers = [];
      this.inventoryService.supplierFilters = null;
    }
  }
}
