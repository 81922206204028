import { Component, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { isNilty } from '../../../../core/utils.service';
import { Contract } from '../../../../models/contract.model';
import { createFormGroup, shipFiltersMap, ShipmentFilters } from '../../../../models/filters/shipment-filters-model';
import { Lot } from '../../../../models/lot-model';
import { MatChipsData } from '../../../../models/mat-chips-data-model';
import { Status } from '../../../../models/status-model';
import { StockLocation } from '../../../../models/stock-location-model';
import { Supplier } from '../../../../models/supplier-model';
import { Vendor } from '../../../../models/vendor-model';
import { Warehouse } from '../../../../models/warehouse-model';
import { LotsService } from '../../../../shared/lots.service';
import { ShipmentsService } from '../../../../shared/shipments.service';
import { VendorsService } from '../../../configuration/vendors/vendors.service';
import { WarehousesService } from '../../../configuration/warehouses/warehouses.service';
import { InventoryService } from '../../../inventory/inventory.service';
import { ShipmentStatus } from '../../../../models/shipment-model';
import { CommonsService } from '../../../../shared/commons.service';
import { Category } from '../../../../models/category-model';

@Component({
  selector: 'app-main-shipments-list-filters',
  templateUrl: './main-shipments-list-filters.component.html',
  styleUrls: ['./main-shipments-list-filters.component.css'],
})
export class MainShipmentsListFiltersComponent implements OnInit {
  constructor(
    private vendorsService: VendorsService,
    private warehousesService: WarehousesService,
    private inventoryService: InventoryService,
    private shipmentsService: ShipmentsService,
    private lotService: LotsService,
    private commonsService: CommonsService
  ) {}

  suppliers: Supplier[] = [];

  stockLocations: StockLocation[] = [];

  warehouses: Warehouse[] = [];

  vendors: Vendor[] = [];

  categories: Category[] = [];

  lotsList: Lot[] = [];
  lots: Subject<Lot[]> = new Subject<Lot[]>();

  @Output() onFilter = new Subject<ShipmentFilters>();

  filterForm: FormGroup;

  public openFilter = false;

  filtersMap: MatChipsData[] = [];

  shipmentsStatus: ShipmentStatus[];

  paymentStatus: Status[];
  ukIntercoInvoiceStatus: Status[];

  outboundStatus: string;

  carrierContracts: Contract[];

  ngOnInit() {
    this.commonsService.getShipmentStatuses().subscribe((r) => (this.shipmentsStatus = r));

    this.filterForm = createFormGroup();

    if (this.shipmentsService.shipmentFilters !== undefined) {
      this.filterForm.patchValue(this.shipmentsService.shipmentFilters);
      this.setPickupDates();
      this.updateFiltersMap();
      this.setLot();
    }

    this.vendorsService.getVendors().subscribe((vendorsResp: Vendor[]) => {
      this.vendors = vendorsResp;
      this.setVendor();
    });

    this.warehousesService.getWarehouses().subscribe((warehouses: Warehouse[]) => {
      this.warehouses = warehouses;
      this.setDestination();
    });

    this.inventoryService.getAllStockLocations().subscribe((sl: StockLocation[]) => {
      this.stockLocations = sl;
      this.setOrigin();
    });

    this.inventoryService.getAllSuppliers().subscribe((s: Supplier[]) => {
      this.suppliers = s;
      this.setSupplier();
    });

    this.shipmentsService.getShipmentPaymentStatus().subscribe((s: Status[]) => {
      this.paymentStatus = s;
      this.setPaymentStatus();
    });

    this.shipmentsService.getIntercoInvoiceStatus().subscribe((s: Status[]) => {
      this.ukIntercoInvoiceStatus = s;
      this.setIntercoInvoiceStatus();
    });

    this.shipmentsService.getAllContracts().subscribe((c: Contract[]) => {
      this.carrierContracts = c;
      this.setCarrierContract();
    });

    this.shipmentsService.getOutboundStatus().subscribe((s: string) => {
      this.outboundStatus = s;
    });

    this.shipmentsService.getAllCategories().subscribe((categories: Supplier[]) => {
      this.categories = categories;
      this.setCategory();
    });
  }

  setPickupDates() {
    if (
      !isNilty(this.shipmentsService.shipmentFilters) &&
      !isNilty(this.shipmentsService.shipmentFilters.latestPickupDateFrom) &&
      !isNilty(this.shipmentsService.shipmentFilters.latestPickupDateTo)
    ) {
      this.filterForm.controls['latestPickupDateFrom'].patchValue(this.shipmentsService.shipmentFilters.latestPickupDateFrom);
      this.filterForm.controls['latestPickupDateTo'].patchValue(this.shipmentsService.shipmentFilters.latestPickupDateTo);
      this.updateFiltersMap();
    }
  }

  setVendor() {
    if (!isNilty(this.shipmentsService.shipmentFilters) && !isNilty(this.shipmentsService.shipmentFilters.vendorId)) {
      this.filterForm.controls['vendor'].patchValue(this.vendors.find((it) => it.id === this.shipmentsService.shipmentFilters.vendorId));
      this.updateFiltersMap();
    }
  }

  setCategory() {
    if (!isNilty(this.shipmentsService.shipmentFilters) && !isNilty(this.shipmentsService.shipmentFilters.categoryId)) {
      this.filterForm.controls['category'].patchValue(
        this.categories.find((it) => it.id === this.shipmentsService.shipmentFilters.categoryId)
      );
      this.updateFiltersMap();
    }
  }

  setDestination() {
    if (!isNilty(this.shipmentsService.shipmentFilters) && !isNilty(this.shipmentsService.shipmentFilters.destinationId)) {
      this.filterForm.controls['destination'].patchValue(
        this.warehouses.find((it) => it.id === this.shipmentsService.shipmentFilters.destinationId)
      );
      this.updateFiltersMap();
    }
  }

  setOrigin() {
    if (!isNilty(this.shipmentsService.shipmentFilters) && !isNilty(this.shipmentsService.shipmentFilters.originId)) {
      this.filterForm.controls['origin'].patchValue(
        this.stockLocations.find((it) => it.id === this.shipmentsService.shipmentFilters.originId)
      );
      this.updateFiltersMap();
    }
  }

  setSupplier() {
    if (!isNilty(this.shipmentsService.shipmentFilters) && !isNilty(this.shipmentsService.shipmentFilters.supplierId)) {
      this.filterForm.controls['supplier'].patchValue(
        this.suppliers.find((it) => it.id === this.shipmentsService.shipmentFilters.supplierId)
      );
      this.updateFiltersMap();
    }
  }

  setCarrierContract() {
    if (!isNilty(this.shipmentsService.shipmentFilters) && !isNilty(this.shipmentsService.shipmentFilters.supplierId)) {
      this.filterForm.controls['carrierContracts'].patchValue(
        this.carrierContracts.filter((it) => this.shipmentsService.shipmentFilters.carrierContracts.some((c) => c === it.id))
      );
      this.updateFiltersMap();
    }
  }

  setLot() {
    if (!isNilty(this.shipmentsService.shipmentFilters.lotId)) {
      this.lotService.searchByName(this.shipmentsService.shipmentFilters.lotId.name).subscribe((l) => {
        this.lots.next(l);
        this.lotsList = l;
        this.filterForm.controls['lot'].patchValue(
          this.lotsList.find((it) => it.name === this.shipmentsService.shipmentFilters.lotId.name)
        );
        this.updateFiltersMap();
      });
    }
  }

  setPaymentStatus() {
    if (!isNilty(this.shipmentsService.shipmentFilters) && !isNilty(this.shipmentsService.shipmentFilters.paymentStatus)) {
      this.filterForm.controls['paymentStatus'].patchValue(
        this.paymentStatus.find((it) => it.code === this.shipmentsService.shipmentFilters.paymentStatus)
      );
      this.updateFiltersMap();
    }
  }

  setIntercoInvoiceStatus() {
    if (!isNilty(this.shipmentsService.shipmentFilters) && !isNilty(this.shipmentsService.shipmentFilters.ukIntercoInvoiceStatus)) {
      this.filterForm.controls['ukIntercoInvoiceStatus'].patchValue(
        this.ukIntercoInvoiceStatus.find((it) => it.code === this.shipmentsService.shipmentFilters.ukIntercoInvoiceStatus)
      );
      this.updateFiltersMap();
    }
  }

  saveFiltersToService() {
    this.shipmentsService.shipmentFilters = new ShipmentFilters();
    this.shipmentsService.shipmentFilters.shipment = this.filterForm.value.shipment;
    this.shipmentsService.shipmentFilters.asnCode = this.filterForm.value.asnCode;
    this.shipmentsService.shipmentFilters.ean = this.filterForm.value.ean;
    this.shipmentsService.shipmentFilters.originId = isNilty(this.filterForm.value.origin) ? null : this.filterForm.value.origin.id;
    this.shipmentsService.shipmentFilters.supplierId = isNilty(this.filterForm.value.supplier) ? null : this.filterForm.value.supplier.id;
    this.shipmentsService.shipmentFilters.po = isNilty(this.filterForm.value.po) ? null : this.filterForm.value.po.split('\n');
    this.shipmentsService.shipmentFilters.vendorId = this.filterForm.value.vendor ? this.filterForm.value.vendor.id : null;
    this.shipmentsService.shipmentFilters.destinationId = isNilty(this.filterForm.value.destination)
      ? null
      : this.filterForm.value.destination.id;
    this.shipmentsService.shipmentFilters.status = this.filterForm.value.status;
    this.shipmentsService.shipmentFilters.paymentStatus = isNilty(this.filterForm.value.paymentStatus)
      ? null
      : this.filterForm.value.paymentStatus.code;
    this.shipmentsService.shipmentFilters.lotId = isNilty(this.filterForm.value.lot) ? null : this.filterForm.value.lot.id;
    this.shipmentsService.shipmentFilters.lotFrom = this.filterForm.value.lotDateFrom;
    this.shipmentsService.shipmentFilters.lotTo = this.filterForm.value.lotDateTo;
    this.shipmentsService.shipmentFilters.orderIds = isNilty(this.filterForm.value.orderIds)
      ? null
      : this.filterForm.value.orderIds.split('\n');
    this.shipmentsService.shipmentFilters.carrierContracts = isNilty(this.filterForm.value.carrierContracts)
      ? null
      : this.filterForm.value.carrierContracts.map((c) => c.id);
    this.shipmentsService.shipmentFilters.trackingNumber = this.filterForm.value.trackingNumber;
    this.shipmentsService.shipmentFilters.latestPickupDateFrom = this.filterForm.value.latestPickupDateFrom;
    this.shipmentsService.shipmentFilters.latestPickupDateTo = this.filterForm.value.latestPickupDateTo;
    this.shipmentsService.shipmentFilters.noInvoice = this.filterForm.value.noInvoice;
    this.shipmentsService.shipmentFilters.crossUkBorder = this.filterForm.value.crossUkBorder;
    this.shipmentsService.shipmentFilters.ukIntercoInvoice = this.filterForm.value.ukIntercoInvoice;
    this.shipmentsService.shipmentFilters.ukIntercoInvoiceValidData = this.filterForm.value.ukIntercoInvoiceValidData;
    this.shipmentsService.shipmentFilters.ukIntercoInvoiceStatus = this.filterForm.value.ukIntercoInvoiceStatus;
    this.shipmentsService.shipmentFilters.isAdr = this.filterForm.value.isAdr;
    this.shipmentsService.shipmentFilters.documentsSentToSupplier = this.filterForm.value.documentsSentToSupplier;
    this.shipmentsService.shipmentFilters.categoryId = this.filterForm.value.category ? this.filterForm.value.category.id : null;
    this.shipmentsService.shipmentFilters.outboundStatus = this.filterForm.value.outboundStatus;
    this.shipmentsService.shipmentFilters.shippedAtFrom = this.filterForm.value.shippedAtFrom;
    this.shipmentsService.shipmentFilters.shippedAtTo = this.filterForm.value.shippedAtTo;
    this.shipmentsService.shipmentFilters.pageNumber = null;
    this.shipmentsService.shipmentFilters.pageSize = null;
    this.shipmentsService.shipmentFilters.sortDirection = null;
    this.shipmentsService.shipmentFilters.sortBy = null;
    this.shipmentsService.shipmentFilters.payment = null;
  }

  onSubmitFilter() {
    this.saveFiltersToService();
    this.updateFiltersMap();
    this.onFilter.next(this.shipmentsService.shipmentFilters);
  }

  resetFilters() {
    this.filterForm.reset();
    this.saveFiltersToService();
    this.updateFiltersMap();
    this.onFilter.next(new ShipmentFilters());
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeevent() {
    this.openFilter = false;
  }

  updateFiltersMap() {
    this.filtersMap = shipFiltersMap(
      this.filterForm.value.shipment,
      this.filterForm.value.asnCode,
      this.filterForm.value.ean,
      this.filterForm.value.origin,
      this.filterForm.value.supplier,
      this.filterForm.value.po?.split(','),
      this.filterForm.value.vendor,
      this.filterForm.value.destinationId,
      this.filterForm.value.status,
      this.filterForm.value.paymentStatus,
      this.shipmentsService.shipmentFilters.lotId,
      this.shipmentsService.shipmentFilters.lotFrom,
      this.shipmentsService.shipmentFilters.lotTo,
      null,
      null,
      this.filterForm.value.orderIds?.split(','),
      this.filterForm.value.carrierContracts,
      this.filterForm.value.trackingNumber,
      this.filterForm.value.latestPickupDateFrom,
      this.filterForm.value.latestPickupDateTo,
      this.filterForm.value.noInvoice,
      this.filterForm.value.crossUkBorder,
      this.filterForm.value.ukIntercoInvoice,
      this.filterForm.value.ukIntercoInvoiceValidData,
      this.filterForm.value.ukIntercoInvoiceStatus,
      this.filterForm.value.isAdr,
      this.filterForm.value.documentsSentToSupplier,
      this.filterForm.value.category,
      this.filterForm.value.outboundStatus,
      this.filterForm.value.shippedAtFrom,
      this.filterForm.value.shippedAtTo
    );
  }

  filterLots(filterString: { filterText: string; filterKey: string }) {
    this.lotService.searchByName(filterString.filterText).subscribe((resp: Lot[]) => {
      this.lots.next(resp);
      this.lotsList = resp;
    });
  }
}
