import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConverterService } from '../core/converter.service';
import { isNilty } from '../core/utils.service';
import { EnvironmentService } from '../environment.service';
import { FulfilmentFilters, fulfilmentsFiltersToPath } from '../models/filters/fulfilment-filters-model';
import { PoFilters } from '../models/filters/po-filters-model';
import { SpoFilters } from '../models/filters/spo-filters-model';
import { SupplierAvailabilityFilters } from '../models/filters/suppliers-availability-filters-model';
import { ShipmentFilters } from '@models/filters/shipment-filters-model';
import { PublicationFilters } from '@models/filters/publication-filters-model';

@Injectable()
export class ImportExportService {
  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private converter: ConverterService,
    private environmentService: EnvironmentService
  ) {}

  exportPos(posIds: number[], format: string, mainChecker: boolean, filters: PoFilters): Observable<string> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');

    if (mainChecker) {
      const path = this.environmentService.getRestEndpoint('excelExportFilteredPos') + '?format=' + format;
      const body = this.converter.fromObjtoJSON(filters);

      return this.http.post(path, body, { headers }).pipe(
        map(() => {
          this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
          return 'Done';
        })
      );
    } else {
      let path = this.environmentService.getRestEndpoint('excelExportPos') + '?format=' + format;
      const body = JSON.stringify(posIds);

      if (this.environmentService.dev) {
        path = this.environmentService.getRestEndpoint('excelExportPos');
      }

      return this.http.post(path, body, { headers }).pipe(
        map(() => {
          this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
          return 'Done';
        })
      );
    }
  }

  exportShipments(selectedShipments: number[], format: string, mainChecker: boolean, filters: ShipmentFilters, onlyHeaders: boolean) {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');

    if (mainChecker) {
      const body = this.converter.fromObjtoJSON(filters);
      const endpoint = onlyHeaders ? 'excelExportFilteredShipmentHeaders' : 'excelExportFilteredShipments';
      const path = this.environmentService.getRestEndpoint(endpoint) + '?format=' + format;

      return this.http.post(path, body, { observe: 'response' }).pipe(
        map(() => {
          this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
          return 'Done';
        })
      );
    } else {
      const body = JSON.stringify(selectedShipments);
      const endpoint = onlyHeaders ? 'excelExportShipmentHeaders' : 'excelExportShipments';
      const path = this.environmentService.getRestEndpoint(endpoint) + '?format=' + format;

      return this.http.post(path, body, { headers }).pipe(
        map(() => {
          this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
          return 'Done';
        })
      );
    }
  }

  exportShipmentItems(selectedIds: number[], format: string, mainChecker: boolean, filters: ShipmentFilters) {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');

    if (mainChecker) {
      const body = this.converter.fromObjtoJSON(filters);
      const path = this.environmentService.getRestEndpoint('excelExportFilteredShipmentItems') + '?format=' + format;

      return this.http.post(path, body, { observe: 'response' }).pipe(
        map(() => {
          this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
          return 'Done';
        })
      );
    } else {
      const body = JSON.stringify(selectedIds);
      const path = this.environmentService.getRestEndpoint('excelExportShipmentItems') + '?format=' + format;

      return this.http.post(path, body, { headers }).pipe(
        map(() => {
          this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
          return 'Done';
        })
      );
    }
  }

  exportCartonItems(shipmentId: number, format: string): Observable<any> {
    const path = isDevMode()
      ? this.environmentService.getRestEndpoint('excelExportCartonItems')
      : this.environmentService.getRestEndpoint('excelExportCartonItems') + '?format=' + format;
    return this.callExportCartons(path, shipmentId);
  }
  exportCartons(shipmentId: number, format: string): Observable<any> {
    const path = isDevMode()
      ? this.environmentService.getRestEndpoint('excelExportCartons')
      : this.environmentService.getRestEndpoint('excelExportCartons') + '?format=' + format;
    return this.callExportCartons(path, shipmentId);
  }
  private callExportCartons(path: string, shipmentId: number) {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(shipmentId);
    return this.http.post(path, body, { headers }).pipe(
      map(() => {
        this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
      })
    );
  }

  exportPoItemsToExcel(posItemsIds: number[], format: string, mainChecker: boolean, filters: PoFilters): Observable<string> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');

    if (mainChecker) {
      const path = this.environmentService.getRestEndpoint('excelExportFilteredPoItems') + '?format=' + format;
      const body = this.converter.fromObjtoJSON(filters);

      return this.http.post(path, body, { headers }).pipe(
        map(() => {
          this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
          return 'Done';
        })
      );
    } else {
      let path = this.environmentService.getRestEndpoint('excelExportPoItems') + '?format=' + format;
      const body = JSON.stringify(posItemsIds);

      if (this.environmentService.dev) {
        path = this.environmentService.getRestEndpoint('excelExportPoItems');
      }

      return this.http.post(path, body, { headers }).pipe(
        map(() => {
          this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
          return 'Done';
        })
      );
    }
  }

  exportLsas(lsasIds: number[], format: string, filters: SupplierAvailabilityFilters, mainChecker: boolean): Observable<string> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    let body;
    let path: string;
    if (mainChecker) {
      const input = new SupplierAvailabilityFilters().copy(filters);
      input.excludedIds = isNilty(lsasIds) ? [] : lsasIds;
      input.pageSize = undefined;
      input.pageNumber = undefined;
      input.sortBy = undefined;
      input.sortDirection = undefined;
      body = this.converter.fromObjtoJSON(input);
      path = this.environmentService.getRestEndpoint('excelExportFilteredLsas');
    } else {
      body = lsasIds;
      path = this.environmentService.getRestEndpoint('excelExportLsas');
    }

    path += '?format=' + format;

    return this.http.post(path, body, { headers }).pipe(
      map(() => {
        this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
        return 'Done';
      })
    );
  }

  exportFulfilment(
    fulfilmentIds: number[],
    format: string,
    filters: FulfilmentFilters,
    mainChecker: boolean,
    currentLotId: number
  ): Observable<string> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(fulfilmentIds);

    if (mainChecker) {
      const path =
        this.environmentService.getRestEndpoint('exportFilteredFulfilment') +
        fulfilmentsFiltersToPath(filters, currentLotId) +
        '&format=' +
        format;

      return this.http.post(path, body, { headers }).pipe(
        map(() => {
          this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
          return 'Done';
        })
      );
    } else {
      let path = this.environmentService.getRestEndpoint('exportFulfilment') + '?format=' + format;

      if (this.environmentService.dev) {
        path = this.environmentService.getRestEndpoint('exportFulfilment');
      }

      return this.http.post(path, body, { headers }).pipe(
        map(() => {
          this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
          return 'Done';
        })
      );
    }
  }

  exportSpoItems(spoIds: number[], format: string, filters: SpoFilters, mainChecker: boolean, currentLotId: number): Observable<string> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    let path: string;
    let body: string;
    if (mainChecker) {
      filters.listOfGroupIds = spoIds;
      filters.lotId = currentLotId;
      body = this.converter.fromObjtoJSON(filters);
      path = isDevMode()
        ? this.environmentService.getRestEndpoint('exportFilteredSpoItems')
        : this.environmentService.getRestEndpoint('exportFilteredSpoItems') + '?format=' + format;
    } else {
      body = JSON.stringify(spoIds);
      path = isDevMode()
        ? this.environmentService.getRestEndpoint('exportSpoItems')
        : this.environmentService.getRestEndpoint('exportSpoItems') + '?format=' + format;
    }

    return this.http.post(path, body, { headers }).pipe(
      map(() => {
        this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
        return 'Done';
      })
    );
  }
  exportSpos(spoIds: number[], format: string, filters: SpoFilters, mainChecker: boolean, currentLotId: number): Observable<string> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    let path: string;
    let body: string;
    if (mainChecker) {
      filters.listOfIds = spoIds;
      filters.lotId = currentLotId;
      body = this.converter.fromObjtoJSON(filters);
      path = isDevMode()
        ? this.environmentService.getRestEndpoint('exportFilteredSpos')
        : this.environmentService.getRestEndpoint('exportFilteredSpos') + '?format=' + format;
    } else {
      body = JSON.stringify(spoIds);
      path = isDevMode()
        ? this.environmentService.getRestEndpoint('exportSpos')
        : this.environmentService.getRestEndpoint('exportSpos') + '?format=' + format;
    }
    return this.http.post(path, body, { headers }).pipe(
      map(() => {
        this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
        return 'Done';
      })
    );
  }

  generateDdt(shipmentId: number, format: string) {
    let path = this.environmentService.getRestEndpoint('generateDdt');

    if (!this.environmentService.dev) {
      path += '?format=' + format;
    }

    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(shipmentId);

    return this.http.post(path, body, { headers }).pipe(
      map(() => {
        this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
        return 'Done';
      })
    );
  }

  exportDdt(shipmentId: number, format: string) {
    let path = this.environmentService.getRestEndpoint('exportDdt');

    if (!this.environmentService.dev) {
      path += '?format=' + format;
    }

    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(shipmentId);

    return this.http.post(path, body, { headers }).pipe(
      map(() => {
        this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
        return 'Done';
      })
    );
  }

  uploadFile(file: File, entity: string, lotId?: number, shipmentId?: number): Observable<void> {
    const formData = new FormData();
    formData.append('file', file);

    const path =
      this.environmentService.getRestEndpoint('uploadFile') +
      '?entity=' +
      entity +
      (isNilty(lotId) ? '' : '&lotId=' + lotId) +
      (isNilty(shipmentId) ? '' : '&shipmentId=' + shipmentId);

    return this.http.post(path, formData).pipe(
      map(() => {
        this.snackBar.open('File uploaded. ', 'CLOSE')._dismissAfter(3000);
      })
    );
  }

  exportClaimDocumentation(claimHeaderId: number): Observable<any> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(claimHeaderId);

    return this.http.post(this.environmentService.getRestEndpoint('exportClaimDocumentation'), body, { headers }).pipe(
      map(() => {
        this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
        return 'Done';
      })
    );
  }

  exportInvoice(invoiceNumber: string): Observable<any> {
    const headers = new HttpHeaders().append('Accept', 'application/pdf');
    const body = invoiceNumber;

    return this.http.post(this.environmentService.getRestEndpoint('exportInvoice'), body, { headers, responseType: 'blob' }).pipe(
      map((res: Blob) => {
        saveAs(res, 'invoice-' + invoiceNumber + '.pdf');

        this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
        return 'Done';
      })
    );
  }

  exportInvoiceXml(invoiceNumber: string): Observable<any> {
    const headers = new HttpHeaders().append('Accept', 'application/xml');
    const body = invoiceNumber;

    return this.http
      .post(this.environmentService.getRestEndpoint('exportInvoice') + '?exportInvoiceXml=true', body, { headers, responseType: 'blob' })
      .pipe(
        map((res: Blob) => {
          saveAs(res, 'invoice-' + invoiceNumber + '.xml');

          this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
          return 'Done';
        })
      );
  }

  exportIntercoInvoice(invoiceNumber: string): Observable<any> {
    const headers = new HttpHeaders().append('Accept', 'application/pdf');
    const body = invoiceNumber;

    return this.http.post(this.environmentService.getRestEndpoint('exportIntercoInvoice'), body, { headers, responseType: 'blob' }).pipe(
      map((res: Blob) => {
        saveAs(res, 'interco-invoice-' + invoiceNumber + '.pdf');

        this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
        return 'Done';
      })
    );
  }

  exportExportDeclaration(invoiceNumber: string): Observable<any> {
    const headers = new HttpHeaders().append('Accept', 'application/pdf');
    const body = invoiceNumber;

    return this.http.post(this.environmentService.getRestEndpoint('exportExportDeclaration'), body, { headers, responseType: 'blob' }).pipe(
      map((res: Blob) => {
        saveAs(res, 'export-declaration-' + invoiceNumber + '.pdf');

        this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
        return 'Done';
      })
    );
  }

  exportPendingApprovaPublications(
    selectedPublications: number[],
    format: string,
    mainChecker: boolean,
    filters: PublicationFilters
  ): Observable<any> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');

    if (mainChecker) {
      const body = this.converter.fromObjtoJSON(filters);
      const path = this.environmentService.getRestEndpoint('exportFilteredPendingApprovalPublication') + '?format=' + format;

      return this.http.post(path, body, { observe: 'response' }).pipe(
        map(() => {
          this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
          return 'Done';
        })
      );
    } else {
      const body = JSON.stringify(selectedPublications);
      const path = this.environmentService.getRestEndpoint('exportPendingApprovalPublication') + '?format=' + format;

      return this.http.post(path, body, { headers }).pipe(
        map(() => {
          this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
          return 'Done';
        })
      );
    }
  }
}
