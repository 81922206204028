import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConverterService } from '../../../core/converter.service';
import { Category } from '../../../models/category-model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../../../environment.service';

@Injectable()
export class CategoriesService {
  categories: Category[];

  resultsNumber = new Subject<number>();

  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  getCategories(name: string, pageSize: number, pageNumber: number): Observable<Category[]> {
    let path =
      this.environmentService.getRestEndpoint('categories') +
      '?name=' +
      (name ? name : '') +
      '&page-size=' +
      pageSize +
      '&page-number=' +
      pageNumber;

    if (this.environmentService.dev) {
      path = this.environmentService.getRestEndpoint('categories');
    }

    return this.http.get(path, { observe: 'response' }).pipe(
      map((resp) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((resp: Category[]) => {
        this.categories = [];
        for (let i = 0; i < resp.length; i++) {
          this.categories[i] = this.converter.fromJSONtoObj(resp[i], Category);
        }
        return this.categories;
      })
    );
  }

  newCategory(newCategory: string): Observable<any> {
    const c = new Category();
    c.id = -1;
    c.name = newCategory;
    return this.http.post(this.environmentService.getRestEndpoint('categories'), c);
  }

  editCategory(c: Category): Observable<any> {
    return this.http.post(this.environmentService.getRestEndpoint('categories'), c);
  }

  deleteCategory(c: Category): Observable<any> {
    let path = this.environmentService.getRestEndpoint('categories') + '/' + c.id;
    if (this.environmentService.dev) {
      path = this.environmentService.getRestEndpoint('categories');
    }
    return this.http.delete(path);
  }
}
