<div class="container">
  <div class="row padding-top-20">
    <div class="col">
      <legend>Brandon Closing Days</legend>

      <div class="row">
        <div class="col-md-2">
          <button class="btn-custom" (click)="saveClosingDays()">
            <i class="fa fa-floppy-o margin-right-6" aria-hidden="true"></i>Save
          </button>
        </div>
        <div class="col-md-2">
          <button class="btn-custom" (click)="resetClosingDays()">
            <i class="fa fa-refresh margin-right-6" aria-hidden="true"></i>Reset
          </button>
        </div>
      </div>

      <hr />

      <div class="row margin-left-0">
        <div class="closing-days-col border-closing-day-col" *ngFor="let closingRange of brandonClosingDates; let index = index">
          <button matPrefix class="actions-button btn-fixed margin-right-10 margin-top-16" (click)="removeClosingDays(index)">
            <i class="fa fa-times margin-right-6" aria-hidden="true"></i>
          </button>

          <mat-form-field>
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate [(ngModel)]="closingRange.begin" placeholder="Start date" />
              <input matEndDate [(ngModel)]="closingRange.end" placeholder="End date" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>

        <div class="closing-days-col text-left">
          <button class="btn-custom" (click)="addNewClosingRange()">
            <i class="fa fa-plus margin-right-6" aria-hidden="true"></i> Add new range
          </button>
        </div>
      </div>
    </div>
  </div>

  <hr />

  <div class="row padding-top-20">
    <div class="col">
      <legend>Country Holidays</legend>

      <div class="box-shadow-none margin-user mat-elevation-z8" id="table-countries">
        <mat-accordion multi="true">
          <mat-expansion-panel class="country-panel" hideToggle="true" *ngFor="let ch of countryHolidaysArray; let i = index">
            <mat-expansion-panel-header class="panel-header">
              <mat-panel-title class="panel-label">
                <div class="row" class="width-100" *ngIf="ch.id">
                  <div class="col-md-4"><b>Country:</b> {{ ch.countryName }}</div>
                  <div class="col-md-4 panel-label"><b>Country Code:</b> {{ ch.countryCode }}</div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row padding-top-20">
              <div class="col-md-12">
                <textarea rows="4" class="width-100" matInput type="text" [(ngModel)]="ch.holidaysString"></textarea>
              </div>
            </div>

            <mat-action-row class="panel-actions">
              <button class="btn-custom" (click)="save(ch)">Save</button>
            </mat-action-row>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>
