<mat-toolbar class="bar-custom">
  <div class="container">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <button class="btn-custom margin-right-15" [routerLink]="['new']">New Rule</button>
      </div>
    </div>
  </div>
</mat-toolbar>

<div class="container table-outer">
  <div class="col-md-2"></div>

  <div class="col-md-8">
    <div class="table-header">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search" />
      </mat-form-field>
    </div>

    <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]"> </mat-paginator>

    <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
      <mat-table #listingTable [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header="type"> Type </mat-header-cell>
          <mat-cell *matCellDef="let publicationRule"> {{ publicationRule.type }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header="status"> Status </mat-header-cell>
          <mat-cell *matCellDef="let publicationRule"> {{ publicationRule.rule.statusDescription }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="childId">
          <mat-header-cell *matHeaderCellDef mat-sort-header="childId"> ChildCode </mat-header-cell>
          <mat-cell *matCellDef="let publicationRule"> {{ publicationRule.rule.childCode }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="strategy">
          <mat-header-cell *matHeaderCellDef mat-sort-header="strategy"> Strategy </mat-header-cell>
          <mat-cell *matCellDef="let publicationRule">
            {{ publicationRule.rule.strategy ? publicationRule.rule.strategy : 'Markup' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="qtyThreshold">
          <mat-header-cell *matHeaderCellDef mat-sort-header="qtyThreshold"> Quantity Threshold </mat-header-cell>
          <mat-cell *matCellDef="let publicationRule"
            ><p>{{ publicationRule.rule.qtyThreshold }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="unitCostThreshold">
          <mat-header-cell *matHeaderCellDef mat-sort-header="unitCostThreshold"> Unit Cost Threshold </mat-header-cell>
          <mat-cell *matCellDef="let publicationRule"
            ><p>{{ publicationRule.rule.unitCostThreshold }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="supplier">
          <mat-header-cell class="name-column" *matHeaderCellDef mat-sort-header="supplier"> Supplier </mat-header-cell>
          <mat-cell class="name-column" *matCellDef="let publicationRule">
            <p>{{ publicationRule.rule.supplierName }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sendFlatFile">
          <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="sendFlatFile">Send Flat File </mat-header-cell>
          <mat-cell class="boolean-column" *matCellDef="let publicationRule">
            <p><i [ngClass]="publicationRule.rule.sendFlatFile ? 'fa fa-check' : 'fa fa-times sel-icon'" aria-hidden="true"></i></p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="edit">
          <mat-header-cell *matHeaderCellDef> </mat-header-cell>
          <mat-cell *matCellDef="let publicationRule">
            <button class="btn-product btn-fixed float-right" [routerLink]="['edit', publicationRule.id]">Edit</button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>
</div>
