import { AfterViewInit, Component, OnInit, Output } from '@angular/core';
import { VendorsService } from '../../../configuration/vendors/vendors.service';
import { LotEditService } from '../../lot-edit.service';
import { WarehousesService } from '../../../configuration/warehouses/warehouses.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Vendor, VendorsFromLotOutput } from '../../../../models/vendor-model';
import { Subject } from 'rxjs';
import { Warehouse } from '../../../../models/warehouse-model';
import { Supplier } from '../../../../models/supplier-model';
import { SpoFilters, spoFiltersMap } from '../../../../models/filters/spo-filters-model';
import { LotsService } from '../../../../shared/lots.service';
import { MatChipsData } from '../../../../models/mat-chips-data-model';
import { SpoStatusPipe } from '../../../../shared/my-pipes';
import { isNilty } from '../../../../core/utils.service';
import { SposService } from '../../../../shared/spos.service';
import { CommonsService } from '../../../../shared/commons.service';
import { SpoStatus } from '../../../../models/spo-model';

@Component({
  selector: 'app-spo-filters',
  templateUrl: './spo-filters.component.html',
  styleUrls: ['./spo-filters.component.css'],
})
export class SpoFiltersComponent implements OnInit, AfterViewInit {
  constructor(
    private vendorsService: VendorsService,
    private warehousesService: WarehousesService,
    private sposService: SposService,
    private lotEditService: LotEditService,
    private lotsService: LotsService,
    private spoStatusPipe: SpoStatusPipe,
    private commonsService: CommonsService
  ) {}

  warehouses: Warehouse[] = [];

  vendors: Vendor[] = [];
  parentVendors: Vendor[] = [];

  suppliers: Supplier[] = [];

  @Output() onFilter = new Subject<SpoFilters>();

  filterForm: FormGroup;

  public openFilter = false;

  filtersMap: MatChipsData[] = [];

  spoStatusArray: SpoStatus[];

  ngOnInit() {
    this.commonsService.getSpoStatuses().subscribe((r) => (this.spoStatusArray = r));
    this.filterForm = new FormGroup({
      parent: new FormControl(),
      destination: new FormControl(),
      ean: new FormControl(),
      po: new FormControl(),
      supplier: new FormControl(),
      orderId: new FormControl(),
      status: new FormControl(),
      unitCostFrom: new FormControl(),
      unitCostTo: new FormControl(),
      acceptBindingOrders: new FormControl(),
      hasOrderedQuantity: new FormControl(false),
      obsoleteWithAccepted: new FormControl(),
      crossUkBorder: new FormControl(),
      isExtra: new FormControl(),
      isAdr: new FormControl(),
      poOrderedOnFrom: new FormControl(),
      poOrderedOnTo: new FormControl(),
      lotDateFrom: new FormControl(),
      lotDateTo: new FormControl(),
    });
    if (this.lotEditService.spoFilters !== undefined) {
      this.filterForm.patchValue(this.lotEditService.spoFilters);
      this.updateFiltersMap();
    }

    if (this.vendorsService.lotEditVendors === undefined) {
      this.vendorsService.getVendorsFromLot(this.lotsService.currentLotId).subscribe((vendorsResp: VendorsFromLotOutput) => {
        this.vendors = vendorsResp.vendors;
        this.parentVendors = vendorsResp.parents;
        this.setParent();
      });
    } else {
      this.vendors = this.vendorsService.lotEditVendors;
      this.parentVendors = this.vendorsService.lotEditVendors.filter((it) => it.parent);
      this.setParent();
    }

    if (this.warehousesService.lotEditWarehouses === undefined) {
      this.warehousesService.getWarehousesFromLot(this.lotsService.currentLotId).subscribe((warehouses: Warehouse[]) => {
        this.warehouses = warehouses;
      });
    } else {
      this.warehouses = this.warehousesService.lotEditWarehouses;
    }

    this.sposService.getSuppliersFromSpos(this.lotsService.currentLotId).subscribe((suppliersResp: Supplier[]) => {
      this.suppliers = suppliersResp;
      this.setSupplier();
      this.updateFiltersMap();
    });
    this.updateFiltersMap();
  }

  setParent() {
    if (this.lotEditService.spoFilters && this.lotEditService.spoFilters.parent) {
      this.filterForm.controls['parent'].patchValue(this.parentVendors.find((it) => it.id === this.lotEditService.spoFilters.parent));
    }
  }

  setSupplier() {
    if (this.lotEditService.spoFilters && this.lotEditService.spoFilters.supplier) {
      this.filterForm.controls['supplier'].patchValue(this.suppliers.find((it) => it.id === this.lotEditService.spoFilters.supplier));
    }
  }

  ngAfterViewInit() {
    this.updateFiltersMap();
  }

  saveFiltersToService() {
    this.lotEditService.saveSpoFiltersToService(this.filterForm);
  }

  onSubmitFilter() {
    this.saveFiltersToService();
    this.onFilter.next(this.lotEditService.spoFilters);
    this.updateFiltersMap();
  }

  resetFilters() {
    this.filterForm.reset();
    this.saveFiltersToService();
    this.onFilter.next(this.lotEditService.spoFilters);
    this.updateFiltersMap();
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeevent() {
    this.openFilter = false;
  }

  updateFiltersMap() {
    const status = !isNilty(this.filterForm.value.status) ? this.spoStatusPipe.transform(this.filterForm.value.status) : null;
    this.filtersMap = spoFiltersMap(
      this.filterForm.value.parent,
      this.filterForm.value.ean,
      this.filterForm.value.po,
      this.filterForm.value.destination,
      this.filterForm.value.supplier,
      null,
      this.filterForm.value.unitCostFrom,
      this.filterForm.value.unitCostTo,
      this.filterForm.value.orderId,
      this.filterForm.value.acceptBindingOrders,
      this.filterForm.value.hasOrderedQuantity,
      this.filterForm.value.obsoleteWithAccepted,
      this.filterForm.value.crossUkBorder,
      this.filterForm.value.isExtra,
      this.filterForm.value.isAdr,
      status,
      this.filterForm.value.PoOrderedOnFrom,
      this.filterForm.value.PoOrderedOnTo,
      this.filterForm.value.lotDateFrom,
      this.filterForm.value.lotDateTo
    );
  }
}
