import { JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from 'json2typescript';

@JsonConverter
export class StringOrNumberToString implements JsonCustomConvert<number | string> {
  serialize(value: number | string): string {
    return value.toString();
  }

  deserialize(string: string): string | number {
    return string;
  }
}

@JsonObject('Param')
export class Param {
  @JsonProperty('key', String, false) key: string = undefined;
  @JsonProperty('value', StringOrNumberToString, false) value: number | string = undefined;
  @JsonProperty('values', [String], false) values: string[] = undefined;
}

@JsonObject('ConfigurationParam')
export class ConfigurationParam {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('type', String, false) type: string = undefined;
  @JsonProperty('description', String, true) description: string = undefined;
  @JsonProperty('parameters', [Param], false) parameters: Param[] = undefined;
  @JsonProperty('unique', Boolean, true) unique: boolean = undefined;
  new = false;
}

@JsonObject('ConfigurationParamsResponse')
export class ConfigurationParamsResponse {
  @JsonProperty('map', [ConfigurationParam], false) map: ConfigurationParam[] = undefined;
  @JsonProperty('params', [ConfigurationParam], false) params: ConfigurationParam[] = undefined;
}
