<app-publications-filters (onFilterEvent)="onFilter($event)"></app-publications-filters>

<mat-toolbar class="bar-custom">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <button [disabled]="!bulkActionsActive" [matMenuTriggerFor]="changeStatusMenu" class="box-shadow-none btn-custom margin-right-6">
          Change Status <i class="fa fa-angle-down sel-icon" aria-hidden="true"></i>
        </button>
        <mat-menu #changeStatusMenu="matMenu">
          <button mat-menu-item *ngFor="let s of publicationStatus" (click)="changeStatus(s.code)">{{ s.description }}</button>
        </mat-menu>

        <button
          [disabled]="!bulkActionsActive"
          [matMenuTriggerFor]="changeNewPriceStatusMenu"
          class="box-shadow-none btn-custom margin-right-6"
        >
          Change New Price Status
          <i class="fa fa-angle-down sel-icon" aria-hidden="true"></i>
        </button>
        <mat-menu #changeNewPriceStatusMenu="matMenu">
          <button mat-menu-item (click)="changeNewPriceStatus('APPROVED')">Approve</button>
          <button mat-menu-item (click)="changeNewPriceStatus('REJECTED')">Reject</button>
        </mat-menu>

        <button
          [disabled]="!bulkActionsActive"
          [matMenuTriggerFor]="exportPublicationsChoice"
          class="box-shadow-none btn-custom margin-right-6"
        >
          <i class="fa fa-download" aria-hidden="true"></i>
          Export Pending Approval
        </button>
        <mat-menu #exportPublicationsChoice="matMenu">
          <button mat-menu-item (click)="export('excel')">Save as Excel</button>
          <button mat-menu-item (click)="export('csv')">Save as CSV</button>
        </mat-menu>

        <button class="box-shadow-none btn-custom margin-right-6" (click)="importFile()">
          <i class="fa fa-upload" aria-hidden="true"></i>
          Import
        </button>
        <input #getFile type="file" style="display: none" onclick="this.value = null" (change)="fileChanged($event)" />
      </div>
    </div>
  </div>
</mat-toolbar>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <hr class="divisor-title" style="width: calc(100% - 18rem)" />
      <div class="square" style="right: 18rem"></div>
      <h2 class="po-element-custom title-custom">Publications</h2>
    </div>
  </div>
</div>

<div class="container table-outer">
  <mat-paginator #paginator [length]="resultsNumber | async" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()">
  </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table [dataSource]="dataSource" class="font-Titillium" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="check" sticky>
        <mat-header-cell *matHeaderCellDef class="checker-col">
          <mat-checkbox (change)="toggleAll($event.checked)" [ngModel]="mainChecker"></mat-checkbox>
        </mat-header-cell>
        <mat-cell class="checker-col" *matCellDef="let publication; let i = index">
          <mat-checkbox (change)="onSelected($event.checked, publication)" [ngModel]="publication.checked"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="vendorCode">
        <mat-header-cell class="long-vendor-column" *matHeaderCellDef mat-sort-header="vendor"> Vendor Code </mat-header-cell>
        <mat-cell class="long-vendor-column" *matCellDef="let publication">
          <p>{{ publication.vendor.code + ' - ' + publication.vendor.name }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="vendorCountry">
        <mat-header-cell class="date-column" *matHeaderCellDef mat-sort-header="vendorCountry"> Country </mat-header-cell>
        <mat-cell class="date-column" *matCellDef="let publication"> {{ publication.vendor.country }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="productEan">
        <mat-header-cell class="ean-column" *matHeaderCellDef mat-sort-header="ean"> Product EAN </mat-header-cell>
        <mat-cell class="ean-column" *matCellDef="let publication">
          <a [routerLink]="['/inventory/products', publication.product.id, 'details']">{{ publication.product.ean }}</a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="title">
        <mat-header-cell class="long-title-column" *matHeaderCellDef mat-sort-header="title"> Title </mat-header-cell>
        <mat-cell class="long-title-column" *matCellDef="let publication">
          <p>{{ publication.product.title }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="category">
        <mat-header-cell class="category-column" *matHeaderCellDef mat-sort-header="category"> Category </mat-header-cell>
        <mat-cell class="category-column" *matCellDef="let publication">
          <p>{{ publication.product.category?.name }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="brand">
        <mat-header-cell class="category-column" *matHeaderCellDef mat-sort-header="brand"> Brand </mat-header-cell>
        <mat-cell class="category-column" *matCellDef="let publication">
          <p>{{ publication.product.brand?.name }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="amazonPrice">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="amazonPrice"> Amazon Price </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let publication">
          <p>{{ publication.amazonPrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="newPrice">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="newPrice"> New Price </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let publication">
          <p>{{ publication.newPrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="publishedPrice">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="publishedPrice"> Publicated Price </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let publication">
          <p>{{ publication.publishedPrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="purchasePrice">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="purchasePrice"> Purchase Price</mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let publication">
          <p>{{ publication.originalPurchasePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell class="shipment-status-column" *matHeaderCellDef mat-sort-header="status"> Status </mat-header-cell>
        <mat-cell class="shipment-status-column" *matCellDef="let publication">
          {{ publication.statusCode | publicationStatusPipe }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="percentageDifference">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="percentageDifference">
          Difference %
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let publication">
          <p>{{ publication.percentageDifference ? publication.percentageDifference + ' %' : '' }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="newPriceStatus">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="newPrice"> New Price Status</mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let publication">
          <p>{{ publication.newPriceStatus?.description }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="pendingApprovalSince">
        <mat-header-cell class="date-time-column" *matHeaderCellDef mat-sort-header="pendingApprovalSince">
          Pending Appproval Since
        </mat-header-cell>
        <mat-cell class="date-time-column" *matCellDef="let publication"> {{ publication.pendingApprovalSince }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="supplier">
        <mat-header-cell class="supplier-column" *matHeaderCellDef mat-sort-header="supplier"> Supplier </mat-header-cell>
        <mat-cell class="supplier-column" *matCellDef="let publication">
          {{ publication.supplier ? publication.supplier.code + ' - ' + publication.supplier.name : '' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="suspendedAt">
        <mat-header-cell class="date-time-column" *matHeaderCellDef mat-sort-header="suspendedAt">Suspended at</mat-header-cell>
        <mat-cell class="date-time-column" *matCellDef="let publication">
          <p>{{ publication.suspendedAt }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastPriceUpdate">
        <mat-header-cell class="date-time-column" *matHeaderCellDef mat-sort-header="lastPriceUpdate"> Last Price Update </mat-header-cell>
        <mat-cell class="date-time-column" *matCellDef="let publication"> {{ publication.lastPriceUpdate }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastOrderDate">
        <mat-header-cell class="date-time-column" *matHeaderCellDef mat-sort-header="lastOrderDate"> Last Order Date </mat-header-cell>
        <mat-cell class="date-time-column" *matCellDef="let publication"> {{ publication.lastOrderDate }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="newQuantity">
        <mat-header-cell class="quantity-column" *matHeaderCellDef mat-sort-header="newQuantity"> New Quantity </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let publication"> {{ publication.newQuantity }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="publishedQuantity">
        <mat-header-cell class="quantity-column" *matHeaderCellDef mat-sort-header="publishedQuantity">
          Published Quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let publication"> {{ publication.publishedQuantity }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="email">
        <mat-header-cell class="email-column-title" *matHeaderCellDef mat-sort-header="email"> Email </mat-header-cell>
        <mat-cell class="email-column" *matCellDef="let publication">
          <p>{{ publication.email }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="showDetails">
        <mat-header-cell class="actions-edit-column" *matHeaderCellDef> Approved history </mat-header-cell>
        <mat-cell class="actions-edit-column" *matCellDef="let publication">
          <button
            class="btn-product edit-button"
            *ngIf="publication.publicationApprovedHistory.length > 0"
            matTooltip="Show approved history"
            (click)="showApprovedHistory(publication)"
          >
            <i class="fa fa-history" aria-hidden="true"></i>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
