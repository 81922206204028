import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Po } from '../../../../models/po-model';
import { Router } from '@angular/router';
import { ImportExportService } from '../../../../shared/import-export.service';
import { PosService } from '../../../../shared/pos.service';
import { PoFilters } from '../../../../models/filters/po-filters-model';
import { Observable, Subject } from 'rxjs';
import { SendPoaModalComponent } from '../../../lot-edit-section/send-poa-modal/send-poa-modal.component';
import { LotsService } from '../../../../shared/lots.service';
import { addCheckers, onSelectedChecker, toggleAllCheckers } from '../../../../shared/checkers.service';
import { isNilty } from '../../../../core/utils.service';
import { AuthorizationService } from '../../../../core/authorization.service';
import { Permissions } from '../../../../models/permission-model';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { NewLotModalComponent } from '../../../modal/new-lot-modal/new-lot-modal.component';

@Component({
  selector: 'app-pos',
  templateUrl: './pos-list.component.html',
  styleUrls: ['./pos-list.component.css'],
})
export class PosListComponent implements OnInit, OnDestroy {
  constructor(
    private posService: PosService,
    private router: Router,
    private lotsService: LotsService,
    private authorizationService: AuthorizationService,
    private importExportService: ImportExportService,
    private dialog: MatDialog
  ) {}

  dataSource: MatTableDataSource<Po>;

  displayedColumns = [
    'check',
    'poId',
    'vendor',
    'parentVendor',
    'orderedOn',
    'destination',
    'earliestDate',
    'latestDate',
    'submittedQuantity',
    'submittedValue',
    'acceptedQuantity',
    'acceptedValue',
    'lastPoaQuantity',
    'hasLot',
    'status',
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  poEanDestModel: string;
  poEanDest: string;

  poEanDestsearched = new Subject<void>();

  dataPos: any[] = [];

  mainChecker = false;

  bulkActionsActive = false;

  hasLotManagement: Observable<boolean>;
  hasPoStateChange: Observable<boolean>;
  hasSendPoaSubmitted: Observable<boolean>;

  ngOnInit() {
    this.resultsNumber = this.posService.resultsNumber;
    this.mainChecker = this.posService.mainChecker;

    if (this.posService.poFilters === null) {
      this.posService.poFilters = new PoFilters();
      this.posService.poFilters.status = 'New';
      this.posService.poFilters.sortBy = 'orderedOn';
      this.posService.poFilters.sortDirection = 'desc';
    }

    this.posService.paginationAndSorting = {
      pageNumber: null,
      pageSize: null,
      sortBy: null,
      sortDirection: null,
    };
    this.dataSource = new MatTableDataSource<Po>([]);
    this.resetCheckers();
    this.loadPos(this.posService.poFilters);

    this.hasLotManagement = this.authorizationService.hasPermission(Permissions.LotManagement);
    this.hasPoStateChange = this.authorizationService.hasPermission(Permissions.PoStateChange);
    this.hasSendPoaSubmitted = this.authorizationService.hasPermission(Permissions.SendPoaSubmitted);
  }

  loadPos(filters: PoFilters) {
    this.posService.getFilteredPos(filters).subscribe((pos: Po[]) => {
      this.dataPos = addCheckers(pos, this.posService.selectedPos, this.mainChecker);
      this.dataSource.data = this.dataPos;
    });
  }

  loadPage() {
    this.posService.paginationAndSorting.pageNumber = this.paginator.pageIndex;
    this.posService.paginationAndSorting.pageSize = this.paginator.pageSize;
    this.posService.poFilters.pageNumber = this.paginator.pageIndex;
    this.posService.poFilters.pageSize = this.paginator.pageSize;
    if (!isNilty(this.poEanDest) && this.poEanDest !== '') {
      this.posService.getPosByPoEanDest(this.poEanDest).subscribe((pos: Po[]) => {
        this.dataPos = addCheckers(pos, this.posService.selectedPos, this.mainChecker);
        this.dataSource.data = this.dataPos;
      });
    } else {
      this.loadPos(this.posService.poFilters);
    }
  }

  onSort() {
    this.posService.poFilters.sortBy = this.sort.active;
    this.posService.poFilters.sortDirection = this.sort.direction;
    this.loadPos(this.posService.poFilters);
  }

  applyFilter(filters: PoFilters) {
    this.poEanDest = '';
    this.poEanDestModel = '';
    filters.pageNumber = 0;
    filters.pageSize = null;
    this.paginator.pageIndex = 0;
    this.resetCheckers();
    this.loadPos(filters);
  }

  onSelected(value: boolean, selectedPo: any) {
    this.bulkActionsActive = onSelectedChecker(value, selectedPo, this.dataPos, this.mainChecker, this.posService.selectedPos);
  }

  toggleAll(value: boolean) {
    this.mainChecker = value;
    this.bulkActionsActive = toggleAllCheckers(value, this.dataPos, this.posService.selectedPos);
  }

  addToLot() {
    this.dialog
      .open(NewLotModalComponent, {
        width: '350px',
        data: {},
      })
      .afterClosed()
      .subscribe((resp: number) => {
        if (!isNilty(resp)) {
          console.log('new lot id is ' + resp);
          this.lotsService
            .addPosToLot(this.posService.poFilters, this.posService.selectedPos, this.mainChecker, false, resp)
            .subscribe(() => this.loadPos(this.posService.poFilters));
        }
      });
  }

  showDetails() {
    this.router.navigate(['pos/details']);
  }

  exportPos(format: string) {
    const selectedPosIds = this.posService.selectedPos;

    this.importExportService.exportPos(selectedPosIds, format, this.mainChecker, this.posService.poFilters).subscribe((resp) => {
      console.log(resp);
    });
  }

  exportAllPos(format: string) {
    this.importExportService.exportPos(this.posService.selectedPos, format, true, this.posService.poFilters).subscribe((resp) => {
      console.log(resp);
    });
  }

  changePosStatus(status: string, submitted: boolean = false) {
    const selectedPos = this.posService.selectedPos;
    const mainChecker = this.mainChecker;

    if (status === 'Closed') {
      const dialogRef = this.dialog.open(SendPoaModalComponent, {
        width: '700px',
        data: {
          mainChecker,
          selected: this.posService.selectedPos.length,
          totalNumber: this.posService.totalPos,
        },
      });

      dialogRef.afterClosed().subscribe((result: string) => {
        if (result === 'send') {
          this.posService.closeAndSendPoa(selectedPos, this.posService.poFilters, mainChecker, submitted).subscribe(() => {
            this.applyFilter(this.posService.poFilters);
          });
        } else if (result === 'dontSend') {
          this.posService.changeStatus(status, selectedPos, this.posService.poFilters, mainChecker).subscribe(() => {
            this.applyFilter(this.posService.poFilters);
          });
        } else {
          return;
        }
      });
    } else {
      return this.posService.changeStatus(status, selectedPos, this.posService.poFilters, mainChecker).subscribe(() => {
        this.applyFilter(this.posService.poFilters);
      });
    }
  }

  onSearchPoEanDest() {
    this.poEanDest = this.poEanDestModel;
    this.resetCheckers();
    if (!isNilty(this.poEanDest) && this.poEanDest !== '') {
      this.posService.getPosByPoEanDest(this.poEanDest).subscribe((pos: Po[]) => {
        this.dataPos = addCheckers(pos, this.posService.selectedPos, this.mainChecker);
        this.dataSource = new MatTableDataSource(this.dataPos);
      });
      this.poEanDestsearched.next();
    } else {
      this.loadPos(this.posService.poFilters);
    }
  }

  ngOnDestroy() {
    this.posService.pos = [];
  }

  resetCheckers() {
    this.mainChecker = false;
    this.bulkActionsActive = false;
    this.posService.selectedPos = [];
  }
}
