import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-generic-switch-confirmation-modal',
  templateUrl: './generic-switch-confirmation-modal.component.html',
  styleUrls: ['./generic-switch-confirmation-modal.component.css'],
})
export class GenericSwitchConfirmationModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<GenericSwitchConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { message: string; switchQuestion: string; question: string }
  ) {}

  message: string;
  switchQuestion: string;
  question = 'Are you sure you want to continue?';
  switchStatus = false;

  ngOnInit() {
    this.message = this.data.message;
    this.question = this.data.question !== undefined ? this.data.question : this.question;
    this.switchQuestion = this.data.switchQuestion;
  }

  changeStatus(result: boolean, switchStatus: boolean) {
    this.dialogRef.close({ result, switchStatus });
  }
}
