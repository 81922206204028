import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { MainShipmentsComponent } from './main-shipments.component';
import { MainShipmentsListComponent } from './main-shipments-list/main-shipments-list.component';
import { MainShipmentsDetailsComponent } from './main-shipments-details/main-shipments-details.component';
import { SingleShipmentDetailComponent } from '../../../shared/single-shipment-detail/single-shipment-detail.component';

const configurationRoutes: Routes = [
  {
    path: '',
    component: MainShipmentsComponent,
    children: [
      { path: '', component: MainShipmentsListComponent, pathMatch: 'full' },
      { path: 'details', component: MainShipmentsDetailsComponent },
      { path: ':id', component: SingleShipmentDetailComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(configurationRoutes)],
  exports: [RouterModule],
  providers: [],
})
export class MainShipmentsRoutingModule {}
