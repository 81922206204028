import { NgModule } from '@angular/core';

import { PoFiltersComponent } from './po-filters/po-filters.component';
import { PoKpiComponent } from './po-kpi/po-kpi.component';
import { PoBulkActionsComponent } from './po/po-bulk-actions/po-bulk-actions.component';
import { PoComponent } from './po/po.component';
import { SharedModule } from '../../../shared.module';
import { PoDetailsComponent } from './po-details/po-details.component';
import { PoDetailsBulkActionsComponent } from './po-details/po-details-bulk-actions/po-details-bulk-actions.component';

@NgModule({
  declarations: [
    PoComponent,
    PoFiltersComponent,
    PoKpiComponent,
    PoBulkActionsComponent,
    PoDetailsComponent,
    PoDetailsBulkActionsComponent,
  ],
  imports: [SharedModule],
  providers: [],
})
export class PoModule {}
