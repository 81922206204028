import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { MainPaymentsComponent } from './main-payments.component';
import { MainPaymentDocumentsComponent } from './main-payment-documents/main-payment-documents.component';
import { PaymentDocumentListComponent } from './main-payment-documents/payment-document-list/payment-document-list.component';
import { PaymentDocumentEditComponent } from './main-payment-documents/payment-document-edit/payment-document-edit.component';
import { MainPaymentRequestsComponent } from './main-payment-requests/main-payment-requests.component';
import { PaymentRequestListComponent } from './main-payment-requests/payment-request-list/payment-request-list.component';

const configurationRoutes: Routes = [
  {
    path: '',
    component: MainPaymentsComponent,
    children: [
      {
        path: 'documents',
        component: MainPaymentDocumentsComponent,
        children: [
          { path: '', component: PaymentDocumentListComponent },
          { path: 'edit/:id', component: PaymentDocumentEditComponent },
          { path: 'new', component: PaymentDocumentEditComponent },
        ],
      },
      {
        path: 'requests',
        component: MainPaymentRequestsComponent,
        children: [
          { path: '', component: PaymentRequestListComponent },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(configurationRoutes)],
  exports: [RouterModule],
  providers: [],
})
export class MainPaymentsRoutingModule {}
