import { Component, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { WarehouseFilters, warehouseFiltersMap } from '@models/filters/warehouse-filters-model';
import { MatChipsData } from '@models/mat-chips-data-model';
import { WarehousesService } from '../../warehouses.service';

@Component({
  selector: 'app-warehouse-list-filters',
  templateUrl: './warehouse-list-filters.component.html',
  styleUrls: ['./warehouse-list-filters.component.css'],
})
export class WarehousesListFiltersComponent implements OnInit {
  constructor(private warehouseService: WarehousesService) {}

  filterForm: FormGroup;
  public openFilter = false;

  countries: string[];

  @Output() onFilter = new Subject<WarehouseFilters>();

  filtersMap: MatChipsData[] = [];

  ngOnInit() {
    this.filterForm = new FormGroup({
      code: new FormControl(),
      country: new FormControl(),
    });

    if (this.warehouseService.warehouseFilters !== undefined) {
      this.filterForm.patchValue(this.warehouseService.warehouseFilters);
    }

    this.warehouseService.getAllCountries().subscribe((resp) => {
      this.countries = resp;
    });
  }

  saveFiltersToService() {
    this.warehouseService.saveWarehouseFiltersToService(this.filterForm);
  }

  onSubmitFilter() {
    this.saveFiltersToService();
    this.onFilter.next(this.warehouseService.warehouseFilters);
    this.updateFiltersMap();
  }

  resetFilters() {
    this.filterForm.reset();
    this.saveFiltersToService();
    this.onFilter.next(this.warehouseService.warehouseFilters);
    this.updateFiltersMap();
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeevent() {
    this.openFilter = false;
  }

  updateFiltersMap() {
    this.filtersMap = warehouseFiltersMap(this.warehouseService.warehouseFilters.code, this.warehouseService.warehouseFilters.country);
  }
}
