import { Component, Input, OnInit, Output } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { AuthorizationService } from '../../../../../core/authorization.service';
import { Permissions } from '../../../../../models/permission-model';

@Component({
  selector: 'app-po-bulk-actions',
  templateUrl: './po-bulk-actions.component.html',
  styleUrls: ['./po-bulk-actions.component.css'],
})
export class PoBulkActionsComponent implements OnInit {
  @Input() bulkActionsActive: boolean;

  @Output() onMoveToLot = new Subject<void>();
  @Output() onRemoveFromLot = new Subject<void>();
  @Output() onPoExport = new Subject<string>();
  @Output() onChangePoStatus = new Subject<{ status: string; submitted: boolean }>();

  hasLotManagement: Observable<boolean>;
  hasPoStateChange: Observable<boolean>;
  hasSendPoaSubmitted: Observable<boolean>;

  constructor(private authorizationService: AuthorizationService) {}

  ngOnInit() {
    this.hasLotManagement = this.authorizationService.hasPermission(Permissions.LotManagement);
    this.hasPoStateChange = this.authorizationService.hasPermission(Permissions.PoStateChange);
    this.hasSendPoaSubmitted = this.authorizationService.hasPermission(Permissions.SendPoaSubmitted);
  }

  moveToLot() {
    this.onMoveToLot.next();
  }

  removeFromLot() {
    this.onRemoveFromLot.next();
  }

  exportExcel(format: string) {
    this.onPoExport.next(format);
  }

  changePosStatus(status: string, submitted: boolean = false) {
    this.onChangePoStatus.next({ status, submitted });
  }
}
