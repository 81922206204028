import { Component, Input, OnInit } from '@angular/core';
import { Predicate } from '@models/publication-rule';

@Component({
  selector: 'app-constraints',
  templateUrl: './constraints.component.html',
  styleUrls: ['./constraints.component.css'],
})
export class ConstraintsComponent implements OnInit {
  @Input() constraints: Predicate[];

  constructor() {}

  ngOnInit(): void {}
}
