import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { PoItem } from '../../../../models/po-item-model';
import { PoaItemLog, PoaLog } from '../../../../models/poa-logs-model';
import { ConverterService } from '../../../../core/converter.service';
import { Po } from '../../../../models/po-model';
import { isNilty } from '../../../../core/utils.service';

@Component({
  selector: 'app-poa-item-history-modal',
  templateUrl: './poa-item-history-modal.component.html',
  styleUrls: ['./poa-item-history-modal.component.css'],
})
export class PoaItemHistoryModalComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  poaItemLogsDataSource: MatTableDataSource<PoaItemLog>;
  poaLogsColumns = ['downloadEdi', 'sentDate', 'acceptedQuantity', 'backOrderQuantity', 'softRejectedQuantity', 'hardRejectedQuantity'];

  poi: PoItem;
  po: Po;

  constructor(
    public dialogRef: MatDialogRef<PoaItemHistoryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { poi: PoItem; po: Po },
    private converterService: ConverterService
  ) {}

  ngOnInit() {
    this.poi = this.data.poi;
    this.po = this.data.po;
    this.poaItemLogsDataSource = new MatTableDataSource<PoaItemLog>();
    this.poaItemLogsDataSource.paginator = this.paginator;
    this.initLogs();
  }

  initPoaLogs() {
    if (isNilty(this.po.poaLog)) {
      this.po.convertedPoaLogs = [];
    } else {
      const array = JSON.parse(this.po.poaLog) as string[];
      this.po.convertedPoaLogs = array.map((it) => this.converterService.fromJSONtoObj(it, PoaLog));
    }
  }

  initLogs() {
    if (isNilty(this.po.convertedPoaLogs)) {
      this.initPoaLogs();
    }
    const array = JSON.parse(this.poi.poaLog) as string[];
    this.poaItemLogsDataSource.data = array.map((it) => {
      const poItemLog = this.converterService.fromJSONtoObj(it, PoaItemLog);
      poItemLog['fileUrl'] = this.po.convertedPoaLogs.find((poLog) => poLog.messageId === poItemLog.messageId)?.fileUrl;
      return poItemLog;
    });
  }

  downloadEdi(url: string) {
    window.open(url, '_blank');
  }

  close() {
    this.dialogRef.close();
  }
}
