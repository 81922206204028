<ng-template #lastStepTimer>
  <div class="col-md-3 text-right">{{ lastStepMinutes }}:{{ lastStepSeconds }}</div>
</ng-template>

<button class="btn-lot-edit-menu" [matMenuTriggerFor]="inProgress">
  <i
    class="fa fa-spinner margin-right-6"
    [ngStyle]="{ transform: iconRotation }"
    aria-hidden="true"
    *ngIf="jobStatus?.status !== JOB_STATUS.TROUBLE"
  ></i>
  <i class="fa fa-exclamation-triangle" aria-hidden="true" *ngIf="jobStatus?.status === JOB_STATUS.TROUBLE"></i>
  Fulfilment
</button>
<mat-menu class="in-progress-mat-menu" #inProgress="matMenu">
  <ng-container *ngIf="!jobStatus">
    <div class="col-md-8 status"><h4>Waiting for worker response</h4></div>
  </ng-container>
  <ng-container *ngIf="jobStatus">
    <div class="container progress-content">
      <div class="row">
        <div class="col-md-12 title">FULFILMENT GENERATION</div>
        <div class="col-md-12">Started at: {{ jobStatus?.startedAt }}</div>
      </div>

      <div class="row padding-top-10" *ngIf="jobStatus.status === JOB_STATUS.TROUBLE">
        <div class="col-md-8 trouble">The job seems to be stuck!</div>
        <div class="col-md-8 trouble padding-top-10" *ngIf="stuckRetry">
          <button class="btn-product" (click)="retry()">Retry</button>
        </div>
      </div>

      <div class="row padding-top-10" *ngIf="jobStatus.status === JOB_STATUS.STARTING">
        <div class="col-md-12">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="col-md-8 status">STARTING</div>
      </div>

      <div class="row padding-top-10" *ngIf="jobStatus.status === JOB_STATUS.RUNNING">
        <ng-container [ngSwitch]="jobStatus.fulfilmentStatus">
          <ng-container *ngSwitchCase="FULFILMENT_GENERATION_STEP.STARTING">
            <div class="col-md-12">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div class="col-md-9 status">Starting (1/{{ TOTAL_STEPS }})</div>
            <ng-container *ngTemplateOutlet="lastStepTimer"></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="FULFILMENT_GENERATION_STEP.FETCHING_PO_ITEMS">
            <div class="col-md-12">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div class="col-md-9 status">Retrieving PoItems (2/{{ TOTAL_STEPS }})</div>
            <ng-container *ngTemplateOutlet="lastStepTimer"></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="FULFILMENT_GENERATION_STEP.RETRIEVING_COMMODITIES">
            <div class="col-md-12">
              <mat-progress-bar [value]="(jobStatus.retrievedCommodities / jobStatus.totalPoItems) * 100"></mat-progress-bar>
            </div>
            <div class="col-md-9 status">Retrieving commodities (3/{{ TOTAL_STEPS }})</div>
            <ng-container *ngTemplateOutlet="lastStepTimer"></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="FULFILMENT_GENERATION_STEP.GENERATION">
            <div class="col-md-12">
              <mat-progress-bar [value]="(jobStatus.completedPoItems / jobStatus.totalPoItems) * 100"></mat-progress-bar>
            </div>
            <div class="col-md-9 status">Generating fulfilments (4/{{ TOTAL_STEPS }})</div>
            <ng-container *ngTemplateOutlet="lastStepTimer"></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="FULFILMENT_GENERATION_STEP.SPOS_THRESHOLD_CHECK">
            <div class="col-md-12">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div class="col-md-9 status">Checking thresholds (5/{{ TOTAL_STEPS }})</div>
            <ng-container *ngTemplateOutlet="lastStepTimer"></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="FULFILMENT_GENERATION_STEP.LSA_GENERATION">
            <div class="col-md-12">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div class="col-md-9 status">Generating LSAs (6/{{ TOTAL_STEPS }})</div>
            <ng-container *ngTemplateOutlet="lastStepTimer"></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="FULFILMENT_GENERATION_STEP.SAVING_FULFILMENTS">
            <div class="col-md-12">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div class="col-md-9 status">Saving fulfilments (7/{{ TOTAL_STEPS }})</div>
            <ng-container *ngTemplateOutlet="lastStepTimer"></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="FULFILMENT_GENERATION_STEP.UPDATING_POS">
            <div class="col-md-12">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div class="col-md-9 status">Updating POs (8/{{ TOTAL_STEPS }})</div>
            <ng-container *ngTemplateOutlet="lastStepTimer"></ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</mat-menu>
