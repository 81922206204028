import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthorizationService } from '../../core/authorization.service';
import { Permissions } from '../../models/permission-model';
import { AuthenticationService } from '../../security/authentication.service';
import { EnvironmentService } from 'src/app/environment.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  constructor(
    private authorizationService: AuthorizationService,
    private http: HttpClient,
    private router: Router,
    private authenticationService: AuthenticationService,
    private environmentService: EnvironmentService
  ) {}

  isAdmin: Observable<boolean>;
  isEditMode: boolean;
  subscription = new Subscription();
  currentMail = '';

  logoUrl: string = this.environmentService.logo;

  ngOnInit() {
    this.isAdmin = this.authorizationService.hasPermissions([
      Permissions.Administration,
      Permissions.Configuration,
      Permissions.VendorAccountManagement,
    ]);
    this.subscription = this.authorizationService.getPermissions().subscribe((_) => {
      this.currentMail = this.authorizationService.currentUserMail;
      this.authorizationService.isLotEditMode.subscribe((value: boolean) => {
        this.isEditMode = value;
      });
    });
  }

  refreshHomepage() {
    this.router.navigate(['/']);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  logout() {
    this.authenticationService.logout();
  }
}
