import { Component, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { ProductsService } from '../../../../../shared/products.service';
import { isNilty } from '../../../../../core/utils.service';
import { Brand } from '../../../../../models/brand-model';
import { Category } from '../../../../../models/category-model';
import { productFiltersMap, ProductsFilters } from '../../../../../models/filters/products-filters-model';
import { MatChipsData } from '../../../../../models/mat-chips-data-model';
import { Season } from '../../../../../models/season-model';
import { Supplier } from '../../../../../models/supplier-model';
import { CategoriesService } from '../../../../configuration/categories/categories.service';
import { InventoryService } from '../../../inventory.service';

@Component({
  selector: 'app-products-filters',
  templateUrl: './products-filters.component.html',
  styleUrls: ['./products-filters.component.css'],
})
export class ProductsFiltersComponent implements OnInit {
  filters: ProductsFilters;

  brands: Subject<Brand[]> = new Subject();
  loadedBrands: Brand[];

  categories: Category[] = [];

  seasons: Season[] = [];

  suppliers: Supplier[] = [];

  public openFilter = false;
  filtersMap: MatChipsData[] = [];

  productStatus: string[] = [];

  @Output() onFilterEvent = new Subject<ProductsFilters>();

  constructor(
    private inventoryService: InventoryService,
    private categoriesService: CategoriesService,
    private productsService: ProductsService
  ) {}

  ngOnInit() {
    this.productsService.getProductsStatus().subscribe((s: string[]) => {
      this.productStatus = s;
    });
    this.filters = isNilty(this.inventoryService.productsFilters) ? new ProductsFilters() : this.inventoryService.productsFilters;
    this.createFiltersMap();

    this.inventoryService.getBrands().subscribe((brands: Brand[]) => {
      this.brands.next(brands);
      this.loadedBrands = brands;
    });

    this.inventoryService.getSeasons().subscribe((seasons: Season[]) => {
      this.seasons = seasons;
    });

    this.inventoryService.getAllSuppliers().subscribe((suppliers: Supplier[]) => {
      this.suppliers = suppliers;
    });

    this.categoriesService.getCategories('', 10000, 0).subscribe((c: Category[]) => {
      this.categories = c;
    });
  }

  onFilter() {
    this.saveFiltersToService();
    this.createFiltersMap();
    this.onFilterEvent.next(this.filters);
  }

  saveFiltersToService() {
    this.inventoryService.productsFilters = this.filters;
  }

  resetFilters() {
    this.filters = new ProductsFilters();
    this.filtersMap = [];
    this.onFilterEvent.next(this.filters);
  }

  createFiltersMap() {
    this.filtersMap = productFiltersMap(
      this.filters.brand ? this.filters.brand : null,
      this.filters.supplier ? this.filters.supplier.name : null,
      this.filters.category ? this.filters.category.name : null,
      this.filters.season ? this.filters.season.period : null,
      this.filters.codes,
      this.filters.status,
      this.filters.hasPo
    );
  }

  openEvent() {
    this.openFilter = !this.openFilter;
  }

  closeEvent() {
    this.openFilter = false;
  }

  filterBrands(filterString: { filterText: string; filterKey: string }) {
    this.inventoryService.getBrands(filterString.filterText).subscribe((resp: Brand[]) => {
      this.brands.next(resp);
      this.loadedBrands = resp;
    });
  }

  findElement(list: any[], e: number): any {
    return list.find((it) => it.id === e);
  }
}
