import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { MainDeductionsComponent } from './main-deductions.component';
import { MainClaimHeadersComponent } from './main-claim-headers/main-claim-headers.component';
import { MainPenaltiesComponent } from './main-penalties/main-penalties.component';
import { ClaimDetailsListComponent } from './main-claim-headers/claim-details-list/claim-details-list.component';
import { ClaimsListComponent } from './main-claim-headers/claims-list/claims-list.component';
import { ClaimHeadersListComponent } from './main-claim-headers/claim-headers-list/claim-headers-list.component';
import { ChargebackListComponent } from './main-penalties/chargeback-list/chargebacks-list.component';
import { MainDisputeBotLogsComponent } from './main-dispute-bot-logs/main-dispute-bot-logs.component';
import { DisputeBotLogsListComponent } from './main-dispute-bot-logs/dispute-bot-logs-list/dispute-bot-logs-list.component';

const configurationRoutes: Routes = [
  {
    path: '',
    component: MainDeductionsComponent,
    children: [
      {
        path: 'claims',
        component: MainClaimHeadersComponent,
        children: [
          { path: '', component: ClaimHeadersListComponent },
          { path: 'details', component: ClaimsListComponent },
          { path: ':claimId', component: ClaimDetailsListComponent },
        ],
      },
      {
        path: 'penalties',
        component: MainPenaltiesComponent,
        children: [{ path: '', component: ChargebackListComponent }],
      },
      {
        path: 'dispute-bot-logs',
        component: MainDisputeBotLogsComponent,
        children: [{ path: '', component: DisputeBotLogsListComponent }],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(configurationRoutes)],
  exports: [RouterModule],
  providers: [],
})
export class MainPenaltiesRoutingModule {}
