import { JsonObject, JsonProperty } from 'json2typescript';
import { Supplier } from '../supplier-model';
import { MatChipsData } from '../mat-chips-data-model';
import { isNilty } from '../../core/utils.service';

@JsonObject('StockLocationFilters')
export class StockLocationFilters {
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('supplier', Number, true) supplier: number = undefined;

  @JsonProperty('pageNumber', Number, true) pageNumber = 0;
  @JsonProperty('pageSize', Number, true) pageSize = 10;
  @JsonProperty('sortBy', String, true) sortBy = 'name';
  @JsonProperty('sortDirection', String, true) sortDirection = 'asc';
}

export const stockLocationFiltersMap = (name?: string, supplier?: string): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (!isNilty(name)) {
    map.push(new MatChipsData('Name/Code', name));
  }
  if (!isNilty(supplier)) {
    map.push(new MatChipsData('Supplier', supplier));
  }
  return map;
};
