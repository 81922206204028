<h2 mat-dialog-title>
  <span class="dialog-title">POA history for {{ po?.poId }}</span>
  <span class="dialog-close-icon padding-right-30"
    ><i class="fa fa-times exit-icon margin-bottom-10" (click)="close()" style="cursor: pointer;"></i
  ></span>
</h2>

<div mat-dialog-content>
  <div class="row header text-blue" *ngIf="po">
    <div class="col-md-12">
      <span class="margin-right-10"><b>PO:</b> {{ po.poId }}</span>
      | <span class="margin-left-10 margin-right-10"><b>Ordered on:</b> {{ po.orderedOn }}</span> |
      <span class="margin-left-10 margin-right-10"><b>Closed on:</b> {{ po.closedDate }}</span> |
      <span class="margin-left-10 margin-right-10"><b>Accepted quantity:</b> {{ po.acceptedQuantity }}</span> |
      <span class="margin-left-10 margin-right-10"><b>Last POA quantity:</b> {{ po.lastPoaQuantity }}</span>
    </div>
  </div>

  <hr />

  <div class="row height-90">
    <div class="col-md-6 height-90">
      <div class="row width-100per">
        <div class="col-md-12 text-right">
          <mat-paginator
            #paginator
            [hidePageSize]="true"
            [length]="po?.convertedPoaLogs?.length"
            [pageSize]="5"
            class="container"
          ></mat-paginator>
        </div>

        <div class="table-container">
          <mat-table [dataSource]="poaLogsDataSource">
            <ng-container matColumnDef="downloadEdi" sticky>
              <mat-header-cell *matHeaderCellDef class="actions-edit-column"> </mat-header-cell>
              <mat-cell *matCellDef="let poa" class="actions-edit-column">
                <button
                  (click)="downloadEdi(poa.fileUrl)"
                  matTooltip="Download EDI"
                  *ngIf="poa.fileUrl"
                  class="btn-custom smaller-icon-button"
                >
                  <i class="fa fa-cloud-download" aria-hidden="true"></i>
                </button>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="source">
              <mat-header-cell *matHeaderCellDef> Source </mat-header-cell>
              <mat-cell *matCellDef="let poa">
                <p>{{ poa.source }}</p>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="sentDate" sticky>
              <mat-header-cell *matHeaderCellDef class="date-time-column"> Poa sent date </mat-header-cell>
              <mat-cell *matCellDef="let poa" class="date-time-column">
                <p>{{ poa.sentDate }}</p>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="totalAcceptedQuantity">
              <mat-header-cell *matHeaderCellDef class="quantity-column-title"> Accepted </mat-header-cell>
              <mat-cell *matCellDef="let poa" class="quantity-column">
                <p>{{ poa.totalAcceptedQuantity }}</p>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="details" stickyEnd>
              <mat-header-cell *matHeaderCellDef class="actions-edit-column"> </mat-header-cell>
              <mat-cell *matCellDef="let poa" class="actions-edit-column">
                <button matTooltip="Show details" class="btn-custom smaller-icon-button" (click)="poaSelected(poa)">
                  <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="poaLogsColumns; sticky: true"></mat-header-row>
            <mat-row
              [ngClass]="{ 'selected-poa': selectedPoa.messageId === row.messageId }"
              *matRowDef="let row; columns: poaLogsColumns"
            ></mat-row>
          </mat-table>
        </div>
      </div>
    </div>

    <div class="col-md-6 height-90">
      <app-poa-details
        *ngIf="selectedPoa"
        [po]="po"
        [selectedPoa]="selectedPoa"
        [changedSelectedPoa]="changedSelectedPoa"
      ></app-poa-details>
    </div>
  </div>
</div>
