<app-suppliers-availabilities-filters (onFilter)="onFilter()"></app-suppliers-availabilities-filters>

<app-suppliers-availabilities-kpi></app-suppliers-availabilities-kpi>

<app-suppliers-availabilities-bulk-actions
  (onExport)="export($event)"
  [bulkActionsActive]="bulkActionsActive"
></app-suppliers-availabilities-bulk-actions>

<div class="container table-outer">
  <mat-paginator #paginator [length]="resultsNumber | async" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()">
  </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="check" sticky>
        <mat-header-cell *matHeaderCellDef class="checker-col">
          <mat-checkbox (change)="toggleAll($event.checked)" [ngModel]="mainChecker"></mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let avail; let i = index" class="checker-col">
          <mat-checkbox (change)="onSelected($event.checked, avail)" [ngModel]="avail.checked"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ean" sticky>
        <mat-header-cell class="ean-column" *matHeaderCellDef mat-sort-header="ean"> EAN </mat-header-cell>
        <mat-cell class="ean-column" *matCellDef="let avail">
          <a [routerLink]="['/inventory/products', avail.product.id, 'details']">{{ avail.product.ean }}</a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="title">
        <mat-header-cell class="title-column" *matHeaderCellDef mat-sort-header="title"> Title </mat-header-cell>
        <mat-cell class="title-column" *matCellDef="let avail" [matTooltip]="avail.product.title" matTooltipPosition="below">
          <p>{{ avail.product.title }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="supplier">
        <mat-header-cell class="origin-column" *matHeaderCellDef mat-sort-header="supplier"> Supplier </mat-header-cell>
        <mat-cell class="origin-column" *matCellDef="let avail" [matTooltip]="avail.supplier.name" matTooltipPosition="below">
          <p>{{ avail.supplier.name }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="submittedQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="submittedQuantity">
          Submitted <br />
          quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let avail">
          <p>{{ avail.submittedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="submittedValue">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="submittedValue">
          Submitted <br />
          value
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let avail">
          <p>{{ avail.submittedValue | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="requestedQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="requestedQuantity">
          Requested <br />
          quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let avail">
          <p>{{ avail.requestedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="requestedValue">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="requestedValue">
          Requested <br />
          value
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let avail">
          <p>{{ avail.requestedValue | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="availableQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="availableQuantity">
          Available <br />
          quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let avail">
          <p>{{ avail.availableQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="availableValue">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="availableValue">
          Available <br />
          value
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let avail">
          <p>{{ avail.availableValue | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="purchasePrice">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="purchasePrice"> Purchase price </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let avail">
          <p>{{ avail.purchasePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
