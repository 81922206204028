import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { MainPaymentDocumentsComponent } from './main-payment-documents/main-payment-documents.component';
import { PaymentDocumentEditComponent } from './main-payment-documents/payment-document-edit/payment-document-edit.component';
// eslint-disable-next-line max-len
import { PaymentDocumentListFiltersComponent } from './main-payment-documents/payment-document-list/payment-document-list-filters/payment-document-list-filters.component';
import { PaymentDocumentListComponent } from './main-payment-documents/payment-document-list/payment-document-list.component';
import { PaymentDocumentService } from './main-payment-documents/payment-document.service';
import { MainPaymentRequestsComponent } from './main-payment-requests/main-payment-requests.component';
// eslint-disable-next-line max-len
import { AddAdjustmentModalComponent } from './main-payment-requests/payment-request-list/add-adjustment-modal/add-adjustment-modal.component';
// eslint-disable-next-line max-len
import { ChangeLatestPaymentDateModalComponent } from './main-payment-requests/payment-request-list/change-latest-payment-date-modal/change-latest-payment-date-modal.component';
import { AdjustmentsTableComponent } from './main-payment-requests/payment-request-list/adjustments-table/adjustments-table.component';
// eslint-disable-next-line max-len
import { PaymentRequestListFiltersComponent } from './main-payment-requests/payment-request-list/payment-request-list-filters/payment-request-list-filters.component';
import { PaymentRequestListComponent } from './main-payment-requests/payment-request-list/payment-request-list.component';
import { PaymentRequestService } from './main-payment-requests/payment-request.service';
import { MainPaymentsMenuComponent } from './main-payments-menu/main-payments-menu.component';
import { MainPaymentsRoutingModule } from './main-payments-routing.module';
import { MainPaymentsComponent } from './main-payments.component';

@NgModule({
  declarations: [
    MainPaymentsComponent,
    MainPaymentsMenuComponent,
    MainPaymentDocumentsComponent,
    PaymentDocumentListComponent,
    PaymentDocumentListFiltersComponent,
    PaymentDocumentEditComponent,
    MainPaymentRequestsComponent,
    PaymentRequestListComponent,
    PaymentRequestListFiltersComponent,
    AddAdjustmentModalComponent,
    ChangeLatestPaymentDateModalComponent,
    AdjustmentsTableComponent,
  ],
  imports: [SharedModule, MainPaymentsRoutingModule],
  providers: [PaymentDocumentService, PaymentRequestService],
  entryComponents: [AddAdjustmentModalComponent, ChangeLatestPaymentDateModalComponent],
})
export class MainPaymentsModule {}
