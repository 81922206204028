import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ConverterService } from '../../../../core/converter.service';
import { EnvironmentService } from '../../../../environment.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Status, StatusConverter } from '../../../../models/status-model';
import { PaymentDocumentFilters } from '../../../../models/filters/payment-document-filters-model';
import { PaymentDocument } from '../../../../models/payment-document-model';
import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '../../../../models/date-converter';

@JsonObject('PaymentDocument')
class PaymentDocumentDto {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('document', String, true) document: string = undefined;
  @JsonProperty('documentNumber', String, true) documentNumber: string = undefined;
  @JsonProperty('documentType', StatusConverter, true) documentType: Status = undefined;
  @JsonProperty('supplier', String, true) supplier: string = undefined;
  @JsonProperty('documentDate', DateConverter, true) documentDate: Date = undefined;
  @JsonProperty('value', Number, true) value: number = undefined;
  @JsonProperty('vat', Number, true) vat: number = undefined;
  @JsonProperty('quantity', Number, true) quantity: number = undefined;
  @JsonProperty('shipments', [Number], true) shipments: number[] = undefined;

  constructor(paymentDocument: PaymentDocument, document: string) {
    this.id = paymentDocument.id;
    this.documentNumber = paymentDocument.documentNumber;
    this.documentDate = paymentDocument.documentDate;
    this.documentType = paymentDocument.documentType;
    this.supplier = paymentDocument.supplier.code;
    this.value = paymentDocument.value;
    this.vat = paymentDocument.vat;
    this.quantity = paymentDocument.quantity;
    this.shipments = paymentDocument.shipments.map((s) => s.id);
    this.document = document;
  }
}

@Injectable()
export class PaymentDocumentService {
  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  resultsNumber = new Subject<number>();

  paymentDocumentFilters: PaymentDocumentFilters;

  getFiltered(filters: PaymentDocumentFilters): Observable<PaymentDocument[]> {
    return this.http
      .post(this.environmentService.getRestEndpoint('paymentDocuments') + '/find', this.converter.fromObjtoJSON(filters), {
        observe: 'response',
      })
      .pipe(
        map((resp: HttpResponse<any>) => {
          this.resultsNumber.next(+resp.headers.get('Total-Length'));
          return resp.body;
        }),
        map((resp: PaymentDocument[]) => resp.map((r) => this.converter.fromJSONtoObj(r, PaymentDocument)))
      );
  }

  getPaymentDocumentById(id: number): Observable<PaymentDocument> {
    return this.http
      .get(this.environmentService.getRestEndpoint('paymentDocuments') + '/' + id)
      .pipe(map((resp: PaymentDocument) => this.converter.fromJSONtoObj(resp, PaymentDocument)));
  }

  getPaymentDocumentTypes(): Observable<Status[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('paymentDocumentTypes'))
      .pipe(map((resp: Status) => this.converter.fromJSONtoObj(resp, Status)));
  }

  getPaymentDocumentStatus(): Observable<Status[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('paymentDocumentStatus'))
      .pipe(map((resp: Status) => this.converter.fromJSONtoObj(resp, Status)));
  }

  checkShipments(shipmentIds: number[]): Observable<boolean> {
    return this.http
      .post(this.environmentService.getRestEndpoint('paymentDocuments') + '/check-shipments', shipmentIds)
      .pipe(map((resp: any) => resp.exists as boolean));
  }

  savePaymentDocument(paymentDocument: PaymentDocument, document: string): Observable<any> {
    const body = new PaymentDocumentDto(paymentDocument, document);
    return this.http.post(this.environmentService.getRestEndpoint('paymentDocuments'), this.converter.fromObjtoJSON(body));
  }

  deletePaymentDocument(paymentDocumentId: number): Observable<any> {
    return this.http.delete(this.environmentService.getRestEndpoint('paymentDocuments') + '/' + paymentDocumentId);
  }
}
