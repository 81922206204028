import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Status } from '../../../../models/status-model';
import { cloneClass, isNilty } from '../../../../core/utils.service';
import { Vendor } from '../../../../models/vendor-model';
import { GenericConfirmationModalComponent } from '../../../modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { GenericErrorModalComponent } from '../../../modal/generic-error-modal/generic-error-modal.component';
import { VendorsService } from '../vendors.service';
import { VendorCentralAccount } from '@models/vendor-central-account-model';
import { VendorCentralAccountsService } from '@container/configuration/vendor-central-accounts/vendor-central-accounts.service';

@Component({
  selector: 'app-vendors-edit',
  templateUrl: './vendors-edit.component.html',
  styleUrls: ['./vendors-edit.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        // :leave is alias to '* => void'
        animate(1000, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class VendorsEditComponent implements OnInit {
  vendorForm: FormGroup;
  editMode = false;
  vendorId: number;
  currentVendor = new Vendor();
  parentVendors: Vendor[];
  checkTypes: Status[] = [];
  paymentTerms: Status[] = [];
  vendorCentralAccounts: VendorCentralAccount[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private vendorsService: VendorsService,
    private dialog: MatDialog,
    private vendorCentralAccountsService: VendorCentralAccountsService
  ) {}

  ngOnInit() {
    this.vendorForm = new FormGroup({
      code: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      brandonGln: new FormControl('', Validators.required),
      brandonWarehouseCode: new FormControl('', Validators.required),
      paymentTerms: new FormControl(Validators.required),
      defaultOrderAcceptanceMode: new FormControl('default'),
      sendSoftFirstorder: new FormControl(false),
      parent: new FormControl(true),
      parentVendor: new FormControl(),
      publicationCheckType: new FormControl(),
      vendorCentralAccount: new FormControl(),
    });

    this.setParentValidators();

    this.route.params.subscribe((params: Params) => {
      this.vendorId = +params['id'];
      this.editMode = params['id'] != null;
      if (this.editMode) {
        this.vendorsService.getVendor(this.vendorId).subscribe((vendor: Vendor) => {
          this.currentVendor = vendor;
          this.changeFormValues(this.currentVendor);
          this.setCheckTypes();
          this.setPaymentTerms();
          this.setVendorCentralAccount();
        });
      }
    });

    this.vendorsService.getPublicationCheckTypes().subscribe((checkTypes: Status[]) => {
      this.checkTypes = checkTypes;
      this.setCheckTypes();
    });

    this.vendorsService.getVendorPaymentTerms().subscribe((paymentTerms: Status[]) => {
      this.paymentTerms = paymentTerms;
      this.setPaymentTerms();
    });

    this.parentVendors = this.vendorsService.parentVendors;

    this.vendorCentralAccountsService.getVendorAccountAccounts().subscribe((resp: VendorCentralAccount[]) => {
      this.vendorCentralAccounts = resp;
      this.setVendorCentralAccount();
    });
  }

  setVendorCentralAccount() {
    if (!isNilty(this.currentVendor.vendorCentralAccount)) {
      this.vendorForm.controls['vendorCentralAccount'].patchValue(
        this.vendorCentralAccounts.find((it) => it.id === this.currentVendor.vendorCentralAccount.id)
      );
    }
  }

  setCheckTypes() {
    if (!isNilty(this.currentVendor.publicationCheckType)) {
      this.vendorForm.controls['publicationCheckType'].patchValue(
        this.checkTypes.find((it) => it.code === this.currentVendor.publicationCheckType.code)
      );
    }
  }

  setPaymentTerms() {
    if (!isNilty(this.currentVendor.paymentTerms)) {
      this.vendorForm.controls['paymentTerms'].patchValue(this.paymentTerms.find((it) => it.code === this.currentVendor.paymentTerms.code));
    }
  }

  changeFormValues(vendor: Vendor) {
    this.vendorForm.setValue({
      code: vendor.code,
      name: vendor.name,
      country: vendor.country,
      brandonGln: vendor.brandonGln,
      brandonWarehouseCode: vendor.brandonWarehouseCode,
      paymentTerms: !isNilty(vendor.paymentTerms) ? vendor.paymentTerms : null,
      defaultOrderAcceptanceMode: vendor.defaultOrderAcceptanceMode,
      sendSoftFirstorder: !isNilty(vendor.sendSoftFirstorder) ? vendor.sendSoftFirstorder : false,
      parent: vendor.parent,
      parentVendor: vendor.parent ? null : vendor.parentVendor.id,
      publicationCheckType: !isNilty(vendor.publicationCheckType) ? vendor.publicationCheckType : null,
      vendorCentralAccount: !isNilty(vendor.vendorCentralAccount) ? vendor.vendorCentralAccount : null,
    });
  }

  onCancel() {
    this.router.navigate(['configuration', 'vendors']);
  }

  onSubmit() {
    if (this.editMode) {
      this.currentVendor.id = this.vendorId;
    } else {
      this.currentVendor.id = null;
    }

    this.currentVendor.code = this.vendorForm.value.code;
    this.currentVendor.name = this.vendorForm.value.name;
    this.currentVendor.country = this.vendorForm.value.country;
    this.currentVendor.brandonGln = this.vendorForm.value.brandonGln;
    this.currentVendor.brandonWarehouseCode = this.vendorForm.value.brandonWarehouseCode;
    this.currentVendor.paymentTerms = this.vendorForm.value.paymentTerms;
    this.currentVendor.defaultOrderAcceptanceMode = this.vendorForm.value.defaultOrderAcceptanceMode;
    this.currentVendor.sendSoftFirstorder = this.vendorForm.value.sendSoftFirstorder ? this.vendorForm.value.sendSoftFirstorder : false;
    this.currentVendor.vendorCentralAccount = this.vendorForm.value.vendorCentralAccount;
    this.currentVendor.parent = this.vendorForm.value.parent;
    if (this.vendorForm.value.parent === true) {
      this.currentVendor.parentVendor = null;
    } else {
      this.currentVendor.parentVendor = this.parentVendors.find((it) => it.id === this.vendorForm.value.parentVendor);
    }
    this.currentVendor.publicationCheckType = this.vendorForm.value.publicationCheckType;

    this.vendorsService.postVendor(this.currentVendor).subscribe((resp: any) => {
      if (resp == null) {
        this.dialog.open(GenericErrorModalComponent, {
          width: '350px',
          data: 'Code ' + this.currentVendor.code + ' already exists',
        });
      } else {
        if (!this.currentVendor.parent) {
          this.vendorsService.getVendor(this.currentVendor.parentVendor.id).subscribe((parentVendor: Vendor) => {
            const updatedParent: Vendor = cloneClass(parentVendor);
            updatedParent.hasChildren = true;
            this.vendorsService.postVendor(updatedParent).subscribe(() => {
              this.router.navigate(['configuration', 'vendors']);
            });
          });
        } else {
          this.router.navigate(['configuration', 'vendors']);
        }
      }
    });
  }

  onDelete() {
    const message = 'Deleting vendor ' + this.currentVendor.code;
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '350px',
      data: message,
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.vendorsService.deleteVendor(this.vendorId).subscribe(() => {
          console.log('Vendor deleted.');
          this.router.navigate(['configuration', 'vendors']);
        });
      } else {
        console.log('Vendor not deleted.');
      }
    });
  }

  setParentValidators() {
    const parentVendor = this.vendorForm.get('parentVendor');
    this.vendorForm.get('parent').valueChanges.subscribe((parent) => {
      if (parent === false) {
        parentVendor.setValidators([Validators.required]);
      } else {
        parentVendor.clearValidators();
      }
      parentVendor.updateValueAndValidity();
    });
  }
}
