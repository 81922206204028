import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';

@JsonObject('Commodity')
export class Commodity extends BaseObject {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('exportHsCode', String, true) exportHsCode: string = undefined;
  @JsonProperty('importHsCode', String, true) importHsCode: string = undefined;
  @JsonProperty('vat', Number, true) vat: number = undefined;
  @JsonProperty('duty', Number, true) duty: number = undefined;
}
