import { Component, OnInit } from '@angular/core';
import {
  GenerateOrderedFromLsaJobStatus,
  OrderedFromLsaGenerationStep,
} from '../../../../models/job-status/generate-ordered-from-lsa-status-model';
import { GenericProgressBarDirective } from '../generic-progress-bar';

@Component({
  selector: 'app-generate-ordered-from-lsa-job-progress',
  templateUrl: './generate-ordered-from-lsa-job-progress.component.html',
  styleUrls: ['./generate-ordered-from-lsa-job-progress.component.scss'],
})
export class GenerateOrderedFromLsaJobProgressComponent extends GenericProgressBarDirective implements OnInit {
  jobStatus: GenerateOrderedFromLsaJobStatus;
  oldStatus: GenerateOrderedFromLsaJobStatus;

  TOTAL_STEPS = 9;
  GENERATION_STEP = OrderedFromLsaGenerationStep;

  constructor() {
    super();
  }

  ngOnInit() {
    this.initProgressBar();
  }

  getInternalJobStatus(job: GenerateOrderedFromLsaJobStatus): string {
    return job?.generationStatus;
  }
}
