<h2 mat-dialog-title>Change Incoming date</h2>

<mat-dialog-content>
  <div>
    <mat-form-field>
      <input matInput [matDatepicker]="incomingDate" placeholder="Incoming date" [(ngModel)]="newIncomingDate" />
      <mat-datepicker-toggle matSuffix [for]="incomingDate"></mat-datepicker-toggle>
      <mat-datepicker #incomingDate></mat-datepicker>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button class="btn-product margin-right-38" [disabled]="!incomingDate" (click)="onSubmit()">Save</button>
  <button class="btn-product" (click)="onClose()">Close</button>
</mat-dialog-actions>
