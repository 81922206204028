import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConverterService } from '../../../core/converter.service';
import { isNilty } from '../../../core/utils.service';
import { EnvironmentService } from '../../../environment.service';
import { InvoiceFilters } from '../../../models/filters/invoice-filters-model';
import { InvoiceItemsFilters } from '../../../models/filters/invoice-items-filters-model';
import { InvoiceItem } from '../../../models/invoice-item-model';
import { Invoice } from '../../../models/invoice-model';
import { InvoiceResponce } from '../../../models/invoice-responce-model';
import { GenericConfirmationModalComponent } from '../../modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { Claim } from '@models/claim-model';

@Injectable()
export class InvoiceService {
  constructor(
    private http: HttpClient,
    private converter: ConverterService,
    private environmentService: EnvironmentService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  resultsNumber = new Subject<number>();

  invoiceFilters: InvoiceFilters;

  selectedInvoices: number[] = [];

  getFilteredInvoices(filters: InvoiceFilters): Observable<Invoice[]> {
    const body = this.converter.fromObjtoJSON(filters);
    return this.http.post(this.environmentService.getRestEndpoint('invoices'), body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((resp: Invoice[]) => resp.map((it) => this.converter.fromJSONtoObj(it, Invoice)))
    );
  }

  getSingleInvoice(invId: number): Observable<Invoice> {
    const path = isDevMode()
      ? this.environmentService.getRestEndpoint('singleInvoice')
      : this.environmentService.getRestEndpoint('singleInvoice') + '/' + invId;
    return this.http.get(path).pipe(map((resp: Invoice) => this.converter.fromJSONtoObj(resp, Invoice)));
  }

  getFilteredInvoiceItems(filters: InvoiceItemsFilters): Observable<InvoiceItem[]> {
    const body = this.createFiltersBody(filters);
    return this.http.post(this.environmentService.getRestEndpoint('invoiceItems'), body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.getHeaders(resp.headers);
        return resp.body;
      }),
      map((invResp: InvoiceItem[]) => {
        if (!isNilty(invResp)) {
          return invResp.map((it) => this.converter.fromJSONtoObj(it, InvoiceItem));
        } else {
          return [];
        }
      })
    );
  }

  private createFiltersBody(filters: InvoiceItemsFilters): string {
    return this.converter.fromObjtoJSON(filters);
  }

  getHeaders(headers) {
    this.resultsNumber.next(+headers.get('Total-Length'));
  }

  sendInvoice(selectedIds: number[]): Observable<any> {
    return this.sendInvoiceByIds(selectedIds, this.environmentService.getRestEndpoint('generateInvoiceById'));
  }

  sendInvoices(selectedIds: number[], filters: InvoiceFilters, mainChecker: boolean): Observable<any> {
    if (mainChecker) {
      this.sendInvoiceFromFiltersValidation(filters).subscribe((resp: InvoiceResponce) => {
        const sentInvoices = resp.invoiceIds;
        const invalidInvoiceNumbers = resp.invalidInvoiceNumbers;
        this.dialog
          .open(GenericConfirmationModalComponent, {
            width: '400px',
            data: sentInvoices + ' invoices will be sent and the following list will not be sent: ' + invalidInvoiceNumbers,
          })
          .afterClosed()
          .subscribe((result: boolean) => {
            if (result) {
              return this.sendInvoiceFromFilters(filters).subscribe(() => {
                this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
                return 'Done';
              });
            }
          });
      });
    } else {
      return this.sendInvoiceByIds(selectedIds, this.environmentService.getRestEndpoint('generateInvoiceById'));
    }
  }

  private sendInvoiceFromFilters(filters: InvoiceFilters): Observable<any> {
    const path = this.environmentService.getRestEndpoint('generateInvoiceFromFiltersResponse');
    const body = this.converter.fromObjtoJSON(filters);

    const headers = new HttpHeaders().append('Content-Type', 'application/json');

    return this.http.post(path, body, { headers });
  }

  private sendInvoiceFromFiltersValidation(filters: InvoiceFilters): Observable<any> {
    const path = this.environmentService.getRestEndpoint('generateInvoiceFromFiltersValidation');
    const body = this.converter.fromObjtoJSON(filters);

    const headers = new HttpHeaders().append('Content-Type', 'application/json');

    return this.http
      .post(path, body, { headers })
      .pipe(map((resp: InvoiceResponce) => this.converter.fromJSONtoObj(resp, InvoiceResponce)));
  }

  private sendInvoiceByIds(selectedIds: number[], path: string): Observable<any> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(selectedIds);
    return this.http.post(path, body, { headers });
  }

  deleteInvoice(invoiceId: number): Observable<any> {
    return this.http.delete(this.environmentService.getRestEndpoint('invoices') + '/' + invoiceId);
  }

  setProbableNC(invoiceId: number, probableNC: boolean): Observable<any> {
    return this.http.post(this.environmentService.getRestEndpoint('invoices') + '/' + invoiceId + '/probable-nc/' + probableNC, null);
  }

  downloadPods(shipmentId: number): Observable<any> {
    const path = this.environmentService.getRestEndpoint('exportPods');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', Accept: 'application/zip' });
    const body = JSON.stringify(shipmentId);
    return this.http.post(path, body, { headers, observe: 'response', responseType: 'blob' as 'json' });
  }

  getClaims(invoiceNumber: string): Observable<any> {
    const path = this.environmentService.getRestEndpoint('invoiceClaim') + '/' + invoiceNumber;
    return this.http.get(path).pipe(map((resp: Claim[]) => resp));
  }
}
