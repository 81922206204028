import { isNil, isNilty } from '../core/utils.service';
import { PoFilters, poFiltersMap } from '../models/filters/po-filters-model';
import { FormControl, FormGroup } from '@angular/forms';
import { MatChipsData } from '../models/mat-chips-data-model';
import { Vendor } from '../models/vendor-model';
import { Warehouse } from '../models/warehouse-model';
import { Category } from '../models/category-model';
import { Brand } from '../models/brand-model';
import { fromDateToString } from '../models/date-converter';

export const createFullPoFiltersFormGroup = (): FormGroup =>
  new FormGroup({
    vendors: new FormControl(),
    parent: new FormControl(),
    from: new FormControl(),
    to: new FormControl(),
    destination: new FormControl(),
    category: new FormControl(),
    lotDate: new FormControl(),
    lotName: new FormControl(),
    eans: new FormControl(),
    purchaseOrder: new FormControl(),
    status: new FormControl(),
    hasLot: new FormControl(),
    brand: new FormControl(),
    acceptedDifferentFromPoa: new FormControl(),
    obsoleteWithAccepted: new FormControl(),

    latestDeliveryDateFrom: new FormControl(),
    latestDeliveryDateTo: new FormControl(),
    isAdr: new FormControl(),
  });

export const createLotEditPoFiltersFormGroup = (): FormGroup =>
  new FormGroup({
    vendors: new FormControl(),
    parent: new FormControl(),
    from: new FormControl(),
    to: new FormControl(),
    destination: new FormControl(),
    category: new FormControl(),
    eans: new FormControl(),
    purchaseOrder: new FormControl(),
    status: new FormControl(),
    brand: new FormControl(),
    acceptedDifferentFromPoa: new FormControl(),
    obsoleteWithAccepted: new FormControl(),

    latestDeliveryDateFrom: new FormControl(),
    latestDeliveryDateTo: new FormControl(),
    isAdr: new FormControl(),
  });

export const createSinglePoFiltersFormGroup = (): FormGroup =>
  new FormGroup({
    category: new FormControl(),
    eans: new FormControl(),
    brand: new FormControl(),
    acceptedDifferentFromPoa: new FormControl(),
    obsoleteWithAccepted: new FormControl(),
  });

export const setVendorsOnPoFilters = (vendors: Vendor[], parents: Vendor[], filters: PoFilters, formGroup: FormGroup): MatChipsData[] => {
  if (!isNilty(filters?.vendors)) {
    formGroup.controls['vendors'].patchValue(vendors.filter((it) => filters.vendors.includes(it.id)));
  }
  if (!isNilty(filters?.parent)) {
    formGroup.controls['parent'].patchValue(parents.find((it) => it.id === filters.parent));
  }
  return updatePoFiltersMap(filters, formGroup);
};

export const setDestinationOnPoFilters = (warehouses: Warehouse[], filters: PoFilters, formGroup: FormGroup): MatChipsData[] => {
  if (!isNilty(filters?.destinationId)) {
    formGroup.controls['destination'].patchValue(warehouses.find((it) => it.id === filters.destinationId));
  }
  return updatePoFiltersMap(filters, formGroup);
};

export const setCategoryOnPoFilters = (categories: Category[], filters: PoFilters, formGroup: FormGroup): MatChipsData[] => {
  if (!isNilty(filters?.categoryId)) {
    formGroup.controls['category'].patchValue(categories.find((it) => it.id === filters.categoryId));
  }
  return updatePoFiltersMap(filters, formGroup);
};

export const setBrandOnPoFilters = (brands: Brand[], filters: PoFilters, formGroup: FormGroup): MatChipsData[] => {
  if (!isNilty(filters?.brand)) {
    formGroup.controls['brand'].patchValue(brands.find((it) => it.name === filters.brand));
  }
  return updatePoFiltersMap(filters, formGroup);
};

export const setLatestDeliveryOnPoFilters = (filters: PoFilters, formGroup: FormGroup): MatChipsData[] => {
  if (!isNil(filters.latestDeliveryDateFrom) || !isNil(filters.latestDeliveryDateTo)) {
    formGroup.controls['latestDeliveryDateFrom'].patchValue(filters.latestDeliveryDateFrom);
    formGroup.controls['latestDeliveryDateTo'].patchValue(filters.latestDeliveryDateTo);
  }
  return updatePoFiltersMap(filters, formGroup);
};

export const updatePoFiltersMap = (filters: PoFilters, formGroup: FormGroup): MatChipsData[] =>
  poFiltersMap(
    formGroup.value.vendors,
    formGroup.value.parent,
    formGroup.value.lotName,
    filters.lotDate,
    formGroup.value.eans,
    formGroup.value.purchaseOrder,
    filters.from,
    filters.to,
    formGroup.value.destination,
    formGroup.value.category,
    formGroup.value.status,
    formGroup.value.hasLot,
    formGroup.value.brand,
    formGroup.value.acceptedDifferentFromPoa,
    formGroup.value.obsoleteWithAccepted,
    formGroup.value.latestDeliveryDateFrom,
    formGroup.value.latestDeliveryDateTo,
    formGroup.value.isAdr
  );
