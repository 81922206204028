import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';
import { Product } from './product-model';
import { Supplier } from './supplier-model';
import { Lot } from './lot-model';

@JsonObject('LotSupplierAvailability')
export class LotSupplierAvailability extends BaseObject {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('product', Product, false) product: Product = undefined;
  @JsonProperty('supplier', Supplier, false) supplier: Supplier = undefined;
  @JsonProperty('lot', Lot, false) lot: Lot = undefined;
  @JsonProperty('submittedQuantity', Number, false) submittedQuantity: number = undefined;
  @JsonProperty('submittedValue', Number, false) submittedValue: number = undefined;
  @JsonProperty('requestedQuantity', Number, false) requestedQuantity: number = undefined;
  @JsonProperty('requestedValue', Number, false) requestedValue: number = undefined;
  @JsonProperty('availableQuantity', Number, false) availableQuantity: number = undefined;
  @JsonProperty('availableValue', Number, false) availableValue: number = undefined;
  @JsonProperty('purchasePrice', Number, false) purchasePrice: number = undefined;
}
