import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter, fromDateToFiltersString } from '../date-converter';
import { Vendor } from '../vendor-model';
import { MatChipsData } from '../mat-chips-data-model';
import { isNil, isNilty } from '../../core/utils.service';

@JsonObject('RemittanceFilters')
export class RemittanceFilters {
  @JsonProperty('paymentNumber', String, true) paymentNumber: string = undefined;
  @JsonProperty('vendorId', Number, true) vendorId: number = undefined;
  @JsonProperty('dateFrom', DateConverter, true) dateFrom: Date = undefined;
  @JsonProperty('dateTo', DateConverter, true) dateTo: Date = undefined;
  @JsonProperty('invoiceNumber', String, true) invoiceNumber: string = undefined;
  @JsonProperty('code', String, true) code: string = undefined;
  @JsonProperty('remittanceId', Number, true) remittanceId: number = undefined;

  @JsonProperty('pageNumber', Number, true) pageNumber = 0;
  @JsonProperty('pageSize', Number, true) pageSize = 10;
  @JsonProperty('sortBy', String, true) sortBy: string = undefined;
  @JsonProperty('sortDirection', String, true) sortDirection: string = undefined;
}

export const remittanceFiltersMap = (
  paymentNumber: string,
  vendorCode: string,
  dateFrom: Date,
  dateTo: Date,
  invoiceNumber: string,
  code: string
): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (!isNilty(paymentNumber)) {
    map.push(new MatChipsData('Payment', paymentNumber));
  }
  if (!isNilty(vendorCode)) {
    map.push(new MatChipsData('Vendor', vendorCode));
  }
  if (!isNil(dateFrom)) {
    map.push(new MatChipsData('Date', fromDateToFiltersString(dateFrom)));
  }
  if (!isNil(dateTo)) {
    map.push(new MatChipsData('Date', fromDateToFiltersString(dateTo)));
  }
  if (!isNilty(invoiceNumber)) {
    map.push(new MatChipsData('Invoice', invoiceNumber));
  }
  if (!isNilty(code)) {
    map.push(new MatChipsData('Code', code));
  }

  return map;
};
