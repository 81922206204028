<div class="dialog-container">
  <h2 mat-dialog-title>Select suppliers</h2>
  <mat-dialog-content>
    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="check">
        <mat-header-cell *matHeaderCellDef class="checker-col">
          <mat-checkbox (change)="toggleAll($event.checked)" [ngModel]="mainChecker"></mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let supplier; let i = index" class="checker-col">
          <mat-checkbox (change)="onSelect($event.checked, supplier)" [ngModel]="supplier.checked"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="code">
        <mat-header-cell class="shipment-code-column" *matHeaderCellDef> Code </mat-header-cell>
        <mat-cell class="shipment-code-column" *matCellDef="let supplier">{{ supplier.code }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell class="shipment-code-column" *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell class="shipment-code-column" *matCellDef="let supplier">{{ supplier.name }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </mat-dialog-content>

  <div mat-dialog-actions>
    <button class="btn-product margin-right-6" [disabled]="isDisabled()" [mat-dialog-close]="selected()">Generate</button>
    <button class="btn-product" cdkFocusInitial [mat-dialog-close]="null">Cancel</button>
  </div>
</div>
