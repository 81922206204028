import { Component, OnInit, ViewChild } from '@angular/core';
import { CategoriesService } from './categories.service';
import { Category } from '../../../models/category-model';
import { Observable } from 'rxjs';
import { GenericConfirmationModalComponent } from '../../modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css'],
})
export class CategoriesComponent implements OnInit {
  newCategory: string;
  searchString: string;

  categories: Category[] = [];

  displayedColumns = ['name', 'edit', 'delete', 'showProducts'];
  dataSource: MatTableDataSource<Category>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  isEditMode = false;
  editingId: number;
  editingName: string;

  constructor(private categoriesService: CategoriesService, private snackBar: MatSnackBar, private dialog: MatDialog) {}

  ngOnInit() {
    this.resultsNumber = this.categoriesService.resultsNumber;
    this.categoriesService.getCategories(this.searchString, 20, 0).subscribe((resp: Category[]) => {
      this.categories = resp;
      this.dataSource = new MatTableDataSource(this.categories);
    });
  }

  createNewCategory() {
    this.categoriesService.newCategory(this.newCategory).subscribe(() => {
      this.snackBar.open('Created category ' + this.newCategory, 'CLOSE')._dismissAfter(5000);
      this.newCategory = undefined;
      this.searchString = undefined;
      this.search();
    });
  }

  search() {
    this.categoriesService.getCategories(this.searchString, 20, 0).subscribe((resp: Category[]) => {
      this.categories = resp;
      this.dataSource = new MatTableDataSource(this.categories);
    });
  }

  loadPage() {
    this.categoriesService
      .getCategories(this.searchString, this.paginator.pageSize, this.paginator.pageIndex)
      .subscribe((resp: Category[]) => {
        this.categories = resp;
        this.dataSource = new MatTableDataSource(this.categories);
      });
  }

  edit(c: Category) {
    this.isEditMode = true;
    this.editingId = c.id;
    this.editingName = c.name;
  }

  saveEdit(c) {
    if (c.name !== this.editingName) {
      c.name = this.editingName;
      this.categoriesService.editCategory(c).subscribe(() => {
        this.snackBar.open('Saved category ' + this.editingName, 'CLOSE')._dismissAfter(5000);
        this.isEditMode = false;
        this.editingId = undefined;
        this.editingName = undefined;
      });
    } else {
      this.snackBar.open('No changes.', 'CLOSE')._dismissAfter(5000);
      this.isEditMode = false;
      this.editingId = undefined;
      this.editingName = undefined;
    }
  }

  deleteCategory(c) {
    const message = 'Deleting category ' + c.name;
    console.log(message);

    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '400px',
      data: message,
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.categoriesService.deleteCategory(c).subscribe(() => {
          this.snackBar.open('Deleted category ' + c.name, 'CLOSE')._dismissAfter(5000);
          this.search();
        });
      } else {
      }
    });
  }
}
