import { MatChipsData } from '../mat-chips-data-model';
import { JsonObject, JsonProperty } from 'json2typescript';
import { FormGroup } from '@angular/forms';

@JsonObject('WarehouseFilters')
export class WarehouseFilters {
  @JsonProperty('code', String, true) code: string = undefined;
  @JsonProperty('country', String, true) country: string = undefined;
}

export const warehouseFiltersMap = (code?: string, country?: string): MatChipsData[] => {
  const map: MatChipsData[] = [];

  if (code !== null && code !== undefined) {
    map.push(new MatChipsData('Code', code));
  }
  if (country !== null && country !== undefined) {
    map.push(new MatChipsData('Country', country));
  }
  return map;
};

export const setFiltersFromForm = (filterForm: FormGroup, warehouseFilters: WarehouseFilters) => {
  warehouseFilters.code = filterForm.value.code?.toUpperCase();
  warehouseFilters.country = filterForm.value.country;
};
