import { DecimalPipe } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { isNilty } from '../core/utils.service';
import { fromDateToString } from '../models/date-converter';
import { CatalogItemNoProduct } from '../models/products-catalog-model';
import { publicationStatusList } from '../models/publication-status';
import { SpoStatus } from '../models/spo-model';
import { CommonsService } from './commons.service';
import { Status } from '@models/status-model';

@Pipe({
  name: 'myCurrency',
})
export class MyCurrencyPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: number): string {
    if (value == null) {
      return '';
    }
    return this.decimalPipe.transform(value, '1.2-2') + ' €';
  }
}

@Pipe({
  name: 'myMeasure',
})
export class MyMeasurePipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: number): string {
    if (value == null) {
      return ' -- ';
    }
    return this.decimalPipe.transform(value, '1.2-2');
  }
}

@Pipe({
  name: 'percentageFromDecimal',
})
export class PercentageFromDecimalPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: number): string {
    if (value == null) {
      return '';
    }
    return this.decimalPipe.transform(value * 100, '1.2-2') + ' %';
  }
}

@Pipe({
  name: 'percentage',
})
export class PercentagePipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: number): string {
    if (value == null) {
      return '';
    }
    return this.decimalPipe.transform(value, '1.2-2') + ' %';
  }
}

@Pipe({
  name: 'myDate',
})
export class MyDatePipe implements PipeTransform {
  transform(value: Date): string {
    return fromDateToString(value);
  }
}

@Pipe({
  name: 'lowestPrice',
})
export class LowestPricePipe implements PipeTransform {
  transform(value: CatalogItemNoProduct[]): number {
    if (isNilty(value)) {
      return null;
    }
    const sorted = value.sort((n1, n2) => n1.purchasePrice - n2.purchasePrice);
    return sorted.length !== 0 ? sorted[0].id : null;
  }
}

@Pipe({
  name: 'capitalizePipe',
})
export class CapitalizePipe implements PipeTransform {
  transform(value: string): string {
    if (value === null || value === undefined) {
      return '';
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}

@Pipe({
  name: 'isNullOrUndefinedOrEmptyString',
})
export class IsNullOrUndefinedOrEmptyString implements PipeTransform {
  transform(value: string): boolean {
    return value == null || false || value === '';
  }
}
@Pipe({
  name: 'isNullOrUndefined',
})
export class IsNullOrUndefined implements PipeTransform {
  transform(value: any): boolean {
    return value == null || false;
  }
}

@Pipe({
  name: 'spoStatusPipe',
})
export class SpoStatusPipe implements PipeTransform {
  constructor(private commonService: CommonsService) {
    this.commonService.getSpoStatuses().subscribe((r) => (this.spoStatuses = r));
  }
  spoStatuses: SpoStatus[] = [];
  transform(value: number): string {
    if (isNilty(value)) {
      return ' - ';
    }
    return this.spoStatuses.find((it) => it.code === value)?.description;
  }
}

@Pipe({
  name: 'publicationStatusPipe',
})
export class PublicationStatusPipe implements PipeTransform {
  transform(value: number): string {
    return publicationStatusList.find((it) => it.code === value).description;
  }
}

@Pipe({
  name: 'booleanIconPipe',
})
export class BooleanIconPipe implements PipeTransform {
  transform(value: boolean): string {
    return value ? 'fa fa-check' : 'fa fa-times sel-icon';
  }
}

@Pipe({
  name: 'psStatusPipe',
})
export class PcStatusPipe implements PipeTransform {
  transform(value: Status[]): Status[] {
    return value.filter((s) => s.code !== 'NOT_REFUNDED');
  }
}

@Pipe({
  name: 'fromCamelCase',
})
export class FromCamelCasePipe implements PipeTransform {
  transform(value: string): string {
    if (isNilty(value) || value.length === 0) {
      return '';
    }

    let out = value[0].toUpperCase();
    for (let i = 1; i < value.length; i++) {
      if (value[i] === value[i].toUpperCase() || !isNaN(+value[i])) {
        out += ' ' + value[i].toLowerCase();
      } else {
        out += value[i];
      }
    }
    return out;
  }
}

@Pipe({
  name: 'join',
})
export class JoinPipe implements PipeTransform {
  transform(values: string[]): string {
    if (isNilty(values)) {
      return null;
    }

    return values.join('; ');
  }
}

@NgModule({
  imports: [],
  declarations: [
    MyDatePipe,
    MyCurrencyPipe,
    LowestPricePipe,
    CapitalizePipe,
    IsNullOrUndefinedOrEmptyString,
    PublicationStatusPipe,
    BooleanIconPipe,
    PcStatusPipe,
    PercentageFromDecimalPipe,
    PercentagePipe,
    FromCamelCasePipe,
    MyMeasurePipe,
    SpoStatusPipe,
    IsNullOrUndefined,
    JoinPipe,
  ],
  providers: [DecimalPipe, CommonsService],
  exports: [
    MyDatePipe,
    MyCurrencyPipe,
    LowestPricePipe,
    CapitalizePipe,
    IsNullOrUndefinedOrEmptyString,
    PublicationStatusPipe,
    BooleanIconPipe,
    PcStatusPipe,
    PercentageFromDecimalPipe,
    PercentagePipe,
    PercentagePipe,
    FromCamelCasePipe,
    MyMeasurePipe,
    SpoStatusPipe,
    IsNullOrUndefined,
    JoinPipe,
  ],
})
export class PipeModule {}
