import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { InventoryService } from '../../inventory.service';
import { Router } from '@angular/router';
import { Supplier } from '../../../../models/supplier-model';
import { fromEvent, Observable } from 'rxjs';
import { SupplierFilters } from '../../../../models/filters/supplier-filters';
import { StockLocationFilters } from '../../../../models/filters/stock-location-filters-model';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { isNilty } from '../../../../core/utils.service';
import { AuthorizationService } from '../../../../core/authorization.service';
import { Permissions } from '../../../../models/permission-model';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-suppliers-list',
  templateUrl: './suppliers-list.component.html',
  styleUrls: ['./suppliers-list.component.css'],
})
export class SuppliersListComponent implements OnInit {
  dataSource: MatTableDataSource<Supplier>;

  filterText: string;

  displayedColumns = [
    'showStock',
    'name',
    'code',
    'minimalValue',
    'minimalShipmentValue',
    'shipsToAmazon',
    'transit',
    'acceptBindingOrders',
    'updatedCatalog',
    'uniqueSupplier',
    'takesBackorder',
    'takesFirstorder',
    'takesSoftFirstorder',
    'ssccPrefix',
    'sendSSCC',
    'sendsAsn',
    'asnPrefix',
    'showProducts',
  ];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  @ViewChild('filterInput', { static: true }) filterInput: ElementRef;

  hasSupplierManagement: Observable<boolean>;

  constructor(
    private inventoryService: InventoryService,
    private router: Router,
    public snackBar: MatSnackBar,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit() {
    this.resultsNumber = this.inventoryService.resultsNumber;
    if (this.inventoryService.supplierFilters === null) {
      this.inventoryService.supplierFilters = new SupplierFilters();
    }

    this.filterText = isNilty(this.inventoryService.supplierFilters.filter) ? '' : this.inventoryService.supplierFilters.filter;

    fromEvent(this.filterInput.nativeElement, 'keyup')
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        this.filterText = text;
        this.inventoryService.supplierFilters.filter = text;
        this.inventoryService.supplierFilters.pageNumber = 0;
        this.loadSuppliers();
      });

    this.dataSource = new MatTableDataSource<Supplier>([]);
    this.loadSuppliers();

    this.hasSupplierManagement = this.authorizationService.hasPermission(Permissions.SupplierManagement);
    this.hasSupplierManagement.subscribe((supplierManagement) => {
      if (supplierManagement) {
        this.displayedColumns.push('edit');
      }
    });
  }

  loadSuppliers() {
    this.inventoryService.getSuppliers(this.inventoryService.supplierFilters).subscribe((suppliers: Supplier[]) => {
      this.dataSource.data = suppliers;
    });
  }

  loadPage() {
    this.inventoryService.supplierFilters.pageNumber = this.paginator.pageIndex;
    this.inventoryService.supplierFilters.pageSize = this.paginator.pageSize;
    this.loadSuppliers();
  }

  onSort() {
    this.inventoryService.supplierFilters.sortBy = this.sort.active;
    this.inventoryService.supplierFilters.sortDirection = this.sort.direction;
    this.loadSuppliers();
  }

  getSupplierProducts(id: number) {
    this.router.navigate(['inventory/suppliers', id]);
  }

  getStockLocations(name: string) {
    this.inventoryService.stockLocationFilters = new StockLocationFilters();
    this.inventoryService.stockLocationFilters.name = name;
    this.router.navigate(['inventory/stock-locations']);
  }
}
