import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConverterService } from '../../../core/converter.service';
import { EnvironmentService } from '../../../environment.service';
import { Carrier } from '../../../models/carrier.model';
import { Contract } from '../../../models/contract.model';
import { CarrierFilters } from '../../../models/filters/carrier-filters';

@Injectable()
export class CarriersService {
  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  resultsNumber = new Subject<number>();
  carrierFilters: CarrierFilters;

  getCarriers(filters: CarrierFilters): Observable<Carrier[]> {
    return this.http
      .post(this.environmentService.getRestEndpoint('carriers') + '/filter', this.converter.fromObjtoJSON(filters), { observe: 'response' })
      .pipe(
        map((resp: HttpResponse<any>) => {
          this.resultsNumber.next(+resp.headers.get('Total-Length'));
          return resp.body;
        }),
        map((carriersResp: Carrier[]) => carriersResp.map((carrier) => this.converter.fromJSONtoObj(carrier, Carrier)))
      );
  }

  getCarrier(id: number): Observable<Carrier> {
    return this.http
      .get(this.environmentService.getRestEndpoint('carriers') + '/' + id)
      .pipe(map((carrier: Carrier) => this.converter.fromJSONtoObj(carrier, Carrier)));
  }

  getContractByCarrier(id: number): Observable<Contract[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('carriers') + '/' + id + '/contracts')
      .pipe(map((contracts: Contract[]) => contracts.map((contract) => this.converter.fromJSONtoObj(contract, Contract))));
  }

  saveCarrier(carrier: Carrier): Observable<Carrier> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = this.converter.fromObjtoJSON(carrier);
    return this.http
      .post(this.environmentService.getRestEndpoint('carriers'), body, { headers })
      .pipe(map((carrierResp: Carrier) => this.converter.fromJSONtoObj(carrierResp, Carrier)));
  }

  saveContract(contract: Contract): Observable<Contract> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = this.converter.fromObjtoJSON(contract);
    return this.http
      .post(this.environmentService.getRestEndpoint('contracts'), body, { headers })
      .pipe(map((contractResp: Contract) => this.converter.fromJSONtoObj(contractResp, Contract)));
  }

  deleteCarrier(id: number): Observable<boolean> {
    return this.http.delete(this.environmentService.getRestEndpoint('carriers') + '/' + id).pipe(map(() => true));
  }

  deleteContract(id: number): Observable<boolean> {
    return this.http.delete(this.environmentService.getRestEndpoint('contracts') + '/' + id).pipe(map(() => true));
  }
}
