import { Component, OnInit, ViewChild } from '@angular/core';
import { PublicationRuleService } from '../publication-rule.service';
import { PublicationRule } from '../../../../models/publication-rule';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-publication-rule-list',
  templateUrl: './publication-rule-list.component.html',
  styleUrls: ['./publication-rule-list.component.css'],
})
export class PublicationRuleListComponent implements OnInit {
  constructor(private publicationRuleService: PublicationRuleService) {}

  dataSource: MatTableDataSource<PublicationRule>;

  displayedColumns = ['type', 'status', 'childId', 'strategy', 'qtyThreshold', 'unitCostThreshold', 'supplier', 'sendFlatFile', 'edit'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource([]);

    this.publicationRuleService.getRules().subscribe((resp: PublicationRule[]) => {
      this.dataSource.data = resp;

      this.dataSource.filterPredicate = (data: PublicationRule, filterValue: string) =>
        data.rule.childCode.toLowerCase().indexOf(filterValue) !== -1 ||
        data.rule.supplierName.toLowerCase().indexOf(filterValue) !== -1 ||
        data.status.toExponential().indexOf(filterValue) !== -1 ||
        data.type.toLowerCase().indexOf(filterValue) !== -1;

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
}
