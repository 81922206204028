import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthorizationService } from '../core/authorization.service';
import { map } from 'rxjs/operators';
import { Permissions } from '../models/permission-model';

@Injectable()
export class SuperAuthGuardService implements CanActivate {
  constructor(private router: Router, private authorizationService: AuthorizationService) {}

  canActivate() {
    return this.authorizationService.hasPermission(Permissions.Configuration).pipe(
      map((configuration) => {
        if (configuration) {
          return true;
        } else {
          this.router.navigate(['/']);
          return false;
        }
      })
    );
  }
}
