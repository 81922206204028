<div class="row padding-top-20 padding-bottom-20">
  <div class="col-md-11">
    <legend>Generate ordered configurations</legend>
  </div>
  <div class="col-md-1">
    <button
      class="btn-custom smaller-icon-button"
      type="button"
      matTooltip="Add new"
      [disabled]="newConfig"
      (click)="addGenerateOrderedConfig()"
    >
      <i class="fa fa-plus"></i>
    </button>
  </div>

  <div class="col-md-12 generate-ordered-configuration" *ngIf="newConfig">
    <div class="row">
      <div class="col-md-2">
        <mat-form-field><input matInput placeholder="Margin" type="number" [(ngModel)]="newConfig.margin" /></mat-form-field>
      </div>
      <div class="col-md-2">
        <mat-form-field><input matInput placeholder="Virtual Margin" type="number" [(ngModel)]="newConfig.virtualMargin" /></mat-form-field>
      </div>
      <div class="col-md-2">
        <mat-form-field
          ><input matInput placeholder="Virtual Net Margin" type="number" [(ngModel)]="newConfig.virtualNetMargin"
        /></mat-form-field>
      </div>
      <div class="col-md-2">
        <mat-form-field
          ><input matInput placeholder="Iterations" type="number" [(ngModel)]="newConfig.generateOrderedIterations"
        /></mat-form-field>
      </div>
      <div class="col-md-2">
        <mat-form-field
          ><input
            matInput
            placeholder="Decrease per Iteration"
            type="number"
            [(ngModel)]="newConfig.generateOrderedDecreaseMarginPerIteration"
        /></mat-form-field>
      </div>
      <div class="col-md-2 buttons">
        <button
          class="btn-custom smaller-icon-button"
          type="button"
          matTooltip="Confirm"
          [disabled]="
            (newConfig.margin | isNullOrUndefined) ||
            (newConfig.virtualMargin | isNullOrUndefined) ||
            (newConfig.generateOrderedIterations | isNullOrUndefined) ||
            (newConfig.generateOrderedDecreaseMarginPerIteration | isNullOrUndefined)
          "
          (click)="confirmGenerateOrderedConfiguration()"
        >
          <i class="fa fa-check"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="col-md-12 generate-ordered-configuration" *ngFor="let gc of generateOrderedConfigurations; let i = index">
    <div class="row">
      <div class="col-md-2">Margin: {{ gc.margin }}</div>
      <div class="col-md-2">Virtual Margin: {{ gc.virtualMargin }}</div>
      <div class="col-md-2">Virtual Net Margin: {{ gc.virtualNetMargin }}</div>
      <div class="col-md-2">Iterations: {{ gc.generateOrderedIterations }}</div>
      <div class="col-md-2">Decrease per iteration: {{ gc.generateOrderedDecreaseMarginPerIteration }}</div>
      <div class="col-md-2 buttons">
        <button class="btn-custom smaller-icon-button" type="button" matTooltip="Remove" (click)="removeGenerateOrderedConfig(i)">
          <i class="fa fa-trash"></i>
        </button>
      </div>
    </div>
  </div>
</div>
