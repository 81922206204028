import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ShipmentFilters } from '../../../../models/filters/shipment-filters-model';
import { ShipmentItem } from '../../../../models/shipment-item-model';
import { Shipment } from '../../../../models/shipment-model';
import { addCheckers } from '../../../../shared/checkers.service';
import { ImportExportService } from '../../../../shared/import-export.service';
import { LotsService } from '../../../../shared/lots.service';
import { ShipmentsService } from '../../../../shared/shipments.service';
import { LotEditService } from '../../lot-edit.service';

@Component({
  selector: 'app-shipments-list-details-list',
  templateUrl: './shipments-list-details.component.html',
  styleUrls: ['./shipments-list-details.component.css'],
})
export class ShipmentsListDetailsComponent implements OnInit {
  constructor(
    private lotsService: LotsService,
    private shipmentsService: ShipmentsService,
    private router: Router,
    private importExportService: ImportExportService,
    private lotEditService: LotEditService
  ) {}

  dataSource: MatTableDataSource<Shipment>;

  displayedColumns = [
    'check',
    'shipmentCode',
    'orderId',
    'origin',
    'extra',
    'crossUkBorder',
    'status',
    'paymentStatus',
    'ean',
    'actualEan',
    'asin',
    'title',
    'poId',
    'brand',
    'category',
    'confirmedQuantity',
    'confirmedValue',
    'confirmedPurchasePrice',
    'virtualConfirmedPurchasePrice',
    'shippedQuantity',
    'shippedValue',
    'shippedPurchasePrice',
    'virtualShippedPurchasePrice',
    'backorder',
    'vendor',
    'destination',
    'latestDeliveryDate',
    'latestPickupDate',
    'requestForPayment',
    'requestForPaymentDate',
    'payment',
    'paymentDate',
    'shipmentDate',
    'asnCode',
    'sentAsn',
    'invoiceNumber',
    'deliveryDate',
    'ddtNumber',
    'hasTransit',
    'ukIntercoInvoice',
    'ukIntercoInvoiceValidData',
    'ukIntercoInvoiceStatus',
  ];

  mainChecker = false;

  bulkActionsActive = false;

  dataShipmentItems: any[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    this.resultsNumber = this.shipmentsService.resultsNumber;
    if (this.lotEditService.shipmentFilters === null) {
      this.lotEditService.shipmentFilters = new ShipmentFilters();
    }

    if (this.lotEditService.shipmentFilters === undefined) {
      this.lotEditService.shipmentFilters = new ShipmentFilters();
    }

    this.lotsService.getLot().subscribe(() => {
      this.lotEditService.shipmentFilters.lotId = this.lotsService.currentLot;
      this.dataSource = new MatTableDataSource([]);
      this.resetCheckers();
      this.loadShipmentItems(this.lotEditService.shipmentFilters);
    });
  }

  loadShipmentItems(filters: ShipmentFilters) {
    this.lotEditService.shipmentFilters.lotId = this.lotsService.currentLotId as any;
    this.shipmentsService.getFilteredShipmentItems(filters).subscribe((si: ShipmentItem[]) => {
      this.dataShipmentItems = addCheckers(si, this.lotEditService.selectedShipmentItems, this.mainChecker);
      this.dataSource.data = this.dataShipmentItems;
    });
  }

  loadPage() {
    this.lotEditService.shipmentFilters.pageNumber = this.paginator.pageIndex;
    this.lotEditService.shipmentFilters.pageSize = this.paginator.pageSize;
    this.loadShipmentItems(this.lotEditService.shipmentFilters);
  }

  onSort() {
    this.lotEditService.shipmentFilters.sortBy = this.sort.active;
    this.lotEditService.shipmentFilters.sortDirection = this.sort.direction;
    this.loadShipmentItems(this.lotEditService.shipmentFilters);
  }

  onSelected(value: boolean, selectedShipmentItem: any) {
    const selectedIndex = this.dataShipmentItems.indexOf(selectedShipmentItem);
    this.dataShipmentItems[selectedIndex].checked = value;
    if (value !== this.mainChecker) {
      this.lotEditService.addShipmentItemIdToSelected(this.dataShipmentItems[selectedIndex].id);
    } else {
      this.lotEditService.removeShipmentItemIdFromSelected(this.dataShipmentItems[selectedIndex].id);
    }
    this.bulkActionsActive = value || this.mainChecker;
  }

  toggleAll(value: boolean) {
    this.mainChecker = value;
    this.lotEditService.shipmentMainChecker = this.mainChecker;
    for (const i of this.dataShipmentItems) {
      i.checked = value;
    }

    this.lotEditService.selectedShipmentItems = [];
    this.bulkActionsActive = value;
  }

  applyFilter(filters: ShipmentFilters) {
    filters.lotId = this.lotsService.currentLot;
    this.paginator.pageIndex = 0;
    this.dataSource.data = [];
    this.resetCheckers();
    this.loadShipmentItems(filters);
  }

  resetCheckers() {
    this.lotEditService.selectedShipmentItems = [];
    this.mainChecker = false;
    this.bulkActionsActive = false;
  }

  exportShipmentItems(format: string) {
    this.importExportService
      .exportShipmentItems(this.lotEditService.selectedShipmentItems, format, this.mainChecker, this.lotEditService.shipmentFilters)
      .subscribe((resp) => {
        console.log(resp);
      });
  }

  openDetails(shipmentId) {
    this.router.navigate(['lot-edit', this.lotsService.currentLotId, 'shipments', shipmentId]);
  }
}
