import { Component, OnInit } from '@angular/core';
import { AmbraAutoUpdaterService } from './version/ambra-auto-updater.service';
import { Title } from '@angular/platform-browser';
import { EnvironmentService } from './environment.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(updater: AmbraAutoUpdaterService, private enviromentService: EnvironmentService, private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle('Ambra - ' + this.enviromentService.tenant);
  }
}
