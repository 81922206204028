import { Warehouse } from '../warehouse-model';
import { Vendor } from '../vendor-model';
import { MatChipsData } from '../mat-chips-data-model';
import { StockLocation } from '../stock-location-model';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('FulfilmentFilters')
export class FulfilmentFilters {
  @JsonProperty('lot', Number, true) lot: number = undefined;
  @JsonProperty('vendor', Number, true) vendor: number = undefined;
  @JsonProperty('ean', String, true) ean: string = undefined;
  @JsonProperty('po', String, true) po: string = undefined;
  @JsonProperty('destination', Number, true) destination: number = undefined;
  @JsonProperty('stockLocation', Number, true) stockLocation: number = undefined;
  @JsonProperty('crossUkBorder', Boolean, true) crossUkBorder: boolean = undefined;

  @JsonProperty('pageNumber', Number, true) pageNumber = 0;
  @JsonProperty('pageSize', Number, true) pageSize = 10;
  @JsonProperty('sortBy', String, true) sortBy: string = undefined;
  @JsonProperty('sortDirection', String, true) sortDirection: string = undefined;
}

export const fulfilFiltersMap = (
  vendor?: Vendor,
  ean?: string,
  po?: string,
  destination?: Warehouse,
  stockLocation?: StockLocation,
  crossUkBorder?: boolean
): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (vendor !== null && vendor !== undefined) {
    map.push(new MatChipsData('Vendor', vendor.code));
  }
  if (ean !== null && ean !== undefined) {
    map.push(new MatChipsData('EAN', ean));
  }
  if (po !== null && po !== undefined) {
    map.push(new MatChipsData('PO', po));
  }
  if (destination !== null && destination !== undefined) {
    map.push(new MatChipsData('Destination', destination.code));
  }
  if (stockLocation !== null && stockLocation !== undefined) {
    map.push(new MatChipsData('Stock Loc', stockLocation.name));
  }
  if (crossUkBorder) {
    map.push(new MatChipsData('Cross UK border', 'yes'));
  }
  return map;
};

export const fulfilmentsFiltersToPath = (filters: FulfilmentFilters, currentLotId: number): string => {
  const vendor = filters.vendor ? filters.vendor.toString() : '';
  const ean = filters.ean ? filters.ean : '';
  const po = filters.po ? filters.po : '';
  const destination = filters.destination ? filters.destination.toString() : '';
  const stockLocation = filters.stockLocation ? filters.stockLocation.toString() : '';
  const crossUkBorder = filters.crossUkBorder ? filters.crossUkBorder.toString() : '';
  const pageNumber = filters.pageNumber ? filters.pageNumber : 0;
  const pageSize = filters.pageSize ? filters.pageSize : 10;
  const sortBy = filters.sortBy ? filters.sortBy : 'orderedOn';
  const sortDirection = filters.sortDirection ? filters.sortDirection : 'desc';

  return (
    '?lot=' +
    currentLotId +
    '&vendor=' +
    vendor +
    '&destination=' +
    destination +
    '&ean=' +
    ean +
    '&po=' +
    po +
    '&stockLocation=' +
    stockLocation +
    '&cross-uk-border=' +
    crossUkBorder +
    '&page-number=' +
    pageNumber +
    '&page-size=' +
    pageSize +
    '&sort-by=' +
    sortBy +
    '&sort-direction=' +
    sortDirection
  );
};
