<div class="loader-overlay" *ngIf="isEditingHeight"></div>

<div class="row">
  <div class="col-md-12">
    <mat-paginator
      [length]="resultsNumber | async"
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20, 50, 100]"
      (page)="loadPage()"
    ></mat-paginator>
  </div>
</div>

<div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60" [ngClass]="{ 'hidden-overflow-table': isEditingHeight }">
  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="id" sticky>
      <mat-header-cell *matHeaderCellDef class="id-column"> ID </mat-header-cell>
      <mat-cell *matCellDef="let pallet" class="id-column">
        <p>{{ pallet.id }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="supplierPalletId" sticky>
      <mat-header-cell *matHeaderCellDef class="id-column"> Supplier Pallet ID </mat-header-cell>
      <mat-cell *matCellDef="let pallet" class="id-column">
        <p>{{ pallet.supplierPalletId }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="height" sticky>
      <mat-header-cell class="height-title" *matHeaderCellDef> Height (cm) </mat-header-cell>
      <mat-cell class="height" *matCellDef="let pallet">
        <p *ngIf="!isEditingHeight || editingPalletId != pallet.id">{{ pallet.height }}</p>

        <mat-form-field class="edit-pallet-form-field" *ngIf="isEditingHeight && editingPalletId == pallet.id">
          <input matInput type="number" [(ngModel)]="pallet.height" (keydown)="keydownEvent($event, pallet)" />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="edit" stickyEnd>
      <mat-header-cell *matHeaderCellDef class="single-line-edit-column">
        <p>Edit Pallet</p>
      </mat-header-cell>
      <mat-cell class="single-line-edit-column" *matCellDef="let pallet">
        <p>
          <button
            *ngIf="editingPalletId != pallet.id"
            class="box-shadow-none actions-button btn-fixed"
            matTooltip="Edit Pallet height"
            (click)="editPalletHeight(pallet)"
          >
            <i class="fa fa-edit" aria-hidden="true"></i>
          </button>
          <button
            *ngIf="editingPalletId == pallet.id"
            class="box-shadow-none actions-button btn-fixed margin-right-3"
            matTooltip="Save"
            (click)="savePalletHeight(pallet)"
          >
            <i class="fa fa-floppy-o" aria-hidden="true"></i>
          </button>
          <button
            *ngIf="editingPalletId == pallet.id"
            class="box-shadow-none actions-button btn-fixed"
            matTooltip="Discard changes"
            (click)="discardChanges(pallet)"
          >
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </p>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: !isEditingHeight"></mat-header-row>
    <mat-row
      *matRowDef="let pallet; columns: displayedColumns"
      [ngClass]="{ 'foreground-row': isEditingHeight && editingPalletId == pallet.id }"
    ></mat-row>
  </mat-table>
</div>
