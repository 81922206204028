import { Component, OnInit } from '@angular/core';
import { InventoryService } from '../../inventory.service';
import { Brand } from '../../../../models/brand-model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Product } from '../../../../models/product-model';
import { GenericErrorModalComponent } from '../../../modal/generic-error-modal/generic-error-modal.component';
import { Category } from '../../../../models/category-model';
import { CategoriesService } from '../../../configuration/categories/categories.service';
import { Season } from '../../../../models/season-model';
import { GenericConfirmationModalComponent } from '../../../modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { Location } from '@angular/common';
import { Permissions } from '../../../../models/permission-model';
import { AuthorizationService } from '../../../../core/authorization.service';
import { MatDialog } from '@angular/material/dialog';
import { ProductsService } from '../../../../shared/products.service';

@Component({
  selector: 'app-products-edit',
  templateUrl: './products-edit.component.html',
  styleUrls: ['./products-edit.component.css'],
})
export class ProductsEditComponent implements OnInit {
  brandList: Brand[] = [];
  brands: Subject<Brand[]> = new Subject();

  categories: Category[] = [];

  seasons: Season[] = [];

  productForm: FormGroup;

  productId: number;
  currentProduct = new Product();
  editMode = false;
  isAdmin: Observable<boolean>;
  productStatus: string[];

  constructor(
    private inventoryService: InventoryService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private dialog: MatDialog,
    private categoriesService: CategoriesService,
    private authorizationService: AuthorizationService,
    private productsService: ProductsService
  ) {}

  ngOnInit() {
    this.productForm = new FormGroup({
      ean: new FormControl('', Validators.required),
      sku: new FormControl(),
      articleCode: new FormControl(),
      title: new FormControl(),
      category: new FormControl(),
      brand: new FormControl(),
      season: new FormControl(),
      size: new FormControl(),
      colorCode: new FormControl(),
      color: new FormControl(),
      wholesalePrice: new FormControl(),
      retailPrice: new FormControl(),
      status: new FormControl(),
    });

    this.productsService.getProductsStatus().subscribe((s: string[]) => {
      this.productStatus = s;
    });

    this.route.params.subscribe((params: Params) => {
      this.isAdmin = this.authorizationService.hasPermissions([Permissions.Administration, Permissions.Configuration]);
      this.productId = +params['id'];
      this.editMode = params['id'] != null;
      if (this.editMode) {
        this.inventoryService.getProductById(this.productId).subscribe((product: Product) => {
          this.currentProduct = product;
          if (this.editMode) {
            this.productForm.controls['ean'].disable();
          }
          this.productForm.patchValue(this.currentProduct);

          const brandName = this.currentProduct && this.currentProduct.brand ? this.currentProduct.brand.name : null;
          this.inventoryService.getBrands(brandName).subscribe((brands: Brand[]) => {
            this.brands.next(brands);
            this.brandList = brands;
            this.setBrand();
          });
        });
      }
    });

    this.inventoryService.getSeasons().subscribe((seasons: Season[]) => {
      this.seasons = seasons;
      this.setSeason();
    });

    this.categoriesService.getCategories('', 10000, 0).subscribe((c: Category[]) => {
      this.categories = c;
      this.setCategory();
    });
  }

  setCategory() {
    if (this.currentProduct.category) {
      this.productForm.controls['category'].patchValue(this.categories.find((it) => it.id === this.currentProduct.category.id));
    }
  }

  setSeason() {
    if (this.currentProduct.season) {
      this.productForm.controls['season'].patchValue(this.seasons.find((it) => it.id === this.currentProduct.season.id));
    }
  }

  setBrand() {
    if (this.currentProduct.brand) {
      this.productForm.controls['brand'].patchValue(this.brandList.find((it) => it.id === this.currentProduct.brand.id));
    }
  }

  onCancel() {
    this.location.back();
  }

  onSubmit() {
    let ean: string;

    if (this.editMode) {
      this.currentProduct.id = this.productId;
      ean = this.currentProduct.ean;
    } else {
      this.currentProduct.id = null;
      ean = this.productForm.value.ean;
    }

    let upc;

    if (ean.length === 12) {
      upc = ean;
      ean = '0' + ean;
    } else if (ean.length === 13 && ean.startsWith('0')) {
      upc = ean.substring(1, ean.length);
    }

    if (this.productForm.value.brand !== '') {
      this.currentProduct.brand = this.productForm.value.brand;
    } else {
      this.currentProduct.brand = null;
    }

    if (this.productForm.value.season !== '') {
      this.currentProduct.season = this.productForm.value.season;
    } else {
      this.currentProduct.season = null;
    }

    this.currentProduct.ean = ean;
    this.currentProduct.upc = upc;
    this.currentProduct.sku = this.productForm.value.sku;
    this.currentProduct.articleCode = this.productForm.value.articleCode;
    this.currentProduct.title = this.productForm.value.title;
    this.currentProduct.category = this.productForm.value.category;
    this.currentProduct.size = this.productForm.value.size;
    this.currentProduct.colorCode = this.productForm.value.colorCode;
    this.currentProduct.color = this.productForm.value.color;
    this.currentProduct.wholesalePrice = this.productForm.value.wholesalePrice;
    this.currentProduct.retailPrice = this.productForm.value.retailPrice;
    this.currentProduct.status = this.productForm.value.status;
    this.currentProduct.hasPo = false;
    this.inventoryService.postProduct(this.currentProduct).subscribe(() => {
      this.router.navigate(['inventory', 'products']);
    });
  }

  onDelete() {
    const message = 'If the product ' + this.currentProduct.ean + ' is not associated with a poItem, it will be permanently canceled';
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '400px',
      data: message,
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.inventoryService
          .deleteProduct(this.productId)
          .toPromise()
          .then(() => {
            console.log('Product deleted.');
            this.router.navigate(['inventory', 'products']);
          })
          .catch(() => {
            const errorRef = this.dialog.open(GenericErrorModalComponent, {
              width: '350px',
              data: 'This Product has associated Suppliers and cannot be deleted.',
            });
          });
      }
    });
  }

  filterBrands(filterString: { filterText: string; filterKey: string }) {
    const brandName = this.currentProduct && this.currentProduct.brand ? this.currentProduct.brand.name : null;
    this.inventoryService.getBrands(filterString.filterText || brandName).subscribe((brands: Brand[]) => {
      this.brands.next(brands);
      this.brandList = brands;
      this.setBrand();
    });
  }
}
