import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Vendor } from '../../../../../../models/vendor-model';
import { DeliveryWindowConstraint } from '../../../../../../models/delivery-window-constraint-model';
import { VendorsService } from '../../../../../../container/configuration/vendors/vendors.service';
import { isNilty } from '../../../../../../core/utils.service';

@Component({
  selector: 'app-delivery-window-constraints-edit',
  templateUrl: './delivery-window-constraints-edit.component.html',
  styleUrls: ['./delivery-window-constraints-edit.component.css'],
})
export class DeliveryWindowConstraintsEditComponent implements OnInit, OnChanges {
  @Input() constraints: DeliveryWindowConstraint[];

  panelOpenState = false;
  vendors: Vendor[];

  constructor(private vendorService: VendorsService) {}

  ngOnInit() {
    this.vendorService.getVendors().subscribe((vendors: Vendor[]) => {
      this.vendors = vendors;
      if (this.constraints.length > 0 && !isNilty(this.vendors)) {
        this.constraints.forEach(
          (constraint) => (constraint.vendors = constraint.vendors.map((vendor) => this.vendors.find((v) => v.code === vendor.code)))
        );
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.constraints.length > 0 && !isNilty(this.vendors)) {
      this.constraints.forEach(
        (constraint) => (constraint.vendors = constraint.vendors.map((vendor) => this.vendors.find((v) => v.code === vendor.code)))
      );
    }
  }

  addConstraint() {
    const c = new DeliveryWindowConstraint();
    c.vendors = [];
    c.daysToExtend = 0;
    this.constraints.push(c);
  }

  deleteConstraint(constraint: DeliveryWindowConstraint) {
    const index = this.constraints.findIndex((it) => it === constraint);
    this.constraints.splice(index, 1);
  }
}
