import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ConverterService } from '../../../core/converter.service';
import { SupplierAvailabilityFilters } from '../../../models/filters/suppliers-availability-filters-model';
import { Observable, Subject } from 'rxjs';
import { LotSupplierAvailability } from '../../../models/lot-supplier-availability-model';
import { AvailabilityKpi } from '../../../models/availability-kpi';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../../../environment.service';
import { Supplier } from '../../../models/supplier-model';

@Injectable()
export class SupplierAvailabilityService {
  totalResults: number;
  resultsNumber = new Subject<number>();

  suppliersAvailabilities: LotSupplierAvailability[];

  kpiData = new Subject<AvailabilityKpi>();

  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  getFilteredSuppAvailabilities(filters: SupplierAvailabilityFilters): Observable<LotSupplierAvailability[]> {
    const body = this.converter.fromObjtoJSON(filters);
    return this.http.post(this.environmentService.getRestEndpoint('suppliersAvailability'), body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.totalResults = +resp.headers.get('Total-Length');
        this.getKpiFromHeaders(resp.headers);
        this.resultsNumber.next(this.totalResults);
        return resp.body;
      }),
      map((availabilities: LotSupplierAvailability[]) => {
        this.suppliersAvailabilities = [];
        for (let i = 0; i < availabilities.length; i++) {
          this.suppliersAvailabilities[i] = this.converter.fromJSONtoObj(availabilities[i], LotSupplierAvailability);
        }
        return this.suppliersAvailabilities;
      })
    );
  }

  getKpiFromHeaders(header: HttpHeaders) {
    const kpi = new AvailabilityKpi();
    kpi.submittedQuantity = +header.get('submittedQuantity');
    kpi.submittedValue = +header.get('submittedValue');
    kpi.requestedQuantity = +header.get('requestedQuantity');
    kpi.requestedValue = +header.get('requestedValue');
    kpi.availableQuantity = +header.get('availableQuantity');
    kpi.availableValue = +header.get('availableValue');
    this.kpiData.next(kpi);
  }

  getSuppliers(lotId: number): Observable<Supplier[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('suppliersAvailability') + '/suppliers?lotId=' + lotId)
      .pipe(map((r: Supplier[]) => r.map((it) => this.converter.fromJSONtoObj(it, Supplier))));
  }
}
