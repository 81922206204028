import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';
import { fromDateToString } from './date-converter';

/* eslint-disable */
export enum LotType {
  PREBUY = 'Prebuy',
  AUTO_ORDER = 'Auto Order',
}
/* eslint-enable */

@JsonObject('Lot')
export class Lot extends BaseObject {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('date', String, true) date: string = undefined;
  @JsonProperty('status', String, true) status: string = undefined;
  @JsonProperty('fulfilmentInProgress', Number, true) fulfilmentInProgress: number = undefined;
  @JsonProperty('submittedQuantity', Number, true) submittedQuantity: number = undefined;
  @JsonProperty('acceptedQuantity', Number, true) acceptedQuantity: number = undefined;
  @JsonProperty('cancelledQuantity', Number, true) cancelledQuantity: number = undefined;
  @JsonProperty('receivedQuantity', Number, true) receivedQuantity: number = undefined;
  @JsonProperty('submittedValue', Number, true) submittedValue: number = undefined;
  @JsonProperty('acceptedValue', Number, true) acceptedValue: number = undefined;
  @JsonProperty('cancelledValue', Number, true) cancelledValue: number = undefined;
  @JsonProperty('receivedValue', Number, true) receivedValue: number = undefined;
  @JsonProperty('type', String, true) type: string = undefined;

  @JsonProperty('firstShipmentGenerate', String, true) firstShipmentGenerate: string = undefined;

  @JsonProperty('lotConfigurationId', Number, true) lotConfigurationId: number = undefined;
  @JsonProperty('winningLotGenerateOrderedConfigurationId', Number, true) winningLotGenerateOrderedConfigurationId: number = undefined;
}

@JsonObject('NewLotInput')
export class NewLotInput {
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('date', String, true) date: string = undefined;
  @JsonProperty('type', String, true) type: string = undefined;

  constructor(name: string, date: any, type: string) {
    this.name = name;
    this.date = fromDateToString(date);
    this.type = type;
  }
}
