import { JsonObject, JsonProperty } from 'json2typescript';
import { Invoice } from './invoice-model';
import { Claim } from './claim-model';
import { Status } from './status-model';

@JsonObject('ClaimHeader')
export class ClaimHeader {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('invoiceNumber', String, true) invoiceNumber: string = undefined;
  @JsonProperty('statusShortageClaim', Status, true) statusShortageClaim: Status = undefined;
  @JsonProperty('statusPriceClaim', Status, true) statusPriceClaim: Status = undefined;
  @JsonProperty('invoice', Invoice, true) invoice: Invoice = undefined;
  @JsonProperty('claims', [Claim], true) claims: Claim[] = undefined;
  @JsonProperty('botEnabled', Boolean, true) botEnabled: boolean = undefined;
  @JsonProperty('settlement', Boolean, true) settlement: boolean = undefined;
}
