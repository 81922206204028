import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-invalid-shipment-origin-modal',
  templateUrl: './invalid-shipment-origin-modal.component.html',
  styleUrls: ['./invalid-shipment-origin-modal.component.css'],
})
export class InvalidShipmentOriginModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<InvalidShipmentOriginModalComponent>,
    // eslint-disable-next-line
    @Inject(MAT_DIALOG_DATA) public data: object
  ) {}

  ngOnInit() {}
}
