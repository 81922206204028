<ng-template #lastStepTimer>
  <div class="col-md-3 text-right">{{ lastStepMinutes }}:{{ lastStepSeconds }}</div>
</ng-template>

<button class="btn-lot-edit-menu" [matMenuTriggerFor]="inProgress">
  <i
    class="fa fa-spinner margin-right-6"
    [ngStyle]="{ transform: iconRotation }"
    aria-hidden="true"
    *ngIf="jobStatus?.status !== JOB_STATUS.TROUBLE"
  ></i>
  <i class="fa fa-exclamation-triangle" aria-hidden="true" *ngIf="jobStatus?.status === JOB_STATUS.TROUBLE"></i>
  Ordered From SPO
</button>
<mat-menu class="in-progress-mat-menu" #inProgress="matMenu">
  <ng-container *ngIf="!jobStatus">
    <div class="col-md-8 status"><h4>Waiting for worker response</h4></div>
  </ng-container>
  <ng-container *ngIf="jobStatus">
    <div class="container progress-content">
      <div class="row">
        <div class="col-md-12 title">ORDERED FROM SPO</div>
        <div class="col-md-12">Started at: {{ jobStatus?.startedAt }}</div>
      </div>

      <div class="row padding-top-10" *ngIf="jobStatus.status === JOB_STATUS.TROUBLE">
        <div class="col-md-8 trouble">The job seems to be stuck!</div>
        <div class="col-md-8 trouble padding-top-10" *ngIf="stuckRetry">
          <button class="btn-product" (click)="retry()">Retry</button>
        </div>
      </div>

      <div class="row padding-top-10" *ngIf="jobStatus.status === JOB_STATUS.STARTING">
        <div class="col-md-12">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="col-md-8 status">STARTING</div>
      </div>

      <div class="row padding-top-10" *ngIf="jobStatus.status === JOB_STATUS.RUNNING">
        <ng-container [ngSwitch]="jobStatus.generationStatus">
          <ng-container *ngSwitchCase="GENERATION_STEP.STARTING">
            <app-indeterminate-job-status [currentStepIndex]="1" [totalSteps]="TOTAL_STEPS" label="Starting"></app-indeterminate-job-status>
            <ng-container *ngTemplateOutlet="lastStepTimer"></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="GENERATION_STEP.FETCHING_DESTINATIONS">
            <app-indeterminate-job-status
              [currentStepIndex]="2"
              [totalSteps]="TOTAL_STEPS"
              label="Fetching Destinations"
            ></app-indeterminate-job-status>
            <ng-container *ngTemplateOutlet="lastStepTimer"></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="GENERATION_STEP.OPTIMIZING_DESTINATIONS">
            <div class="col-md-12">
              <mat-progress-bar [value]="(jobStatus.destinationIndex / jobStatus.totalDestinations) * 100"></mat-progress-bar>
            </div>
            <div class="col-md-9 status" *ngIf="jobStatus.destinationOptimizationStatus === DESTINATIONS.FETCHING_SPO_ITEMS">
              Fetching Spo Items ({{ jobStatus.destinationIndex }}/{{ jobStatus.totalDestinations }})
            </div>
            <div class="col-md-9 status" *ngIf="jobStatus.destinationOptimizationStatus === DESTINATIONS.OPTIMIZING">
              Optimizing ({{ jobStatus.destinationIndex }}/{{ jobStatus.totalDestinations }})
            </div>
            <div class="col-md-9 status" *ngIf="jobStatus.destinationOptimizationStatus === DESTINATIONS.UPDATING_SPOS">
              Updating Spos ({{ jobStatus.destinationIndex }}/{{ jobStatus.totalDestinations }})
            </div>
            <hr class="col-md-12" />
            <div class="col-md-9 status">Optimizing destinations (3/{{ TOTAL_STEPS }})</div>
            <ng-container *ngTemplateOutlet="lastStepTimer"></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="GENERATION_STEP.UPDATING_POS">
            <app-indeterminate-job-status
              [currentStepIndex]="4"
              [totalSteps]="TOTAL_STEPS"
              label="Updating POs"
            ></app-indeterminate-job-status>
            <ng-container *ngTemplateOutlet="lastStepTimer"></ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</mat-menu>
