import { JsonObject, JsonProperty } from 'json2typescript';
import { JobExecutionStatus } from './job-execution-status';

export enum FulfilmentGenerationStep {
  STARTING = 'STARTING',
  FETCHING_PO_ITEMS = 'FETCHING_PO_ITEMS',
  RETRIEVING_COMMODITIES = 'RETRIEVING_COMMODITIES',
  GENERATION = 'GENERATION',
  SPOS_THRESHOLD_CHECK = 'SPOS_THRESHOLD_CHECK',
  LSA_GENERATION = 'LSA_GENERATION',
  SAVING_FULFILMENTS = 'SAVING_FULFILMENTS',
  UPDATING_POS = 'UPDATING_POS',
}

@JsonObject('FulfilmentJobStatus')
export class FulfilmentJobStatus extends JobExecutionStatus {
  @JsonProperty('fulfilmentStatus', String, true) fulfilmentStatus: string = undefined;
  @JsonProperty('totalPoItems', Number, true) totalPoItems: number = undefined;
  @JsonProperty('retrievedCommodities', Number, true) retrievedCommodities: number = undefined;
  @JsonProperty('completedPoItems', Number, true) completedPoItems: number = undefined;
}
