<h2 mat-dialog-title>Shipment Edit</h2>

<mat-dialog-content [formGroup]="shipmentForm">
  <div>
    <mat-form-field>
      <input matInput type="number" min="0" placeholder="Number of pallets" formControlName="pallets" />
    </mat-form-field>
  </div>

  <div>
    <mat-form-field>
      <input matInput type="number" min="0" placeholder="Number of cartons" formControlName="cartons" />
    </mat-form-field>
  </div>

  <div>
    <mat-form-field>
      <input matInput type="number" min="0.0" step="0.1" placeholder="Weight" formControlName="weight" />
    </mat-form-field>
  </div>

  <div>
    <mat-form-field>
      <input matInput placeholder="DDT number" formControlName="ddt" />
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button class="btn-product margin-right-38" (click)="onSubmit()">Save</button>
  <button class="btn-product" (click)="onClose()">Close</button>
</mat-dialog-actions>
