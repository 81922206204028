import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('InvoiceItemsFilters')
export class InvoiceItemsFilters {
  @JsonProperty('invoiceId', Number, true) invoiceId = 0;

  @JsonProperty('pageNumber', Number, true) pageNumber = 0;
  @JsonProperty('pageSize', Number, true) pageSize = 10;
  @JsonProperty('sortBy', String, true) sortBy: string = undefined;
  @JsonProperty('sortDirection', String, true) sortDirection: string = undefined;
}
