import { FormControl, FormGroup } from '@angular/forms';
import { JsonObject, JsonProperty } from 'json2typescript';
import { isNilty } from '../../core/utils.service';
import { Category } from '../category-model';
import { Contract } from '../contract.model';
import { DateConverter, fromDateToFiltersString } from '../date-converter';
import { Lot } from '../lot-model';
import { MatChipsData } from '../mat-chips-data-model';
import { Status } from '../status-model';
import { StockLocation } from '../stock-location-model';
import { Supplier } from '../supplier-model';
import { Vendor } from '../vendor-model';
import { Warehouse } from '../warehouse-model';

@JsonObject('ShipmentFilters')
export class ShipmentFilters {
  @JsonProperty('shipment', String, true) shipment: string = undefined;
  @JsonProperty('asnCode', String, true) asnCode: string = undefined;
  @JsonProperty('ean', String, true) ean: string = undefined;
  @JsonProperty('originId', Number, true) originId: number = undefined;
  @JsonProperty('supplierId', Number, true) supplierId: number = undefined;
  @JsonProperty('po', [String], true) po: string[] = undefined;
  @JsonProperty('vendorId', Number, true) vendorId: number = undefined;
  @JsonProperty('destinationId', Number, true) destinationId: number = undefined;
  @JsonProperty('status', [String], true) status: string[] = undefined;
  @JsonProperty('paymentStatus', String, true) paymentStatus: string = undefined;
  @JsonProperty('lotId', Number, true) lotId: Lot = undefined;
  @JsonProperty('lotFrom', DateConverter, true) lotFrom: Date = undefined;
  @JsonProperty('lotTo', DateConverter, true) lotTo: Date = undefined;
  @JsonProperty('payment', Boolean, true) payment: boolean = undefined;
  @JsonProperty('orderIds', [String], true) orderIds: string[] = undefined;
  @JsonProperty('carrierContracts', [Number], true) carrierContracts: number[] = [];
  @JsonProperty('trackingNumber', String, true) trackingNumber: string = undefined;
  @JsonProperty('latestPickupDateFrom', DateConverter, true) latestPickupDateFrom: Date = undefined;
  @JsonProperty('latestPickupDateTo', DateConverter, true) latestPickupDateTo: Date = undefined;
  @JsonProperty('noInvoice', Boolean, true) noInvoice: boolean = undefined;
  @JsonProperty('crossUkBorder', Boolean, true) crossUkBorder: boolean = undefined;
  @JsonProperty('ukIntercoInvoice', Boolean, true) ukIntercoInvoice: boolean = undefined;
  @JsonProperty('ukIntercoInvoiceValidData', Boolean, true) ukIntercoInvoiceValidData: boolean = undefined;
  @JsonProperty('ukIntercoInvoiceStatus', String, true) ukIntercoInvoiceStatus: string = undefined;
  @JsonProperty('isAdr', Boolean, true) isAdr: boolean = undefined;
  @JsonProperty('documentsSentToSupplier', Boolean, true) documentsSentToSupplier: boolean = undefined;
  @JsonProperty('categoryId', Number, true) categoryId: number = undefined;
  @JsonProperty('outboundStatus', String, true) outboundStatus: string = undefined;
  @JsonProperty('shippedAtFrom', DateConverter, true) shippedAtFrom: Date = undefined;
  @JsonProperty('shippedAtTo', DateConverter, true) shippedAtTo: Date = undefined;

  @JsonProperty('pageNumber', Number, true) pageNumber = 0;
  @JsonProperty('pageSize', Number, true) pageSize = 10;
  @JsonProperty('sortBy', String, true) sortBy: string = undefined;
  @JsonProperty('sortDirection', String, true) sortDirection: string = undefined;
}

export const createFormGroup = () =>
  new FormGroup({
    vendor: new FormControl(),
    destination: new FormControl(),
    ean: new FormControl(),
    po: new FormControl(),
    asnCode: new FormControl(),
    supplier: new FormControl(),
    origin: new FormControl(),
    shipment: new FormControl(),
    status: new FormControl(),
    payment: new FormControl(),
    paymentStatus: new FormControl(),
    lot: new FormControl(),
    lotDateFrom: new FormControl(),
    lotDateTo: new FormControl(),
    orderIds: new FormControl(),
    carrierContracts: new FormControl(),
    trackingNumber: new FormControl(),
    latestPickupDateFrom: new FormControl(),
    latestPickupDateTo: new FormControl(),
    noInvoice: new FormControl(),
    crossUkBorder: new FormControl(),
    ukIntercoInvoice: new FormControl(),
    ukIntercoInvoiceValidData: new FormControl(),
    ukIntercoInvoiceStatus: new FormControl(),
    isAdr: new FormControl(),
    documentsSentToSupplier: new FormControl(),
    category: new FormControl(),
    outboundStatus: new FormControl(),
    shippedAtFrom: new FormControl(),
    shippedAtTo: new FormControl(),
  });

export const shipFiltersMap = (
  shipment?: string,
  asnCode?: string,
  ean?: string,
  origin?: StockLocation,
  supplier?: Supplier,
  po?: string[],
  vendor?: Vendor,
  destination?: Warehouse,
  status?: [number],
  paymentStatus?: Status,
  lot?: Lot,
  lotDateFrom?: Date,
  lotDateTo?: Date,
  payment?: string,
  ids?: number[],
  orderIds?: string[],
  carrierContracts?: [Contract],
  trackingNumber?: string,
  latestPickupDateFrom?: Date,
  latestPickupDateTo?: Date,
  noInvoice?: string,
  crossUkBorder?: boolean,
  ukIntercoInvoice?: boolean,
  ukIntercoInvoiceValidData?: boolean,
  ukIntercoInvoiceStatus?: string,
  isAdr?: boolean,
  documentsSentToSupplier?: boolean,
  category?: Category,
  outboundStatus?: string,
  shippedAtFrom?: Date,
  shippedAtTo?: Date
): MatChipsData[] => {
  const map: MatChipsData[] = [];
  // const warehouses: Warehouse[] = [];

  if (shipment !== null && shipment !== undefined) {
    map.push(new MatChipsData('Shipment', shipment));
  }
  if (asnCode !== null && asnCode !== undefined) {
    map.push(new MatChipsData('ASN', asnCode));
  }
  if (ean !== null && ean !== undefined) {
    map.push(new MatChipsData('EAN', ean));
  }
  if (origin !== null && origin !== undefined) {
    map.push(new MatChipsData('Origin', origin.code));
  }
  if (po !== null && po !== undefined) {
    map.push(new MatChipsData('PO', po.join(', ')));
  }
  if (vendor !== null && vendor !== undefined) {
    map.push(new MatChipsData('Vendor', vendor.code));
  }
  if (destination !== null && destination !== undefined) {
    map.push(new MatChipsData('Destination', destination.code));
  }
  if (status !== null && status !== undefined) {
    map.push(new MatChipsData('Status', status.length));
  }
  if (paymentStatus !== null && paymentStatus !== undefined) {
    map.push(new MatChipsData('Payment Status', paymentStatus.description));
  }
  if (!isNilty(lot)) {
    map.push(new MatChipsData('Lot', lot.name));
  }
  if (supplier !== null && supplier !== undefined) {
    map.push(new MatChipsData('Supplier', supplier.code));
  }
  if (lotDateFrom !== null && lotDateFrom !== undefined) {
    map.push(new MatChipsData('Lot Date From', fromDateToFiltersString(lotDateFrom)));
  }
  if (lotDateTo !== null && lotDateTo !== undefined) {
    map.push(new MatChipsData('Lot Date To', fromDateToFiltersString(lotDateTo)));
  }
  if (payment !== null && payment !== undefined) {
    map.push(new MatChipsData('Paid', payment));
  }
  if (ids !== null && ids !== undefined) {
    map.push(new MatChipsData('ids', ids.toString()));
  }
  if (orderIds !== null && orderIds !== undefined) {
    map.push(new MatChipsData('Order IDs', orderIds.join(', ')));
  }
  if (!isNilty(carrierContracts)) {
    map.push(new MatChipsData('Carrier Contracts', carrierContracts.map((c) => c.name).join()));
  }
  if (trackingNumber !== null && trackingNumber !== undefined) {
    map.push(new MatChipsData('Tracking Number', trackingNumber));
  }
  if (latestPickupDateFrom || latestPickupDateTo) {
    map.push(
      new MatChipsData(
        'Latest Pickup Date',
        fromDateToFiltersString(latestPickupDateFrom) + ' - ' + fromDateToFiltersString(latestPickupDateTo)
      )
    );
  }
  if (!isNilty(noInvoice)) {
    map.push(new MatChipsData('No invoice', noInvoice));
  }
  if (crossUkBorder) {
    map.push(new MatChipsData('Cross UK border', 'yes'));
  }
  if (ukIntercoInvoice) {
    map.push(new MatChipsData('Requires Uk Interco Invoice', 'yes'));
  }
  if (ukIntercoInvoiceValidData) {
    map.push(new MatChipsData('Has valid data for UK Interco invoice', 'yes'));
  }
  if (ukIntercoInvoiceStatus) {
    map.push(new MatChipsData('UK Interco invoice status', ukIntercoInvoiceStatus));
  }
  if (isAdr) {
    map.push(new MatChipsData('Is ADR', 'yes'));
  }
  if (documentsSentToSupplier != null) {
    map.push(new MatChipsData('Documents sent to Supplier', documentsSentToSupplier.toString()));
  }
  if (category !== null && category !== undefined) {
    map.push(new MatChipsData('category', category.name));
  }
  if (outboundStatus !== null && outboundStatus !== undefined) {
    map.push(new MatChipsData('Outbound Status', outboundStatus));
  }
  if (shippedAtFrom !== null && shippedAtFrom !== undefined) {
    map.push(new MatChipsData('Shipped At From', fromDateToFiltersString(shippedAtFrom)));
  }
  if (shippedAtTo !== null && shippedAtTo !== undefined) {
    map.push(new MatChipsData('Shipped At To', fromDateToFiltersString(shippedAtTo)));
  }
  return map;
};
