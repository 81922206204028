<div class="lot-kpi">
  <div class="container">
    <div class="col-md-3 background-blue text-white height-box font-row">
      <h4 class="h-padding-box">
        <b>Submitted</b>
      </h4>
      <p><b>Quantity: </b> {{ (kpiData | async)?.submittedQuantity }}</p>
      <p><b>Value: </b> {{ (kpiData | async)?.submittedValue | myCurrency }}</p>
    </div>

    <div class="col-md-3 background-light-blue text-white height-box font-row">
      <h4 class="h-padding-box">
        <b>Accepted</b>
      </h4>
      <p><b>Quantity: </b> {{ (kpiData | async)?.acceptedQuantity }}</p>
      <p><b>Value: </b> {{ (kpiData | async)?.acceptedValue | myCurrency }}</p>
    </div>

    <div class="col-md-3 background-blue text-white height-box font-row">
      <h4 class="h-padding-box">
        <b>Canceled</b>
      </h4>
      <p><b>Quantity: </b> {{ (kpiData | async)?.cancelledQuantity }}</p>
      <p><b>Value: </b> {{ (kpiData | async)?.cancelledValue | myCurrency }}</p>
    </div>

    <div class="col-md-3 background-light-blue text-white height-box font-row">
      <h4 class="h-padding-box">
        <b>Ordered</b>
      </h4>
      <p><b>Quantity: </b> {{ (kpiData | async)?.receivedQuantity }}</p>
      <p><b>Value: </b> {{ (kpiData | async)?.receivedValue | myCurrency }}</p>
    </div>

    <!--<div class="col-xs-2 background-blue text-white height-box font-row">-->
    <!--<h4 class="h-padding-box">-->
    <!--<b>Shipped</b>-->
    <!--</h4>-->
    <!--<p><b>Quantity: </b> {{ (kpiData | async)?.shippedQuantity }}</p>-->
    <!--<p><b>Value: </b> {{ (kpiData | async)?.shippedValue | myCurrency }}</p>-->
    <!--</div>-->
  </div>
</div>
