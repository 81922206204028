import { VendorCentralAccount } from './../vendor-central-account-model';
import { PaginationAndSorting } from './pagination-and-sorting-model';
import { MatChipsData } from '../mat-chips-data-model';
import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter, fromDateToFiltersString } from '@models/date-converter';

@JsonObject('DisputeBotFilters')
export class DisputeBotLogFilters extends PaginationAndSorting {
  @JsonProperty('vendorCentralAccount', String, true) vendorCentralAccount: string = undefined;
  @JsonProperty('invoiceNumber', String, true) invoiceNumber: string = undefined;
  @JsonProperty('dateFrom', DateConverter, true) dateFrom: Date = undefined;
  @JsonProperty('dateTo', DateConverter, true) dateTo: Date = undefined;
  @JsonProperty('actionType', String, true) actionType: string = undefined;
  @JsonProperty('statusType', String, true) statusType: string = undefined;
  @JsonProperty('disputeId', String, true) disputeId: string = undefined;
  @JsonProperty('caseId', String, true) caseId: string = undefined;
}

export const disputeBotLogFiltersMap = (
  vendorCentralAccount: VendorCentralAccount,
  invoiceNumber: string,
  dateFrom: Date,
  dateTo: Date,
  actionType: string,
  statusType: string,
  disputeId: string,
  caseId: string
): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (vendorCentralAccount != null) {
    map.push(new MatChipsData('Vendor Central Account', vendorCentralAccount.name));
  }
  if (invoiceNumber != null) {
    map.push(new MatChipsData('Invoice Number', invoiceNumber));
  }
  if (dateFrom != null) {
    map.push(new MatChipsData('From Date', fromDateToFiltersString(dateFrom)));
  }
  if (dateTo != null) {
    map.push(new MatChipsData('To Date', fromDateToFiltersString(dateTo)));
  }
  if (actionType != null) {
    map.push(new MatChipsData('Action Type', actionType));
  }
  if (statusType != null) {
    map.push(new MatChipsData('Status Type', statusType));
  }
  if (disputeId != null) {
    map.push(new MatChipsData('Dispute Id', disputeId));
  }
  if (caseId != null) {
    map.push(new MatChipsData('Case Id', caseId));
  }
  return map;
};
