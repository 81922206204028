import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { InventoryComponent } from './inventory.component';
import { ProductsComponent } from './products/products.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { StockLocationsComponent } from './stock-locations/stock-locations.component';
import { SupplierProductsComponent } from './supplier-products/supplier-products.component';
import { StockItemsComponent } from './stock-items/stock-items.component';
import { PublicationsComponent } from './publications/publications.component';
import { SuppliersListComponent } from './suppliers/suppliers-list/suppliers-list.component';
import { SupplierEditComponent } from './suppliers/supplier-edit/supplier-edit.component';
import { ProductsListComponent } from './products/products-list/products-list.component';
import { ProductsEditComponent } from './products/products-edit/products-edit.component';
import { StockLocationsListComponent } from './stock-locations/stock-locations-list/stock-locations-list.component';
import { StockLocationsEditComponent } from './stock-locations/stock-locations-edit/stock-locations-edit.component';
import { ProductDetailsComponent } from './products/product-details/product-details.component';
import { SupplierDiscountComponent } from './supplier-discount/supplier-discount.component';
import { CarriersComponent } from './carriers/carriers.component';
import { CarriersListComponent } from './carriers/carriers-list/carriers-list.component';
import { CarrierEditComponent } from './carriers/carrier-edit/carrier-edit.component';

const inventoryRoutes: Routes = [
  {
    path: '',
    component: InventoryComponent,
    children: [
      {
        path: 'products',
        component: ProductsComponent,
        children: [
          { path: '', component: ProductsListComponent, pathMatch: 'full' },
          { path: ':id/details', component: ProductDetailsComponent },
          { path: ':id/edit', component: ProductsEditComponent },
          { path: 'new', component: ProductsEditComponent },
        ],
      },
      {
        path: 'suppliers',
        component: SuppliersComponent,
        children: [
          { path: '', component: SuppliersListComponent, pathMatch: 'full' },
          { path: 'edit/:id', component: SupplierEditComponent },
          { path: 'new', component: SupplierEditComponent },
        ],
      },
      { path: 'supplier-discount/:suppId', component: SupplierDiscountComponent },
      { path: 'suppliers/:suppId', component: SupplierProductsComponent },
      {
        path: 'stock-locations',
        component: StockLocationsComponent,
        children: [
          { path: '', component: StockLocationsListComponent, pathMatch: 'full' },
          { path: 'edit/:id', component: StockLocationsEditComponent },
          { path: 'new', component: StockLocationsEditComponent },
        ],
      },
      { path: 'stock-locations/:id', component: StockItemsComponent },
      { path: 'publications', component: PublicationsComponent },
      {
        path: 'carriers',
        component: CarriersComponent,
        children: [
          { path: '', component: CarriersListComponent, pathMatch: 'full' },
          { path: 'edit/:id', component: CarrierEditComponent },
          { path: 'new', component: CarrierEditComponent },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(inventoryRoutes)],
  exports: [RouterModule],
  providers: [],
})
export class InventoryRoutingModule {}
