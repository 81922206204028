import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ShipmentFilters } from '../../../../models/filters/shipment-filters-model';
import { ShipmentItem } from '../../../../models/shipment-item-model';
import { addCheckers } from '../../../../shared/checkers.service';
import { ImportExportService } from '../../../../shared/import-export.service';
import { ShipmentsService } from '../../../../shared/shipments.service';

@Component({
  selector: 'app-main-shipments-details',
  templateUrl: './main-shipments-details.component.html',
  styleUrls: ['./main-shipments-details.component.css'],
})
export class MainShipmentsDetailsComponent implements OnInit {
  dataShipmentItems = [];

  dataSource;

  mainChecker = false;

  bulkActionsActive = false;

  displayedColumns = [
    'check',
    'week',
    'lot',
    'shipmentCode',
    'orderId',
    'origin',
    'extra',
    'crossUkBorder',
    'status',
    'paymentStatus',
    'ean',
    'actualEan',
    'asin',
    'title',
    'poId',
    'brand',
    'category',
    'confirmedQuantity',
    'confirmedValue',
    'confirmedPurchasePrice',
    'virtualConfirmedPurchasePrice',
    'shippedQuantity',
    'shippedValue',
    'shippedPurchasePrice',
    'virtualShippedPurchasePrice',
    'backorder',
    'vendor',
    'destination',
    'latestDeliveryDate',
    'latestPickupDate',
    'requestForPayment',
    'requestForPaymentDate',
    'payment',
    'paymentDate',
    'shipmentDate',
    'asnCode',
    'sentAsn',
    'invoiceNumber',
    'deliveryDate',
    'ddtNumber',
    'hasTransit',
    'ukIntercoInvoice',
    'ukIntercoInvoiceValidData',
    'ukIntercoInvoiceStatus',
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private shipmentsService: ShipmentsService, private router: Router, private importExportService: ImportExportService) {}

  ngOnInit() {
    this.resultsNumber = this.shipmentsService.resultsNumber;

    if (this.shipmentsService.shipmentFilters === undefined) {
      this.shipmentsService.shipmentFilters = new ShipmentFilters();
    }
    this.dataSource = new MatTableDataSource([]);
    this.resetCheckers();
    this.loadShipmentItems(this.shipmentsService.shipmentFilters);
  }

  loadShipmentItems(filters: ShipmentFilters) {
    this.shipmentsService.getFilteredShipmentItems(filters).subscribe((ship: ShipmentItem[]) => {
      this.dataShipmentItems = addCheckers(ship, this.shipmentsService.selectedShipmentItems, this.mainChecker);
      this.dataSource.data = this.dataShipmentItems;
    });
  }

  loadPage() {
    this.shipmentsService.shipmentFilters.pageNumber = this.paginator.pageIndex;
    this.shipmentsService.shipmentFilters.pageSize = this.paginator.pageSize;
    this.loadShipmentItems(this.shipmentsService.shipmentFilters);
  }

  onSort() {
    this.shipmentsService.shipmentFilters.sortBy = this.sort.active;
    this.shipmentsService.shipmentFilters.sortDirection = this.sort.direction;
    this.loadShipmentItems(this.shipmentsService.shipmentFilters);
  }

  applyFilter(filters: ShipmentFilters) {
    this.paginator.pageIndex = 0;
    this.resetCheckers();
    this.loadShipmentItems(filters);
  }

  onSelected(value: boolean, selectedShipmentItem: any) {
    const selectedIndex = this.dataShipmentItems.indexOf(selectedShipmentItem);
    this.dataShipmentItems[selectedIndex].checked = value;
    if (value !== this.mainChecker) {
      this.shipmentsService.addShipmentItemIdToSelected(this.dataShipmentItems[selectedIndex].id);
    } else {
      this.shipmentsService.removeShipmentItemIdFromSelected(this.dataShipmentItems[selectedIndex].id);
    }
    this.bulkActionsActive = value || this.mainChecker;
  }

  toggleAll(value: boolean) {
    this.mainChecker = value;
    for (const i of this.dataShipmentItems) {
      i.checked = value;
    }

    this.shipmentsService.selectedShipmentItems = [];
    this.bulkActionsActive = value;
  }

  export(format: string) {
    this.importExportService
      .exportShipmentItems(this.shipmentsService.selectedShipmentItems, format, this.mainChecker, this.shipmentsService.shipmentFilters)
      .subscribe((resp) => {
        console.log(resp);
      });
  }

  resetCheckers() {
    this.shipmentsService.selectedShipmentItems = [];
    this.mainChecker = false;
    this.bulkActionsActive = false;
  }
}
