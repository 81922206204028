import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject } from 'rxjs';
import { isNilty } from '../../../core/utils.service';
import { Carton } from '../../../models/carton-model';
import { ShipmentFilters } from '../../../models/filters/shipment-filters-model';
import { ShipmentsService } from '../../shipments.service';

const DETAILS_HEIGHT = 370;

@Component({
  selector: 'app-single-shipment-cartons',
  templateUrl: './single-shipment-cartons.component.html',
  styleUrls: ['./single-shipment-cartons.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: DETAILS_HEIGHT + 'px', minHeight: DETAILS_HEIGHT + 'px' })),
      transition('expanded <=> collapsed', animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class SingleShipmentCartonsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @Output() showItemsEvent = new EventEmitter<void>();

  @Input() printBarcode: boolean;

  @Input() shipmentId: number;

  @Input() set loadCartons(it: Subject<ShipmentFilters>) {
    it.subscribe((filters: ShipmentFilters) => {
      this.applyFilter(filters);
    });
  }

  cartons: Carton[] = [];
  filters: ShipmentFilters;

  dataSource: MatTableDataSource<Carton> = new MatTableDataSource<Carton>([]);

  mainChecker = false;

  selectedRow: Carton;

  displayedColumns = [
    'sscc',
    'totalQuantity',
    'totalValue',
    'length',
    'height',
    'width',
    'grossWeight',
    'createdAt',
    'trackingNumber',
    'printBarcode',
    'printLabel',
    'printBarcodeAndLabel',
    'showDetails',
  ];

  resultsNumber: Observable<number>;

  constructor(private shipmentsService: ShipmentsService) {}

  ngOnInit(): void {
    this.resultsNumber = this.shipmentsService.cartonsResultsNumber;
  }

  loadRows() {
    this.shipmentsService.getShipmentCartons(this.shipmentId, this.filters).subscribe((cartons: Carton[]) => {
      this.cartons = cartons;
      this.dataSource.data = this.cartons;
    });
  }

  loadPage() {
    this.filters.pageNumber = this.paginator.pageIndex;
    this.filters.pageSize = this.paginator.pageSize;
    this.loadRows();
  }

  onSort() {
    this.filters.sortBy = this.sort.active;
    this.filters.sortDirection = this.sort.direction;
    this.loadRows();
  }

  applyFilter(filters: ShipmentFilters) {
    this.filters = filters;
    this.paginator.pageIndex = 0;
    this.loadRows();
  }

  toggle(row: Carton) {
    this.selectedRow = this.selectedRow === row ? null : row;
  }

  printSSCCBarcode(carton: Carton) {
    if (!isNilty(carton.ssccBarcodeLink)) {
      window.open(carton.ssccBarcodeLink, '_blank');
    } else {
      this.shipmentsService.printSSCCBarcode(carton.id).subscribe((url: string) => {
        carton.ssccBarcodeLink = url;
        window.open(carton.ssccBarcodeLink, '_blank');
      });
    }
  }

  printLDVLabel(carton: Carton) {
    if (!isNilty(carton.labelUrl)) {
      window.open(carton.labelUrl, '_blank');
    }
  }

  printSSCCAndLdvLabel(carton: Carton) {
    this.shipmentsService.printSSCCAndLdvLabel(carton.id).subscribe((data) => {
      const barcodeBlob = new Blob([data], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(barcodeBlob);
      const anchor = document.createElement('a');
      anchor.download = carton.id + '_' + carton.trackingNumber + '_SSCC_LDV_Label.pdf';
      anchor.href = url;
      anchor.click();
    });
  }
}
