/* eslint-disable */
export const ENTITIES = {
  CATALOG: 'catalog',
  INCREMENTAL_EXTRA_QUANTITY: 'incremental-extra-quantity',
  TOTAL_EXTRA_QUANTITY: 'total-extra-quantity',
  STOCK_LOCATION: 'stock-locations',
  STOCK_LOCATION_PARTIAL: 'stock-locations-partial',
  LSA_RESPONSE: 'lsas-response',
  SPO_IMPORT_CONFIRM: 'spo-import-confirm',
  SPO_IMPORT_GENERATE: 'spo-import-generate',
  UPDATE_ORDERED: 'update-ordered',
  UPDATE_AVAILABLE: 'update-available',
  PACKING_LIST: 'packing-list',
  ALIGN_SHIPMENT_QUANTITIES: 'align-shipment-quantities',
  UPDATE_PENDING_APPROVAL_PUBLICATION: 'update-pending-approval-publication',

  GENERATE_ORDERED_SPO: 'spo',
  GENERATE_ORDERED_LSA: 'lsa',
};
