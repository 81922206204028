<div class="container table-outer">
  <div class="col-md-2"></div>

  <div class="col-md-8">
    <div class="row">
      <div class="col-md-9">
        <mat-form-field style="min-width: 100%;">
          <input matInput #filterInput placeholder="Search Ean" [value]="filterText" />
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-paginator [length]="resultsNumber | async" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()">
        </mat-paginator>
      </div>
    </div>

    <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
      <mat-table [dataSource]="dataSource" class="font-Titillium" matSort (matSortChange)="onSort()">
        <ng-container matColumnDef="supplierName">
          <mat-header-cell *matHeaderCellDef mat-sort-header="supplierName"> Supplier Name </mat-header-cell>
          <mat-cell *matCellDef="let supplierDiscount"> {{ supplierDiscount.supplier.name }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="supplierCode">
          <mat-header-cell *matHeaderCellDef mat-sort-header="supplierCode"> Supplier Code </mat-header-cell>
          <mat-cell *matCellDef="let supplierDiscount"> {{ supplierDiscount.supplier.code }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="productEan">
          <mat-header-cell *matHeaderCellDef mat-sort-header="product"> Ean </mat-header-cell>
          <mat-cell *matCellDef="let supplierDiscount"> {{ supplierDiscount.productEan }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="discountedPrice">
          <mat-header-cell *matHeaderCellDef mat-sort-header="discountedPrice"> Discounted Price </mat-header-cell>
          <mat-cell *matCellDef="let supplierDiscount"> {{ supplierDiscount.discountedPrice }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="validFrom">
          <mat-header-cell *matHeaderCellDef mat-sort-header="validFrom"> Valid From </mat-header-cell>
          <mat-cell *matCellDef="let supplierDiscount"> {{ supplierDiscount.validFrom }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="validTo">
          <mat-header-cell *matHeaderCellDef mat-sort-header="validTo"> Valid To</mat-header-cell>
          <mat-cell *matCellDef="let supplierDiscount"
            ><p>{{ supplierDiscount.validTo }}</p></mat-cell
          >
        </ng-container>

        <ng-container matColumnDef="valid">
          <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="valid"> Valid</mat-header-cell>
          <mat-cell class="boolean-column" *matCellDef="let supplierDiscount">
            <p><i [ngClass]="supplierDiscount.valid ? 'fa fa-check' : 'fa fa-times sel-icon'" aria-hidden="true"></i></p>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>
</div>
