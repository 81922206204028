import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { isNilty } from '../../../../../core/utils.service';
import { PaymentRequestService } from '../payment-request.service';
import { PaymentRequest } from '../../../../../models/payment-request-model';
import { PaymentRequestFilters } from '../../../../../models/filters/payment-request-filters-model';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddAdjustmentModalComponent } from './add-adjustment-modal/add-adjustment-modal.component';
import { ChangeLatestPaymentDateModalComponent } from './change-latest-payment-date-modal/change-latest-payment-date-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { PaymentAdjustment } from '../../../../../models/payment-adjustment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AuthorizationService } from '../../../../../core/authorization.service';
import { Permissions } from '../../../../../models/permission-model';
import { Shipment } from '../../../../../models/shipment-model';

@Component({
  selector: 'app-payment-request-list',
  templateUrl: './payment-request-list.component.html',
  styleUrls: ['./payment-request-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PaymentRequestListComponent implements OnInit {
  dataSource: MatTableDataSource<any>;

  displayedColumns = [
    'id',
    'createdAt',
    'supplier',
    'documents',
    'shipments',
    'status',
    'paymentTaxable',
    'paymentVat',
    'paymentValue',
    'paymentQuantity',
    'shipmentsPurchasePrice',
    'shipmentsValue',
    'shipmentsQuantity',
    'latestPaymentDate',
    'latestPickupDate',
    'hasAdjustment',
    'actions',
    'showAdjustments',
  ];

  resultsNumber: Observable<number>;
  selectedIds: number[] = [];
  mainChecker = false;
  bulkActionsActive = false;
  selectedRow: PaymentAdjustment;
  hasPaymentManagement: boolean;
  hasChangePaymentRequestStatus: boolean;
  isAdmin: boolean;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private paymentRequestService: PaymentRequestService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit() {
    this.authorizationService.hasPermission(Permissions.PaymentManagement).subscribe((value) => {
      this.hasPaymentManagement = value;
    });

    this.authorizationService.hasPermission(Permissions.ChangePaymentRequestStatus).subscribe((value) => {
      this.hasChangePaymentRequestStatus = value;
      if (value && !this.displayedColumns.includes('check')) {
        this.displayedColumns.splice(0, 0, 'check');
      }
    });

    this.authorizationService.hasPermission(Permissions.Administration).subscribe((value) => {
      this.isAdmin = value;
      if (value && !this.displayedColumns.includes('check')) {
        this.displayedColumns.splice(0, 0, 'check');
      }
    });

    this.resultsNumber = this.paymentRequestService.resultsNumber;

    if (isNilty(this.paymentRequestService.paymentRequestFilters)) {
      this.paymentRequestService.paymentRequestFilters = new PaymentRequestFilters();
    }

    this.paginator.pageSize = this.paymentRequestService.paymentRequestFilters.pageSize;
    this.paginator.pageIndex = this.paymentRequestService.paymentRequestFilters.pageNumber;

    this.dataSource = new MatTableDataSource([]);
    this.loadPaymentRequests(this.paymentRequestService.paymentRequestFilters);
  }

  loadPaymentRequests(filters: PaymentRequestFilters) {
    this.paymentRequestService.getFiltered(filters).subscribe((pr: PaymentRequest[]) => {
      this.dataSource.data = pr;
    });
  }

  loadPage() {
    this.paymentRequestService.paymentRequestFilters.pageNumber = this.paginator.pageIndex;
    this.paymentRequestService.paymentRequestFilters.pageSize = this.paginator.pageSize;
    this.loadPaymentRequests(this.paymentRequestService.paymentRequestFilters);
  }

  onSort() {
    this.paymentRequestService.paymentRequestFilters.sortBy = this.sort.active;
    this.paymentRequestService.paymentRequestFilters.sortDirection = this.sort.direction;
    this.loadPaymentRequests(this.paymentRequestService.paymentRequestFilters);
  }

  onFilter(filters: PaymentRequestFilters) {
    this.paginator.pageIndex = 0;
    this.loadPaymentRequests(filters);
  }

  joinDocumentNumber(pr: PaymentRequest): string {
    return pr.documents.map((p) => p.documentNumber).join(', ');
  }

  getShipmentList(pr: PaymentRequest): Shipment[] {
    const shipments: Shipment[] = [];

    pr.documents.forEach((pd) => {
      pd.shipments.forEach((s) => {
        if (!shipments.find((d) => d.id === s.id)) {
          shipments.push(s);
        }
      });
    });

    return shipments;
  }

  joinShipmentsOrderId(pr: PaymentRequest): string {
    return this.getShipmentList(pr)
      .map((s) => s.orderId)
      .join(', ');
  }

  newPaymentRequest() {
    this.router.navigate(['payments', 'requests', 'new']);
  }

  delete(pr: PaymentRequest) {
    this.paymentRequestService.deletePaymentDocument(pr.id).subscribe(() => {
      this.snackBar.open('Payment request ' + pr.id + '.', 'CLOSE')._dismissAfter(2000);
      this.loadPaymentRequests(this.paymentRequestService.paymentRequestFilters);
    });
  }

  addAdjustment(pr: PaymentRequest) {
    const dialogRef = this.dialog.open(AddAdjustmentModalComponent, { width: '400px' });

    dialogRef.afterClosed().subscribe((paymentAdjustment: PaymentAdjustment) => {
      if (!isNilty(paymentAdjustment)) {
        paymentAdjustment.paymentRequest = pr;
        this.paymentRequestService.savePaymentAdjustment(paymentAdjustment).subscribe(() => {
          this.snackBar.open('Payment Adjustment added.', '')._dismissAfter(2000);
          this.loadPaymentRequests(this.paymentRequestService.paymentRequestFilters);
        });
      }
    });
  }

  toggle(row: PaymentAdjustment) {
    this.selectedRow = this.selectedRow === row ? null : row;
  }

  adjustmentDeleted(adjustmentId: number) {
    this.paymentRequestService.deletePaymentAdjustment(adjustmentId).subscribe(() => {
      this.snackBar.open('Payment Adjustment deleted.', '')._dismissAfter(2000);
      this.loadPaymentRequests(this.paymentRequestService.paymentRequestFilters);
    });
  }

  approve(pr: PaymentRequest) {
    this.paymentRequestService.approvePaymentRequest(pr.id).subscribe(() => {
      this.snackBar.open('Payment Request approved.', '')._dismissAfter(2000);
      this.loadPaymentRequests(this.paymentRequestService.paymentRequestFilters);
    });
  }

  cancel(pr: PaymentRequest) {
    this.paymentRequestService.cancelPaymentRequest(pr.id).subscribe(() => {
      this.snackBar.open('Payment Request unapproved.', '')._dismissAfter(2000);
      this.loadPaymentRequests(this.paymentRequestService.paymentRequestFilters);
    });
  }

  validate(pr: PaymentRequest) {
    this.paymentRequestService.validatePaymentRequest(pr.id).subscribe(() => {
      this.snackBar.open('Payment Request validated.', '')._dismissAfter(2000);
      this.loadPaymentRequests(this.paymentRequestService.paymentRequestFilters);
    });
  }

  generateDetails(pr: PaymentRequest) {
    this.paymentRequestService.generatePaymentDetails(pr.id).subscribe((data) => {
      const barcodeBlob = new Blob([data], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(barcodeBlob);
      const anchor = document.createElement('a');
      anchor.download = 'PAYMENT_REQUEST_' + pr.id + '_' + pr.supplier.code + '.zip';
      anchor.href = url;
      anchor.click();

      if (pr.status.editable) {
        this.loadPaymentRequests(this.paymentRequestService.paymentRequestFilters);
      }
    });
  }

  onSelected(value: boolean, selectedClaim: any) {
    const selectedIndex = this.dataSource.data.findIndex((a) => a.id === selectedClaim.id);
    this.dataSource.data[selectedIndex].checked = value;
    if (this.mainChecker) {
      this.mainChecker = false;
    }
    if (value !== this.mainChecker) {
      this.selectedIds.push(this.dataSource.data[selectedIndex].id);
      this.selectedIds.filter((v, i, a) => a.indexOf(v) === i);
    } else {
      const index = this.selectedIds.indexOf(this.dataSource.data[selectedIndex].id);
      this.selectedIds.splice(index, 1);
    }
    this.bulkActionsActive = this.dataSource.data.some((a) => a.checked) || this.mainChecker;
  }

  toggleAll(value: boolean) {
    this.mainChecker = value;
    this.dataSource.data.forEach((a) => (a.checked = value));
    this.selectedIds = [];
    this.bulkActionsActive = value;
  }

  resetCheckers() {
    this.selectedIds = [];
    this.mainChecker = false;
    this.bulkActionsActive = false;
  }

  changeStatus(status: string) {
    let request: Observable<any>;
    if (this.mainChecker) {
      request = this.paymentRequestService.changeStatusFromFilters(this.paymentRequestService.paymentRequestFilters, status);
    } else {
      request = this.paymentRequestService.changeStatus(this.selectedIds, status);
    }

    request.subscribe(() => {
      this.snackBar.open('Status changed to ' + status + '.', '')._dismissAfter(2000);
      this.loadPaymentRequests(this.paymentRequestService.paymentRequestFilters);
      this.resetCheckers();
    });
  }

  changeValidToPending(paymentRequest: PaymentRequest) {
    const request = this.paymentRequestService.changeStatusValidToPending(paymentRequest.id);
    request.subscribe(() => {
      this.snackBar.open('Status changed to pending.', '')._dismissAfter(2000);
      this.loadPaymentRequests(this.paymentRequestService.paymentRequestFilters);
      this.resetCheckers();
    });
  }

  showApproveButton(paymentRequest: PaymentRequest): boolean {
    if (paymentRequest.status.approvable) {
      const mail = this.authorizationService.currentUserMail;
      if (paymentRequest.status.code === 'APPROVAL_CATEGORY_PENDING') {
        return (
          paymentRequest.supplier.executiveManagerReceivers?.includes(mail) ||
          paymentRequest.supplier.categoryManagerReceivers?.includes(mail)
        );
      } else if (paymentRequest.status.code === 'APPROVAL_EXECUTIVE_PENDING') {
        return paymentRequest.supplier.executiveManagerReceivers?.includes(mail);
      }
    }
    return false;
  }

  changeLatestPaymentDate(prId: number, prLpd: Date) {
    const dialogRef = this.dialog.open(ChangeLatestPaymentDateModalComponent, { width: '250px', data: { prId, prLpd } });

    dialogRef.afterClosed().subscribe((data) => {
      if (data !== undefined) {
        this.dataSource.data = this.dataSource.data.filter((value) => {
          if (value.id === prId) {
            value.latestPaymentDate = data;
          }
          return true;
        });
      }
    });
  }
}
