import { Component, OnInit, Output } from '@angular/core';
import { Brand } from '../../../../models/brand-model';
import { Observable, Subject } from 'rxjs';
import { Season } from '../../../../models/season-model';
import { FormControl, FormGroup } from '@angular/forms';
import { MatChipsData } from '../../../../models/mat-chips-data-model';
import { HttpClient } from '@angular/common/http';
import { ConverterService } from '../../../../core/converter.service';
import { InventoryService } from '../../inventory.service';
import { CatalogItemsFilters, catalogItemsFiltersMap } from '../../../../models/filters/catalog-items-filters-model';
import { map, startWith } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { isNilty } from '../../../../core/utils.service';
import { ProductsFilters } from '../../../../models/filters/products-filters-model';

@Component({
  selector: 'app-supplier-products-filters',
  templateUrl: './supplier-products-filters.component.html',
  styleUrls: ['./supplier-products-filters.component.css'],
})
export class SupplierProductsFiltersComponent implements OnInit {
  brands: Brand[] = [];
  filteredBrands: Observable<Brand[]>;

  seasons: Season[] = [];
  filteredSeasons: Observable<Season[]>;

  filterForm: FormGroup;
  public openFilter = false;
  filtersMap: MatChipsData[] = [];

  filters: CatalogItemsFilters;

  @Output() onFilterEvent = new Subject<CatalogItemsFilters>();

  supplierId: number;

  constructor(
    private http: HttpClient,
    private converter: ConverterService,
    private inventoryService: InventoryService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.filters = isNilty(this.inventoryService.catalogItemsFilters)
      ? new CatalogItemsFilters()
      : this.inventoryService.catalogItemsFilters;
    this.filtersMap = catalogItemsFiltersMap();
    this.filterForm = new FormGroup({
      brand: new FormControl(),
      season: new FormControl(),
      ean: new FormControl(),
      status: new FormControl(),
    });

    this.supplierId = this.route.snapshot.params['suppId'];

    this.inventoryService.getSupplierBrands(this.supplierId).subscribe((brands: Brand[]) => {
      this.brands = brands;
      this.filteredBrands = this.filterForm.controls.brand.valueChanges.pipe(
        startWith<string | Brand>(''),
        map((value) => (typeof value === 'string' ? value : value ? value.name : '')),
        map((name) => (name ? this.autocompleteBrandFilter(name) : this.brands.slice()))
      );
    });

    this.inventoryService.getSeasons().subscribe((seasons: Season[]) => {
      this.seasons = seasons;
      this.filteredSeasons = this.filterForm.controls.season.valueChanges.pipe(
        startWith<string | Season>(''),
        map((value) => (typeof value === 'string' ? value : value ? value.period : '')),
        map((period) => (period ? this.autocompleteSeasonFilter(period) : this.seasons.slice()))
      );
    });
  }

  brandDisplayFn(brand?: Brand): string {
    return brand ? brand.name : '';
  }

  seasonDisplayFn(seas?: Season): string {
    return seas ? seas.period : '';
  }

  autocompleteBrandFilter(val: string): Brand[] {
    return this.brands.filter((brand) => brand.name.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }

  autocompleteSeasonFilter(val: string): Season[] {
    return this.seasons.filter((cat) => cat.period.indexOf(val) === 0);
  }

  onFilter() {
    this.filtersMap = catalogItemsFiltersMap(
      this.filterForm.value.brand ? this.filterForm.value.brand.name : null,
      this.filterForm.value.season ? this.filterForm.value.season.period : null,
      this.filterForm.value.ean,
      this.filterForm.value.status
    );
    this.saveFiltersCatalogItem();
    this.onFilterEvent.next(this.filters);
  }

  saveFiltersCatalogItem() {
    this.filters.ean = this.filterForm.value.ean;
    this.filters.status = this.filterForm.value.status;
    this.filters.brandId = this.filterForm.value.brand ? this.filterForm.value.brand.id : null;
    this.filters.seasonId = this.filterForm.value.season ? this.filterForm.value.season.id : null;
  }

  resetFilters() {
    this.filterForm.reset();
    this.filtersMap = [];
    this.saveFiltersCatalogItem();
    this.onFilterEvent.next(this.filters);
  }

  openEvent() {
    this.openFilter = !this.openFilter;
  }

  closeEvent() {
    this.openFilter = false;
  }

  saveFilters() {
    const brandId = this.filterForm.value.brand ? this.filterForm.value.brand.id : null;
    const seasonId = this.filterForm.value.season ? this.filterForm.value.season.id : null;

    this.inventoryService.catalogItemsFilters = {
      ids: this.filterForm.value.ids,
      supplierId: this.filterForm.value.supplierId,
      brandId,
      seasonId,
      ean: this.filterForm.value.ean,
      status: this.filterForm.value.status,
      pageNumber: null,
      pageSize: null,
      sortBy: null,
      sortDirection: null,
    };
  }
}
