import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PrioritySupplier } from '@models/publication-rule';
import { Supplier } from '@models/supplier-model';

@Component({
  selector: 'app-priority-supplier-list',
  templateUrl: './priority-supplier-list.component.html',
  styleUrls: ['./priority-supplier-list.component.css'],
})
export class PrioritySupplierListComponent implements OnInit {
  @Input() prioritySuppliers: PrioritySupplier[];
  @Output() saveButtonDisabled = new EventEmitter<boolean>();

  suppliers: Supplier[] = [];

  constructor() {}

  ngOnInit(): void {}

  onDisableSaveButton(disable: boolean) {
    this.saveButtonDisabled.emit(disable);
  }
}
