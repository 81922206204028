import { PreloadingStrategy, Route } from '@angular/router';
import { flatMap } from 'rxjs/operators';
import { Observable, of, timer } from 'rxjs';
import { isDevMode } from '@angular/core';

export class AppPreloadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: () => any): Observable<any> {
    return !isDevMode() && route.data && route.data.preload ? timer(100).pipe(flatMap((_) => load())) : of(null);
  }
}
