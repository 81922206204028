<mat-toolbar class="lot-edit-bulk-actions">
  <div class="container">
    <div class="row">
      <div class="col-xs-10">
        <button [disabled]="!bulkActionsActive" (click)="delete()" class="box-shadow-none btn-custom margin-right-6">Delete</button>

        <button class="box-shadow-none btn-custom margin-right-6" (click)="importUpdateAvailable()">Update available</button>
        <input #getUpdateAvailable type="file" style="display: none;" onclick="this.value = null" (change)="fileChanged($event)" />
      </div>

      <div class="col-xs-2 text-right">
        <button [disabled]="!bulkActionsActive" [matMenuTriggerFor]="exportChoice" class="box-shadow-none btn-custom">
          Export selected rows
        </button>
        <mat-menu #exportChoice="matMenu">
          <button mat-menu-item (click)="exportExcel('excel')">Save as Excel</button>
          <button mat-menu-item (click)="exportExcel('csv')">Save as CSV</button>
        </mat-menu>
      </div>
    </div>
  </div>
</mat-toolbar>
