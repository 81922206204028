import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-extra-quantity-modal',
  templateUrl: './extra-quantity-modal.component.html',
  styleUrls: ['./extra-quantity-modal.component.css']
})
export class ExtraQuantityModalComponent {

  constructor(
    public dialogRef: MatDialogRef<ExtraQuantityModalComponent>
  ) { }

}
