import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ImportExportService } from '../../../../shared/import-export.service';
import { InventoryService } from '../../inventory.service';
import { StockLocation } from '../../../../models/stock-location-model';
import { fromEvent, Observable } from 'rxjs';
import { StockLocationFilters } from '../../../../models/filters/stock-location-filters-model';
import { ENTITIES } from '../../../../entities';
import { isNilty } from '../../../../core/utils.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-stock-locations-list',
  templateUrl: './stock-locations-list.component.html',
  styleUrls: ['./stock-locations-list.component.css'],
})
export class StockLocationsListComponent implements OnInit {
  dataSource: MatTableDataSource<StockLocation>;

  filterText: string;

  displayedColumns = [
    'name',
    'code',
    'supplier',
    'address',
    'city',
    'province',
    'transitWarehouse',
    'enableWarehouseFulfilment',
    'showProducts',
    'edit',
  ];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  @ViewChild('filterInput', { static: true }) filterInput: ElementRef;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @ViewChild('getFullFeedFile', { static: false }) getFullFeedFile: ElementRef;
  @ViewChild('getPartialFeedFile', { static: false }) getPartialFeedFile: ElementRef;

  currentFileUpload: File;

  constructor(
    private inventoryService: InventoryService,
    private router: Router,
    public snackBar: MatSnackBar,
    private importExportService: ImportExportService
  ) {}

  ngOnInit() {
    this.resultsNumber = this.inventoryService.resultsNumber;
    if (this.inventoryService.stockLocationFilters === null) {
      this.inventoryService.stockLocationFilters = new StockLocationFilters();
    }

    this.filterText = isNilty(this.inventoryService.stockLocationFilters.name) ? '' : this.inventoryService.stockLocationFilters.name;

    fromEvent(this.filterInput.nativeElement, 'keyup')
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        this.filterText = text;
        this.inventoryService.stockLocationFilters.name = text;
        this.inventoryService.stockLocationFilters.pageNumber = 0;
        this.loadStockLocations();
      });

    this.dataSource = new MatTableDataSource<StockLocation>([]);
    this.loadStockLocations();
  }

  onFilter() {
    this.inventoryService.stockLocationFilters.pageNumber = 0;
    this.paginator.pageIndex = 0;
    this.loadStockLocations();
  }

  private loadStockLocations() {
    this.inventoryService.getFilteredStockLocations(this.inventoryService.stockLocationFilters).subscribe((sl: StockLocation[]) => {
      this.dataSource.data = sl;
    });
  }

  loadPage() {
    this.inventoryService.stockLocationFilters.pageNumber = this.paginator.pageIndex;
    this.inventoryService.stockLocationFilters.pageSize = this.paginator.pageSize;
    this.loadStockLocations();
  }

  onSort() {
    this.inventoryService.stockLocationFilters.sortBy = this.sort.active;
    this.inventoryService.stockLocationFilters.sortDirection = this.sort.direction;
    this.loadStockLocations();
  }

  getStockProducts(id: number) {
    this.router.navigate(['inventory/stock-locations', id]);
  }

  importFullFeed() {
    this.getFullFeedFile.nativeElement.click();
  }

  importPartialFeed() {
    this.getPartialFeedFile.nativeElement.click();
  }

  fileChanged(event, fullFeed: boolean) {
    this.currentFileUpload = event.target.files[0];

    this.snackBar.open('Uploading', '')._dismissAfter(3000);

    const entity = fullFeed ? ENTITIES.STOCK_LOCATION : ENTITIES.STOCK_LOCATION_PARTIAL;

    this.importExportService.uploadFile(this.currentFileUpload, entity).subscribe(() => {});
  }
}
