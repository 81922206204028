<h2 mat-dialog-title>
  <span class="dialog-title">POA history for PO {{ poi?.purchaseOrder.poId }} and EAN {{ poi?.product.ean }}</span>
  <span class="dialog-close-icon padding-right-30"
    ><i class="fa fa-times exit-icon margin-bottom-10" (click)="close()" style="cursor: pointer;"></i
  ></span>
</h2>

<div mat-dialog-content>
  <div class="row header text-blue" *ngIf="poi">
    <div class="col-md-12">
      <span class="margin-right-10"><b>PO:</b> {{ poi.purchaseOrder.poId }}</span>
      | <span class="margin-left-10 margin-right-10"><b>EAN:</b> {{ poi.product.ean }}</span> |
      <span class="margin-left-10 margin-right-10"><b>First POA:</b> {{ poi.firstPoaDate }}</span> |
      <span class="margin-left-10 margin-right-10"><b>Last POA:</b> {{ poi.lastPoaDate }}</span> |
      <span class="margin-left-10 margin-right-10"><b>Accepted:</b> {{ poi.acceptedQuantity }}</span> |
      <span class="margin-left-10 margin-right-10"><b>Last POA quantity:</b> {{ poi.lastPoaQuantity }}</span>
    </div>
  </div>

  <hr />

  <div class="row height-90">
    <div class="col-md-12 height-90">
      <mat-paginator
        #paginator
        [hidePageSize]="true"
        [length]="poi?.convertedPoaLogs?.length"
        [pageSize]="10"
        class="container"
      ></mat-paginator>

      <div class="table-container">
        <mat-table [dataSource]="poaItemLogsDataSource">
          <ng-container matColumnDef="downloadEdi" sticky>
            <mat-header-cell *matHeaderCellDef class="actions-edit-column"> </mat-header-cell>
            <mat-cell *matCellDef="let poai" class="actions-edit-column">
              <button
                (click)="downloadEdi(poai.fileUrl)"
                matTooltip="Download EDI"
                *ngIf="poai.fileUrl"
                class="btn-custom smaller-icon-button"
              >
                <i class="fa fa-cloud-download" aria-hidden="true"></i>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="sentDate">
            <mat-header-cell *matHeaderCellDef> Sent on </mat-header-cell>
            <mat-cell *matCellDef="let poai">
              <p>{{ poai.sentDate }}</p>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="acceptedQuantity">
            <mat-header-cell *matHeaderCellDef class="quantity-column-title"> Accepted </mat-header-cell>
            <mat-cell *matCellDef="let poai" class="quantity-column">
              <p>{{ poai.acceptedQuantity }}</p>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="backOrderQuantity">
            <mat-header-cell *matHeaderCellDef class="quantity-column-title"> Backordered </mat-header-cell>
            <mat-cell *matCellDef="let poai" class="quantity-column">
              <p>{{ poai.backOrderQuantity }}</p>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="softRejectedQuantity">
            <mat-header-cell *matHeaderCellDef class="quantity-column-title">
              Soft <br />
              rejected
            </mat-header-cell>
            <mat-cell *matCellDef="let poai" class="quantity-column">
              <p>{{ poai.softRejectedQuantity }}</p>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="hardRejectedQuantity">
            <mat-header-cell *matHeaderCellDef class="quantity-column-title">
              Hard <br />
              rejected
            </mat-header-cell>
            <mat-cell *matCellDef="let poai" class="quantity-column">
              <p>{{ poai.hardRejectedQuantity }}</p>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="poaLogsColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: poaLogsColumns"></mat-row>
        </mat-table>
      </div>
    </div>
  </div>
</div>
