import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { lotTypes } from '../../../models/constants';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LotsService } from '../../../shared/lots.service';
import { Lot, NewLotInput } from '../../../models/lot-model';
import { isNilty } from '../../../core/utils.service';

@Component({
  selector: 'app-new-lot-modal',
  templateUrl: './new-lot-modal.component.html',
  styleUrls: ['./new-lot-modal.component.css'],
})
export class NewLotModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<NewLotModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { currentLotId: number },
    private lotsService: LotsService
  ) {}

  currentLotId = this.data.currentLotId;

  lots: Lot[] = [];
  selectedLotId: number;

  lotNameForm: FormGroup;
  types = lotTypes;

  creatingNewLot = true;

  ngOnInit() {
    this.searchLots({ filterText: '', filterKey: '' });
    if (this.creatingNewLot) {
      this.createNewLot();
    }
  }

  private createNewLot() {
    this.creatingNewLot = true;
    this.selectedLotId = undefined;
    const currentDate = new Date();

    this.lotNameForm = new FormGroup({
      name: new FormControl(this.convertDateToLotName(currentDate), [Validators.required, Validators.pattern('[^\\s]+')]),
      date: new FormControl(currentDate, Validators.required),
      type: new FormControl(lotTypes[1], Validators.required),
    });
  }

  private chooseExisting() {
    this.lotNameForm = undefined;
    this.creatingNewLot = false;
    this.searchLots({ filterText: '', filterKey: '' });
  }

  searchLots(filterString: { filterText: string; filterKey: string }) {
    this.lotsService.searchOpenLotsByName(filterString.filterText).subscribe((resp: Lot[]) => {
      if (isNilty(this.currentLotId)) {
        this.lots = resp;
      } else {
        this.lots = resp.filter((it) => it.id !== this.currentLotId);
      }
    });
  }

  private convertDateToLotName(date: Date): string {
    if (date == null) {
      return null;
    } else {
      const day = date.getDate();
      let dayString = day.toString();
      if (day < 10) {
        dayString = '0' + dayString;
      }
      const month = date.getMonth() + 1;
      let monthString = month.toString();
      if (month < 10) {
        monthString = '0' + monthString;
      }

      const year = date.getFullYear().toString();

      return dayString + '/' + monthString + '/' + year;
    }
  }

  saveNewLotAndConfirm() {
    this.lotsService
      .saveNewLot(new NewLotInput(this.lotNameForm.value.name, this.lotNameForm.value.date, this.lotNameForm.value.type))
      .subscribe((resp: number) => {
        this.dialogRef.close(resp);
      });
  }

  keydown(event: KeyboardEvent) {
    if (event.key === ' ') {
      event.preventDefault();
    }
  }

  confirmSelectedLot() {
    this.dialogRef.close(this.selectedLotId);
  }

  changedRadio(create: boolean) {
    if (create) {
      this.createNewLot();
    } else {
      this.chooseExisting();
    }
  }
}
