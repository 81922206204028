import { Component, Input, OnInit, Output } from '@angular/core';
import { VendorsService } from '../../../container/configuration/vendors/vendors.service';
import { Vendor } from '../../../models/vendor-model';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { Warehouse } from '../../../models/warehouse-model';
import { WarehousesService } from '../../../container/configuration/warehouses/warehouses.service';
import { PosService } from '../../pos.service';
import { PoFilters } from '../../../models/filters/po-filters-model';
import { MatChipsData } from '../../../models/mat-chips-data-model';
import { Category } from '../../../models/category-model';
import { CategoriesService } from '../../../container/configuration/categories/categories.service';
import { Brand } from '../../../models/brand-model';
import { InventoryService } from '../../../container/inventory/inventory.service';
import { isNilty } from '../../../core/utils.service';
import { createSinglePoFiltersFormGroup, setBrandOnPoFilters, setCategoryOnPoFilters, updatePoFiltersMap } from '../../po-filters-handler';

@Component({
  selector: 'app-single-po-details-filters',
  templateUrl: './single-po-details-filters.component.html',
  styleUrls: ['./single-po-details-filters.component.css'],
})
export class SinglePoDetailsFiltersComponent implements OnInit {
  @Output() onPoItemFilter = new Subject<PoFilters>();

  warehouses: Warehouse[] = [];

  vendors: Vendor[] = [];
  parents: Vendor[] = [];

  categories: Category[] = [];

  brandList: Brand[] = [];
  brands: Subject<Brand[]> = new Subject();

  singlePoFilters: PoFilters = new PoFilters();

  openFilter = false;

  filterForm: FormGroup;

  filtersMap: MatChipsData[] = [];

  constructor(private categoriesService: CategoriesService, private inventoryService: InventoryService) {}

  ngOnInit() {
    this.filterForm = createSinglePoFiltersFormGroup();

    if (this.categoriesService.categories !== undefined) {
      this.categories = this.categoriesService.categories;
      this.filtersMap = setCategoryOnPoFilters(this.categories, this.singlePoFilters, this.filterForm);
    } else {
      this.categoriesService.getCategories('', 10000, 0).subscribe((c: Category[]) => {
        this.categories = c;
        this.filtersMap = setCategoryOnPoFilters(this.categories, this.singlePoFilters, this.filterForm);
      });
    }

    const brandString = this.singlePoFilters ? this.singlePoFilters.brand : undefined;

    this.inventoryService.getBrands(brandString).subscribe((brands: Brand[]) => {
      this.brands.next(brands);
      this.brandList = brands;
      this.filtersMap = setBrandOnPoFilters(this.brandList, this.singlePoFilters, this.filterForm);
    });
  }

  saveFilters() {
    this.singlePoFilters = new PoFilters();

    this.singlePoFilters.purchaseOrder = this.filterForm.value.purchaseOrder;

    this.singlePoFilters.eans = this.filterForm.value.eans;
    this.singlePoFilters.categoryId = !isNilty(this.filterForm.value.category) ? this.filterForm.value.category.id : null;
    this.singlePoFilters.brand = this.filterForm.value.brand ? this.filterForm.value.brand.name : null;
    this.singlePoFilters.acceptedDifferentFromPoa = this.filterForm.value.acceptedDifferentFromPoa;
    this.singlePoFilters.obsoleteWithAccepted = this.filterForm.value.obsoleteWithAccepted;
  }

  onSubmitFilter() {
    this.saveFilters();
    this.filtersMap = updatePoFiltersMap(this.singlePoFilters, this.filterForm);
    this.onPoItemFilter.next(this.singlePoFilters);
  }

  resetFilters() {
    this.filterForm.reset();
    this.saveFilters();
    this.filtersMap = updatePoFiltersMap(this.singlePoFilters, this.filterForm);
    this.onPoItemFilter.next(this.singlePoFilters);
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeEvent() {
    this.openFilter = false;
  }

  selectedParent(event) {
    const parent = event.value;
    if (parent != null) {
      this.filterForm.controls['vendor'].setValue(null);
    }
  }

  selectedChildren(event) {
    const children = event.value;
    if (children != null) {
      this.filterForm.controls['parent'].setValue(null);
    }
  }

  filterBrands(filterString: { filterText: string; filterKey: string }) {
    this.inventoryService.getBrands(filterString.filterText).subscribe((resp: Brand[]) => {
      this.brands.next(resp);
      this.brandList = resp;
    });
  }
}
