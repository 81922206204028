import { JsonObject, JsonProperty } from 'json2typescript';
import { KeepaProductDetails, Product } from './product-model';
import { Warehouse } from './warehouse-model';
import { Vendor } from './vendor-model';
import { CatalogItemWithStock } from './catalog-item-model';
import { SupplierDiscount } from './supplier-discount-model';

@JsonObject('SimplePoItem')
export class SimplePoItem {
  @JsonProperty('purchaseOrderItemId', Number, true) purchaseOrderItemId: number = undefined;
  @JsonProperty('purchaseOrderId', Number, true) purchaseOrderId: number = undefined;
  @JsonProperty('poCode', String, true) poCode: string = undefined;
  @JsonProperty('orderedOn', String, true) orderedOn: string = undefined;
  @JsonProperty('submittedQuantity', Number, true) submittedQuantity: number = undefined;
  @JsonProperty('acceptedQuantity', Number, true) acceptedQuantity: number = undefined;
  @JsonProperty('unitCost', Number, true) unitCost: number = undefined;
  @JsonProperty('totalCost', Number, true) totalCost: number = undefined;
  @JsonProperty('vendor', Vendor, true) vendor: Vendor = undefined;
  @JsonProperty('destination', Warehouse, true) destination: Warehouse = undefined;
}

@JsonObject('SimplePublication')
export class SimplePublication {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('amazonPrice', Number, true) amazonPrice: number = undefined;
  @JsonProperty('vendor', Vendor, true) vendor: Vendor = undefined;
  @JsonProperty('updatedAt', String, true) updatedAt: string = undefined;
}

@JsonObject('ProductQuantity')
export class ProductQuantity {
  @JsonProperty('submittedQuantity', Number, true) submittedQuantity: number = undefined;
  @JsonProperty('acceptedQuantity', Number, true) acceptedQuantity: number = undefined;
}

@JsonObject('ProductDetails')
export class ProductDetails {
  @JsonProperty('product', Product, true) product: Product = undefined;
  @JsonProperty('keepaProductDetail', KeepaProductDetails, true) keepaProductDetail: KeepaProductDetails = undefined;
  @JsonProperty('catalogItems', [CatalogItemWithStock], true) catalogItems: CatalogItemWithStock = undefined;
  @JsonProperty('lastPoItems', [SimplePoItem], true) lastPoItems: SimplePoItem = undefined;
  @JsonProperty('activePublications', [SimplePublication], true) activePublications: SimplePublication = undefined;
  @JsonProperty('supplierDiscount', [SupplierDiscount], true) supplierDiscount: SupplierDiscount = undefined;
  @JsonProperty('amazonQuantitySellable', Number, true) amazonQuantitySellable: number = undefined;
  @JsonProperty('lastThirtyDaysQuantity', ProductQuantity, true) lastThirtyDaysQuantity: ProductQuantity = undefined;
  @JsonProperty('lastNinetyDaysQuantity', ProductQuantity, true) lastNinetyDaysQuantity: ProductQuantity = undefined;
  @JsonProperty('yearQuantity', ProductQuantity, true) yearQuantity: ProductQuantity = undefined;
}
