<app-lots-list-filters (onLotFilter)="applyFilters($event)"></app-lots-list-filters>

<div>
  <div class="container table-outer">
    <div class="row">
      <div class="col-md-1"></div>

      <div class="col-md-10">
        <mat-paginator
          #paginator
          [length]="resultsNumber | async"
          [pageSize]="10"
          [pageSizeOptions]="[5, 10, 20, 50, 100]"
          (page)="loadPage()"
        >
        </mat-paginator>

        <div class="table-container margin-bottom-60">
          <mat-table [dataSource]="dataSource" class="font-Titillium" matSort (matSortChange)="onSort()">
            <ng-container matColumnDef="lotName">
              <mat-header-cell *matHeaderCellDef mat-sort-header="name" class="lot-name"> Lot name </mat-header-cell>
              <mat-cell *matCellDef="let lot" class="lot-name">
                <a *ngIf="lot.status != LOT_UPDATING_STATUS" [routerLink]="['/lot-edit', lot.id, 'po']">{{ lot.name }}</a>
                <p *ngIf="lot.status == LOT_UPDATING_STATUS">{{ lot.name }}</p>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="lotDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header="date" class="date-column"> Date </mat-header-cell>
              <mat-cell *matCellDef="let lot" class="date-column"> {{ lot.date }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="lotSubmittedQuantity">
              <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="submittedQuantity">
                Total quantity
              </mat-header-cell>
              <mat-cell class="quantity-column" *matCellDef="let lot">
                <p>{{ lot.submittedQuantity }}</p>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="lotSubmittedValue">
              <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="submittedValue"> Total Value </mat-header-cell>
              <mat-cell class="value-column" *matCellDef="let lot">
                <p>{{ lot.submittedValue | myCurrency }}</p>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="lotStatus">
              <mat-header-cell *matHeaderCellDef mat-sort-header="status" class="status-column"> Status </mat-header-cell>
              <mat-cell *matCellDef="let lot" class="status-column"> {{ lot.status }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="lotType">
              <mat-header-cell *matHeaderCellDef class="status-column" mat-sort-header="type"> Type </mat-header-cell>
              <mat-cell class="status-column" *matCellDef="let lot"> {{ lot.type }} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
        </div>
      </div>
    </div>
  </div>
</div>
