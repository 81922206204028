import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { MainDeductionsComponent } from './main-deductions.component';
import { MainPenaltiesRoutingModule } from './main-deductions-routing.module';
import { MainClaimHeadersComponent } from './main-claim-headers/main-claim-headers.component';
import { MainPenaltiesComponent } from './main-penalties/main-penalties.component';
import { ClaimService } from './main-claim-headers/claim.service';
import { PenaltiesService } from './main-penalties/penalties.service';
import { MainDeductionsMenuComponent } from './main-deductions-menu/main-deductions-menu.component';
import { ClaimsTableComponent } from './main-claim-headers/claims-table/claims-table.component';
import { EditClaimModalComponent } from './main-claim-headers/edit-claim-modal/edit-claim-modal.component';
import { ClaimDetailsListComponent } from './main-claim-headers/claim-details-list/claim-details-list.component';
import { ClaimsListComponent } from './main-claim-headers/claims-list/claims-list.component';
import { ClaimHeadersListComponent } from './main-claim-headers/claim-headers-list/claim-headers-list.component';
import { ChargebackListComponent } from './main-penalties/chargeback-list/chargebacks-list.component';
import { ChargebackListFiltersComponent } from './main-penalties/chargeback-list-filters/chargeback-list-filters.component';
// eslint-disable-next-line
import { ClaimHeadersListFiltersComponent } from './main-claim-headers/claim-headers-list/claim-headers-list-filters/claim-headers-list-filters.component';
import { ClaimsListFiltersComponent } from './main-claim-headers/claims-list/claims-list-filters/claims-list-filters.component';
import { MainDisputeBotLogsComponent } from './main-dispute-bot-logs/main-dispute-bot-logs.component';
// eslint-disable-next-line
import { DisputeBotLogsFiltersComponent } from './main-dispute-bot-logs/dispute-bot-logs-filters/dispute-bot-logs-filters.component';
import { DisputeBotLogsListComponent } from './main-dispute-bot-logs/dispute-bot-logs-list/dispute-bot-logs-list.component';

@NgModule({
  declarations: [
    MainDeductionsComponent,
    MainDeductionsMenuComponent,
    MainClaimHeadersComponent,
    ClaimHeadersListComponent,
    ClaimHeadersListFiltersComponent,
    ClaimsTableComponent,
    EditClaimModalComponent,
    ClaimDetailsListComponent,
    ClaimsListComponent,
    ClaimsListFiltersComponent,
    MainPenaltiesComponent,
    ChargebackListComponent,
    ChargebackListFiltersComponent,
    MainDisputeBotLogsComponent,
    DisputeBotLogsFiltersComponent,
    DisputeBotLogsListComponent,
  ],
  imports: [SharedModule, MainPenaltiesRoutingModule],
  providers: [PenaltiesService],
  entryComponents: [EditClaimModalComponent],
})
export class MainDeductionsModule {}
