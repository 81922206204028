import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';
import { CartonItem } from './carton-item-model';
import { Measure } from './measure-model';

@JsonObject('Carton')
export class Carton extends BaseObject {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('totalQuantity', Number, true) totalQuantity: number = undefined;
  @JsonProperty('totalValue', Number, true) totalValue: number = undefined;
  @JsonProperty('sscc', String, true) sscc: string = undefined;
  @JsonProperty('cartonItems', [CartonItem], true) cartonItems: CartonItem[] = undefined;
  @JsonProperty('length', Measure, true) length: Measure = undefined;
  @JsonProperty('height', Measure, true) height: Measure = undefined;
  @JsonProperty('width', Measure, true) width: Measure = undefined;
  @JsonProperty('grossWeight', Measure, true) grossWeight: Measure = undefined;
  @JsonProperty('ssccBarcodeLink', String, true) ssccBarcodeLink: string = undefined;
  @JsonProperty('trackingNumber', String, true) trackingNumber: string = undefined;
  @JsonProperty('labelUrl', String, true) labelUrl: string = undefined;
}
