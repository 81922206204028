import { Component, Input, ViewChild } from '@angular/core';
import { compareNumberOrString, isNilty } from '../../../../../core/utils.service';
import { SupplierDiscount } from '../../../../../models/supplier-discount-model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-supplier-discount-product',
  templateUrl: './supplier-discount-product.component.html',
  styleUrls: ['./supplier-discount-product.component.css'],
})
export class SupplierDiscountProductComponent {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() set supplierDiscount(values: SupplierDiscount[]) {
    if (!isNilty(values)) {
      this.dataSource = new MatTableDataSource(values);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  dataSource: MatTableDataSource<SupplierDiscount>;
  displayedColumns = ['supplierName', 'supplierCode', 'productEan', 'discountedPrice', 'validFrom', 'validTo', 'valid'];

  constructor() {}

  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'supplier':
          return compareNumberOrString(a.supplier.name, b.supplier.name, isAsc);
        case 'validTo':
          return compareNumberOrString(a.validTo, b.validTo, isAsc);
        case 'validFrom':
          return compareNumberOrString(a.validFrom, b.validFrom, isAsc);
        default:
          return compareNumberOrString(a[sort.active], b[sort.active], isAsc);
      }
    });
  }
}
