<app-po-filters (onPoFilter)="applyFilter($event)"></app-po-filters>

<app-po-kpi></app-po-kpi>

<app-po-bulk-actions
  [bulkActionsActive]="bulkActionsActive"
  (onMoveToLot)="onMoveToLot()"
  (onRemoveFromLot)="onRemoveFromLot()"
  (onPoExport)="exportPos($event)"
  (onChangePoStatus)="changePosStatus($event)"
></app-po-bulk-actions>

<div class="container table-outer">
  <mat-paginator #paginator [length]="resultsNumber | async" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()">
  </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="check" sticky>
        <mat-header-cell *matHeaderCellDef class="checker-col">
          <mat-checkbox (change)="toggleAll($event.checked)" [ngModel]="mainChecker"></mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let po; let i = index" class="checker-col">
          <mat-checkbox (change)="onSelected($event.checked, po)" [ngModel]="po.checked"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="poId" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="poId" class="po-code-column"> PO ID </mat-header-cell>
        <mat-cell class="po-code-column" *matCellDef="let po"
          ><a [routerLink]="[po.id]">{{ po.poId }}</a></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="vendor">
        <mat-header-cell class="vendor-column" *matHeaderCellDef mat-sort-header="vendor"> Vendor </mat-header-cell>
        <mat-cell class="vendor-column" *matCellDef="let po">{{ po.vendor ? po.vendor.code : '' }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="orderedOn">
        <mat-header-cell class="date-column" *matHeaderCellDef mat-sort-header="orderedOn"> Ordered on </mat-header-cell>
        <mat-cell class="date-column" *matCellDef="let po">{{ po.orderedOn }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="destination">
        <mat-header-cell class="destination-column" *matHeaderCellDef mat-sort-header="destination"> Destination </mat-header-cell>
        <mat-cell
          class="destination-column"
          *matCellDef="let po"
          [matTooltip]="po.destination ? po.destination.code + ' - ' + po.destination.name : ''"
          matTooltipPosition="below"
        >
          <p>{{ po.destination ? po.destination.code + ' - ' + po.destination.name : '' }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="earliestDate">
        <mat-header-cell class="date-column" *matHeaderCellDef mat-sort-header="earliestDate">
          Delivery <br />
          window start
        </mat-header-cell>
        <mat-cell class="date-column" *matCellDef="let po">{{ po.earliestDate }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="latestDate">
        <mat-header-cell class="date-column" *matHeaderCellDef mat-sort-header="latestDate">
          Delivery <br />
          window end
        </mat-header-cell>
        <mat-cell class="date-column" *matCellDef="let po">{{ po.latestDate }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="submittedQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="submittedQuantity">
          Total <br />
          Quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let po">
          <p>{{ po.submittedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="submittedValue">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="submittedValue">
          Total <br />
          Value
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let po">
          <p>{{ po.submittedValue | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="acceptedQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="acceptedQuantity">
          Accepted <br />
          Quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let po">
          <p>{{ po.acceptedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="acceptedValue">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="acceptedValue">
          Accepted <br />
          Value
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let po">
          <p>{{ po.acceptedValue | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastPoaQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="lastPoaQuantity">
          Last POA <br />
          Quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let po">
          <p>{{ po.lastPoaQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell class="shipment-status-column" *matHeaderCellDef mat-sort-header="status"> Status </mat-header-cell>
        <mat-cell class="shipment-status-column" *matCellDef="let po">{{ po.status }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
