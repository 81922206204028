import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Po } from '../../../../models/po-model';
import { PoaItemLog, PoaLog } from '../../../../models/poa-logs-model';
import { PosService } from '../../../pos.service';
import { MatTableDataSource } from '@angular/material/table';
import { PoItem } from '../../../../models/po-item-model';
import { MatPaginator } from '@angular/material/paginator';
import { PoFilters } from '../../../../models/filters/po-filters-model';
import { ConverterService } from '../../../../core/converter.service';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PoaItemHistoryModalComponent } from '../poa-item-history-modal/poa-item-history-modal.component';
import { isNilty } from '../../../../core/utils.service';

@Component({
  selector: 'app-poa-details',
  templateUrl: './poa-details.component.html',
  styleUrls: ['./poa-details.component.css'],
})
export class PoaDetailsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @Input() po: Po;
  @Input() selectedPoa: PoaLog;
  @Input() changedSelectedPoa: Subject<void>;

  filters: PoFilters;

  resultsNumber: Subject<number>;

  poaFileName: string;

  poaItemsDataSource: MatTableDataSource<PoItemPoaLog>;
  columns = ['showItemHistory', 'ean', 'acceptedQuantity', 'backOrderQuantity', 'softRejectedQuantity', 'hardRejectedQuantity'];

  constructor(private posService: PosService, private converterService: ConverterService, private dialog: MatDialog) {}

  ngOnInit() {
    this.resultsNumber = this.posService.resultsNumber;
    this.filters = new PoFilters();
    this.filters.purchaseOrder = this.po.poId;
    this.poaItemsDataSource = new MatTableDataSource<PoItemPoaLog>();
    this.getPoaFileName();
    this.loadPoItems();

    this.changedSelectedPoa.subscribe(() => {
      this.search();
    });
  }

  getPoaFileName() {
    if (isNilty(this.selectedPoa.fileUrl)) {
      this.poaFileName = undefined;
    } else {
      const path = this.selectedPoa.fileUrl.split('/');
      this.poaFileName = path[path.length - 1];
    }
  }

  loadPage() {
    this.filters.pageNumber = this.paginator.pageIndex;
    this.filters.pageSize = this.paginator.pageSize;
    this.loadPoItems();
  }

  search() {
    this.paginator.pageIndex = 0;
    this.filters.pageNumber = 0;
    this.loadPoItems();
  }
  reset() {
    this.filters.eans = undefined;
    this.search();
  }

  loadPoItems() {
    this.posService.getFilteredPoItems(this.filters).subscribe((pois) => {
      this.poaItemsDataSource.data = pois.map((it) => this.logFromPoItem(it));
      this.getPoaFileName();
    });
  }

  logFromPoItem(poi: PoItem): PoItemPoaLog {
    const array = JSON.parse(poi.poaLog) as string[];
    const convertedLogs = array.map((it) => this.converterService.fromJSONtoObj(it, PoaItemLog));

    return new PoItemPoaLog(
      poi,
      convertedLogs.find((it) => it.messageId === this.selectedPoa.messageId)
    );
  }

  showItemHistory(poItem: PoItem) {
    this.dialog.open(PoaItemHistoryModalComponent, {
      disableClose: true,
      data: { poi: poItem, po: this.po },
    });
  }

  downloadEdi() {
    window.open(this.selectedPoa.fileUrl, '_blank');
  }
}

class PoItemPoaLog {
  poItem: PoItem;
  log: PoaItemLog;
  constructor(poi: PoItem, log: PoaItemLog) {
    this.poItem = poi;
    this.log = log;
  }
}
