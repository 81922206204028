import { Component, OnInit } from '@angular/core';
import { LotsService } from '@shared/lots.service';
import { GenerateOrderedSummary } from '@models/generate-ordered-summary-model';

@Component({
  selector: 'app-generate-ordered-results',
  templateUrl: './generate-ordered-results.component.html',
  styleUrls: ['./generate-ordered-results.component.scss'],
})
export class GenerateOrderedResultsComponent implements OnInit {
  summaries: GenerateOrderedSummary[] = [];

  constructor(private lotsService: LotsService) {}

  ngOnInit() {
    this.lotsService.getGenerateOrderedResultSummaries(this.lotsService.currentLotId).subscribe((r) => {
      this.summaries = r;
      console.log(this.summaries);
    });
  }
}
