import { Component, OnInit } from '@angular/core';
import {
  GenerateOrderedFromSpoJobStatus,
  DestinationOptimizationStatus,
  OrderedFromSpoGenerationStep,
} from '../../../../models/job-status/generate-ordered-from-spo-status-model';
import { GenericProgressBarDirective } from '../generic-progress-bar';

@Component({
  selector: 'app-generate-ordered-from-spo-job-progress',
  templateUrl: './generate-ordered-from-spo-job-progress.component.html',
  styleUrls: ['./generate-ordered-from-spo-job-progress.component.scss'],
})
export class GenerateOrderedFromSpoJobProgressComponent extends GenericProgressBarDirective implements OnInit {
  jobStatus: GenerateOrderedFromSpoJobStatus;
  oldStatus: GenerateOrderedFromSpoJobStatus;

  TOTAL_STEPS = 4;
  GENERATION_STEP = OrderedFromSpoGenerationStep;
  DESTINATIONS = DestinationOptimizationStatus;

  constructor() {
    super();
  }

  ngOnInit() {
    this.initProgressBar();
  }

  getInternalJobStatus(job: GenerateOrderedFromSpoJobStatus): string {
    return job?.generationStatus;
  }
}
