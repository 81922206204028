import { Component, Input, OnInit } from '@angular/core';
import { MarkupConstraint } from '../../../../../models/publication-rule';

@Component({
  selector: 'app-markup-constraints',
  templateUrl: './markup-constraints.component.html',
  styleUrls: ['./markup-constraints.component.css'],
})
export class MarkupConstraintsComponent implements OnInit {
  @Input() markupConstraints: MarkupConstraint[];

  ngOnInit() {}
}
