<div class="lot-kpi">
  <div class="container">
    <div class="col-xs-6 background-blue text-white height-box font-row">
      <h4 class="h-padding-box">
        <b>Submitted</b>
      </h4>
      <p><b>Quantity: </b> {{ (kpiData | async)?.submittedQuantity }}</p>
      <p><b>Value: </b> {{ (kpiData | async)?.submittedValue | myCurrency }}</p>
    </div>

    <div class="col-xs-6 background-light-blue text-white height-box font-row">
      <h4 class="h-padding-box">
        <b>Fulfilled</b>
      </h4>
      <p><b>Quantity: </b> {{ (kpiData | async)?.acceptedQuantity }}</p>
      <p><b>Value: </b> {{ (kpiData | async)?.acceptedValue | myCurrency }}</p>
    </div>
  </div>
</div>
