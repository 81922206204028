import { Component, Input, OnInit } from '@angular/core';
import { DeliveryWindowConstraint } from '../../../../../models/delivery-window-constraint-model';

@Component({
  selector: 'app-delivery-window-constraints',
  templateUrl: './delivery-window-constraints.component.html',
  styleUrls: ['./delivery-window-constraints.component.css'],
})
export class DeliveryWindowConstraintsComponent implements OnInit {
  @Input() deliveryWindowConstraints: DeliveryWindowConstraint[];

  ngOnInit() {}
}
