import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';
import { DeliveryWindowConstraintDTO } from './delivery-window-constraint-dto-model';
import { Vendor } from './vendor-model';

@JsonObject('DeliveryWindowConstraint')
export class DeliveryWindowConstraint extends BaseObject {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('vendors', [Vendor], true) vendors: Vendor[] = undefined;
  @JsonProperty('daysToExtend', Number, true) daysToExtend: number = undefined;

  get description(): string {
    return `Days ${this.daysToExtend}: ${this.vendors.map((p) => p.code).join(', ')}`;
  }

  toDTO(): DeliveryWindowConstraintDTO {
    const dto = new DeliveryWindowConstraintDTO();
    dto.id = this.id;
    dto.vendors = this.vendors.map((vendor) => vendor.id);
    dto.daysToExtend = this.daysToExtend;
    return dto;
  }
}
