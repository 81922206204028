import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from 'json2typescript';
import { isNil } from '../core/utils.service';

@JsonObject('PaymentRequestStatus')
export class PaymentRequestStatus {
  @JsonProperty('code', String, false) code: string = undefined;
  @JsonProperty('description', String, false) description: string = undefined;
  @JsonProperty('editable', Boolean, false) editable: boolean = undefined;
  @JsonProperty('approvable', Boolean, false) approvable: boolean = undefined;
}

@JsonConverter
export class PaymentRequestStatusConverter implements JsonCustomConvert<PaymentRequestStatus> {
  jsonConvert: JsonConvert;

  constructor() {
    this.jsonConvert = new JsonConvert();
  }

  serialize(status: PaymentRequestStatus): string {
    return !isNil(status) ? status.code : undefined;
  }

  deserialize(value: string): PaymentRequestStatus {
    return this.jsonConvert.deserialize(value, PaymentRequestStatus) as PaymentRequestStatus;
  }
}
