import { JsonObject, JsonProperty } from 'json2typescript';
import { Product } from './product-model';
import { Po } from './po-model';
import { BaseObject } from './base-object-model';
import { PoaItemLog } from './poa-logs-model';

@JsonObject('PoItem')
export class PoItem extends BaseObject {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('product', Product, true) product: Product = undefined;
  @JsonProperty('expectedDate', String, true) expectedDate: string = undefined;
  @JsonProperty('backorder', Boolean, true) backorder: boolean = undefined;
  @JsonProperty('submittedQuantity', Number, false) submittedQuantity: number = undefined;
  @JsonProperty('acceptedQuantity', Number, false) acceptedQuantity: number = undefined;
  @JsonProperty('cancelledQuantity', Number, true) cancelledQuantity: number = undefined;
  @JsonProperty('receivedQuantity', Number, true) receivedQuantity: number = undefined;
  @JsonProperty('lastPoaQuantity', Number, true) lastPoaQuantity: number = undefined;
  @JsonProperty('unitCost', Number, true) unitCost: number = undefined;
  @JsonProperty('totalCost', Number, true) totalCost: number = undefined;
  @JsonProperty('purchaseOrder', Po, true) purchaseOrder: Po = undefined;
  @JsonProperty('obsoleteWithAcceptedGreaterThanZero', Boolean, true) obsoleteWithAcceptedGreaterThanZero: boolean = undefined;

  @JsonProperty('firstPoaDate', String, true) firstPoaDate: string = undefined;
  @JsonProperty('lastPoaDate', String, true) lastPoaDate: string = undefined;

  @JsonProperty('poaLog', String, true) poaLog: string = undefined;
  lotName: string;

  convertedPoaLogs: PoaItemLog[] = undefined;
}
