<div class="loader-overlay" *ngIf="isEditingShippedQuantities"></div>

<div class="row">
  <div class="col-md-12">
    <mat-paginator
      [length]="resultsNumber | async"
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20, 50, 100]"
      (page)="loadPage()"
    ></mat-paginator>
  </div>
</div>

<div
  class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60"
  [ngClass]="{ 'hidden-overflow-table': isEditingShippedQuantities }"
>
  <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
    <ng-container matColumnDef="check" sticky>
      <mat-header-cell *matHeaderCellDef class="checker-col">
        <mat-checkbox (change)="toggleAll($event.checked)" [ngModel]="mainChecker"></mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let shipmentItem; let i = index" class="checker-col">
        <mat-checkbox (change)="onSelected($event.checked, shipmentItem)" [ngModel]="shipmentItem.checked"></mat-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef mat-sort-header="title" class="title-column"> Title </mat-header-cell>
      <mat-cell *matCellDef="let shipmentItem" class="title-column">
        <p>{{ shipmentItem.purchaseOrderItem.product.title }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="ean" sticky>
      <mat-header-cell *matHeaderCellDef class="ean-column" mat-sort-header="ean"> EAN </mat-header-cell>
      <mat-cell *matCellDef="let shipmentItem" class="ean-column">
        <button
          (click)="showPoItemHistory(shipmentItem)"
          matTooltip="Show Po Item History"
          class="btn-custom smaller-icon-button margin-right-6"
        >
          <i class="fa fa-history" aria-hidden="true"></i>
        </button>
        <a [routerLink]="['/inventory/products', shipmentItem.purchaseOrderItem.product.id, 'details']">{{
          shipmentItem.purchaseOrderItem.product.ean
        }}</a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actualEan">
      <mat-header-cell *matHeaderCellDef class="ean-column" mat-sort-header="actualEan"> Actual EAN </mat-header-cell>
      <mat-cell *matCellDef="let shipmentItem" class="ean-column">
        {{ shipmentItem.actualEan }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="poId">
      <mat-header-cell *matHeaderCellDef mat-sort-header="po" class="po-code-column"> PO </mat-header-cell>
      <mat-cell *matCellDef="let shipmentItem" class="po-code-column"> {{ shipmentItem.purchaseOrderItem.purchaseOrder.poId }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="vendor">
      <mat-header-cell class="vendor-column" *matHeaderCellDef mat-sort-header="vendor"> Vendor </mat-header-cell>
      <mat-cell
        class="vendor-column"
        *matCellDef="let shipmentItem"
        [matTooltip]="shipmentItem.purchaseOrderItem.purchaseOrder.vendor.name"
        matTooltipPosition="below"
        >{{ shipmentItem.purchaseOrderItem.purchaseOrder.vendor.code }}</mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="brand">
      <mat-header-cell class="category-column" *matHeaderCellDef mat-sort-header="brand"> Brand </mat-header-cell>
      <mat-cell class="category-column" *matCellDef="let shipmentItem">
        <p>{{ shipmentItem.purchaseOrderItem.product.brand ? shipmentItem.purchaseOrderItem.product.brand.name : '' }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="submittedQuantity">
      <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="submittedQuantity">
        Submitted <br />
        quantity
      </mat-header-cell>
      <mat-cell class="quantity-column" *matCellDef="let shipmentItem">
        <p>{{ shipmentItem.purchaseOrderItem.submittedQuantity }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="submittedValue">
      <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="submittedValue">
        Submitted <br />
        value
      </mat-header-cell>
      <mat-cell class="value-column" *matCellDef="let shipmentItem">
        <p>{{ shipmentItem.purchaseOrderItem.totalCost | myCurrency }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="requestedQuantity">
      <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="requestedQuantity">
        Requested <br />
        quantity
      </mat-header-cell>
      <mat-cell class="quantity-column" *matCellDef="let shipmentItem">
        <p>{{ shipmentItem.requestedQuantity }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="requestedValue">
      <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="requestedValue">
        Requested <br />
        value
      </mat-header-cell>
      <mat-cell class="value-column" *matCellDef="let shipmentItem">
        <p>{{ shipmentItem.requestedValue | myCurrency }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="confirmedQuantity">
      <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="confirmedQuantity">
        Confirmed <br />
        quantity
      </mat-header-cell>
      <mat-cell class="quantity-column" *matCellDef="let shipmentItem">
        <p>{{ shipmentItem.confirmedQuantity }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="confirmedValue">
      <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="confirmedValue">
        Confirmed <br />
        value
      </mat-header-cell>
      <mat-cell class="value-column" *matCellDef="let shipmentItem">
        <p>{{ shipmentItem.confirmedValue | myCurrency }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="shippedQuantity">
      <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="shippedQuantity">
        Shipped <br />
        quantity
      </mat-header-cell>
      <mat-cell class="quantity-column" *matCellDef="let shipmentItem">
        <p *ngIf="!isEditingShippedQuantities || editingShippedId != shipmentItem.id">{{ shipmentItem.shippedQuantity }}</p>

        <mat-form-field class="edit-shipped-form-field" *ngIf="isEditingShippedQuantities && editingShippedId == shipmentItem.id">
          <input matInput [(ngModel)]="shipmentItem.shippedQuantity" (keydown)="keydownEvent($event, shipmentItem)" />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="shippedValue">
      <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="shippedValue">
        Shipped <br />
        value
      </mat-header-cell>
      <mat-cell class="value-column-title" *matCellDef="let shipmentItem">
        <p>{{ shipmentItem.shippedValue | myCurrency }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="purchasePriceUnit">
      <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="purchasePriceUnit">
        Purchase Price <br />
        Unit
      </mat-header-cell>
      <mat-cell class="value-column-title" *matCellDef="let shipmentItem">
        <p>{{ shipmentItem.purchasePriceUnit | myCurrency }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="confirmedPurchasePrice">
      <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="confirmedPurchasePrice">
        Confirmed <br />
        purchase price
      </mat-header-cell>
      <mat-cell class="value-column-title" *matCellDef="let shipmentItem">
        <p>{{ shipmentItem.confirmedPurchasePrice | myCurrency }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="virtualConfirmedPurchasePrice">
      <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="virtualConfirmedPurchasePrice">
        Virtual Confirmed <br />
        purchase price
      </mat-header-cell>
      <mat-cell class="value-column-title" *matCellDef="let shipmentItem">
        <p>{{ shipmentItem.virtualConfirmedPurchasePrice | myCurrency }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="lot">
      <mat-header-cell *matHeaderCellDef mat-sort-header="lot" class="lot-name-column"> Lot </mat-header-cell>
      <mat-cell class="lot-name-column" *matCellDef="let shipmentItem"
        ><a [routerLink]="['/lot-edit', shipmentItem.purchaseOrderItem.lot.id, 'po']">
          {{ shipmentItem.purchaseOrderItem.lot.name }}
        </a></mat-cell
      >
    </ng-container>

    <ng-container matColumnDef="edit" stickyEnd>
      <mat-header-cell *matHeaderCellDef class="single-line-edit-column">
        <p>Edit shipped</p>
      </mat-header-cell>
      <mat-cell class="single-line-edit-column" *matCellDef="let shipmentItem">
        <p>
          <button
            *ngIf="editingShippedId != shipmentItem.id"
            class="box-shadow-none actions-button btn-fixed"
            matTooltip="Edit shipped quantity"
            (click)="editShippedQuantities(shipmentItem)"
          >
            <i class="fa fa-edit" aria-hidden="true"></i>
          </button>
          <button
            *ngIf="editingShippedId == shipmentItem.id"
            class="box-shadow-none actions-button btn-fixed margin-right-3"
            matTooltip="Save"
            (click)="saveShippedQuantities(shipmentItem)"
          >
            <i class="fa fa-floppy-o" aria-hidden="true"></i>
          </button>
          <button
            *ngIf="editingShippedId == shipmentItem.id"
            class="box-shadow-none actions-button btn-fixed"
            matTooltip="Discard changes"
            (click)="discardChanges(shipmentItem)"
          >
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </p>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: !isEditingShippedQuantities"></mat-header-row>
    <mat-row
      *matRowDef="let shipmentItem; columns: displayedColumns"
      [ngClass]="{ 'foreground-row': isEditingShippedQuantities && editingShippedId == shipmentItem.id }"
    ></mat-row>
  </mat-table>
</div>
