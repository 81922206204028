<mat-toolbar class="bar-custom">
  <div class="container">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-8">
        <button class="btn-custom" [routerLink]="['new']">New Stock Location</button>
      </div>
      <div class="col-md-2 text-right">
        <button class="btn-custom" [matMenuTriggerFor]="importMenu">IMPORT</button>
      </div>
      <mat-menu #importMenu="matMenu">
        <button mat-menu-item (click)="importFullFeed()">Full Feed</button>
        <input #getFullFeedFile type="file" style="display: none" onclick="this.value = null" (change)="fileChanged($event, true)" />

        <button mat-menu-item (click)="importPartialFeed()">Partial Inbound</button>
        <input #getPartialFeedFile type="file" style="display: none" onclick="this.value = null" (change)="fileChanged($event, false)" />
      </mat-menu>
      <div class="col-md-1"></div>
    </div>
  </div>
</mat-toolbar>

<div class="container" id="title-stock-locations">
  <div class="row">
    <div class="col-md-10 col-md-offset-1">
      <hr class="divisor-title" style="width: 80% !important" />
      <div class="square" style="right: 20%"></div>
      <h2 class="po-element-custom title-custom">Stock Locations</h2>
    </div>
  </div>
</div>

<div class="container table-outer">
  <div class="col-md-10 col-md-offset-1">
    <div class="row">
      <div class="col-md-9">
        <mat-form-field style="min-width: 100%">
          <input matInput #filterInput placeholder="Search Name / Code / Supplier" [value]="filterText" />
        </mat-form-field>
      </div>

      <mat-paginator
        #paginator
        [length]="resultsNumber | async"
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 20, 50, 100]"
        (page)="loadPage()"
      >
      </mat-paginator>
    </div>

    <div class="row">
      <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
        <mat-table [dataSource]="dataSource" class="font-Titillium" matSort (matSortChange)="onSort()">
          <ng-container matColumnDef="name">
            <mat-header-cell class="stock-location-name-column" *matHeaderCellDef mat-sort-header="name"> Name </mat-header-cell>
            <mat-cell class="stock-location-name-column" *matCellDef="let stockLoc">
              <p>{{ stockLoc.name }}</p>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="code">
            <mat-header-cell class="code-column" *matHeaderCellDef mat-sort-header="code"> Code </mat-header-cell>
            <mat-cell class="code-column" *matCellDef="let stockLoc"> {{ stockLoc.code }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="supplier">
            <mat-header-cell class="origin-column" *matHeaderCellDef mat-sort-header="supplier"> Supplier </mat-header-cell>
            <mat-cell class="origin-column" *matCellDef="let stockLoc"> {{ stockLoc.supplier ? stockLoc.supplier.name : '' }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="address">
            <mat-header-cell class="stock-location-address-column" *matHeaderCellDef mat-sort-header="address"> Address </mat-header-cell>
            <mat-cell class="stock-location-address-column" *matCellDef="let stockLoc" matTooltip="{{ stockLoc.address }}">
              <p>{{ stockLoc.address }}</p>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="province">
            <mat-header-cell class="stock-location-province-column" *matHeaderCellDef mat-sort-header="province">
              Province
            </mat-header-cell>
            <mat-cell class="stock-location-province-column" *matCellDef="let stockLoc">
              <p>{{ stockLoc.province }}</p>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="city">
            <mat-header-cell class="stock-location-city-column" *matHeaderCellDef mat-sort-header="city"> City </mat-header-cell>
            <mat-cell class="stock-location-city-column" *matCellDef="let stockLoc">
              <p>{{ stockLoc.city }}</p>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="transitWarehouse">
            <mat-header-cell class="stock-location-transit-column" *matHeaderCellDef mat-sort-header="transitWarehouse">
              Transit Warehouse?
            </mat-header-cell>
            <mat-cell class="stock-location-transit-column" *matCellDef="let stockLoc">
              <i [ngClass]="stockLoc.transitWarehouse | booleanIconPipe"> </i>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="enableWarehouseFulfilment">
            <mat-header-cell class="stock-location-transit-column" *matHeaderCellDef mat-sort-header="enableWarehouseFulfilment">
              Enable fulfilment
            </mat-header-cell>
            <mat-cell class="stock-location-transit-column" *matCellDef="let stockLoc">
              <i [ngClass]="stockLoc.enableWarehouseFulfilment | booleanIconPipe"> </i>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="showProducts">
            <mat-header-cell class="actions-show-column" *matHeaderCellDef></mat-header-cell>
            <mat-cell class="actions-show-column" *matCellDef="let stockLoc">
              <button class="btn-product btn-fixed" (click)="getStockProducts(stockLoc.id)" style="width: 110px !important; float: right">
                Products
              </button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="edit">
            <mat-header-cell class="actions-edit-column" *matHeaderCellDef> </mat-header-cell>
            <mat-cell class="actions-edit-column" *matCellDef="let stockLoc">
              <button class="btn-product btn-fixed" [routerLink]="['edit', stockLoc.id]" style="margin-left: 30px">Edit</button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
    </div>
  </div>
</div>
