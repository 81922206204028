<h1 mat-dialog-title>Move POs to Lot</h1>

<div mat-dialog-content>
  <div class="row padding-top-10">
    <div class="col-md-12 text-center">
      <mat-radio-group aria-label="Select an option" [(ngModel)]="creatingNewLot" (ngModelChange)="changedRadio($event)">
        <mat-radio-button class="margin-right-38" [value]="true">New Lot</mat-radio-button>
        <mat-radio-button [value]="false">Existing Lot</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <hr />

  <div class="row" *ngIf="!creatingNewLot">
    <div class="col-md-12 text-center">
      <mat-form-field class="margin-right-15">
        <app-mat-select [(ngModel)]="selectedLotId" filterKey="name" placeholder="Choose a Lot">
          <mat-option [value]="null">None</mat-option>
          <mat-option *appMatSelectFor="let lot of lots; maxRows: 20" [value]="lot.id">
            {{ lot.name }}
          </mat-option>
        </app-mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row" *ngIf="creatingNewLot">
    <div class="col-md-12">
      <form [formGroup]="lotNameForm">
        <mat-form-field style="width: 80%;">
          <input
            matInput
            type="text"
            id="name"
            formControlName="name"
            placeholder="Insert the name of the new lot"
            maxlength="11"
            (keydown)="keydown($event)"
          />
          <mat-error *ngIf="lotNameForm.controls.name.hasError('required')">
            Required field
          </mat-error>
          <mat-error *ngIf="lotNameForm.controls.name.hasError('pattern')">
            Spaces are not allowed
          </mat-error>
        </mat-form-field>
        <mat-form-field style="width: 80%;">
          <input matInput formControlName="date" [matDatepicker]="date" placeholder="Date" />
          <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
          <mat-datepicker #date></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <mat-select type="text" id="type" formControlName="type" placeholder="Type">
            <mat-option *ngFor="let type of types" [value]="type">
              {{ type }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
  </div>
</div>

<hr />

<div class="row">
  <div class="col-md-2"></div>
  <div class="col-md-4 text-center">
    <button class="btn-custom" type="button" *ngIf="!creatingNewLot" [disabled]="!selectedLotId" (click)="confirmSelectedLot()">
      Add POs to Lot
    </button>

    <button class="btn-custom" type="submit" (click)="saveNewLotAndConfirm()" *ngIf="creatingNewLot">
      Save new Lot and confirm
    </button>
  </div>

  <div class="col-md-4 text-center">
    <button class="btn-product" type="button" cdkFocusInitial [mat-dialog-close]="null">Cancel</button>
  </div>
</div>
