import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Category } from '../../models/category-model';
import { ConverterService } from '../../core/converter.service';
import { isNilty } from '../../core/utils.service';
import { EnvironmentService } from '../../environment.service';
import { Brand } from '../../models/brand-model';
import { CatalogItem } from '../../models/catalog-item-model';
import { CatalogItemsFilters } from '../../models/filters/catalog-items-filters-model';
import { PaginationAndSorting } from '../../models/filters/pagination-and-sorting-model';
import { ProductsFilters } from '../../models/filters/products-filters-model';
import { StockLocationFilters } from '../../models/filters/stock-location-filters-model';
import { SupplierDiscountFilters } from '../../models/filters/supplier-discount-filters';
import { SupplierFilters } from '../../models/filters/supplier-filters';
import { ProductDetails } from '../../models/product-details-model';
import { Product } from '../../models/product-model';
import { ProductsCatalog } from '../../models/products-catalog-model';
import { Season } from '../../models/season-model';
import { StockItem } from '../../models/stock-item-model';
import { StockLocation } from '../../models/stock-location-model';
import { StockLocationWithoutSupplier } from '../../models/stock-location-without-supplier-model';
import { SupplierDiscount } from '../../models/supplier-discount-model';
import { Supplier } from '../../models/supplier-model';
import { GenericErrorModalComponent } from '../modal/generic-error-modal/generic-error-modal.component';
import { Status } from '../../models/status-model';
import { SupplierDTO } from '../../models/supplier-dto-model';
import { StockLocationDto } from '../../models/stock-location-dto-model';

@Injectable()
export class InventoryService {
  resultsNumber = new Subject<number>();

  products: Product[] = [];
  productsFilters: ProductsFilters = null;

  brands: Brand[] = [];

  seasons: Season[] = [];

  suppliers: Supplier[] = [];
  supplierFilters: SupplierFilters = null;
  supplierDiscountFilters: SupplierDiscountFilters = null;
  supplierDiscount: SupplierDiscount[] = [];

  stockLocations: StockLocation[] = [];
  stockLocationFilters: StockLocationFilters = null;

  paginationAndSorting: PaginationAndSorting = null;

  catalogItemsFilters: CatalogItemsFilters = null;

  mainChecker = false;
  selectedCatalogItems: number[] = [];

  constructor(
    private http: HttpClient,
    private converter: ConverterService,
    private dialog: MatDialog,
    private environmentService: EnvironmentService
  ) {}

  getBrands(filters?: string): Observable<Brand[]> {
    const endpoint = this.environmentService.getRestEndpoint('brands');
    const path = isNilty(filters) ? endpoint : this.environmentService.dev ? endpoint : endpoint + '?filter=' + filters;
    return this.http.get(path).pipe(
      map((respBrands: Brand[]) => {
        this.brands = respBrands.map((it) => this.converter.fromJSONtoObj(it, Brand));
        return this.brands;
      })
    );
  }

  getSupplierBrands(supplierId: number): Observable<Brand[]> {
    const path = this.environmentService.dev
      ? this.environmentService.getRestEndpoint('brands')
      : this.environmentService.getRestEndpoint('brands') + '/supplier/' + supplierId;

    return this.http.get(path).pipe(
      map((respBrands: Brand[]) => {
        this.brands = [];
        for (let i = 0; i < respBrands.length; i++) {
          this.brands[i] = this.converter.fromJSONtoObj(respBrands[i], Brand);
        }
        return this.brands;
      })
    );
  }

  getSeasons(): Observable<Season[]> {
    return this.http.get(this.environmentService.getRestEndpoint('seasons')).pipe(
      map((respSeasons: Season[]) => {
        this.seasons = [];
        for (let i = 0; i < respSeasons.length; i++) {
          this.seasons[i] = this.converter.fromJSONtoObj(respSeasons[i], Season);
        }
        return this.seasons;
      })
    );
  }

  getProductById(id: number): Observable<Product> {
    let path: string;
    if (this.environmentService.dev) {
      path = this.environmentService.getRestEndpoint('singleProduct');
    } else {
      path = this.environmentService.getRestEndpoint('products') + '/' + id;
    }

    return this.http.get(path).pipe(map((product: Product) => this.converter.fromJSONtoObj(product, Product)));
  }

  getProductDetails(id: number): Observable<ProductDetails> {
    let path: string;
    if (this.environmentService.dev) {
      path = this.environmentService.getRestEndpoint('productDetails');
    } else {
      path = this.environmentService.getRestEndpoint('products') + '/' + id + '/details';
    }

    return this.http.get(path).pipe(
      map((product: ProductDetails) => {
        if (!isNilty(product)) {
          return this.converter.fromJSONtoObj(product, ProductDetails);
        } else {
          return null;
        }
      })
    );
  }

  getFilteredProducts(filters: ProductsFilters): Observable<ProductsCatalog[]> {
    const body = this.converter.fromObjtoJSON(filters);

    return this.http.post(this.environmentService.getRestEndpoint('products'), body, { observe: 'response' }).pipe(
      map((resp) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((prodResponse: ProductsCatalog[]) => {
        const filteredProds: ProductsCatalog[] = [];
        for (let i = 0; i < prodResponse.length; i++) {
          filteredProds[i] = this.converter.fromJSONtoObj(prodResponse[i], ProductsCatalog);
        }
        return filteredProds;
      })
    );
  }

  getAllSuppliers(): Observable<Supplier[]> {
    const path = this.environmentService.getRestEndpoint('suppliers');

    return this.http.get(path).pipe(
      map((suppliers: Supplier[]) => {
        this.suppliers = [];
        for (let i = 0; i < suppliers.length; i++) {
          this.suppliers[i] = this.converter.fromJSONtoObj(suppliers[i], Supplier);
        }
        return this.suppliers;
      })
    );
  }

  getAllStockLocations(): Observable<StockLocation[]> {
    const path = this.environmentService.getRestEndpoint('stockLocations');

    return this.http.get(path).pipe(
      map((stockLoc: StockLocation[]) => {
        this.stockLocations = [];
        for (let i = 0; i < stockLoc.length; i++) {
          this.stockLocations[i] = this.converter.fromJSONtoObj(stockLoc[i], StockLocation);
        }
        return this.stockLocations;
      })
    );
  }

  getSuppliers(filters: SupplierFilters): Observable<Supplier[]> {
    const path = this.environmentService.getRestEndpoint('suppliers') + '/find';

    if (!this.environmentService.dev) {
      filters.sortBy = filters.sortBy ? filters.sortBy : 'name';
      filters.sortDirection = filters.sortDirection ? filters.sortDirection : 'asc';
    }

    const body = this.converter.fromObjtoJSON(filters);

    return this.http.post(path, body, { observe: 'response' }).pipe(
      map((resp) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((suppliers: Supplier[]) => {
        this.suppliers = [];
        for (let i = 0; i < suppliers.length; i++) {
          this.suppliers[i] = this.converter.fromJSONtoObj(suppliers[i], Supplier);
        }
        return this.suppliers;
      })
    );
  }

  getSupplierById(id: number): Observable<Supplier> {
    let path: string;
    if (this.environmentService.dev) {
      path = this.environmentService.getRestEndpoint('suppliers');
    } else {
      path = this.environmentService.getRestEndpoint('suppliers') + '/' + id;
    }
    return this.http.get(path).pipe(
      map((supplierResp: Supplier) => {
        const supplier: Supplier = this.converter.fromJSONtoObj(supplierResp, Supplier);
        if (this.environmentService.dev) {
          return this.suppliers[1];
        } else {
          return supplier;
        }
      })
    );
  }

  getFilteredStockLocations(filters: StockLocationFilters): Observable<StockLocation[]> {
    const path = this.environmentService.getRestEndpoint('stockLocations') + '/find';

    const body = this.converter.fromObjtoJSON(filters);

    return this.http.post(path, body, { observe: 'response' }).pipe(
      map((resp) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((sl: StockLocation[]) => {
        this.stockLocations = [];
        for (let i = 0; i < sl.length; i++) {
          this.stockLocations[i] = this.converter.fromJSONtoObj(sl[i], StockLocation);
        }
        return this.stockLocations;
      })
    );
  }

  getStockLocationById(id: number): Observable<StockLocation> {
    let path: string;
    if (this.environmentService.dev) {
      path = this.environmentService.getRestEndpoint('singleStockLocation');
    } else {
      path = this.environmentService.getRestEndpoint('stockLocations') + '/' + id;
    }
    return this.http.get(path).pipe(
      map((stockResp: StockLocation) => {
        const stockLoc: StockLocation = this.converter.fromJSONtoObj(stockResp, StockLocation);
        return stockLoc;
      })
    );
  }

  getTransitWarehouses(): Observable<StockLocation[]> {
    const path = this.environmentService.getRestEndpoint('transitWarehouses');

    return this.http.get(path).pipe(
      map((sl: StockLocation[]) => {
        this.stockLocations = sl.map((it) => this.converter.fromJSONtoObj(it, StockLocation));
        return this.stockLocations;
      })
    );
  }

  getBrandonTransitWarehouses(): Observable<StockLocationWithoutSupplier[]> {
    const path = this.environmentService.getRestEndpoint('brandonTransitWarehouses');

    return this.http.get(path).pipe(
      map((sl: StockLocation[]) => {
        this.stockLocations = sl.map((it) => this.converter.fromJSONtoObj(it, StockLocation));
        return this.stockLocations;
      })
    );
  }

  getSupplierProducts(id: number, filters: CatalogItemsFilters): Observable<CatalogItem[]> {
    const path = this.environmentService.getRestEndpoint('supplierProducts') + id;
    const body = this.converter.fromObjtoJSON(filters);

    return this.http.post(path, body, { observe: 'response' }).pipe(
      map((resp) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((suppProductsResp: CatalogItem[]) => {
        const supplierProducts: CatalogItem[] = [];
        for (let i = 0; i < suppProductsResp.length; i++) {
          supplierProducts[i] = this.converter.fromJSONtoObj(suppProductsResp[i], CatalogItem);
        }
        return supplierProducts;
      })
    );
  }

  getStockProducts(id: number, filters: PaginationAndSorting): Observable<StockItem[]> {
    let path = this.environmentService.getRestEndpoint('stockProducts');

    if (!this.environmentService.dev) {
      const pageNumber = filters.pageNumber ? filters.pageNumber : 0;
      const pageSize = filters.pageSize ? filters.pageSize : 20;
      const sortBy = filters.sortBy ? filters.sortBy : 'id';
      const sortDirection = filters.sortDirection ? filters.sortDirection : 'desc';

      path += id + '?page-number=' + pageNumber + '&page-size=' + pageSize + '&sort-by=' + sortBy + '&sort-direction=' + sortDirection;
    }

    return this.http.get(path, { observe: 'response' }).pipe(
      map((resp) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((stockItemResp: StockItem[]) => {
        const stockProducts: StockItem[] = [];
        for (let i = 0; i < stockItemResp.length; i++) {
          stockProducts[i] = this.converter.fromJSONtoObj(stockItemResp[i], StockItem);
        }
        return stockProducts;
      })
    );
  }

  postSupplier(supplierDTO: SupplierDTO): Observable<Supplier> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = this.converter.fromObjtoJSON(supplierDTO);

    return this.http
      .post(this.environmentService.getRestEndpoint('suppliers'), body, { headers })
      .pipe(map((resp: Supplier) => this.converter.fromJSONtoObj(resp, Supplier)));
  }

  deleteSupplier(id: number): Observable<boolean> {
    let path = this.environmentService.getRestEndpoint('suppliers');

    if (!this.environmentService.dev) {
      path += '/' + id;
    }

    return this.http.delete(path).pipe(map((exitStatus: boolean) => exitStatus));
  }

  postProduct(product: Product) {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = this.converter.fromObjtoJSON(product);
    const path = this.environmentService.dev
      ? this.environmentService.getRestEndpoint('products')
      : this.environmentService.getRestEndpoint('products') + '/save';
    return this.http.post(path, body, { headers }).pipe(map(() => true));
  }

  deleteProduct(id: number): Observable<boolean> {
    let path = this.environmentService.getRestEndpoint('products');

    if (!this.environmentService.dev) {
      path += '/' + id;
    }

    return this.http.delete(path).pipe(map(() => true));
  }

  postStockLocation(stockLoc: StockLocationDto): Observable<StockLocation> {
    let path = this.environmentService.getRestEndpoint('stockLocations');
    if (this.environmentService.dev) {
      path = this.environmentService.getRestEndpoint('singleStockLocation');
    }
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = this.converter.fromObjtoJSON(stockLoc);
    return this.http.post(path, body, { headers }).pipe(map((resp: StockLocation) => this.converter.fromJSONtoObj(resp, StockLocation)));
  }

  deleteStockLocation(id: number): Observable<string> {
    let path = this.environmentService.getRestEndpoint('stockLocations');

    if (!this.environmentService.dev) {
      path += '/' + id;
    }

    return this.http.delete(path).pipe(map((resp: string) => resp));
  }

  addIdToSelected(id: number) {
    this.selectedCatalogItems.push(id);
    this.selectedCatalogItems.filter((v, i, a) => a.indexOf(v) === i);
  }

  removeIdFromSelected(id: number) {
    const index = this.selectedCatalogItems.indexOf(id);
    this.selectedCatalogItems.splice(index, 1);
  }

  countCatalogItems(filters: CatalogItemsFilters): Observable<number> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = this.converter.fromObjtoJSON(filters);
    const path = this.environmentService.getRestEndpoint('countCatalogItems');
    return this.http.post(path, body, { headers }).pipe(map((resp: number) => resp));
  }

  deleteCatalogItems(filters: CatalogItemsFilters, mainChecker: boolean): Observable<boolean> {
    if (mainChecker) {
      return this.deleteFilteredCatalogItems(filters);
    } else {
      return this.deleteCatalogItemsByIds();
    }
  }

  deleteCatalogItemsByIds(): Observable<boolean> {
    let path = this.environmentService.getRestEndpoint('catalogItems');
    if (!this.environmentService.dev) {
      path += '/delete';
    }

    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(this.selectedCatalogItems);

    return this.http.post(path, body, { headers }).pipe(
      map((resp: string) => {
        if (resp === '0') {
          return true;
        } else {
          this.dialog.open(GenericErrorModalComponent, {
            width: '350px',
            data: resp,
          });
        }
      })
    );
  }

  deleteFilteredCatalogItems(filters: CatalogItemsFilters): Observable<boolean> {
    let path = this.environmentService.getRestEndpoint('catalogItems');

    if (!this.environmentService.dev) {
      path += '/delete-filtered';
      const headers = new HttpHeaders().append('Content-Type', 'application/json');
      const body = this.converter.fromObjtoJSON(filters);

      return this.http.post(path, body, { headers }).pipe(
        map((resp: string) => {
          if (resp === '0') {
            return true;
          } else {
            this.dialog.open(GenericErrorModalComponent, {
              width: '350px',
              data: resp,
            });
          }
        })
      );
    }
  }

  changeCatalogItemStatus(status: string, selectedCatalog: number[], supplierId: number, mainChecker: boolean): Observable<any> {
    if (mainChecker) {
      return this.changeCatalogItemStatusFromFilters(status, selectedCatalog, supplierId);
    } else {
      return this.changeCatalogItemStatusFromIds(status, selectedCatalog);
    }
  }

  private changeCatalogItemStatusFromFilters(status: string, selectedCatalog: number[], supplierId: number) {
    const filters = this.catalogItemsFilters;
    filters.supplierId = supplierId;
    filters.ids = selectedCatalog;
    const path = this.environmentService.dev
      ? this.environmentService.getRestEndpoint('changeCatalogItemStatusFromFilters')
      : this.environmentService.getRestEndpoint('changeCatalogItemStatusFromFilters') + status;
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = this.converter.fromObjtoJSON(filters);
    return this.http.post(path, body, { headers });
  }

  private changeCatalogItemStatusFromIds(status: string, selectedCatalog: number[]): Observable<any> {
    const path = this.environmentService.dev
      ? this.environmentService.getRestEndpoint('changeCatalogItemStatusFromIds')
      : this.environmentService.getRestEndpoint('changeCatalogItemStatusFromIds') + status;
    const headers = new HttpHeaders().append('Content-Type', 'application/json');

    const body = JSON.stringify(selectedCatalog);
    return this.http.post(path, body, { headers });
  }

  getJimboMessageTypes(): Observable<string[]> {
    return this.http.get(this.environmentService.getRestEndpoint('getJimboMessages')).pipe(map((resp: string[]) => resp));
  }

  setJimboMessageTypes(supplierId: number, messages: string[]): Observable<any> {
    return this.http.post(this.environmentService.getRestEndpoint('saveJimboMessages') + '/' + supplierId, messages);
  }

  getSupplierDiscountFromSupplier(supplierId: number, filters: SupplierDiscountFilters): Observable<SupplierDiscount[]> {
    const path = this.environmentService.getRestEndpoint('supplierDiscount') + '/' + supplierId;
    const body = this.converter.fromObjtoJSON(filters);

    return this.http.post(path, body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((supplierDiscount: SupplierDiscount[]) => {
        this.supplierDiscount = [];
        for (let i = 0; i < supplierDiscount.length; i++) {
          this.supplierDiscount[i] = this.converter.fromJSONtoObj(supplierDiscount[i], SupplierDiscount);
        }
        return this.supplierDiscount;
      })
    );
  }

  getStockLocationsBySupplierId(supplierId: number): Observable<StockLocation[]> {
    return this.http
      .get<StockLocation[]>(this.environmentService.getRestEndpoint('suppliers') + '/' + supplierId + '/stock-locations')
      .pipe(map((resp: StockLocation[]) => resp.map((it) => this.converter.fromJSONtoObj(it, StockLocation))));
  }

  getPackingListFields(): Observable<string[]> {
    return this.http.get<string[]>(this.environmentService.getRestEndpoint('suppliers') + '/packinglist-fields');
  }

  getPaymentTerms(): Observable<Status[]> {
    return this.http
      .get<Status[]>(this.environmentService.getRestEndpoint('suppliers') + '/payment-terms')
      .pipe(map((resp: Status[]) => resp.map((it) => this.converter.fromJSONtoObj(it, Status))));
  }

  getAllCategories(): Observable<Category[]> {
    const path = this.environmentService.getRestEndpoint('categories') + '/all';

    return this.http.get(path).pipe(map((resp: Category[]) => resp.map((it) => this.converter.fromJSONtoObj(it, Category))));
  }

  deleteUnNumber(catalogItemId: number): Observable<any> {
    return this.http.post(this.environmentService.getRestEndpoint('catalogItems') + '/delete-unnumber/' + catalogItemId, null);
  }

  getShipmentDocumentMethods(): Observable<Status[]> {
    return this.http
      .get<Status[]>(this.environmentService.getRestEndpoint('suppliers') + '/shipment-document-methods')
      .pipe(map((resp: Status[]) => resp.map((it) => this.converter.fromJSONtoObj(it, Status))));
  }
}
