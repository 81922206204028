import { Component, OnInit } from '@angular/core';
import { LotEditKpi } from '../../../../../models/lot-edit-kpi';
import { Observable } from 'rxjs';
import { PosService } from '../../../../../shared/pos.service';

@Component({
  selector: 'app-pos-list-details-kpi',
  templateUrl: './pos-list-details-kpi.component.html',
  styleUrls: ['./pos-list-details-kpi.component.css'],
})
export class PosListDetailsKpiComponent implements OnInit {
  constructor(private posService: PosService) {}

  kpiData: Observable<LotEditKpi>;

  ngOnInit() {
    this.kpiData = this.posService.kpiData;
  }
}
