export class LotEditKpi {
  submittedQuantity = 0;
  submittedValue = 0;

  acceptedQuantity = 0;
  acceptedValue = 0;

  cancelledQuantity = 0;
  cancelledValue = 0;

  receivedQuantity = 0;
  receivedValue = 0;
}
