import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { InventoryService } from '../inventory.service';
import { StockItem } from '../../../models/stock-item-model';
import { StockLocation } from '../../../models/stock-location-model';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-stock-items',
  templateUrl: './stock-items.component.html',
  styleUrls: ['./stock-items.component.css'],
})
export class StockItemsComponent implements OnInit {
  constructor(private route: ActivatedRoute, private inventoryService: InventoryService, private router: Router) {}

  stockLocation = new StockLocation();

  dataSource: MatTableDataSource<StockItem>;

  displayedColumns = ['ean', 'title', 'physicalQuantity', 'availableQuantity', 'updatedAt'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    this.resultsNumber = this.inventoryService.resultsNumber;
    this.inventoryService.paginationAndSorting = {
      pageNumber: null,
      pageSize: null,
      sortBy: null,
      sortDirection: null,
    };

    this.route.params.pipe(take(1)).subscribe((param: Params) => {
      const id = param['id'];

      this.inventoryService.getStockProducts(id, this.inventoryService.paginationAndSorting).subscribe((stockItems: StockItem[]) => {
        this.dataSource = new MatTableDataSource<StockItem>(stockItems);
      });

      this.inventoryService.getStockLocationById(id).subscribe((stockLoc: StockLocation) => {
        this.stockLocation = stockLoc;
      });
    });
  }

  loadPage() {
    this.inventoryService.paginationAndSorting.pageNumber = this.paginator.pageIndex;
    this.inventoryService.paginationAndSorting.pageSize = this.paginator.pageSize;

    this.inventoryService
      .getStockProducts(this.stockLocation.id, this.inventoryService.paginationAndSorting)
      .subscribe((stockItems: StockItem[]) => {
        this.dataSource = new MatTableDataSource<StockItem>(stockItems);
      });
  }

  onSort() {
    this.inventoryService.paginationAndSorting.sortBy = this.sort.active;
    this.inventoryService.paginationAndSorting.sortDirection = this.sort.direction;
    this.inventoryService
      .getStockProducts(this.stockLocation.id, this.inventoryService.paginationAndSorting)
      .subscribe((stockItems: StockItem[]) => {
        this.dataSource = new MatTableDataSource<StockItem>(stockItems);
      });
  }

  goBack() {
    this.router.navigate(['inventory/stock-locations']);
  }
}
