import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-shipments',
  templateUrl: './main-shipments.component.html',
  styleUrls: ['./main-shipments.component.css'],
})
export class MainShipmentsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
