import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('PoaLog')
export class PoaLog {
  @JsonProperty('source', String, true) source: string = undefined;
  @JsonProperty('messageId', String, true) messageId: string = undefined;
  @JsonProperty('totalAcceptedQuantity', Number, true) totalAcceptedQuantity: number = undefined;
  @JsonProperty('fileUrl', String, true) fileUrl: string = undefined;
  @JsonProperty('sentDate', String, true) sentDate: string = undefined;
  @JsonProperty('confirmedDate', String, true) confirmedDate: string = undefined;
}

@JsonObject('PoaItemLog')
export class PoaItemLog {
  @JsonProperty('messageId', String, true) messageId: string = undefined;
  @JsonProperty('acceptedQuantity', Number, true) acceptedQuantity: number = undefined;
  @JsonProperty('backOrderQuantity', Number, true) backOrderQuantity: number = undefined;
  @JsonProperty('softRejectedQuantity', Number, true) softRejectedQuantity: number = undefined;
  @JsonProperty('hardRejectedQuantity', Number, true) hardRejectedQuantity: number = undefined;
  @JsonProperty('sentDate', String, true) sentDate: string = undefined;
}
