/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { CategoriesComponent } from './categories/categories.component';
import { ConfigurationMenuComponent } from './configuration-menu/configuration-menu.component';
import { ConfigurationParamsComponent } from './configuration-params/configuration-params.component';
import { ConfigurationParamsService } from './configuration-params/configuration-params.service';
import { ConfigurationRoutingModule } from './configuration-routing.module';
import { ConfigurationComponent } from './configuration.component';
import { CountryHolidaysComponent } from './country-holidays/country-holidays.component';
import { CountryHolidaysService } from './country-holidays/country-holidays.service';
import { DeliveryWindowConstraintsEditComponent } from './lot-configurations/lot-configuration-edit/delivery-window-constraints/delivery-window-constraints-edit/delivery-window-constraints-edit.component';
import { DeliveryWindowConstraintsComponent } from './lot-configurations/lot-configuration-edit/delivery-window-constraints/delivery-window-constraints.component';
import { LotConfigurationEditComponent } from './lot-configurations/lot-configuration-edit/lot-configuration-edit.component';
import { LotConfigurationsListComponent } from './lot-configurations/lot-configuration-list/lot-configurations-list.component';
import { LotConfigurationsComponent } from './lot-configurations/lot-configurations.component';
import { LotConfigurationsService } from './lot-configurations/lot-configurations.service';
import { PublicationRulesModule } from './publication-rules/publication-rules.module';
import { StockLocationsTransitTimesComponent } from './transit-time/stock-locations-transit-times/stock-locations-transit-times.component';
import { TransitTimeComponent } from './transit-time/transit-time.component';
import { TransitTimeService } from './transit-time/transit-time.service';
import { VendorsEditComponent } from './vendors/vendors-edit/vendors-edit.component';
import { ChildVendorsDialog } from './vendors/vendors-list/child-vendors-dialog/child-vendors-dialog.component';
import { VendorsListComponent } from './vendors/vendors-list/vendors-list.component';
import { VendorsComponent } from './vendors/vendors.component';
import { WarehouseEditComponent } from './warehouses/warehouse-edit/warehouse-edit.component';
import { WarehouseListComponent } from './warehouses/warehouse-list/warehouse-list.component';
import { WarehousesListFiltersComponent } from './warehouses/warehouse-list/warehouse-list-filters/warehouse-list-filters.component';
import { WarehousesComponent } from './warehouses/warehouses.component';
import { VendorCentralAccountsComponent } from './vendor-central-accounts/vendor-central-accounts.component';
import { VendorCentralAccountsListComponent } from './vendor-central-accounts/vendor-central-accounts-list/vendor-central-accounts-list.component';
import { VendorCentralAccountsEditComponent } from './vendor-central-accounts/vendor-central-accounts-edit/vendor-central-accounts-edit.component';
import { VendorsDialogComponent } from './vendor-central-accounts/vendor-central-accounts-list/vendors-dialog/vendors-dialog.component';
import { GenerateOrderedConfigurationsComponent } from '@container/configuration/lot-configurations/lot-configuration-edit/generate-ordered-configurations/generate-ordered-configurations.component';

@NgModule({
  declarations: [
    ConfigurationComponent,
    WarehousesComponent,
    ConfigurationMenuComponent,
    VendorsComponent,
    LotConfigurationsComponent,
    WarehouseListComponent,
    WarehousesListFiltersComponent,
    VendorsListComponent,
    LotConfigurationsListComponent,
    VendorsEditComponent,
    LotConfigurationEditComponent,
    ChildVendorsDialog,
    WarehouseEditComponent,
    CategoriesComponent,
    ConfigurationParamsComponent,
    TransitTimeComponent,
    CountryHolidaysComponent,
    StockLocationsTransitTimesComponent,
    DeliveryWindowConstraintsComponent,
    DeliveryWindowConstraintsEditComponent,
    VendorCentralAccountsComponent,
    VendorCentralAccountsListComponent,
    VendorCentralAccountsEditComponent,
    VendorsDialogComponent,
    GenerateOrderedConfigurationsComponent,
  ],
  imports: [SharedModule, ConfigurationRoutingModule, PublicationRulesModule],
  providers: [ConfigurationParamsService, TransitTimeService, CountryHolidaysService],
  entryComponents: [ChildVendorsDialog],
})
export class ConfigurationModule {}
