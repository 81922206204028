<h2 mat-dialog-title>Generate manual invoice</h2>

<mat-dialog-content [formGroup]="manualInvoiceForm">
  <div>
    <mat-form-field>
      <input matInput maxlength="12" placeholder="Invoice Number" formControlName="invoiceNumber" />
    </mat-form-field>
  </div>

  <div>
    <mat-form-field>
      <input matInput [matDatepicker]="invoiceDate" placeholder="Invoice Date" formControlName="invoiceDate" />
      <mat-datepicker-toggle matSuffix [for]="invoiceDate"></mat-datepicker-toggle>
      <mat-datepicker #invoiceDate></mat-datepicker>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button class="btn-product margin-right-38" [disabled]="manualInvoiceForm.invalid" (click)="onSubmit()">Save</button>
  <button class="btn-product" (click)="onClose()">Close</button>
</mat-dialog-actions>
