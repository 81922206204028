import { JsonObject, JsonProperty } from 'json2typescript';
import { StockLocation } from './stock-location-model';
import { PoItem } from './po-item-model';
import { BaseObject } from './base-object-model';

@JsonObject('Fulfilment')
export class Fulfilment extends BaseObject {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('stockLocation', StockLocation, false) stockLocation: StockLocation = undefined;
  @JsonProperty('purchaseOrderItem', PoItem, false) purchaseOrderItem: PoItem = undefined;
  @JsonProperty('availableQuantity', Number, true) availableQuantity: number = undefined;
  @JsonProperty('availableValue', Number, true) availableValue: number = undefined;
  @JsonProperty('actualEan', String, true) actualEan: string = undefined;
  @JsonProperty('actualSupplierCode', String, true) actualSupplierCode: string = undefined;
  @JsonProperty('crossUkBorder', Boolean, true) crossUkBorder: boolean = undefined;
}
