import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LotsService } from '../../../../shared/lots.service';
import { Observable } from 'rxjs';
import { Lot } from '../../../../models/lot-model';
import { AvailabilityKpi } from '../../../../models/availability-kpi';
import { SupplierAvailabilityService } from '../supplier-availability.service';

@Component({
  selector: 'app-suppliers-availabilities-kpi',
  templateUrl: './supplier-availabilities-kpi.component.html',
  styleUrls: ['./supplier-availabilities-kpi.component.css'],
})
export class SupplierAvailabilitiesKpiComponent implements OnInit {
  constructor(private lotsService: LotsService, private router: Router, private availabilityService: SupplierAvailabilityService) {}

  currentLot: Observable<Lot>;

  kpiData: Observable<AvailabilityKpi>;

  ngOnInit() {
    this.kpiData = this.availabilityService.kpiData;
    this.currentLot = this.lotsService.lotSubject;
  }

  goBack() {
    this.router.navigate(['/lots']);
  }
}
