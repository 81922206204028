import { Warehouse } from '../warehouse-model';
import { Vendor } from '../vendor-model';
import { MatChipsData } from '../mat-chips-data-model';
import { Supplier } from '../supplier-model';
import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter, fromDateToFiltersString } from '../date-converter';
import { isNilty } from '../../core/utils.service';
import { FormGroup } from '@angular/forms';

@JsonObject('SpoFilters')
export class SpoFilters {
  @JsonProperty('listOfIds', [Number], true) listOfIds: number[] = [];
  @JsonProperty('listOfGroupIds', [Number], true) listOfGroupIds: number[] = [];
  @JsonProperty('lotId', Number, true) lotId: number = undefined;
  @JsonProperty('ean', String, true) ean: string = undefined;
  @JsonProperty('supplierId', Number, true) supplier: number = undefined;
  @JsonProperty('po', String, true) po: string = undefined;
  @JsonProperty('parentVendorId', Number, true) parent: number = undefined;
  @JsonProperty('destinationId', Number, true) destination: number = undefined;
  @JsonProperty('lotName', String, true) lotName: string = undefined;
  @JsonProperty('spoId', Number, true) spoId: number = undefined;
  @JsonProperty('unitCostFrom', Number, true) unitCostFrom: number = undefined;
  @JsonProperty('unitCostTo', Number, true) unitCostTo: number = undefined;
  @JsonProperty('status', Number, true) status: number = undefined;
  @JsonProperty('acceptBindingOrders', Boolean, true) acceptBindingOrders: boolean = undefined;
  @JsonProperty('hasOrderedQuantity', Boolean, true) hasOrderedQuantity: boolean = undefined;
  @JsonProperty('orderId', String, true) orderId: string = undefined;
  @JsonProperty('obsoleteWithAccepted', Boolean, true) obsoleteWithAccepted: boolean = undefined;
  @JsonProperty('crossUkBorder', Boolean, true) crossUkBorder: boolean = undefined;
  @JsonProperty('isExtra', Boolean, true) isExtra: boolean = undefined;
  @JsonProperty('isAdr', Boolean, true) isAdr: boolean = undefined;
  @JsonProperty('poOrderedOnFrom', DateConverter, true) poOrderedOnFrom: Date = undefined;
  @JsonProperty('poOrderedOnTo', DateConverter, true) poOrderedOnTo: Date = undefined;

  @JsonProperty('latestDateFrom', DateConverter, true) latestDateFrom: Date = undefined;
  @JsonProperty('latestDateTo', DateConverter, true) latestDateTo: Date = undefined;

  @JsonProperty('lotDateFrom', DateConverter, true) lotDateFrom: Date = undefined;
  @JsonProperty('lotDateTo', DateConverter, true) lotDateTo: Date = undefined;

  @JsonProperty('pageNumber', Number, true) pageNumber = 0;
  @JsonProperty('pageSize', Number, true) pageSize = 10;
  @JsonProperty('sortBy', String, true) sortBy: string = undefined;
  @JsonProperty('sortDirection', String, true) sortDirection: string = undefined;
}

export const spoFiltersMap = (
  parent?: Vendor,
  ean?: string,
  po?: string,
  destination?: Warehouse,
  supplier?: Supplier,
  lotName?: string,
  unitCostFrom?: number,
  unitCostTo?: number,
  orderId?: string,
  acceptBindingOrders?: boolean,
  hasOrderedQuantity?: boolean,
  obsoleteWithAccepted?: boolean,
  crossUkBorder?: boolean,
  isExtra?: boolean,
  isAdr?: boolean,
  status?: string,
  poOrderedOnFrom?: Date,
  poOrderedOnTo?: Date,
  dateFrom?: Date,
  dateTo?: Date,
  lotDateFrom?: Date,
  lotDateTo?: Date
): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (!isNilty(parent)) {
    map.push(new MatChipsData('Parent', parent.code));
  }
  if (!isNilty(ean)) {
    map.push(new MatChipsData('EAN', ean));
  }
  if (!isNilty(po)) {
    map.push(new MatChipsData('PO', po));
  }
  if (!isNilty(destination)) {
    map.push(new MatChipsData('Destination', destination.code));
  }
  if (!isNilty(supplier)) {
    map.push(new MatChipsData('Supplier', supplier.name));
  }
  if (!isNilty(lotName)) {
    map.push(new MatChipsData('Lot Name', lotName));
  }
  if (!isNilty(unitCostFrom)) {
    map.push(new MatChipsData('Cost From', unitCostFrom));
  }
  if (!isNilty(unitCostTo)) {
    map.push(new MatChipsData('Cost To', unitCostTo));
  }
  if (!isNilty(status)) {
    map.push(new MatChipsData('Status', status));
  }
  if (dateFrom !== null && dateFrom !== undefined) {
    map.push(new MatChipsData('From', fromDateToFiltersString(dateFrom)));
  }
  if (dateTo !== null && dateTo !== undefined) {
    map.push(new MatChipsData('To', fromDateToFiltersString(dateTo)));
  }
  if (!isNilty(orderId)) {
    map.push(new MatChipsData('Order ID', orderId));
  }
  if (!isNilty(acceptBindingOrders)) {
    map.push(new MatChipsData(acceptBindingOrders ? 'Only binding' : 'Only non-binding', ''));
  }
  if (obsoleteWithAccepted) {
    map.push(new MatChipsData('Obsolete with accepted', 'yes'));
  }
  if (crossUkBorder) {
    map.push(new MatChipsData('Cross UK border', 'yes'));
  }
  if (isExtra) {
    map.push(new MatChipsData('Is Extra', 'yes'));
  }
  if (isAdr) {
    map.push(new MatChipsData('Is ADR', 'yes'));
  }
  if (!isNilty(hasOrderedQuantity) && hasOrderedQuantity) {
    map.push(new MatChipsData('Ordered > 0', ''));
  }
  if (poOrderedOnFrom !== null && poOrderedOnTo !== undefined) {
    map.push(new MatChipsData('Po Ordered On From', fromDateToFiltersString(poOrderedOnFrom)));
  }
  if (poOrderedOnTo !== null && poOrderedOnFrom !== undefined) {
    map.push(new MatChipsData('Po Ordered On To', fromDateToFiltersString(dateTo)));
  }
  if (lotDateFrom !== null && lotDateTo !== undefined) {
    map.push(new MatChipsData('Lot Date From', fromDateToFiltersString(lotDateFrom)));
  }
  if (lotDateTo !== null && lotDateFrom !== undefined) {
    map.push(new MatChipsData('Lot Date To', fromDateToFiltersString(lotDateTo)));
  }
  return map;
};

export const setFiltersFromForm = (filterForm: FormGroup, spoFilters: SpoFilters) => {
  spoFilters.parent = filterForm.value.parent ? filterForm.value.parent.id : undefined;
  spoFilters.ean = filterForm.value.ean === '' ? undefined : filterForm.value.ean;
  spoFilters.po = filterForm.value.po === '' ? undefined : filterForm.value.po;
  spoFilters.destination = filterForm.value.destination ? filterForm.value.destination.id : undefined;
  spoFilters.supplier = filterForm.value.supplier ? filterForm.value.supplier.id : undefined;
  spoFilters.lotName = filterForm.value.lotName;
  spoFilters.unitCostFrom = filterForm.value.unitCostFrom;
  spoFilters.unitCostFrom = filterForm.value.unitCostFrom;
  spoFilters.unitCostTo = filterForm.value.unitCostTo;
  spoFilters.spoId = undefined;
  spoFilters.orderId = filterForm.value.orderId === '' ? undefined : filterForm.value.orderId;
  spoFilters.acceptBindingOrders = filterForm.value.acceptBindingOrders;
  spoFilters.hasOrderedQuantity = filterForm.value.hasOrderedQuantity;
  spoFilters.obsoleteWithAccepted = filterForm.value.obsoleteWithAccepted ? true : undefined;
  spoFilters.crossUkBorder = filterForm.value.crossUkBorder ? true : undefined;
  spoFilters.isExtra = filterForm.value.isExtra ? true : undefined;
  spoFilters.isAdr = filterForm.value.isAdr ? true : undefined;
  spoFilters.status = filterForm.value.status;
  spoFilters.poOrderedOnFrom = filterForm.value.poOrderedOnFrom;
  spoFilters.poOrderedOnTo = filterForm.value.poOrderedOnTo;
  spoFilters.lotDateFrom = filterForm.value.lotDateFrom;
  spoFilters.lotDateTo = filterForm.value.lotDateTo;
  spoFilters.pageNumber = undefined;
  spoFilters.pageSize = undefined;
  spoFilters.sortDirection = undefined;
  spoFilters.sortBy = undefined;
};
