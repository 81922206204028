import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-packaging-modal',
  templateUrl: './edit-packaging-modal.component.html',
  styleUrls: ['./edit-packaging-modal.component.css'],
})
export class EditPackagingModalComponent implements OnInit {
  shipmentForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<EditPackagingModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      pallets: number;
      cartons: number;
      weight: number;
      ddt: string;
    }
  ) {}

  ngOnInit() {
    this.shipmentForm = new FormGroup({
      pallets: new FormControl(this.data.pallets),
      cartons: new FormControl(this.data.cartons),
      weight: new FormControl(this.data.weight),
      ddt: new FormControl(this.data.ddt),
    });
  }

  onClose() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close(this.shipmentForm);
  }
}
