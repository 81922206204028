<app-dispute-bot-logs-filters (onFilter)="applyFilter($event)"></app-dispute-bot-logs-filters>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <hr class="divisor-title" style="width: calc(100% - 27rem)" />
      <div class="square" style="right: 27rem"></div>
      <h2 class="po-element-custom title-custom">Dispute Bot Logs</h2>
    </div>
  </div>
</div>

<div class="container table-outer">
  <mat-paginator
    #paginator
    [length]="resultsNumber | async"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 20, 50, 100]"
    (page)="loadPage()"
  ></mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="requestId">
        <mat-header-cell class="request-column" *matHeaderCellDef mat-sort-header="requestId"> Request ID </mat-header-cell>
        <mat-cell class="request-column" *matCellDef="let bot">
          <p>{{ bot?.requestId }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="invoiceNumber">
        <mat-header-cell class="invoice-column" *matHeaderCellDef mat-sort-header="invoiceNumber"> Invoice Number </mat-header-cell>
        <mat-cell class="invoice-column" *matCellDef="let bot">
          <p>{{ bot?.invoiceNumber }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="accountName" sticky>
        <mat-header-cell class="vendor-central-account-name-column" *matHeaderCellDef mat-sort-header="accountName">
          Vendor Central Account Name
        </mat-header-cell>
        <mat-cell class="vendor-central-account-name-column" *matCellDef="let bot">
          <p>{{ bot?.accountName }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="date" sticky>
        <mat-header-cell class="date-column" *matHeaderCellDef mat-sort-header="date"> Date </mat-header-cell>
        <mat-cell *matCellDef="let bot" class="date-column">
          {{ bot?.date }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="action" sticky>
        <mat-header-cell class="actions-show-column" *matHeaderCellDef mat-sort-header="action"> Action </mat-header-cell>
        <mat-cell class="actions-show-column" *matCellDef="let bot">
          {{ bot?.action?.description }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status" sticky>
        <mat-header-cell class="actions-show-column" *matHeaderCellDef mat-sort-header="status"> Status </mat-header-cell>
        <mat-cell class="actions-show-column" *matCellDef="let bot">
          {{ bot?.status?.description }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="caseId" sticky>
        <mat-header-cell class="date-column" *matHeaderCellDef mat-sort-header="caseId"> Case ID </mat-header-cell>
        <mat-cell class="date-column" *matCellDef="let bot">
          {{ bot?.caseId }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="caseDate" sticky>
        <mat-header-cell class="date-column" *matHeaderCellDef mat-sort-header="caseDate"> Case Date </mat-header-cell>
        <mat-cell class="date-column" *matCellDef="let bot">
          {{ bot?.caseDate }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="disputeDate" sticky>
        <mat-header-cell class="date-column" *matHeaderCellDef mat-sort-header="disputeDate"> Dispute Date </mat-header-cell>
        <mat-cell class="date-column" *matCellDef="let bot">
          {{ bot?.disputeDate }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="disputeId" sticky>
        <mat-header-cell class="invoice-number-column" *matHeaderCellDef mat-sort-header="disputeId"> Dispute ID </mat-header-cell>
        <mat-cell class="invoice-number-column" *matCellDef="let bot">
          {{ bot?.disputeId }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="message" sticky>
        <mat-header-cell class="message-column" *matHeaderCellDef> Message </mat-header-cell>
        <mat-cell class="message-column" *matCellDef="let bot" [matTooltip]="bot.message">
          {{ bot?.message }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngStyle]="row.status.code === 'ERROR' && { backgroundColor: '#e47a7a' }"
      ></mat-row>
    </mat-table>
  </div>
</div>
