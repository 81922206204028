<app-payment-document-list-filters (onFilter)="onFilter($event)"></app-payment-document-list-filters>

<div *ngIf="hasPaymentManagement | async">
  <mat-toolbar class="bar-custom">
    <div class="container">
      <div class="row">
        <div class="col-md-2">
          <button class="box-shadow-none btn-custom" (click)="newPaymentDocument()">New Payment Document</button>
        </div>
        <div class="col-md-2">
          <button [disabled]="!bulkActionsActive" class="box-shadow-none btn-custom" (click)="createPaymentRequest()">
            Create Payment Request
          </button>
        </div>
      </div>
    </div>
  </mat-toolbar>
</div>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <hr class="divisor-title" />
      <div class="square"></div>
      <h2 class="po-element-custom title-custom">Payment Documents</h2>
    </div>
  </div>
</div>

<div class="container table-outer">
  <mat-paginator [length]="resultsNumber | async" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()"> </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table multiTemplateDataRows [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="check" sticky>
        <mat-header-cell *matHeaderCellDef class="checker-col">
          <mat-checkbox (change)="toggleAll($event.checked)" [checked]="mainChecker"></mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let pd" class="checker-col">
          <mat-checkbox (change)="onSelected($event.checked, pd)" [checked]="pd.checked"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="documentNumber" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="documentNumber" class="code-column"> Document Number </mat-header-cell>
        <mat-cell *matCellDef="let pd" class="code-column">
          {{ pd.documentNumber }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="supplier" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="supplier" class="supplier-column"> Supplier </mat-header-cell>
        <mat-cell *matCellDef="let pd" class="supplier-column" [matTooltip]="pd.supplier.name">
          {{ pd.supplier.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="documentType" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="invoiceDate" class="code-column"> Document Type </mat-header-cell>
        <mat-cell *matCellDef="let pd" class="code-column">
          <p>{{ pd.documentType.description }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="documentDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="documentDate" class="date-column"> Document Date </mat-header-cell>
        <mat-cell *matCellDef="let pd" class="date-column">
          <p>{{ pd.documentDate | myDate }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header="status" class="status-column"> Status </mat-header-cell>
        <mat-cell *matCellDef="let pd" class="status-column">
          <p>{{ pd.status.description }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="value">
        <mat-header-cell *matHeaderCellDef mat-sort-header="value" class="value-column-title"> Value </mat-header-cell>
        <mat-cell *matCellDef="let pd" class="value-column">
          <p>{{ pd.value + pd.vat | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header="quantity" class="quantity-column-title"> Quantity </mat-header-cell>
        <mat-cell *matCellDef="let pd" class="quantity-column">
          <p>{{ pd.quantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="shipments">
        <mat-header-cell *matHeaderCellDef> Shipments </mat-header-cell>
        <mat-cell *matCellDef="let pd" [matTooltip]="joinShipmentsOrderId(pd)">
          <p>{{ joinShipmentsOrderId(pd) }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="shipmentsValue">
        <mat-header-cell *matHeaderCellDef class="value-column-title"> Shipments Total Value </mat-header-cell>
        <mat-cell *matCellDef="let pd" class="value-column">
          <p>{{ computeShipmentsValue(pd) | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="shipmentsQuantity">
        <mat-header-cell *matHeaderCellDef class="quantity-column-title"> Shipments Total Quantity </mat-header-cell>
        <mat-cell *matCellDef="let pd" class="quantity-column">
          <p>{{ computeShipmentsQuantity(pd) }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="downloadDocument" stickyEnd>
        <mat-header-cell class="icon-column center" *matHeaderCellDef>Download Document </mat-header-cell>
        <mat-cell class="icon-column" *matCellDef="let pd">
          <button
            class="box-shadow-none actions-button btn-fixed"
            matTooltip="Download document"
            (click)="downloadDocument(pd)"
            [disabled]="!pd.documentUrl"
          >
            <i class="fa fa-download" aria-hidden="true"></i>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions" stickyEnd>
        <mat-header-cell class="icon-column center" *matHeaderCellDef>Actions</mat-header-cell>
        <mat-cell class="icon-column" *matCellDef="let pd">
          <button
            class="box-shadow-none actions-button btn-fixed"
            matTooltip="Delete payment document"
            (click)="delete(pd)"
            [disabled]="pd.paymentRequest"
          >
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
    </mat-table>
  </div>
</div>
