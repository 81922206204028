import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject } from 'rxjs';
import { SpoFilters } from '../../../models/filters/spo-filters-model';
import { GroupedSpoItem, SpoItem } from '../../../models/spo-item-model';
import { addCheckers } from '../../checkers.service';
import { LotsService } from '../../lots.service';
import { SposService } from '../../spos.service';

@Component({
  selector: 'app-single-spo-item-table',
  templateUrl: './single-spo-item-table.component.html',
  styleUrls: ['./single-spo-item-table.component.css'],
})
export class SingleSpoItemTableComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Input() spoId: number;

  @Input() set loadSpoItems(it: Subject<SpoFilters>) {
    it.subscribe((filters: SpoFilters) => {
      this.applyFilters(filters);
    });
  }

  @Input() set deleteItems(it: Subject<void>) {
    it.subscribe(() => {
      this.filters.spoId = this.spoId;
      this.sposService
        .deleteSpoItems(this.selectedSpoItems, this.filters, this.mainChecker, this.lotsService.currentLotId)
        .subscribe(() => {
          this.matSnackBar.open('Selected SPO Items will be deleted.')._dismissAfter(3000);
        });
    });
  }

  @Output() toggleView = new EventEmitter<void>();
  @Output() bulkActionsEmitter = new EventEmitter<boolean>();

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  filters = new SpoFilters();
  dataSource: MatTableDataSource<GroupedSpoItem>;
  dataSpoItem: any[];

  selectedSpoItems: number[] = [];
  mainChecker = false;

  bulkActionsActive = false;

  displayedColumns = [
    'check',
    'ean',
    'poCode',
    'latestDate',
    'actualEan',
    'title',
    'purchasePrice',
    'requestedQuantity',
    'confirmedQuantity',
    'orderedQuantity',
    'margin',
    'percentMargin',
    'virtualMargin',
    'virtualPercentMargin',
    'requestedValue',
    'confirmedValue',
    'confirmedPurchasePrice',
    'virtualConfirmedPurchasePrice',
    'orderedValue',
    'orderedPurchasePrice',
    'virtualOrderedPurchasePrice',
    'obsoleteWithAccepted',
  ];

  constructor(private sposService: SposService, private lotsService: LotsService, private matSnackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.resultsNumber = this.sposService.spoItemResultsNumber;
    this.dataSource = new MatTableDataSource<GroupedSpoItem>([]);
  }

  loadPage() {
    this.filters.pageNumber = this.paginator.pageIndex;
    this.filters.pageSize = this.paginator.pageSize;
    this.getSpoItems();
  }

  onSort() {
    this.filters.sortBy = this.sort.active;
    this.filters.sortDirection = this.sort.direction;
    this.getSpoItems();
  }

  getSpoItems() {
    this.filters.spoId = this.spoId;
    this.sposService.getFilteredSpoItems(this.lotsService.currentLotId, this.filters).subscribe((resp: SpoItem[]) => {
      this.dataSpoItem = addCheckers(resp, this.selectedSpoItems, this.mainChecker);
      this.dataSource.data = this.dataSpoItem;
    });
  }

  applyFilters(filters: SpoFilters) {
    this.filters = filters;
    this.paginator.pageIndex = 0;
    this.getSpoItems();
  }

  onSelected(value: boolean, selectedSpo: any) {
    const selectedIndex = this.dataSpoItem.indexOf(selectedSpo);
    this.dataSpoItem[selectedIndex].checked = value;
    if (value !== this.mainChecker) {
      this.selectedSpoItems.push(this.dataSpoItem[selectedIndex].id);
      this.selectedSpoItems.filter((v, i, a) => a.indexOf(v) === i);
    } else {
      const index = this.selectedSpoItems.indexOf(selectedSpo.id);
      this.selectedSpoItems.splice(index, 1);
    }
    this.bulkActionsActive = value || this.mainChecker;
    this.emitBulkActions();
  }

  toggleAll(value: boolean) {
    this.mainChecker = value;
    for (const i of this.dataSpoItem) {
      i.checked = value;
    }
    this.selectedSpoItems = [];
    this.bulkActionsActive = value;
    this.emitBulkActions();
  }

  toggleDetailsView() {
    this.resetCheckers();
    this.toggleView.emit();
  }

  resetCheckers() {
    this.selectedSpoItems = [];
    this.toggleAll(false);
  }

  emitBulkActions() {
    this.bulkActionsEmitter.emit(this.bulkActionsActive);
  }
}
