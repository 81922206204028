import { Component, OnInit, ViewChild } from '@angular/core';
import { ImportExportService } from '../../../../shared/import-export.service';
import { PoItemWithLot } from '../../../../models/po-item-with-lot-model';
import { LotEditKpi } from '../../../../models/lot-edit-kpi';
import { Observable } from 'rxjs';
import { LotsService } from '../../../../shared/lots.service';
import { PoFilters } from '../../../../models/filters/po-filters-model';
import { PosService } from '../../../../shared/pos.service';
import { addCheckers } from '../../../../shared/checkers.service';
import { AuthorizationService } from '../../../../core/authorization.service';
import { Permissions } from '../../../../models/permission-model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { isNilty } from '../../../../core/utils.service';

@Component({
  selector: 'app-pos-list-details',
  templateUrl: './pos-list-details.component.html',
  styleUrls: ['./pos-list-details.component.css'],
})
export class PosListDetailsComponent implements OnInit {
  constructor(
    private poService: PosService,
    private lotsService: LotsService,
    private importExportService: ImportExportService,
    private authorizationService: AuthorizationService
  ) {}

  dataPoItems = [];

  kpiData = new LotEditKpi();

  dataSource: MatTableDataSource<PoItemWithLot>;

  mainChecker = false;

  bulkActionsActive = false;

  resultsNumber: Observable<number>;

  hasLotManagement: Observable<boolean>;

  displayedColumns = [
    'check',
    'poId',
    'vendor',
    'parentVendor',
    'destination',
    'orderedOn',
    'earliestDate',
    'latestDate',
    'ean',
    'title',
    'category',
    'sku',
    'brand',
    'backorder',
    'expectedDate',
    'submittedQuantity',
    'acceptedQuantity',
    'lastPoaQuantity',
    'obsoleteWithAccepted',
    'unitCost',
    'totalCost',
    'lotName',
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    this.resultsNumber = this.poService.resultsNumber;
    if (isNilty(this.poService.poFilters)) {
      this.poService.poFilters = new PoFilters();
      this.poService.poFilters.status = 'New';
    }

    this.resetCheckers();
    this.dataSource = new MatTableDataSource([]);
    this.loadPoItems(this.poService.poFilters);

    this.hasLotManagement = this.authorizationService.hasPermission(Permissions.LotManagement);
  }

  loadPoItems(filters: PoFilters) {
    this.poService.getFilteredPoItems(filters).subscribe((poItems: PoItemWithLot[]) => {
      this.dataPoItems = addCheckers(poItems, this.poService.selectedPoItems, this.mainChecker);
      this.dataSource.data = this.dataPoItems;
    });
  }

  loadPage() {
    this.poService.poFilters.pageNumber = this.paginator.pageIndex;
    this.poService.poFilters.pageSize = this.paginator.pageSize;
    this.loadPoItems(this.poService.poFilters);
  }

  onSort() {
    this.poService.poFilters.sortBy = this.sort.active;
    this.poService.poFilters.sortDirection = this.sort.direction;
    this.loadPoItems(this.poService.poFilters);
  }

  applyFilter(filters: PoFilters) {
    this.paginator.pageIndex = 0;
    this.resetCheckers();
    this.loadPoItems(filters);
  }

  onSelected(value: boolean, selectedPoItem: any) {
    const selectedIndex = this.dataPoItems.indexOf(selectedPoItem);
    this.dataPoItems[selectedIndex].checked = value;

    if (value !== this.mainChecker) {
      this.poService.addPoItemIdToSelected(this.dataPoItems[selectedIndex].id);
    } else {
      this.poService.removePoItemIdFromSelected(this.dataPoItems[selectedIndex].id);
    }
    this.bulkActionsActive = value || this.mainChecker;
  }

  toggleAll(value: boolean) {
    this.mainChecker = value;
    this.poService.mainChecker = this.mainChecker;
    for (const i of this.dataPoItems) {
      i.checked = value;
    }
    this.poService.selectedPoItems = [];
    this.bulkActionsActive = value;
  }

  exportPoItems(format: string) {
    const selectedPoItemsIds = this.poService.selectedPoItems;

    this.importExportService
      .exportPoItemsToExcel(selectedPoItemsIds, format, this.mainChecker, this.poService.poFilters)
      .subscribe((resp) => {
        console.log(resp);
      });
  }

  resetCheckers() {
    this.poService.selectedPoItems = [];
    this.mainChecker = false;
    this.bulkActionsActive = false;
  }
}
