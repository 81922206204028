<mat-toolbar class="bar-custom">
  <div class="container">
    <div class="row">
      <div class="col-md-10">
        <button class="btn-custom" [routerLink]="['new']">New Vendor</button>
      </div>
      <div class="col-md-2 text-right">
        <mat-slide-toggle class="text-blue font-15" [checked]="showOnlyParents" (change)="onChangeParentChoice($event)"
          >Show Only Parent Codes</mat-slide-toggle
        >
      </div>
    </div>
  </div>
</mat-toolbar>

<div class="container table-outer">
  <div class="table-header">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search" />
    </mat-form-field>
  </div>

  <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]"> </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="code">
        <mat-header-cell *matHeaderCellDef mat-sort-header="code"> Code </mat-header-cell>
        <mat-cell *matCellDef="let vendor"> {{ vendor.code }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell class="name-column" *matHeaderCellDef mat-sort-header="name"> Name </mat-header-cell>
        <mat-cell class="name-column" *matCellDef="let vendor"
          ><p>{{ vendor.name }}</p></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="country">
        <mat-header-cell *matHeaderCellDef mat-sort-header="country"> Country </mat-header-cell>
        <mat-cell *matCellDef="let vendor"> {{ vendor.country }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="brandonGln">
        <mat-header-cell *matHeaderCellDef mat-sort-header="brandonGln"> GLN </mat-header-cell>
        <mat-cell *matCellDef="let vendor"> {{ vendor.brandonGln }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="defaultOrderAcceptanceMode">
        <mat-header-cell *matHeaderCellDef mat-sort-header="defaultOrderAcceptanceMode">
          Default order <br />
          acceptance mode
        </mat-header-cell>
        <mat-cell *matCellDef="let vendor"> {{ vendor.defaultOrderAcceptanceMode | capitalizePipe }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="sendSoftFirstorder">
        <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="sendSoftFirstorder">
          Send Soft Firstorder
        </mat-header-cell>
        <mat-cell class="boolean-column" *matCellDef="let vendor">
          <p><i [ngClass]="vendor.sendSoftFirstorder ? 'fa fa-check' : 'fa fa-times sel-icon'" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="brandonWarehouseCode">
        <mat-header-cell *matHeaderCellDef mat-sort-header="country"> Brandon Warehouse Code </mat-header-cell>
        <mat-cell *matCellDef="let vendor"> {{ vendor.brandonWarehouseCode }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="showChildren">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let vendor">
          <button
            class="btn-product btn-fixed"
            style="width: 100px !important; float: right;"
            *ngIf="vendor.hasChildren"
            (click)="showChildren(vendor)"
          >
            Show Children
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="edit">
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let vendor">
          <button class="btn-product btn-fixed" style="margin-left: 15px;" [routerLink]="['edit', vendor.id]">Edit</button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
