import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ConverterService } from '../../../../core/converter.service';
import { EnvironmentService } from '../../../../environment.service';
import { ClaimHeader } from '../../../../models/claim-header-model';
import { ClaimFilters } from '../../../../models/filters/claim-filters-model';
import { Observable, Subject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Claim } from '../../../../models/claim-model';
import { ClaimDetailFilters } from '../../../../models/filters/claim-detail-filters-model';
import { ClaimHeaderFilters } from '../../../../models/filters/claim-header-filters-model';
import { ClaimDetail } from '../../../../models/claim-detail-model';
import { ClaimEditData } from './edit-claim-modal/edit-claim-modal.component';
import { Status } from '../../../../models/status-model';

@Injectable()
export class ClaimService {
  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  resultsNumber = new Subject<number>();

  claimHeaderFilters: ClaimHeaderFilters;
  claimHeaderSelectedRow: ClaimHeader;

  claimFilters: ClaimFilters;

  claimDetailFilters: ClaimDetailFilters;

  getFilteredClaimHeaders(filters: ClaimHeaderFilters): Observable<ClaimHeader[]> {
    return this.http
      .post(this.environmentService.getRestEndpoint('claimHeaders'), this.createFilterBody(filters), { observe: 'response' })
      .pipe(
        map((resp: HttpResponse<any>) => {
          this.resultsNumber.next(+resp.headers.get('Total-Length'));
          return resp.body;
        }),
        map((resp: ClaimHeader[]) => resp.map((r) => this.converter.fromJSONtoObj(r, ClaimHeader)))
      );
  }

  getFilteredClaims(filters: ClaimFilters): Observable<Claim[]> {
    return this.http.post(this.environmentService.getRestEndpoint('claims'), this.createFilterBody(filters), { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((resp: Claim[]) => resp.map((r) => this.converter.fromJSONtoObj(r, Claim)))
    );
  }

  getFilteredClaimDetails(claimId: number, filters: ClaimDetailFilters): Observable<ClaimDetail[]> {
    return this.http
      .post(this.environmentService.getRestEndpoint('claims') + '/' + claimId + '/claim-details', this.createFilterBody(filters), {
        observe: 'response',
      })
      .pipe(
        map((resp: HttpResponse<any>) => {
          this.resultsNumber.next(+resp.headers.get('Total-Length'));
          return resp.body;
        }),
        map((resp: ClaimDetail[]) => resp.map((r) => this.converter.fromJSONtoObj(r, ClaimDetail)))
      );
  }

  updateClaim(claimId: number, claimEditData: ClaimEditData): Observable<Claim> {
    return this.http
      .post(this.environmentService.getRestEndpoint('claims') + '/' + claimId, this.converter.fromObjtoJSON(claimEditData))
      .pipe(map((resp: Claim) => this.converter.fromJSONtoObj(resp, Claim)));
  }

  deleteClaim(claimId: number): Observable<boolean> {
    const path = this.environmentService.getRestEndpoint('claims') + '/' + claimId;

    return this.http.delete(path).pipe(map(() => true));
  }

  getClaimHeaderStatus(): Observable<Status[]> {
    return this.getStatus(this.environmentService.getRestEndpoint('claimHeaders')).pipe(map((status: Status[]) => status));
  }

  getClaimStatus(): Observable<Status[]> {
    return this.getStatus(this.environmentService.getRestEndpoint('claims')).pipe(map((status: Status[]) => status));
  }

  downloadLetter(ids: number[], filters: ClaimHeaderFilters, mainChecker: boolean): Observable<void> {
    if (mainChecker) {
      return this.downloadLetterFromFilters(filters);
    } else {
      return this.downloadLetterFromIds(ids);
    }
  }

  changeBotStatus(status: string, selectedIds: number[], filters: ClaimHeaderFilters, mainChecker: boolean): Observable<any> {
    if (mainChecker) {
      return this.changeBotStatusFromFilters(status, filters);
    } else {
      return this.changeBotStatusByIds(status, selectedIds);
    }
  }

  private changeBotStatusByIds(status: string, selectedIds: number[]): Observable<any> {
    const path = this.environmentService.getRestEndpoint('claimHeaders') + '/change-bot-status=' + status;

    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(selectedIds);
    return this.http.post(path, body, { headers });
  }

  private changeBotStatusFromFilters(status: string, filters: ClaimHeaderFilters): Observable<void> {
    const path = this.environmentService.getRestEndpoint('claimHeaders') + '/change-bot-status-from-filters=' + status;

    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = this.converter.fromObjtoJSON(filters);
    return this.http.post<void>(path, body, { headers });
  }

  private downloadLetterFromFilters(filters: ClaimHeaderFilters): Observable<void> {
    const path = this.environmentService.getRestEndpoint('exportClaimLetter') + '/filters';
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = this.converter.fromObjtoJSON(filters);

    return this.http.post<void>(path, body, { headers });
  }

  private downloadLetterFromIds(ids: number[]): Observable<void> {
    const path = this.environmentService.getRestEndpoint('exportClaimLetter') + '/ids';
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(ids);

    return this.http.post<void>(path, body, { headers });
  }

  private getStatus(base: string): Observable<Status[]> {
    return this.http.get(base + '/status').pipe(map((resp: Claim) => this.converter.fromJSONtoObj(resp, Status)));
  }

  private createFilterBody(filters: any): string {
    return this.converter.fromObjtoJSON(filters);
  }
}
