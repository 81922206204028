import { JsonObject, JsonProperty } from 'json2typescript';
import { isNilty } from '../../core/utils.service';
import { DateConverter, fromDateToString } from '../date-converter';
import { MatChipsData } from '../mat-chips-data-model';
import { Vendor } from '../vendor-model';

@JsonObject('InvoiceFilters')
export class InvoiceFilters {
  @JsonProperty('listOfIds', [Number], true) listOfIds: number[] = [];

  @JsonProperty('vendorCountry', String, true) vendorCountry: string = undefined;
  @JsonProperty('vendor', Number, true) vendor: number = undefined;
  @JsonProperty('asn', String, true) asn: string = undefined;
  @JsonProperty('po', String, true) po: string = undefined;
  @JsonProperty('invoiceNumber', String, true) invoiceNumber: string = undefined;
  @JsonProperty('dueDate', DateConverter, true) dueDate: Date = undefined;
  @JsonProperty('remittanceNumber', String, true) remittanceNumber: string = undefined;
  @JsonProperty('remittanceDateStart', DateConverter, true) remittanceDateStart: Date = undefined;
  @JsonProperty('remittanceDateEnd', DateConverter, true) remittanceDateEnd: Date = undefined;
  @JsonProperty('sentToAmazon', String, true) sentToAmazon: string = undefined;
  @JsonProperty('hasProbableNC', String, true) hasProbableNC: string = undefined;
  @JsonProperty('pastDueDate', String, true) pastDueDate: string = undefined;
  @JsonProperty('ean', String, true) ean: string = undefined;
  @JsonProperty('orderId', String, true) orderId: string = undefined;


  @JsonProperty('pageNumber', Number, true) pageNumber = 0;
  @JsonProperty('pageSize', Number, true) pageSize = 10;
  @JsonProperty('sortBy', String, true) sortBy: string = undefined;
  @JsonProperty('sortDirection', String, true) sortDirection: string = undefined;
}

export const invoiceFiltersMap = (
  vendorCountry?: string,
  vendor?: Vendor,
  asn?: string,
  po?: string,
  invoiceNumber?: string,
  dueDate?: Date,
  remittanceNumber?: string,
  remittanceDateStart?: Date,
  remittanceDateEnd?: Date,
  sentToAmazon?: string,
  hasProbableNC?: string,
  pastDueDate?: string,
  ean?: string,
  orderId?: string
): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (!isNilty(vendorCountry)) {
    map.push(new MatChipsData('Vendor Contry', vendorCountry));
  }
  if (!isNilty(vendor)) {
    map.push(new MatChipsData('Vendor', vendor.code));
  }
  if (!isNilty(asn)) {
    map.push(new MatChipsData('ASN', asn));
  }
  if (!isNilty(po)) {
    map.push(new MatChipsData('PO', po));
  }
  if (!isNilty(invoiceNumber)) {
    map.push(new MatChipsData('Invoice Number', invoiceNumber));
  }
  if (!isNilty(dueDate)) {
    map.push(new MatChipsData('Due Date', fromDateToString(dueDate)));
  }
  if (!isNilty(remittanceNumber)) {
    map.push(new MatChipsData('Remittance Number', remittanceNumber));
  }
  if (!isNilty(remittanceDateStart)) {
    map.push(new MatChipsData('Remittance Date Start', fromDateToString(remittanceDateStart)));
  }
  if (!isNilty(remittanceDateEnd)) {
    map.push(new MatChipsData('Remittance Date End', fromDateToString(remittanceDateEnd)));
  }
  if (!isNilty(sentToAmazon)) {
    map.push(new MatChipsData('Sent to Amazon', sentToAmazon));
  }
  if (!isNilty(hasProbableNC)) {
    map.push(new MatChipsData('Has Probable NC', hasProbableNC));
  }
  if (!isNilty(pastDueDate)) {
    map.push(new MatChipsData('Has Passed Due Date', pastDueDate));
  }
  if (!isNilty(ean)) {
    ean.split("\n").filter(e => e.length > 0).forEach(singleEan => map.push(new MatChipsData('EAN', singleEan)));
  }
  if (!isNilty(orderId)) {
    orderId.split("\n").filter(id => id.length > 0).forEach(id => map.push(new MatChipsData('Order ID', id)));
  }

  return map;
};
