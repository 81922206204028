<mat-toolbar class="lot-edit-bulk-actions">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 text-right">
        <button [disabled]="!bulkActionsActive" [matMenuTriggerFor]="exportShipmentItemsChoice" class="box-shadow-none btn-custom">
          Export selected
        </button>
        <mat-menu #exportShipmentItemsChoice="matMenu">
          <button mat-menu-item (click)="exportShipmentItems('excel')">Save as Excel</button>
          <button mat-menu-item (click)="exportShipmentItems('csv')">Save as CSV</button>
        </mat-menu>
      </div>
    </div>
  </div>
</mat-toolbar>
