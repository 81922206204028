import { Component, OnInit } from '@angular/core';
import { InventoryService } from '../../inventory.service';
import { ActivatedRoute } from '@angular/router';
import { isNilty } from '../../../../core/utils.service';
import { Location } from '@angular/common';
import { Product } from '../../../../models/product-model';
import { ProductDetails } from '../../../../models/product-details-model';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css'],
})
export class ProductDetailsComponent implements OnInit {
  constructor(private inventoryService: InventoryService, private activatedRoute: ActivatedRoute, private location: Location) {}

  productDetails = new ProductDetails();
  product = new Product();

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      if (!isNilty(params['id']) && !isNaN(+params['id'])) {
        this.inventoryService.getProductDetails(+params['id']).subscribe((resp: ProductDetails) => {
          this.productDetails = resp;
          this.product = this.productDetails.product;
        });
      } else {
        this.location.back();
      }
    });
  }

  goBack() {
    this.location.back();
  }
}
