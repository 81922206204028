import { Component, Inject, OnInit } from '@angular/core';
import { ChangeCarrierPropertiesInput, Shipment } from '../../../models/shipment-model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Contract } from '../../../models/contract.model';
import { ShipmentsService } from '../../shipments.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { isEmpty, isNilty } from '../../../core/utils.service';
//eslint-disable-next-line
import { GenericConfirmationModalComponent } from '../../../container/modal/generic-confirmation-modal/generic-confirmation-modal.component';

export class EditCarrierModalData {
  hasTransit: boolean;
  shipmentId: number;
  formData: ChangeCarrierPropertiesInput;
}

@Component({
  selector: 'app-edit-carrier-modal',
  templateUrl: './edit-carrier-modal.component.html',
  styleUrls: ['./edit-carrier-modal.component.css'],
})
export class EditCarrierModalComponent implements OnInit {
  carrierForm: FormGroup;
  contracts: Contract[];
  hasTransit = false;

  constructor(
    public dialogRef: MatDialogRef<EditCarrierModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditCarrierModalData,
    private shipmentsService: ShipmentsService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.hasTransit = this.data.hasTransit;

    this.shipmentsService.getContracts(this.data.shipmentId).subscribe((contracts) => {
      this.contracts = contracts;
      if (this.data.formData.contract) {
        this.carrierForm.controls.contract.setValue(this.contracts.find((contract) => contract.id === this.data.formData.contract.id));
      }
    });

    this.carrierForm = new FormGroup({
      contract: new FormControl(this.data.formData.contract),
      trackingNumber: new FormControl(this.data.formData.trackingNumber),
      expectedDeliveryDate: new FormControl(this.data.formData.expectedDeliveryDate),
      deliveryDate: new FormControl(this.data.formData.deliveryDate),
      incomingDate: new FormControl(this.data.formData.incomingDate),
      preparationDays: new FormControl(
        this.data.formData.preparationDays || (this.hasTransit ? 2 : null),
        this.hasTransit ? [Validators.required, Validators.min(0)] : null
      ),
    });
  }

  onClose() {
    this.dialogRef.close();
  }

  onSubmit() {
    const input = new ChangeCarrierPropertiesInput();
    input.contract = this.carrierForm.value.contract;
    input.trackingNumber = this.carrierForm.value.trackingNumber;
    input.expectedDeliveryDate = this.carrierForm.value.expectedDeliveryDate;
    input.deliveryDate = this.carrierForm.value.deliveryDate;
    input.incomingDate = this.carrierForm.value.incomingDate;
    input.preparationDays = this.carrierForm.value.preparationDays;

    this.shipmentsService.checkTrackingNumber(this.data.shipmentId, input.trackingNumber).subscribe((shipments: Shipment[]) => {
      if (isNilty(shipments) || isEmpty(shipments)) {
        this.dialogRef.close(input);
      } else {
        this.dialog
          .open(GenericConfirmationModalComponent, {
            data:
              `Provided tracking ${input.trackingNumber} was found on other existing Shipments: ` +
              shipments.map((it) => `${it.orderId} (${it.shipmentCode})`).join(', '),
          })
          .afterClosed()
          .subscribe((r: boolean) => {
            if (r) {
              this.dialogRef.close(input);
            }
          });
      }
    });
  }
}
