import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConverterService } from '../../../core/converter.service';
import { EnvironmentService } from '../../../environment.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PredicateFieldsOutput, PublicationRule } from '../../../models/publication-rule';
import { Status } from '../../../models/publication-rule-status-model';
import { cachedArrayGet, GenericArrayCache } from '../../../shared/service-cached-array';

@Injectable()
export class PublicationRuleService {
  publicationRules: PublicationRule[];
  rulePredicateFieldsCache = new GenericArrayCache<PredicateFieldsOutput>(this.http, this.converter, PredicateFieldsOutput);

  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  getRules(): Observable<PublicationRule[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('publicationRule'))
      .pipe(
        map((publicationRule: PublicationRule[]) => publicationRule.map((rule) => this.converter.fromJSONtoObj(rule, PublicationRule)))
      );
  }

  getStatus(): Observable<Status[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('publicationRuleStatus'))
      .pipe(map((status: Status[]) => status.map((it) => this.converter.fromJSONtoObj(it, Status))));
  }

  getStrategies(): Observable<string[]> {
    return this.http.get(this.environmentService.getRestEndpoint('publicationRuleStrategies')).pipe(map((strategy: string[]) => strategy));
  }

  getRule(ruleId: number): Observable<PublicationRule> {
    let path = this.environmentService.getRestEndpoint('publicationRule');

    path += '/' + ruleId;

    return this.http.get(path).pipe(map((resp: PublicationRule) => this.converter.fromJSONtoObj(resp, PublicationRule)));
  }

  postRule(publicationRule: PublicationRule): Observable<boolean> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = this.converter.fromObjtoJSON(publicationRule);
    return this.http.post(this.environmentService.getRestEndpoint('publicationRule'), body, { headers }).pipe(map((resp: any) => resp));
  }

  deleteRule(ruleId: number): Observable<boolean> {
    let path = this.environmentService.getRestEndpoint('publicationRule');

    if (!this.environmentService.dev) {
      path += '/' + ruleId;
    }

    return this.http.delete(path).pipe(map(() => true));
  }

  getRulePredicateFields(): Observable<PredicateFieldsOutput[]> {
    return cachedArrayGet<PredicateFieldsOutput>(
      this.rulePredicateFieldsCache,
      this.environmentService.getRestEndpoint('publicationRulePredicateFields')
    );
  }
}
