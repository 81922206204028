import { JsonObject, JsonProperty } from 'json2typescript';
import { Warehouse } from './warehouse-model';
import { Vendor } from './vendor-model';
import { BaseObject } from './base-object-model';
import { PoaItemLog, PoaLog } from './poa-logs-model';

@JsonObject('Po')
export class Po extends BaseObject {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('poId', String, false) poId: string = undefined;
  @JsonProperty('vendor', Vendor, true) vendor: Vendor = undefined;
  @JsonProperty('orderedOn', String, true) orderedOn: string = undefined;
  @JsonProperty('latestDate', String, true) latestDate: string = undefined;
  @JsonProperty('earliestDate', String, true) earliestDate: string = undefined;
  @JsonProperty('closedDate', String, true) closedDate: string = undefined;
  @JsonProperty('status', String, true) status: string = undefined;
  @JsonProperty('submittedQuantity', Number, false) submittedQuantity: number = undefined;
  @JsonProperty('acceptedQuantity', Number, false) acceptedQuantity: number = undefined;
  @JsonProperty('cancelledQuantity', Number, false) cancelledQuantity: number = undefined;
  @JsonProperty('receivedQuantity', Number, false) receivedQuantity: number = undefined;
  @JsonProperty('lastPoaQuantity', Number, false) lastPoaQuantity: number = undefined;
  @JsonProperty('submittedValue', Number, false) submittedValue: number = undefined;
  @JsonProperty('acceptedValue', Number, false) acceptedValue: number = undefined;
  @JsonProperty('cancelledValue', Number, false) cancelledValue: number = undefined;
  @JsonProperty('receivedValue', Number, false) receivedValue: number = undefined;
  @JsonProperty('hasLot', Boolean, true) hasLot: boolean = undefined;
  @JsonProperty('destination', Warehouse, true) destination: Warehouse = undefined;

  @JsonProperty('poaLog', String, true) poaLog: string = undefined;
  @JsonProperty('ediUrl', String, true) ediUrl: string = undefined;

  convertedPoaLogs: PoaLog[] = undefined;
}
