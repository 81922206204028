import { Component, Inject, OnInit } from '@angular/core';
import { Shipment } from '../../../models/shipment-model';
import { ShipmentsService } from '../../shipments.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SendShipmentDocumentInput, ShipmentDocument } from '../../../models/shipment-documents-model';
import { GenericErrorModalComponent } from '../../../container/modal/generic-error-modal/generic-error-modal.component';

@Component({
  selector: 'app-edit-carrier-modal',
  templateUrl: './send-documents-modal.component.html',
  styleUrls: ['./send-documents-modal.component.css'],
})
export class SendDocumentsModalComponent implements OnInit {
  shipment: Shipment;
  documents: ShipmentDocument[];

  constructor(
    public dialogRef: MatDialogRef<SendDocumentsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Shipment,
    private shipmentsService: ShipmentsService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.shipment = this.data;

    this.shipmentsService.getShipmentDocuments().subscribe((resp: ShipmentDocument[]) => {
      this.documents = resp.map((it) => {
        it.isAvailable(this.shipment);
        return it;
      });
    });
  }

  sendDocuments() {
    const body = new SendShipmentDocumentInput();
    body.shipmentId = this.shipment.id;
    body.documents = this.documents.filter((it) => it.selected).map((it) => it.name);

    if (body.documents.length !== 0) {
      this.shipmentsService.sendShipmentDocuments(body).subscribe(() => this.dialogRef.close(true));
    } else {
      this.dialog.open(GenericErrorModalComponent, { data: 'You must select at least one document.' });
    }
  }
}
