<app-main-spos-filters (onFilter)="onFilter($event)"></app-main-spos-filters>

<div class="bulk-actions-div">
  <mat-toolbar class="bar-custom">
    <div class="container">
      <div class="row">
        <div class="col-md-2">
          <button class="box-shadow-none btn-custom" routerLink="/spos/details">Show SPO Items</button>
        </div>
        <div class="col-md-8">
          <button
            class="box-shadow-none btn-custom margin-right-6"
            [disabled]="!bulkActionsActive"
            [matMenuTriggerFor]="exportPoItemsChoice"
          >
            Export SPOs
          </button>
          <mat-menu #exportPoItemsChoice="matMenu">
            <button mat-menu-item (click)="export('excel')">Save as Excel</button>
            <button mat-menu-item (click)="export('csv')">Save as CSV</button>
          </mat-menu>
        </div>
      </div>
    </div>
  </mat-toolbar>
</div>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <hr class="divisor-title" style="width: calc(100% - 10rem)" />
      <div class="square" style="right: 10rem"></div>
      <h2 class="po-element-custom title-custom">Spos</h2>
    </div>
  </div>
</div>

<div class="container table-outer">
  <mat-paginator [length]="resultsNumber | async" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()">
  </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="check" sticky>
        <mat-header-cell *matHeaderCellDef class="checker-col">
          <mat-checkbox (change)="toggleAll($event.checked)" [ngModel]="mainChecker"></mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let spo; let i = index" class="checker-col">
          <mat-checkbox (change)="onSelected($event.checked, spo)" [ngModel]="spo.checked"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="code">
        <mat-header-cell *matHeaderCellDef mat-sort-header="spoCode" class="shipment-code-column"> Code </mat-header-cell>
        <mat-cell *matCellDef="let spo" class="shipment-code-column">
          <a [routerLink]="['/spos', spo.id]">{{ spo.code }}</a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="orderId" sticky>
        <mat-header-cell class="order-id-column" *matHeaderCellDef mat-sort-header="orderId">Order ID</mat-header-cell>
        <mat-cell class="order-id-column" *matCellDef="let spo">{{ spo.orderId }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="vendor" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="vendor" class="vendor-column"> Vendor </mat-header-cell>
        <mat-cell *matCellDef="let spo" class="vendor-column">{{ spo.vendor ? spo.vendor.code : '' }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="lotName">
        <mat-header-cell *matHeaderCellDef mat-sort-header="lot" class="lot-name-column"> Lot </mat-header-cell>
        <mat-cell *matCellDef="let spo" class="lot-name-column"
          ><a [routerLink]="['/lot-edit', spo.lot?.id, 'po']">{{ spo.lot?.name }}</a></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="extra">
        <mat-header-cell class="extra-flag-column" *matHeaderCellDef> Extra </mat-header-cell>
        <mat-cell class="extra-flag-column" *matCellDef="let spo">
          <i [ngClass]="spo.isExtra | booleanIconPipe" aria-hidden="true"></i>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="crossUkBorder">
        <mat-header-cell class="extra-flag-column" *matHeaderCellDef> Cross UK </mat-header-cell>
        <mat-cell class="extra-flag-column" *matCellDef="let spo">
          <i [ngClass]="spo.crossUkBorder | booleanIconPipe" aria-hidden="true"></i>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="destination" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="destination" class="destination-column"> Destination </mat-header-cell>
        <mat-cell
          class="destination-column"
          *matCellDef="let spo"
          [matTooltip]="spo.destination?.code + ' - ' + spo.destination?.name"
          matTooltipPosition="below"
        >
          <p>{{ spo.destination?.code + ' - ' + spo.destination?.name }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="earliestDeliveryDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="earliestDeliveryDate" class="date-column"> Earliest Date </mat-header-cell>
        <mat-cell class="date-column" *matCellDef="let spo">
          <p>{{ spo.earliestDeliveryDate }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="latestDeliveryDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="latestDeliveryDate" class="date-column"> Latest Date </mat-header-cell>
        <mat-cell class="date-column" *matCellDef="let spo">
          <p>{{ spo.latestDeliveryDate }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="latestPickupDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="latestPickupDate" class="date-column"> Latest Pickup Date </mat-header-cell>
        <mat-cell class="date-column" *matCellDef="let spo">
          <p>{{ spo.latestPickupDate }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="supplier" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="supplier" class="supplier-column"> Supplier </mat-header-cell>
        <mat-cell class="supplier-column" *matCellDef="let spo" [matTooltip]="spo.supplier?.name" matTooltipPosition="below">
          <p>{{ spo.supplier?.name }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="requestedQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="requestedQuantity">
          Requested quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let spo">
          <p>{{ spo.requestedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="confirmedQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="confirmedQuantity">
          Confirmed quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let spo">
          <p>{{ spo.confirmedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="orderedQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="orderedQuantity">
          Ordered quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let spo">
          <p>{{ spo.orderedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="margin">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="margin"> Margin </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.margin | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="percentMargin">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="percentMargin"> Per cent margin </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let spo">
          <p>{{ spo.percentMargin | percentageFromDecimal }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="virtualMargin">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="virtualMargin"> Virtual Margin </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.virtualMargin | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="virtualPercentMargin">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="virtualPercentMargin">
          Virtual Percent margin
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let spo">
          <p>{{ spo.virtualPercentMargin | percentageFromDecimal }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="virtualConfirmedMargin">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="virtualConfirmedMargin">
          Virtual Confirmed Margin
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.virtualConfirmedMargin | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="virtualConfirmedPercentMargin">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="virtualConfirmedPercentMargin">
          Virtual Confirmed margin %
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let spo">
          <p>{{ spo.virtualConfirmedPercentMargin | percentageFromDecimal }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="requestedValue">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="requestedValue"> Requested value </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.requestedValue | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="confirmedValue">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="confirmedValue"> Confirmed value </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.confirmedValue | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="confirmedPurchasePrice">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="confirmedPurchasePrice">
          Confirmed purchase price
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.confirmedPurchasePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="virtualConfirmedPurchasePrice">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="virtualConfirmedPurchasePrice">
          Virtual Confirmed purchase price
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.virtualConfirmedPurchasePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="orderedValue">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="orderedValue"> Ordered value </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.orderedValue | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="orderedPurchasePrice">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="orderedPurchasePrice">
          Ordered purchase price
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.orderedPurchasePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="virtualOrderedPurchasePrice">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="virtualOrderedPurchasePrice">
          Virtual Ordered purchase price
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.virtualOrderedPurchasePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell class="shipment-status-column" *matHeaderCellDef mat-sort-header="status"> Status </mat-header-cell>
        <mat-cell class="shipment-status-column" *matCellDef="let spo">
          <p>{{ spo.spoStatus | spoStatusPipe }}</p>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
