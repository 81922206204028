import { JsonObject, JsonProperty } from 'json2typescript';
import { Remittance } from './remittance-model';
import { Invoice } from './invoice-model';
import { Lot } from './lot-model';
import { Supplier } from './supplier-model';
import { StockLocation } from './stock-location-model';
import { Vendor } from './vendor-model';
import { Warehouse } from './warehouse-model';
import { Status } from './status-model';

@JsonObject('LogDiscardedValue')
export class LogDiscardedValue {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('lot', Lot, true) lot: Lot = undefined;
  @JsonProperty('supplier', Supplier, true) supplier: Supplier = undefined;
  @JsonProperty('stockLocation', StockLocation, true) stockLocation: StockLocation = undefined;
  @JsonProperty('vendor', Vendor, true) vendor: Vendor = undefined;
  @JsonProperty('destination', Warehouse, true) destination: Warehouse = undefined;
  @JsonProperty('type', Status, true) type: Status = undefined;
  @JsonProperty('discardedValue', Number, true) discardedValue: number = undefined;
  @JsonProperty('description', String, true) description: string = undefined;
}
