import { Any, JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('PredicateField')
export class PredicateField {
  @JsonProperty('fieldName', String, true) fieldName: string = undefined;
  @JsonProperty('type', String, true) type: string = undefined;
}

@JsonObject('PredicateOperator')
export class PredicateOperator {
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('description', String, true) description: string = undefined;
  @JsonProperty('type', String, true) type: string = undefined;
}

@JsonObject('PredicateFieldsOutput')
export class PredicateFieldsOutput {
  @JsonProperty('field', PredicateField, true) field: PredicateField = undefined;
  @JsonProperty('operators', [PredicateOperator], true) operators: PredicateOperator[] = undefined;
}

@JsonObject('Predicate')
export class Predicate {
  @JsonProperty('field', PredicateField, true) field: PredicateField = undefined;
  @JsonProperty('operator', PredicateOperator, true) operator: PredicateOperator = undefined;
  @JsonProperty('target', Any, true) target: any = undefined;
  @JsonProperty('negatePredicate', Boolean, true) negatePredicate: boolean = undefined;
  @JsonProperty('isEnabled', Boolean, true) isEnabled: boolean = undefined;

  isEdit = false;
  availableOperators: PredicateOperator[];

  get description(): string {
    return `${this.field.fieldName} ${this.negatePredicate ? 'not ' : ''}${this.operator.description} ${this.target}`;
  }
}

@JsonObject('MarkupConstraint')
export class MarkupConstraint {
  @JsonProperty('markup', Number, true) markup: number = undefined;
  @JsonProperty('predicates', [Predicate], true) predicates: Predicate[] = undefined;
  @JsonProperty('markupEnabled', Boolean, true) markupEnabled: boolean = undefined;

  get description(): string {
    return `Markup ${this.markup}%: ${this.predicates
      .filter((p) => !!p.field && !!p.operator && !!p.target)
      .map((p) => p.description)
      .join(' and ')}`;
  }
}

@JsonObject('MarginConstraint')
export class MarginConstraint {
  @JsonProperty('margin', Number, true) margin: number = undefined;
  @JsonProperty('predicates', [Predicate], true) predicates: Predicate[] = undefined;
  @JsonProperty('marginEnabled', Boolean, true) marginEnabled: boolean = undefined;

  get description(): string {
    return `Margin ${this.margin}%: ${this.predicates
      .filter((p) => !!p.field && !!p.operator && !!p.target)
      .map((p) => p.description)
      .join(' and ')}`;
  }
}

@JsonObject('PrioritySupplier')
export class PrioritySupplier {
  @JsonProperty('supplierId', Number, true) supplierId: number = undefined;
  @JsonProperty('priority', Number, true) priority: number = undefined;

  isEdit = false;
}

@JsonObject('JsonRule')
export class JsonRule {
  @JsonProperty('childId', Number, true) childId: number = undefined;
  @JsonProperty('strategy', String, true) strategy: string = undefined;
  @JsonProperty('strategyPercentage', String, true) strategyPercentage: string = undefined;
  @JsonProperty('constraints', [Predicate], true) constraints: Predicate[] = [];
  @JsonProperty('markupConstraints', [MarkupConstraint], true) markupConstraints: MarkupConstraint[] = [];
  @JsonProperty('marginConstraints', [MarginConstraint], true) marginConstraints: MarginConstraint[] = [];
  @JsonProperty('qtyThreshold', String, true) qtyThreshold: string = undefined;
  @JsonProperty('unitCostThreshold', String, true) unitCostThreshold: string = undefined;
  @JsonProperty('prioritySuppliers', [PrioritySupplier], true) prioritySuppliers: PrioritySupplier[] = [];
  @JsonProperty('sendFlatFile', Boolean, true) sendFlatFile: boolean = undefined;
  @JsonProperty('supplierName', String, true) supplierName: string = undefined;
  @JsonProperty('childCode', String, true) childCode: string = undefined;
  @JsonProperty('statusDescription', String, true) statusDescription: string = undefined;
  @JsonProperty('priceVariation', String, true) priceVariation: string = undefined;
}

@JsonObject('PublicationRule')
export class PublicationRule {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('type', String, false) type: string = undefined;
  @JsonProperty('status', Number, false) status: number = undefined;
  @JsonProperty('rule', JsonRule, false) rule: JsonRule = undefined;
}
