<app-single-po-details-filters (onPoItemFilter)="applyFilter($event)"></app-single-po-details-filters>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <hr class="divisor-title" style="width: calc(100% - 23rem)" />
      <div class="square" style="right: 23rem"></div>
      <h2 class="po-element-custom title-custom">PO: {{ po.poId }}</h2>
    </div>
  </div>
</div>

<div class="lot-kpi">
  <div class="container">
    <div class="col-md-4 background-blue text-white font-row min-height-box padding-top-15">
      <p class="key-table-fixed"><b class="margin-right-6"> Status: </b> {{ po.status ? po.status : ' - ' }}</p>
      <p class="key-table-fixed"><b class="margin-right-6"> Vendor: </b> {{ po.vendor ? po.vendor.code : ' - ' }}</p>
      <p *ngIf="!po.vendor?.parent" class="key-table-fixed">
        <b class="margin-right-6"> Parent Vendor: </b> {{ po.vendor?.parentVendor?.code }}
      </p>
      <p class="key-table-fixed">
        <b class="margin-right-6"> Ship To Location: </b> {{ po.destination ? po.destination.code + ' - ' + po.destination.name : ' - ' }}
      </p>
      <p class="key-table-fixed"><b class="margin-right-6"> Ordered on: </b> {{ po.orderedOn ? po.orderedOn : ' - ' }}</p>
      <p class="key-table-fixed" style="margin-bottom: 0">
        <b class="margin-right-6"> Delivery window: </b> {{ po.earliestDate }} - {{ po.latestDate }}
      </p>
    </div>

    <div class="col-md-2 background-light-blue text-white min-height-box font-row">
      <h4 class="h-padding-box">
        <b>Submitted</b>
      </h4>
      <p><b class="margin-right-6">Quantity: </b> {{ (kpiData | async)?.submittedQuantity }}</p>
      <p><b class="margin-right-6">Value: </b> {{ (kpiData | async)?.submittedValue | myCurrency }}</p>
    </div>

    <div class="col-md-2 background-blue text-white min-height-box font-row">
      <h4 class="h-padding-box">
        <b>Accepted</b>
      </h4>
      <p><b class="margin-right-6">Quantity: </b> {{ (kpiData | async)?.acceptedQuantity }}</p>
      <p><b class="margin-right-6">Value: </b> {{ (kpiData | async)?.acceptedValue | myCurrency }}</p>
    </div>

    <div class="col-md-2 background-light-blue text-white min-height-box font-row">
      <h4 class="h-padding-box">
        <b>Canceled</b>
      </h4>
      <p><b class="margin-right-6">Quantity: </b> {{ (kpiData | async)?.cancelledQuantity }}</p>
      <p><b class="margin-right-6">Value: </b> {{ (kpiData | async)?.cancelledValue | myCurrency }}</p>
    </div>

    <div class="col-md-2 background-blue text-white min-height-box font-row">
      <h4 class="h-padding-box">
        <b>Received</b>
      </h4>
      <p><b class="margin-right-6">Quantity: </b> {{ (kpiData | async)?.receivedQuantity }}</p>
      <p><b class="margin-right-6">Value: </b> {{ (kpiData | async)?.receivedValue | myCurrency }}</p>
    </div>
  </div>
</div>

<mat-toolbar class="bar-custom">
  <div class="container">
    <div class="row">
      <div class="col-md-2">
        <button class="btn-custom margin-right-6" (click)="downloadEdi()">
          <i class="fa fa-download margin-right-6" aria-hidden="true"></i> Download EDI
        </button>
      </div>
      <div class="col-md-2">
        <button class="btn-custom margin-right-6" [disabled]="!bulkActionsActive" [matMenuTriggerFor]="exportPoItemsChoice">
          Export PO-Items
        </button>
        <mat-menu #exportPoItemsChoice="matMenu">
          <button mat-menu-item (click)="exportPoItems('excel')">Save as Excel</button>
          <button mat-menu-item (click)="exportPoItems('csv')">Save as CSV</button>
        </mat-menu>
      </div>

      <div class="col-md-2">
        <button
          class="btn-custom margin-right-6"
          (click)="openPoaHistory()"
          *ngIf="po.lastPoaQuantity !== undefined && po.lastPoaQuantity !== null"
        >
          <i class="fa fa-history margin-right-6" aria-hidden="true"></i> POA history
        </button>
      </div>

      <div class="col-md-4"></div>

      <div class="col-xs-2 text-right">
        <button class="btn-custom" (click)="goBack()">Back</button>
      </div>
    </div>
  </div>
</mat-toolbar>

<div class="container outer-table">
  <mat-paginator
    #paginator
    [length]="resultsNumber | async"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 20, 50, 100]"
    class="container"
    (page)="loadPage()"
  >
  </mat-paginator>

  <div class="table-container margin-bottom-60 mat-elevation-z8 box-shadow-none">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="check" sticky>
        <mat-header-cell *matHeaderCellDef class="checker-col">
          <mat-checkbox (change)="toggleAll($event.checked)" [ngModel]="mainChecker"></mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let po; let i = index" class="checker-col">
          <mat-checkbox (change)="onSelected($event.checked, po)" [ngModel]="po.checked"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="title" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="title" class="title-column"> Title </mat-header-cell>
        <mat-cell *matCellDef="let poItem" [matTooltip]="poItem.product.title" matTooltipPosition="below" class="title-column">
          <p>{{ poItem.product.title }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="brand">
        <mat-header-cell *matHeaderCellDef mat-sort-header="brand" class="category-column"> Brand </mat-header-cell>
        <mat-cell
          *matCellDef="let poItem"
          class="category-column"
          [matTooltip]="poItem.product.brand ? poItem.product.brand.name : ''"
          matTooltipPosition="below"
        >
          <p>{{ poItem.product.brand ? poItem.product.brand.name : '' }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="category">
        <mat-header-cell *matHeaderCellDef mat-sort-header="category" class="category-column"> Category </mat-header-cell>
        <mat-cell *matCellDef="let poItem" [matTooltip]="poItem.product.category?.name" matTooltipPosition="below" class="category-column">
          <p>{{ poItem.product.category?.name }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ean" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="ean" class="ean-column"> EAN </mat-header-cell>
        <mat-cell *matCellDef="let poItem" class="ean-column"
          ><a [routerLink]="['/inventory/products', poItem.product.id, 'details']">{{ poItem.product.ean }}</a></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="sku">
        <mat-header-cell *matHeaderCellDef mat-sort-header="sku" class="sku-column"> SKU </mat-header-cell>
        <mat-cell *matCellDef="let poItem" [matTooltip]="poItem.product.sku" matTooltipPosition="below" class="sku-column">
          <p>{{ poItem.product.sku }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="backorder">
        <mat-header-cell *matHeaderCellDef mat-sort-header="backorder" class="boolean-column"> Backorder </mat-header-cell>
        <mat-cell *matCellDef="let poItem" class="boolean-column">
          <p><i [ngClass]="poItem.backorder ? 'fa fa-check' : 'fa fa-times sel-icon'" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="expectedDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="expectedDate" class="date-column"> Expected Date </mat-header-cell>
        <mat-cell *matCellDef="let poItem" class="date-column">{{ poItem.expectedDate }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="submittedQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="submittedQuantity"> Quantity </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let poItem">
          <p>{{ poItem.submittedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="acceptedQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="acceptedQuantity">
          Accepted <br />
          Quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let poItem">
          <p>{{ poItem.acceptedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastPoaQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="lastPoaQuantity">
          Last POA <br />
          Quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let poItem">
          <p>{{ poItem.lastPoaQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="obsoleteWithAccepted">
        <mat-header-cell class="bigger-boolean-column" *matHeaderCellDef> Obsolete with accepted</mat-header-cell>
        <mat-cell class="bigger-boolean-column" *matCellDef="let poItem">
          <p><i [ngClass]="poItem.obsoleteWithAcceptedGreaterThanZero ? 'fa fa-check' : 'fa fa-times sel-icon'" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="unitCost">
        <mat-header-cell class="value-column-title-small" *matHeaderCellDef mat-sort-header="unitCost"> Unit Cost </mat-header-cell>
        <mat-cell class="value-column-small" *matCellDef="let poItem">
          <p>{{ poItem.unitCost | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="totalCost">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="totalCost"> Total Cost </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let poItem">
          <p>{{ poItem.totalCost | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lotName">
        <mat-header-cell *matHeaderCellDef mat-sort-header="lotName" class="lot-name-column"> Lot </mat-header-cell>
        <mat-cell *matCellDef="let poItem" class="lot-name-column">
          <a
            *ngIf="poItem.lot"
            [routerLink]="['/lot-edit', poItem.lot.id, 'po']"
            [matTooltip]="poItem.lot ? poItem.lot.name : ''"
            matTooltipPosition="below"
            >{{ poItem.lot.name }}</a
          >
          <p *ngIf="!poItem.lot">None</p>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
