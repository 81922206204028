import { JsonObject, JsonProperty } from 'json2typescript';
import { Supplier } from './supplier-model';
import { COUNTRIES, CountryTransitTime, TransitTime } from './transit-time-model';
import { StockLocationWithoutSupplier } from './stock-location-without-supplier-model';
import { StockLocationDto } from './stock-location-dto-model';
import { isNilty } from '../core/utils.service';

@JsonObject('StockLocation')
export class StockLocation extends StockLocationWithoutSupplier {
  @JsonProperty('supplier', Supplier, true) supplier: Supplier = undefined;
  @JsonProperty('transitTimes', [TransitTime], true) transitTimes: TransitTime[] = undefined;

  toDTO(): StockLocationDto {
    const supplierDTO = new StockLocationDto();
    Object.entries(this).forEach((entry) => {
      const key = entry[0];
      const value = entry[1];

      if (value !== undefined) {
        if (key === 'supplier' && !isNilty(value)) {
          supplierDTO[key] = (value as Supplier).id;
        } else {
          supplierDTO[key] = value;
        }
      }
    });
    return supplierDTO;
  }
}

@JsonObject('StockLocationTransitTime')
export class StockLocationTransitTime {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('stockLocation', StockLocation, true) stockLocation: StockLocation = undefined;
  @JsonProperty('palletThreshold', Number, true) palletThreshold: number = undefined;
  @JsonProperty('transitTimes', [CountryTransitTime], true) transitTimes: CountryTransitTime[] = undefined;
  @JsonProperty('enableWarehouseFulfilment', Boolean, true) enableWarehouseFulfilment = false;

  constructor(stockLocation: StockLocation) {
    this.stockLocation = stockLocation;
    this.palletThreshold = 0;
    this.transitTimes = COUNTRIES.map((it) => new CountryTransitTime(it));
  }
}
