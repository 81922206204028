import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LotsService } from '../../../../../shared/lots.service';
import { Lot } from '../../../../../models/lot-model';
import { Router } from '@angular/router';
import { ShipmentsDetailsKpi } from '@models/shipments-details-kpi';
import { ShipmentsService } from '@shared/shipments.service';

@Component({
  selector: 'app-shipments-details-kpi',
  templateUrl: './shipments-details-kpi.component.html',
  styleUrls: ['./shipments-details-kpi.component.css'],
})
export class ShipmentsDetailsKpiComponent implements OnInit {
  constructor(private lotsService: LotsService, private router: Router, private shipmentsService: ShipmentsService) {}

  currentLot: Observable<Lot>;
  kpiData: Observable<ShipmentsDetailsKpi>;

  ngOnInit() {
    this.currentLot = this.lotsService.lotSubject;
    this.kpiData = this.shipmentsService.shipDetailsKpiData;
  }

  goBack() {
    this.router.navigate(['/lots']);
  }
}
