import { AfterViewInit, Component, OnInit, Output } from '@angular/core';
import { VendorsService } from '../../../configuration/vendors/vendors.service';
import { LotEditService } from '../../lot-edit.service';
import { WarehousesService } from '../../../configuration/warehouses/warehouses.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Vendor, VendorsFromLotOutput } from '../../../../models/vendor-model';
import { Subject } from 'rxjs';
import { Warehouse } from '../../../../models/warehouse-model';
import { Supplier } from '../../../../models/supplier-model';
import { LotsService } from '../../../../shared/lots.service';
import { MatChipsData } from '../../../../models/mat-chips-data-model';
import { ldvFiltersMap, LogDiscardedValueFilters, setFiltersFromForm } from '../../../../models/filters/log-discarded-value-filters-model';
import { InventoryService } from '../../../../container/inventory/inventory.service';
import { Status } from '../../../../models/status-model';
import { LogDiscardedValueService } from '../../../../shared/log-discarded-value.service';
import { isNilty } from '../../../../core/utils.service';
import { StockLocation } from '../../../../models/stock-location-model';

@Component({
  selector: 'app-log-discarded-value-filters',
  templateUrl: './log-discarded-value-filters.component.html',
  styleUrls: ['./log-discarded-value-filters.component.css'],
})
export class LogDiscardedValueFiltersComponent implements OnInit, AfterViewInit {
  constructor(
    private vendorsService: VendorsService,
    private warehousesService: WarehousesService,
    private inventoryService: InventoryService,
    private lotEditService: LotEditService,
    private lotsService: LotsService,
    private logDiscardedValueService: LogDiscardedValueService
  ) {}

  warehouses: Warehouse[] = [];
  vendors: Vendor[] = [];
  parentVendors: Vendor[] = [];
  suppliers: Supplier[] = [];
  stockLocations: StockLocation[] = [];

  @Output() onFilter = new Subject<LogDiscardedValueFilters>();

  filterForm: FormGroup;

  public openFilter = false;

  filtersMap: MatChipsData[] = [];
  types: Status[];

  ngOnInit() {
    this.filterForm = new FormGroup({
      supplier: new FormControl(),
      stockLocation: new FormControl(),
      vendor: new FormControl(),
      destination: new FormControl(),
      type: new FormControl(),
      ean: new FormControl(),
      po: new FormControl(),
    });

    if (this.lotEditService.logDiscardedValueFilters !== undefined) {
      this.filterForm.patchValue(this.lotEditService.logDiscardedValueFilters);
      this.updateFiltersMap();
    }

    this.inventoryService.getAllSuppliers().subscribe((suppliersResp: Supplier[]) => {
      this.suppliers = suppliersResp;
      this.setSupplier();

      const brandonId = this.suppliers.find((s: Supplier) => s.code === 'BRND')?.id;
      if (!isNilty(brandonId)) {
        this.inventoryService.getStockLocationsBySupplierId(brandonId).subscribe((stockLocationResp: StockLocation[]) => {
          this.stockLocations = stockLocationResp;
          this.setStockLocation();
        });
      }
    });

    if (this.vendorsService.lotEditVendors === undefined) {
      this.vendorsService.getVendorsFromLot(this.lotsService.currentLotId).subscribe((vendorsResp: VendorsFromLotOutput) => {
        this.vendors = vendorsResp.vendors;
        this.parentVendors = vendorsResp.parents;
        this.setVendor();
      });
    } else {
      this.vendors = this.vendorsService.lotEditVendors;
      this.parentVendors = this.vendorsService.lotEditVendors.filter((it) => it.parent);
      this.setVendor();
    }

    if (this.warehousesService.lotEditWarehouses === undefined) {
      this.warehousesService.getWarehouses().subscribe((warehouses: Warehouse[]) => {
        this.warehouses = warehouses;
        this.setDestination();
      });
    } else {
      this.warehouses = this.warehousesService.lotEditWarehouses;
      this.setDestination();
    }

    this.logDiscardedValueService.getTypes().subscribe((r) => {
      this.types = r;
      this.setTypes();
    });

    this.updateFiltersMap();
  }

  setSupplier() {
    if (this.lotEditService.logDiscardedValueFilters && this.lotEditService.logDiscardedValueFilters.supplierId) {
      this.filterForm.controls['supplier'].patchValue(
        this.suppliers.find((it) => it.id === this.lotEditService.logDiscardedValueFilters.supplierId)
      );
      this.updateFiltersMap();
    }
  }

  setStockLocation() {
    if (this.lotEditService.logDiscardedValueFilters && this.lotEditService.logDiscardedValueFilters.stockLocationId) {
      this.filterForm.controls['stockLocation'].patchValue(
        this.stockLocations.find((it) => it.id === this.lotEditService.logDiscardedValueFilters.stockLocationId)
      );
      this.updateFiltersMap();
    }
  }

  setVendor() {
    if (this.lotEditService.logDiscardedValueFilters && this.lotEditService.logDiscardedValueFilters.vendorId) {
      this.filterForm.controls['vendor'].patchValue(
        this.parentVendors.find((it) => it.id === this.lotEditService.logDiscardedValueFilters.vendorId)
      );
      this.updateFiltersMap();
    }
  }

  setDestination() {
    if (this.lotEditService.logDiscardedValueFilters && this.lotEditService.logDiscardedValueFilters.destinationId) {
      this.filterForm.controls['destination'].patchValue(
        this.warehouses.find((it) => it.id === this.lotEditService.logDiscardedValueFilters.destinationId)
      );
      this.updateFiltersMap();
    }
  }

  setTypes() {
    if (this.lotEditService.logDiscardedValueFilters && this.lotEditService.logDiscardedValueFilters.type) {
      this.filterForm.controls['type'].patchValue(this.types.find((it) => it.code === this.lotEditService.logDiscardedValueFilters.type));
      this.updateFiltersMap();
    }
  }

  ngAfterViewInit() {
    this.updateFiltersMap();
  }

  saveFiltersToService() {
    setFiltersFromForm(this.filterForm, this.lotEditService.logDiscardedValueFilters);
  }

  onSubmitFilter() {
    this.saveFiltersToService();
    this.onFilter.next(this.lotEditService.logDiscardedValueFilters);
    this.updateFiltersMap();
  }

  resetFilters() {
    this.filterForm.reset();
    this.saveFiltersToService();
    this.onFilter.next(this.lotEditService.logDiscardedValueFilters);
    this.updateFiltersMap();
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeevent() {
    this.openFilter = false;
  }

  updateFiltersMap() {
    this.filtersMap = ldvFiltersMap(
      this.filterForm.value.supplier,
      this.filterForm.value.stockLocation,
      this.filterForm.value.vendor,
      this.filterForm.value.destination,
      this.filterForm.value.type,
      this.filterForm.value.po,
      this.filterForm.value.ean
    );
  }
}
