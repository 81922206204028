import { Component, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { InventoryService } from '../../../inventory.service';
import { StockLocationFilters, stockLocationFiltersMap } from '../../../../../models/filters/stock-location-filters-model';
import { Supplier } from '../../../../../models/supplier-model';
import { MatChipsData } from '../../../../../models/mat-chips-data-model';
import { isNilty } from '../../../../../core/utils.service';

@Component({
  selector: 'app-stock-locations-list-filters',
  templateUrl: './stock-locations-list-filters.component.html',
  styleUrls: ['./stock-locations-list-filters.component.css'],
})
export class StockLocationsListFiltersComponent implements OnInit {
  constructor(private inventoryService: InventoryService) {}

  filters: StockLocationFilters;
  suppliers: Supplier[] = [];

  public openFilter = false;
  filtersMap: MatChipsData[] = [];

  @Output() onFilterEvent = new Subject<StockLocationFilters>();

  ngOnInit() {
    this.filters = isNilty(this.inventoryService.stockLocationFilters)
      ? new StockLocationFilters()
      : this.inventoryService.stockLocationFilters;

    this.createFiltersMap();

    this.inventoryService.getAllSuppliers().subscribe((suppliers: Supplier[]) => {
      this.suppliers = suppliers;
    });
  }

  onFilter() {
    this.saveFiltersToService();
    this.createFiltersMap();
    this.onFilterEvent.next(this.filters);
  }

  private saveFiltersToService() {
    this.inventoryService.stockLocationFilters.name = this.filters.name;
    this.inventoryService.stockLocationFilters.supplier = this.filters.supplier;
  }

  resetFilters() {
    this.filtersMap = [];
    this.filters = new StockLocationFilters();
    this.saveFiltersToService();
    this.onFilterEvent.next(this.filters);
  }

  private createFiltersMap() {
    const supplier = this.suppliers.find((it) => it.id === this.filters.supplier);
    this.filtersMap = stockLocationFiltersMap(this.filters.name, !isNilty(supplier) ? supplier.code : undefined);
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeEvent() {
    this.openFilter = false;
  }
}
