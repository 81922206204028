<div class="header text-blue">
  <div class="row">
    <div class="col-md-12">
      <b>POA: </b> <a *ngIf="poaFileName" (click)="downloadEdi()"> {{ poaFileName }} </a>
      <i *ngIf="!poaFileName">Ack not received for this POA.</i>
    </div>
  </div>

  <div class="header-details">
    <div class="row">
      <div class="col-md-6"><b>POA sent on: </b> {{ selectedPoa.sentDate }}</div>
      <div class="col-md-6"><b>Accepted: </b> {{ selectedPoa.totalAcceptedQuantity }}</div>
    </div>
    <div class="row padding-top-5">
      <div class="col-md-6"><b>Ack received on: </b> {{ selectedPoa.confirmedDate }}</div>
      <div class="col-md-6"><b>Source: </b> {{ selectedPoa.source }}</div>
    </div>
  </div>
</div>

<hr />

<div class="row">
  <div class="col-md-7" (keyup.enter)="search()">
    <mat-form-field>
      <input matInput [(ngModel)]="filters.eans" placeholder="Search EAN" />
    </mat-form-field>
    <button (click)="search()" matTooltip="Search EAN" class="btn-custom smaller-icon-button margin-left-6">
      <i class="fa fa-search" aria-hidden="true"></i>
    </button>
    <button (click)="reset()" matTooltip="Search EAN" class="btn-custom smaller-icon-button margin-left-6">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
  </div>
  <div class="col-md-5 text-right">
    <mat-paginator #paginator [hidePageSize]="true" [length]="resultsNumber | async" [pageSize]="10" (page)="loadPage()"></mat-paginator>
  </div>
</div>

<div class="row width-100per overflow-hidden">
  <div class="table-container">
    <mat-table [dataSource]="poaItemsDataSource">
      <ng-container matColumnDef="showItemHistory" sticky>
        <mat-header-cell *matHeaderCellDef class="item-history-btn-column"> </mat-header-cell>
        <mat-cell *matCellDef="let poai" class="item-history-btn-column">
          <button (click)="showItemHistory(poai.poItem)" matTooltip="Show History for this item" class="btn-custom smaller-icon-button">
            <i class="fa fa-history" aria-hidden="true"></i>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ean" sticky>
        <mat-header-cell *matHeaderCellDef class="ean-column"> EAN </mat-header-cell>
        <mat-cell *matCellDef="let poai" class="ean-column">
          <p>{{ poai.poItem.product.ean }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="acceptedQuantity">
        <mat-header-cell *matHeaderCellDef class="quantity-column-title"> Accepted </mat-header-cell>
        <mat-cell *matCellDef="let poai" class="quantity-column">
          <p>{{ poai.log.acceptedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="backOrderQuantity">
        <mat-header-cell *matHeaderCellDef class="quantity-column-title"> Backordered </mat-header-cell>
        <mat-cell *matCellDef="let poai" class="quantity-column">
          <p>{{ poai.log.backOrderQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="softRejectedQuantity">
        <mat-header-cell *matHeaderCellDef class="quantity-column-title">
          Soft <br />
          rejected
        </mat-header-cell>
        <mat-cell *matCellDef="let poai" class="quantity-column">
          <p>{{ poai.log.softRejectedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="hardRejectedQuantity">
        <mat-header-cell *matHeaderCellDef class="quantity-column-title">
          Hard <br />
          rejected
        </mat-header-cell>
        <mat-cell *matCellDef="let poai" class="quantity-column">
          <p>{{ poai.log.hardRejectedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: columns"></mat-row>
    </mat-table>
  </div>
</div>
