import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { isNil, isNilty } from '../../../core/utils.service';
import { FulfilmentKpi } from '../../../models/fulfilment-kpi';
import { Shipment } from '../../../models/shipment-model';
import { ShipmentsService } from '../../shipments.service';
import { OriginModalComponent } from '../origin-modal/origin-modal.component';
import { TransitWarehouseModalComponent } from '../transit-warehouse-modal/transit-warehouse-modal.component';
import { SupplierClaimDetailModalComponent } from './supplier-claim-details-modal/supplier-claim-detail-modal.component';

@Component({
  selector: 'app-single-shipment-detail-kpi',
  templateUrl: './single-shipment-detail-kpi.component.html',
  styleUrls: ['./single-shipment-detail-kpi.component.css'],
})
export class SingleShipmentDetailKpiComponent {
  @Input() shipment: Shipment;
  @Input() kpiData: Observable<FulfilmentKpi>;

  @Output() reload = new EventEmitter<void>();

  constructor(private dialog: MatDialog, private snackBar: MatSnackBar, private shipmentsService: ShipmentsService) {}

  setTransitWarehouse() {
    const dialogRef = this.dialog.open(TransitWarehouseModalComponent, {
      width: '350px',
      data: {
        transitWarehouse: this.shipment.transitWarehouse,
      },
    });

    dialogRef.afterClosed().subscribe((result: number) => {
      if (result !== undefined) {
        this.shipmentsService.setTransitWarehouse(this.shipment.id, result).subscribe(() => {
          this.snackBar.open('Request sent.', 'CLOSE')._dismissAfter(3000);
          this.reload.emit();
        });
      }
    });
  }

  setOrigin() {
    const dialogRef = this.dialog.open(OriginModalComponent, {
      width: '350px',
      data: {
        origin: this.shipment.origin,
      },
    });

    dialogRef.afterClosed().subscribe((result: number) => {
      if (result !== undefined) {
        this.shipmentsService.setOrigin(this.shipment.id, result).subscribe(() => {
          this.snackBar.open('Request sent.', 'CLOSE')._dismissAfter(3000);
          this.reload.emit();
        });
      }
    });
  }

  showSupplierClaimQuantity(): number {
    if (isNilty(this.shipment) || isNilty(this.shipment.supplierClaims)) {
      return 0;
    }

    return this.shipment.supplierClaims.reduce((value, sc) => value + sc.quantity, 0);
  }

  showSupplierClaimValue(): number {
    if (isNilty(this.shipment) || isNilty(this.shipment.supplierClaims)) {
      return 0;
    }

    return this.shipment.supplierClaims.reduce((value, sc) => value + sc.value, 0);
  }

  showSupplierClaimDetails() {
    if (!isNilty(this.shipment) && !isNil(this.shipment.supplierClaims)) {
      this.dialog.open(SupplierClaimDetailModalComponent, {
        disableClose: true,
        data: { supplierClaims: this.shipment.supplierClaims },
      });
    }
  }
}
