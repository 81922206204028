import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subject } from 'rxjs';
import { AuthorizationService } from '../../../../../core/authorization.service';
import { Permissions } from '../../../../../models/permission-model';
import { Status } from '../../../../../models/status-model';
import { ShipmentsService } from '../../../../../shared/shipments.service';
import { ShipmentStatus } from '../../../../../models/shipment-model';
import { CommonsService } from '../../../../../shared/commons.service';
import { ENTITIES } from '../../../../../entities';
import { ImportExportService } from '../../../../../shared/import-export.service';
import { LotsService } from '../../../../../shared/lots.service';

@Component({
  selector: 'app-shipments-list-bulk-actions',
  templateUrl: './shipments-list-bulk-actions.component.html',
  styleUrls: ['./shipments-list-bulk-actions.component.css'],
})
export class ShipmentsListBulkActionsComponent implements OnInit {
  @Input() bulkActionsActive: boolean;

  @Output() onChangeShipmentsPaymentStatus = new Subject<string>();
  @Output() onChangeShipmentsIncomingDate = new Subject<void>();
  @Output() onClearUnorderedShipments = new Subject<void>();

  @Output() onShipmentsExport = new Subject<{ format: string; onlyHeaders: boolean }>();

  @ViewChild('alignQuantitiesBtn', { static: true }) alignQuantitiesBtn: ElementRef;

  shipmentStatus: ShipmentStatus[];
  paymentStatus: Status[];

  hasShipmentManagement: Observable<boolean>;
  hasCancelShipment: Observable<boolean>;
  hasChangeShipmentPaymentStatus: Observable<boolean>;

  private currentFileUpload: File;

  constructor(
    public snackBar: MatSnackBar,
    private authorizationService: AuthorizationService,
    private shipmentsService: ShipmentsService,
    private commonsService: CommonsService,
    private importExportService: ImportExportService,
    private lotsService: LotsService
  ) {}

  ngOnInit() {
    this.commonsService.getShipmentStatuses().subscribe((r) => (this.shipmentStatus = r));

    this.hasShipmentManagement = this.authorizationService.hasPermission(Permissions.ShipmentManagement);
    this.hasCancelShipment = this.authorizationService.hasPermission(Permissions.CancelShipments);
    this.hasChangeShipmentPaymentStatus = this.authorizationService.hasPermission(Permissions.ChangeShipmentPaymentStatus);

    this.shipmentsService.getShipmentPaymentStatus().subscribe((result) => (this.paymentStatus = result));
  }

  changeShipmentsPaymentStatus(status: string) {
    this.onChangeShipmentsPaymentStatus.next(status);
  }

  changeShipmentsIncomingDate() {
    this.onChangeShipmentsIncomingDate.next();
  }

  exportExcel(format: string, onlyHeaders: boolean) {
    this.onShipmentsExport.next({ format, onlyHeaders });
  }

  clearUnorderedShipments() {
    this.onClearUnorderedShipments.next();
  }

  alignShipmentQuantities() {
    this.alignQuantitiesBtn.nativeElement.click();
  }

  uploadAlignQuantitiesFile(event) {
    this.currentFileUpload = event.target.files[0];

    this.snackBar.open('Uploading', '')._dismissAfter(3000);

    this.importExportService
      .uploadFile(this.currentFileUpload, ENTITIES.ALIGN_SHIPMENT_QUANTITIES, this.lotsService.currentLotId)
      .subscribe(() => {
        this.currentFileUpload = undefined;
      });
  }
}
