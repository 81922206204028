<div class="container main-content">
  <div class="row">
    <div class="col-md-4"></div>

    <div class="col-xs-4">
      <div class="row">
        <legend>Stock Location</legend>
      </div>

      <form [formGroup]="stockLocForm" (ngSubmit)="onSubmit(false)">
        <div class="col-md-8 col-md-offset-2">
          <mat-form-field>
            <input matInput type="text" id="name" formControlName="name" placeholder="Name" />
          </mat-form-field>
        </div>

        <div class="col-md-2"></div>

        <div class="col-md-8 col-md-offset-2">
          <mat-form-field>
            <input matInput type="text" id="code" maxlength="4" formControlName="code" placeholder="Code" />
          </mat-form-field>
        </div>

        <div class="col-md-2"></div>

        <div class="col-md-8 col-md-offset-2">
          <mat-form-field>
            <input matInput type="text" id="address" formControlName="address" placeholder="Address" />
          </mat-form-field>
        </div>

        <div class="col-md-2"></div>

        <div class="col-md-8 col-md-offset-2">
          <mat-form-field>
            <input matInput type="text" id="city" formControlName="city" placeholder="City" />
          </mat-form-field>
        </div>

        <div class="col-md-2"></div>

        <div class="col-md-8 col-md-offset-2">
          <mat-form-field>
            <input matInput type="text" id="province" formControlName="province" placeholder="Province" />
          </mat-form-field>
        </div>

        <div class="col-md-2"></div>

        <div class="col-md-8 col-md-offset-2">
          <mat-form-field>
            <input matInput type="text" id="postalCode" formControlName="postalCode" placeholder="Postal Code" />
          </mat-form-field>
        </div>

        <div class="col-md-2"></div>

        <div class="col-md-8 col-md-offset-2">
          <mat-form-field>
            <input matInput type="text" id="countryCode" formControlName="countryCode" placeholder="Country Code" />
          </mat-form-field>
        </div>

        <div class="col-md-2"></div>

        <div class="col-md-8 col-md-offset-2">
          <mat-form-field>
            <input matInput type="text" id="upsShipperNumber" formControlName="upsShipperNumber" placeholder="Ups Shipper Number" />
          </mat-form-field>
        </div>

        <div class="col-md-2"></div>

        <div class="col-md-8 col-md-offset-2">
          <mat-form-field>
            <app-mat-select filterKey="description" formControlName="supplier" placeholder="Supplier">
              <mat-option
                *appMatSelectFor="let supplier of suppliers"
                [value]="supplier.id"
                style="font-family: 'Titillium Web', sans-serif !important; font-size: 12px"
              >
                {{ supplier.description }}
              </mat-option>
            </app-mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-2"></div>

        <div class="col-md-8 col-md-offset-2 padding-top-20 padding-bottom-20 edit-border-bottom">
          <mat-slide-toggle class="text-blue" id="setByDefault" formControlName="setByDefault">Default </mat-slide-toggle>
        </div>

        <div class="col-md-8 col-md-offset-2 padding-top-20 padding-bottom-20 edit-border-bottom">
          <mat-slide-toggle class="text-blue" id="transitWarehouse" formControlName="transitWarehouse">Transit warehouse?</mat-slide-toggle>
        </div>

        <div class="col-md-8 col-md-offset-2 padding-top-20 padding-bottom-20 edit-border-bottom">
          <mat-slide-toggle class="text-blue" id="enableWarehouseFulfilment" formControlName="enableWarehouseFulfilment"
            >Enable warehouse fulfilment?</mat-slide-toggle
          >
        </div>

        <div class="col-md-2"></div>

        <div class="col-md-12 padding-top-20 text-center">
          <button type="submit" [disabled]="stockLocForm.invalid" class="btn-product margin-right-6">Save</button>
          <button type="button" (click)="onSubmit(true)" [disabled]="stockLocForm.invalid" class="btn-product margin-right-6">
            Save and exit
          </button>
          <button *ngIf="editMode || hasBeenSaved" type="button" (click)="onDelete()" class="btn-product margin-right-6">Delete!</button>
          <button type="button" (click)="onCancel()" class="btn-product">Back</button>
        </div>
      </form>
    </div>
  </div>
</div>
