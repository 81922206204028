import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { AuthorizationService } from '../../core/authorization.service';
import { LotsService } from '../../shared/lots.service';
import { LotEditService } from './lot-edit.service';
import { ShipmentsService } from '../../shared/shipments.service';
import { VendorsService } from '../configuration/vendors/vendors.service';
import { WarehousesService } from '../configuration/warehouses/warehouses.service';

@Component({
  selector: 'app-lot-edit',
  templateUrl: './lot-edit.component.html',
  styleUrls: ['./lot-edit.component.css'],
})
export class LotEditComponent implements OnInit, OnDestroy {
  id: string;

  constructor(
    private lotsService: LotsService,
    private route: ActivatedRoute,
    private authorizationService: AuthorizationService,
    private lotEditService: LotEditService,
    private shipmentsService: ShipmentsService,
    private vendorsService: VendorsService,
    private warehousesService: WarehousesService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((param: Params) => {
      this.id = param['id'];
      this.lotsService.currentLotId = +this.id;
    });
    this.authorizationService.isLotEditMode.next(true);
  }

  ngOnDestroy() {
    this.authorizationService.isLotEditMode.next(false);
    this.lotsService.currentLotId = undefined;
    this.lotEditService.resetAllFilters();
    this.vendorsService.lotEditVendors = undefined;
    this.warehousesService.lotEditWarehouses = undefined;
  }
}
