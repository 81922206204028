<mat-toolbar>
  <div class="row new-category-row">
    <div class="col-md-10">
      <mat-form-field>
        <input [disabled]="isEditMode" matInput [(ngModel)]="newCategory" placeholder="New Category" />
      </mat-form-field>
    </div>
    <div class="col-md-2">
      <button [disabled]="isEditMode || (newCategory | isNullOrUndefinedOrEmptyString)" class="btn-custom" (click)="createNewCategory()">
        New Category
      </button>
    </div>
    <div class="col-md-2"></div>
  </div>
</mat-toolbar>

<div class="table-container container box-shadow-none margin-user mat-elevation-z8" id="table-categories">
  <div class="table-header">
    <mat-form-field>
      <input [disabled]="isEditMode" matInput placeholder="Search" [(ngModel)]="searchString" (keydown.enter)="search()" />
    </mat-form-field>
  </div>

  <mat-paginator #paginator [length]="resultsNumber | async" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]"> </mat-paginator>

  <mat-table #listingTable [dataSource]="dataSource">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
      <mat-cell *matCellDef="let c">
        <div *ngIf="!isEditMode || editingId !== c.id">{{ c.name }}</div>
        <mat-form-field *ngIf="isEditMode && editingId === c.id">
          <input matInput [(ngModel)]="editingName" type="text" id="name" />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let c">
        <button
          *ngIf="!isEditMode || editingId !== c.id"
          [disabled]="isEditMode && editingId !== c.id"
          class="btn-category"
          (click)="edit(c)"
        >
          <i class="fa fa-edit padding-bottom-1" aria-hidden="true"></i> Edit
        </button>
        <button *ngIf="isEditMode && editingId === c.id" class="btn-category" (click)="saveEdit(c)">
          <i class="fa fa-save padding-bottom-1" aria-hidden="true"></i> Save
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="showProducts">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let c">
        <button class="btn-category" [disabled]="true || isEditMode">
          <i class="fa fa-list-ul padding-bottom-1" aria-hidden="true"></i> Show Products
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="delete">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let c">
        <button class="btn-category" [disabled]="isEditMode" (click)="deleteCategory(c)">
          <i class="fa fa-trash padding-bottom-1" aria-hidden="true"></i> Delete
        </button>
      </mat-cell>
    </ng-container>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>
