import { Component, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { Contract } from '../../../../models/contract.model';
import { createFormGroup, shipFiltersMap, ShipmentFilters } from '../../../../models/filters/shipment-filters-model';
import { MatChipsData } from '../../../../models/mat-chips-data-model';
import { Status } from '../../../../models/status-model';
import { StockLocation } from '../../../../models/stock-location-model';
import { Supplier } from '../../../../models/supplier-model';
import { Vendor, VendorsFromLotOutput } from '../../../../models/vendor-model';
import { Warehouse } from '../../../../models/warehouse-model';
import { LotsService } from '../../../../shared/lots.service';
import { ShipmentsService } from '../../../../shared/shipments.service';
import { VendorsService } from '../../../configuration/vendors/vendors.service';
import { WarehousesService } from '../../../configuration/warehouses/warehouses.service';
import { LotEditService } from '../../lot-edit.service';
import { ShipmentStatus } from '../../../../models/shipment-model';
import { CommonsService } from '../../../../shared/commons.service';
import { Category } from '../../../../models/category-model';
import { isNilty } from '@core/utils.service';

@Component({
  selector: 'app-shipments-list-filters',
  templateUrl: './shipments-list-filters.component.html',
  styleUrls: ['./shipments-list-filters.component.css'],
})
export class ShipmentsListFiltersComponent implements OnInit {
  constructor(
    private vendorsService: VendorsService,
    private warehousesService: WarehousesService,
    private shipmentsService: ShipmentsService,
    private lotsService: LotsService,
    private lotEditService: LotEditService,
    private commonsService: CommonsService
  ) {}

  suppliers: Supplier[] = [];

  stockLocations: StockLocation[] = [];

  warehouses: Warehouse[] = [];

  vendors: Vendor[] = [];

  categories: Category[] = [];

  @Output() onFilter = new Subject<ShipmentFilters>();

  filterForm: FormGroup;

  shipmentsStatus: ShipmentStatus[];

  paymentStatus: Status[];
  ukIntercoInvoiceStatus: Status[];

  carrierContracts: Contract[];

  outboundStatus: string;

  public openFilter = false;
  filtersMap: MatChipsData[] = [];

  ngOnInit() {
    this.commonsService.getShipmentStatuses().subscribe((r) => (this.shipmentsStatus = r));

    this.filterForm = createFormGroup();

    if (this.lotEditService.shipmentFilters !== undefined) {
      this.filterForm.patchValue(this.lotEditService.shipmentFilters);
      this.setPickupDates();
      this.updateFiltersMap();
    }

    this.shipmentsService.getStockLocationsFromShipments(this.lotsService.currentLotId).subscribe((stockLocations: StockLocation[]) => {
      this.stockLocations = stockLocations;
      this.setStockLocation();
    });

    this.shipmentsService.getSuppliersFromShipments(this.lotsService.currentLotId).subscribe((suppliers: Supplier[]) => {
      this.suppliers = suppliers;
      this.setSupplier();
    });

    this.shipmentsService.getShipmentPaymentStatus().subscribe((s: Status[]) => {
      this.paymentStatus = s;
      this.setPaymentStatus();
    });

    this.shipmentsService.getIntercoInvoiceStatus().subscribe((s: Status[]) => {
      this.ukIntercoInvoiceStatus = s;
      this.setIntercoInvoiceStatus();
    });

    this.shipmentsService.getAllContracts().subscribe((c: Contract[]) => {
      this.carrierContracts = c;
      this.setCarrierContract();
    });

    if (this.vendorsService.lotEditVendors === undefined) {
      this.vendorsService.getVendorsFromLot(this.lotsService.currentLotId).subscribe((vendorsResp: VendorsFromLotOutput) => {
        this.vendors = vendorsResp.vendors;
        this.setVendor();
      });
    } else {
      this.vendors = this.vendorsService.lotEditVendors;
      this.setVendor();
    }

    this.shipmentsService.getAllCategories().subscribe((categories: Supplier[]) => {
      this.categories = categories;
      this.setCategory();
    });

    if (this.warehousesService.lotEditWarehouses === undefined) {
      this.warehousesService.getWarehousesFromLot(this.lotsService.currentLotId).subscribe((warehouses: Warehouse[]) => {
        this.warehouses = warehouses;
        this.setWarehouses();
      });
    } else {
      this.warehouses = this.warehousesService.lotEditWarehouses;
      this.setWarehouses();
    }

    this.updateFiltersMap();
  }

  setPickupDates() {
    if (
      !isNilty(this.lotEditService.shipmentFilters) &&
      !isNilty(this.lotEditService.shipmentFilters.latestPickupDateFrom) &&
      !isNilty(this.lotEditService.shipmentFilters.latestPickupDateTo)
    ) {
      this.filterForm.controls['latestPickupDateFrom'].patchValue(this.lotEditService.shipmentFilters.latestPickupDateFrom);
      this.filterForm.controls['latestPickupDateTo'].patchValue(this.lotEditService.shipmentFilters.latestPickupDateTo);
      this.updateFiltersMap();
    }
  }

  setVendor() {
    if (this.lotEditService.shipmentFilters && this.lotEditService.shipmentFilters.vendorId) {
      this.filterForm.controls['vendor'].patchValue(this.vendors.find((it) => it.id === this.lotEditService.shipmentFilters.vendorId));
      this.updateFiltersMap();
    }
  }

  setCategory() {
    if (this.lotEditService.shipmentFilters && this.lotEditService.shipmentFilters.categoryId) {
      this.filterForm.controls['category'].patchValue(
        this.categories.find((it) => it.id === this.lotEditService.shipmentFilters.categoryId)
      );
      this.updateFiltersMap();
    }
  }

  setWarehouses() {
    if (this.lotEditService.shipmentFilters && this.lotEditService.shipmentFilters.destinationId) {
      this.filterForm.controls['destination'].patchValue(
        this.warehouses.find((it) => it.id === this.lotEditService.shipmentFilters.destinationId)
      );
      this.updateFiltersMap();
    }
  }

  setSupplier() {
    if (this.lotEditService.shipmentFilters && this.lotEditService.shipmentFilters.supplierId) {
      this.filterForm.controls['supplier'].patchValue(
        this.suppliers.find((it) => it.id === this.lotEditService.shipmentFilters.supplierId)
      );
      this.updateFiltersMap();
    }
  }

  setCarrierContract() {
    if (this.lotEditService.shipmentFilters && this.lotEditService.shipmentFilters.supplierId) {
      this.filterForm.controls['carrierContracts'].patchValue(
        this.carrierContracts.filter((it) => this.lotEditService.shipmentFilters.carrierContracts.some((c) => c === it.id))
      );
      this.updateFiltersMap();
    }
  }

  setPaymentStatus() {
    if (this.lotEditService.shipmentFilters && !isNilty(this.lotEditService.shipmentFilters.paymentStatus)) {
      this.filterForm.controls['paymentStatus'].patchValue(
        this.paymentStatus.find((it) => it.code === this.shipmentsService.shipmentFilters.paymentStatus)
      );
      this.updateFiltersMap();
    }
  }

  setIntercoInvoiceStatus() {
    if (this.lotEditService.shipmentFilters && !isNilty(this.lotEditService.shipmentFilters.ukIntercoInvoiceStatus)) {
      this.filterForm.controls['ukIntercoInvoiceStatus'].patchValue(
        this.ukIntercoInvoiceStatus.find((it) => it.code === this.shipmentsService.shipmentFilters.ukIntercoInvoiceStatus)
      );
      this.updateFiltersMap();
    }
  }

  setStockLocation() {
    if (this.lotEditService.shipmentFilters && this.lotEditService.shipmentFilters.originId) {
      this.filterForm.controls['origin'].patchValue(
        this.stockLocations.find((it) => it.id === this.lotEditService.shipmentFilters.originId)
      );
      this.updateFiltersMap();
    }
  }

  saveFiltersToService() {
    this.lotEditService.saveShipmentFiltersToService(this.filterForm);
  }

  onSubmitFilter() {
    this.saveFiltersToService();
    this.updateFiltersMap();
    this.onFilter.next(this.lotEditService.shipmentFilters);
  }

  resetFilters() {
    this.filterForm.reset();
    this.updateFiltersMap();
    this.saveFiltersToService();
    this.onFilter.next(this.lotEditService.shipmentFilters);
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeevent() {
    this.openFilter = false;
  }

  updateFiltersMap() {
    this.filtersMap = shipFiltersMap(
      this.filterForm.value.shipment,
      this.filterForm.value.asnCode,
      this.filterForm.value.ean,
      this.filterForm.value.origin,
      this.filterForm.value.supplier,
      this.filterForm.value.po?.split(','),
      this.filterForm.value.vendor,
      this.filterForm.value.destinationId,
      this.filterForm.value.status,
      this.filterForm.value.paymentStatus,
      null,
      null,
      null,
      this.filterForm.value.payment,
      null,
      this.filterForm.value.orderIds?.split(','),
      this.filterForm.value.carrierContracts,
      this.filterForm.value.trackingNumber,
      this.filterForm.value.latestPickupDateFrom,
      this.filterForm.value.latestPickupDateTo,
      this.filterForm.value.noInvoice,
      this.filterForm.value.crossUkBorder,
      this.filterForm.value.ukIntercoInvoice,
      this.filterForm.value.ukIntercoInvoiceValidData,
      this.filterForm.value.ukIntercoInvoiceStatus,
      this.filterForm.value.isAdr,
      this.filterForm.value.documentsSentToSupplier,
      this.filterForm.value.category,
      this.filterForm.value.outboundStatus
    );
  }
}
