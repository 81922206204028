import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';

@JsonObject('StockLocationWithoutSupplier')
export class StockLocationWithoutSupplier extends BaseObject {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('name', String, false) name: string = undefined;
  @JsonProperty('address', String, true) address: string = undefined;
  @JsonProperty('city', String, true) city: string = undefined;
  @JsonProperty('province', String, true) province: string = undefined;
  @JsonProperty('postalCode', String, true) postalCode: string = undefined;
  @JsonProperty('countryCode', String, true) countryCode: string = undefined;
  @JsonProperty('code', String, false) code: string = undefined;
  @JsonProperty('setByDefault', Boolean, true) setByDefault: boolean = undefined;
  @JsonProperty('transitWarehouse', Boolean, true) transitWarehouse: boolean = undefined;
  @JsonProperty('upsShipperNumber', String, true) upsShipperNumber: string = undefined;
  @JsonProperty('enableWarehouseFulfilment', Boolean, true) enableWarehouseFulfilment = false;

  get description(): string {
    return `${this.code} - ${this.name}`;
  }
}
