import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-indeterminate-job-status',
  template: `
    <div class="col-md-12">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="col-md-9 status">{{ label }} ({{ currentStepIndex }}/{{ totalSteps }})</div>
  `,
  styles: [
    `
      .progress-content {
        min-width: 160px;
        overflow: hidden;
        padding: 10px;
      }
      .status {
        font-weight: bold;
        color: #14437e;
        padding-bottom: 5px;
      }
      .title {
        font-weight: bold;
        color: #14437e;
      }
      .trouble {
        color: red;
        font-weight: bold;
      }
    `,
  ],
})
export class IndeterminateJobStatusComponent {
  @Input() label: string;
  @Input() currentStepIndex: number;
  @Input() totalSteps: number;
}
