import { Component, OnInit } from '@angular/core';
import { InventoryService } from '../../inventory/inventory.service';
import { Supplier } from '../../../models/supplier-model';
import { TransitTimeService } from './transit-time.service';
import { StockLocation, StockLocationTransitTime } from '../../../models/stock-location-model';
import { isNilty } from '../../../core/utils.service';
import { SupplierTransitTime, TransitTime } from '../../../models/transit-time-model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-transit-time',
  templateUrl: './transit-time.component.html',
  styleUrls: ['./transit-time.component.scss'],
})
export class TransitTimeComponent implements OnInit {
  constructor(private inventoryService: InventoryService, private transitTimeService: TransitTimeService, private snackBar: MatSnackBar) {}

  suppliers: Supplier[];
  selectedSupplierId: number;

  allStockLocations: StockLocation[];

  stockLocations: StockLocation[];
  selectedStockLocationId: number;

  supplierTransitTime: SupplierTransitTime;
  stockLocationTransitTime: StockLocationTransitTime;
  stockLocationsTransitTimes: TransitTime[];

  ngOnInit() {
    this.inventoryService.getAllSuppliers().subscribe((resp: Supplier[]) => {
      this.suppliers = resp;
    });
    this.inventoryService.getAllStockLocations().subscribe((resp: StockLocation[]) => {
      this.allStockLocations = resp;
      this.stockLocations = [...this.allStockLocations];
    });
  }

  onSearch() {
    this.getSupplierTransitTimes();
    this.getStockLocationDefaultTransitTimes();
    this.getStockLocationsTransitTimes();
  }

  private getSupplierTransitTimes() {
    this.transitTimeService.getSupplierTransitTimes(this.selectedSupplierId).subscribe((resp: SupplierTransitTime) => {
      this.supplierTransitTime = resp;
    });
  }

  private getStockLocationDefaultTransitTimes() {
    this.transitTimeService
      .getStockLocationDefaultTransitTimes(this.selectedSupplierId, this.selectedStockLocationId)
      .subscribe((resp: StockLocationTransitTime) => {
        this.stockLocationTransitTime = resp;
      });
  }

  private getStockLocationsTransitTimes() {
    this.transitTimeService
      .getStockLocationTransitTimes(this.selectedSupplierId, this.selectedStockLocationId)
      .subscribe((resp: TransitTime[]) => {
        this.stockLocationsTransitTimes = resp;
      });
  }

  onSelectedSupplier(supplierId: number) {
    this.selectedStockLocationId = null;
    this.supplierTransitTime = null;
    this.stockLocationTransitTime = null;
    this.stockLocationsTransitTimes = null;
    if (isNilty(supplierId)) {
      this.stockLocations = [...this.allStockLocations];
    } else {
      this.getSupplierTransitTimes();
      this.stockLocations = this.allStockLocations.filter((it) => it.supplier.id === supplierId);
      if (this.stockLocations.length === 1) {
        this.selectedStockLocationId = this.stockLocations[0].id;
        this.onSelectedStockLocation(this.selectedStockLocationId);
        this.onSearch();
      }
    }
  }

  onSelectedStockLocation(stockLocationId: number) {
    this.selectedSupplierId = this.stockLocations.find((it) => it.id === stockLocationId).supplier.id;
  }

  createSupplierTransitTimes() {
    this.supplierTransitTime = new SupplierTransitTime(this.suppliers.find((it) => it.id === this.selectedSupplierId));
  }

  createStockLocationTransitTimes() {
    this.stockLocationTransitTime = new StockLocationTransitTime(this.stockLocations.find((it) => it.id === this.selectedStockLocationId));
  }

  saveSupplierTransitTimes() {
    this.transitTimeService.saveSupplierTransitTime(this.supplierTransitTime).subscribe(() => {
      this.snackBar.open('Supplier default transit times saved.', 'CLOSE')._dismissAfter(2000);
      this.getSupplierTransitTimes();
    });
  }

  saveDefaultStockLocationTransitTime() {
    this.transitTimeService.saveDefaultStockLocationTransitTime(this.stockLocationTransitTime).subscribe(() => {
      this.snackBar.open('Stock location default transit times saved.', 'CLOSE')._dismissAfter(2000);
      this.getStockLocationDefaultTransitTimes();
    });
  }

  resetSupplierTransitTime() {
    this.getSupplierTransitTimes();
  }

  resetDefaultStockLocationTransitTime() {
    this.getStockLocationDefaultTransitTimes();
  }

  saveStockLocationTransitTime(sltt: TransitTime) {
    this.transitTimeService.saveStockLocationTransitTime(sltt, this.selectedStockLocationId).subscribe(() => {
      this.snackBar.open('Stock location transit time saved.', 'CLOSE')._dismissAfter(2000);
      this.getStockLocationsTransitTimes();
    });
  }

  deleteStockLocationTransitTime(id: number) {
    this.transitTimeService.deleteStockLocationTransitTime(id).subscribe(() => this.getStockLocationsTransitTimes());
  }
}
