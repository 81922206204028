import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConverterService } from '../core/converter.service';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { map } from 'rxjs/operators';

@Injectable()
export class ProductsService {
  constructor(private http: HttpClient, private environmentService: EnvironmentService) {}

  getProductsStatus(): Observable<string[]> {
    return this.http.get(this.environmentService.getRestEndpoint('productsStatuses')).pipe(map((status: string[]) => status));
  }
}
