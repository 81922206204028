import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FulfilmentKpi } from '../../../models/fulfilment-kpi';
import { SposService } from '../../spos.service';

@Component({
  selector: 'app-spo-kpi',
  templateUrl: './spo-kpi.component.html',
  styleUrls: ['./spo-kpi.component.css'],
})
export class SpoKpiComponent implements OnInit {
  constructor(private router: Router, private sposService: SposService) {}

  kpiData: Observable<FulfilmentKpi>;

  ngOnInit() {
    this.kpiData = this.sposService.kpiData;
  }

  goBack() {
    this.router.navigate(['/lots']);
  }
}
