import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConverterService } from '../../../core/converter.service';
import { EnvironmentService } from '../../../environment.service';
import { Observable } from 'rxjs';
import { SaveTransitTimeInput, SupplierTransitTime, TransitTime } from '../../../models/transit-time-model';
import { map } from 'rxjs/operators';
import { isNilty } from '../../../core/utils.service';
import { StockLocation, StockLocationTransitTime } from '../../../models/stock-location-model';

@Injectable()
export class TransitTimeService {
  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  getSupplierTransitTimes(supplierId: number): Observable<SupplierTransitTime> {
    return this.http
      .get(this.environmentService.getRestEndpoint('supplierTransitTimes') + '/' + supplierId)
      .pipe(map((resp) => this.converter.fromJSONtoObj(resp, SupplierTransitTime)));
  }

  saveSupplierTransitTime(stt: SupplierTransitTime): Observable<any> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = this.converter.fromObjtoJSON(stt);
    return this.http.post(this.environmentService.getRestEndpoint('saveSupplierTransitTimes'), body, { headers });
  }

  saveDefaultStockLocationTransitTime(sltt: StockLocationTransitTime): Observable<any> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = this.converter.fromObjtoJSON(sltt);
    return this.http.post(this.environmentService.getRestEndpoint('saveStockLocationDefaultTransitTimes'), body, { headers });
  }

  getStockLocationTransitTimes(supplierId: number, stockLocationId: number): Observable<TransitTime[]> {
    const path =
      this.environmentService.getRestEndpoint('stockLocationTransitTimes') +
      '?supplier=' +
      supplierId +
      '&stockLocation=' +
      stockLocationId;
    return this.http.get(path).pipe(
      map((resp: TransitTime[]) => {
        if (isNilty(resp)) {
          return [];
        }
        return resp.map((it) => this.converter.fromJSONtoObj(it, TransitTime));
      })
    );
  }

  getStockLocationDefaultTransitTimes(supplierId: number, stockLocationId: number): Observable<StockLocationTransitTime> {
    return this.http
      .get(this.environmentService.getRestEndpoint('stockLocationDefaultTransitTimes') + '/' + stockLocationId)
      .pipe(map((resp) => this.converter.fromJSONtoObj(resp, StockLocationTransitTime)));
  }

  saveStockLocationTransitTime(transitTime: TransitTime, stockLocationId: number): Observable<StockLocation> {
    const path = this.environmentService.getRestEndpoint('saveStockLocationTransitTimes');
    const body = this.converter.fromObjtoJSON(new SaveTransitTimeInput(transitTime, stockLocationId));

    return this.http.post(path, body).pipe(map((resp: StockLocation) => this.converter.fromJSONtoObj(resp, StockLocation)));
  }

  deleteStockLocationTransitTime(id: number): Observable<StockLocation> {
    const path = this.environmentService.getRestEndpoint('stockLocationTransitTimes') + '/' + id;
    return this.http.delete(path).pipe(map((resp: StockLocation) => this.converter.fromJSONtoObj(resp, StockLocation)));
  }
}
