import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';
import { PoItemWithLot } from './po-item-with-lot-model';
import { Shipment } from './shipment-model';

@JsonObject('ShipmentItem')
export class ShipmentItem extends BaseObject {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('purchaseOrderItem', PoItemWithLot, true) purchaseOrderItem: PoItemWithLot = undefined;
  @JsonProperty('actualEan', String, true) actualEan: string = undefined;
  @JsonProperty('requestedQuantity', Number, true) requestedQuantity: number = undefined;
  @JsonProperty('requestedValue', Number, true) requestedValue: number = undefined;
  @JsonProperty('confirmedQuantity', Number, true) confirmedQuantity: number = undefined;
  @JsonProperty('confirmedValue', Number, true) confirmedValue: number = undefined;
  @JsonProperty('shippedQuantity', Number, true) shippedQuantity: number = undefined;
  @JsonProperty('shippedValue', Number, true) shippedValue: number = undefined;
  @JsonProperty('purchasePrice', Number, true) purchasePriceUnit: number = undefined;
  @JsonProperty('confirmedPurchasePrice', Number, true) confirmedPurchasePrice: number = undefined;
  @JsonProperty('virtualConfirmedPurchasePrice', Number, true) virtualConfirmedPurchasePrice: number = undefined;
  @JsonProperty('shippedPurchasePrice', Number, true) shippedPurchasePrice: number = undefined;
  @JsonProperty('virtualShippedPurchasePrice', Number, true) virtualShippedPurchasePrice: number = undefined;
  @JsonProperty('shipment', Shipment, true) shipment: Shipment = undefined;
}
