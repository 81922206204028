import {
  ChangeDetectorRef,
  Directive,
  Input,
  IterableDiffer,
  IterableDiffers,
  NgIterable,
  OnDestroy,
  OnInit,
  TemplateRef,
  TrackByFunction,
  ViewContainerRef,
  ViewRef,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { AppMatSelectComponent } from './app-mat-select.component';
import { isEqual, isNil, isNilty } from '../../core/utils.service';
import { MatOption } from '@angular/material/core';

export class AppMatSelectForContext<T> {
  $implicit: T;
  index: number;
  count: number;
  appMatSelectFor: NgIterable<T>;

  constructor($implicit: T, index: number, count: number, appMatSelectFor: NgIterable<T>) {
    this.$implicit = $implicit;
    this.index = index;
    this.count = count;
    this.appMatSelectFor = appMatSelectFor;
  }
}

@Directive({
  selector: '[appMatSelectFor][appMatSelectForOf]',
  exportAs: 'appMatSelectFor',
})
export class AppMatSelectForDirective implements OnInit, OnDestroy {
  private collection: any[];
  private originalElements: any[];
  private trackByFn: TrackByFunction<MatOption>;
  private differ: IterableDiffer<MatOption>;
  private viewMap: Map<any, ViewRef> = new Map<any, ViewRef>();
  private subscription: Subscription;

  constructor(
    private parent: AppMatSelectComponent,
    private viewContainer: ViewContainerRef,
    private template: TemplateRef<AppMatSelectForContext<MatOption>>,
    private differs: IterableDiffers,
    private cd: ChangeDetectorRef
  ) {}

  @Input() set appMatSelectForOf(coll: any[]) {
    if (!isNilty(coll)) {
      if (!isEqual(coll, this.collection)) {
        if (!isNilty(this.maxRows) && coll.length > this.maxRows) {
          this.collection = coll.slice(0, this.maxRows);

          if (this.parent.value) {
            if (this.parent.multiple) {
              this.parent.value.forEach((value) => {
                if (!this.collection.includes(value)) {
                  this.collection.push(value);
                }
              });
            } else {
              if (!this.collection.includes(this.parent.value)) {
                this.collection.push(this.parent.value);
              }
            }
          }
        } else {
          this.collection = coll;
        }
        this.originalElements = coll;
        if (!isNilty(coll) && !this.differ) {
          this.differ = this.differs.find(coll).create(this.appMatSelectForTrackBy);
        }
        this.applyChanges();
      }
    } else {
      this.collection = [];
      this.applyChanges();
    }
  }

  @Input() set appMatSelectForTrackBy(fn) {
    this.trackByFn = fn;
  }
  get appMatSelectForTrackBy() {
    return this.trackByFn;
  }

  private maxRows: number = undefined;
  @Input()
  set appMatSelectForMaxRows(value: number) {
    this.maxRows = value;
  }

  @Input() set appMatSelectForTemplate(value) {
    if (value) {
      this.template = value;
    }
  }

  ngOnInit() {
    if (this.parent.automaticSearch) {
      this.subscription = this.parent.filterChange.subscribe((filterData: { filterText: string; filterKey: string | string[] }) => {
        let filteredElements: any[];
        if (isNilty(filterData.filterText)) {
          filteredElements = this.originalElements;
        } else {
          if (isNilty(filterData.filterKey)) {
            filteredElements = this.originalElements.filter((elem) => elem.toLowerCase().indexOf(filterData.filterText.toLowerCase()) > -1);
          } else {
            filteredElements = this.originalElements.filter((elem) => {
              if (Array.isArray(filterData.filterKey)) {
                return filterData.filterKey.some(
                  (key) => !isNil(elem[key]) && elem[key].toLowerCase().indexOf(filterData.filterText.toLowerCase()) > -1
                );
              } else {
                return (
                  !isNil(elem[filterData.filterKey]) &&
                  elem[filterData.filterKey].toLowerCase().indexOf(filterData.filterText.toLowerCase()) > -1
                );
              }
            });
          }
        }
        if (!isNilty(this.maxRows) && filteredElements.length > this.maxRows) {
          this.collection = filteredElements.slice(0, this.maxRows);
        } else {
          this.collection = filteredElements;
        }
        if (this.parent.value) {
          if (this.parent.multiple) {
            this.parent.value.forEach((value) => {
              if (!this.collection.includes(value)) {
                this.collection.push(value);
              }
            });
          } else {
            if (!this.collection.includes(this.parent.value)) {
              this.collection.push(this.parent.value);
            }
          }
        }
        this.applyChanges();
      });
    }
  }

  private applyChanges() {
    if (this.differ) {
      const changes = this.differ.diff(this.collection);
      if (changes) {
        // Remove all items
        this.viewContainer.clear();
        this.viewMap.clear();

        this.collection.forEach((item, index) => {
          // Add all items of collection
          const view = this.viewContainer.createEmbeddedView(
            this.template as any,
            new AppMatSelectForContext(null, -1, -1, this.appMatSelectForOf),
            index
          );
          view.context.$implicit = item;
          this.viewMap.set(item, view);
        });

        this.cd.markForCheck(); // per quei componenti padri che hanno il change detection OnPush
      }
    }
  }

  ngOnDestroy() {
    if (!isNilty(this.subscription)) {
      this.subscription.unsubscribe();
    }
  }
}
