import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from './date-converter';
import { Shipment } from './shipment-model';
import { Status, StatusConverter } from './status-model';
import { Supplier } from './supplier-model';

@JsonObject('PaymentRequestSimple')
export class PaymentRequestSimple {
  @JsonProperty('id', Number, false) id: number = undefined;
}

@JsonObject('PaymentDocument')
export class PaymentDocument {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('documentNumber', String, true) documentNumber: string = undefined;
  @JsonProperty('documentUrl', String, true) documentUrl: string = undefined;
  @JsonProperty('documentType', StatusConverter, true) documentType: Status = undefined;
  @JsonProperty('supplier', Supplier, true) supplier: Supplier = undefined;
  @JsonProperty('status', StatusConverter, true) status: Status = undefined;
  @JsonProperty('documentDate', DateConverter, true) documentDate: Date = undefined;
  @JsonProperty('value', Number, true) value: number = undefined;
  @JsonProperty('vat', Number, true) vat: number = undefined;
  @JsonProperty('quantity', Number, true) quantity: number = undefined;
  @JsonProperty('shipments', [Shipment], true) shipments: Shipment[] = undefined;
}
