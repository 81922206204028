<div class="main-content">
  <form [formGroup]="lotConfigurationForm" (ngSubmit)="onSubmit()">
    <div class="col-xs-12 margin-bottom-60">
      <div class="col-xs-2"></div>
      <div class="col-xs-8">
        <div class="row">
          <div class="col-xs-6">
            <mat-form-field>
              <input matInput type="text" id="name" formControlName="name" placeholder="Name" />
            </mat-form-field>
          </div>

          <div class="col-xs-6">
            <mat-form-field>
              <input matInput type="text" id="lotName" formControlName="lotName" placeholder="Lot Name" />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-6">
            <mat-form-field>
              <input matInput type="text" id="cronExpression" formControlName="cronExpression" placeholder="Cron Expression" />
            </mat-form-field>
          </div>

          <div class="col-xs-6">
            <mat-form-field>
              <app-mat-select filterKey="description" formControlName="vendors" id="vendors" placeholder="Vendors" [multiple]="true">
                <mat-option *appMatSelectFor="let vendor of vendors" [value]="vendor">{{ vendor.description }} </mat-option>
              </app-mat-select>
            </mat-form-field>
          </div>
        </div>

        <ng-container>
          <div class="row padding-bottom-20">
            <div class="col-md-12">
              <app-delivery-window-constraints
                [deliveryWindowConstraints]="currentLotConfiguration.deliveryWindowConstraints"
              ></app-delivery-window-constraints>
            </div>
          </div>
        </ng-container>

        <div class="row">
          <div class="col-xs-6">
            <mat-form-field>
              <input matInput type="number" id="thresholdValue" formControlName="thresholdValue" placeholder="Threshold value" />
            </mat-form-field>
          </div>

          <div class="col-xs-6">
            <mat-form-field>
              <app-mat-select
                filterKey="name"
                formControlName="notIntegratedSuppliers"
                id="notIntegratedSuppliers"
                placeholder="Not Integrated Suppliers"
                [multiple]="true"
              >
                <mat-option *ngFor="let supplier of suppliers" [value]="supplier">{{ supplier.description }} </mat-option>
              </app-mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-6">
            <mat-form-field>
              <input
                matInput
                type="number"
                id="generateOrderedIterations"
                formControlName="generateOrderedIterations"
                placeholder="Ordered Iterations"
              />
            </mat-form-field>
          </div>

          <div class="col-xs-6">
            <mat-form-field>
              <input
                matInput
                type="number"
                id="generateOrderedDecreseMarginPerIteration"
                formControlName="generateOrderedDecreseMarginPerIteration"
                placeholder="Ordered Decrease Margin per Iteration"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-6">
            <mat-checkbox class="text-blue font-Titillium" id="disabled" formControlName="disabled"> Disabled </mat-checkbox>
          </div>
          <div class="col-xs-6"></div>
        </div>

        <app-generate-ordered-configurations
          [generateOrderedConfigurations]="generateOrderedConfigurations"
        ></app-generate-ordered-configurations>

        <div class="row padding-top-20">
          <div class="col text-center">
            <button type="submit" [disabled]="!lotConfigurationForm.valid" class="btn-product">Save</button>
            <button type="button" (click)="onBack()" class="btn-product margin-left-10">Back</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
