<div class="container is-not-in-lot">
  <div class="row">
    <div class="col-xs-12">
      <hr class="divisor-title" style="width: calc(100% - 32rem)" />
      <div class="square" style="right: 32rem"></div>
      <h2 class="po-element-custom title-custom">INVOICE: {{ invoice.invoiceNumber }}</h2>
    </div>
  </div>
</div>

<app-single-invoice-kpi [invoice]="invoice" [claims]="claims"></app-single-invoice-kpi>

<div class="padding-top-15">
  <mat-toolbar class="lot-edit-bulk-actions">
    <div class="container">
      <div class="row">
        <div class="col-md-10">
          <button
            *ngIf="hasInvoiceManagement | async"
            class="box-shadow-none btn-custom margin-right-6"
            (click)="sendInvoice()"
            [disabled]="invoice.sentToAmazon"
          >
            <i class="fa fa-file-text margin-right-6" aria-hidden="true"></i>Send invoice
          </button>

          <button [matMenuTriggerFor]="exportInvoiceMenu" class="box-shadow-none btn-custom margin-right-6">
            Download invoice <i class="fa fa-angle-down sel-icon" aria-hidden="true"></i>
          </button>
          <mat-menu #exportInvoiceMenu="matMenu">
            <button mat-menu-item (click)="exportInvoice()">Download Pdf</button>
            <button mat-menu-item (click)="exportInvoiceXml()">Download Xml</button>
          </mat-menu>

          <button *ngIf="hasInvoiceManagement | async" class="box-shadow-none btn-custom margin-right-6" (click)="deleteInvoice()">
            <i class="fa fa-file-text margin-right-6" aria-hidden="true"></i>Delete invoice
          </button>

          <button [disabled]="!hasPodUrls" class="box-shadow-none btn-custom margin-right-6" (click)="downloadPods()">
            <i class="fa fa-download margin-right-6" aria-hidden="true"></i>Download PODs
          </button>

          <button class="box-shadow-none btn-custom margin-right-6" (click)="showClaim()">
            <i class="fa fa-arrow-right margin-right-6" aria-hidden="true"></i>Show Claim
          </button>
        </div>

        <div class="col-md-2 text-right padding-left-0">
          <button class="btn-custom" (click)="goBack()">Back</button>
        </div>
      </div>
    </div>
  </mat-toolbar>
</div>

<div class="container table-outer">
  <mat-paginator #paginator [length]="resultsNumber | async" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()">
  </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="ean" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="ean">EAN</mat-header-cell>
        <mat-cell *matCellDef="let invoiceItem">{{ invoiceItem.shipmentItem.purchaseOrderItem.product.ean }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="actualEan">
        <mat-header-cell *matHeaderCellDef mat-sort-header="actualEan">Actual EAN</mat-header-cell>
        <mat-cell *matCellDef="let invoiceItem">{{ invoiceItem.shipmentItem.actualEan }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="title">
        <mat-header-cell class="title-column" *matHeaderCellDef mat-sort-header="title">Title</mat-header-cell>
        <mat-cell class="title-column" *matCellDef="let invoiceItem">
          <p>{{ invoiceItem.shipmentItem.purchaseOrderItem.product.title }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="shippedQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="shippedQuantity"
          >Shipped Quantity</mat-header-cell
        >
        <mat-cell class="quantity-column" *matCellDef="let invoiceItem">
          <p>{{ invoiceItem.shipmentItem.shippedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="shippedValue">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="shippedValue">Shipped Value </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let invoiceItem">
          <p>{{ invoiceItem.shipmentItem.shippedValue | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="taxRate">
        <mat-header-cell class="tax-column" *matHeaderCellDef mat-sort-header="taxRate">Tax Rate</mat-header-cell>
        <mat-cell class="tax-column" *matCellDef="let invoiceItem">{{ invoiceItem.taxRate }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
