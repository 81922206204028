import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ImportExportService } from '../../../../../shared/import-export.service';
import { LotsService } from '../../../../../shared/lots.service';
import { ENTITIES } from '../../../../../entities';
import { AuthorizationService } from '../../../../../core/authorization.service';
import { Permissions } from '../../../../../models/permission-model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-spo-items-bulk-actions',
  templateUrl: './spo-items-bulk-actions.component.html',
  styleUrls: ['./spo-items-bulk-actions.component.css'],
})
export class SpoItemsBulkActionsComponent implements OnInit {
  @ViewChild('getSpoResp', { static: true }) getSpoResp: ElementRef;
  @ViewChild('getUpdateOrdered', { static: true }) getUpdateOrdered: ElementRef;
  @ViewChild('getSpoGenerate', { static: true }) getSpoGenerate: ElementRef;
  currentFileUpload: File;

  @Input() bulkActionsActive: boolean;

  @Output() onExport = new Subject<string>();

  hasFulfillmentCreation: Observable<boolean>;

  constructor(
    private lotsService: LotsService,
    public snackBar: MatSnackBar,
    private importExportService: ImportExportService,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit() {
    this.hasFulfillmentCreation = this.authorizationService.hasPermission(Permissions.FulfillmentCreation);
  }

  exportExcel(format: string) {
    this.onExport.next(format);
  }

  importSpoResponse() {
    this.getSpoResp.nativeElement.click();
  }

  importSpoGenerate() {
    this.getSpoGenerate.nativeElement.click();
  }

  importUpdateOrdered() {
    this.getUpdateOrdered.nativeElement.click();
  }

  uploadSpoResponse(event) {
    this.fileChanged(event, ENTITIES.SPO_IMPORT_CONFIRM);
  }

  uploadSpoGenerate(event) {
    this.fileChanged(event, ENTITIES.SPO_IMPORT_GENERATE);
  }

  uploadUpdateOrdered(event) {
    this.fileChanged(event, ENTITIES.UPDATE_ORDERED);
  }

  fileChanged(event, entity: string) {
    this.currentFileUpload = event.target.files[0];
    this.snackBar.open('Uploading', '')._dismissAfter(3000);
    this.importExportService.uploadFile(this.currentFileUpload, entity, this.lotsService.currentLotId).subscribe(() => {
      this.currentFileUpload = undefined;
    });
  }
}
