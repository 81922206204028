<div class="container">
  <hr />

  <div class="col-xs-3 background-blue text-white height-box font-row">
    <h4 class="h-padding-box">
      <b>Submitted</b>
    </h4>
    <p><b class="margin-right-6">Quantity: </b> {{ (kpiData | async)?.submittedQuantity }}</p>
    <p><b class="margin-right-6">Value: </b> {{ (kpiData | async)?.submittedValue | myCurrency }}</p>
  </div>

  <div class="col-xs-3 background-light-blue text-white height-box font-row">
    <h4 class="h-padding-box">
      <b>Accepted</b>
    </h4>
    <p><b class="margin-right-6">Quantity: </b> {{ (kpiData | async)?.acceptedQuantity }}</p>
    <p><b class="margin-right-6">Value: </b> {{ (kpiData | async)?.acceptedValue | myCurrency }}</p>
  </div>

  <div class="col-xs-3 background-blue text-white height-box font-row">
    <h4 class="h-padding-box">
      <b>Cancelled</b>
    </h4>
    <p><b class="margin-right-6">Quantity: </b> {{ (kpiData | async)?.cancelledQuantity }}</p>
    <p><b class="margin-right-6">Value: </b> {{ (kpiData | async)?.cancelledValue | myCurrency }}</p>
  </div>

  <div class="col-xs-3 background-light-blue text-white height-box font-row">
    <h4 class="h-padding-box">
      <b>Received</b>
    </h4>
    <p><b class="margin-right-6">Quantity: </b> {{ (kpiData | async)?.receivedQuantity }}</p>
    <p><b class="margin-right-6">Value: </b> {{ (kpiData | async)?.receivedValue | myCurrency }}</p>
  </div>
</div>

<div class="container">
  <hr />
</div>
