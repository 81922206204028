/* eslint-disable max-len */
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { SendPoaModalComponent } from './container/lot-edit-section/send-poa-modal/send-poa-modal.component';
import { GenericConfirmationModalComponent } from './container/modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { GenericSwitchConfirmationModalComponent } from './container/modal/generic-switch-confirmation-modal/generic-switch-confirmation-modal.component';
import { InvalidShipmentOriginModalComponent } from './container/modal/invalid-shipment-origin-modal/invalid-shipment-origin-modal.component';
import { RESTInterceptor } from './core/rest.Interceptor';
import { MaterialModule } from './material.module';
import { SpoKpiComponent } from './shared/kpi/spo-kpi/spo-kpi.component';
import { AppMatSelectForDirective } from './shared/material-select-searchbox/app-mat-select-for.directive';
import { AppMatSelectComponent } from './shared/material-select-searchbox/app-mat-select.component';
import { MyDateAdapter } from './shared/my-date-adapter.component';
import { PipeModule } from './shared/my-pipes';
import { OriginModalComponent } from './shared/single-shipment-detail/origin-modal/origin-modal.component';
import { TransitWarehouseModalComponent } from './shared/single-shipment-detail/transit-warehouse-modal/transit-warehouse-modal.component';
import { CommonsService } from './shared/commons.service';
import { ProductsService } from './shared/products.service';
import { LotConfigurationsService } from '@container/configuration/lot-configurations/lot-configurations.service';

const MY_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};

@NgModule({
  declarations: [
    AppMatSelectForDirective,
    GenericConfirmationModalComponent,
    InvalidShipmentOriginModalComponent,
    TransitWarehouseModalComponent,
    OriginModalComponent,
    AppMatSelectComponent,
    SendPoaModalComponent,
    SpoKpiComponent,
    GenericSwitchConfirmationModalComponent,
  ],
  imports: [CommonModule, MaterialModule, FormsModule, OverlayModule, RouterModule, ReactiveFormsModule, HttpClientModule, PipeModule],
  providers: [
    PipeModule,
    CommonsService,
    ProductsService,
    LotConfigurationsService,
    { provide: DateAdapter, useClass: MyDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: HTTP_INTERCEPTORS, useClass: RESTInterceptor, multi: true },
  ],
  entryComponents: [
    GenericConfirmationModalComponent,
    InvalidShipmentOriginModalComponent,
    SendPoaModalComponent,
    GenericSwitchConfirmationModalComponent,
  ],
  exports: [
    RouterModule,
    CommonModule,
    MaterialModule,
    OverlayModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    PipeModule,
    AppMatSelectForDirective,
    AppMatSelectComponent,
    SpoKpiComponent,
  ],
})
export class SharedModule {}
