<div class="dialog-container">
  <h2 mat-dialog-title>Change Latest Payment Date</h2>

  <mat-dialog-content>
    <div class="row">
      <div class="col-xs-12 margin-top-20 text-center">
        <mat-form-field>
          <mat-label>Choose a date</mat-label>
          <input matInput [matDatepicker]="picker" [(ngModel)]="data.prLpd" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>

  <div mat-dialog-actions>
    <button class="btn-product margin-right-6" [disabled]="isDisabled()" (click)="changeLatestPaymentDate(data.prLpd)">Save</button>
    <button class="btn-product" cdkFocusInitial [mat-dialog-close]="undefined">Cancel</button>
  </div>
</div>
