import { Component, OnInit } from '@angular/core';
import { ConfigurationParamsService } from './configuration-params.service';
import { ConfigurationParam, ConfigurationParamsResponse } from '../../../models/configuration-params-model';
import { cloneClass, isNilty } from '../../../core/utils.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-configuration-params',
  templateUrl: './configuration-params.component.html',
  styleUrls: ['./configuration-params.component.css'],
})
export class ConfigurationParamsComponent implements OnInit {
  constructor(private configurationService: ConfigurationParamsService, private snackBar: MatSnackBar) {}

  configurations: ConfigurationParam[];

  configurationsMap: ConfigurationParam[] = [];

  ngOnInit() {
    this.configurationService.getConfigurationParamTypes().subscribe((resp: ConfigurationParamsResponse) => {
      this.configurations = resp.params;
      this.configurationsMap = resp.map;
    });
  }

  save() {
    this.configurationService.saveConfigurationParams(this.configurations).subscribe((resp: ConfigurationParamsResponse) => {
      this.configurations = resp.params;
      this.configurationsMap = resp.map;
      this.snackBar.open('Configuration saved.' + status, 'CLOSE')._dismissAfter(2000);
    });
  }

  new() {
    const c = new ConfigurationParam();
    c.new = true;
    this.configurations.push(c);
  }

  add(row: ConfigurationParam) {
    const newConfig = this.configurationsMap.find((it) => it.type === row.type);

    if (!isNilty(newConfig)) {
      row.parameters = [];
      newConfig.parameters.forEach((it) => {
        row.parameters.push(cloneClass(it));
      });

      if (newConfig.unique) {
        this.configurationsMap.splice(
          this.configurationsMap.findIndex((it) => it.type === newConfig.type),
          1
        );
      }

      row.new = false;
    }
  }

  delete(index: number) {
    if (!isNilty(this.configurations[index].id) && this.configurations[index].id !== -1) {
      this.configurationService.deleteConfiguration(this.configurations[index].id).subscribe((resp: ConfigurationParamsResponse) => {
        this.configurations.splice(index, 1);
        this.configurationsMap = resp.map;
      });
    } else {
      this.configurations.splice(index, 1);
      this.configurationService.getConfigurationParamTypes().subscribe((resp: ConfigurationParamsResponse) => {
        this.configurationsMap = resp.map;
      });
    }
  }
}
