import { JsonObject, JsonProperty } from 'json2typescript';
import { Warehouse } from './warehouse-model';
import { Supplier } from './supplier-model';

export const COUNTRIES = ['DE', 'ES', 'FR', 'IT', 'NL', 'UK', 'SE', 'PL'];

@JsonObject('TransitTime')
export class TransitTime {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('destination', Warehouse, false) destination: Warehouse = undefined;
  @JsonProperty('transitTimeWorkingDays', Number, false) transitTimeWorkingDays: number = undefined;

  constructor() {
    this.transitTimeWorkingDays = 0;
  }
}

@JsonObject('SaveTransitTimeInput')
export class SaveTransitTimeInput {
  @JsonProperty('transitTimeWorkingDays', Number, true) transitTimeWorkingDays: number = undefined;
  @JsonProperty('stockLocationId', Number, true) stockLocationId: number = undefined;
  @JsonProperty('destinationId', Number, true) destinationId: number = undefined;

  constructor(tt: TransitTime, stockLocationId: number) {
    this.transitTimeWorkingDays = tt.transitTimeWorkingDays;
    this.destinationId = tt.destination.id;
    this.stockLocationId = stockLocationId;
  }
}

@JsonObject('CountryTransitTime')
export class CountryTransitTime {
  @JsonProperty('countryCode', String, true) countryCode: string = undefined;
  @JsonProperty('transitTimeWorkingDays', Number, false) transitTimeWorkingDays: number = undefined;

  constructor(countryCode: string) {
    this.countryCode = countryCode;
    this.transitTimeWorkingDays = 0;
  }
}

@JsonObject('SupplierTransitTime')
export class SupplierTransitTime {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('supplier', Supplier, true) supplier: Supplier = undefined;
  @JsonProperty('palletThreshold', Number, true) palletThreshold: number = undefined;
  @JsonProperty('transitTimes', [CountryTransitTime], true) transitTimes: CountryTransitTime[] = undefined;

  constructor(supplier: Supplier) {
    this.supplier = supplier;
    this.palletThreshold = 0;
    this.transitTimes = COUNTRIES.map((it) => new CountryTransitTime(it));
  }
}
