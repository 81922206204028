import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';
import { ShipmentItem } from './shipment-item-model';
import { Invoice } from './invoice-model';

@JsonObject('InvoiceItem')
export class InvoiceItem extends BaseObject {
  @JsonProperty('id', Number, false) id: number = undefined;

  @JsonProperty('taxRate', Number, true) taxRate: number = undefined;
  @JsonProperty('shipmentItem', ShipmentItem, true) shipmentItem: ShipmentItem = undefined;
  @JsonProperty('invoice', Invoice, true) invoice: Invoice = undefined;
}
