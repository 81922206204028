<div>
  <mat-toolbar class="bar-custom">
    <div class="container">
      <div class="row">
        <div class="col-md-2">
          <button class="box-shadow-none btn-custom" (click)="back()">Back</button>
        </div>
      </div>
    </div>
  </mat-toolbar>
</div>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <hr class="divisor-title" />
      <div class="square"></div>
      <h2 class="po-element-custom title-custom">Claim Details</h2>
    </div>
  </div>
</div>

<div class="container table-outer">
  <mat-paginator [length]="resultsNumber | async" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()">
  </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table multiTemplateDataRows [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="invoiceNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header="invoiceNumber" class="invoice-number-column"> Invoice Number </mat-header-cell>
        <mat-cell *matCellDef="let cd" class="invoice-number-column">
          {{ cd.invoiceNumber }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="poNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header="poNumber" class="po-number-column"> Po Number </mat-header-cell>
        <mat-cell *matCellDef="let cd" class="po-number-column">
          {{ cd.poNumber }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="asin">
        <mat-header-cell *matHeaderCellDef mat-sort-header="asin" class="asin-column"> Asin </mat-header-cell>
        <mat-cell *matCellDef="let cd" class="asin-column">
          <p>{{ cd.asin }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ean">
        <mat-header-cell *matHeaderCellDef mat-sort-header="ean" class="ean-column"> Ean </mat-header-cell>
        <mat-cell *matCellDef="let cd" class="ean-column">
          <p>{{ cd.externalId }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef mat-sort-header="title" class="title-claim-header-column"> Title </mat-header-cell>
        <mat-cell *matCellDef="let cd" class="title-claim-header-column">
          <p>{{ cd.title }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="invoiceQuantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header="invoiceQuantity" class="quantity-column-title">
          Invoice Quantity
        </mat-header-cell>
        <mat-cell *matCellDef="let cd" class="quantity-column">
          <p>{{ cd.invoiceQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="receivedQuantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header="receivedQuantity" class="quantity-column-title">
          Received Quantity
        </mat-header-cell>
        <mat-cell *matCellDef="let cd" class="quantity-column">
          <p>{{ cd.receivedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="claimQuantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header="claimQuantity" class="quantity-column-title"> Claim Quantity </mat-header-cell>
        <mat-cell *matCellDef="let cd" class="quantity-column">
          <p>{{ cd.claimQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="amount">
        <mat-header-cell *matHeaderCellDef mat-sort-header="claimAmount" class="value-column-title"> Amount </mat-header-cell>
        <mat-cell *matCellDef="let cd" class="value-column">
          <p>{{ cd.claimAmount | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
    </mat-table>
  </div>
</div>
