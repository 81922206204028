import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { SingleSpoDetailComponent } from './single-spo-detail.component';
import { SingleSpoFiltersComponent } from './single-spo-filters/single-spo-filters.component';
import { SingleSpoKpiComponent } from './single-spo-kpi/single-spo-kpi.component';
import { SingleSpoGroupedSpoItemTableComponent } from './single-spo-grouped-spo-item-table/single-spo-grouped-spo-item-table.component';
import { SingleSpoItemTableComponent } from './single-spo-item-table/single-spo-item-table.component';

@NgModule({
  imports: [SharedModule],
  declarations: [
    SingleSpoDetailComponent,
    SingleSpoFiltersComponent,
    SingleSpoKpiComponent,
    SingleSpoGroupedSpoItemTableComponent,
    SingleSpoItemTableComponent,
  ],
  exports: [],
  providers: [],
  entryComponents: [],
})
export class SingleSpoDetailsModule {}
