<div class="container row main-content" style="">
  <form [formGroup]="warehouseForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-md-3"></div>

      <div class="col-md-6 padding-bottom-20">
        <div class="row">
          <legend>Warehouse</legend>
        </div>

        <div class="row">
          <div class="col-md-8 form-group">
            <mat-form-field>
              <input matInput type="text" id="name" formControlName="name" placeholder="Name*" />
            </mat-form-field>
          </div>

          <div class="col-md-2 form-group">
            <mat-form-field>
              <input matInput type="text" id="code" formControlName="code" placeholder="Code*" />
            </mat-form-field>
          </div>

          <div class="col-md-2 form-group">
            <mat-form-field>
              <input matInput type="number" id="minimalValue" formControlName="minimalValue" placeholder="Minimal Value" />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3 form-group">
            <mat-form-field>
              <input matInput type="text" id="businessName" formControlName="businessName" placeholder="Business Name" />
            </mat-form-field>
          </div>

          <div class="col-md-3 form-group">
            <mat-form-field>
              <input matInput type="text" id="marketplace" formControlName="marketplace" placeholder="Marketplace*" />
            </mat-form-field>
          </div>

          <div class="col-md-3 form-group">
            <mat-form-field>
              <input matInput type="text" id="gln" formControlName="gln" placeholder="GLN Code*" />
            </mat-form-field>
          </div>

          <div class="col-md-3 form-group">
            <mat-form-field>
              <input matInput type="text" id="brandonGln" formControlName="brandonGln" placeholder="Brandon GLN*" />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <legend>Country</legend>
        </div>

        <div class="row">
          <div class="col-md-4 form-group">
            <mat-form-field>
              <input matInput type="text" id="country" formControlName="country" placeholder="Country*" />
            </mat-form-field>
          </div>

          <div class="col-md-4 form-group">
            <mat-form-field>
              <input matInput type="text" id="countryCode" formControlName="countryCode" placeholder="Country Code*" />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <legend>Contacts</legend>
        </div>

        <div class="row">
          <div class="col-md-9 form-group">
            <mat-form-field>
              <input matInput type="text" id="address" formControlName="address" placeholder="Address*" />
            </mat-form-field>
          </div>

          <div class="col-md-3 form-group">
            <mat-form-field>
              <input matInput type="text" id="city" formControlName="city" placeholder="City*" />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-md-2 form-group">
            <mat-form-field>
              <input matInput type="text" id="postalCode" formControlName="postalCode" placeholder="Postal Code*" />
            </mat-form-field>
          </div>

          <div class="col-md-3 form-group">
            <mat-form-field>
              <input matInput type="text" id="region" formControlName="region" placeholder="Region" />
            </mat-form-field>
          </div>

          <div class="col-md-2 form-group">
            <mat-form-field>
              <input matInput type="text" id="phone" formControlName="phone" placeholder="Phone" />
            </mat-form-field>
          </div>

          <div class="col-md-5 form-group">
            <mat-form-field>
              <input matInput type="text" id="mail" formControlName="mail" placeholder="Email" />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="col-xs-4 col-xs-offset-4">
        <div class="row form-buttons">
          <button type="submit" [disabled]="!warehouseForm.valid" class="btn-product margin-right-15">Save</button>
          <button *ngIf="editMode" type="button" (click)="onDelete()" class="btn-product margin-right-15">Delete!</button>
          <button type="button" (click)="onCancel()" class="btn-product">Back</button>
        </div>
      </div>
    </div>
  </form>
</div>
