import { Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { JsonObject, JsonProperty } from 'json2typescript';
import { isNil, isNilty } from '../../../../../core/utils.service';
import { DateConverter } from '../../../../../models/date-converter';
import { Status } from '../../../../../models/status-model';
import { ClaimService } from '../claim.service';
import { GenericConfirmationModalComponent } from '@container/modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { ActivatedRoute, Router } from '@angular/router';

export class ClaimEditModalData {
  showCreditNote: boolean;
  form: ClaimEditData;
  claimId: number;
}

@JsonObject('ClaimEditData')
export class ClaimEditData {
  @JsonProperty('disputeId', String, true) disputeId: string = undefined;
  @JsonProperty('disputeDate', DateConverter, true) disputeDate: Date = undefined;
  @JsonProperty('creditNote', Boolean, true) creditNote: boolean = undefined;
  @JsonProperty('caseIds', [String], true) caseIds: string[] = undefined;
  @JsonProperty('caseDate', DateConverter, true) caseDate: Date = undefined;
  @JsonProperty('status', String, true) status: string = undefined;
}

@Component({
  selector: 'app-edit-claim-modal',
  templateUrl: './edit-claim-modal.component.html',
  styleUrls: ['./edit-claim-modal.component.css'],
})
export class EditClaimModalComponent implements OnInit {
  constructor(
    private claimService: ClaimService,
    public dialogRef: MatDialogRef<EditClaimModalComponent>,
    private router: Router,
    private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ClaimEditModalData
  ) {}

  modalData: ClaimEditModalData;
  status: Status[] = [];
  claimId: number;

  @ViewChild('caseIdInput', { static: true }) caseIdInput: ElementRef<MatInput>;

  ngOnInit() {
    this.modalData = this.data;
    this.claimId = this.data.claimId;

    this.claimService.getClaimStatus().subscribe((status: Status[]) => (this.status = status));
  }

  addCaseId(value: string) {
    this.data.form.caseIds.push(value);
    this.caseIdInput.nativeElement.value = '';
  }

  removeCaseId(index: number) {
    this.modalData.form.caseIds.splice(index, 1);
  }

  isDisabled() {
    const form = this.modalData.form;
    if (isNilty(form.disputeId) !== isNil(form.disputeDate)) {
      return true;
    }
    return false;
  }

  onDelete() {
    const message = 'Deleting claim';
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '350px',
      data: message,
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.claimService.deleteClaim(this.claimId).subscribe(() => {
          console.log('Claim deleted.');
          this.router.navigate(['deductions']);
        });
      } else {
        console.log('Claim not deleted.');
      }
    });
  }
}
