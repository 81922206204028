<app-suppliers-bulk-actions *ngIf="hasSupplierManagement | async"></app-suppliers-bulk-actions>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <hr class="divisor-title" style="width: calc(100% - 14rem);" />
      <div class="square" style="right: 14rem;"></div>
      <h2 class="po-element-custom title-custom">Suppliers</h2>
    </div>
  </div>
</div>

<div class="container table-outer">
  <div class="row">
    <div class="col-md-9">
      <mat-form-field style="min-width: 100%;">
        <input matInput #filterInput placeholder="Search Name / Code" [value]="filterText" />
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-paginator [length]="resultsNumber | async" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()">
      </mat-paginator>
    </div>
  </div>

  <div class="row">
    <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
      <mat-table [dataSource]="dataSource" class="font-Titillium" matSort (matSortChange)="onSort()">
        <ng-container matColumnDef="showStock" sticky>
          <mat-header-cell class="actions-edit-column" *matHeaderCellDef></mat-header-cell>
          <mat-cell class="actions-edit-column" *matCellDef="let supplier">
            <button class="btn-product edit-button" matTooltip="Show Stock Locations" (click)="getStockLocations(supplier.name)">
              <i class="fa fa-cube" aria-hidden="true"></i>
            </button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name" sticky>
          <mat-header-cell class="supplier-name-column" *matHeaderCellDef mat-sort-header="name"> Name </mat-header-cell>
          <mat-cell class="supplier-name-column" *matCellDef="let supplier">
            <p>{{ supplier.name }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="code">
          <mat-header-cell class="code-column" *matHeaderCellDef mat-sort-header="code"> Code </mat-header-cell>
          <mat-cell class="code-column" *matCellDef="let supplier"> {{ supplier.code }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="minimalValue">
          <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="minimalValue"> Minimal Value </mat-header-cell>
          <mat-cell class="value-column" *matCellDef="let supplier">
            <p>{{ supplier.minimalValue | myCurrency }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="minimalShipmentValue">
          <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="minimalShipmentValue">
            Minimal Shipment Value
          </mat-header-cell>
          <mat-cell class="value-column" *matCellDef="let supplier">
            <p>{{ supplier.minimalShipmentValue | myCurrency }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="shipsToAmazon">
          <mat-header-cell *matHeaderCellDef mat-sort-header="shipsToAmazon" class="boolean-column"> Ships to Amazon </mat-header-cell>
          <mat-cell class="boolean-column" *matCellDef="let supplier">
            <p><i [ngClass]="supplier.shipsToAmazon | booleanIconPipe" aria-hidden="true"></i></p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="transit">
          <mat-header-cell *matHeaderCellDef mat-sort-header="transit" class="boolean-column"> Transit </mat-header-cell>
          <mat-cell class="boolean-column" *matCellDef="let supplier">
            <p><i [ngClass]="supplier.transit | booleanIconPipe" aria-hidden="true"></i></p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="acceptBindingOrders">
          <mat-header-cell *matHeaderCellDef mat-sort-header="acceptBindingOrders" class="boolean-column"> Binding orders </mat-header-cell>
          <mat-cell class="boolean-column" *matCellDef="let supplier">
            <p><i [ngClass]="supplier.acceptBindingOrders | booleanIconPipe" aria-hidden="true"></i></p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="updatedCatalog">
          <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="updatedCatalog"> Updated Catalog </mat-header-cell>
          <mat-cell class="boolean-column" *matCellDef="let supplier">
            <p><i [ngClass]="supplier.updatedCatalog | booleanIconPipe" aria-hidden="true"></i></p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="uniqueSupplier">
          <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="uniqueSupplier"> Unique </mat-header-cell>
          <mat-cell class="boolean-column" *matCellDef="let supplier">
            <p><i [ngClass]="supplier.uniqueSupplier | booleanIconPipe" aria-hidden="true"></i></p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="takesBackorder">
          <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="takesBackorder"> Takes Backorder </mat-header-cell>
          <mat-cell class="boolean-column" *matCellDef="let supplier">
            <p><i [ngClass]="supplier.takesBackorder | booleanIconPipe" aria-hidden="true"></i></p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="takesFirstorder">
          <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="takesFirstorder"> Takes Firstorder </mat-header-cell>
          <mat-cell class="boolean-column" *matCellDef="let supplier">
            <p><i [ngClass]="supplier.takesFirstorder | booleanIconPipe" aria-hidden="true"></i></p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="takesSoftFirstorder">
          <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="takesSoftFirstorder">
            Takes Soft Firstorder
          </mat-header-cell>
          <mat-cell class="boolean-column" *matCellDef="let supplier">
            <p><i [ngClass]="supplier.takesSoftFirstorder | booleanIconPipe" aria-hidden="true"></i></p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sendSSCC">
          <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="sendSSCC"> Send SSCC </mat-header-cell>
          <mat-cell class="boolean-column" *matCellDef="let supplier">
            <p><i [ngClass]="supplier.sendSSCC | booleanIconPipe" aria-hidden="true"></i></p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ssccPrefix">
          <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="ssccPrefix"> SSCC Prefix </mat-header-cell>
          <mat-cell class="boolean-column" *matCellDef="let supplier">
            <p>{{ supplier.ssccPrefix }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sendsAsn">
          <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="sendsAsn"> Sends ASN </mat-header-cell>
          <mat-cell class="boolean-column" *matCellDef="let supplier">
            <p><i [ngClass]="supplier.sendsAsn | booleanIconPipe" aria-hidden="true"></i></p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="asnPrefix">
          <mat-header-cell class="code-column" *matHeaderCellDef mat-sort-header="asnPrefix"> ASN Prefix </mat-header-cell>
          <mat-cell class="code-column" *matCellDef="let supplier">
            <p>{{ supplier.asnPrefix }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="showProducts" stickyEnd>
          <mat-header-cell class="actions-edit-column" *matHeaderCellDef></mat-header-cell>
          <mat-cell class="actions-edit-column" *matCellDef="let supplier">
            <button class="btn-product edit-button" matTooltip="Show catalog" (click)="getSupplierProducts(supplier.id)">
              <i class="fa fa-list" aria-hidden="true"></i>
            </button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="edit" *ngIf="hasSupplierManagement | async" stickyEnd>
          <mat-header-cell class="actions-edit-column" *matHeaderCellDef></mat-header-cell>
          <mat-cell class="actions-edit-column" *matCellDef="let supplier">
            <button class="btn-product edit-button" matTooltip="Edit" [routerLink]="['edit', supplier.id]">
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>
</div>
