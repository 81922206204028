import { NgModule } from '@angular/core';
import { SingleShipmentDetailComponent } from './single-shipment-detail.component';
import { EditCarrierModalComponent } from './edit-carrier-modal/edit-carrier-modal.component';
import { EditPackagingModalComponent } from './edit-packaging-modal/edit-packaging-modal.component';
import { SingleShipmentDetailFiltersComponent } from './single-shipment-detail-filters/single-shipment-detail-filters.component';
import { SingleShipmentDetailKpiComponent } from './single-shipment-detail-kpi/single-shipment-detail-kpi.component';
import { SingleShipmentItemsTableComponent } from './single-shipment-items-table/single-shipment-items-table.component';
import { SingleShipmentAccordionComponent } from './single-shipments-accordion/single-shipment-accordion.component';
import { SharedModule } from '../../shared.module';
import { SingleShipmentCartonsComponent } from './single-shipment-cartons/single-shipment-cartons.component';
import { CartonItemsTableComponent } from './single-shipment-cartons/carton-items-table/carton-items-table.component';
import { TransitWarehouseModalComponent } from './transit-warehouse-modal/transit-warehouse-modal.component';
import { SingleShipmentPalletsComponent } from './single-shipment-pallets/single-shipment-pallets.component';
import { SendDocumentsModalComponent } from './send-documents-modal/send-documents-modal.component';
import { ManualInvoiceModalComponent } from './manual-invoice-modal/manual-invoice-modal.component';
// eslint-disable-next-line
import { SupplierClaimDetailModalComponent } from './single-shipment-detail-kpi/supplier-claim-details-modal/supplier-claim-detail-modal.component';

@NgModule({
  imports: [SharedModule],
  declarations: [
    SingleShipmentDetailComponent,
    EditCarrierModalComponent,
    ManualInvoiceModalComponent,
    EditPackagingModalComponent,
    SingleShipmentDetailFiltersComponent,
    SingleShipmentDetailKpiComponent,
    SingleShipmentItemsTableComponent,
    SingleShipmentAccordionComponent,
    SingleShipmentCartonsComponent,
    SingleShipmentPalletsComponent,
    CartonItemsTableComponent,
    SendDocumentsModalComponent,
    SupplierClaimDetailModalComponent
  ],
  exports: [
    SingleShipmentDetailComponent,
    SingleShipmentDetailFiltersComponent,
    SingleShipmentDetailKpiComponent,
    SingleShipmentItemsTableComponent,
    SingleShipmentAccordionComponent,
    SingleShipmentCartonsComponent,
    SingleShipmentPalletsComponent,
    CartonItemsTableComponent,
  ],
  providers: [],
  entryComponents: [
    EditCarrierModalComponent,
    ManualInvoiceModalComponent,
    EditPackagingModalComponent,
    TransitWarehouseModalComponent,
    SendDocumentsModalComponent,
    SupplierClaimDetailModalComponent
  ],
})
export class SingleShipmentDetailsModule {}
