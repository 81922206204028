import { JsonObject, JsonProperty } from 'json2typescript';
import { PaymentRequest } from './payment-request-model';
import { Status, StatusConverter } from './status-model';

@JsonObject('PaymentAdjustment')
export class PaymentAdjustment {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('type', StatusConverter, true) type: Status = undefined;
  @JsonProperty('value', Number, true) value: number = undefined;
  @JsonProperty('paymentRequest', PaymentRequest, true) paymentRequest: PaymentRequest = undefined;
  @JsonProperty('causal', String, true) causal: string = undefined;
}
