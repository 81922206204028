import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { IncomingDateModalComponent } from '../../../../container/modal/incoming-date-modal/incoming-date-modal.component';
import { isNil } from '../../../../core/utils.service';
import { ShipmentFilters } from '../../../../models/filters/shipment-filters-model';
import { Shipment } from '../../../../models/shipment-model';
import { addCheckers } from '../../../../shared/checkers.service';
import { ImportExportService } from '../../../../shared/import-export.service';
import { LotsService } from '../../../../shared/lots.service';
import { ShipmentsService } from '../../../../shared/shipments.service';
import { GenericConfirmationModalComponent } from '../../../modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { LotEditService } from '../../lot-edit.service';

@Component({
  selector: 'app-shipments-list',
  templateUrl: './shipments-list.component.html',
  styleUrls: ['./shipments-list.component.css'],
})
export class ShipmentsListComponent implements OnInit {
  constructor(
    private lotsService: LotsService,
    private dialog: MatDialog,
    private shipmentsService: ShipmentsService,
    private router: Router,
    private importExportService: ImportExportService,
    private lotEditService: LotEditService,
    private snackBar: MatSnackBar
  ) {}

  dataSource: MatTableDataSource<Shipment>;

  displayedColumns = [
    'check',
    'shipmentCode',
    'orderId',
    'statusCheck',
    'origin',
    'extra',
    'crossUkBorder',
    'status',
    'paymentStatus',
    'backorder',
    'category',
    'totalAcceptedQuantity',
    'totalAcceptedValue',
    'totalAcceptedPurchasePrice',
    'totalVirtualAcceptedPurchasePrice',
    'totalShippedQuantity',
    'totalShippedValue',
    'totalPurchasePrice',
    'totalVirtualPurchasePrice',
    'margin',
    'percentMargin',
    'virtualMargin',
    'virtualPercentMargin',
    'vendor',
    'destination',
    'latestDeliveryDate',
    'latestPickupDate',
    'requestForPayment',
    'requestForPaymentDate',
    'payment',
    'paymentDate',
    'numberOfCartons',
    'numberOfPallets',
    'weight',
    'shipmentDate',
    'asnCode',
    'sentAsn',
    'invoiceNumber',
    'carrierContract',
    'trackingNumber',
    'expectedDeliveryDate',
    'earliestDeliveryDate',
    'deliveryDate',
    'ddtNumber',
    'hasTransit',
    'ukIntercoInvoice',
    'ukIntercoInvoiceValidData',
    'ukIntercoInvoiceStatus',
  ];

  mainChecker = false;

  bulkActionsActive = false;

  dataShipments: any[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    this.resultsNumber = this.shipmentsService.resultsNumber;

    this.lotsService.getLot().subscribe();

    if (this.lotEditService.shipmentFilters === undefined) {
      this.lotEditService.shipmentFilters = new ShipmentFilters();
    }
    this.resetCheckers();
    this.dataSource = new MatTableDataSource([]);
    this.loadShipments(this.lotEditService.shipmentFilters);
  }

  loadShipments(filters: ShipmentFilters) {
    this.lotEditService.shipmentFilters.lotId = this.lotsService.currentLotId as any;
    this.shipmentsService.getFilteredShipments(filters).subscribe((ship: Shipment[]) => {
      this.dataShipments = addCheckers(ship, this.lotEditService.selectedShipments, this.mainChecker);
      this.dataSource.data = this.dataShipments;
    });
  }

  loadPage() {
    this.lotEditService.shipmentFilters.pageNumber = this.paginator.pageIndex;
    this.lotEditService.shipmentFilters.pageSize = this.paginator.pageSize;
    this.loadShipments(this.lotEditService.shipmentFilters);
  }

  onSort() {
    this.lotEditService.shipmentFilters.sortBy = this.sort.active;
    this.lotEditService.shipmentFilters.sortDirection = this.sort.direction;
    this.loadShipments(this.lotEditService.shipmentFilters);
  }

  onSelected(value: boolean, selectedShipment: any) {
    const selectedIndex = this.dataShipments.indexOf(selectedShipment);
    this.dataShipments[selectedIndex].checked = value;
    if (value !== this.mainChecker) {
      this.lotEditService.addShipmentIdToSelected(this.dataShipments[selectedIndex].id);
    } else {
      this.lotEditService.removeShipmentIdFromSelected(this.dataShipments[selectedIndex].id);
    }
    this.bulkActionsActive = value || this.mainChecker;
  }

  toggleAll(value: boolean) {
    this.mainChecker = value;
    this.lotEditService.shipmentMainChecker = this.mainChecker;
    for (const i of this.dataShipments) {
      i.checked = value;
    }

    this.lotEditService.selectedShipments = [];
    this.bulkActionsActive = value;
  }

  applyFilter(filters: ShipmentFilters) {
    this.paginator.pageIndex = 0;
    this.dataShipments = [];
    this.resetCheckers();
    this.loadShipments(filters);
  }

  changePaymentStatus(status: string) {
    this.shipmentsService
      .changePaymentStatus(
        this.lotsService.currentLotId,
        status,
        this.lotEditService.selectedShipments,
        this.lotEditService.shipmentFilters,
        this.mainChecker
      )
      .subscribe(() => {
        this.applyFilter(this.lotEditService.shipmentFilters);
      });
  }

  openDetails(shipmentId) {
    this.router.navigate(['lot-edit', this.lotsService.currentLotId, 'shipments', shipmentId]);
  }

  resetCheckers() {
    this.lotEditService.selectedShipments = [];
    this.mainChecker = false;
    this.bulkActionsActive = false;
  }

  export(event: { format: string; onlyHeaders: boolean }) {
    this.importExportService
      .exportShipments(
        this.lotEditService.selectedShipments,
        event.format,
        this.mainChecker,
        this.lotEditService.shipmentFilters,
        event.onlyHeaders
      )
      .subscribe((resp) => {
        console.log(resp);
      });
  }

  changeIncomingDate() {
    const dialogRef = this.dialog.open(IncomingDateModalComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe((incomingDate: Date) => {
      if (!isNil(incomingDate)) {
        this.shipmentsService
          .changeIncomingDate(
            this.lotsService.currentLotId,
            incomingDate,
            this.lotEditService.selectedShipments,
            this.lotEditService.shipmentFilters,
            this.mainChecker
          )
          .subscribe(() => {
            this.applyFilter(this.lotEditService.shipmentFilters);
          });
      } else {
      }
    });
  }

  clearUnorderedShipments() {
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '400px',
      data: 'All shipments with ordered quantity to 0 will be deleted.',
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.shipmentsService.clearUnorderedShipments(this.lotsService.currentLotId).subscribe((size: number) => {
          this.snackBar.open('Deleted ' + size + ' shipments', 'CLOSE')._dismissAfter(4000);
          this.applyFilter(this.lotEditService.shipmentFilters);
        });
      }
    });
  }
}
