import { AfterViewInit, Component, OnInit, Output } from '@angular/core';
import { LotEditService } from '../../lot-edit.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { Supplier } from '../../../../models/supplier-model';
import { LotsService } from '../../../../shared/lots.service';
import { MatChipsData } from '../../../../models/mat-chips-data-model';
import { SupplierAvailabilityService } from '../supplier-availability.service';
import { lsaFiltersMap, SupplierAvailabilityFilters } from '../../../../models/filters/suppliers-availability-filters-model';
import { isNilty } from '../../../../core/utils.service';
import { SposService } from '../../../../shared/spos.service';

@Component({
  selector: 'app-suppliers-availabilities-filters',
  templateUrl: './supplier-availabilities-filters.component.html',
  styleUrls: ['./supplier-availabilities-filters.component.css'],
})
export class SupplierAvailabilitiesFiltersComponent implements OnInit, AfterViewInit {
  constructor(
    private availabilityService: SupplierAvailabilityService,
    private sposService: SposService,
    private lotEditService: LotEditService,
    private lotsService: LotsService
  ) {}

  suppliers: Supplier[] = [];

  @Output() onFilter = new Subject<SupplierAvailabilityFilters>();

  filterForm: FormGroup;

  public openFilter = false;

  filtersMap: MatChipsData[] = [];

  ngOnInit() {
    this.filterForm = new FormGroup({
      ean: new FormControl(),
      supplier: new FormControl(),
    });
    if (this.lotEditService.lsaFilters !== undefined) {
      this.filterForm.patchValue(this.lotEditService.lsaFilters);
      this.updateFiltersMap();
    }

    this.sposService.getSuppliersFromSpos(this.lotsService.currentLotId).subscribe((suppliersResp: Supplier[]) => {
      this.suppliers = suppliersResp;
      this.setSupplier();
      this.updateFiltersMap();
    });
    this.updateFiltersMap();
  }

  setSupplier() {
    if (this.lotEditService.lsaFilters && this.lotEditService.lsaFilters.supplierId) {
      this.filterForm.controls['supplier'].patchValue(this.suppliers.find((it) => it.id === this.lotEditService.lsaFilters.supplierId));
    }
  }

  ngAfterViewInit() {
    this.updateFiltersMap();
  }

  saveFiltersToService() {
    if (isNilty(this.lotEditService.lsaFilters)) {
      this.lotEditService.lsaFilters = new SupplierAvailabilityFilters();
    }
    this.lotEditService.lsaFilters.ean = this.filterForm.value.ean;
    this.lotEditService.lsaFilters.supplierId = this.filterForm.value.supplier ? this.filterForm.value.supplier.id : null;
    this.lotEditService.lsaFilters.lotId = this.lotsService.currentLotId;
    this.lotEditService.lsaFilters.pageNumber = 0;
    this.lotEditService.lsaFilters.pageSize = 10;
    this.lotEditService.lsaFilters.sortDirection = null;
    this.lotEditService.lsaFilters.sortBy = null;
  }

  onSubmitFilter() {
    this.saveFiltersToService();
    this.onFilter.next(this.lotEditService.lsaFilters);
    this.updateFiltersMap();
  }

  resetFilters() {
    this.filterForm.reset();
    this.saveFiltersToService();
    this.onFilter.next(this.lotEditService.lsaFilters);
    this.updateFiltersMap();
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeEvent() {
    this.openFilter = false;
  }

  updateFiltersMap() {
    this.filtersMap = lsaFiltersMap(this.filterForm.value.ean, this.filterForm.value.supplier);
  }
}
