import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { WarehouseFilters } from '@models/filters/warehouse-filters-model';
import { Warehouse } from '@models/warehouse-model';
import { WarehousesService } from '../warehouses.service';
import { addCheckers } from '@shared/checkers.service';

@Component({
  selector: 'app-warehouse-list',
  templateUrl: './warehouse-list.component.html',
  styleUrls: ['./warehouse-list.component.css'],
})
export class WarehouseListComponent implements OnInit {
  constructor(private warehousesService: WarehousesService) {}

  dataSource: MatTableDataSource<Warehouse>;

  displayedColumns = [
    'name',
    'code',
    'marketplace',
    'glnCode',
    'brandonGln',
    'minimalValue',
    'address',
    'city',
    'region',
    'country',
    'phone',
    'edit',
  ];

  mainChecker = false;
  dataWarehouses: any[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    if (this.warehousesService.warehouseFilters === null) {
      this.warehousesService.warehouseFilters = new WarehouseFilters();
    }
    this.dataSource = new MatTableDataSource([]);
    this.loadWarehouses(this.warehousesService.warehouseFilters);
  }

  loadWarehouses(filters: WarehouseFilters) {
    this.warehousesService.getFilteredWarehouses(filters).subscribe((warehouses: Warehouse[]) => {
      this.dataWarehouses = addCheckers(warehouses, this.warehousesService.selectedWarehouses, this.mainChecker);

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = this.dataWarehouses;
    });
  }

  onFilter(filters: WarehouseFilters) {
    this.paginator.pageIndex = 0;
    this.loadWarehouses(filters);
  }
}
