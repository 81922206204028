import { Component, OnInit } from '@angular/core';
import { FulfilmentGenerationStep, FulfilmentJobStatus } from '../../../../models/job-status/fulfilment-job-status-model';
import { GenericProgressBarDirective } from '../generic-progress-bar';

@Component({
  selector: 'app-fulfilment-job-progress',
  templateUrl: './fulfilment-job-progress.component.html',
  styleUrls: ['./fulfilment-job-progress.component.scss'],
})
export class LotFulfilmentJobProgressComponent extends GenericProgressBarDirective implements OnInit {
  oldStatus: FulfilmentJobStatus;
  jobStatus: FulfilmentJobStatus;

  TOTAL_STEPS = 8;
  FULFILMENT_GENERATION_STEP = FulfilmentGenerationStep;

  constructor() {
    super();
  }

  ngOnInit() {
    this.initProgressBar();
  }

  getInternalJobStatus(job: FulfilmentJobStatus): string {
    return job?.fulfilmentStatus;
  }
}
