import { Injectable, isDevMode } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConverterService } from '../../../core/converter.service';
import { Observable } from 'rxjs';
import { ConfigurationParam, ConfigurationParamsResponse } from '../../../models/configuration-params-model';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../../../environment.service';
import { DateRange } from '../../../models/date-range-model';

@Injectable()
export class ConfigurationParamsService {
  constructor(private converter: ConverterService, private http: HttpClient, private environmentService: EnvironmentService) {}

  getConfigurationParamTypes(): Observable<ConfigurationParamsResponse> {
    return this.http
      .get(this.environmentService.getRestEndpoint('configurationParams'))
      .pipe(map((resp: ConfigurationParamsResponse) => this.converter.fromJSONtoObj(resp, ConfigurationParamsResponse)));
  }

  saveConfigurationParams(c: ConfigurationParam[]): Observable<ConfigurationParamsResponse> {
    const body = this.converter.fromObjtoJSON(c);
    return this.http
      .post(this.environmentService.getRestEndpoint('configurationParams'), body)
      .pipe(map((resp: ConfigurationParamsResponse) => this.converter.fromJSONtoObj(resp, ConfigurationParamsResponse)));
  }

  deleteConfiguration(id: number): Observable<ConfigurationParamsResponse> {
    let path = this.environmentService.getRestEndpoint('configurationParams');
    if (!isDevMode()) {
      path += '/' + id;
    }
    return this.http
      .delete(path)
      .pipe(map((resp: ConfigurationParamsResponse) => this.converter.fromJSONtoObj(resp, ConfigurationParamsResponse)));
  }

  getBrandonClosingDays(): Observable<DateRange[]> {
    const path = this.environmentService.getRestEndpoint('brandonClosingDays');
    return this.http.get(path).pipe(map((resp: DateRange[]) => resp.map((it) => this.converter.fromJSONtoObj(it, DateRange))));
  }

  saveBrandonClosingDays(closingDays: DateRange[]): Observable<void> {
    const path = this.environmentService.getRestEndpoint('brandonClosingDays');
    return this.http.post(path, this.converter.fromObjtoJSON(closingDays)).pipe(map(() => {}));
  }
}
