import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConverterService } from '../core/converter.service';
import { isNilty } from '../core/utils.service';
import { EnvironmentService } from '../environment.service';
import { Carton } from '../models/carton-model';
import { Category } from '../models/category-model';
import { Contract } from '../models/contract.model';
import { fromDateToString } from '../models/date-converter';
import { ShipmentFilters } from '../models/filters/shipment-filters-model';
import { UpdateShippedInputModel } from '../models/filters/update-shipped-input-model';
import { FulfilmentKpi } from '../models/fulfilment-kpi';
import { Lot } from '../models/lot-model';
import { Pallet } from '../models/pallet-model';
import { SendShipmentDocumentInput, ShipmentDocument } from '../models/shipment-documents-model';
import { ShipmentItem } from '../models/shipment-item-model';
import { ChangeCarrierPropertiesInput, CheckTrackingNumberInput, Shipment } from '../models/shipment-model';
import { Status } from '../models/status-model';
import { StockLocation } from '../models/stock-location-model';
import { Supplier } from '../models/supplier-model';
import { ShipmentsKpi } from '@models/shipments-kpi';
import { ShipmentsDetailsKpi } from '@models/shipments-details-kpi';

@Injectable()
export class ShipmentsService {
  constructor(
    private http: HttpClient,
    private converter: ConverterService,
    private snackBar: MatSnackBar,
    private environmentService: EnvironmentService
  ) {}

  shipments: Shipment[] = [];
  shipmentItems: ShipmentItem[] = [];

  resultsNumber = new Subject<number>();
  cartonsResultsNumber = new Subject<number>();
  palletsResultsNumber = new Subject<number>();

  totalShipments: number;
  totalShipmentItems: number;
  totalCartons: number;
  totalPallets: number;

  shipmentFilters: ShipmentFilters = undefined;

  selectedShipments: number[] = [];
  selectedShipmentItems: number[] = [];

  shipmentMainChecker = false;

  lots: Lot[];

  kpiData = new Subject<FulfilmentKpi>();
  shipKpiData = new Subject<ShipmentsKpi>();
  shipDetailsKpiData = new Subject<ShipmentsDetailsKpi>();

  addShipmentIdToSelected(id: number) {
    this.selectedShipments.push(id);
    this.selectedShipments.filter((v, i, a) => a.indexOf(v) === i);
  }
  removeShipmentIdFromSelected(id: number) {
    const index = this.selectedShipments.indexOf(id);
    this.selectedShipments.splice(index, 1);
  }

  addShipmentItemIdToSelected(id: number) {
    this.selectedShipmentItems.push(id);
    this.selectedShipmentItems.filter((v, i, a) => a.indexOf(v) === i);
  }
  removeShipmentItemIdFromSelected(id: number) {
    const index = this.selectedShipmentItems.indexOf(id);
    this.selectedShipmentItems.splice(index, 1);
  }

  getFilteredShipments(filters: ShipmentFilters): Observable<Shipment[]> {
    const path = this.environmentService.getRestEndpoint('shipments');
    const body = this.converter.fromObjtoJSON(filters);

    return this.http.post(path, body, { observe: 'response' }).pipe(
      map((resp) => {
        this.totalShipments = +resp.headers.get('Total-Length');
        this.resultsNumber.next(this.totalShipments);
        this.getShipmentsKpiFromHeaders(resp.headers);
        return resp.body;
      }),
      map((shipmentsResp: Shipment[]) => {
        this.shipments = [];
        for (let i = 0; i < shipmentsResp.length; i++) {
          this.shipments[i] = this.converter.fromJSONtoObj(shipmentsResp[i], Shipment);
        }
        return this.shipments;
      })
    );
  }

  getFilteredShipmentItems(filters: ShipmentFilters): Observable<ShipmentItem[]> {
    const path = this.environmentService.getRestEndpoint('shipmentItems');
    const body = this.converter.fromObjtoJSON(filters);

    return this.http.post(path, body, { observe: 'response' }).pipe(
      map((resp) => {
        this.totalShipmentItems = +resp.headers.get('Total-Length');
        this.resultsNumber.next(this.totalShipmentItems);
        this.getShipmentsDetailsKpiFromHeaders(resp.headers);
        return resp.body;
      }),
      map((shipmentsResp: ShipmentItem[]) => {
        this.shipmentItems = [];
        for (let i = 0; i < shipmentsResp.length; i++) {
          this.shipmentItems[i] = this.converter.fromJSONtoObj(shipmentsResp[i], ShipmentItem);
        }
        return this.shipmentItems;
      })
    );
  }

  getSuppliersFromShipments(lotId: number): Observable<Supplier[]> {
    let path = this.environmentService.getRestEndpoint('suppliersFromShipments');

    if (!this.environmentService.dev) {
      path += '?lot=' + lotId;
    }

    return this.http.get(path).pipe(map((suppliers: Supplier[]) => suppliers.map((it) => this.converter.fromJSONtoObj(it, Supplier))));
  }

  getStockLocationsFromShipments(lotId: number): Observable<StockLocation[]> {
    let path = this.environmentService.getRestEndpoint('stockLocationsFromShipments');

    if (!this.environmentService.dev) {
      path += '?lot=' + lotId;
    }

    return this.http
      .get(path)
      .pipe(map((stockLocations: StockLocation[]) => stockLocations.map((it) => this.converter.fromJSONtoObj(it, StockLocation))));
  }

  getShipment(shipmentId: number): Observable<Shipment> {
    let path = this.environmentService.getRestEndpoint('singleShipment');

    if (!this.environmentService.dev) {
      path += '/' + shipmentId;
    }

    return this.http.get(path).pipe(map((resp: Shipment) => this.converter.fromJSONtoObj(resp, Shipment)));
  }

  getKpiFromHeaders(header: HttpHeaders) {
    const kpi = new FulfilmentKpi();
    kpi.submittedQuantity = +header.get('submittedQuantity');
    kpi.submittedValue = +header.get('submittedValue');
    kpi.requestedQuantity = +header.get('requestedQuantity');
    kpi.requestedValue = +header.get('requestedValue');
    kpi.acceptedQuantity = +header.get('acceptedQuantity');
    kpi.acceptedValue = +header.get('acceptedValue');
    kpi.shippedQuantity = +header.get('shippedQuantity');
    kpi.shippedValue = +header.get('shippedValue');
    this.kpiData.next(kpi);
  }

  getShipmentsKpiFromHeaders(header: HttpHeaders) {
    const kpi = new ShipmentsKpi();
    kpi.submittedQuantity = +header.get('submittedQuantity');
    kpi.submittedValue = +header.get('submittedValue');
    kpi.requestedQuantity = +header.get('requestedQuantity');
    kpi.requestedValue = +header.get('requestedValue');
    kpi.acceptedQuantity = +header.get('acceptedQuantity');
    kpi.acceptedValue = +header.get('acceptedValue');
    kpi.purchasePrice = +header.get('purchasePrice');
    this.shipKpiData.next(kpi);
  }

  getShipmentsDetailsKpiFromHeaders(header: HttpHeaders) {
    const kpi = new ShipmentsDetailsKpi();
    kpi.submittedQuantity = +header.get('submittedQuantity');
    kpi.submittedValue = +header.get('submittedValue');
    kpi.requestedQuantity = +header.get('requestedQuantity');
    kpi.requestedValue = +header.get('requestedValue');
    kpi.acceptedQuantity = +header.get('acceptedQuantity');
    kpi.acceptedValue = +header.get('acceptedValue');
    kpi.shippedQuantity = +header.get('shippedQuantity');
    kpi.shippedValue = +header.get('shippedValue');
    this.shipDetailsKpiData.next(kpi);
  }

  changePaymentStatus(lotId: number, status: string, pos: number[], filters: ShipmentFilters, mainChecker: boolean) {
    if (mainChecker) {
      return this.changeShipmentsPaymentStatusFromFilters(status, filters);
    } else {
      return this.changeShipmentsPaymentStatus(status, pos);
    }
  }

  changeShipmentsStatus(status: string, shipmentId: number, waitForOutstanding = false): Observable<void> {
    let path = this.environmentService.getRestEndpoint('shipments') + '/' + shipmentId + '/change-status=' + status;

    if (waitForOutstanding !== null) {
      path += '?wait-for-outstanding=' + waitForOutstanding;
    }

    const headers = new HttpHeaders().append('Content-Type', 'application/json');

    return this.http.post(path, null, { headers }).pipe(
      map(() => {
        this.snackBar.open('State changed to ' + status, 'CLOSE')._dismissAfter(2000);
        return;
      })
    );
  }

  changeShipmentsPaymentStatus(status: string, shipments: number[]): Observable<void> {
    const path = this.environmentService.getRestEndpoint('shipments') + '/change-payment-status=' + status;

    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(shipments);

    return this.http.post(path, body, { headers }).pipe(
      map(() => {
        this.snackBar.open('State changed to ' + status, 'CLOSE')._dismissAfter(2000);
        return;
      })
    );
  }

  changeShipmentsPaymentStatusFromFilters(status: string, filters: ShipmentFilters): Observable<void> {
    const path = this.environmentService.getRestEndpoint('shipments') + '/change-payment-status-from-filters=' + status;
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(filters);

    return this.http.post(path, body, { headers }).pipe(
      map(() => {
        this.snackBar.open('State changed to ' + status, 'CLOSE')._dismissAfter(2000);
        return;
      })
    );
  }

  merge(mainChecker: boolean) {
    if (mainChecker) {
      return this.mergeShipmentsFromFilters(this.shipmentFilters);
    } else {
      return this.mergeShipments(this.selectedShipments);
    }
  }

  mergeShipmentsFromFilters(filters: ShipmentFilters) {
    const path = this.environmentService.getRestEndpoint('mergeFilteredShipments');

    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(filters);

    return this.http.post(path, body, { headers }).pipe(map((response) => response));
  }

  mergeShipments(shipments: number[]) {
    const path = this.environmentService.getRestEndpoint('mergeShipments');

    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(shipments);

    return this.http.post(path, body, { headers }).pipe(map((response) => response));
  }

  generateAsn(shipmentId: number) {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(shipmentId);

    const path = this.environmentService.getRestEndpoint('generateAsn');

    return this.http.post(path, body, { headers }).pipe(map((response) => response));
  }

  singleCarton(shipmentId: number) {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(shipmentId);

    const path = this.environmentService.getRestEndpoint('singleCarton');

    return this.http.post(path, body, { headers }).pipe(map((response) => response));
  }

  changePackingProperties(shipmentId: number, pallets: number, cartons: number, weight: number, ddt: string) {
    let path = this.environmentService.getRestEndpoint('changePackingProperties');

    if (!this.environmentService.dev) {
      path +=
        '?pallets=' +
        (pallets ? pallets.toString() : 0) +
        '&cartons=' +
        (cartons ? cartons.toString() : 0) +
        '&weight=' +
        (weight ? weight.toString() : 0) +
        '&ddt=' +
        (ddt ? ddt : '');
    }

    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(shipmentId);

    return this.http.post(path, body, { headers }).pipe(map((response) => response));
  }

  changeCarrierProperties(shipmentId: number, data: ChangeCarrierPropertiesInput): Observable<any> {
    const path = this.environmentService.getRestEndpoint('changeCarrierProperties') + '/' + shipmentId;
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = this.converter.fromObjtoJSON(data);
    return this.http.post(path, body, { headers });
  }

  checkTrackingNumber(shipmentId: number, trackingNumber: string): Observable<Shipment[]> {
    const path = this.environmentService.getRestEndpoint('checkTrackingNumber');
    const body = new CheckTrackingNumberInput(shipmentId, trackingNumber);

    return this.http
      .post(path, this.converter.fromObjtoJSON(body))
      .pipe(map((r: Shipment[]) => r.map((it) => this.converter.fromJSONtoObj(it, Shipment))));
  }

  shipAll(shipmentId: number) {
    const path = this.environmentService.getRestEndpoint('shipments') + '/ship-all';

    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(shipmentId);

    return this.http.post(path, body, { headers }).pipe(map((response) => response));
  }

  resetShippedQuantities(shipmentId: number) {
    const path = this.environmentService.getRestEndpoint('shipments') + '/reset-shipped-quantities';

    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(shipmentId);

    return this.http.post(path, body, { headers }).pipe(map((response) => response));
  }

  updateShippedQuantities(s: ShipmentItem): Observable<ShipmentItem> {
    const path = this.environmentService.getRestEndpoint('updateShippedQuantities');
    const body = this.converter.fromObjtoJSON(new UpdateShippedInputModel(s.id, +s.shippedQuantity));
    return this.http.post(path, body).pipe(
      map((resp: ShipmentItem) => {
        if (isNilty(resp)) {
          return null;
        } else {
          return this.converter.fromJSONtoObj(resp, ShipmentItem);
        }
      })
    );
  }

  updatePalletHeight(shipmentId: number, pallet: Pallet): Observable<Pallet> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const path = this.environmentService.getRestEndpoint('pallets') + '/' + shipmentId + '/' + pallet.id;
    const body = JSON.stringify(pallet.height);
    return this.http.post(path, body, { headers }).pipe(
      map((resp: Pallet) => {
        if (isNilty(resp)) {
          return null;
        } else {
          return this.converter.fromJSONtoObj(resp, Pallet);
        }
      })
    );
  }

  printASNBarcode(shipmentId: number): Observable<any> {
    const path = isDevMode()
      ? this.environmentService.getRestEndpoint('generateASNBarcode')
      : this.environmentService.getRestEndpoint('generateASNBarcode') + '?shipmentId=' + shipmentId;
    // eslint-disable-next-line
    return this.http.get(path, { responseType: 'blob', headers: { Accept: 'application/octet-stream' } });
  }

  cancelASN(shipmentId: number): Observable<any> {
    const path = isDevMode()
      ? this.environmentService.getRestEndpoint('cancelASN')
      : this.environmentService.getRestEndpoint('cancelASN') + '?shipmentId=' + shipmentId;
    return this.http.get(path);
  }

  printSSCCBarcode(cartonId: number): Observable<any> {
    const path = isDevMode()
      ? this.environmentService.getRestEndpoint('generateSSCCBarcode')
      : this.environmentService.getRestEndpoint('generateSSCCBarcode') + '?cartonId=' + cartonId;
    return this.http.get(path);
  }

  printAllSSCCBarcode(shipmentId: number): Observable<any> {
    const path = isDevMode()
      ? this.environmentService.getRestEndpoint('generateAllSSCCBarcode')
      : this.environmentService.getRestEndpoint('generateAllSSCCBarcode') + '?shipmentId=' + shipmentId;
    // eslint-disable-next-line
    return this.http.get(path, { responseType: 'blob', headers: { Accept: 'application/octet-stream' } });
  }

  printLQLabels(shipmentId: number): Observable<any> {
    const path = this.environmentService.getRestEndpoint('generateLqLabels') + '?shipmentId=' + shipmentId;
    // eslint-disable-next-line
    return this.http.get(path, { responseType: 'blob', headers: { Accept: 'application/octet-stream' } });
  }

  printSSCCAndLdvLabel(cartonId: number): Observable<any> {
    const path = this.environmentService.getRestEndpoint('generateSSCCAndLDVLabel') + '?cartonId=' + cartonId;
    return this.http.get(path, { responseType: 'blob', headers: { Accept: 'application/octet-stream' } });
  }

  printAllSSCCAndLdvLabel(shipmentId: number): Observable<any> {
    const path = this.environmentService.getRestEndpoint('generateAllSSCCAndLDVLabel') + '?shipmentId=' + shipmentId;
    return this.http.get(path, { responseType: 'blob', headers: { Accept: 'application/octet-stream' } });
  }

  getSingleFilteredShipmentItems(filters: ShipmentFilters, shipmentId: number): Observable<ShipmentItem[]> {
    const path = isDevMode()
      ? this.environmentService.getRestEndpoint('shipmentItems')
      : this.environmentService.getRestEndpoint('shipmentItems') + '/' + shipmentId;
    const body = this.converter.fromObjtoJSON(filters);
    return this.http.post(path, body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.totalShipmentItems = +resp.headers.get('Total-Length');
        this.resultsNumber.next(this.totalShipmentItems);
        this.getKpiFromHeaders(resp.headers);
        return resp.body;
      }),
      map((resp: ShipmentItem[]) => resp.map((it) => this.converter.fromJSONtoObj(it, ShipmentItem)))
    );
  }

  getShipmentCartons(shipmentId: number, filters: ShipmentFilters): Observable<Carton[]> {
    const path = isDevMode()
      ? this.environmentService.getRestEndpoint('cartons')
      : this.environmentService.getRestEndpoint('cartons') + '/' + shipmentId;
    const body = this.converter.fromObjtoJSON(filters);
    return this.http.post(path, body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.totalCartons = +resp.headers.get('Total-Length');
        this.cartonsResultsNumber.next(this.totalCartons);
        return resp.body;
      }),
      map((resp: Carton[]) => resp.map((it) => this.converter.fromJSONtoObj(it, Carton)))
    );
  }

  getShipmentPallets(shipmentId: number, filters: ShipmentFilters): Observable<Pallet[]> {
    const path = this.environmentService.getRestEndpoint('pallets') + '/' + shipmentId;
    const body = this.converter.fromObjtoJSON(filters);
    return this.http.post(path, body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.totalPallets = +resp.headers.get('Total-Length');
        this.palletsResultsNumber.next(this.totalPallets);
        return resp.body;
      }),
      map((resp: Pallet[]) => resp.map((it) => this.converter.fromJSONtoObj(it, Pallet)))
    );
  }

  splitShipment(selectedShipmentItems: number[], shipmentId: number, mainChecker: boolean, filters: ShipmentFilters) {
    if (mainChecker) {
      return this.splitShipmentFromFilters(shipmentId, filters);
    } else {
      return this.splitShipmentFromIds(selectedShipmentItems, shipmentId);
    }
  }

  private splitShipmentFromIds(selectedShipmentItems: number[], shipmentId: number): Observable<any> {
    const path = this.environmentService.dev
      ? this.environmentService.getRestEndpoint('splitShipmentFromIds')
      : this.environmentService.getRestEndpoint('splitShipmentFromIds') + '/' + shipmentId;
    const headers = new HttpHeaders().append('Content-Type', 'application/json');

    const body = JSON.stringify(selectedShipmentItems);
    return this.http.post(path, body, { headers });
  }

  private splitShipmentFromFilters(shipmentId: number, filters: ShipmentFilters): Observable<any> {
    const path = this.environmentService.dev
      ? this.environmentService.getRestEndpoint('splitShipmentFromFiltersMock')
      : this.environmentService.getRestEndpoint('splitShipmentFromFilters') + '/' + shipmentId;

    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = this.converter.fromObjtoJSON(filters);

    return this.http.post(path, body, { headers });
  }

  sendToBridget(shipmentId: number) {
    const path = this.environmentService.getRestEndpoint('sendToBridget');
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(shipmentId);

    return this.http.post(path, body, { headers });
  }

  deleteFromBridget(shipmentId: number) {
    const path = this.environmentService.getRestEndpoint('cancelFromBridget');
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(shipmentId);

    return this.http.post(path, body, { headers });
  }

  setTransitWarehouse(shipmentId: number, transitWarehouseId: number) {
    const path = this.environmentService.getRestEndpoint('setTransitWarehouse').replace('{shipmentId}', shipmentId.toString());

    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(transitWarehouseId);

    return this.http.post(path, body, { headers });
  }

  getContracts(shipmentId: number): Observable<Contract[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('contracts') + '/' + shipmentId)
      .pipe(map((contractsResp: Contract[]) => contractsResp.map((contract) => this.converter.fromJSONtoObj(contract, Contract))));
  }

  getAllContracts(): Observable<Contract[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('contracts'))
      .pipe(map((contractsResp: Contract[]) => contractsResp.map((contract) => this.converter.fromJSONtoObj(contract, Contract))));
  }

  getShipmentPaymentStatus(): Observable<Status[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('shipments') + '/payment-status')
      .pipe(map((status: Status[]) => status.map((s) => this.converter.fromJSONtoObj(s, Status))));
  }

  getShipmentDocuments(): Observable<ShipmentDocument[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('shipmentDocumentsList'))
      .pipe(map((resp: ShipmentDocument[]) => resp.map((it) => this.converter.fromJSONtoObj(it, ShipmentDocument))));
  }

  sendShipmentDocuments(input: SendShipmentDocumentInput): Observable<void> {
    const body = this.converter.fromObjtoJSON(input);
    return this.http.post(this.environmentService.getRestEndpoint('sendDocuments'), body).pipe(map(() => {}));
  }

  setOrigin(shipmentId: number, origin: number) {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(origin);

    return this.http.post(this.environmentService.getRestEndpoint('originShipment') + '/' + shipmentId, body, { headers });
  }

  setCategory(shipmentId: number) {
    return this.http.post(this.environmentService.getRestEndpoint('caterogyShipment') + '/' + shipmentId, null);
  }

  changeIncomingDate(lotId: number, incomingDate: Date, pos: number[], filters: ShipmentFilters, mainChecker: boolean) {
    if (mainChecker) {
      return this.changeShipmentsIncomingDateFromFilters(incomingDate, filters);
    } else {
      return this.changeShipmentsIncomingDate(incomingDate, pos);
    }
  }

  changeShipmentsIncomingDateFromFilters(incomingDate: Date, filters: ShipmentFilters): Observable<void> {
    const path =
      this.environmentService.getRestEndpoint('shipments') + '/change-incoming-date-from-filters=' + fromDateToString(incomingDate, '-');
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = this.converter.fromObjtoJSON(filters);

    return this.http.post(path, body, { headers }).pipe(
      map(() => {
        this.snackBar.open('Incoming date changed to ' + incomingDate.getDate(), 'CLOSE')._dismissAfter(2000);
        return;
      })
    );
  }

  changeShipmentsIncomingDate(incomingDate: Date, shipments: number[]): Observable<void> {
    const path = this.environmentService.getRestEndpoint('shipments') + '/change-incoming-date=' + fromDateToString(incomingDate, '-');

    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(shipments);

    return this.http.post(path, body, { headers }).pipe(
      map(() => {
        this.snackBar.open('Incoming date changed to ' + fromDateToString(incomingDate), 'CLOSE')._dismissAfter(2000);
        return;
      })
    );
  }

  clearUnorderedShipments(lotId: number): Observable<number> {
    const path = this.environmentService.getRestEndpoint('shipments') + '/clear-unordered/' + lotId;
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http
      .post(path, null, { headers, observe: 'response' })
      .pipe(map((resp: HttpResponse<any>) => +resp.headers.get('Total-Length')));
  }

  createUkIntercoInvoice(shipmentId: number): Observable<void> {
    const path = this.environmentService.getRestEndpoint('shipments') + '/create-uk-interco-invoice/' + shipmentId;
    return this.http.post<void>(path, null);
  }

  getIntercoInvoiceStatus(): Observable<Status[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('shipments') + '/interco-invoice-status')
      .pipe(map((status: Status[]) => status.map((s) => this.converter.fromJSONtoObj(s, Status))));
  }

  getOutboundStatus(): Observable<string> {
    return this.http.get(this.environmentService.getRestEndpoint('shipments') + '/outbound-status').pipe(map((string: string) => string));
  }

  generateManualInvoice(shipmentId: number, invoiceNumber: string, invoiceDate: Date): Observable<void> {
    const path = this.environmentService.getRestEndpoint('shipments') + '/generate-manual-invoice/' + shipmentId;

    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify({ invoiceNumber, invoiceDate });

    return this.http.post(path, body, { headers }).pipe(
      map(() => {
        this.snackBar.open('Manual invoice ' + invoiceNumber + ' generated', 'CLOSE')._dismissAfter(3000);
        return;
      })
    );
  }

  getAllCategories(): Observable<Category[]> {
    const path = this.environmentService.getRestEndpoint('categories') + '/all';

    return this.http.get(path).pipe(
      map((resp: Category[]) => {
        const categories = [];
        for (let i = 0; i < resp.length; i++) {
          categories[i] = this.converter.fromJSONtoObj(resp[i], Category);
        }
        return categories;
      })
    );
  }

  getShipmentsBySupplierAndPaymentStatusPending(supplierId: number): Observable<Shipment[]> {
    const path = this.environmentService.getRestEndpoint('shipments') + '/payment-pending/' + supplierId;

    return this.http.get(path).pipe(
      map((shipmentsResp: Shipment[]) => {
        this.shipments = [];
        for (let i = 0; i < shipmentsResp.length; i++) {
          this.shipments[i] = this.converter.fromJSONtoObj(shipmentsResp[i], Shipment);
        }
        return this.shipments;
      })
    );
  }

  sendToNav(shipmentId: number): Observable<any> {
    return this.http.post(this.environmentService.getRestEndpoint('shipments') + '/send-to-nav/' + shipmentId, null);
  }

  resendToNav(shipmentId: number): Observable<any> {
    return this.http.post(this.environmentService.getRestEndpoint('shipments') + '/resend-to-nav/' + shipmentId, null);
  }

  sendOutbound(shipmentId: number): Observable<any> {
    return this.http.post(this.environmentService.getRestEndpoint('shipments') + '/send-outbound/' + shipmentId, null);
  }

  automaticManagement(shipmentId: number): Observable<any> {
    return this.http.post(this.environmentService.getRestEndpoint('shipments') + '/automatic-management/' + shipmentId, null);
  }
}
