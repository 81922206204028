import { JsonObject, JsonProperty } from 'json2typescript';
import { isNilty } from '../core/utils.service';

@JsonObject('CountryHolidays')
export class CountryHolidays {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('countryCode', String, true) countryCode: string = undefined;
  @JsonProperty('countryName', String, true) countryName: string = undefined;
  @JsonProperty('holidays', [String], true) holidays: string[] = undefined;

  holidaysString: string;

  setHolidaysString() {
    if (isNilty(this.holidays)) {
      this.holidays = [];
    }
    this.holidaysString = '';
    this.holidays.forEach((it) => {
      this.holidaysString += it + '; ';
    });
  }

  setHolidays() {
    this.holidays = this.holidaysString
      .split(';')
      .map((it) => it.trim())
      .filter((it) => !isNilty(it));
    this.holidays.forEach((it) => it.trim());
  }
}
