import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConverterService } from '@core/converter.service';
import { WarehouseFilters, setFiltersFromForm } from '@models/filters/warehouse-filters-model';
import { Warehouse } from '@models/warehouse-model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentService } from 'src/app/environment.service';

@Injectable()
export class WarehousesService {
  warehouses: Warehouse[] = undefined;
  lotEditWarehouses: Warehouse[] = undefined;
  warehouseFilters: WarehouseFilters = null;
  selectedWarehouses: number[] = [];
  resultsNumber = new Subject<number>();

  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  getWarehouses(): Observable<Warehouse[]> {
    return this.http.get(this.environmentService.getRestEndpoint('warehouses')).pipe(
      map((warehousesResp: Warehouse[]) => {
        this.warehouses = [];
        for (let i = 0; i < warehousesResp.length; i++) {
          this.warehouses[i] = this.converter.fromJSONtoObj(warehousesResp[i], Warehouse);
        }
        return this.warehouses;
      })
    );
  }

  getFilteredWarehouses(filters: WarehouseFilters): Observable<Warehouse[]> {
    const body = this.converter.fromObjtoJSON(filters);

    return this.http.post(this.environmentService.getRestEndpoint('filteredWarehouses'), body, { observe: 'response' }).pipe(
      map((resp) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((wResp: Warehouse[]) => {
        const filteredW: Warehouse[] = [];
        for (let i = 0; i < wResp.length; i++) {
          filteredW[i] = this.converter.fromJSONtoObj(wResp[i], Warehouse);
        }
        return filteredW;
      })
    );
  }

  getAllCountries(): Observable<string[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('warehousesCountries'))
      .pipe(map((countriesResp: string[]) => countriesResp));
  }

  getWarehouse(id: number): Observable<Warehouse> {
    let path = this.environmentService.getRestEndpoint('warehouses');
    if (!this.environmentService.dev) {
      path += '/' + id;
    }
    return this.http.get(path).pipe(
      map((warehouse: Warehouse) => {
        if (this.environmentService.dev) {
          return this.warehouses[id - 1];
        }
        return this.converter.fromJSONtoObj(warehouse, Warehouse);
      })
    );
  }

  postWarehouse(warehouse: Warehouse) {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = this.converter.fromObjtoJSON(warehouse);
    return this.http.post(this.environmentService.getRestEndpoint('warehouses'), body, { headers }).pipe(map(() => true));
  }

  deleteWarehouse(id: number): Observable<boolean> {
    let path = this.environmentService.getRestEndpoint('warehouses');

    if (!this.environmentService.dev) {
      path += '/' + id;
    }

    return this.http.delete(path).pipe(map(() => true));
  }

  getWarehousesFromLot(lotId: number): Observable<Warehouse[]> {
    let path = this.environmentService.getRestEndpoint('warehouses');

    if (!this.environmentService.dev) {
      path += '/lot=' + lotId;
    }

    return this.http.get(path).pipe(
      map((warehousesResp: Warehouse[]) => {
        this.lotEditWarehouses = [];
        for (let i = 0; i < warehousesResp.length; i++) {
          this.lotEditWarehouses[i] = this.converter.fromJSONtoObj(warehousesResp[i], Warehouse);
        }
        return this.lotEditWarehouses;
      })
    );
  }

  saveWarehouseFiltersToService(filterForm: FormGroup) {
    setFiltersFromForm(filterForm, this.warehouseFilters);
  }
}
