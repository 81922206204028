import { Component, Input, ViewChild } from '@angular/core';
import { compareNumberOrString, isNilty } from '../../../../../core/utils.service';
import { SimplePoItem } from '../../../../../models/product-details-model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-last-pos-table',
  templateUrl: './last-pos-table.component.html',
  styleUrls: ['./last-pos-table.component.css'],
})
export class LastPosTableComponent {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() set pois(values: SimplePoItem[]) {
    if (!isNilty(values)) {
      this.dataSource = new MatTableDataSource(values);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  dataSource: MatTableDataSource<SimplePoItem>;
  displayedColumns = ['poCode', 'orderedOn', 'vendor', 'destination', 'submittedQuantity', 'acceptedQuantity', 'unitCost', 'totalCost'];

  constructor() {}

  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'vendor':
          return compareNumberOrString(a.vendor.code, b.vendor.code, isAsc);
        case 'destination':
          return compareNumberOrString(a.destination.code, b.destination.code, isAsc);
        default:
          return compareNumberOrString(a[sort.active], b[sort.active], isAsc);
      }
    });
  }
}
