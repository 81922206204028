import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { LotsService } from '../lots.service';
import { FulfilmentKpi } from '../../models/fulfilment-kpi';
import { Location } from '@angular/common';
import { Spo, SpoStatus } from '../../models/spo-model';
import { SpoFilters } from '../../models/filters/spo-filters-model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SposService } from '../spos.service';
import { MatTabGroup } from '@angular/material/tabs';
import { MatDialog } from '@angular/material/dialog';
import { GenericConfirmationModalComponent } from '../../container/modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { map } from 'rxjs/operators';
import { isNilty } from '../../core/utils.service';
import { Permissions } from '../../models/permission-model';
import { AuthorizationService } from '../../core/authorization.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonsService } from '../commons.service';
import { GenericErrorModalComponent } from '../../container/modal/generic-error-modal/generic-error-modal.component';

@Component({
  selector: 'app-single-spo-detail',
  templateUrl: './single-spo-detail.component.html',
  styleUrls: ['./single-spo-detail.component.css'],
})
export class SingleSpoDetailComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @ViewChild('spoItemsMatGroup', { static: false }) matTabGroup: MatTabGroup;

  spoId: number;

  canSendToJimbo = false;

  spo: Spo = new Spo();

  isNotInLot = false;

  filters = new SpoFilters();

  kpiData: Observable<FulfilmentKpi>;
  spoStatusArray: SpoStatus[];

  loadSpoItems = new Subject<SpoFilters>();
  exportSubject = new Subject<string>();
  deleteSpoItemsSubject = new Subject<void>();
  deleteGroupedSpoItemsSubject = new Subject<void>();

  itemsActionsActive = false;

  hasFulfillmentCreation: Observable<boolean>;
  hasShipmentCreation: Observable<boolean>;

  constructor(
    private route: ActivatedRoute,
    private sposService: SposService,
    private lotsService: LotsService,
    private location: Location,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
    private authorizationService: AuthorizationService,
    private commonsService: CommonsService
  ) {}

  ngOnInit() {
    this.commonsService.getSpoStatuses().subscribe((r) => (this.spoStatusArray = r.filter((it) => it.setFromFrontend)));

    this.isNotInLot = this.lotsService.currentLotId === undefined;
    this.kpiData = this.sposService.kpiData;
    this.hasFulfillmentCreation = this.authorizationService.hasPermission(Permissions.FulfillmentCreation);
    this.hasShipmentCreation = this.authorizationService.hasPermission(Permissions.ShipmentCreation);
    this.route.params.subscribe((param: Params) => {
      this.spoId = +param['id'];
      this.filters = new SpoFilters();
      this.filters.spoId = this.spoId;
      this.getSpo(true);
    });
  }

  getSpo(loadItems = false) {
    this.sposService.getSingleSpo(this.spoId).subscribe((resp: Spo) => {
      if (isNilty(resp)) {
        this.goBack();
      }
      this.spo = resp;
      this.canSendToJimbo = this.spo.supplier.canSendSposToJimbo();
      if (loadItems) {
        this.loadSpoItems.next(this.filters);
      }
    });
  }

  goBack() {
    this.location.back();
  }

  setPayment(payment: boolean) {
    this.sposService.setSposPayment([this.spo.id], null, false, this.spo.lot.id, payment).subscribe(() => {
      this.getSpo();
    });
  }

  changeStatus(status: number) {
    this.sposService.changeSpoStatus(status, [this.spo.id], null, false).subscribe((resp: boolean) => {
      if (resp) {
        this.getSpo();
      }
    });
  }

  generateShipment() {
    if (isNilty(this.spo.lot.firstShipmentGenerate)) {
      this.matDialog.open(GenericErrorModalComponent, {
        data: 'To generate Shipments from single SPOs you must first call Create Shipment on the corresponding Lot.',
      });
      return;
    }

    this.matDialog
      .open(GenericConfirmationModalComponent, {
        width: '350px',
        data:
          this.spo.spoStatus === 5
            ? 'Shipment associated with the current SPO will be deleted and recreated.'
            : 'SPO will be closed and shipment will be generated.\nWarning: once Closed, the SPO will not be editable anymore.',
      })
      .afterClosed()
      .subscribe((resp: boolean) => {
        if (resp) {
          const filters = new SpoFilters();
          filters.spoId = this.spo.id;
          this.sposService.closeSpoAndGenerateShipmentsFromSpo(this.spo.id, this.spo.lot.id).subscribe(() => {
            this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
            return 'Done';
          });
        }
      });
  }

  applyFilters(filters: SpoFilters) {
    this.filters = filters;
    this.filters.spoId = this.spoId;
    this.loadSpoItems.next(this.filters);
  }

  export(format: string) {
    this.exportSubject.next(format);
  }

  toggleGroupedView() {
    this.matTabGroup.selectedIndex = this.matTabGroup.selectedIndex === 0 ? 1 : 0;
  }

  deleteSpo() {
    this.confirmationDialog('The current SPO will be deleted.').subscribe((resp: boolean) => {
      if (resp) {
        this.sposService.deleteSpos([this.spoId], this.filters, false, this.lotsService.currentLotId).subscribe(() => {
          this.goBack();
        });
      }
    });
  }

  deleteSpoItems() {
    this.confirmationDialog('The selected SPO items will be deleted.').subscribe((resp: boolean) => {
      if (resp) {
        if (this.matTabGroup.selectedIndex === 0) {
          this.deleteGroupedSpoItemsSubject.next();
        } else {
          this.deleteSpoItemsSubject.next();
        }
      }
    });
  }

  confirmationDialog(message: string): Observable<boolean> {
    return this.matDialog
      .open(GenericConfirmationModalComponent, {
        width: '350px',
        data: message,
      })
      .afterClosed()
      .pipe(map((resp: boolean) => resp));
  }

  refresh() {
    this.getSpo(true);
  }

  checkItemsActionsActive(bulkActionsActive: boolean) {
    this.itemsActionsActive = bulkActionsActive;
  }

  sendSpo() {
    this.matDialog
      .open(GenericConfirmationModalComponent, {
        data: 'SPO ' + this.spo.orderId + ' will be sent to supplier ' + this.spo.supplier.code + '.',
      })
      .afterClosed()
      .subscribe((resp: boolean) => {
        if (resp) {
          this.sposService.sendSpos([this.spoId], null, false, null).subscribe();
        }
      });
  }
}
