<app-spo-filters (onFilter)="onFilter($event)"></app-spo-filters>

<app-spo-kpi></app-spo-kpi>

<app-spo-bulk-actions
  (onMerge)="merge()"
  (onDelete)="delete()"
  [bulkActionsActive]="bulkActionsActive"
  (onExport)="export($event)"
  (onSendSpos)="sendSpos()"
  (onSetPayment)="setPayment($event)"
  (onChangeStatus)="changeStatus($event)"
  (onGenerateShipments)="generateShipment()"
  [currentLot]="currentLot"
></app-spo-bulk-actions>

<div class="container table-outer">
  <mat-paginator #paginator [length]="resultsNumber | async" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()">
  </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="check" sticky>
        <mat-header-cell *matHeaderCellDef class="checker-col">
          <mat-checkbox (change)="toggleAll($event.checked)" [ngModel]="mainChecker"></mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let spo; let i = index" class="checker-col">
          <mat-checkbox (change)="onSelected($event.checked, spo)" [ngModel]="spo.checked"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="spoCode">
        <mat-header-cell class="shipment-code-column" *matHeaderCellDef mat-sort-header="spoCode">SPO code</mat-header-cell>
        <mat-cell class="shipment-code-column" *matCellDef="let spo"
          ><a (click)="openDetails(spo.id)">{{ spo.code }}</a></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="orderId" sticky>
        <mat-header-cell class="order-id-column" *matHeaderCellDef mat-sort-header="orderId">Order ID</mat-header-cell>
        <mat-cell class="order-id-column" *matCellDef="let spo">{{ spo.orderId }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="vendor">
        <mat-header-cell class="vendor-column" *matHeaderCellDef mat-sort-header="vendor"> Parent vendor </mat-header-cell>
        <mat-cell class="vendor-column" *matCellDef="let spo">{{ spo.vendor ? spo.vendor.code : '' }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="destination" sticky>
        <mat-header-cell class="destination-column" *matHeaderCellDef mat-sort-header="destination"> Destination </mat-header-cell>
        <mat-cell
          class="destination-column"
          *matCellDef="let spo"
          [matTooltip]="spo.destination ? spo.destination.code + ' - ' + spo.destination.name : ''"
          matTooltipPosition="below"
        >
          <p>{{ spo.destination ? spo.destination.code + ' - ' + spo.destination.name : '' }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="supplier" sticky>
        <mat-header-cell class="origin-column" *matHeaderCellDef mat-sort-header="supplier"> Supplier </mat-header-cell>
        <mat-cell class="origin-column" *matCellDef="let spo" [matTooltip]="spo?.supplier?.name" matTooltipPosition="below">
          <p>{{ spo?.supplier?.name }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="extra">
        <mat-header-cell class="extra-flag-column" *matHeaderCellDef> Extra </mat-header-cell>
        <mat-cell class="extra-flag-column" *matCellDef="let spo">
          <i [ngClass]="spo.isExtra | booleanIconPipe" aria-hidden="true"></i>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="crossUkBorder">
        <mat-header-cell class="extra-flag-column" *matHeaderCellDef> Cross UK </mat-header-cell>
        <mat-cell class="extra-flag-column" *matCellDef="let spo">
          <i [ngClass]="spo.crossUkBorder | booleanIconPipe" aria-hidden="true"></i>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="earliestDeliveryDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="earliestDeliveryDate" class="date-column"> Earliest Date </mat-header-cell>
        <mat-cell class="date-column" *matCellDef="let spo">
          <p>{{ spo.earliestDeliveryDate }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="latestDeliveryDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="latestDeliveryDate" class="date-column"> Latest Date </mat-header-cell>
        <mat-cell class="date-column" *matCellDef="let spo">
          <p>{{ spo.latestDeliveryDate }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="latestPickupDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="latestPickupDate" class="date-column"> Latest Pickup Date </mat-header-cell>
        <mat-cell class="date-column" *matCellDef="let spo">
          <p>{{ spo.latestPickupDate }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="requestedQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="requestedQuantity">
          Requested quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let spo">
          <p>{{ spo.requestedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="confirmedQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="confirmedQuantity">
          Confirmed quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let spo">
          <p>{{ spo.confirmedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="orderedQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="orderedQuantity">
          Ordered quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let spo">
          <p>{{ spo.orderedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="margin">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="margin"> Margin </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.margin | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="requestedValue">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="requestedValue"> Requested value </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.requestedValue | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="requestedPurchasePrice">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="requestedPurchasePrice">
          Requested purchase price
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.requestedPurchasePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="virtualRequestedPurchasePrice">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="virtualRequestedPurchasePrice">
          Virtual Requested purchase price
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.virtualRequestedPurchasePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="confirmedValue">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="confirmedValue"> Confirmed value </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.confirmedValue | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="confirmedPurchasePrice">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="confirmedPurchasePrice">
          Confirmed purchase price
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.confirmedPurchasePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="virtualConfirmedPurchasePrice">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="virtualConfirmedPurchasePrice">
          Virtual Confirmed purchase price
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.virtualConfirmedPurchasePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="orderedValue">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="orderedValue"> Ordered value </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.orderedValue | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="orderedPurchasePrice">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="orderedPurchasePrice">
          Ordered purchase price
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.orderedPurchasePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="virtualOrderedPurchasePrice">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="virtualOrderedPurchasePrice">
          Virtual Ordered purchase price
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.virtualOrderedPurchasePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell class="shipment-status-column" *matHeaderCellDef mat-sort-header="status"> Status </mat-header-cell>
        <mat-cell class="shipment-status-column" *matCellDef="let spo">
          <p>{{ spo.spoStatus | spoStatusPipe }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="virtualConfirmedMargin">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="virtualConfirmedMargin">
          Virtual Confirmed Margin
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.virtualConfirmedMargin | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="virtualConfirmedPercentMargin">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="virtualConfirmedPercentMargin">
          Virtual Confirmed margin %
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let spo">
          <p>{{ spo.virtualConfirmedPercentMargin | percentageFromDecimal }}</p>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
