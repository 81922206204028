<div [ngClass]="{ 'black-panel': openFilter, panel: !openFilter }">
  <mat-expansion-panel class="mat-elevation-z8 filter-position" [attr.expanded]="openFilter" (opened)="openEvent()" (closed)="closeevent()">
    <mat-expansion-panel-header class="filt-custom">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-9">
            <mat-chip-list>
              <mat-chip *ngFor="let f of filtersMap">{{ f.filter }}</mat-chip>
            </mat-chip-list>
          </div>
          <div class="col-md-2">
            <mat-panel-title class="filter-title"> Filters </mat-panel-title>
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>

    <div class="row filters-row">
      <form [formGroup]="filterForm" (submit)="onSubmitFilter()">
        <div class="col-md-12 text-blue margin-top-5 border-bottom-orange">
          <div class="row">
            <div class="col-md-1"></div>

            <div class="col-md-2">
              <mat-form-field>
                <textarea matInput formControlName="orderId" placeholder="Order ID"></textarea>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select filterKey="description" formControlName="supplier" id="supplier" placeholder="Supplier">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="supplier" *appMatSelectFor="let supplier of suppliers">{{ supplier.description }} </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select filterKey="description" formControlName="destination" placeholder="Destination">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option
                    *appMatSelectFor="let warehouse of warehouses"
                    [value]="warehouse"
                    style="font-family: 'Titillium Web', sans-serif !important; font-size: 12px"
                  >
                    {{ warehouse.description }}
                  </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select filterKey="description" formControlName="parent" id="parent" placeholder="Parent vendor">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="vendor" *appMatSelectFor="let vendor of parentVendors">{{ vendor.description }} </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="lotName" placeholder="Lot Name" />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-1"></div>

            <div class="col-md-2">
              <mat-form-field class="small-mat-form-field">
                <input matInput type="number" formControlName="unitCostFrom" placeholder="Cost from" />
              </mat-form-field>
              <mat-form-field class="small-mat-form-field margin-left-10">
                <input matInput type="number" formControlName="unitCostTo" placeholder="Cost to" />
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="ean" placeholder="EAN" />
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="po" placeholder="PO" />
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select formControlName="acceptBindingOrders" placeholder="Binding orders">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="true">Only binding</mat-option>
                  <mat-option [value]="false">Only non-binding</mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select filterKey="description" formControlName="status" id="status" placeholder="Status">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="s.code" *appMatSelectFor="let s of spoStatusArray">{{ s.description }} </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-1"></div>

            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Po Ordered On</mat-label>
                <mat-date-range-input [rangePicker]="poOrderedOn">
                  <input matStartDate formControlName="poOrderedOnFrom" placeholder="Start date" />
                  <input matEndDate formControlName="poOrderedOnTo" placeholder="End date" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="poOrderedOn"></mat-datepicker-toggle>
                <mat-date-range-picker #poOrderedOn></mat-date-range-picker>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Lot Date</mat-label>
                <mat-date-range-input [rangePicker]="lotDate">
                  <input matStartDate formControlName="lotDateFrom" placeholder="Start date" />
                  <input matEndDate formControlName="lotDateTo" placeholder="End date" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="lotDate"></mat-datepicker-toggle>
                <mat-date-range-picker #lotDate></mat-date-range-picker>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-1"></div>

            <div class="col-md-2 checkbox-small-text">
              <mat-checkbox formControlName="hasOrderedQuantity">Ordered > 0</mat-checkbox>
            </div>

            <div class="col-md-2 checkbox-small-text">
              <mat-checkbox formControlName="obsoleteWithAccepted">Obsolete with accepted</mat-checkbox>
            </div>

            <div class="col-md-2 checkbox-small-text">
              <mat-checkbox formControlName="crossUkBorder">Cross UK border</mat-checkbox>
            </div>

            <div class="col-md-2 checkbox-small-text">
              <mat-checkbox formControlName="isAdr">Is ADR</mat-checkbox>
            </div>

            <div class="col-md-2 checkbox-small-text">
              <mat-checkbox formControlName="isExtra">Is Extra</mat-checkbox>
            </div>
          </div>
        </div>

        <div class="col-md-12 border-top-white">
          <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-2 center">
              <button type="submit" class="box-shadow-none btn-custom" style="float: right">Filter</button>
            </div>
            <div class="col-md-2 center">
              <button type="button" (click)="resetFilters()" class="box-shadow-none btn-custom">Reset</button>
            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
      </form>

      <mat-divider></mat-divider>
    </div>
  </mat-expansion-panel>
</div>
