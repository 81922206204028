import { JsonObject, JsonProperty } from 'json2typescript';
import { GenerateOrderedConfigurationDto } from '@models/lot-configuration-dto-model';

@JsonObject('GenerateOrderedResult')
export class GenerateOrderedResult {
  @JsonProperty('lotId', Number, true) lotId: number = undefined;
  @JsonProperty('configurationId', Number, true) configurationId: number = undefined;
  @JsonProperty('orderedQuantity', Number, true) orderedQuantity: number = undefined;
  @JsonProperty('orderedValue', Number, true) orderedValue: number = undefined;
  @JsonProperty('orderedPurchasePrice', Number, true) orderedPurchasePrice: number = undefined;
  @JsonProperty('margin', Number, true) margin: number = undefined;
  @JsonProperty('percentMargin', Number, true) percentMargin: number = undefined;
  @JsonProperty('virtualOrderedPurchasePrice', Number, true) virtualOrderedPurchasePrice: number = undefined;
  @JsonProperty('virtualMargin', Number, true) virtualMargin: number = undefined;
  @JsonProperty('virtualPercentMargin', Number, true) virtualPercentMargin: number = undefined;
}

@JsonObject('GenerateOrderedSummary')
export class GenerateOrderedSummary {
  // eslint-disable-next-line max-len
  @JsonProperty('generateOrderedConfiguration', GenerateOrderedConfigurationDto, true)
  generateOrderedConfiguration: GenerateOrderedConfigurationDto = undefined;
  @JsonProperty('summary', GenerateOrderedResult, true) summary: GenerateOrderedResult = undefined;
  @JsonProperty('winner', Boolean, true) winner = false;
}
