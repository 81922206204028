<div [ngClass]="{ 'black-panel': openFilter, panel: !openFilter }">
  <mat-expansion-panel class="filter-position" [attr.expanded]="openFilter" (opened)="openEvent()" (closed)="closeevent()">
    <mat-expansion-panel-header class="filt-custom">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-9">
            <mat-chip-list>
              <mat-chip *ngFor="let f of filtersMap">{{ f.filter }}</mat-chip>
            </mat-chip-list>
          </div>
          <div class="col-md-2">
            <mat-panel-title class="filter-title"> Filters </mat-panel-title>
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>

    <div class="row" style="padding-top: 15px">
      <form [formGroup]="filterForm" (submit)="onSubmitFilter()">
        <div class="container border-bottom-orange col-md-12 text-blue margin-top-5">
          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="documentNumber" id="documentNumber" placeholder="Document Number" />
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select filterKey="description" formControlName="supplier" id="supplier" placeholder="Supplier">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="supplier.code" *appMatSelectFor="let supplier of suppliers">
                    {{ supplier.description }}
                  </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="documentDate" [matDatepicker]="documentDate" placeholder="Document Date" />
                <mat-datepicker-toggle matSuffix [for]="documentDate"></mat-datepicker-toggle>
                <mat-datepicker #documentDate></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select filterKey="code" formControlName="status" id="status" placeholder="Status">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="s" *appMatSelectFor="let s of status">
                    {{ s.description }}
                  </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <textarea matInput formControlName="shipments" id="shipments" placeholder="Shipments Order ID"></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-md-12 border-top-white">
          <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-2 center">
              <button class="btn-custom padding-left-right-70" type="submit">Filter</button>
            </div>
            <div class="col-md-2 center">
              <button class="btn-custom padding-left-right-70" type="button" (click)="resetFilters()">Reset</button>
            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
</div>
