<div class="row padding-right-30">
  <ng-container *ngIf="!dataSource || dataSource?.data.length === 0">
    <div class="col-md-12 small-table-title text-center">
      <h4>No Supplier Discount found</h4>
    </div>
  </ng-container>

  <div
    class="col-md-12 padding-right-0 small-table-title border-bottom-title"
    [ngClass]="{ 'display-none': !dataSource || dataSource?.data.length === 0 }"
  >
    <h4>Supplier Discount</h4>
  </div>

  <div
    class="col-md-12 fixed-table-5-rows padding-left-0 padding-right-0"
    [ngClass]="{ 'display-none': !dataSource || dataSource?.data.length === 0 }"
  >
    <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-40">
      <mat-table #listingTable [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
        <ng-container matColumnDef="supplierName">
          <mat-header-cell *matHeaderCellDef mat-sort-header="supplierName">Supplier Name</mat-header-cell>
          <mat-cell *matCellDef="let supplierDiscount">
            <p>{{ supplierDiscount.supplier.name }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="supplierCode">
          <mat-header-cell *matHeaderCellDef mat-sort-header="supplierCode">Supplier Code</mat-header-cell>
          <mat-cell *matCellDef="let supplierDiscount">
            <p>{{ supplierDiscount.supplier.code }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="productEan">
          <mat-header-cell *matHeaderCellDef mat-sort-header="productEan"> Ean </mat-header-cell>
          <mat-cell *matCellDef="let supplierDiscount">
            <p>{{ supplierDiscount.productEan }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="discountedPrice">
          <mat-header-cell *matHeaderCellDef mat-sort-header="discountedPrice">Discounted Price</mat-header-cell>
          <mat-cell *matCellDef="let supplierDiscount">
            <p>{{ supplierDiscount.discountedPrice }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="validFrom">
          <mat-header-cell *matHeaderCellDef mat-sort-header="validFrom"> Valid From </mat-header-cell>
          <mat-cell *matCellDef="let supplierDiscount">
            <p>{{ supplierDiscount.validFrom }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="validTo">
          <mat-header-cell *matHeaderCellDef mat-sort-header="validTo"> Valid To </mat-header-cell>
          <mat-cell *matCellDef="let supplierDiscount">
            <p>{{ supplierDiscount.validTo }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="valid">
          <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="valid"> Valid</mat-header-cell>
          <mat-cell class="boolean-column" *matCellDef="let supplierDiscount">
            <p><i [ngClass]="supplierDiscount.valid ? 'fa fa-check' : 'fa fa-times sel-icon'" aria-hidden="true"></i></p>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>
</div>
