import { Component, Input, OnInit } from '@angular/core';
import { PublicationRuleService } from '@container/configuration/publication-rules/publication-rule.service';
import { Predicate, PredicateField, PredicateFieldsOutput, PredicateOperator } from '@models/publication-rule';

@Component({
  selector: 'app-margin-predicates-edit',
  templateUrl: './margin-predicates-edit.component.html',
  styleUrls: ['./margin-predicates-edit.component.css'],
})
export class MarginPredicatesEditComponent {
  @Input() set _predicates(p: Predicate[]) {
    this.predicates = p;
    this.getFields();
  }

  @Input() marginEnabled = true;

  predicates: Predicate[];
  fieldsOutput: PredicateFieldsOutput[];

  fields: PredicateField[];

  constructor(private publicationRuleService: PublicationRuleService) {}

  getFields() {
    this.publicationRuleService.getRulePredicateFields().subscribe((r) => {
      this.fieldsOutput = r;
      this.fields = r.map((it) => it.field);
      this.predicates.forEach((p) => {
        this.setPredicateField(p.field.fieldName, p);
      });
    });
  }

  addPredicate() {
    const p = new Predicate();
    p.field = new PredicateField();
    p.operator = new PredicateOperator();
    p.isEdit = true;
    p.isEnabled = true;
    this.predicates.push(p);
  }

  setPredicateField(fieldName: string, predicate: Predicate) {
    const selectedField = this.fieldsOutput.find((it) => it.field.fieldName === fieldName);
    predicate.field = selectedField.field;
    predicate.availableOperators = selectedField.operators;
  }

  setPredicateOperator(operatorName: string, predicate: Predicate) {
    predicate.operator = predicate.availableOperators.find((it) => it.name === operatorName);
  }

  confirmPredicate(predicate: Predicate) {
    predicate.isEdit = false;
  }

  deletePredicate(predicate: Predicate) {
    const index = this.predicates.findIndex((it) => it === predicate);
    this.predicates.splice(index, 1);
  }
}
