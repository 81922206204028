<mat-toolbar class="bar-custom">
  <div class="container">
    <div class="row">
      <div class="col-md-10">
        <button class="btn-custom" [routerLink]="['new']">New Lot Configuration</button>
      </div>
    </div>
  </div>
</mat-toolbar>

<div class="container table-outer">
  <div class="table-header">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search" />
    </mat-form-field>
  </div>

  <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]"> </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="name">
        <mat-header-cell class="name-column" *matHeaderCellDef mat-sort-header="name"> Name </mat-header-cell>
        <mat-cell class="name-column" *matCellDef="let configuration">
          {{ configuration.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lotName">
        <mat-header-cell class="lot-name-column" *matHeaderCellDef> Lot Name </mat-header-cell>
        <mat-cell class="lot-name-column" *matCellDef="let configuration">
          {{ configuration.lotName }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="cronExpression">
        <mat-header-cell class="cron-column" *matHeaderCellDef> cron Expression </mat-header-cell>
        <mat-cell class="cron-column" *matCellDef="let configuration">
          {{ configuration.cronExpression }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="vendors">
        <mat-header-cell class="wide-column" *matHeaderCellDef> Vendors </mat-header-cell>
        <mat-cell class="wide-column" *matCellDef="let configuration" [matTooltip]="configuration.vendorsList">
          {{ configuration.vendorsList }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="generateOrderedIterations">
        <mat-header-cell class="boolean-column" *matHeaderCellDef> Ordered Iterations </mat-header-cell>
        <mat-cell *matCellDef="let configuration" class="boolean-column">
          {{ configuration.generateOrderedIterations }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="decreaseMargin">
        <mat-header-cell class="boolean-column" *matHeaderCellDef> Ordered Decrease Margin per Iteration </mat-header-cell>
        <mat-cell *matCellDef="let configuration" class="boolean-column">
          {{ configuration.generateOrderedDecreseMarginPerIteration | myMeasure }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="disabled">
        <mat-header-cell class="boolean-column" *matHeaderCellDef> Disabled </mat-header-cell>
        <mat-cell *matCellDef="let configuration" class="boolean-column">
          <i [ngClass]="configuration.disabled ? 'fa fa-check' : 'fa fa-times sel-icon'" aria-hidden="true"></i>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="edit" stickyEnd>
        <mat-header-cell class="button-column" *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let configuration" class="button-column">
          <button class="btn-product btn-fixed" style="margin-left: 15px" [routerLink]="['edit', configuration.id]">Edit</button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
