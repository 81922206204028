import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { MainSposComponent } from './main-spos.component';
import { MainSposListComponent } from './main-spos-list/main-spos-list.component';
import { MainSposListDetailsComponent } from './main-spos-details/main-spos-list-details.component';
import { SingleSpoDetailComponent } from '../../../shared/single-spo-detail/single-spo-detail.component';

const configurationRoutes: Routes = [
  {
    path: '',
    component: MainSposComponent,
    children: [
      { path: '', component: MainSposListComponent, pathMatch: 'full' },
      { path: 'details', component: MainSposListDetailsComponent },
      { path: ':id', component: SingleSpoDetailComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(configurationRoutes)],
  exports: [RouterModule],
  providers: [],
})
export class MainSposRoutingModule {}
