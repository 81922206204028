import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { SingleSpoDetailsModule } from '../../../shared/single-spo-detail/single-spo-details.module';
import { MainSposComponent } from './main-spos.component';
import { MainSposFiltersComponent } from './main-spos-filters/main-spos-filters.component';
import { MainSposRoutingModule } from './main-spos-routing.module';
import { MainSposListComponent } from './main-spos-list/main-spos-list.component';
import { MainSposListDetailsComponent } from './main-spos-details/main-spos-list-details.component';
import { SpoStatusPipe } from '../../../shared/my-pipes';

@NgModule({
  declarations: [MainSposComponent, MainSposFiltersComponent, MainSposListComponent, MainSposListDetailsComponent],
  imports: [SharedModule, MainSposRoutingModule, SingleSpoDetailsModule],
  providers: [SpoStatusPipe],
  entryComponents: [],
})
export class MainSposModule {}
