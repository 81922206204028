<div class="lot-kpi">
  <div class="container">
    <div class="col-md-3 background-blue text-white height-box font-row padding-top-15">
      <p><b>Invoice Number: </b> {{ invoice?.invoiceNumber }}</p>
      <p><b>Invoice Date: </b> {{ invoice?.invoiceDate }}</p>
      <p><b>Sending Date: </b> {{ invoice?.sendingDate }}</p>
      <p><b>Sent to Amazon: </b> <i [ngClass]="invoice?.sentToAmazon | booleanIconPipe" class="margin-left-6"> </i></p>
      <p><b>Manual: </b><i [ngClass]="invoice?.manual ? 'fa fa-check' : 'fa fa-times sel-icon'" aria-hidden="true"></i></p>
      <p>
        <b>Probable NC: </b>
        <ng-container *ngIf="hasInvoiceManagement | async">
          <mat-checkbox [ngModel]="probableNC" (change)="onProbableNC($event)"></mat-checkbox>
        </ng-container>
        <ng-container *ngIf="(hasInvoiceManagement | async) === (false || null || undefined)">
          <i [ngClass]="invoice?.probableNC | booleanIconPipe" class="margin-left-6"> </i>
        </ng-container>
      </p>
    </div>

    <div class="col-md-3 background-light-blue text-white height-box font-row">
      <h4 class="h-padding-box">
        <b>Bill to {{ invoice?.billToGln }}</b>
      </h4>
      <p><b>Entity: </b> {{ invoice?.billToEntity }}</p>
      <p><b>Street: </b> {{ invoice?.billToStreet }}</p>
      <p><b>City: </b> {{ invoice?.billToCity }}</p>
      <p><b>Post Code: </b> {{ invoice?.billToPostCode }}</p>
      <p><b>Country: </b> {{ invoice?.billToCountryCode }}</p>
    </div>

    <div class="col-md-3 background-blue text-white height-box font-row">
      <h4 class="h-padding-box">
        <b>Shipment</b>
      </h4>
      <p>
        <b>Shipment Code: </b> <a [routerLink]="['/shipments', invoice?.shipment?.id]">{{ invoice?.shipment?.shipmentCode }}</a>
      </p>
      <p><b>Asn Code: </b> {{ invoice?.shipment?.asnCode }}</p>
      <p><b>Origin: </b> {{ invoice?.shipment?.origin.code + ' - ' + invoice?.shipment?.origin.name }}</p>
      <p><b>Destination: </b> {{ invoice?.shipment?.destination.code + ' - ' + invoice?.shipment?.destination.name }}</p>
    </div>

    <div class="col-md-3 background-light-blue text-white height-box font-row padding-top-15">
      <p><b>Invoice Total amount: </b> {{ computeTotalAmount() }}</p>
      <p><b>Remaining Shortage Claim: </b> {{ computeRemaining(claims, 'SC') | myCurrency }}</p>
      <p><b>SC Quantity: </b> {{ claimQuantity(claims) }}</p>
      <p><b>Amazon Status: </b> {{ invoice?.amazonStatus }}</p>
    </div>
  </div>
</div>
