export class AvailabilityKpi {
  submittedQuantity = 0;
  submittedValue = 0;

  requestedQuantity = 0;
  requestedValue = 0;

  availableQuantity = 0;
  availableValue = 0;
}
