import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('ClaimDetail')
export class ClaimDetail {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('invoiceNumber', String, true) invoiceNumber: string = undefined;
  @JsonProperty('poNumber', String, true) poNumber: string = undefined;
  @JsonProperty('asin', String, true) asin: string = undefined;
  @JsonProperty('externalId', String, true) externalId: string = undefined;
  @JsonProperty('title', String, true) title: string = undefined;
  @JsonProperty('invoiceQuantity', Number, true) invoiceQuantity: number = undefined;
  @JsonProperty('receivedQuantity', Number, true) receivedQuantity: number = undefined;
  @JsonProperty('claimQuantity', Number, true) claimQuantity: number = undefined;
  @JsonProperty('currencyCode', String, true) currencyCode: string = undefined;
  @JsonProperty('invoiceListPrice', Number, true) invoiceListPrice: number = undefined;
  @JsonProperty('invoiceDiscountPct', Number, true) invoiceDiscountPct: number = undefined;
  @JsonProperty('invoiceCost', Number, true) invoiceCost: number = undefined;
  @JsonProperty('poListPrice', Number, true) poListPrice: number = undefined;
  @JsonProperty('poDiscountPct', Number, true) poDiscountPct: number = undefined;
  @JsonProperty('poCost', Number, true) poCost: number = undefined;
  @JsonProperty('claimAmount', Number, true) claimAmount: number = undefined;
  @JsonProperty('taxRate', Number, true) taxRate: number = undefined;
  @JsonProperty('taxAmount', Number, true) taxAmount: number = undefined;
}
