import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { MainInvoicesComponent } from './main-invoices.component';
import { SingleInvoiceDetailComponent } from '../../../shared/single-invoice-detail/single-invoice-detail.component';

const configurationRoutes: Routes = [
  { path: '', component: MainInvoicesComponent, pathMatch: 'full' },
  { path: ':id', component: SingleInvoiceDetailComponent },
];

@NgModule({
  imports: [RouterModule.forChild(configurationRoutes)],
  exports: [RouterModule],
  providers: [],
})
export class MainInvoicesRoutingModule {}
