<ng-container *ngFor="let predicate of predicates">
  <div class="row predicate-row" [ngClass]="predicate.isEdit ? 'edit-predicate-row' : ''">
    <ng-container *ngIf="predicate.isEdit">
      <div class="col-md-1 padding-top-15 negate-text">
        {{ predicate.negatePredicate ? 'Not' : '' }}
      </div>
      <div class="col-md-2">
        <mat-form-field>
          <mat-select [ngModel]="predicate.field.fieldName" (ngModelChange)="setPredicateField($event, predicate)" placeholder="Field">
            <mat-option *ngFor="let field of fields" [value]="field.fieldName"> {{ field.fieldName }} </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-2">
        <mat-form-field *ngIf="predicate.field?.fieldName">
          <mat-select [ngModel]="predicate.operator.name" (ngModelChange)="setPredicateOperator($event, predicate)" placeholder="Operator">
            <mat-option *ngFor="let operator of predicate.availableOperators" [value]="operator.name" [matTooltip]="operator.description">
              {{ operator.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-2">
        <mat-form-field *ngIf="predicate.field?.fieldName">
          <input
            matInput
            placeholder="Target value"
            [(ngModel)]="predicate.target"
            [type]="predicate.field.type === 'STRING' ? 'text' : 'number'"
          />
        </mat-form-field>
      </div>

      <div class="col-md-1 padding-top-15">
        <mat-checkbox [(ngModel)]="predicate.negatePredicate">Negate predicate</mat-checkbox>
      </div>

      <div class="col-md-4 text-right padding-top-15">
        <button
          class="btn-custom smaller-icon-button"
          matTooltip="Confirm"
          (click)="confirmPredicate(predicate)"
          [disabled]="!predicate.target || !predicate.field || !predicate.operator"
        >
          <i class="fa fa-check" aria-hidden="true"></i>
        </button>
        <button class="btn-custom smaller-icon-button margin-left-5" matTooltip="Delete" (click)="deletePredicate(predicate)">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="!predicate.isEdit">
      <div class="col-md-9">
        {{ predicate.description }}
      </div>
      <div class="col-md-3 text-right">
        <button
          class="btn-custom smaller-icon-button"
          matTooltip="Edit"
          (click)="predicate.isEdit = true"
          [disabled]="!predicate.isEnabled || !markupEnabled"
        >
          <i class="fa fa-pencil" aria-hidden="true"></i>
        </button>

        <button
          class="btn-custom smaller-icon-button margin-left-5"
          matTooltip="Delete"
          (click)="deletePredicate(predicate)"
          [disabled]="!predicate.isEnabled || !markupEnabled"
        >
          <i class="fa fa-trash" aria-hidden="true"></i>
        </button>

        <button
          *ngIf="predicate.isEnabled; else disable"
          class="btn-product2"
          matTooltip="click if you want disable current constraint"
          (click)="predicate.isEnabled = false"
          [disabled]="!markupEnabled"
        >
          Disable
        </button>
        <ng-template #disable>
          <button
            class="btn-product2"
            matTooltip="click if you want enable current constraint"
            (click)="predicate.isEnabled = true"
            [disabled]="!markupEnabled"
          >
            Enable
          </button>
        </ng-template>
      </div>
    </ng-container>
  </div>
</ng-container>

<div
  *ngIf="markupEnabled; else dis"
  class="row predicate-row clickable-predicate-row"
  matTooltip="Add new predicate"
  (click)="addPredicate()"
>
  <div class="col-md-12 text-right">Add predicate</div>
</div>
<ng-template #dis>
  <div disabled="true" class="row predicate-row" matTooltip="Add new predicate">
    <div class="col-md-12 text-right">Add predicate</div>
  </div>
</ng-template>
