import { Any, JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';
import { Brand } from './brand-model';
import { Category } from './category-model';
import { Commodity } from './commodity';
import { Season } from './season-model';

@JsonObject('KeepaProductDetails')
export class KeepaProductDetails extends BaseObject {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('category', String, true) category: string = undefined;
  @JsonProperty('categoryTree', String, true) categoryTree: string = undefined;
  @JsonProperty('productData', Any, true) productData: any = undefined;
  @JsonProperty('statistics', Any, true) statistics: any = undefined;

  get gbAmazonPrice(): number | null {
    return this.getPriceValue(this.statistics?.GB?.stats?.current[0]);
  }

  get gbNewPrice(): number | null {
    return this.getPriceValue(this.statistics?.GB?.stats?.current[1]);
  }

  get deAmazonPrice(): number | null {
    return this.getPriceValue(this.statistics?.DE?.stats?.current[0]);
  }

  get deNewPrice(): number | null {
    return this.getPriceValue(this.statistics?.DE?.stats?.current[1]);
  }

  get itAmazonPrice(): number | null {
    return this.getPriceValue(this.statistics?.IT?.stats?.current[0]);
  }

  get itNewPrice(): number | null {
    return this.getPriceValue(this.statistics?.IT?.stats?.current[1]);
  }

  get frAmazonPrice(): number | null {
    return this.getPriceValue(this.statistics?.FR?.stats?.current[0]);
  }

  get frNewPrice(): number | null {
    return this.getPriceValue(this.statistics?.FR?.stats?.current[1]);
  }

  get esAmazonPrice(): number | null {
    return this.getPriceValue(this.statistics?.ES?.stats?.current[0]);
  }

  get esNewPrice(): number | null {
    return this.getPriceValue(this.statistics?.ES?.stats?.current[1]);
  }

  private getPriceValue(value: number): number | null {
    if (value == null || value === -1) {
      return null;
    }
    return value / 100;
  }
}

@JsonObject('Product')
export class Product extends BaseObject {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('ean', String, false) ean: string = undefined;
  @JsonProperty('upc', String, true) upc: string = undefined;
  @JsonProperty('sku', String, true) sku: string = undefined;
  @JsonProperty('title', String, true) title: string = undefined;
  @JsonProperty('brand', Brand, true) brand: Brand = undefined;
  @JsonProperty('stockQty', Number, true) stockQty: number = undefined;
  @JsonProperty('size', String, true) size: string = undefined;
  @JsonProperty('colorCode', String, true) colorCode: string = undefined;
  @JsonProperty('color', String, true) color: string = undefined;
  @JsonProperty('wholesalePrice', Number, true) wholesalePrice: number = undefined;
  @JsonProperty('retailPrice', Number, true) retailPrice: number = undefined;
  @JsonProperty('articleCode', String, true) articleCode: string = undefined;
  @JsonProperty('status', String, true) status: string = undefined;
  @JsonProperty('category', Category, true) category: Category = undefined;
  @JsonProperty('season', Season, true) season: Season = undefined;
  @JsonProperty('hasPo', Boolean, true) hasPo: boolean = undefined;
  @JsonProperty('sentToNav', Boolean, true) sentToNav: boolean = undefined;
  @JsonProperty('asin', String, true) asin: string = undefined;
  @JsonProperty('keepaProductDetails', KeepaProductDetails, true) keepaProductDetails: KeepaProductDetails = undefined;

  @JsonProperty('hsCode', String, true) hsCode: string = undefined;
  @JsonProperty('commodity', Commodity, true) commodity: Commodity = undefined;
}

/* eslint-disable */
export const AMAZON_PRICES_LABELS = {
  GB: 'gbAmazonPrice',
  DE: 'deAmazonPrice',
  IT: 'itAmazonPrice',
  FR: 'frAmazonPrice',
  ES: 'esAmazonPrice',
};

export const NEW_PRICES_LABELS = {
  GB: 'gbNewPrice',
  DE: 'deNewPrice',
  IT: 'itNewPrice',
  FR: 'frNewPrice',
  ES: 'esNewPrice',
};

export const MARKETPLACES = ['GB', 'DE', 'IT', 'FR', 'ES'];
/* eslint-enable */
