import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PoComponent } from './po-module/po/po.component';
import { PoDetailsComponent } from './po-module/po-details/po-details.component';
import { SpoItemsComponent } from './spo-module/spo-items/spo-items.component';
import { LotEditComponent } from './lot-edit.component';
import { WarehouseFulfilmentComponent } from './warehouse-fulfilment/warehouse-fulfilment.component';
import { ShipmentsListComponent } from './shipments-module/shipments-list/shipments-list.component';
import { SingleShipmentDetailComponent } from '@shared/single-shipment-detail/single-shipment-detail.component';
import { ShipmentsListDetailsComponent } from './shipments-module/shipments-list-details/shipments-list-details.component';
import { SupplierAvailabilitiesComponent } from './supplier-availabilities/supplier-availabilities.component';
import { SpoComponent } from './spo-module/spos/spo.component';
import { SingleSpoDetailComponent } from '@shared/single-spo-detail/single-spo-detail.component';
import { SinglePoDetailsComponent } from '@shared/single-po-details/single-po-details.component';
import { LogDiscardedValueComponent } from './log-discarded-value/log-discarded-value/log-discarded-value.component';
// eslint-disable-next-line max-len
import { GenerateOrderedResultsComponent } from '@container/lot-edit-section/spo-module/generate-ordered-results/generate-ordered-results.component';

const lotEditRoutes: Routes = [
  {
    path: '',
    component: LotEditComponent,
    children: [
      { path: 'po', component: PoComponent },
      { path: 'po-det', component: PoDetailsComponent },
      { path: 'po/:id', component: SinglePoDetailsComponent },
      { path: 'warehouse-fulfilment', component: WarehouseFulfilmentComponent },
      { path: 'spo', component: SpoComponent },
      { path: 'spo/:id', component: SingleSpoDetailComponent },
      { path: 'spo-det', component: SpoItemsComponent },
      { path: 'generate-ordered-results', component: GenerateOrderedResultsComponent },
      { path: 'supplier-availabilities', component: SupplierAvailabilitiesComponent },
      { path: 'log-discarded-value', component: LogDiscardedValueComponent },
      { path: 'shipments', component: ShipmentsListComponent },
      { path: 'shipments/:id', component: SingleShipmentDetailComponent },
      { path: 'shipments-det', component: ShipmentsListDetailsComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(lotEditRoutes)],
  exports: [RouterModule],
  providers: [],
})
export class LotEditRoutingModule {}
