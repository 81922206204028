import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  public baseApiUrl = '';
  public ponchBaseApiUrl = '';
  public application = 'AMBRA';
  public tenant = 'Brandon';
  public dev = true;
  public staging = false;
  public production = false;
  public logo = 'logo.svg';

  public getRestEndpoint(name: string): string {
    // tslint:disable-next-line: no-use-before-declare
    return this.dev ? mockEndpoints[name] : this.baseApiUrl + restEndpoints[name];
  }

  public getPonchRestEndpoint(name: string): string {
    // tslint:disable-next-line: no-use-before-declare
    return this.dev ? mockEndpoints[name] : this.ponchBaseApiUrl + ponchRestEnpoints[name];
  }

  constructor() {}
}

const ponchRestEnpoints = {
  userPermissions: 'api/v1/user-permissions',
  users: 'api/v1/users',
  roles: 'api/v1/roles',
  permissions: 'api/v1/permissions',
  applications: 'api/v1/applications',
};

const restEndpoints = {
  uploadFile: 'api/v1/uploadFile',

  // PublicationRule
  publicationRule: 'api/v1/publication-rule',
  publicationRuleStatus: 'api/v1/publication-rule/status',
  publicationRulePredicateFields: 'api/v1/publication-rule/predicate-fields',
  publicationRuleStrategies: 'api/v1/publication-rule/strategies',

  // Lots
  lots: 'api/v1/lots',
  newLot: 'api/v1/lots/new',
  searchOpenLotsByName: 'api/v1/lots/search-open',
  hasFulfilments: 'api/v1/lots/has-fulfilments',
  generateOrderedSummaries: 'api/v1/lots/generate-ordered-summaries/',
  shipmentGenerateRecap: 'api/v1/lots/shipment-generate-recap/',
  searchLotsByName: 'api/v1/lots/search/',

  // Lots handling
  addPosToLot: 'api/v1/lots/add-pos',
  addFilteredPosToLot: 'api/v1/lots/add-filtered-pos',
  movePosToLot: 'api/v1/lots/move-pos',
  moveFilteredPosToLot: 'api/v1/lots/move-filtered-pos',
  removePosFromLot: 'api/v1/lots/remove-pos',
  removeFilteredPosFromLot: 'api/v1/lots/remove-filtered-pos',

  // BOT
  disputeBotLogs: 'api/v1/dispute-bot-logs',

  // POS
  pos: 'api/v1/pos',
  singlePo: 'api/v1/pos',
  poItems: 'api/v1/po-items',

  excelExportPos: 'api/v1/utils/export/pos',
  excelExportPoItems: 'api/v1/utils/export/po-items',
  excelExportFilteredPos: 'api/v1/utils/export/filtered-pos',
  excelExportFilteredPoItems: 'api/v1/utils/export/filtered-po-items',

  closeAndSendPoaFromIds: 'api/v1/close-and-send-poa',
  closeAndSendPoaFromFromFilters: 'api/v1/close-and-send-poa-from-filters',
  searchPoEanDest: 'api/v1/search-po-ean-dest',

  // Inventory
  products: 'api/v1/products',
  singleProduct: 'api/v1/products',
  productDetails: 'api/v1/products',
  productsCatalog: 'api/v1/products',
  productsStatuses: 'api/v1/products/status',
  productSuppliers: 'api/v1/catalog-items/product=',

  suppliers: 'api/v1/suppliers',
  supplierProducts: 'api/v1/catalog-items/supplier=',
  countCatalogItems: 'api/v1/catalog-items/count-by-filters',
  catalogItems: 'api/v1/catalog-items',
  excelSuppImport: 'api/v1/utils/import-catalog',
  changeCatalogItemStatusFromFilters: 'api/v1/catalog-items/change-status-from-filters=',
  changeCatalogItemStatusFromIds: 'api/v1/catalog-items/change-status=',
  catalog: 'api/v1/catalog-items',

  getJimboMessages: 'api/v1/suppliers/jimbo-messages',
  saveJimboMessages: 'api/v1/suppliers/set-jimbo-messages',

  supplierDiscount: 'api/v1/supplierDiscount',

  stockLocations: 'api/v1/stock-locations',
  transitWarehouses: 'api/v1/stock-locations/transit-warehouses',
  brandonTransitWarehouses: 'api/v1/stock-locations/brandon-transit-warehouses',
  singleStockLocation: 'api/v1/stock-locations',
  productStockLocations: 'api/v1/stock-items/product=',
  stockProducts: 'api/v1/stock-items/stock-location=',

  brands: 'api/v1/brands',

  publications: 'api/v1/publications',
  exportPendingApprovalPublication: 'api/v1/utils/export/pending-approval-publications',
  exportFilteredPendingApprovalPublication: 'api/v1/utils/export/filtered-pending-approval-publications',

  categories: 'api/v1/categories',

  seasons: 'api/v1/seasons',

  //Vendors Account Name
  vendorCentralAccounts: 'api/v1/vendor-central-accounts',

  // Vendors-Warehouses
  warehouses: 'api/v1/warehouses',
  filteredWarehouses: 'api/v1/filtered-warehouses',
  warehousesCountries: 'api/v1/warehouses-countries',
  vendors: 'api/v1/vendors',
  parentVendors: 'api/v1/vendors/parents',
  vendorsChildren: 'api/v1/vendors/children',

  // Lot Configuration
  lotConfigurations: 'api/v1/lot-configurations',
  lotGenerateOrderedConfigurations: 'api/v1/lot-configurations/generate-ordered/',

  // Fulfilment
  generateFulfilment: 'api/v1/generate-fulfilment',
  dropGenerateFulfilment: 'api/v1/drop-generate-fulfilment',
  fulfilments: 'api/v1/fulfilments',

  stockLocationsFromFulfilments: 'api/v1/fulfilments/stockLocations',

  exportFulfilment: 'api/v1/utils/export/fulfilments',
  exportFilteredFulfilment: 'api/v1/utils/export/filtered-fulfilments',

  // SPOs
  spos: 'api/v1/spos',
  spoStatuses: 'api/v1/spos/status',
  singleSpo: 'api/v1/spos',
  changeSpoStatusFromIds: 'api/v1/spos/change-status=',
  changeSpoStatusFromFilters: 'api/v1/spos/change-status-from-filters=',
  spoItems: 'api/v1/spo-items',
  groupedSpoItems: 'api/v1/spo-items/grouped',

  mergeByIds: 'api/v1/spos/merge-by-ids',
  mergeByFilters: 'api/v1/spos/merge-by-filters',

  suppliersFromSpos: 'api/v1/spos/suppliers',

  exportSpos: 'api/v1/utils/export/spos',
  exportFilteredSpos: 'api/v1/utils/export/filtered-spos',
  exportSpoItems: 'api/v1/utils/export/spo-items',
  exportFilteredSpoItems: 'api/v1/utils/export/filtered-spo-items',

  closeSpoAndGenerateShipmentById: 'api/v1/spos/close-spo-and-generate-shipment',
  generateShipmentsFromSposByIds: 'api/v1/spos/generate-shipments-by-ids',
  generateShipmentsFromSposByFilters: 'api/v1/spos/generate-shipments-by-filters',
  generateShipmentsFromSpoItemsByIds: 'api/v1/spo-items/generate-shipments-by-ids',
  generateShipmentsFromSpoItemsByFilters: 'api/v1/spo-items/generate-shipments-by-filters',

  importSpoResp: 'api/v1/utils/import-spo-resp',
  generateOrderedQuantities: 'api/v1/lots/generate-ordered-quantities',
  generateOrderedQuantitiesForConfiguration: 'api/v1/lots/generate-ordered-for-configuration',

  sendSposFromIds: 'api/v1/spos/send-from-ids',
  sendSposFromFilters: 'api/v1/spos/send-from-filters',

  // LSAs
  suppliersAvailability: 'api/v1/supplier-availability',

  // Log Discarded Value
  logDiscardedValue: 'api/v1/log-discarded-value',
  logDiscardedValueDetail: 'api/v1/log-discarded-value-detail',

  // Shipments
  shipments: 'api/v1/shipments',
  shipmentStatuses: 'api/v1/shipments/status',
  singleShipment: 'api/v1/shipments',
  shipmentItems: 'api/v1/shipment-items',

  mergeShipments: 'api/v1/shipments/merge-shipments',
  mergeFilteredShipments: 'api/v1/shipments/merge-filtered-shipments',
  splitShipmentFromIds: 'api/v1/shipments/split',
  splitShipmentFromFilters: 'api/v1/shipments/split-by-filters',

  stockLocationsFromShipments: 'api/v1/shipments/stockLocations',
  suppliersFromShipments: 'api/v1/shipments/suppliers',
  generateLqLabels: 'api/v1/shipments/lq-labels',

  generateShipments: 'api/v1/generate-shipments',
  dropGenerateShipments: 'api/v1/drop-generate-shipments',
  dropOnlyCancelable: 'api/v1/drop-cancelable-shipments',
  generateMissingShipments: 'api/v1/generate-missing-shipments',

  importPackingList: 'api/v1/utils/import-packing-list',
  updateShippedQuantities: 'api/v1/shipment-items/update-shipped-quantity',
  changePackingProperties: 'api/v1/shipments/change-packing-properties',
  checkTrackingNumber: 'api/v1/shipments/check-tracking-number',
  changeCarrierProperties: 'api/v1/shipments/change-carrier-properties',
  sendToBridget: 'api/v1/shipments/send-to-bridget',
  cancelFromBridget: 'api/v1/shipments/cancel-from-bridget',

  generateASNBarcode: 'api/v1/generate/asn-barcode',
  cancelASN: 'api/v1/shipments/cancel-asn',

  originShipment: 'api/v1/shipments/origin',
  caterogyShipment: 'api/v1/shipments/category',

  excelExportShipmentHeaders: 'api/v1/utils/export/shipment-headers',
  excelExportFilteredShipmentHeaders: 'api/v1/utils/export/filtered-shipment-headers',
  excelExportShipments: 'api/v1/utils/export/shipments',
  excelExportShipmentItems: 'api/v1/utils/export/shipment-items',
  excelExportFilteredShipments: 'api/v1/utils/export/filtered-shipments',
  excelExportFilteredShipmentItems: 'api/v1/utils/export/filtered-shipment-items',

  generateAsn: 'api/v1/shipments/generate-asn',
  singleCarton: 'api/v1/shipments/single-carton',

  excelExportFilteredLsas: 'api/v1/utils/export/filtered-lsas',
  excelExportLsas: 'api/v1/utils/export/lsas',
  generateDdt: 'api/v1/utils/generate-ddt',
  exportDdt: 'api/v1/utils/export/ddt',

  shipmentDocumentsList: 'api/v1/shipments/documents-list',
  sendDocuments: 'api/v1/shipments/send-documents',

  setTransitWarehouse: 'api/v1/shipments/{shipmentId}/set-transit-warehouse',

  // Cartons
  cartons: 'api/v1/cartons',
  generateSSCCBarcode: 'api/v1/generate/sscc-barcode',
  generateAllSSCCBarcode: 'api/v1/generate/all-sscc-barcode',

  generateSSCCAndLDVLabel: 'api/v1/generate/sscc-ldv-label',
  generateAllSSCCAndLDVLabel: 'api/v1/generate/all-sscc-ldv-label',

  excelExportCartons: 'api/v1/utils/export/cartons',
  excelExportCartonItems: 'api/v1/utils/export/carton-items',

  // Pallets
  pallets: 'api/v1/pallets',

  // Invoices
  invoices: 'api/v1/invoices',
  singleInvoice: 'api/v1/invoices',
  invoiceItems: 'api/v1/invoice-items',
  generateInvoiceById: 'api/v1/invoices/send',
  generateInvoiceFromFiltersResponse: 'api/v1/invoices/send-from-filters-response',
  generateInvoiceFromFiltersValidation: 'api/v1/invoices/send-from-filters-validation',

  exportInvoice: 'api/v1/utils/export/invoice',
  exportIntercoInvoice: 'api/v1/utils/export/interco-invoice',
  exportExportDeclaration: 'api/v1/utils/export/export-declaration',

  invoiceClaim: 'api/v1/invoices/invoice-claim',

  // Remittances
  remittances: 'api/v1/remittances',
  remittanceDetailsCodes: 'api/v1/remittances/codes',
  remittanceDetails: 'api/v1/remittance-details',

  // Claims
  claims: 'api/v1/claims',
  claimHeaders: 'api/v1/claim-headers',
  claimDetails: 'api/v1/claim-details',

  exportClaimDocumentation: 'api/v1/utils/export/claim-documentation',
  exportClaimLetter: 'api/v1/utils/export/claim-letter',

  // Chargebacks
  chargebacks: 'api/v1/chargebacks',

  // Payment Documents
  paymentDocuments: 'api/v1/payment-documents',
  paymentDocumentTypes: 'api/v1/payment-documents/types',
  paymentDocumentStatus: 'api/v1/payment-documents/status',

  paymentRequests: 'api/v1/payment-requests',
  paymentRequestsStatus: 'api/v1/payment-requests/status',
  paymentAdjustments: 'api/v1/payment-adjustments',
  paymentRequestsChangeLatestPaymentDate: 'api/v1/payment-requests/change-latest-payment-date',

  // Configuration
  configurationParams: 'api/v1/configurations',
  workerDynosConfig: 'api/v1/worker/dyno/config',
  workerDynosQueues: 'api/v1/worker/dyno/queues',
  workerRabbitQueues: 'api/v1/worker/rabbit/queues',

  brandonClosingDays: 'api/v1/configurations/brandon-closing-days',

  carriers: 'api/v1/carriers',
  contracts: 'api/v1/contracts',

  supplierTransitTimes: 'api/v1/supplier-transit-times',
  saveSupplierTransitTimes: 'api/v1/supplier-transit-times/save',

  stockLocationDefaultTransitTimes: 'api/v1/stock-location-transit-times/default',
  saveStockLocationDefaultTransitTimes: 'api/v1/stock-location-transit-times/default/save',

  stockLocationTransitTimes: 'api/v1/stock-location-transit-times',
  saveStockLocationTransitTimes: 'api/v1/stock-location-transit-times/save',

  countryHolidays: 'api/v1/country-holidays',
  saveCountryHolidays: 'api/v1/country-holidays/save',

  // Jobs
  getJobStatus: 'api/v1/jobs',

  exportPods: 'api/v1/utils/export/pods',
};

const mockEndpoints = {
  login: '../assets/mock/login.json',
  users: '../assets/mock/users.json',
  authenticate: '../assets/mock/authenticate.json',
  getCurrentRole: '../assets/mock/role.json',
  userRoles: '../assets/mock/user-roles.json',
  lots: '../assets/mock/lots.json',
  hasFulfilments: '../assets/mock/has-fulfilments.json',
  splitlotPo: '../assets/mock/pos.json',
  splitlotPoItem: '../assets/mock/lots.json',
  splitFilteredPoItems: '../assets/mock/lots.json',
  splitFilteredPos: '../assets/mock/lots.json',
  products: '../assets/mock/products.json',
  singleProduct: '../assets/mock/single-product.json',
  productDetails: '../assets/mock/product-details.json',
  productsCatalog: '../assets/mock/products-catalogs.json',
  pos: '../assets/mock/pos.json',
  singlePo: '../assets/mock/single-po.json',
  poItems: '../assets/mock/po-items.json',
  suppliers: '../assets/mock/suppliers.json',
  stockLocations: '../assets/mock/stock-locations.json',
  transitWarehouses: '../assets/mock/stock-locations.json',
  singleStockLocation: '../assets/mock/single-stock-location.json',
  catalogItems: '../assets/mock/supplier-products.json',
  countCatalogItems: '../assets/mock/supplier-products.json',
  productSuppliers: '../assets/mock/product-suppliers.json',
  supplierProducts: '../assets/mock/supplier-products.json',
  stockProducts: '../assets/mock/stock-products.json',
  productStockLocations: '../assets/mock/product-stock-locations.json',
  brands: '../assets/mock/brands.json',
  warehouses: '../assets/mock/warehouses.json',
  vendors: '../assets/mock/vendors.json',
  parentVendors: '../assets/mock/parent-vendors.json',
  vendorsChildren: '../assets/mock/vendors.json',
  publications: '../assets/mock/publications.json',
  excelSuppImport: '../assets/mock/suppliers.json',
  excelExportPos: '../assets/mock/pos.json',
  excelExportPoItems: '../assets/mock/po-items.json',
  excelExportFilteredPos: '../assets/mock/pos.json',
  excelExportFilteredPoItems: '../assets/mock/po-items.json',
  generateFulfilment: '../assets/mock/pos.json',
  dropGenerateFulfilment: '../assets/mock/pos.json',
  fulfilments: '../assets/mock/fulfilments.json',
  exportFulfilment: '../assets/mock/fulfilments.json',
  exportFilteredFulfilment: '../assets/mock/fulfilments.json',
  spos: '../assets/mock/spos.json',
  singleSpo: '../assets/mock/single-spo.json',
  singleInvoice: '../assets/mock/single-invoice.json',
  changeSpoStatusFromIds: '../assets/mock/spos.json',
  changeSpoStatusFromFilters: '../assets/mock/spos.json',
  spoItems: '../assets/mock/spo-items.json',
  exportSpos: '../assets/mock/spos.json',
  exportFilteredSpos: '../assets/mock/spos.json',
  exportSpoItems: '../assets/mock/spo-items.json',
  exportFilteredSpoItems: '../assets/mock/spo-items.json',
  generateShipmentsFromSposByIds: '../assets/mock/spo-items.json',
  generateShipmentsFromSposByFilters: '../assets/mock/spo-items.json',
  generateShipmentsFromSpoItemsByIds: '../assets/mock/spo-items.json',
  generateShipmentsFromSpoItemsByFilters: '../assets/mock/spo-items.json',
  importSpoResp: '../assets/mock/spo-items.json',
  importPackingList: '../assets/mock/shipments.json',
  generateShipments: '../assets/mock/pos.json',
  dropGenerateShipments: '../assets/mock/pos.json',
  shipments: '../assets/mock/shipments.json',
  singleShipment: '../assets/mock/single-shipment.json',
  shipmentItems: '../assets/mock/shipment-items.json',
  shipmentsLots: '../assets/mock/shipments-lots.json',
  suppliersFromShipments: '../assets/mock/suppliers.json',
  stockLocationsFromShipments: '../assets/mock/stock-locations.json',
  suppliersFromSpos: '../assets/mock/suppliers.json',
  stockLocationsFromFulfilments: '../assets/mock/stock-locations.json',
  excelExportShipments: '../assets/mock/shipments.json',
  excelExportShipmentItems: '../assets/mock/shipment-items.json',
  excelExportFilteredShipments: '../assets/mock/shipments.json',
  excelExportFilteredShipmentItems: '../assets/mock/shipment-items.json',
  mergeShipments: '../assets/mock/shipments.json',
  mergeFilteredShipments: '../assets/mock/shipments.json',
  sendPoaFromIds: '../assets/mock/pos.json',
  sendPoaFromFromFilters: '../assets/mock/pos.json',
  searchPoEanDest: '../assets/mock/pos.json',
  categories: '../assets/mock/categories.json',
  seasons: '../assets/mock/seasons.json',
  uploadFile: 'api/v1/uploadFile',
  generateAsn: '../assets/mock/shipments.json',
  singleCarton: '../assets/mock/shipments.json',
  sendToBridget: '../assets/mock/shipments.json',
  suppliersAvailability: '../assets/mock/lot-supplier-availabilities.json',
  excelExportFilteredLsas: '../assets/mock/lot-supplier-availabilities.json',
  excelExportLsas: '../assets/mock/lot-supplier-availabilities.json',
  generateOrderedQuantities: '../assets/mock/lots.json',
  updateShippedQuantities: '../assets/mock/update-shipped.json',
  excelExportCartons: '../assets/mock/cartons.json',
  excelExportCartonItems: '../assets/mock/cartons.json',
  cartons: '../assets/mock/cartons.json',
  invoices: '../assets/mock/invoices.json',
  invoiceItems: '../assets/mock/invoice-items.json',
  generateInvoiceById: '../assets/mock/single-invoice.json',
  remittances: '../assets/mock/remittances.json',
  remittanceDetailsCodes: '../assets/mock/remittance-codes.json',
  remittanceDetails: '../assets/mock/remittance-details.json',

  configurationParams: '../assets/mock/configuration-params.json',
  changePackingProperties: '../assets/mock/shipments.json',
  changeCarrierProperties: '../assets/mock/shipments.json',
  generateDdt: '../assets/mock/ddt.json',
  exportDdt: 'api/v1/utils/export/ddt',
  setTransitWarehouse: '../assets/mock/shipments.json',
  generateASNBarcode: 'api/v1/generate/asn-barcode',
  generateSSCCBarcode: 'api/v1/generate/sscc-barcode',
  cancelASN: '../assets/mock/shipments.json',

  workerDynosConfig: '../assets/mock/dynos.json',
  workerDynosQueues: '../assets/mock/queues.json',
  workerRabbitQueues: '../assets/mock/rabbit-queues.json',
};
