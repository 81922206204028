import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Supplier } from '../../../../models/supplier-model';
import { SupplierAvailabilityService } from '../../supplier-availabilities/supplier-availability.service';

export class SelectSupplierModalData {
  lotId: number = undefined;
}

@Component({
  selector: 'app-select-supplier-modal',
  templateUrl: './select-supplier-modal.component.html',
  styleUrls: ['./select-supplier-modal.component.css'],
})
export class SelectSupplierModalComponent implements OnInit {
  constructor(
    private supplierAvailabilityService: SupplierAvailabilityService,
    public dialogRef: MatDialogRef<SelectSupplierModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: SelectSupplierModalData
  ) {}

  displayedColumns = ['check', 'code', 'name'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  ngOnInit() {
    this.supplierAvailabilityService.getSuppliers(this.data.lotId).subscribe((suppliers: any[]) => {
      suppliers.forEach((supplier) => (supplier.checked = false));
      this.dataSource.data = suppliers;
    });
  }

  onSelect(value: boolean, supplier: Supplier) {
    const selectedIndex = this.dataSource.data.indexOf(supplier);
    this.dataSource.data[selectedIndex].checked = value;
  }

  toggleAll(value: boolean) {
    this.dataSource.data.forEach((supplier) => supplier.checked === value);
  }

  selected(): number[] {
    return this.dataSource.data.filter((supplier) => supplier.checked).map((supplier) => supplier.id);
  }

  isDisabled() {
    return !this.dataSource.data.some((supplier) => supplier.checked);
  }
}
