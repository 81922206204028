import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../../../../core/authorization.service';
import { Carrier } from '../../../../models/carrier.model';
import { Contract } from '../../../../models/contract.model';
import { Permissions } from '../../../../models/permission-model';
import { Supplier } from '../../../../models/supplier-model';
import { AppMatSelectComponent } from '../../../../shared/material-select-searchbox/app-mat-select.component';
import { GenericConfirmationModalComponent } from '../../../modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { InventoryService } from '../../inventory.service';
import { CarriersService } from '../carriers.service';

@Component({
  selector: 'app-carrier-edit',
  templateUrl: './carrier-edit.component.html',
  styleUrls: ['./carrier-edit.component.css'],
})
export class CarrierEditComponent implements OnInit {
  suppliers: Supplier[];
  supplier: Supplier;
  carrierId: number;
  carrier = new Carrier();
  oldName: string = null;
  oldTrackingMore = false;
  contracts: Contract[] = [];
  editMode = false;
  hasShipmentManagement: Observable<boolean>;

  @ViewChild('supplierInput', { static: true }) supplierInput: AppMatSelectComponent;

  constructor(
    private carriersService: CarriersService,
    private inventoryService: InventoryService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit() {
    this.hasShipmentManagement = this.authorizationService.hasPermission(Permissions.ShipmentManagement);

    this.route.params.subscribe((params: Params) => {
      this.carrierId = +params['id'];
      this.editMode = params['id'] != null;

      if (this.editMode) {
        this.carriersService.getCarrier(this.carrierId).subscribe((carrier: Carrier) => {
          this.carrier = carrier;
          this.carrier.contracts = null;
          this.oldName = this.carrier.name;
          this.oldTrackingMore = this.carrier.trackingMore;
        });

        this.carriersService.getContractByCarrier(this.carrierId).subscribe((contracts: Contract[]) => {
          this.contracts = contracts;
        });
      }

      this.inventoryService.getAllSuppliers().subscribe((suppliers: Supplier[]) => (this.suppliers = suppliers));
    });
  }

  onCancel() {
    this.router.navigate(['inventory', 'carriers']);
  }

  onSubmit() {
    this.carrier.name = this.carrier.name.toUpperCase();
    this.carriersService.saveCarrier(this.carrier).subscribe((carrier) => {
      carrier.contracts = null;
      this.carrier = carrier;
      this.editMode = true;
      this.oldName = this.carrier.name;
      this.oldTrackingMore = this.carrier.trackingMore;
    });
  }

  onDelete() {
    const message = 'Deleting carrier ' + this.carrier.name;
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '350px',
      data: message,
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.carriersService.deleteCarrier(this.carrierId).subscribe(() => {
          console.log('Carrier deleted.');
          this.router.navigate(['inventory', 'carriers']);
        });
      } else {
        console.log('Carrier not deleted.');
      }
    });
  }

  addContract() {
    const contract = new Contract();
    contract.supplier = this.supplier;
    contract.carrier = this.carrier;

    this.carriersService.saveContract(contract).subscribe((newContract) => {
      this.contracts.push(newContract);
      this.supplier = null;
    });
  }

  removeContract(index: number) {
    const contract = this.contracts[index];

    this.carriersService.deleteContract(contract.id).subscribe((res) => {
      if (res) {
        this.contracts.splice(index, 1);
      }
    });
  }
}
