import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Lot } from '@models/lot-model';
import { LotConfigurationsService } from '@container/configuration/lot-configurations/lot-configurations.service';
import { GenerateOrderedConfigurationDto } from '@models/lot-configuration-dto-model';
import { LotsService } from '@shared/lots.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-generate-ordered-for-configuration-modal',
  templateUrl: './generate-ordered-for-configuration-modal.component.html',
  styleUrls: ['./generate-ordered-for-configuration-modal.component.scss'],
})
export class GenerateOrderedForConfigurationModalComponent implements OnInit {
  generateOrderedConfigurations: GenerateOrderedConfigurationDto[] = [];
  selectedConfigurations: GenerateOrderedConfigurationDto[] = [];

  constructor(
    public dialogRef: MatDialogRef<GenerateOrderedForConfigurationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Lot,
    private lotConfigurationService: LotConfigurationsService,
    private lotService: LotsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.lotConfigurationService
      .getGenerateOrderedConfigurations(this.data.lotConfigurationId)
      .subscribe((r) => (this.generateOrderedConfigurations = r));
  }

  selectedCheckbox(c: GenerateOrderedConfigurationDto) {
    c['selected'] = c['selected'] ? !c['selected'] : true;
    this.selectedConfigurations = this.generateOrderedConfigurations.filter((it) => it['selected']);
  }

  generateAll() {
    this.doGenerate([]);
  }
  generateSelected() {
    this.doGenerate(this.selectedConfigurations);
  }

  private doGenerate(configurations: GenerateOrderedConfigurationDto[]) {
    const selectedConfigurationsIds = configurations.map((it) => it.id);
    this.lotService.generateOrderedForConfiguration(this.data.id, this.data.lotConfigurationId, selectedConfigurationsIds).subscribe(() => {
      this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
      this.dialogRef.close();
    });
  }
}
