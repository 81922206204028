<h2 mat-dialog-title>
  <span class="dialog-title">Manual Generate Ordered for Lot {{ data.name }}</span>
  <span class="dialog-close-icon padding-right-30"
    ><i class="fa fa-times exit-icon margin-bottom-10" mat-dialog-close style="cursor: pointer"></i
  ></span>
</h2>

<div mat-dialog-content>
  <div class="row">
    <div class="col-md-10 col-md-offset-1">
      <div class="row config-row" *ngFor="let c of generateOrderedConfigurations">
        <div class="col-md-2"><mat-checkbox (change)="selectedCheckbox(c)"></mat-checkbox></div>
        <div class="col-md-2"><b>Margin:</b> {{ c.margin }}</div>
        <div class="col-md-2"><b>VirtMargin:</b> {{ c.virtualMargin }}</div>
        <div class="col-md-2"><b>VirtNetMargin:</b> {{ c.virtualNetMargin }}</div>
        <div class="col-md-2"><b>Iterations:</b> {{ c.generateOrderedIterations }}</div>
        <div class="col-md-2"><b>Decrease:</b> {{ c.generateOrderedDecreaseMarginPerIteration }}</div>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button class="btn-product margin-right-6" (click)="generateAll()">Generate all</button>
  <button class="btn-product margin-right-6" (click)="generateSelected()" [disabled]="selectedConfigurations.length === 0">
    Generate selected
  </button>
  <button class="btn-product" mat-dialog-close>Cancel</button>
</div>
