import { JsonObject, JsonProperty } from 'json2typescript';
import { isNilty } from '../../core/utils.service';
import { Contract } from '../contract.model';
import { DateConverter, fromDateToFiltersString } from '../date-converter';
import { MatChipsData } from '../mat-chips-data-model';
import { Vendor } from '../vendor-model';
import { PaginationAndSorting } from './pagination-and-sorting-model';

@JsonObject('ClaimFilters')
export class ClaimFilters extends PaginationAndSorting {
  @JsonProperty('invoiceNumber', String, true) invoiceNumber: string = undefined;
  @JsonProperty('invoiceDate', DateConverter, true) invoiceDate: Date = undefined;
  @JsonProperty('suffix', String, true) suffix: string = undefined;
  @JsonProperty('amount', Number, true) amount: number = undefined;
  @JsonProperty('vendor', Number, true) vendor: number = undefined;
  @JsonProperty('vendorChild', Number, true) vendorChild: number = undefined;
  @JsonProperty('supplier', String, true) supplier: string = undefined;
  @JsonProperty('carrierContracts', [Number], true) carrierContracts: number[] = undefined;
  @JsonProperty('trackingNumber', String, true) trackingNumber: string = undefined;
  @JsonProperty('status', String, true) status: string = undefined;
  @JsonProperty('disputeId', String, true) disputeId: string = undefined;
  @JsonProperty('caseId', String, true) caseId: string = undefined;
  @JsonProperty('deliveryDate', DateConverter, true) deliveryDate: Date = undefined;
  @JsonProperty('hasPod', Boolean, true) hasPod: boolean = undefined;
  @JsonProperty('hasRemittance', Boolean, true) hasRemittance: boolean = undefined;
  @JsonProperty('po', String, true) po: string = undefined;
  @JsonProperty('settlement', Boolean, true) settlement: boolean = undefined;
}

export const claimFiltersMap = (
  invoiceNumber: string,
  invoiceDate: Date,
  suffix: string,
  amount: number,
  vendor: Vendor,
  supplier: string,
  carrierContracts: Contract[],
  trackingNumber: string,
  status: string,
  disputeId: string,
  caseId: string,
  deliveryDate: Date,
  hasPod: string,
  hasRemittance: string,
  po: string,
  settlement: string
): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (!isNilty(invoiceNumber)) {
    map.push(new MatChipsData('Invoice Number', invoiceNumber));
  }
  if (!isNilty(invoiceDate)) {
    map.push(new MatChipsData('Invoice Date', fromDateToFiltersString(invoiceDate)));
  }
  if (!isNilty(suffix)) {
    map.push(new MatChipsData('Suffix', suffix));
  }
  if (!isNilty(amount)) {
    map.push(new MatChipsData('Amount', amount));
  }
  if (!isNilty(vendor)) {
    map.push(new MatChipsData('Vendor', vendor.code));
  }
  if (!isNilty(supplier)) {
    map.push(new MatChipsData('Supplier', supplier));
  }
  if (!isNilty(carrierContracts)) {
    map.push(new MatChipsData('Carrier', carrierContracts.map((carrierContract) => carrierContract.name).join()));
  }
  if (!isNilty(trackingNumber)) {
    map.push(new MatChipsData('Tracking Number', trackingNumber));
  }
  if (!isNilty(status)) {
    map.push(new MatChipsData('Status', status));
  }
  if (!isNilty(disputeId)) {
    map.push(new MatChipsData('Dispute ID', disputeId));
  }
  if (!isNilty(caseId)) {
    map.push(new MatChipsData('Case ID', caseId));
  }
  if (!isNilty(deliveryDate)) {
    map.push(new MatChipsData('Delivery Date', fromDateToFiltersString(deliveryDate)));
  }
  if (!isNilty(hasPod)) {
    map.push(new MatChipsData('Has Pod', hasPod));
  }
  if (!isNilty(hasRemittance)) {
    map.push(new MatChipsData('Has Remittance', hasRemittance));
  }
  if (!isNilty(po)) {
    map.push(new MatChipsData('PO', po));
  }
  if (!isNilty(settlement)) {
    map.push(new MatChipsData('Settlement 2022', settlement));
  }

  return map;
};
