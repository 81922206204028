import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PosService } from '../../pos.service';
import { Po } from '../../../models/po-model';
import { PoItem } from '../../../models/po-item-model';
import { ConverterService } from '../../../core/converter.service';
import { PoaLog } from '../../../models/poa-logs-model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { isNilty } from '../../../core/utils.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-poa-history-modal',
  templateUrl: './poa-history-modal.component.html',
  styleUrls: ['./poa-history-modal.component.css'],
})
export class PoaHistoryModalComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  po: Po;
  poItems: PoItem[];

  poaLogsDataSource: MatTableDataSource<PoaLog>;
  poaLogsColumns = ['downloadEdi', 'source', 'sentDate', 'totalAcceptedQuantity', 'details'];

  selectedPoa: PoaLog;
  changedSelectedPoa = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<PoaHistoryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { poId: number },
    private posService: PosService,
    private converterService: ConverterService
  ) {}

  ngOnInit() {
    this.poaLogsDataSource = new MatTableDataSource<PoaLog>();
    this.poaLogsDataSource.paginator = this.paginator;

    this.posService.getPo(this.data.poId).subscribe((po) => {
      this.po = po;
      this.poaLogsFromPo();
    });
  }

  poaLogsFromPo() {
    if (isNilty(this.po.poaLog)) {
      this.po.convertedPoaLogs = [];
    } else {
      const array = JSON.parse(this.po.poaLog) as string[];
      this.po.convertedPoaLogs = array.map((it) => this.converterService.fromJSONtoObj(it, PoaLog));
      // These are returned from the oldest to the newest, so I'm inverting them
      this.poaLogsDataSource.data = this.po.convertedPoaLogs.sort(() => -1);

      this.selectedPoa = this.po.convertedPoaLogs[0];
    }
  }

  downloadEdi(url: string) {
    window.open(url, '_blank');
  }

  close() {
    this.dialogRef.close();
  }

  poaSelected(log: PoaLog) {
    this.selectedPoa = log;
    this.changedSelectedPoa.next();
  }
}
