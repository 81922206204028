export class PublicationStatus {
  code: number;
  description: string;
}

export const publicationStatusList: PublicationStatus[] = [
  { code: 1, description: 'Online' },
  { code: 2, description: 'Suspended' },
  { code: 3, description: 'Offline' },
  { code: 4, description: 'toPublish' },
];
