<div class="dialog-container">
  <h1 mat-dialog-title>Cannot ship: the origin of this shipment is missing some address information.</h1>

  <div mat-dialog-content>
    You can go <a [routerLink]="['/inventory/stock-locations/edit/', data['stockLocationId']]" [mat-dialog-close]="false">fill them in</a>.
  </div>

  <div mat-dialog-actions>
    <div class="row width-100per">
      <div class="col-md-12 text-center width-100per">
        <button class="btn-product" [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
      </div>
    </div>
  </div>
</div>
