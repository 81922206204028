import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { isNilty } from '../../../../core/utils.service';
import { Carrier } from '../../../../models/carrier.model';
import { CarrierFilters } from '../../../../models/filters/carrier-filters';
import { CarriersService } from '../carriers.service';

@Component({
  selector: 'app-carriers-list',
  templateUrl: './carriers-list.component.html',
  styleUrls: ['./carriers-list.component.css'],
})
export class CarriersListComponent implements OnInit {
  constructor(private carriersService: CarriersService) {}

  dataSource: MatTableDataSource<Carrier>;

  displayedColumns = ['name', 'contracts', 'trackingMore', 'edit'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  resultsNumber: Observable<number>;

  ngOnInit() {
    this.resultsNumber = this.carriersService.resultsNumber;

    if (isNilty(this.carriersService.carrierFilters)) {
      this.carriersService.carrierFilters = new CarrierFilters();
    }
    this.carriersService.carrierFilters.name = '';

    this.paginator.pageSize = this.carriersService.carrierFilters.pageSize;
    this.paginator.pageIndex = this.carriersService.carrierFilters.pageNumber;

    this.dataSource = new MatTableDataSource([]);
    this.loadCarrier(this.carriersService.carrierFilters);
  }

  loadCarrier(filters: CarrierFilters) {
    this.carriersService.getCarriers(filters).subscribe((ch: Carrier[]) => {
      this.dataSource.data = ch;
    });
  }

  loadPage() {
    this.carriersService.carrierFilters.pageNumber = this.paginator.pageIndex;
    this.carriersService.carrierFilters.pageSize = this.paginator.pageSize;
    this.loadCarrier(this.carriersService.carrierFilters);
  }

  onSort() {
    this.carriersService.carrierFilters.sortBy = this.sort.active;
    this.carriersService.carrierFilters.sortDirection = this.sort.direction;
    this.loadCarrier(this.carriersService.carrierFilters);
  }

  applyFilter(filterValue: string) {
    this.carriersService.carrierFilters.name = filterValue.toUpperCase();
    this.paginator.pageIndex = this.carriersService.carrierFilters.pageNumber = 0;
    this.loadCarrier(this.carriersService.carrierFilters);
  }
}
