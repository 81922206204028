import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';

@JsonObject('Warehouse')
export class Warehouse extends BaseObject {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('code', String, false) code: string = undefined;
  @JsonProperty('businessName', String, true) businessName: string = undefined;
  @JsonProperty('address', String, true) address: string = undefined;
  @JsonProperty('city', String, true) city: string = undefined;
  @JsonProperty('postalCode', String, true) postalCode: string = undefined;
  @JsonProperty('region', String, true) region: string = undefined;
  @JsonProperty('country', String, true) country: string = undefined;
  @JsonProperty('countryCode', String, true) countryCode: string = undefined;
  @JsonProperty('phone', String, true) phone: string = undefined;
  @JsonProperty('mail', String, true) mail: string = undefined;
  @JsonProperty('marketplace', String, true) marketplace: string = undefined;
  @JsonProperty('glnCode', String, true) glnCode: string = undefined;
  @JsonProperty('brandonGln', String, true) brandonGln: string = undefined;
  @JsonProperty('name', String, false) name: string = undefined;
  @JsonProperty('minimalValue', Number, true) minimalValue: number = undefined;

  get description(): string {
    return `${this.code} - ${this.name}`;
  }
}
