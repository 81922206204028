import { JsonObject, JsonProperty } from 'json2typescript';
import { Supplier } from './supplier-model';

@JsonObject('ContractCarrier')
export class ContractCarrier {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('supplier', Supplier, true) supplier: Supplier = undefined;
}

@JsonObject('Carrier')
export class Carrier {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('contracts', [ContractCarrier], true) contracts: ContractCarrier[] = undefined;
  @JsonProperty('trackingMore', Boolean, true) trackingMore: boolean = undefined;
}
