<app-main-spos-filters (onFilter)="onFilter($event)"></app-main-spos-filters>

<div class="bulk-actions-div">
  <mat-toolbar class="bar-custom">
    <div class="container">
      <div class="row">
        <div class="col-md-2">
          <button class="box-shadow-none btn-custom" routerLink="/spos">Show SPOs</button>
        </div>
        <div class="col-md-8">
          <button
            class="box-shadow-none btn-custom margin-right-6"
            [disabled]="!bulkActionsActive"
            [matMenuTriggerFor]="exportPoItemsChoice"
          >
            Export SPO Items
          </button>
          <mat-menu #exportPoItemsChoice="matMenu">
            <button mat-menu-item (click)="export('excel')">Save as Excel</button>
            <button mat-menu-item (click)="export('csv')">Save as CSV</button>
          </mat-menu>
        </div>
      </div>
    </div>
  </mat-toolbar>
</div>

<hr />

<app-spo-kpi></app-spo-kpi>

<div class="container table-outer">
  <mat-paginator #paginator [length]="resultsNumber | async" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()">
  </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="check" sticky>
        <mat-header-cell *matHeaderCellDef class="checker-col">
          <mat-checkbox (change)="toggleAll($event.checked)" [ngModel]="mainChecker"></mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let spo; let i = index" class="checker-col">
          <mat-checkbox (change)="onSelected($event.checked, spo)" [ngModel]="spo.checked"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="spoCode">
        <mat-header-cell *matHeaderCellDef mat-sort-header="spoCode" class="shipment-code-column"> SPO Code </mat-header-cell>
        <mat-cell class="shipment-code-column" *matCellDef="let spo">
          <a [routerLink]="['/spos', spo.supplierPurchaseOrder?.id]">{{ spo.supplierPurchaseOrder?.code }}</a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="orderId" sticky>
        <mat-header-cell class="order-id-column" *matHeaderCellDef mat-sort-header="orderId">Order ID</mat-header-cell>
        <mat-cell class="order-id-column" *matCellDef="let spo">{{ spo.supplierPurchaseOrder.orderId }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="parentVendor">
        <mat-header-cell *matHeaderCellDef mat-sort-header="parentVendor" class="vendor-column"> Parent Vendor </mat-header-cell>
        <mat-cell *matCellDef="let spo" class="vendor-column">{{ spo.supplierPurchaseOrder?.vendor?.code }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="destination" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="destination" class="destination-column"> Destination </mat-header-cell>
        <mat-cell
          class="destination-column"
          *matCellDef="let spo"
          [matTooltip]="
            spo.supplierPurchaseOrder.destination
              ? spo.supplierPurchaseOrder.destination.code + ' - ' + spo.supplierPurchaseOrder.destination.name
              : ''
          "
          matTooltipPosition="below"
        >
          <p>
            {{
              spo.supplierPurchaseOrder.destination
                ? spo.supplierPurchaseOrder.destination.code + ' - ' + spo.supplierPurchaseOrder.destination.name
                : ''
            }}
          </p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="extra">
        <mat-header-cell class="extra-flag-column" *matHeaderCellDef> Extra </mat-header-cell>
        <mat-cell class="extra-flag-column" *matCellDef="let spo">
          <i [ngClass]="spo.supplierPurchaseOrder.isExtra | booleanIconPipe" aria-hidden="true"></i>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="crossUkBorder">
        <mat-header-cell class="extra-flag-column" *matHeaderCellDef> Cross UK </mat-header-cell>
        <mat-cell class="extra-flag-column" *matCellDef="let spo">
          <i [ngClass]="spo.supplierPurchaseOrder.crossUkBorder | booleanIconPipe" aria-hidden="true"></i>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="earliestDeliveryDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="earliestDeliveryDate" class="date-column"> Earliest Date </mat-header-cell>
        <mat-cell class="date-column" *matCellDef="let spo">
          <p>{{ spo.supplierPurchaseOrder.earliestDeliveryDate }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="latestDeliveryDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="latestDeliveryDate" class="date-column"> Latest Date </mat-header-cell>
        <mat-cell class="date-column" *matCellDef="let spo">
          <p>{{ spo.supplierPurchaseOrder.latestDeliveryDate }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="latestPickupDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="latestPickupDate" class="date-column"> Latest Pickup Date </mat-header-cell>
        <mat-cell class="date-column" *matCellDef="let spo">
          <p>{{ spo.supplierPurchaseOrder.latestPickupDate }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ean" sticky>
        <mat-header-cell class="ean-column" *matHeaderCellDef mat-sort-header="ean"> EAN </mat-header-cell>
        <mat-cell class="ean-column" *matCellDef="let spo">
          <a [routerLink]="['/inventory/products', spo.product.id, 'details']">{{ spo.product.ean }}</a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="obsoleteWithAccepted">
        <mat-header-cell class="bigger-boolean-column" *matHeaderCellDef> Obsolete with accepted </mat-header-cell>
        <mat-cell class="bigger-boolean-column" *matCellDef="let spo">
          <i [ngClass]="spo?.obsoleteWithAcceptedGreaterThanZero | booleanIconPipe" aria-hidden="true"></i>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef mat-sort-header="title" class="title-column"> Title </mat-header-cell>
        <mat-cell *matCellDef="let spo" class="title-column">
          <p>{{ spo.product.title }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="supplier" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="supplier" class="supplier-column"> Supplier </mat-header-cell>
        <mat-cell
          class="supplier-column"
          *matCellDef="let spo"
          [matTooltip]="spo?.supplierPurchaseOrder?.supplier?.name"
          matTooltipPosition="below"
        >
          <p>{{ spo?.supplierPurchaseOrder?.supplier?.name }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="purchasePrice">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="purchasePrice">
          Purchase <br />
          price
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.purchasePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="requestedQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="requestedQuantity">
          Requested <br />
          quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let spo">
          <p>{{ spo.requestedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="confirmedQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="confirmedQuantity">
          Confirmed <br />
          quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let spo">
          <p>{{ spo.confirmedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="orderedQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="orderedQuantity">
          Ordered <br />
          quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let spo">
          <p>{{ spo.orderedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="margin">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="margin"> Margin </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.margin | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="percentMargin">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="percentMargin"> Per cent margin </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let spo">
          <p>{{ spo.percentMargin | percentageFromDecimal }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="requestedValue">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="requestedValue">
          Requested <br />
          value
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.requestedValue | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="confirmedValue">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="confirmedValue">
          Confirmed <br />
          value
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.confirmedValue | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="confirmedPurchasePrice">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="confirmedPurchasePrice">
          Confirmed <br />
          purchase price
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.confirmedPurchasePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="virtualConfirmedPurchasePrice">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="virtualConfirmedPurchasePrice">
          Virtual Confirmed <br />
          purchase price
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.virtualConfirmedPurchasePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="orderedValue">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="orderedValue">
          Ordered <br />
          value
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.orderedValue | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="orderedPurchasePrice">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="orderedPurchasePrice">
          Ordered <br />
          purchase price
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.orderedPurchasePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="virtualOrderedPurchasePrice">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="virtualOrderedPurchasePrice">
          Virtual Ordered <br />
          purchase price
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.virtualOrderedPurchasePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="virtualConfirmedMargin">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="virtualConfirmedMargin">
          Virtual Confirmed Margin
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let spo">
          <p>{{ spo.virtualConfirmedMargin | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="virtualConfirmedPercentMargin">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="virtualConfirmedPercentMargin">
          Virtual Confirmed margin %
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let spo">
          <p>{{ spo.virtualConfirmedPercentMargin | percentageFromDecimal }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell class="shipment-status-column" *matHeaderCellDef mat-sort-header="status"> Status </mat-header-cell>
        <mat-cell class="shipment-status-column" *matCellDef="let spo">
          <p>{{ spo.supplierPurchaseOrder?.spoStatus | spoStatusPipe }}</p>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
