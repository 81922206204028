import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { SpoItemsComponent } from './spo-items/spo-items.component';
import { SpoItemsBulkActionsComponent } from './spo-items/spo-items-bulk-actions/spo-items-bulk-actions.component';
import { SpoComponent } from './spos/spo.component';
import { SpoBulkActionsComponent } from './spos/spo-bulk-actions/spo-bulk-actions.component';
import { SpoFiltersComponent } from './spo-filters/spo-filters.component';
import { SingleSpoDetailsModule } from '@shared/single-spo-detail/single-spo-details.module';
import { SpoStatusPipe } from '@shared/my-pipes';
// eslint-disable-next-line max-len
import { GenerateOrderedResultsComponent } from '@container/lot-edit-section/spo-module/generate-ordered-results/generate-ordered-results.component';

@NgModule({
  declarations: [
    SpoItemsComponent,
    SpoItemsBulkActionsComponent,
    SpoComponent,
    SpoItemsComponent,
    SpoBulkActionsComponent,
    SpoFiltersComponent,
    GenerateOrderedResultsComponent,
  ],
  imports: [SharedModule, SingleSpoDetailsModule],
  providers: [SpoStatusPipe],
})
export class SpoModule {}
