<div class="container padding-top-20">
  <div class="row">
    <div class="col-md-10 col-md-offset-1">
      <div class="row summary-box" *ngFor="let s of summaries" [ngClass]="s.winner ? 'winning' : ''">
        <div class="col-md-12">
          <div class="row summary-header">
            <div class="col-md-4">
              <i class="fa fa-trophy" *ngIf="s.winner"></i>
              Configuration {{ s.generateOrderedConfiguration?.id }}
            </div>
            <div class="col-md-8 configuration-details" *ngIf="s.generateOrderedConfiguration">
              <span>Margin: {{ s.generateOrderedConfiguration.margin ? s.generateOrderedConfiguration.margin : '--' }}</span>
              <span
                >VirtualMargin:
                {{ s.generateOrderedConfiguration.virtualMargin ? s.generateOrderedConfiguration.virtualMargin : '--' }}</span
              >
              <span
                >VirtualNetMargin:
                {{ s.generateOrderedConfiguration.virtualNetMargin ? s.generateOrderedConfiguration.virtualNetMargin : '--' }}</span
              >
              <span
                >Iterations:
                {{
                  s.generateOrderedConfiguration.generateOrderedIterations ? s.generateOrderedConfiguration.generateOrderedIterations : '--'
                }}</span
              >
              <span
                >DecreaseMarginPerIteration:
                {{
                  s.generateOrderedConfiguration.generateOrderedDecreaseMarginPerIteration
                    ? s.generateOrderedConfiguration.generateOrderedDecreaseMarginPerIteration
                    : '--'
                }}</span
              >
            </div>
          </div>

          <div class="row summary-body">
            <div class="col-md-12">
              <span><b>VirtualMargin</b>: {{ s.summary.virtualMargin | myCurrency }}</span>
              <span><b>VirtualPercentMargin</b>: {{ s.summary.virtualPercentMargin | percentageFromDecimal }}</span>
              <span><b>OrderedQuantity</b>: {{ s.summary.orderedQuantity }}</span>
            </div>

            <div class="col-md-12">
              <span><b>OrderedValue</b>: {{ s.summary.orderedValue | myCurrency }}</span>
              <span><b>OrderedPurchasePrice</b>: {{ s.summary.orderedPurchasePrice | myCurrency }}</span>
              <span><b>Margin</b>: {{ s.summary.margin | myCurrency }}</span>
              <span><b>PercentMargin</b>: {{ s.summary.percentMargin | percentageFromDecimal }}</span>
              <span><b>VirtualOrderedPurchasePrice</b>: {{ s.summary.virtualOrderedPurchasePrice | myCurrency }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
