import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LotsService } from '../../../shared/lots.service';
import { Lot, LotType } from '../../../models/lot-model';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { GenericErrorModalComponent } from '../../modal/generic-error-modal/generic-error-modal.component';
import { FulfilmentModalComponent } from '../warehouse-fulfilment/fulfilment-modal/fulfilment-modal.component';
import { GenericConfirmationModalComponent } from '../../modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { EnvironmentService } from '../../../environment.service';
import { ENTITIES } from '../../../entities';
import { AuthorizationService } from '../../../core/authorization.service';
import { Permissions } from '../../../models/permission-model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { GenerateShipmentsRecap } from '../../../models/generate-shipments-recap-model';
// eslint-disable-next-line
import { GenerateShipmentsRecapModalComponent } from '../warehouse-fulfilment/generate-shipments-recap-modal/generate-shipments-recap-modal.component';
import { isNilty } from '../../../core/utils.service';
import { GenerateShipmentsEntitiesEnum } from '../../../models/generate-shipments-entities-enum';
// eslint-disable-next-line
import { IgnoreFulfilmentParametersModalComponent } from './ignore-fulfilment-parameters-modal/ignore-fulfilment-parameters-modal.component';
import { AmbraJobService } from '../../../shared/ambra-job.service';
import { FulfilmentJobStatus } from '../../../models/job-status/fulfilment-job-status-model';
import { GenerateOrderedFromLsaJobStatus } from '../../../models/job-status/generate-ordered-from-lsa-status-model';
import { GenerateOrderedFromSpoJobStatus } from '../../../models/job-status/generate-ordered-from-spo-status-model';
import { SelectSupplierModalComponent } from './select-supplier-modal/select-supplier-modal.component';
// eslint-disable-next-line max-len
import { GenerateOrderedForConfigurationModalComponent } from '@container/lot-edit-section/lot-edit-menu/generate-ordered-for-configuration-modal/generate-ordered-for-configuration-modal.component';

@Component({
  selector: 'app-lot-edit-menu',
  templateUrl: './lot-edit-menu.component.html',
  styleUrls: ['./lot-edit-menu.component.scss'],
})
export class LotEditMenuComponent implements OnInit, OnDestroy {
  @Input() id: string;

  currentLot = new Observable<Lot>();
  fulfilmentInProgress: number;
  interval;
  hasLotManagement: Observable<boolean>;
  hasFulfillmentCreation: Observable<boolean>;
  hasShipmentCreation: Observable<boolean>;
  hasAdmin: Observable<boolean>;

  fulfilmentJobInProgress = false;
  fulfilmentJobStatusUpdated: Subject<FulfilmentJobStatus> = new Subject<FulfilmentJobStatus>();

  generateOrderedFromLsaJobInProgress = false;
  generateOrderedFromLsaJobUpdated: Subject<GenerateOrderedFromLsaJobStatus> = new Subject<GenerateOrderedFromLsaJobStatus>();

  generateOrderedFromSpoJobInProgress = false;
  generateOrderedFromSpoJobUpdated: Subject<GenerateOrderedFromSpoJobStatus> = new Subject<GenerateOrderedFromSpoJobStatus>();

  constructor(
    private lotsService: LotsService,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    private environmentService: EnvironmentService,
    private authorizationService: AuthorizationService,
    private ambraJobService: AmbraJobService
  ) {}

  ngOnInit() {
    this.currentLot = this.lotsService.lotSubject;
    this.currentLot.subscribe((lot: Lot) => {
      this.fulfilmentInProgress = lot.fulfilmentInProgress;
    });

    this.hasLotManagement = this.authorizationService.hasPermission(Permissions.LotManagement);
    this.hasFulfillmentCreation = this.authorizationService.hasPermission(Permissions.FulfillmentCreation);
    this.hasShipmentCreation = this.authorizationService.hasPermission(Permissions.ShipmentCreation);
    this.hasAdmin = this.authorizationService.hasPermission(Permissions.Administration);

    this.checkIfFulfilmentIsInProgress();
    this.checkIfGenerateOrderedFromLsaInProgress();
    this.checkIfGenerateOrderedFromSpoInProgress();
  }

  generateOrderedFromSPO() {
    this.generateOrderedQuantities(ENTITIES.GENERATE_ORDERED_SPO);
  }

  generateOrderedFromLSA(selectSupplier: boolean) {
    this.generateOrderedQuantities(ENTITIES.GENERATE_ORDERED_LSA, selectSupplier);
  }

  manualGenerateOrderedFromConfiguration() {
    this.dialog.open(GenerateOrderedForConfigurationModalComponent, { data: this.lotsService.currentLot });
  }

  generateOrderedQuantities(entity: string, selectSupplier: boolean = false) {
    const message =
      entity === ENTITIES.GENERATE_ORDERED_SPO ? 'Generating Ordered Quantities from SPOs.' : 'Generating Ordered Quantities from LSAs.';
    this.dialog
      .open(GenericConfirmationModalComponent, {
        width: '400px',
        data: message,
      })
      .afterClosed()
      .subscribe((resp: boolean) => {
        if (resp) {
          if (selectSupplier) {
            const dialogRef = this.dialog.open(SelectSupplierModalComponent, {
              width: '350px',
              data: { lotId: this.lotsService.currentLotId },
            });

            dialogRef.afterClosed().subscribe((result: number[]) => {
              if (result) {
                this.callGenerateOrdered(entity, result);
              }
            });
          } else {
            this.callGenerateOrdered(entity);
          }
        }
      });
  }

  callGenerateOrdered(entity: string, supplierIds: number[] | null = null) {
    this.lotsService.generateOrdered(entity, supplierIds).subscribe(() => {
      this.snackBar.open('Request sent.', 'CLOSE')._dismissAfter(2000);
      if (entity === ENTITIES.GENERATE_ORDERED_LSA) {
        this.subscribeToGenerateOrderedFromLsaStatus();
      } else if (entity === ENTITIES.GENERATE_ORDERED_SPO) {
        this.subscribeToGenerateOrderedFromSpoStatus();
      } else {
        //  TODO
      }
    });
  }

  generateFulfilment() {
    if (this.lotsService.currentLot.type === LotType.PREBUY) {
      this.dialog
        .open(IgnoreFulfilmentParametersModalComponent)
        .afterClosed()
        .subscribe((ignoreFulfilmentParameters: boolean) => {
          if (!isNilty(ignoreFulfilmentParameters)) {
            this.doGenerateFulfilment(ignoreFulfilmentParameters);
          }
        });
    } else {
      this.doGenerateFulfilment();
    }
  }

  private doGenerateFulfilment(ignoreFulfilmentParameters: boolean = false) {
    this.fulfilmentInProgress = 1;
    this.lotsService.currentLot.fulfilmentInProgress = 1;

    this.lotsService.hasFulfilments(this.lotsService.currentLotId).subscribe((hasFulfilments: boolean) => {
      if (!hasFulfilments) {
        this.callGenerateFulfilment(this.lotsService.currentLotId, ignoreFulfilmentParameters);
      } else {
        this.callDropAndGenerateFulfilment(this.lotsService.currentLotId, ignoreFulfilmentParameters);
      }
    });
  }

  generateShipments() {
    this.lotsService.getShipmentGenerateRecap(this.lotsService.currentLotId).subscribe((recap: GenerateShipmentsRecap) => {
      this.dialog
        .open(GenerateShipmentsRecapModalComponent, {
          width: '500px',
          data: recap,
        })
        .afterClosed()
        .subscribe((entity: GenerateShipmentsEntitiesEnum) => {
          if (!isNilty(entity)) {
            const path = this.environmentService.getRestEndpoint(entity.valueOf()) + '?lot=' + this.lotsService.currentLotId;

            this.http.get(path).subscribe((callResp: string) => {
              if (callResp === '0') {
                this.router.navigate(['lot-edit/', this.lotsService.currentLotId, 'po']);
                this.snackBar.open('Generating shipments.', 'CLOSE')._dismissAfter(5000);
                return;
              } else {
                this.dialog.open(GenericErrorModalComponent, {
                  width: '350px',
                  data: callResp,
                });
              }
            });
          }
        });
    });
  }

  callGenerateFulfilment(lotId: number, ignoreFulfilmentParameters: boolean) {
    const path =
      this.environmentService.getRestEndpoint('generateFulfilment') +
      '?lot=' +
      lotId +
      '&ignoreConfigurationParams=' +
      ignoreFulfilmentParameters;
    console.log('generating fulfilment for lot ', lotId);

    this.http.get(path).subscribe((resp: string) => {
      if (resp === '0') {
        this.subscribeToFulfilmentStatus();
        this.router.navigate(['lot-edit/', lotId, 'po']);
        this.snackBar.open('Generating Fulfilments.', 'CLOSE')._dismissAfter(5000);
        return;
      } else {
        this.dialog.open(GenericErrorModalComponent, {
          width: '350px',
          data: resp,
        });
      }
    });
  }

  callDropAndGenerateFulfilment(lotId: number, ignoreFulfilmentParameters: boolean) {
    const dialogRef = this.dialog.open(FulfilmentModalComponent, {
      width: '350px',
      data: 'Fulfilment',
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        const path =
          this.environmentService.getRestEndpoint('dropGenerateFulfilment') +
          '?lot=' +
          lotId +
          '&ignoreConfigurationParams=' +
          ignoreFulfilmentParameters;

        this.http.get(path).subscribe((resp: string) => {
          if (resp === '0') {
            this.subscribeToFulfilmentStatus();
            this.router.navigate(['lot-edit/', lotId, 'po']);
            this.snackBar.open('Generating Fulfilments.', 'CLOSE')._dismissAfter(5000);
            return;
          } else {
            this.dialog.open(GenericErrorModalComponent, {
              width: '350px',
              data: resp,
            });
          }
        });
      } else {
        this.fulfilmentInProgress = 0;
        clearInterval(this.interval);
        return;
      }
    });
  }

  changeLotStatus(status: string) {
    this.lotsService.changeLotStatus(status).subscribe(() => {
      this.snackBar.open('Status changed to ' + status, 'CLOSE')._dismissAfter(5000);
      this.lotsService.getLot().subscribe(() => {});
    });
  }

  deleteLot() {
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '350px',
      data: 'Deleting lot ' + this.lotsService.currentLot.name + '.',
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.lotsService.deleteLot().subscribe(() => {
          console.log('Lot deleted!');
          this.router.navigate(['lots']);
        });
      } else {
        console.log('Lot not deleted.');
      }
    });
  }

  private checkIfFulfilmentIsInProgress() {
    this.ambraJobService.getFulfilmentJobStatus(this.lotsService.currentLotId).subscribe((s) => {
      if (!isNilty(s)) {
        this.subscribeToFulfilmentStatus();
      }
    });
  }
  private checkIfGenerateOrderedFromLsaInProgress() {
    this.ambraJobService.getGenerateOrderedForLsaStatus(this.lotsService.currentLotId).subscribe((s) => {
      if (!isNilty(s)) {
        this.subscribeToGenerateOrderedFromLsaStatus();
      }
    });
  }
  private checkIfGenerateOrderedFromSpoInProgress() {
    this.ambraJobService.getGenerateOrderedForSpoStatus(this.lotsService.currentLotId).subscribe((s) => {
      if (!isNilty(s)) {
        this.subscribeToGenerateOrderedFromSpoStatus();
      }
    });
  }

  private subscribeToFulfilmentStatus() {
    this.fulfilmentJobInProgress = true;
    this.interval = setInterval(() => {
      this.ambraJobService.getFulfilmentJobStatus(this.lotsService.currentLotId).subscribe((s) => {
        this.fulfilmentJobStatusUpdated.next(s);
      });
    }, 2000);
  }

  private subscribeToGenerateOrderedFromLsaStatus() {
    this.generateOrderedFromLsaJobInProgress = true;
    this.interval = setInterval(() => {
      this.ambraJobService.getGenerateOrderedForLsaStatus(this.lotsService.currentLotId).subscribe((s) => {
        this.generateOrderedFromLsaJobUpdated.next(s);
      });
    }, 2000);
  }
  private subscribeToGenerateOrderedFromSpoStatus() {
    this.generateOrderedFromSpoJobInProgress = true;
    this.interval = setInterval(() => {
      this.ambraJobService.getGenerateOrderedForSpoStatus(this.lotsService.currentLotId).subscribe((s) => {
        this.generateOrderedFromSpoJobUpdated.next(s);
      });
    }, 2000);
  }

  fulfilmentJobCompleted() {
    clearInterval(this.interval);
    this.fulfilmentJobInProgress = false;
    this.lotsService.getLot().subscribe(() => {
      if (this.lotsService.currentLot.fulfilmentInProgress === 0) {
        this.snackBar.dismiss();
        this.snackBar.open('Fulfilment generation has been completed.')._dismissAfter(5000);
      } else if (this.lotsService.currentLot.fulfilmentInProgress === 2) {
        this.snackBar.dismiss();
        this.snackBar.open('There was some error during Fulfilment generation.')._dismissAfter(5000);
      }
    });
  }
  fulfilmentJobStuck() {
    clearInterval(this.interval);
    this.snackBar.open('Fulfilment job seems stuck!')._dismissAfter(5000);
  }
  fulfilmentJobRetry() {
    this.checkIfFulfilmentIsInProgress();
  }

  generateOrderedFromLsaJobCompleted() {
    clearInterval(this.interval);
    this.snackBar.open('Generate Ordered from LSA has been completed.')._dismissAfter(5000);
    this.generateOrderedFromLsaJobInProgress = false;
  }
  generateOrderedFromLsaJobStuck() {
    clearInterval(this.interval);
    this.snackBar.open('Generate Ordered from LSA job seems stuck!')._dismissAfter(5000);
  }
  generateOrderedFromLsaJobRetry() {
    this.checkIfGenerateOrderedFromLsaInProgress();
  }

  generateOrderedFromSpoJobCompleted() {
    clearInterval(this.interval);
    this.snackBar.open('Generate Ordered from Spo has been completed.')._dismissAfter(5000);
    this.generateOrderedFromSpoJobInProgress = false;
  }
  generateOrderedFromSpoJobStuck() {
    clearInterval(this.interval);
    this.snackBar.open('Generate Ordered from Spo job seems stuck!')._dismissAfter(5000);
  }
  generateOrderedFromSpoJobRetry() {
    this.checkIfGenerateOrderedFromSpoInProgress();
  }

  goBack() {
    this.router.navigate(['/lots']);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
}
