import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-send-poa-modal',
  templateUrl: './send-poa-modal.component.html',
  styleUrls: ['./send-poa-modal.component.css'],
})
export class SendPoaModalComponent implements OnInit {
  message = 0;

  constructor(
    public dialogRef: MatDialogRef<SendPoaModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      mainChecker: boolean;
      selected: number;
      totalNumber: number;
    }
  ) {}

  ngOnInit() {
    this.message = this.data.mainChecker ? this.data.totalNumber - this.data.selected : this.data.selected;
  }
}
