import { NgModule } from '@angular/core';

import { LotEditMenuComponent } from './lot-edit-menu/lot-edit-menu.component';
import { LotEditRoutingModule } from './lot-edit-routing.module';
import { LotEditComponent } from './lot-edit.component';
import { PoModule } from './po-module/po.module';
import { WarehouseFulfilmentComponent } from './warehouse-fulfilment/warehouse-fulfilment.component';
// eslint-disable-next-line
import { WarehouseFulfilmentFiltersComponent } from './warehouse-fulfilment/warehouse-fulfilment-filters/warehouse-fulfilment-filters.component';
import { FulfilmentModalComponent } from './warehouse-fulfilment/fulfilment-modal/fulfilment-modal.component';
import { WarehouseFulfilmentKpiComponent } from './warehouse-fulfilment/warehouse-fulfilment-kpi/warehouse-fulfilment-kpi.component';
// eslint-disable-next-line
import { WarehouseFulfilmentBulkActionsComponent } from './warehouse-fulfilment/warehouse-fulfilment-bulk-actions/warehouse-fulfilment-bulk-actions.component';
import { SharedModule } from '../../shared.module';
// eslint-disable-next-line
import { SupplierAvailabilitiesBulkActionsComponent } from './supplier-availabilities/supplier-availabilities-bulk-actions/supplier-availabilities-bulk-actions.component';
import { SupplierAvailabilitiesComponent } from './supplier-availabilities/supplier-availabilities.component';
// eslint-disable-next-line
import { SupplierAvailabilitiesFiltersComponent } from './supplier-availabilities/supplier-availabilities-filters/supplier-availabilities-filters.component';
// eslint-disable-next-line
import { SupplierAvailabilitiesKpiComponent } from './supplier-availabilities/supplier-availabilities-kpi/supplier-availabilities-kpi.component';
import { SupplierAvailabilityService } from './supplier-availabilities/supplier-availability.service';
import { SpoModule } from './spo-module/spo.module';
import { ShipmentsModule } from './shipments-module/shipments.module';
import { FulfilmentService } from '../../shared/fulfilment.service';
// eslint-disable-next-line
import { GenerateShipmentsRecapModalComponent } from './warehouse-fulfilment/generate-shipments-recap-modal/generate-shipments-recap-modal.component';
// eslint-disable-next-line
import { IgnoreFulfilmentParametersModalComponent } from './lot-edit-menu/ignore-fulfilment-parameters-modal/ignore-fulfilment-parameters-modal.component';
import { AmbraJobService } from '../../shared/ambra-job.service';
import { LotFulfilmentJobProgressComponent } from './lot-edit-menu/fulfilment-job-progress/fulfilment-job-progress.component';
// eslint-disable-next-line
import { GenerateOrderedFromLsaJobProgressComponent } from './lot-edit-menu/generate-ordered-from-lsa-job-progress/generate-ordered-from-lsa-job-progress.component';
// eslint-disable-next-line
import { GenerateOrderedFromSpoJobProgressComponent } from './lot-edit-menu/generate-ordered-from-spo-job-progress/generate-ordered-from-spo-job-progress.component';
import { IndeterminateJobStatusComponent } from './lot-edit-menu/indeterminate-job-status.component';
import { LogDiscardedValueModule } from './log-discarded-value/log-discarded-value.module';
import { SelectSupplierModalComponent } from './lot-edit-menu/select-supplier-modal/select-supplier-modal.component';
import {
  GenerateOrderedForConfigurationModalComponent,
  // eslint-disable-next-line max-len
} from '@container/lot-edit-section/lot-edit-menu/generate-ordered-for-configuration-modal/generate-ordered-for-configuration-modal.component';

@NgModule({
  imports: [SharedModule, LotEditRoutingModule, PoModule, SpoModule, ShipmentsModule, LogDiscardedValueModule],
  declarations: [
    LotEditMenuComponent,
    LotEditComponent,
    WarehouseFulfilmentComponent,
    WarehouseFulfilmentFiltersComponent,
    FulfilmentModalComponent,
    WarehouseFulfilmentKpiComponent,
    WarehouseFulfilmentBulkActionsComponent,
    SupplierAvailabilitiesKpiComponent,
    SupplierAvailabilitiesComponent,
    SupplierAvailabilitiesBulkActionsComponent,
    SupplierAvailabilitiesFiltersComponent,
    GenerateShipmentsRecapModalComponent,
    IgnoreFulfilmentParametersModalComponent,
    LotFulfilmentJobProgressComponent,
    GenerateOrderedFromLsaJobProgressComponent,
    GenerateOrderedFromSpoJobProgressComponent,
    IndeterminateJobStatusComponent,
    SelectSupplierModalComponent,
    GenerateOrderedForConfigurationModalComponent,
  ],
  providers: [SupplierAvailabilityService, FulfilmentService, AmbraJobService],
  entryComponents: [
    FulfilmentModalComponent,
    GenerateShipmentsRecapModalComponent,
    IgnoreFulfilmentParametersModalComponent,
    SelectSupplierModalComponent,
  ],
})
export class LotEditModule {}
