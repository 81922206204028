import { Component, Input } from '@angular/core';
import { MarkupConstraint } from '../../../../../../models/publication-rule';

@Component({
  selector: 'app-markup-constraints-edit',
  templateUrl: './markup-constraints-edit.component.html',
  styleUrls: ['./markup-constraints-edit.component.css'],
})
export class MarkupConstraintsEditComponent {
  @Input() constraints: MarkupConstraint[];

  panelOpenState = false;

  addConstraint() {
    const c = new MarkupConstraint();
    c.markup = 10;
    c.predicates = [];
    c.markupEnabled = true;
    this.constraints.push(c);
  }

  deleteConstraint(constraint: MarkupConstraint) {
    const index = this.constraints.findIndex((it) => it === constraint);
    this.constraints.splice(index, 1);
  }
}
