<app-po-filters (onPoFilter)="applyFilter($event)"></app-po-filters>

<app-po-kpi></app-po-kpi>

<app-po-details-bulk-actions
  [bulkActionsActive]="bulkActionsActive"
  (onPoItemsExport)="exportPoItems($event)"
></app-po-details-bulk-actions>

<div class="container table-outer">
  <mat-paginator #paginator [length]="resultsNumber | async" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()">
  </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="check" sticky>
        <mat-header-cell *matHeaderCellDef class="checker-col">
          <mat-checkbox (change)="toggleAll($event.checked)" [ngModel]="mainChecker"></mat-checkbox>
        </mat-header-cell>
        <mat-cell class="checker-col" *matCellDef="let po; let i = index">
          <mat-checkbox (change)="onSelected($event.checked, po)" [ngModel]="po.checked"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="poId" sticky>
        <mat-header-cell class="po-code-column" *matHeaderCellDef mat-sort-header="poId"> PO </mat-header-cell>
        <mat-cell class="po-code-column" *matCellDef="let poItem">{{ poItem.purchaseOrder.poId }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="vendor">
        <mat-header-cell class="vendor-column" *matHeaderCellDef mat-sort-header="vendor"> Vendor </mat-header-cell>
        <mat-cell class="vendor-column" *matCellDef="let poItem">{{ poItem.purchaseOrder.vendor.code }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="destination">
        <mat-header-cell class="destination-column" *matHeaderCellDef mat-sort-header="destination"> Destination </mat-header-cell>
        <mat-cell
          class="destination-column"
          *matCellDef="let poItem"
          [matTooltip]="
            poItem.purchaseOrder.destination ? poItem.purchaseOrder.destination.code + ' - ' + poItem.purchaseOrder.destination.name : ''
          "
          matTooltipPosition="below"
        >
          <p>
            {{
              poItem.purchaseOrder.destination ? poItem.purchaseOrder.destination.code + ' - ' + poItem.purchaseOrder.destination.name : ''
            }}
          </p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="orderedOn">
        <mat-header-cell class="date-column" *matHeaderCellDef mat-sort-header="orderedOn"> Ordered on </mat-header-cell>
        <mat-cell class="date-column" *matCellDef="let poItem">{{ poItem.purchaseOrder.orderedOn }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="earliestDate">
        <mat-header-cell class="date-column" *matHeaderCellDef mat-sort-header="earliestDate">
          Delivery window <br />
          start
        </mat-header-cell>
        <mat-cell class="date-column" *matCellDef="let poItem">{{ poItem.purchaseOrder.earliestDate }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="latestDate">
        <mat-header-cell class="date-column" *matHeaderCellDef mat-sort-header="latestDate">
          Delivery <br />
          window end
        </mat-header-cell>
        <mat-cell class="date-column" *matCellDef="let poItem">{{ poItem.purchaseOrder.latestDate }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="title" sticky>
        <mat-header-cell class="title-column" *matHeaderCellDef mat-sort-header="title"> Title </mat-header-cell>
        <mat-cell class="title-column" *matCellDef="let poItem" [matTooltip]="poItem.product.title" matTooltipPosition="below">
          <p>{{ poItem.product.title }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ean" sticky>
        <mat-header-cell class="ean-column" *matHeaderCellDef mat-sort-header="ean"> EAN </mat-header-cell>
        <mat-cell class="ean-column" *matCellDef="let poItem">
          <a [routerLink]="['/inventory/products', poItem.product.id, 'details']">{{ poItem.product.ean }}</a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="sku">
        <mat-header-cell class="sku-column" *matHeaderCellDef mat-sort-header="sku"> SKU </mat-header-cell>
        <mat-cell class="sku-column" *matCellDef="let poItem" [matTooltip]="poItem.product.sku" matTooltipPosition="below">
          <p>{{ poItem.product.sku }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="brand">
        <mat-header-cell class="category-column" *matHeaderCellDef mat-sort-header="brand"> Brand </mat-header-cell>
        <mat-cell
          class="category-column"
          *matCellDef="let poItem"
          [matTooltip]="poItem.product.brand ? poItem.product.brand.name : ''"
          matTooltipPosition="below"
        >
          <p>{{ poItem.product.brand ? poItem.product.brand.name : '' }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="backorder">
        <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="backorder"> Backorder </mat-header-cell>
        <mat-cell class="boolean-column" *matCellDef="let poItem">
          <p><i [ngClass]="poItem.backorder ? 'fa fa-check' : 'fa fa-times sel-icon'" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="expectedDate">
        <mat-header-cell class="date-column" *matHeaderCellDef mat-sort-header="expectedDate"> Expected Date </mat-header-cell>
        <mat-cell class="date-column" *matCellDef="let poItem">{{ poItem.expectedDate }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="submittedQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="submittedQuantity">
          Submitted <br />
          Quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let poItem">
          <p>{{ poItem.submittedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="acceptedQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="acceptedQuantity">
          Accepted <br />
          Quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let poItem">
          <p>{{ poItem.acceptedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastPoaQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="lastPoaQuantity"
          >Last POA <br />
          Quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let poItem">
          <p>{{ poItem.lastPoaQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="obsoleteWithAccepted">
        <mat-header-cell class="bigger-boolean-column" *matHeaderCellDef> Obsolete with accepted</mat-header-cell>
        <mat-cell class="bigger-boolean-column" *matCellDef="let poItem">
          <p><i [ngClass]="poItem.obsoleteWithAcceptedGreaterThanZero ? 'fa fa-check' : 'fa fa-times sel-icon'" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="unitCost">
        <mat-header-cell class="value-column-title-small" *matHeaderCellDef mat-sort-header="unitCost"> Unit Cost </mat-header-cell>
        <mat-cell class="value-column-small" *matCellDef="let poItem">
          <p>{{ poItem.unitCost | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="totalCost">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="totalCost"> Total Cost </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let poItem">
          <p>{{ poItem.totalCost | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
