import { Component, Input } from '@angular/core';
import { isNilty } from '../../../../../core/utils.service';
import { KeepaProductDetails } from '../../../../../models/product-model';

@Component({
  selector: 'app-keepa-details-accordion',
  templateUrl: './keepa-details-accordion.component.html',
  styleUrls: ['./keepa-details-accordion.component.css'],
})
export class KeepaDetailsAccordionComponent {
  @Input() set _keepaDetails(values: KeepaProductDetails) {
    if (!isNilty(values)) {
      this.keepaDetails = values;
      if (!isNilty(this.keepaDetails.productData)) {
        this.keys = Object.keys(this.keepaDetails.productData).filter((it) => it !== 'title' && it !== 'description');
      }
      if (!isNilty(this.keepaDetails.categoryTree)) {
        this.categoryTree = this.keepaDetails.categoryTree.split('>');
      }
    }
  }

  keepaDetails: KeepaProductDetails;
  keys: string[];
  categoryTree: string[];

  marketplaces = ['gb', 'de', 'it', 'fr', 'es'];
}
