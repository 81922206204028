import { HttpHeaders } from '@angular/common/http';
import { isNilty } from '../core/utils.service';

export class FulfilmentKpi {
  submittedQuantity = 0;
  submittedValue = 0;

  requestedQuantity = 0;
  requestedValue = 0;
  requestedPurchasePrice = 0;
  virtualRequestedPurchasePrice = 0;

  acceptedQuantity = 0;
  acceptedValue = 0;
  acceptedPurchasePrice = 0;
  virtualAcceptedPurchasePrice = 0;

  orderedQuantity = 0;
  orderedValue = 0;
  orderedPurchasePrice = 0;
  virtualOrderedPurchasePrice = 0;

  shippedQuantity = 0;
  shippedValue = 0;

  margin = 0;
  percentMargin = 0;

  virtualMargin = 0;
  virtualPercentMargin = 0;
}

export const getFulfilmentsKpiFromHeaders = (header: HttpHeaders) => {
  const kpi = new FulfilmentKpi();
  kpi.submittedQuantity = +header.get('submittedQuantity');
  kpi.submittedValue = +header.get('submittedValue');
  kpi.requestedQuantity = +header.get('requestedQuantity');
  kpi.requestedValue = +header.get('requestedValue');
  kpi.requestedPurchasePrice = +header.get('requestedPurchasePrice');
  kpi.virtualRequestedPurchasePrice = +header.get('virtualRequestedPurchasePrice');
  kpi.acceptedQuantity = +header.get('acceptedQuantity');
  kpi.acceptedValue = +header.get('acceptedValue');
  kpi.acceptedPurchasePrice = +header.get('acceptedPurchasePrice');
  kpi.virtualAcceptedPurchasePrice = +header.get('virtualAcceptedPurchasePrice');
  kpi.orderedQuantity = +header.get('orderedQuantity');
  kpi.orderedValue = +header.get('orderedValue');
  kpi.orderedPurchasePrice = +header.get('orderedPurchasePrice');
  kpi.virtualOrderedPurchasePrice = +header.get('virtualOrderedPurchasePrice');
  kpi.margin = +header.get('margin');
  kpi.virtualMargin = +header.get('virtualMargin');
  kpi.percentMargin = isNilty(kpi.orderedValue) || isNaN(kpi.orderedValue) || kpi.orderedValue === 0 ? 0 : kpi.margin / kpi.orderedValue;
  kpi.virtualPercentMargin =
    isNilty(kpi.orderedValue) || isNaN(kpi.orderedValue) || kpi.orderedValue === 0 ? 0 : kpi.virtualMargin / kpi.orderedValue;
  return kpi;
};
