import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-fulfilment-modal',
  templateUrl: './fulfilment-modal.component.html',
  styleUrls: ['./fulfilment-modal.component.css'],
})
export class FulfilmentModalComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<FulfilmentModalComponent>, @Inject(MAT_DIALOG_DATA) public data: string) {}

  ngOnInit() {}
}
