import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';
import { Status, StatusConverter } from './status-model';

@JsonObject('VendorCentral')
export class VendorCentral {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('botEnabled', Boolean, true) botEnabled: boolean = undefined;
}

@JsonObject('ParentVendor')
export class ParentVendor extends BaseObject {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('code', String, false) code: string = undefined;
  @JsonProperty('name', String, false) name: string = undefined;
  @JsonProperty('country', String, false) country: string = undefined;
  @JsonProperty('brandonGln', String, false) brandonGln: string = undefined;
  @JsonProperty('parent', Boolean, true) parent: boolean = undefined;
  @JsonProperty('hasChildren', Boolean, true) hasChildren: boolean = undefined;
  @JsonProperty('defaultOrderAcceptanceMode', String, true) defaultOrderAcceptanceMode: string = undefined;
  @JsonProperty('sendSoftFirstorder', Boolean, true) sendSoftFirstorder: boolean = undefined;
}

@JsonObject('Vendor')
export class Vendor extends BaseObject {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('code', String, false) code: string = undefined;
  @JsonProperty('name', String, false) name: string = undefined;
  @JsonProperty('country', String, true) country: string = undefined;
  @JsonProperty('brandonGln', String, true) brandonGln: string = undefined;
  @JsonProperty('parent', Boolean, true) parent: boolean = undefined;
  @JsonProperty('hasChildren', Boolean, true) hasChildren: boolean = undefined;
  @JsonProperty('defaultOrderAcceptanceMode', String, true) defaultOrderAcceptanceMode: string = undefined;
  @JsonProperty('parentVendor', ParentVendor, true) parentVendor: ParentVendor = undefined;
  @JsonProperty('sendSoftFirstorder', Boolean, true) sendSoftFirstorder: boolean = undefined;
  @JsonProperty('brandonWarehouseCode', String, true) brandonWarehouseCode: string = undefined;
  @JsonProperty('paymentTerms', StatusConverter, true) paymentTerms: Status = undefined;
  @JsonProperty('publicationCheckType', StatusConverter, true) publicationCheckType: Status = undefined;
  @JsonProperty('vendorCentralAccount', VendorCentral, true) vendorCentralAccount: VendorCentral = undefined;

  get description(): string {
    return `${this.code} - ${this.name}`;
  }
}

@JsonObject('VendorsFromLotOutput')
export class VendorsFromLotOutput {
  @JsonProperty('vendors', [Vendor], true) vendors: Vendor[] = undefined;
  @JsonProperty('parents', [Vendor], true) parents: Vendor[] = undefined;
}
