import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ShipmentsService } from '../../shipments.service';
import { ShipmentFilters } from '../../../models/filters/shipment-filters-model';
import { isNilty } from '../../../core/utils.service';
import { Pallet } from '../../../models/pallet-model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-single-shipment-pallets',
  templateUrl: './single-shipment-pallets.component.html',
  styleUrls: ['./single-shipment-pallets.component.css'],
})
export class SingleShipmentPalletsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @Input() shipmentId: number;

  @Input() set loadPallets(it: Subject<ShipmentFilters>) {
    it.subscribe((filters: ShipmentFilters) => {
      this.applyFilter(filters);
    });
  }

  pallets: Pallet[] = [];
  filters: ShipmentFilters;

  dataSource: MatTableDataSource<Pallet> = new MatTableDataSource<Pallet>([]);

  displayedColumns = ['id', 'supplierPalletId', 'height', 'edit'];

  isEditingHeight = false;
  editingPalletId: number;
  oldHeight: number;

  resultsNumber: Observable<number>;

  constructor(private shipmentsService: ShipmentsService, private snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.resultsNumber = this.shipmentsService.palletsResultsNumber;
  }

  loadRows() {
    this.shipmentsService.getShipmentPallets(this.shipmentId, this.filters).subscribe((palletsResp: Pallet[]) => {
      this.pallets = palletsResp;
      this.dataSource.data = this.pallets;
    });
  }

  loadPage() {
    this.filters.pageNumber = this.paginator.pageIndex;
    this.filters.pageSize = this.paginator.pageSize;
    this.loadRows();
  }

  applyFilter(filters: ShipmentFilters) {
    this.filters = filters;
    this.paginator.pageIndex = 0;
    this.loadRows();
  }

  editPalletHeight(p: Pallet) {
    this.isEditingHeight = !this.isEditingHeight;
    this.editingPalletId = this.isEditingHeight ? p.id : undefined;
    this.oldHeight = isNilty(p.height) ? 0 : p.height;
  }

  keydownEvent(event: KeyboardEvent, p: Pallet) {
    const excludedKeys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete'];

    if (event.key === 'Enter') {
      this.savePalletHeight(p);
    } else if (excludedKeys.indexOf(event.key) === -1) {
      const regexp = new RegExp('[0-9]');
      if (!regexp.test(event.key)) {
        event.preventDefault();
      }
    }
  }

  savePalletHeight(p: Pallet) {
    if (p.height !== this.oldHeight) {
      this.shipmentsService.updatePalletHeight(this.shipmentId, p).subscribe((resp: Pallet) => {
        if (isNilty(resp)) {
          this.snackBar.open('Error during update.', '')._dismissAfter(2000);
        } else {
          this.updateTableRow(resp);
          this.snackBar.open('Pallet height updated.', '')._dismissAfter(2000);
        }
      });
    } else {
      this.snackBar.open('No changes.', '')._dismissAfter(2000);
    }
    this.isEditingHeight = false;
    this.editingPalletId = undefined;
    this.oldHeight = undefined;
  }

  discardChanges(s: Pallet) {
    s.height = this.oldHeight;
    this.isEditingHeight = false;
    this.editingPalletId = undefined;
    this.oldHeight = undefined;
  }

  updateTableRow(p: Pallet) {
    const updatedIndex = this.pallets.findIndex((it) => it.id === p.id);
    if (!isNilty(updatedIndex) && updatedIndex !== -1) {
      this.pallets[updatedIndex] = p;
      this.dataSource.data = this.pallets;
    }
  }
}
