<div [ngClass]="{ 'black-panel': openFilter, panel: !openFilter }">
  <mat-expansion-panel class="mat-elevation-z8 filter-position" [attr.expanded]="openFilter" (opened)="openEvent()" (closed)="closeevent()">
    <mat-expansion-panel-header class="filt-custom">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-9">
            <mat-chip-list>
              <mat-chip *ngFor="let f of filtersMap">{{ f.filter }}</mat-chip>
            </mat-chip-list>
          </div>
          <div class="col-md-2">
            <mat-panel-title class="filter-title"> Filters </mat-panel-title>
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>

    <div class="row filters-row">
      <form [formGroup]="filterForm" (submit)="onSubmitFilter()">
        <div class="col-md-12 text-blue margin-top-5 border-bottom-orange">
          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="asnCode" placeholder="ASN" />
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="trackingNumber" placeholder="Tracking Number" />
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="ean" placeholder="EAN" />
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select filterKey="description" formControlName="supplier" id="supplier" placeholder="Supplier">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="supplier" *appMatSelectFor="let supplier of suppliers">{{ supplier.description }} </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select filterKey="description" formControlName="vendor" id="vendor" placeholder="Vendor">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="vendor" *appMatSelectFor="let vendor of vendors">{{ vendor.description }} </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-select formControlName="status" id="status" placeholder="Status" [multiple]="true">
                  <mat-option *ngFor="let s of shipmentsStatus" [value]="s.code">{{ s.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <mat-select formControlName="paymentStatus" id="paymentStatus" placeholder="Payment Status">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option *ngFor="let s of paymentStatus" [value]="s">{{ s.description }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select filterKey="description" formControlName="origin" id="origin" placeholder="Origin">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="sl" *appMatSelectFor="let sl of stockLocations">{{ sl.description }} </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="shipment" placeholder="Shipment Code" />
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select
                  filterKey="name"
                  formControlName="lot"
                  placeholder="Lot"
                  [automaticSearch]="false"
                  (filterChange)="filterLots($event)"
                >
                  <mat-option [value]="null">None</mat-option>
                  <mat-option *appMatSelectFor="let lot of lots | async" [value]="lot">
                    {{ lot.name }}
                  </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="lotDateFrom" [matDatepicker]="lotDateFrom" placeholder="From lot date" />
                <mat-datepicker-toggle matSuffix [for]="lotDateFrom"></mat-datepicker-toggle>
                <mat-datepicker #lotDateFrom></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="lotDateTo" [matDatepicker]="lotDateTo" placeholder="To lot date" />
                <mat-datepicker-toggle matSuffix [for]="lotDateTo"></mat-datepicker-toggle>
                <mat-datepicker #lotDateTo></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <textarea matInput formControlName="po" placeholder="POs"></textarea>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <textarea matInput formControlName="orderIds" placeholder="Order ID"></textarea>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select
                  filterKey="name"
                  formControlName="carrierContracts"
                  id="carrierContracts"
                  placeholder="Carrier contract"
                  [multiple]="true"
                >
                  <mat-option [value]="contract" *appMatSelectFor="let contract of carrierContracts">
                    {{ contract.name }}
                  </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select filterKey="description" formControlName="destination" placeholder="Destination">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option
                    *appMatSelectFor="let warehouse of warehouses"
                    [value]="warehouse"
                    style="font-family: 'Titillium Web', sans-serif !important; font-size: 12px"
                  >
                    {{ warehouse.description }}
                  </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2 checkbox-small-text">
              <mat-form-field>
                <mat-select formControlName="ukIntercoInvoice" id="ukIntercoInvoice" placeholder="Requires Uk Interco Invoice">
                  <mat-option [value]="null">All</mat-option>
                  <mat-option [value]="true">True</mat-option>
                  <mat-option [value]="false">False</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2 checkbox-small-text">
              <mat-form-field>
                <mat-select
                  formControlName="ukIntercoInvoiceValidData"
                  id="ukIntercoInvoiceValidData"
                  placeholder="Has valid data for UK Interco invoice"
                >
                  <mat-option [value]="null">All</mat-option>
                  <mat-option [value]="true">True</mat-option>
                  <mat-option [value]="false">False</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-2 checkbox-small-text">
              <mat-form-field>
                <mat-select formControlName="ukIntercoInvoiceStatus" id="ukIntercoInvoiceStatus" placeholder="UK Interco invoice status">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option *ngFor="let s of ukIntercoInvoiceStatus" [value]="s.code">{{ s.description }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2 checkbox-small-text">
              <mat-form-field>
                <mat-select formControlName="documentsSentToSupplier" id="documentsSentToSupplier" placeholder="Documents sent to Supplier">
                  <mat-option [value]="null">All</mat-option>
                  <mat-option [value]="true">True</mat-option>
                  <mat-option [value]="false">False</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Latest Pickup Date</mat-label>
                <mat-date-range-input [rangePicker]="latestPickupDatePicker">
                  <input matStartDate formControlName="latestPickupDateFrom" placeholder="Start date" />
                  <input matEndDate formControlName="latestPickupDateTo" placeholder="End date" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="latestPickupDatePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #latestPickupDatePicker></mat-date-range-picker>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select formControlName="noInvoice" id="noInvoice" placeholder="Show only shipments with no invoice">
                  <mat-option [value]="true">Yes</mat-option>
                  <mat-option [value]="false">No</mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2 checkbox-small-text">
              <mat-form-field>
                <app-mat-select filterKey="name" formControlName="category" id="category" placeholder="Category">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="category" *appMatSelectFor="let category of categories">{{ category.name }} </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-2 checkbox-small-text">
              <mat-form-field>
                <mat-select formControlName="outboundStatus" id="outboundStatus" placeholder="Outbound Status">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option *ngFor="let s of outboundStatus" [value]="s">{{ s }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-2 checkbox-small-text">
              <mat-form-field>
                <input matInput formControlName="shippedAtFrom" [matDatepicker]="shippedAtFrom" placeholder="Shipped At From" />
                <mat-datepicker-toggle matSuffix [for]="shippedAtFrom"></mat-datepicker-toggle>
                <mat-datepicker #shippedAtFrom></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-2 checkbox-small-text">
              <mat-form-field>
                <input matInput formControlName="shippedAtTo" [matDatepicker]="shippedAtTo" placeholder="Shipped At To" />
                <mat-datepicker-toggle matSuffix [for]="shippedAtTo"></mat-datepicker-toggle>
                <mat-datepicker #shippedAtTo></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-2 checkbox-small-text">
              <mat-checkbox formControlName="crossUkBorder">Cross UK border</mat-checkbox>
            </div>
            <div class="col-md-2 checkbox-small-text">
              <mat-checkbox formControlName="isAdr">Is ADR</mat-checkbox>
            </div>
          </div>
        </div>

        <div class="col-md-12 border-top-white">
          <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-2 center">
              <button type="submit" class="box-shadow-none btn-custom">Filter</button>
            </div>
            <div class="col-md-2 center">
              <button type="button" (click)="resetFilters()" class="box-shadow-none btn-custom">Reset</button>
            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
      </form>

      <mat-divider></mat-divider>
    </div>
  </mat-expansion-panel>
</div>
