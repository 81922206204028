import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { RemittanceDetail } from '../../../../models/remittance-detail-model';
import { RemittanceService } from '../remittance.service';
import { isNilty } from '../../../../core/utils.service';
import { RemittanceFilters } from '../../../../models/filters/remittance-filters-model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-main-remittances-list-details',
  templateUrl: './main-remittances-list-details.component.html',
  styleUrls: ['./main-remittances-list-details.component.css'],
})
export class MainRemittancesListDetailsComponent implements OnInit {
  dataSource: MatTableDataSource<RemittanceDetail>;

  displayedColumns = [
    'paymentNumber',
    'code',
    'invoiceNumber',
    'invoiceReference',
    'invoiceDate',
    'invoiceDescription',
    'discountTaken',
    'amountPaid',
    'amountRemaining',
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private remittanceService: RemittanceService) {}

  ngOnInit() {
    this.resultsNumber = this.remittanceService.resultsNumber;

    if (isNilty(this.remittanceService.remittanceFilters)) {
      this.remittanceService.remittanceFilters = new RemittanceFilters();
    }
    this.dataSource = new MatTableDataSource([]);
    this.loadRemittanceDetails(this.remittanceService.remittanceFilters);
  }

  loadRemittanceDetails(filters: RemittanceFilters) {
    this.remittanceService.getFilteredRemittanceDetails(filters).subscribe((resp: RemittanceDetail[]) => {
      this.dataSource.data = resp;
    });
  }

  loadPage() {
    this.remittanceService.remittanceFilters.pageNumber = this.paginator.pageIndex;
    this.remittanceService.remittanceFilters.pageSize = this.paginator.pageSize;
    this.loadRemittanceDetails(this.remittanceService.remittanceFilters);
  }

  onSort() {
    this.remittanceService.remittanceFilters.sortBy = this.sort.active;
    this.remittanceService.remittanceFilters.sortDirection = this.sort.direction;
    this.loadRemittanceDetails(this.remittanceService.remittanceFilters);
  }

  onFilter(filters: RemittanceFilters) {
    this.paginator.pageIndex = 0;
    this.loadRemittanceDetails(filters);
  }
}
