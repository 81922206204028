<app-products-filters (onFilterEvent)="onFilter($event)"></app-products-filters>

<mat-toolbar class="bar-custom">
  <div class="container">
    <div class="row">
      <div class="col-md-10">
        <button class="btn-custom" [routerLink]="['new']">New Product</button>
      </div>

      <div class="col-md-2 text-right">
        <button class="btn-custom" [matMenuTriggerFor]="importMenu">IMPORT</button>
      </div>
      <mat-menu #importMenu="matMenu" class="margin-left-15">
        <button mat-menu-item  (click)="import(true)">Catalog</button>
        <input #getFile type="file" style="display: none;" onclick="this.value = null" (change)="fileChanged($event)" />
      
        <button mat-menu-item (click)="import(false)">Extra quantity</button>
        <input #getFile type="file" style="display: none" onclick="this.value = null" (change)="fileChanged($event)" />
      </mat-menu>
    </div>
  </div>
</mat-toolbar>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <hr class="divisor-title" style="width: calc(100% - 14rem);" />
      <div class="square" style="right: 14rem;"></div>
      <h2 class="po-element-custom title-custom">Products</h2>
    </div>
  </div>
</div>

<div class="container table-outer">
  <mat-paginator #paginator [length]="resultsNumber | async" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()">
  </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="ean" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="ean" class="ean-column"> EAN </mat-header-cell>
        <mat-cell *matCellDef="let row" class="ean-column">
          <a [routerLink]="[row?.productWithCatalog.id, 'details']">{{ row?.productWithCatalog.ean }}</a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="upc" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="upc" class="ean-column"> UPC </mat-header-cell>
        <mat-cell *matCellDef="let row" class="ean-column"> {{ row?.productWithCatalog.upc }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="sku">
        <mat-header-cell *matHeaderCellDef mat-sort-header="sku" class="sku-column"> SKU </mat-header-cell>
        <mat-cell class="sku-column" *matCellDef="let row" [matTooltip]="row?.productWithCatalog.sku" matTooltipPosition="below">
          {{ row?.productWithCatalog.sku }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="hsCode">
        <mat-header-cell *matHeaderCellDef class="sku-column"> HS Code </mat-header-cell>
        <mat-cell class="sku-column" *matCellDef="let row" [matTooltip]="row?.productWithCatalog.hsCode" matTooltipPosition="below">
          <p>{{ row?.productWithCatalog.hsCode }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="articleCode">
        <mat-header-cell class="art-code-column" *matHeaderCellDef mat-sort-header="articleCode">
          Article <br />
          code
        </mat-header-cell>
        <mat-cell
          class="art-code-column"
          *matCellDef="let row"
          [matTooltip]="row?.productWithCatalog.articleCode"
          matTooltipPosition="below"
        >
          {{ row?.productWithCatalog.articleCode }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="title" sticky>
        <mat-header-cell class="long-title-column" *matHeaderCellDef mat-sort-header="title"> Title </mat-header-cell>
        <mat-cell class="long-title-column" *matCellDef="let row" [matTooltip]="row?.productWithCatalog.title" matTooltipPosition="below">
          <p>{{ row?.productWithCatalog.title }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="brand">
        <mat-header-cell class="category-column" *matHeaderCellDef mat-sort-header="brand"> Brand </mat-header-cell>
        <mat-cell
          class="category-column"
          *matCellDef="let row"
          [matTooltip]="row?.productWithCatalog?.brand?.name"
          matTooltipPosition="below"
        >
          <p>{{ row?.productWithCatalog?.brand?.name }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="category">
        <mat-header-cell class="category-column" *matHeaderCellDef mat-sort-header="category"> Category </mat-header-cell>
        <mat-cell
          class="category-column"
          *matCellDef="let row"
          [matTooltip]="row?.productWithCatalog?.category?.name"
          matTooltipPosition="below"
        >
          <p>{{ row?.productWithCatalog?.category?.name }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="season">
        <mat-header-cell class="category-column" *matHeaderCellDef mat-sort-header="season"> Season </mat-header-cell>
        <mat-cell
          class="category-column"
          *matCellDef="let row"
          [matTooltip]="row?.productWithCatalog?.season?.period"
          matTooltipPosition="below"
        >
          <p>{{ row?.productWithCatalog?.season?.period }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="size">
        <mat-header-cell class="color-code-column" *matHeaderCellDef mat-sort-header="size"> Size </mat-header-cell>
        <mat-cell class="color-code-column" *matCellDef="let row"> {{ row?.productWithCatalog.size }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="colorCode">
        <mat-header-cell class="color-code-column" *matHeaderCellDef mat-sort-header="colorCode">
          Color <br />
          Code
        </mat-header-cell>
        <mat-cell class="color-code-column" *matCellDef="let row"> {{ row?.productWithCatalog.colorCode }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="color">
        <mat-header-cell class="category-column" *matHeaderCellDef mat-sort-header="color"> Color </mat-header-cell>
        <mat-cell class="category-column" *matCellDef="let row" [matTooltip]="row?.productWithCatalog.color" matTooltipPosition="below">
          <p>{{ row?.productWithCatalog.color }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="wholesalePrice">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="wholesalePrice">Wholesale Price</mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let row">
          <p>{{ row?.productWithCatalog.wholesalePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="retailPrice">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="retailPrice">Retail Price</mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let row">
          <p>{{ row?.productWithCatalog.retailPrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell class="shipment-status-column" *matHeaderCellDef mat-sort-header="status"> Status </mat-header-cell>
        <mat-cell class="shipment-status-column" *matCellDef="let row"> {{ row?.productWithCatalog.status }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="stockBalance">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="stockBalance">Stock Balance</mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let row">
          <p>{{ row.stockBalance }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="purchasePrice">
        <mat-header-cell class="purchase-price-select-column" *matHeaderCellDef>Purchase Price</mat-header-cell>
        <mat-cell class="purchase-price-select-column" *matCellDef="let row">
          <mat-select [value]="row?.productWithCatalog.catalogItems | lowestPrice">
            <mat-option class="money-cell" *ngFor="let catalog of row?.productWithCatalog.catalogItems" [value]="catalog.id"
              >{{ catalog.purchasePrice | myCurrency }} - {{ catalog.availableQuantity }} - {{ catalog.supplier.name }}</mat-option
            >
          </mat-select>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
