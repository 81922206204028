<mat-toolbar class="lot-edit-bulk-actions">
  <div class="container">
    <div class="row">
      <div class="col-xs-10">
        <ng-container *ngIf="hasFulfillmentCreation | async">
          <button [matMenuTriggerFor]="importResponseMenu" class="box-shadow-none btn-custom margin-right-6">
            <i class="fa fa-upload margin-right-6" aria-hidden="true"></i> Import
          </button>
          <mat-menu #importResponseMenu="matMenu">
            <button mat-menu-item (click)="importSpoResponse()">
              <i class="fa fa-check-square-o margin-right-6" aria-hidden="true"></i> SPO confirmation
            </button>
            <button mat-menu-item (click)="importSpoGenerate()">
              <i class="fa fa-plus margin-right-6" aria-hidden="true"></i>Generate SPOs
            </button>
            <button mat-menu-item (click)="importUpdateOrdered()">
              <i class="fa fa-list-ol margin-right-6" aria-hidden="true"></i>Update ordered
            </button>
          </mat-menu>
        </ng-container>

        <input #getSpoResp type="file" style="display: none;" onclick="this.value = null" (change)="uploadSpoResponse($event)" />
        <input #getUpdateOrdered type="file" style="display: none;" onclick="this.value = null" (change)="uploadUpdateOrdered($event)" />
      </div>

      <div class="col-xs-2 text-right">
        <button [disabled]="!bulkActionsActive" [matMenuTriggerFor]="exportChoice" class="box-shadow-none btn-custom">
          Export selected rows
        </button>
        <mat-menu #exportChoice="matMenu">
          <button mat-menu-item (click)="exportExcel('excel')">Save as Excel</button>
          <input #getSpoGenerate type="file" style="display: none;" onclick="this.value = null" (change)="uploadSpoGenerate($event)" />
          <button mat-menu-item (click)="exportExcel('csv')">Save as CSV</button>
        </mat-menu>
      </div>
    </div>
  </div>
</mat-toolbar>
