import { Component, Inject, OnInit } from '@angular/core';
import { InventoryService } from '../../../container/inventory/inventory.service';
import { StockLocation } from '../../../models/stock-location-model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isNilty } from '../../../core/utils.service';

@Component({
  selector: 'app-transit-warehouse-modal',
  templateUrl: './transit-warehouse-modal.component.html',
  styleUrls: ['./transit-warehouse-modal.component.css'],
})
export class TransitWarehouseModalComponent implements OnInit {
  transitWarehouses: StockLocation[] = [];
  selectedId = 0;
  transitWarehouseTws = false;
  transitWarehouseTwsCheck = true;

  constructor(
    public dialogRef: MatDialogRef<TransitWarehouseModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      transitWarehouse: StockLocation;
    },
    private inventoryService: InventoryService
  ) {}

  ngOnInit() {
    if (this.data.transitWarehouse != null) {
      this.selectedId = this.data.transitWarehouse.id;
    }

    this.inventoryService.getTransitWarehouses().subscribe((transitWarehouses: StockLocation[]) => {
      this.transitWarehouses = transitWarehouses;
    });
  }

  onClose() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close(this.selectedId);
  }

  onChange(e) {
    const selectedStockLocation = this.transitWarehouses.find((it) => it.id === e.value);
    if (isNilty(selectedStockLocation)) {
      return;
    }

    if (selectedStockLocation.code === 'PBYW' || selectedStockLocation.code === 'AUTW') {
      this.transitWarehouseTwsCheck = false;
      this.transitWarehouseTws = true;
    } else {
      this.transitWarehouseTws = false;
      this.transitWarehouseTwsCheck = true;
    }
  }

  onClick(e) {
    this.transitWarehouseTwsCheck = e.checked === true ? true : false;
  }
}
