import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-dispute-bot-logs',
  templateUrl: './main-dispute-bot-logs.component.html',
  styleUrls: ['./main-dispute-bot-logs.component.css'],
})
export class MainDisputeBotLogsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
