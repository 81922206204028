import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConverterService } from '../core/converter.service';
import { cachedArrayGet, GenericArrayCache } from './service-cached-array';
import { SpoStatus } from '../models/spo-model';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { ShipmentStatus } from '../models/shipment-model';

@Injectable()
export class CommonsService {
  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {
    this.getShipmentStatuses().subscribe();
    this.getSpoStatuses().subscribe();
  }

  spoStatusCache = new GenericArrayCache<SpoStatus>(this.http, this.converter, SpoStatus);
  shipmentStatusCache = new GenericArrayCache<ShipmentStatus>(this.http, this.converter, ShipmentStatus);

  getSpoStatuses(): Observable<SpoStatus[]> {
    return cachedArrayGet<SpoStatus>(this.spoStatusCache, this.environmentService.getRestEndpoint('spoStatuses'));
  }
  getShipmentStatuses(): Observable<ShipmentStatus[]> {
    return cachedArrayGet<ShipmentStatus>(this.shipmentStatusCache, this.environmentService.getRestEndpoint('shipmentStatuses'));
  }
}
