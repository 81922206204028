import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../../../../../core/authorization.service';
import { getDaysDifference, isNil, isNilty } from '../../../../../core/utils.service';
import { Claim } from '../../../../../models/claim-model';
import { Permissions } from '../../../../../models/permission-model';
import { ClaimService } from '../claim.service';
import { ClaimEditData, EditClaimModalComponent } from '../edit-claim-modal/edit-claim-modal.component';

@Component({
  selector: 'app-claims-table',
  templateUrl: './claims-table.component.html',
  styleUrls: ['./claims-table.component.css'],
})
export class ClaimsTableComponent implements OnInit {
  @Input() set claims(values: Claim[]) {
    if (!isNilty(values)) {
      this.dataSource = new MatTableDataSource(values);
    }
  }

  @Output() claimEdited = new EventEmitter<void>();

  dataSource: MatTableDataSource<Claim>;
  displayedColumns = [
    'invoiceNumber',
    'invoiceDate',
    'suffix',
    'status',
    'disputeId',
    'disputeBy',
    'caseIds',
    'openedBy',
    'amountPaid',
    'claimQuantity',
    'hasRemittance',
    'disputeCreatedByBot',
    'caseCreatedByBot',
    'showDetails',
  ];

  hasDeductionManagement: Observable<boolean>;

  constructor(
    private claimService: ClaimService,
    private authorizationService: AuthorizationService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.hasDeductionManagement = this.authorizationService.hasPermission(Permissions.DeductionManagement);
    this.hasDeductionManagement.subscribe((deductionManagement) => {
      if (deductionManagement) {
        this.displayedColumns.push('edit');
      }
    });
  }

  edit(claim: Claim) {
    const claimId = claim.id;
    const form = new ClaimEditData();
    form.caseIds = claim.caseIds ? [...claim.caseIds] : [];
    form.disputeId = claim.disputeId;
    form.disputeDate = claim.disputeDate;
    form.creditNote = claim.status.code.startsWith('CREDIT_NOTE');
    form.caseDate = claim.caseDate;

    const dialogRef = this.dialog.open(EditClaimModalComponent, {
      width: '400px',
      data: {
        showCreditNote: claim.code.startsWith('SC'),
        form,
        claimId,
      },
    });

    dialogRef.afterClosed().subscribe((result: ClaimEditData) => {
      if (result) {
        if (
          result.disputeId !== claim.disputeId ||
          result.disputeDate !== claim.disputeDate ||
          result.creditNote !== claim.status.code.startsWith('CREDIT_NOTE') ||
          JSON.stringify(result.caseIds) !== JSON.stringify(claim.caseIds) ||
          result.caseDate !== claim.caseDate ||
          (!isNilty(result.status) && result.status !== claim.status.code)
        ) {
          this.claimService.updateClaim(claim.id, result).subscribe((resp: Claim) => {
            if (isNilty(resp)) {
              this.snackBar.open('Error during update.', '')._dismissAfter(2000);
            } else {
              this.claimEdited.emit();
              this.snackBar.open('Claim updated.', '')._dismissAfter(2000);
            }
          });
        } else {
          this.snackBar.open('No changes.', '')._dismissAfter(2000);
        }
      } else {
        console.log('Claim not edited');
      }
    });
  }

  showDetails(id: number) {
    this.router.navigate(['deductions/claims/' + id]);
  }

  getDisputeBy(claim: Claim): string {
    if (
      isNil(claim.disputeDate) ||
      !this.dataSource.data.filter((c) => c.code.startsWith(claim.code)).some((c) => c.status.code === 'DISPUTE_PENDING')
    ) {
      return '';
    }
    return getDaysDifference(claim.disputeDate, new Date()).toString();
  }

  getOpenedBy(claim: Claim): string {
    if (isNil(claim.caseDate)) {
      return '';
    }
    return getDaysDifference(claim.caseDate, new Date()).toString();
  }

  claimQuantity(claim: Claim) {
    if (claim.claimDetails) {
      return claim.claimDetails.reduce((sum, claimDetail) => sum + claimDetail.claimQuantity, 0);
    }
    return 0;
  }
}
