import { JsonObject, JsonProperty } from 'json2typescript';
import { Remittance } from './remittance-model';
import { BaseObject } from './base-object-model';

@JsonObject('RemittanceDetailNoInvoice')
export class RemittanceDetailNoInvoice extends BaseObject {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('invoiceNumber', String, true) invoiceNumber: string = undefined;
  @JsonProperty('invoiceDate', String, true) invoiceDate: string = undefined;
  @JsonProperty('invoiceDescription', String, true) invoiceDescription: string = undefined;
  @JsonProperty('discountTaken', String, true) discountTaken: string = undefined;
  @JsonProperty('amountPaid', Number, true) amountPaid: number = undefined;
  @JsonProperty('amountRemaining', Number, true) amountRemaining: number = undefined;
  @JsonProperty('code', String, true) code: string = undefined;
  @JsonProperty('partiallyPaid', Boolean, true) partiallyPaid: boolean = undefined;
  @JsonProperty('remittance', Remittance, true) remittance: Remittance = undefined;
}
