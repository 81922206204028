import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../../../../../core/authorization.service';
import { getDaysDifference, isNil, isNilty } from '../../../../../core/utils.service';
import { Claim } from '../../../../../models/claim-model';
import { ClaimFilters } from '../../../../../models/filters/claim-filters-model';
import { Permissions } from '../../../../../models/permission-model';
import { ImportExportService } from '../../../../../shared/import-export.service';
import { ClaimService } from '../claim.service';
import { ClaimEditData, EditClaimModalComponent } from '../edit-claim-modal/edit-claim-modal.component';

@Component({
  selector: 'app-claims-list',
  templateUrl: './claims-list.component.html',
  styleUrls: ['./claims-list.component.css'],
})
export class ClaimsListComponent implements OnInit {
  dataSource: MatTableDataSource<Claim>;

  displayedColumns = [
    'invoiceNumber',
    'invoiceDate',
    'suffix',
    'status',
    'disputeId',
    'disputeBy',
    'caseIds',
    'openedBy',
    'vendor',
    'shipmentCode',
    'carrier',
    'supplier',
    'trackingNumber',
    'deliveryDate',
    'amountPaid',
    'claimQuantity',
    'hasRemittance',
    'hasPod',
    'settlement2022',
    'export',
    'showDetails',
  ];

  hasDeductionManagement: Observable<boolean>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private claimService: ClaimService,
    private importExportService: ImportExportService,
    private authorizationService: AuthorizationService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.hasDeductionManagement = this.authorizationService.hasPermission(Permissions.DeductionManagement);
    this.hasDeductionManagement.subscribe((deductionManagement) => {
      if (deductionManagement) {
        this.displayedColumns.push('edit');
      }
    });
    this.resultsNumber = this.claimService.resultsNumber;

    if (isNilty(this.claimService.claimFilters)) {
      this.claimService.claimFilters = new ClaimFilters();
    }

    this.paginator.pageSize = this.claimService.claimFilters.pageSize;
    this.paginator.pageIndex = this.claimService.claimFilters.pageNumber;

    this.dataSource = new MatTableDataSource([]);
    this.loadClaims(this.claimService.claimFilters);
  }

  loadClaims(filters: ClaimFilters) {
    this.claimService.getFilteredClaims(filters).subscribe((ch: Claim[]) => {
      this.dataSource.data = ch;
    });
  }

  loadPage() {
    this.claimService.claimFilters.pageNumber = this.paginator.pageIndex;
    this.claimService.claimFilters.pageSize = this.paginator.pageSize;
    this.loadClaims(this.claimService.claimFilters);
  }

  onSort() {
    this.claimService.claimFilters.sortBy = this.sort.active;
    this.claimService.claimFilters.sortDirection = this.sort.direction;
    this.loadClaims(this.claimService.claimFilters);
  }

  onFilter(filters: ClaimFilters) {
    this.paginator.pageIndex = 0;
    this.loadClaims(filters);
  }

  edit(claim: Claim) {
    const form = new ClaimEditData();
    form.caseIds = claim.caseIds ? [...claim.caseIds] : [];
    form.disputeId = claim.disputeId;
    form.disputeDate = claim.disputeDate;
    form.creditNote = claim.status.code.startsWith('CREDIT_NOTE');
    form.caseDate = claim.caseDate;

    const dialogRef = this.dialog.open(EditClaimModalComponent, {
      width: '400px',
      data: {
        showCreditNote: claim.code.startsWith('SC'),
        form,
      },
    });

    dialogRef.afterClosed().subscribe((result: ClaimEditData) => {
      if (result) {
        if (
          result.disputeId !== claim.disputeId ||
          result.disputeDate !== claim.disputeDate ||
          result.creditNote !== claim.status.code.startsWith('CREDIT_NOTE') ||
          JSON.stringify(result.caseIds) !== JSON.stringify(claim.caseIds) ||
          result.caseDate !== claim.caseDate ||
          (!isNilty(result.status) && result.status !== claim.status.code)
        ) {
          this.claimService.updateClaim(claim.id, result).subscribe((resp: Claim) => {
            if (isNilty(resp)) {
              this.snackBar.open('Error during update.', '')._dismissAfter(2000);
            } else {
              this.loadClaims(this.claimService.claimFilters);
              this.snackBar.open('Claim updated.', '')._dismissAfter(2000);
            }
          });
        } else {
          this.snackBar.open('No changes.', '')._dismissAfter(2000);
        }
      } else {
        console.log('Claim not edited');
      }
    });
  }

  showDetails(id: number) {
    this.router.navigate(['deductions/claims/' + id]);
  }

  getDisputeBy(claim: Claim): string {
    if (isNil(claim.disputeDate) || claim.status.code !== 'DISPUTE_PENDING') {
      return '';
    }
    return getDaysDifference(claim.disputeDate, new Date()).toString();
  }

  getOpenedBy(claim: Claim): string {
    if (isNil(claim.caseDate)) {
      return '';
    }
    return getDaysDifference(claim.caseDate, new Date()).toString();
  }

  export(claim: Claim) {
    if (claim.claimHeader) {
      this.importExportService.exportClaimDocumentation(claim.claimHeader.id).subscribe((resp) => console.log(resp));
    }
  }

  claimQuantity(claim: Claim) {
    if (claim.claimDetails) {
      return claim.claimDetails.reduce((sum, claimDetail) => sum + claimDetail.claimQuantity, 0);
    }
    return 0;
  }
}
