import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ConverterService } from '../core/converter.service';
import { Observable, of, Subject } from 'rxjs';
import { setFiltersFromForm, SpoFilters } from '../models/filters/spo-filters-model';
import { FulfilmentKpi, getFulfilmentsKpiFromHeaders } from '../models/fulfilment-kpi';
import { FormGroup } from '@angular/forms';
import { environment } from '../../environments/environment';
import { map, switchMap } from 'rxjs/operators';
import { isNilty } from '../core/utils.service';
import { Spo } from '../models/spo-model';
import { GenericErrorModalComponent } from '../container/modal/generic-error-modal/generic-error-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Supplier } from '../models/supplier-model';
import { EnvironmentService } from '../environment.service';
import { SpoItem, GroupedSpoItem } from '../models/spo-item-model';
import { GenericConfirmationModalComponent } from '../container/modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { SPO_CANCELED_STATUS } from '../models/status-enum';

@Injectable()
export class SposService {
  resultsNumber = new Subject<number>();
  spoItemResultsNumber = new Subject<number>();

  spoFilters: SpoFilters;

  selectedSpos: number[];
  selectedGroupedSpoItems: number[];

  spoItemsMainChecker: boolean;
  sposMainChecker: boolean;

  kpiData = new Subject<FulfilmentKpi>();

  constructor(
    private http: HttpClient,
    private converter: ConverterService,
    private dialog: MatDialog,
    private environmentService: EnvironmentService
  ) {}

  saveSpoFiltersToService(filterForm: FormGroup) {
    setFiltersFromForm(filterForm, this.spoFilters);
  }

  createFiltersBody(filters: SpoFilters, currentLotId: number, selectedIds: number[]): string {
    filters.listOfGroupIds = null;
    filters.listOfIds = selectedIds;
    filters.lotId = currentLotId;

    // If is false, no filtering is done.
    if (!filters.hasOrderedQuantity) {
      filters.hasOrderedQuantity = undefined;
    }
    return this.converter.fromObjtoJSON(filters);
  }

  getHeaders(headers) {
    this.kpiData.next(getFulfilmentsKpiFromHeaders(headers));
    this.resultsNumber.next(+headers.get('Total-Length'));
  }

  getFilteredGroupedSpoItems(currentLotId: number, filters: SpoFilters): Observable<GroupedSpoItem[]> {
    const body = this.createFiltersBody(filters, currentLotId, undefined);
    return this.http.post(this.environmentService.getRestEndpoint('groupedSpoItems'), body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.getHeaders(resp.headers);
        return resp.body;
      }),
      map((sposResp: GroupedSpoItem[]) => {
        if (!isNilty(sposResp)) {
          return sposResp.map((it) => this.converter.fromJSONtoObj(it, GroupedSpoItem));
        } else {
          return [];
        }
      })
    );
  }

  getFilteredSpoItems(currentLotId: number, filters: SpoFilters): Observable<SpoItem[]> {
    const body = this.createFiltersBody(filters, currentLotId, undefined);
    return this.http.post(this.environmentService.getRestEndpoint('spoItems'), body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.spoItemResultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((sposResp: SpoItem[]) => {
        if (!isNilty(sposResp)) {
          return sposResp.map((it) => this.converter.fromJSONtoObj(it, SpoItem));
        } else {
          return [];
        }
      })
    );
  }

  getFilteredSpos(currentLotId: number, filters: SpoFilters): Observable<Spo[]> {
    const body = this.createFiltersBody(filters, currentLotId, undefined);
    return this.http.post(this.environmentService.getRestEndpoint('spos'), body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.getHeaders(resp.headers);
        return resp.body;
      }),
      map((sposResp: Spo[]) => {
        if (!isNilty(sposResp)) {
          return sposResp.map((it) => this.converter.fromJSONtoObj(it, Spo));
        } else {
          return [];
        }
      })
    );
  }

  getSingleSpo(spoId: number): Observable<Spo> {
    const endpoint = this.environmentService.getRestEndpoint('singleSpo');
    const path = isDevMode() ? endpoint : endpoint + '/' + spoId;
    return this.http.get(path).pipe(
      map((resp: Spo) => {
        if (isNilty(resp)) {
          return null;
        }
        return this.converter.fromJSONtoObj(resp, Spo);
      })
    );
  }

  mergeSpos(selectedIds: number[], filters: SpoFilters, mainChecker: boolean, currentLotId: number): Observable<any> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    let path;
    let body;
    if (mainChecker) {
      path = this.environmentService.getRestEndpoint('mergeByFilters');
      body = this.createFiltersBody(filters, currentLotId, selectedIds);
    } else {
      path = this.environmentService.getRestEndpoint('mergeByIds');
      body = JSON.stringify(selectedIds);
    }
    return this.http.post(path, body, { headers });
  }

  deleteSpos(selectedIds: number[], filters: SpoFilters, mainChecker: boolean, currentLotId: number): Observable<boolean> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    let path = this.environmentService.getRestEndpoint('spos');
    let body: string;
    if (mainChecker) {
      if (!isDevMode()) {
        path += '/delete-filtered';
      }
      body = this.createFiltersBody(filters, currentLotId, selectedIds);
    } else {
      if (!isDevMode()) {
        path += '/delete';
      }
      body = JSON.stringify(selectedIds);
    }
    return this.http.post(path, body, { headers }).pipe(
      map((resp: string) => {
        if (resp === '0') {
          return true;
        } else {
          this.dialog.open(GenericErrorModalComponent, {
            width: '350px',
            data: resp,
          });
        }
      })
    );
  }

  deleteSpoItems(selectedIds: number[], filters: SpoFilters, mainChecker: boolean, currentLotId: number): Observable<boolean> {
    if (mainChecker) {
      return this.deleteFilteredSpoItems(selectedIds, filters, currentLotId);
    } else {
      return this.deleteSpoItemsByIds(selectedIds);
    }
  }

  getSuppliersFromSpos(lotId: number): Observable<Supplier[]> {
    let path = this.environmentService.getRestEndpoint('suppliersFromSpos');
    if (environment.envName !== 'mock') {
      path += '?lot=' + lotId;
    }
    return this.http.get(path).pipe(
      map((suppliers: Supplier[]) => {
        if (!isNilty(suppliers)) {
          return suppliers.map((it) => this.converter.fromJSONtoObj(it, Supplier));
        } else {
          return [];
        }
      })
    );
  }

  addSpoIdToSelected(id: number) {
    this.selectedSpos.push(id);
    this.selectedSpos.filter((v, i, a) => a.indexOf(v) === i);
  }
  removeSpoIdFromSelected(id: number) {
    const index = this.selectedSpos.indexOf(id);
    this.selectedSpos.splice(index, 1);
  }
  addSpoItemIdToSelected(groupId: number) {
    this.selectedGroupedSpoItems.push(groupId);
    this.selectedGroupedSpoItems.filter((v, i, a) => a.indexOf(v) === i);
  }
  removeSpoItemIdFromSelected(groupId: number) {
    const index = this.selectedGroupedSpoItems.indexOf(groupId);
    this.selectedGroupedSpoItems.splice(index, 1);
  }

  setSposPayment(selectedIds: number[], filters: SpoFilters, mainChecker: boolean, currentLotId: number, payment: boolean) {
    if (mainChecker) {
      return this.setFilteredSposPayment(selectedIds, filters, currentLotId, payment);
    } else {
      return this.setSposPaymentByIds(selectedIds, payment);
    }
  }

  closeSpoAndGenerateShipmentsFromSpo(spoId: number, currentLotId: number): Observable<any> {
    const path = this.environmentService.getRestEndpoint('closeSpoAndGenerateShipmentById') + '?id=' + spoId + '&lotId=' + currentLotId;
    return this.http.get(path);
  }

  generateShipmentsFromSpos(selectedIds: number[], filters: SpoFilters, mainChecker: boolean, currentLotId: number): Observable<any> {
    if (mainChecker) {
      return this.generateShipmentsFromSposByFilters(
        selectedIds,
        filters,
        currentLotId,
        this.environmentService.getRestEndpoint('generateShipmentsFromSposByFilters')
      );
    } else {
      const endpoint = this.environmentService.getRestEndpoint('generateShipmentsFromSposByIds') + '?lotId=' + currentLotId;
      return this.generateShipmentsFromSposByIds(selectedIds, endpoint);
    }
  }

  changeSpoStatus(status: number, selectedSpos: number[], filters: SpoFilters, mainChecker: boolean): Observable<boolean> {
    if (status === SPO_CANCELED_STATUS) {
      const dialogObservable = this.dialog
        .open(GenericConfirmationModalComponent, { data: 'Canceling SPOs will set all their ordered quantities to zero.' })
        .afterClosed();

      return dialogObservable.pipe(
        switchMap((resp: boolean, _: number) => {
          if (resp) {
            return this.doChangeSpoStatus(status, selectedSpos, filters, mainChecker);
          } else {
            return of(false);
          }
        })
      );
    } else {
      return this.doChangeSpoStatus(status, selectedSpos, filters, mainChecker);
    }
  }

  doChangeSpoStatus(status: number, selectedSpos: number[], filters: SpoFilters, mainChecker: boolean): Observable<boolean> {
    if (mainChecker) {
      return this.changeSpoStatusFromFilters(status, selectedSpos, filters);
    } else {
      return this.changeSpoStatusFromIds(status, selectedSpos);
    }
  }

  sendSpos(selectedSpos: number[], filters: SpoFilters, mainChecker: boolean, lotId: number): Observable<any> {
    if (mainChecker) {
      return this.sendSposFromFilters(selectedSpos, filters, lotId);
    } else {
      return this.sendSposFromIds(selectedSpos);
    }
  }

  private setSposPaymentByIds(selectedIds: number[], payment: boolean): Observable<any> {
    let path = this.environmentService.getRestEndpoint('spos');
    if (environment.envName !== 'mock') {
      path += '/set-payment=' + payment;
    }
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(selectedIds);
    return this.http.post(path, body, { headers });
  }

  private setFilteredSposPayment(selectedIds: number[], filters: SpoFilters, currentLotId: number, payment: boolean): Observable<any> {
    let path = this.environmentService.getRestEndpoint('spos');
    if (environment.envName !== 'mock') {
      path += '/set-payment-filtered=' + payment;
    }
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = this.createFiltersBody(filters, currentLotId, selectedIds);
    return this.http.post(path, body, { headers });
  }

  private generateShipmentsFromSposByIds(selectedIds: number[], path: string): Observable<any> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(selectedIds);
    return this.http.post(path, body, { headers });
  }

  private generateShipmentsFromSposByFilters(
    selectedIds: number[],
    filters: SpoFilters,
    currentLotId: number,
    path: string
  ): Observable<any> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = this.createFiltersBody(filters, currentLotId, selectedIds);
    return this.http.post(path, body, { headers });
  }

  private changeSpoStatusFromIds(status: number, selectedSpos: number[]): Observable<boolean> {
    const path = this.environmentService.getRestEndpoint('changeSpoStatusFromIds') + status;
    const headers = new HttpHeaders().append('Content-Type', 'application/json');

    const body = JSON.stringify(selectedSpos);
    return this.http.post(path, body, { headers }).pipe(map(() => true));
  }
  private changeSpoStatusFromFilters(status: number, selectedSpos: number[], filters: SpoFilters): Observable<boolean> {
    filters.listOfIds = selectedSpos;
    const path = this.environmentService.getRestEndpoint('changeSpoStatusFromFilters') + status;
    const headers = new HttpHeaders().append('Content-Type', 'application/json');

    const body = this.converter.fromObjtoJSON(filters);
    return this.http.post(path, body, { headers }).pipe(map(() => true));
  }

  private sendSposFromFilters(selectedSpos: number[], filters: SpoFilters, lotId: number): Observable<any> {
    const body = this.createFiltersBody(filters, lotId, selectedSpos);
    return this.http.post(this.environmentService.getRestEndpoint('sendSposFromFilters'), body);
  }

  private sendSposFromIds(selectedSpos: number[]): Observable<any> {
    return this.http.post(this.environmentService.getRestEndpoint('sendSposFromIds'), selectedSpos);
  }

  private deleteSpoItemsByIds(selectedIds: number[]): Observable<boolean> {
    let path = this.environmentService.getRestEndpoint('spoItems');
    if (environment.envName !== 'mock') {
      path += '/delete';
    }
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = JSON.stringify(selectedIds);
    return this.http.post(path, body, { headers }).pipe(
      map((resp: string) => {
        if (resp === '0') {
          return true;
        } else {
          this.dialog.open(GenericErrorModalComponent, {
            width: '350px',
            data: resp,
          });
        }
      })
    );
  }

  private deleteFilteredSpoItems(selectedIds: number[], filters: SpoFilters, currentLotId: number): Observable<boolean> {
    const path = this.environmentService.getRestEndpoint('spoItems') + '/delete-filtered';
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const body = this.createFiltersBody(filters, currentLotId, selectedIds);
    return this.http.post(path, body, { headers }).pipe(
      map((resp: string) => {
        if (resp === '0') {
          return true;
        } else {
          this.dialog.open(GenericErrorModalComponent, {
            width: '350px',
            data: resp,
          });
        }
      })
    );
  }
}
