<div class="row">
  <ng-container *ngIf="!dataSource || dataSource?.data.length === 0">
    <div class="col-md-12 small-table-title text-center">
      <h4>No supplier found</h4>
    </div>
  </ng-container>

  <div class="col-md-9 small-table-title border-bottom-title" [ngClass]="{ 'display-none': !dataSource || dataSource?.data.length === 0 }">
    <h4>Suppliers</h4>
  </div>
  <div class="col-md-3 fixed-height-40 border-bottom-title" [ngClass]="{ 'display-none': !dataSource || dataSource?.data.length === 0 }">
    <mat-paginator class="border-bottom-title" id="little-paginator" #paginator [pageSize]="5" [pageSizeOptions]="[5]"> </mat-paginator>
  </div>

  <div
    class="col-md-12 padding-left-0 padding-right-0"
    [ngStyle]="{ 'min-height.px': tableHeight }"
    [ngClass]="{ 'display-none': !dataSource || dataSource?.data.length === 0 }"
  >
    <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-40">
      <mat-table
        multiTemplateDataRows
        class="height-animation-table"
        #listingTable
        [dataSource]="dataSource"
        matSort
        (matSortChange)="sortData($event)"
      >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header="supplier">Name</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <p>{{ row.supplier.name }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sku">
          <mat-header-cell *matHeaderCellDef mat-sort-header="sku">Supplier SKU</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <p>{{ row?.sku ? row.sku : '--' }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="physicalQuantity">
          <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="physicalQuantity"
            >Physical quantity</mat-header-cell
          >
          <mat-cell class="quantity-column" *matCellDef="let row">
            <p>{{ (row.physicalQuantity | isNullOrUndefined) ? '--' : row.physicalQuantity }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="availableQuantity">
          <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="availableQuantity"
            >Available quantity</mat-header-cell
          >
          <mat-cell class="quantity-column" *matCellDef="let row">
            <p>{{ (row.availableQuantity | isNullOrUndefined) ? '--' : row.availableQuantity }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="extraQuantity">
          <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="extraQuantity"
            >Extra quantity</mat-header-cell
          >
          <mat-cell class="quantity-column" *matCellDef="let row">
            <p>{{ (row.extraQuantity | isNullOrUndefined) ? '--' : row.extraQuantity }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="purchasePrice">
          <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="purchasePrice">Purchase Price</mat-header-cell>
          <mat-cell class="value-column" *matCellDef="let row">
            <p>{{ (row?.purchasePrice | isNullOrUndefined) ? '--' : (row.purchasePrice | myCurrency) }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="latestSpo">
          <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="lastSpoPrice">Latest SPO Price</mat-header-cell>
          <mat-cell class="value-column" *matCellDef="let row">
            <p>{{ row?.lastSpoPrice ? (row.lastSpoPrice | myCurrency) : '--' }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lowestSpo">
          <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="lowestThirtyDaysSpoPrice"
            >Lowest SPO Price <br />
            (last 30 days)</mat-header-cell
          >
          <mat-cell class="value-column" *matCellDef="let row">
            <p>{{ row?.lowestThirtyDaysSpoPrice ? (row.lowestThirtyDaysSpoPrice | myCurrency) : '--' }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell class="shipment-status-column" *matHeaderCellDef mat-sort-header="status">Status</mat-header-cell>
          <mat-cell class="shipment-status-column" *matCellDef="let row">
            <p>{{ row?.status }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="updatedAt">
          <mat-header-cell *matHeaderCellDef mat-sort-header="updatedAt">Updated at</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <p>{{ row?.updatedAt ? row.updatedAt : '--' }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="countryOfOrigin">
          <mat-header-cell *matHeaderCellDef class="code-column"> Country of origin </mat-header-cell>
          <mat-cell class="code-column" *matCellDef="let row" matTooltipPosition="below">
            <p>{{ row?.countryOfOrigin }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="unNumber">
          <mat-header-cell *matHeaderCellDef class="code-column"> UN Number </mat-header-cell>
          <mat-cell class="code-column" *matCellDef="let row" matTooltipPosition="below">
            <p>{{row?.unNumber}}
              <i class="fa fa-trash icon-button" *ngIf="row?.unNumber" (click)="deleteUnNumber(row)"></i>
            </p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="showDetails">
          <mat-header-cell class="details-column" style="text-align: center;" *matHeaderCellDef
            >Stock locations / price history</mat-header-cell
          >
          <mat-cell class="details-column" *matCellDef="let row">
            <p>
              <i
                [ngClass]="selectedRow === row ? 'fa fa-chevron-circle-up' : 'fa fa-chevron-circle-down'"
                class="expand-icon"
                (click)="toggle(row)"
              ></i>
            </p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <mat-cell class="background-light" *matCellDef="let row" [attr.colspan]="displayedColumns.length">
            <div class="example-element-detail" [@detailExpand]="row == selectedRow ? 'expanded' : 'collapsed'">
              <div class="row margin-lr-0 padding-top-10 padding-bottom-10 width-100per">
                <div class="col-md-6">
                  <app-stock-items-table [stockItems]="row.stockItems"></app-stock-items-table>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-5">
                  <app-price-history-table [prices]="row.priceChanges"></app-price-history-table>
                </div>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns" [class.example-expanded-row]="selectedRow === row"></mat-row>

        <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="collapsed-detail-row"></mat-row>
        <mat-row *matRowDef="let row; columns: []" style="border: none; min-height: 0; max-height: 0;"></mat-row>
      </mat-table>
    </div>
  </div>
</div>
