import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';
import { LotSupplierAvailability } from './lot-supplier-availability-model';
import { PoItem } from './po-item-model';
import { Product } from './product-model';
import { Spo } from './spo-model';

@JsonObject('BaseSpoItem')
export class BaseSpoItem extends BaseObject {
  @JsonProperty('product', Product, true) product: Product = undefined;
  @JsonProperty('actualEan', String, true) actualEan: string = undefined;
  @JsonProperty('supplierPurchaseOrder', Spo, true) supplierPurchaseOrder: Spo = undefined;

  @JsonProperty('purchasePrice', Number, true) purchasePrice: number = undefined;

  @JsonProperty('requestedQuantity', Number, true) requestedQuantity: number = undefined;
  @JsonProperty('requestedValue', Number, true) requestedValue: number = undefined;
  @JsonProperty('requestedPurchasePrice', Number, true) requestedPurchasePrice: number = undefined;
  @JsonProperty('virtualRequestedPurchasePrice', Number, true) virtualRequestedPurchasePrice: number = undefined;

  @JsonProperty('confirmedQuantity', Number, true) confirmedQuantity: number = undefined;
  @JsonProperty('confirmedValue', Number, true) confirmedValue: number = undefined;
  @JsonProperty('confirmedPurchasePrice', Number, true) confirmedPurchasePrice: number = undefined;
  @JsonProperty('virtualConfirmedPurchasePrice', Number, true) virtualConfirmedPurchasePrice: number = undefined;

  @JsonProperty('orderedQuantity', Number, true) orderedQuantity: number = undefined;
  @JsonProperty('orderedValue', Number, true) orderedValue: number = undefined;
  @JsonProperty('orderedPurchasePrice', Number, true) orderedPurchasePrice: number = undefined;
  @JsonProperty('virtualOrderedPurchasePrice', Number, true) virtualOrderedPurchasePrice: number = undefined;

  @JsonProperty('margin', Number, true) margin: number = undefined;
  @JsonProperty('percentMargin', Number, true) percentMargin: number = undefined;
  @JsonProperty('virtualMargin', Number, true) virtualMargin: number = undefined;
  @JsonProperty('virtualPercentMargin', Number, true) virtualPercentMargin: number = undefined;
  @JsonProperty('virtualConfirmedMargin', Number, true) virtualConfirmedMargin: number = undefined;
  @JsonProperty('virtualConfirmedPercentMargin', Number, true) virtualConfirmedPercentMargin: number = undefined;

  @JsonProperty('obsoleteWithAcceptedGreaterThanZero', Boolean, true) obsoleteWithAcceptedGreaterThanZero: boolean = undefined;
}

@JsonObject('SpoItem')
export class SpoItem extends BaseSpoItem {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('purchaseOrderItem', PoItem, true) purchaseOrderItem: PoItem = undefined;
  @JsonProperty('lotSupplierAvailability', LotSupplierAvailability, true) lotSupplierAvailability: LotSupplierAvailability = undefined;
}

@JsonObject('GroupedSpoItem')
export class GroupedSpoItem extends BaseSpoItem {
  @JsonProperty('groupId', Number, true) id: number = undefined;
  @JsonProperty('spoiIds', [Number], true) spoiIds: number[] = undefined;
}
