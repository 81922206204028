import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { MainRemittancesComponent } from './main-remittances.component';
import { MainRemittancesFiltersComponent } from './main-remittances-filters/main-remittances-filters.component';
import { MainRemittancesRoutingModule } from './main-remittances-routing.module';
import { MainRemittancesListComponent } from './main-remittances-list/main-remittances-list.component';
import { MainRemittancesListDetailsComponent } from './main-remittances-details/main-remittances-list-details.component';
import { RemittanceService } from './remittance.service';

@NgModule({
  declarations: [
    MainRemittancesComponent,
    MainRemittancesFiltersComponent,
    MainRemittancesListComponent,
    MainRemittancesListDetailsComponent,
  ],
  imports: [SharedModule, MainRemittancesRoutingModule],
  providers: [RemittanceService],
  entryComponents: [],
})
export class MainRemittancesModule {}
