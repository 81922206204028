import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { addCheckers } from '../../../../../shared/checkers.service';
import { isNilty } from '../../../../../core/utils.service';
import { ClaimHeader } from '../../../../../models/claim-header-model';
import { ClaimHeaderFilters } from '../../../../../models/filters/claim-header-filters-model';
import { ImportExportService } from '../../../../../shared/import-export.service';
import { ClaimService } from '../claim.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-claim-headers-list',
  templateUrl: './claim-headers-list.component.html',
  styleUrls: ['./claim-headers-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ClaimHeadersListComponent implements OnInit {
  dataSource: MatTableDataSource<any>;

  displayedColumns = [
    'check',
    'invoiceNumber',
    'invoiceDate',
    'statusShortageClaim',
    'probableNC',
    'remainingShortageClaim',
    'statusPriceClaim',
    'remainingPriceClaim',
    'vendor',
    'shipmentCode',
    'carrier',
    'supplier',
    'trackingNumber',
    'deliveryDate',
    'hasPod',
    'settlement2022',
    'export',
    'hasBot',
    'activeSingleBot',
    'showDetails',
  ];

  selectedRow: ClaimHeader;

  selectedIds: number[] = [];
  selectedMainChecker = false;
  bulkActionsActive = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private claimService: ClaimService, private importExportService: ImportExportService, private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.resultsNumber = this.claimService.resultsNumber;
    this.selectedRow = this.claimService.claimHeaderSelectedRow;

    if (isNilty(this.claimService.claimHeaderFilters)) {
      this.claimService.claimHeaderFilters = new ClaimHeaderFilters();
    }

    this.paginator.pageSize = this.claimService.claimHeaderFilters.pageSize;
    this.paginator.pageIndex = this.claimService.claimHeaderFilters.pageNumber;

    this.dataSource = new MatTableDataSource([]);
    this.loadClaimHeaders(this.claimService.claimHeaderFilters);
  }

  loadClaimHeaders(filters: ClaimHeaderFilters) {
    this.resetCheckers();
    this.claimService.getFilteredClaimHeaders(filters).subscribe((ch: ClaimHeader[]) => {
      this.dataSource.data = addCheckers(ch, this.selectedIds, this.selectedMainChecker);
    });
  }

  loadPage() {
    this.claimService.claimHeaderFilters.pageNumber = this.paginator.pageIndex;
    this.claimService.claimHeaderFilters.pageSize = this.paginator.pageSize;
    this.loadClaimHeaders(this.claimService.claimHeaderFilters);
  }

  onSort() {
    this.claimService.claimHeaderFilters.sortBy = this.sort.active;
    this.claimService.claimHeaderFilters.sortDirection = this.sort.direction;
    this.loadClaimHeaders(this.claimService.claimHeaderFilters);
  }

  onFilter(filters: ClaimHeaderFilters) {
    this.paginator.pageIndex = 0;
    this.loadClaimHeaders(filters);
  }

  toggle(row: ClaimHeader) {
    this.selectedRow = this.selectedRow === row ? null : row;
    this.claimService.claimHeaderSelectedRow = this.selectedRow;
  }

  claimEdited() {
    this.loadClaimHeaders(this.claimService.claimHeaderFilters);
  }

  computeRemaining(ch: ClaimHeader, type: 'SC' | 'PC'): number {
    const claims = ch.claims.filter((claim) => claim.code.startsWith(type));
    if (claims.length > 0) {
      const last = claims[claims.length - 1];
      if (last.code === type) {
        return last.amountPaid;
      }
    }
    return 0;
  }

  export(claimHeader: ClaimHeader) {
    this.importExportService.exportClaimDocumentation(claimHeader.id).subscribe((resp) => console.log(resp));
  }

  onSelected(value: boolean, selectedClaim: any) {
    const selectedIndex = this.dataSource.data.findIndex((a) => a.id === selectedClaim.id);
    this.dataSource.data[selectedIndex].checked = value;
    if (value !== this.selectedMainChecker) {
      this.selectedIds.push(this.dataSource.data[selectedIndex].id);
      this.selectedIds.filter((v, i, a) => a.indexOf(v) === i);
    } else {
      const index = this.selectedIds.indexOf(this.dataSource.data[selectedIndex].id);
      this.selectedIds.splice(index, 1);
    }
    this.bulkActionsActive = this.dataSource.data.some((a) => a.checked) || this.selectedMainChecker;
  }

  toggleAll(value: boolean) {
    this.selectedMainChecker = value;
    this.dataSource.data.forEach((a) => (a.checked = value));
    this.selectedIds = [];
    this.bulkActionsActive = value;
  }

  applyFilter(filters: ClaimHeaderFilters) {
    this.paginator.pageIndex = 0;
    this.loadClaimHeaders(filters);
  }

  resetCheckers() {
    this.selectedIds = [];
    this.selectedMainChecker = false;
    this.bulkActionsActive = false;
  }

  downloadLetter() {
    this.claimService.downloadLetter(this.selectedIds, this.claimService.claimHeaderFilters, this.selectedMainChecker).subscribe(() => {
      this.snackBar.open('Request sent.', 'CLOSE')._dismissAfter(2000);
    });
  }

  enableDisableBot(status: string) {
    this.claimService
      .changeBotStatus(status, this.selectedIds, this.claimService.claimHeaderFilters, this.selectedMainChecker)
      .subscribe(() => {
        this.applyFilter(this.claimService.claimHeaderFilters);
        this.snackBar.open('Bot ' + status + ' on selected elements', 'CLOSE')._dismissAfter(2000);
      });
  }

  enableDisableSingleBot(claimHeader: ClaimHeader, status: string) {
    this.selectedIds.push(claimHeader.id);
    this.claimService
      .changeBotStatus(status, this.selectedIds, this.claimService.claimHeaderFilters, this.selectedMainChecker)
      .subscribe(() => {
        this.applyFilter(this.claimService.claimHeaderFilters);
        this.snackBar.open('Bot ' + status + ' on selected element', 'CLOSE')._dismissAfter(2000);
      });
  }
}
