<app-pos-list-filters (onPoFilter)="applyFilter($event)" [poEanDestsearched]="poEanDestsearched"></app-pos-list-filters>

<div class="bulk-actions-div">
  <mat-toolbar class="bar-custom">
    <div class="container">
      <div class="row">
        <div class="col-md-2">
          <button (click)="showDetails()" class="box-shadow-none btn-custom margin-right-6">Show POs details</button>
        </div>

        <div class="col-md-8">
          <button
            *ngIf="hasPoStateChange | async"
            [disabled]="!bulkActionsActive || !(poEanDest | isNullOrUndefinedOrEmptyString)"
            [matMenuTriggerFor]="changeStatusMenu"
            class="box-shadow-none btn-custom margin-right-6"
          >
            Change Status <i class="fa fa-angle-down sel-icon" aria-hidden="true"></i>
          </button>
          <mat-menu #changeStatusMenu="matMenu">
            <button mat-menu-item (click)="changePosStatus('New')">New</button>
            <button mat-menu-item (click)="changePosStatus('Open')">Open</button>
            <button mat-menu-item (click)="changePosStatus('Closed')">Closed</button>
            <button *ngIf="hasSendPoaSubmitted | async" mat-menu-item (click)="changePosStatus('Closed', true)">Closed (submitted)</button>
          </mat-menu>

          <ng-container [ngSwitch]="bulkActionsActive && (poEanDest | isNullOrUndefinedOrEmptyString)">
            <button *ngSwitchCase="true" [matMenuTriggerFor]="exportPosChoice" class="box-shadow-none btn-custom margin-right-6">
              <i class="fa fa-external-link" aria-hidden="true"></i> Export selected POs
            </button>
            <mat-menu #exportPosChoice="matMenu">
              <button mat-menu-item (click)="exportPos('excel')">Save as Excel</button>
              <button mat-menu-item (click)="exportPos('csv')">Save as CSV</button>
            </mat-menu>
            <button
              [disabled]="!(poEanDest | isNullOrUndefinedOrEmptyString)"
              *ngSwitchCase="false"
              [matMenuTriggerFor]="exportAllPosChoice"
              class="box-shadow-none btn-custom margin-right-6"
            >
              <i class="fa fa-external-link" aria-hidden="true"></i> Export all POs
            </button>
            <mat-menu #exportAllPosChoice="matMenu">
              <button mat-menu-item (click)="exportAllPos('excel')">Save as Excel</button>
              <button mat-menu-item (click)="exportAllPos('csv')">Save as CSV</button>
            </mat-menu>
          </ng-container>

          <button
            *ngIf="hasLotManagement | async"
            [disabled]="!bulkActionsActive || !(poEanDest | isNullOrUndefinedOrEmptyString)"
            (click)="addToLot()"
            class="box-shadow-none btn-custom margin-right-6"
          >
            <i class="fa fa-plus margin-right-6" aria-hidden="true"></i> Add to Lot
          </button>
        </div>

        <div class="col-md-2 text-right">
          <input
            type="text"
            [(ngModel)]="poEanDestModel"
            (keyup.enter)="onSearchPoEanDest()"
            class="box-shadow-none sea-custom no-focus"
            placeholder="Search PO/EAN/Destination &#xf002;"
            style="font-family: 'Titillium Web', FontAwesome, sans-serif"
          />
        </div>
      </div>
    </div>
  </mat-toolbar>
</div>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <hr class="divisor-title" style="width: calc(100% - 14rem)" />
      <div class="square" style="right: 14rem"></div>
      <h2 class="po-element-custom title-custom">Pos List</h2>
    </div>
  </div>
</div>

<div class="container table-outer">
  <mat-paginator #paginator [length]="resultsNumber | async" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()">
  </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table [dataSource]="dataSource" class="font-Titillium" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="check" sticky>
        <mat-header-cell *matHeaderCellDef class="checker-col">
          <mat-checkbox (change)="toggleAll($event.checked)" [ngModel]="mainChecker"></mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let po; let i = index" class="checker-col">
          <mat-checkbox (change)="onSelected($event.checked, po)" [ngModel]="po.checked"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="poId" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="poId" class="po-code-column"> PO ID </mat-header-cell>
        <mat-cell *matCellDef="let po" class="po-code-column"
          ><a [routerLink]="['/pos', po.id]">{{ po.poId }}</a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="vendor">
        <mat-header-cell *matHeaderCellDef mat-sort-header="vendor" class="vendor-column"> Vendor </mat-header-cell>
        <mat-cell *matCellDef="let po" class="vendor-column">{{ po.vendor.code }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="parentVendor">
        <mat-header-cell *matHeaderCellDef mat-sort-header="parentVendor" class="vendor-column">
          Parent <br />
          Vendor
        </mat-header-cell>
        <mat-cell *matCellDef="let po" class="vendor-column">{{ po.vendor?.parentVendor?.code }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="orderedOn">
        <mat-header-cell *matHeaderCellDef mat-sort-header="orderedOn" class="date-column"> Ordered on </mat-header-cell>
        <mat-cell *matCellDef="let po" class="date-column">{{ po.orderedOn }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="destination">
        <mat-header-cell *matHeaderCellDef mat-sort-header="destination" class="destination-column"> Ship to Location </mat-header-cell>
        <mat-cell
          *matCellDef="let po"
          [matTooltip]="po.destination ? po.destination.code + ' - ' + po.destination.name : ''"
          matTooltipPosition="below"
          class="destination-column"
        >
          <p>{{ po.destination ? po.destination.code + ' - ' + po.destination.name : '' }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="earliestDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="earliestDate" class="date-column">
          Delivery <br />
          window start
        </mat-header-cell>
        <mat-cell *matCellDef="let po" class="date-column">{{ po.earliestDate }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="latestDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="date-column">
          Delivery <br />
          window end
        </mat-header-cell>
        <mat-cell *matCellDef="let po" class="date-column">{{ po.latestDate }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="submittedQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header>
          Total <br />
          quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let po">
          <p>{{ po.submittedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="submittedValue">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header>
          Total <br />
          cost
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let po">
          <p>{{ po.submittedValue | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="acceptedQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="acceptedQuantity">
          Accepted <br />
          Quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let po">
          <p>{{ po.acceptedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="acceptedValue">
        <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="acceptedValue">
          Accepted <br />
          Value
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let po">
          <p>{{ po.acceptedValue | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastPoaQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="lastPoaQuantity">
          Last POA <br />
          Quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let po">
          <p>{{ po.lastPoaQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="hasLot">
        <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header> Has Lot </mat-header-cell>
        <mat-cell class="boolean-column" *matCellDef="let po">
          <p><i [ngClass]="po.hasLot | booleanIconPipe" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
        <mat-cell *matCellDef="let po">{{ po.status }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
