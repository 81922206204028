import { Injectable } from '@angular/core';
import { ConverterService } from '../core/converter.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNilty } from '../core/utils.service';
import { EnvironmentService } from '../environment.service';
import { LogDiscardedValueFilters } from '../models/filters/log-discarded-value-filters-model';
import { LogDiscardedValue } from '../models/log-discarded-value-model';
import { PaginationAndSorting } from '../models/filters/pagination-and-sorting-model';
import { LogDiscardedValueDetail } from '../models/log-discarded-value-detail-model';
import { Status } from '../models/status-model';

@Injectable()
export class LogDiscardedValueService {
  resultsNumber = new Subject<number>();

  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  getFilteredLogDiscardedValue(filters: LogDiscardedValueFilters): Observable<LogDiscardedValue[]> {
    const body = this.converter.fromObjtoJSON(filters);
    return this.http.post(this.environmentService.getRestEndpoint('logDiscardedValue'), body, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        this.resultsNumber.next(+resp.headers.get('Total-Length'));
        return resp.body;
      }),
      map((resp: LogDiscardedValue[]) => {
        if (!isNilty(resp)) {
          return resp.map((it) => this.converter.fromJSONtoObj(it, LogDiscardedValue));
        } else {
          return [];
        }
      })
    );
  }

  getFilteredLogDiscardedValueDetail(
    logDiscardedValueId: number,
    filters: PaginationAndSorting
  ): Observable<[number, LogDiscardedValueDetail[]]> {
    const body = this.converter.fromObjtoJSON(filters);
    return this.http
      .post(this.environmentService.getRestEndpoint('logDiscardedValueDetail') + '?logDiscardedValueId=' + logDiscardedValueId, body, {
        observe: 'response',
      })
      .pipe(
        map((resp: HttpResponse<any>) => [+resp.headers.get('Total-Length'), resp.body]),
        map((resp: [number, LogDiscardedValueDetail[]]) => {
          if (!isNilty(resp[1])) {
            return [resp[0], resp[1].map((it) => this.converter.fromJSONtoObj(it, LogDiscardedValueDetail))];
          } else {
            return [resp[0], []];
          }
        })
      );
  }

  getTypes(): Observable<Status[]> {
    return this.http.get(this.environmentService.getRestEndpoint('logDiscardedValue') + '/types').pipe(
      map((resp: Status[]) => {
        if (!isNilty(resp)) {
          return resp.map((it) => this.converter.fromJSONtoObj(it, Status));
        } else {
          return [];
        }
      })
    );
  }
}
