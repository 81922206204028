import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Lot } from '../../../models/lot-model';
import { LotsService } from '../../../shared/lots.service';
import { LotFilters } from '../../../models/filters/lot-filters-model';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
@Component({
  selector: 'app-lots-list',
  templateUrl: './lots-list.component.html',
  styleUrls: ['./lots-list.component.css'],
})
export class LotsListComponent implements OnInit, OnDestroy {
  // eslint-disable-next-line
  LOT_UPDATING_STATUS = 'Updating';

  dataSource: MatTableDataSource<Lot>;
  displayedColumns = ['lotName', 'lotDate', 'lotSubmittedQuantity', 'lotSubmittedValue', 'lotStatus', 'lotType'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private lotsService: LotsService) {}

  ngOnInit() {
    this.resultsNumber = this.lotsService.resultsNumber;
    if (this.lotsService.lotFilters === null) {
      this.lotsService.lotFilters = new LotFilters();
    }

    this.lotsService.lotFilters.pageNumber = 0;
    this.lotsService.lotFilters.pageSize = 10;
    this.lotsService.lotFilters.sortBy = 'date';
    this.lotsService.lotFilters.sortDirection = 'desc';
    this.lotsService.getFilteredLots(this.lotsService.lotFilters).subscribe((lots: Lot[]) => {
      this.dataSource = new MatTableDataSource<Lot>(lots);
    });
  }

  loadPage() {
    this.lotsService.lotFilters.pageNumber = this.paginator.pageIndex;
    this.lotsService.lotFilters.pageSize = this.paginator.pageSize;

    this.lotsService.getFilteredLots(this.lotsService.lotFilters).subscribe((lots: Lot[]) => {
      this.dataSource = new MatTableDataSource<Lot>(lots);
    });
  }

  onSort() {
    this.lotsService.lotFilters.sortBy = this.sort.active;
    this.lotsService.lotFilters.sortDirection = this.sort.direction;
    this.lotsService.getFilteredLots(this.lotsService.lotFilters).subscribe((lots: Lot[]) => {
      this.dataSource = new MatTableDataSource<Lot>(lots);
    });
  }

  applyFilters(filters) {
    this.lotsService.getFilteredLots(filters).subscribe((lots: Lot[]) => {
      this.dataSource = new MatTableDataSource<Lot>(lots);
    });
  }

  ngOnDestroy() {
    this.lotsService.lots = [];
  }
}
