/* eslint-disable */
import {
  AfterContentInit,
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  NgZone,
  Optional,
  Output,
  QueryList,
  Renderer2,
  Self,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { CdkConnectedOverlay, ViewportRuler } from '@angular/cdk/overlay';
import { ActiveDescendantKeyManager, LiveAnnouncer } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';
import { ErrorStateMatcher, MAT_OPTION_PARENT_COMPONENT, MatOptgroup, MatOption } from '@angular/material/core';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { DOWN_ARROW, END, ENTER, HOME, SPACE, UP_ARROW } from '@angular/cdk/keycodes';
import { debounceTime } from 'rxjs/operators';
import { isNilty } from '../../core/utils.service';
import { MAT_SELECT_SCROLL_STRATEGY, MatSelect, matSelectAnimations, MatSelectChange, MatSelectTrigger } from '@angular/material/select';

@Component({
  selector: 'app-mat-select',
  templateUrl: './app-mat-select.component.html',
  styles: [
    '.mat-select{display:inline-block;width:100%;outline:0}.mat-select-trigger{display:inline-table;cursor:pointer;position:relative;box-sizing:border-box}.mat-select-disabled .mat-select-trigger{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;cursor:default}.mat-select-value{display:table-cell;max-width:0;width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.mat-select-value-text{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.mat-select-arrow-wrapper{display:table-cell;vertical-align:middle}.mat-form-field-appearance-fill .mat-select-arrow-wrapper,.mat-form-field-appearance-standard .mat-select-arrow-wrapper{transform:translateY(-50%)}.mat-form-field-appearance-outline .mat-select-arrow-wrapper{transform:translateY(-25%)}.mat-select-arrow{width:0;height:0;border-left:5px solid transparent;border-right:5px solid transparent;border-top:5px solid;margin:0 4px}.mat-select-panel{min-width:112px;max-width:280px;overflow:auto;-webkit-overflow-scrolling:touch;padding-top:0;padding-bottom:0;max-height:256px;min-width:100%}.mat-select-panel:not([class*=mat-elevation-z]){box-shadow:0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12)}@media screen and (-ms-high-contrast:active){.mat-select-panel{outline:solid 1px}}.mat-select-panel .mat-optgroup-label,.mat-select-panel .mat-option{font-size:inherit;line-height:3em;height:3em}.mat-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-form-field-flex{cursor:pointer}.mat-form-field-type-mat-select .mat-form-field-label{width:calc(100% - 18px)}.mat-select-placeholder{transition:color .4s .133s cubic-bezier(.25,.8,.25,1)}.mat-form-field-hide-placeholder .mat-select-placeholder{color:transparent;transition:none;display:block}',
  ],
  styleUrls: ['./app-mat-select.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [matSelectAnimations.transformPanel, matSelectAnimations.transformPanelWrap],
  providers: [
    { provide: MatFormFieldControl, useExisting: AppMatSelectComponent },
    { provide: MAT_OPTION_PARENT_COMPONENT, useExisting: AppMatSelectComponent },
  ],
})
export class AppMatSelectComponent extends MatSelect implements AfterContentInit {
  @Input() panelClass: string;
  @Input() filterKey: string;

  @Input() automaticSearch = true;

  // tslint:disable-next-line
  @Input('aria-label') ariaLabel: string;
  @Input() errorStateMatcher: ErrorStateMatcher;

  @Output() openedChange: EventEmitter<boolean>;
  // tslint:disable-next-line
  @Output('opened') _openedStream: EventEmitter<void>;
  // tslint:disable-next-line
  @Output('closed') _closedStream: EventEmitter<void>;
  @Output() selectionChange: EventEmitter<MatSelectChange>;
  @Output() valueChange: EventEmitter<any>;
  @Output() filterChange: EventEmitter<{ filterText: string; filterKey: string }> = new EventEmitter<{
    filterText: string;
    filterKey: string;
  }>();

  @ViewChild('searchInput', { static: false }) private searchInput: ElementRef;
  @ViewChild('MatSelectContent', { static: false }) private elementRef: ElementRef;
  @ViewChild('trigger', { static: true }) trigger: ElementRef;
  @ViewChild('panel', { static: false }) panel: ElementRef;
  @ViewChild(CdkConnectedOverlay, { static: true }) overlayDir: CdkConnectedOverlay;
  @ContentChildren(MatOption, { descendants: true }) options: QueryList<MatOption>;
  @ContentChildren(MatOptgroup) optionGroups: QueryList<MatOptgroup>;
  @ContentChild(MatSelectTrigger, { static: false }) customTrigger: MatSelectTrigger;

  filterText = '';
  showFilter: boolean;

  triggerDebounceFilter = new EventEmitter<any>();

  @HostBinding('attr.role') role = 'listbox';
  @HostBinding('class') class = 'mat-select';
  @HostBinding('attr.tabindex') tabIndex: number;
  @HostBinding('attr.aria-label') _ariaLabel: string;
  // tslint:disable-next-line
  @HostBinding('attr.aria-labelledby') @Input('aria-labelledby') ariaLabelledby: string;
  @HostBinding('attr.aria-required') 'required.toString()';
  @HostBinding('attr.aria-disabled') 'disabled.toString()';
  @HostBinding('attr.aria-invalid') @HostBinding('class.mat-select-invalid') errorState;
  @HostBinding('attr.aria-owns') 'panelOpen ? _optionIds : null';
  @HostBinding('attr.aria-describedby') '_ariaDescribedby || null';
  @HostBinding('class.mat-select-disabled') @Input() disabled: boolean;
  @HostBinding('class.mat-select-required') get isRequired(): boolean {
    return this.required;
  }
  @HostBinding('attr.aria-activedescendant') '_getAriaActiveDescendant()';

  @HostListener('keydown', ['$event']) _handleKeydown(event: KeyboardEvent) {
    super._handleKeydown(event);
  }

  @HostListener('focus') _onFocus() {
    super._onFocus();
  }

  @HostListener('blur') _onBlur() {
    super._onBlur();
  }

  constructor(
    _viewportRuler: ViewportRuler,
    _changeDetectorRef: ChangeDetectorRef,
    _ngZone: NgZone,
    _defaultErrorStateMatcher: ErrorStateMatcher,
    elementRef: ElementRef,
    @Optional() _dir: Directionality,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    @Optional() _parentFormField: MatFormField,
    @Optional() @Self() ngControl: NgControl,
    @Attribute('tabindex') tabIndex: string,
    @Inject(MAT_SELECT_SCROLL_STRATEGY) _scrollStrategyFactory: any,
    _liveAnnouncer: LiveAnnouncer
  ) {
    super(
      _viewportRuler,
      _changeDetectorRef,
      _ngZone,
      _defaultErrorStateMatcher,
      elementRef,
      _dir,
      _parentForm,
      _parentFormGroup,
      _parentFormField,
      ngControl,
      tabIndex,
      _scrollStrategyFactory,
      _liveAnnouncer
    );
  }

  ngAfterContentInit() {
    super.ngAfterContentInit();
    this.initKeyManager();

    if (!this.automaticSearch) {
      this.triggerDebounceFilter.pipe(debounceTime(500)).subscribe((e: any) => {
        this.filterChange.emit(e);
      });
    }
  }

  private initKeyManager() {
    this._keyManager = new ActiveDescendantKeyManager(this.options)
      .withTypeAhead()
      .withVerticalOrientation()
      .withHorizontalOrientation(this._isRtl() ? 'rtl' : 'ltr');
  }

  open() {
    super.open();
    this.showFilter = this.options.length > 10 || !isNilty(this.filterText) || !this.automaticSearch;
    if (!this.disabled && this.showFilter) {
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      }, 0);
    }
  }

  close() {
    super.close();
    setTimeout(() => {
      if (!this.disabled && this.showFilter) {
        if (this.filterText !== '' && isNilty(this.value)) {
          this.filterText = '';
          if (this.automaticSearch) {
            this.filterChange.emit({ filterText: this.filterText, filterKey: this.filterKey });
          } else {
            this.triggerDebounceFilter.emit({ filterText: this.filterText, filterKey: this.filterKey });
          }
        }
      }
    }, 0);
  }

  inputKeyDown(event) {
    const keyCode = event.keyCode;
    const isArrowKey = keyCode === UP_ARROW || keyCode === DOWN_ARROW;
    if (keyCode === SPACE || (!isArrowKey && keyCode !== ENTER && keyCode !== HOME && keyCode !== END)) {
      event.stopPropagation();
      setTimeout(() => {
        if (this.filterText !== event.target.value) {
          this.filterText = event.target.value;
          this._keyManager.setActiveItem(-1);
          if (this.automaticSearch) {
            this.filterChange.emit({ filterText: this.filterText, filterKey: this.filterKey });
          } else {
            this.triggerDebounceFilter.emit({ filterText: this.filterText, filterKey: this.filterKey });
          }
        }
      }, 0);
    }
  }
}
