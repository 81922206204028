import { Component, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { InventoryService } from '../../../../../inventory/inventory.service';
import { isNilty } from '../../../../../../core/utils.service';
import { MatChipsData } from '../../../../../../models/mat-chips-data-model';
import { Supplier } from '../../../../../../models/supplier-model';
import { PaymentDocumentService } from '../../payment-document.service';
import { PaymentDocumentFilters, paymentDocumentFiltersMap } from '../../../../../../models/filters/payment-document-filters-model';
import { Status } from '../../../../../../models/status-model';

@Component({
  selector: 'app-payment-document-list-filters',
  templateUrl: './payment-document-list-filters.component.html',
  styleUrls: ['./payment-document-list-filters.component.css'],
})
export class PaymentDocumentListFiltersComponent implements OnInit {
  constructor(private inventoryService: InventoryService, private paymentDocumentService: PaymentDocumentService) {}

  filterForm: FormGroup;
  public openFilter = false;

  filtersMap: MatChipsData[] = [];

  suppliers: Supplier[] = [];
  status: Status[] = [];

  @Output() onFilter = new Subject<PaymentDocumentFilters>();

  ngOnInit() {
    this.filterForm = new FormGroup({
      documentNumber: new FormControl(),
      supplier: new FormControl(),
      documentDate: new FormControl(),
      status: new FormControl(),
      shipments: new FormControl(),
    });

    if (!isNilty(this.paymentDocumentService.paymentDocumentFilters !== null)) {
      this.filterForm.patchValue(this.paymentDocumentService.paymentDocumentFilters);
      this.updateFiltersMap();
    }

    this.inventoryService.getAllSuppliers().subscribe((suppliers: Supplier[]) => (this.suppliers = suppliers));
    this.paymentDocumentService.getPaymentDocumentStatus().subscribe((status: Status[]) => (this.status = status));
  }

  onSubmitFilter() {
    this.saveFilters();
    this.updateFiltersMap();
    this.onFilter.next(this.paymentDocumentService.paymentDocumentFilters);
  }

  resetFilters() {
    this.filterForm.reset();
    this.saveFilters();
    this.updateFiltersMap();
    this.onFilter.next(this.paymentDocumentService.paymentDocumentFilters);
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeevent() {
    this.openFilter = false;
  }

  saveFilters() {
    this.paymentDocumentService.paymentDocumentFilters.documentNumber = this.filterForm.value.documentNumber;
    this.paymentDocumentService.paymentDocumentFilters.supplier = this.filterForm.value.supplier;
    this.paymentDocumentService.paymentDocumentFilters.documentDate = this.filterForm.value.documentDate;
    this.paymentDocumentService.paymentDocumentFilters.status = this.filterForm.value.status;
    this.paymentDocumentService.paymentDocumentFilters.shipments = isNilty(this.filterForm.value.shipments)
      ? null
      : this.filterForm.value.shipments.split('\n');

    this.paymentDocumentService.paymentDocumentFilters.pageNumber = null;
    this.paymentDocumentService.paymentDocumentFilters.pageSize = null;
    this.paymentDocumentService.paymentDocumentFilters.sortBy = null;
    this.paymentDocumentService.paymentDocumentFilters.sortDirection = null;
  }

  updateFiltersMap() {
    this.filtersMap = paymentDocumentFiltersMap(
      this.filterForm.value.documentNumber,
      this.filterForm.value.supplier,
      this.filterForm.value.documentDate,
      this.filterForm.value.status,
      isNilty(this.filterForm.value.shipments) ? null : this.filterForm.value.shipments.split('\n')
    );
  }
}
