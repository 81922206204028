import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { VendorsService } from '../../vendors.service';
import { Vendor } from '../../../../../models/vendor-model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-child-vendors-dialog',
  templateUrl: './child-vendors-dialog.component.html',
  styleUrls: ['./child-vendors-dialog.component.css'],
})
// eslint-disable-next-line
export class ChildVendorsDialog implements OnInit {
  dataSource: MatTableDataSource<Vendor>;

  displayedColumns = ['code', 'name', 'country', 'showChildren', 'edit'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    public dialogRef: MatDialogRef<ChildVendorsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public vendorsService: VendorsService
  ) {}

  ngOnInit() {
    this.vendorsService.getChildrenVendors(this.data.vendor.id).subscribe((children: Vendor[]) => {
      this.dataSource = new MatTableDataSource(children);
      this.dataSource.paginator = this.paginator;
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
