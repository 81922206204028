<mat-toolbar id="header" color="primary" class="background-header header-position">
  <div class="container">
    <div class="row">
      <div class="col-md-2" style="display: flex; align-items: center">
        <a (click)="refreshHomepage()" class="logo-link">
          <img src="https://brandon-frontend-assets.s3.eu-west-1.amazonaws.com/ambra/{{ logoUrl }}" height="30" />
        </a>
      </div>

      <div class="col-md-2"></div>
      <div class="col-md-8 text-right" style="display: flex; align-items: center">
        <button class="btn-header no-focus" routerLink="/pos" routerLinkActive="active">Amazon PO</button>
        <button class="btn-header no-focus" routerLink="/lots" routerLinkActive="active" [ngClass]="{ active: isEditMode }">Lots</button>
        <button class="btn-header no-focus" routerLink="/inventory" routerLinkActive="active">Inventory</button>
        <button class="btn-header no-focus" routerLink="/spos" routerLinkActive="active">SPO</button>
        <button class="btn-header no-focus" routerLink="/payments" routerLinkActive="active">Payments</button>
        <button class="btn-header no-focus" routerLink="/shipments" routerLinkActive="active">Shipments</button>
        <button class="btn-header no-focus" routerLink="/invoices" routerLinkActive="active">Invoices</button>
        <button class="btn-header no-focus" routerLink="/remittances" routerLinkActive="active">Remittances</button>
        <button class="btn-header no-focus margin-right-15" routerLink="/deductions" routerLinkActive="active">Deductions</button>

        <label class="email-custom"><i class="fa fa-user padding-right-5" aria-hidden="true"></i>{{ currentMail }}</label>

        <button
          routerLinkActive="active-conf"
          *ngIf="isAdmin | async"
          routerLink="/configuration"
          class="configuration-button smaller-configuration-button no-focus"
          matTooltip="Configuration"
        >
          <i class="fa fa-cog" aria-hidden="true"></i>
        </button>

        <a (click)="logout()">
          <button class="configuration-button no-focus">
            <i class="fa fa-user" aria-hidden="true"></i>
            Logout
          </button>
        </a>
      </div>
    </div>
  </div>
</mat-toolbar>
