import { AfterViewInit, Component, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { Vendor, VendorsFromLotOutput } from '../../../../models/vendor-model';
import { Warehouse } from '../../../../models/warehouse-model';
import { LotEditService } from '../../lot-edit.service';
import { VendorsService } from '../../../configuration/vendors/vendors.service';
import { WarehousesService } from '../../../configuration/warehouses/warehouses.service';
import { StockLocation } from '../../../../models/stock-location-model';
import { fulfilFiltersMap, FulfilmentFilters } from '../../../../models/filters/fulfilment-filters-model';
import { LotsService } from '../../../../shared/lots.service';
import { FulfilmentService } from '../../../../shared/fulfilment.service';
import { MatChipsData } from '../../../../models/mat-chips-data-model';

@Component({
  selector: 'app-warehouse-fulfilment-filters',
  templateUrl: './warehouse-fulfilment-filters.component.html',
  styleUrls: ['./warehouse-fulfilment-filters.component.css'],
})
export class WarehouseFulfilmentFiltersComponent implements OnInit, AfterViewInit {
  constructor(
    private vendorsService: VendorsService,
    private warehousesService: WarehousesService,
    private fulfilmentService: FulfilmentService,
    private lotEditService: LotEditService,
    private lotsService: LotsService
  ) {}

  warehouses: Warehouse[] = [];

  vendors: Vendor[] = [];

  stockLocations: StockLocation[] = [];

  @Output() onFilter = new Subject<FulfilmentFilters>();

  filterForm: FormGroup;

  public openFilter = false;

  filtersMap: MatChipsData[] = [];

  ngOnInit() {
    this.filterForm = new FormGroup({
      vendor: new FormControl(),
      destination: new FormControl(),
      ean: new FormControl(),
      po: new FormControl(),
      stockLocation: new FormControl(),
      crossUkBorder: new FormControl(),
    });

    if (this.vendorsService.lotEditVendors === undefined) {
      this.vendorsService.getVendorsFromLot(this.lotsService.currentLotId).subscribe((vendorsResp: VendorsFromLotOutput) => {
        this.vendors = vendorsResp.vendors;
        this.setVendor();
      });
    } else {
      this.vendors = this.vendorsService.lotEditVendors;
      this.setVendor();
    }

    if (this.warehousesService.lotEditWarehouses === undefined) {
      this.warehousesService.getWarehousesFromLot(this.lotsService.currentLotId).subscribe((warehouses: Warehouse[]) => {
        this.warehouses = warehouses;
      });
    } else {
      this.warehouses = this.warehousesService.lotEditWarehouses;
    }

    this.fulfilmentService.getStockLocationsFromFulfilments(this.lotsService.currentLotId).subscribe((stockLocResp: StockLocation[]) => {
      this.stockLocations = stockLocResp;
      this.setStockLocation();
      this.updateFiltersMap();
    });

    if (this.lotEditService.fulfilmentFilters !== undefined) {
      this.filterForm.patchValue(this.lotEditService.fulfilmentFilters);
      this.setVendor();
      this.setStockLocation();
    }
    this.updateFiltersMap();
  }

  setVendor() {
    if (this.lotEditService.fulfilmentFilters && this.lotEditService.fulfilmentFilters.vendor) {
      this.filterForm.controls['vendor'].patchValue(this.vendors.find((it) => it.id === this.lotEditService.fulfilmentFilters.vendor));
    }
  }

  setStockLocation() {
    if (this.lotEditService.fulfilmentFilters && this.lotEditService.fulfilmentFilters.stockLocation) {
      this.filterForm.controls['stockLocation'].patchValue(
        this.stockLocations.find((it) => it.id === this.lotEditService.fulfilmentFilters.stockLocation)
      );
    }
  }

  ngAfterViewInit() {
    this.updateFiltersMap();
  }

  saveFiltersToService() {
    this.lotEditService.fulfilmentFilters.lot = this.lotsService.currentLotId;
    this.lotEditService.fulfilmentFilters.vendor = this.filterForm.value.vendor ? this.filterForm.value.vendor.id : null;
    this.lotEditService.fulfilmentFilters.ean = this.filterForm.value.ean;
    this.lotEditService.fulfilmentFilters.po = this.filterForm.value.po;
    this.lotEditService.fulfilmentFilters.destination = this.filterForm.value.destination ? this.filterForm.value.destination.id : null;
    this.lotEditService.fulfilmentFilters.stockLocation =
      this.filterForm.value.stockLocation ?
      this.filterForm.value.stockLocation.id :
      null;
    this.lotEditService.fulfilmentFilters.crossUkBorder = this.filterForm.value.crossUkBorder ? this.filterForm.value.crossUkBorder : null;
    this.lotEditService.fulfilmentFilters.pageNumber = null;
    this.lotEditService.fulfilmentFilters.pageSize = null;
    this.lotEditService.fulfilmentFilters.sortDirection = null;
    this.lotEditService.fulfilmentFilters.sortBy = null;
  }

  onSubmitFilter() {
    this.saveFiltersToService();
    this.onFilter.next(this.lotEditService.fulfilmentFilters);
    this.updateFiltersMap();
  }

  resetFilters() {
    this.filterForm.reset();
    this.updateFiltersMap();
    this.saveFiltersToService();
    this.onFilter.next(this.lotEditService.fulfilmentFilters);
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeEvent() {
    this.openFilter = false;
  }

  updateFiltersMap() {
    this.filtersMap = fulfilFiltersMap(
      this.filterForm.value.vendor,
      this.filterForm.value.ean,
      this.filterForm.value.po,
      this.filterForm.value.destination,
      this.filterForm.value.stockLocation,
      this.filterForm.value.crossUkBorder
    );
  }
}
