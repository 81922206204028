import { JsonObject, JsonProperty } from 'json2typescript';
import { Category } from '../category-model';
import { MatChipsData } from '../mat-chips-data-model';
import { Season } from '../season-model';
import { Supplier } from '../supplier-model';
import { PaginationAndSorting } from './pagination-and-sorting-model';

@JsonObject('ProductsFilters')
export class ProductsFilters extends PaginationAndSorting {
  @JsonProperty('brand', String, true) brand: string = undefined;
  @JsonProperty('supplierId', Number, true) supplierId: number = undefined;
  @JsonProperty('categoryId', Number, true) categoryId: number = undefined;
  @JsonProperty('seasonId', Number, true) seasonId: number = undefined;
  @JsonProperty('codes', String, true) codes: string = undefined;
  @JsonProperty('status', String, true) status: string = undefined;
  @JsonProperty('hasPo', Boolean, true) hasPo: boolean = undefined;

  supplier: Supplier;
  category: Category;
  season: Season;
}

export const productFiltersMap = (
  brand?: string,
  supplier?: string,
  category?: string,
  season?: string,
  codes?: string,
  status?: string,
  hasPo?: boolean
): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (brand !== null && brand !== undefined) {
    map.push(new MatChipsData('Brand', brand));
  }
  if (supplier !== null && supplier !== undefined) {
    map.push(new MatChipsData('Supplier', supplier));
  }
  if (category !== null && category !== undefined) {
    map.push(new MatChipsData('Category', category));
  }
  if (season !== null && season !== undefined) {
    map.push(new MatChipsData('Season', season));
  }
  if (codes !== null && codes !== undefined) {
    map.push(new MatChipsData('Code', codes));
  }
  if (status !== null && status !== undefined) {
    map.push(new MatChipsData('Status', status));
  }
  if (hasPo !== null && hasPo !== undefined) {
    map.push(new MatChipsData('Has PO', hasPo.toString()));
  }
  return map;
};
