<div class="row">
  <ng-container *ngIf="!dataSource || dataSource?.data.length === 0">
    <div class="col-md-12 small-table-title text-center">
      <h4>No stock items found</h4>
    </div>
  </ng-container>

  <div class="col-md-6 small-table-title border-bottom-title" [ngClass]="{ 'display-none': !dataSource || dataSource?.data.length === 0 }">
    <h4>Stock locations</h4>
  </div>
  <div class="col-md-6 fixed-height-40 border-bottom-title" [ngClass]="{ 'display-none': !dataSource || dataSource?.data.length === 0 }">
    <mat-paginator class="border-bottom-title" id="little-paginator" #paginator [pageSize]="3" [pageSizeOptions]="[3]"> </mat-paginator>
  </div>

  <div
    class="col-md-12 fixed-table-5-rows padding-right-0 padding-left-0"
    [ngClass]="{ 'display-none': !dataSource || dataSource?.data.length === 0 }"
  >
    <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-40">
      <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
        <ng-container matColumnDef="stockLocation">
          <mat-header-cell *matHeaderCellDef mat-sort-header="stockLocation">Stock location</mat-header-cell>
          <mat-cell *matCellDef="let ci">
            <p>{{ ci?.stockLocation?.code + ' - ' + ci?.stockLocation?.name }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="physicalQuantity">
          <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="physicalQuantity"
            >Physical quantity</mat-header-cell
          >
          <mat-cell class="quantity-column" *matCellDef="let ci">
            <p>{{ ci?.physicalQuantity }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="availableQuantity">
          <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="availableQuantity"
            >Available quantity</mat-header-cell
          >
          <mat-cell class="quantity-column" *matCellDef="let ci">
            <p>{{ ci?.availableQuantity }}</p>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>
</div>
