import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InventoryService } from '@container/inventory/inventory.service';
import { PrioritySupplier } from '@models/publication-rule';
import { Supplier } from '@models/supplier-model';

@Component({
  selector: 'app-priority-supplier-list-edit',
  templateUrl: './priority-supplier-list-edit.component.html',
  styleUrls: ['./priority-supplier-list-edit.component.css'],
})
export class PrioritySupplierListEditComponent implements OnInit {
  @Input() set _prioritySuppliers(ps: PrioritySupplier[]) {
    this.prioritySuppliers = ps;
  }

  @Output() saveButtonDisabled = new EventEmitter<boolean>();

  prioritySuppliers: PrioritySupplier[];
  suppliers: Supplier[];
  disabledConfirmButton = false;

  constructor(private inventoryService: InventoryService, private snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.inventoryService.getAllSuppliers().subscribe((suppliers: Supplier[]) => {
      this.suppliers = suppliers;
      this.checkRequestSuppliers();
    });
  }

  checkRequestSuppliers() {
    if (this.prioritySuppliers.length === 0) {
      this.saveButtonDisabled.emit(true);
    }
  }

  addPrioritySupplier() {
    const ps = new PrioritySupplier();
    ps.supplierId = null;
    ps.priority = 100;
    ps.isEdit = true;
    this.prioritySuppliers.push(ps);
    this.saveButtonDisabled.emit(true);
  }

  confirmPrioritySupplier(prioritySupplier: PrioritySupplier) {
    this.saveButtonDisabled.emit(false);
    prioritySupplier.isEdit = false;
  }

  deletePrioritySupplier(prioritySupplier: PrioritySupplier) {
    const index = this.prioritySuppliers.findIndex((it) => it === prioritySupplier);
    this.prioritySuppliers.splice(index, 1);
    this.saveButtonDisabled.emit(false);
  }

  setPriorityValue(event: any) {
    if (event.type === 'keydown') {
      const value = event.target.value;
      if (event.key >= '0' && event.key <= '9') {
        const newValue = Number(value + event.key);
        if (newValue < 1 || newValue > 100) {
          event.preventDefault();
          this.snackBar.open('Invalid value. Please enter a value between 1 and 100.', 'Close', {
            duration: 5000,
          });
        }
      }
    } else if (event.type === 'input') {
      const value = event.target.value;
      if (value < 1 || value > 100) {
        event.target.style.color = 'red';
        this.disabledConfirmButton = true;
        this.snackBar.open('Invalid value. Please enter a value between 1 and 100.', 'Close', {
          duration: 5000,
        });
      } else {
        this.disabledConfirmButton = false;
        event.target.style.color = 'black';
      }
    }
  }

  addSupplierDescription(ps: PrioritySupplier): string {
    if (this.suppliers) {
      const supplier = this.suppliers.find((it) => it.id === ps.supplierId);
      return `Priority: ${ps.priority} | Supplier: ${supplier ? supplier.description : ''}`;
    }
    return '';
  }
}
