<mat-toolbar id="subsectionMenu" color="accent">
  <span class="fill-remaining-space text-center">
    <button class="btn-main-penalties" routerLink="claims" routerLinkActive="active">
      <i class="fa fa-pencil-square-o padding-bottom-1" aria-hidden="true"></i>
      Claims
    </button>
    <button class="btn-main-penalties" routerLink="penalties" routerLinkActive="active">
      <i class="fa fa-list-ul padding-bottom-1" aria-hidden="true"></i>
      Penalties
    </button>
    <button class="btn-main-penalties" routerLink="dispute-bot-logs" routerLinkActive="active">
      <i class="fa fa-list-ul padding-bottom-1" aria-hidden="true"></i>
      Dispute Bot Logs
    </button>
  </span>
</mat-toolbar>
