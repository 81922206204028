import { JsonObject, JsonProperty } from 'json2typescript';
import { Supplier } from './supplier-model';
import { Product } from './product-model';
import { BaseObject } from './base-object-model';

@JsonObject('PriceChange')
export class PriceChange {
  @JsonProperty('date', String, true) date: string = undefined;
  @JsonProperty('price', Number, true) price: number = undefined;
}

@JsonObject('CatalogItemNoProduct')
export class CatalogItemNoProduct {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('supplier', Supplier, false) supplier: Supplier = undefined;
  @JsonProperty('physicalQuantity', Number, true) physicalQuantity: number = undefined;
  @JsonProperty('availableQuantity', Number, true) availableQuantity: number = undefined;
  @JsonProperty('extraQuantity', Number, true) extraQuantity: number = undefined;
  @JsonProperty('purchasePrice', Number, true) purchasePrice: number = undefined;
  @JsonProperty('lastSpoPrice', Number, true) lastSpoPrice: number = undefined;
  @JsonProperty('lowestThirtyDaysSpoPrice', Number, true) lowestThirtyDaysSpoPrice: number = undefined;
  @JsonProperty('sku', String, true) sku: string = undefined;
  @JsonProperty('updatedAt', String, true) updatedAt: string = undefined;
  @JsonProperty('status', String, true) status: string = undefined;
  @JsonProperty('priceChanges', [PriceChange], true) priceChanges: PriceChange[] = undefined;

  @JsonProperty('countryOfOrigin', String, true) countryOfOrigin: string = undefined;
  @JsonProperty('unNumber', String, true) unNumber: string = undefined;
}

@JsonObject('ProductWithCatalog')
export class ProductWithCatalog extends Product {
  @JsonProperty('catalogItems', [CatalogItemNoProduct], true) catalogItems: CatalogItemNoProduct[] = undefined;
}

@JsonObject('ProductsCatalog')
export class ProductsCatalog extends BaseObject {
  @JsonProperty('product', ProductWithCatalog, false) productWithCatalog: ProductWithCatalog = undefined;
  @JsonProperty('stockBalance', Number, false) stockBalance: number = undefined;
}
