<app-single-spo-filters (onFilter)="applyFilters($event)"></app-single-spo-filters>

<div [ngClass]="{ 'is-not-in-lot': isNotInLot }">
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <hr class="divisor-title" style="width: calc(100% - 62rem);" />
        <div class="square" style="right: 62rem;"></div>
        <h2 class="po-element-custom title-custom">SPO: {{ spo.code }}</h2>
      </div>
    </div>
  </div>

  <app-single-spo-kpi [spo]="spo" [kpiData]="kpiData"></app-single-spo-kpi>

  <mat-toolbar class="lot-edit-bulk-actions">
    <div class="container">
      <div class="row">
        <div class="col-md-10">
          <button class="btn-custom box-shadow-none margin-right-6" [matMenuTriggerFor]="exportShipmentItemsChoiceNew">
            <i class="fa fa-list margin-right-6" aria-hidden="true"></i>Export Items
          </button>
          <mat-menu #exportShipmentItemsChoiceNew="matMenu">
            <button mat-menu-item (click)="export('excel')">Save as Excel</button>
            <button mat-menu-item (click)="export('csv')">Save as CSV</button>
          </mat-menu>

          <ng-container *ngIf="hasFulfillmentCreation | async">
            <button [matMenuTriggerFor]="editMenu" class="box-shadow-none btn-custom margin-right-6">
              <i class="fa fa-pencil margin-right-6" aria-hidden="true"></i> Edit
            </button>
            <mat-menu #editMenu="matMenu">
              <button mat-menu-item [matMenuTriggerFor]="changePaymentMenu">
                <i class="fa fa-eur margin-right-6" aria-hidden="true"></i> Payment status
              </button>
              <mat-menu #changePaymentMenu="matMenu">
                <button mat-menu-item [disabled]="spo.payment" (click)="setPayment(true)">Paid</button>
                <button mat-menu-item [disabled]="!spo.payment" (click)="setPayment(false)">Not paid</button>
              </mat-menu>

              <button mat-menu-item [matMenuTriggerFor]="statusMenu">Change Status</button>
              <mat-menu #statusMenu="matMenu">
                <button mat-menu-item [disabled]="spo.spoStatus === s.code" *ngFor="let s of spoStatusArray" (click)="changeStatus(s.code)">
                  {{ s.description }}
                </button>
              </mat-menu>

              <button mat-menu-item [matMenuTriggerFor]="deleteMenu">Delete</button>
              <mat-menu #deleteMenu="matMenu">
                <button mat-menu-item (click)="deleteSpo()">SPO</button>
                <button mat-menu-item (click)="deleteSpoItems()" [disabled]="!itemsActionsActive">Selected Items</button>
              </mat-menu>
            </mat-menu>

            <button class="box-shadow-none btn-custom margin-right-6" *ngIf="canSendToJimbo" (click)="sendSpo()">
              <i class="fa fa-paper-plane margin-right-6" aria-hidden="true"></i> Send
            </button>
          </ng-container>
          <ng-container *ngIf="(hasFulfillmentCreation | async) || (hasShipmentCreation | async)">
            <button
              [disabled]="spo.spoStatus === 1 || spo.spoStatus === 2"
              class="box-shadow-none btn-custom margin-right-6"
              (click)="generateShipment()"
            >
              <i class="fa fa-truck margin-right-6" aria-hidden="true"></i>Generate shipment
            </button>
          </ng-container>
        </div>

        <div class="col-md-2 text-right padding-left-0">
          <button class="refresh-btn margin-right-6" (click)="refresh()"><i class="fa fa-refresh" aria-hidden="true"></i></button>
          <button class="btn-custom" (click)="goBack()">Back</button>
        </div>
      </div>
    </div>
  </mat-toolbar>

  <mat-tab-group #spoItemsMatGroup id="spoItemsMatGroup">
    <mat-tab label="Grouped SPO Items">
      <div class="container table-outer">
        <app-single-spo-grouped-spo-item-table
          [loadSpoItems]="loadSpoItems"
          [exportSubject]="exportSubject"
          [deleteItems]="deleteGroupedSpoItemsSubject"
          [spoId]="spoId"
          (toggleView)="toggleGroupedView()"
          (bulkActionsEmitter)="checkItemsActionsActive($event)"
        ></app-single-spo-grouped-spo-item-table>
      </div>
    </mat-tab>

    <mat-tab label="Spo Items">
      <div class="container table-outer">
        <app-single-spo-item-table
          [loadSpoItems]="loadSpoItems"
          [spoId]="spoId"
          [deleteItems]="deleteSpoItemsSubject"
          (toggleView)="toggleGroupedView()"
          (bulkActionsEmitter)="checkItemsActionsActive($event)"
        ></app-single-spo-item-table>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
