import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ConverterService } from '../../../../core/converter.service';
import { EnvironmentService } from '../../../../environment.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaymentRequestFilters } from '../../../../models/filters/payment-request-filters-model';
import { PaymentRequest } from '../../../../models/payment-request-model';
import { Status, StatusConverter } from '../../../../models/status-model';
import { PaymentAdjustment } from '../../../../models/payment-adjustment';
import { JsonObject, JsonProperty } from 'json2typescript';
import { PaymentDocumentFilters } from '../../../../models/filters/payment-document-filters-model';

@JsonObject('PaymentAdjustment')
class PaymentAdjustmentDto {
  @JsonProperty('type', StatusConverter, true) type: Status = undefined;
  @JsonProperty('value', Number, true) value: number = undefined;
  @JsonProperty('paymentRequestId', Number, true) paymentRequestId: number = undefined;
  @JsonProperty('causal', String, true) causal: string = undefined;

  constructor(paymentAdjustment: PaymentAdjustment) {
    this.type = paymentAdjustment.type;
    this.value = paymentAdjustment.value;
    this.paymentRequestId = paymentAdjustment.paymentRequest.id;
    if (this.type.code === 'ADJUSTMENT') {
      this.causal = paymentAdjustment.causal;
    }
  }
}

@Injectable()
export class PaymentRequestService {
  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) {}

  resultsNumber = new Subject<number>();

  paymentRequestFilters: PaymentRequestFilters;
  selectedRow: PaymentRequest;

  getFiltered(filters: PaymentRequestFilters): Observable<PaymentRequest[]> {
    return this.http
      .post(this.environmentService.getRestEndpoint('paymentRequests') + '/find', this.converter.fromObjtoJSON(filters), {
        observe: 'response',
      })
      .pipe(
        map((resp: HttpResponse<any>) => {
          this.resultsNumber.next(+resp.headers.get('Total-Length'));
          return resp.body;
        }),
        map((resp: PaymentRequest[]) => resp.map((r) => this.converter.fromJSONtoObj(r, PaymentRequest)))
      );
  }

  createPaymentRequest(paymentDocuments: number[]): Observable<any> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http.post(this.environmentService.getRestEndpoint('paymentRequests'), JSON.stringify(paymentDocuments), { headers });
  }

  createPaymentRequestFromFilters(filters: PaymentDocumentFilters): Observable<any> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http.post(this.environmentService.getRestEndpoint('paymentRequests') + '/filters', this.converter.fromObjtoJSON(filters), {
      headers,
    });
  }

  deletePaymentDocument(paymentRequestId: number): Observable<any> {
    return this.http.delete(this.environmentService.getRestEndpoint('paymentRequests') + '/' + paymentRequestId);
  }

  getPaymentRequestStatus(): Observable<Status[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('paymentRequestsStatus'))
      .pipe(map((resp: Status[]) => resp.map((r) => this.converter.fromJSONtoObj(r, Status))));
  }

  getAdjustmentTypes(): Observable<Status[]> {
    return this.http
      .get(this.environmentService.getRestEndpoint('paymentAdjustments') + '/types')
      .pipe(map((resp: Status[]) => resp.map((r) => this.converter.fromJSONtoObj(r, Status))));
  }

  savePaymentAdjustment(paymentAdjustment: PaymentAdjustment): Observable<any> {
    const body = new PaymentAdjustmentDto(paymentAdjustment);
    return this.http.post(this.environmentService.getRestEndpoint('paymentAdjustments'), this.converter.fromObjtoJSON(body));
  }

  deletePaymentAdjustment(paymentAdjustmentId: number): Observable<any> {
    return this.http.delete(this.environmentService.getRestEndpoint('paymentAdjustments') + '/' + paymentAdjustmentId);
  }

  approvePaymentRequest(paymentRequestId: number): Observable<any> {
    return this.http.post(this.environmentService.getRestEndpoint('paymentRequests') + '/approve/' + paymentRequestId, null);
  }

  cancelPaymentRequest(paymentRequestId: number): Observable<any> {
    return this.http.post(this.environmentService.getRestEndpoint('paymentRequests') + '/cancel/' + paymentRequestId, null);
  }

  validatePaymentRequest(paymentRequestId: number): Observable<any> {
    return this.http.post(this.environmentService.getRestEndpoint('paymentRequests') + '/validate/' + paymentRequestId, null);
  }

  generatePaymentDetails(paymentRequestId: number): Observable<any> {
    return this.http.get(this.environmentService.getRestEndpoint('paymentRequests') + '/details/' + paymentRequestId, {
      responseType: 'blob',
      headers: { Accept: 'application/octet-stream' },
    });
  }

  changeStatus(paymentRequests: number[], status: string): Observable<any> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http.post(
      this.environmentService.getRestEndpoint('paymentRequests') + '/change-status/' + status,
      JSON.stringify(paymentRequests),
      { headers }
    );
  }

  changeStatusValidToPending(paymentRequests: number): Observable<any> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http.post(
      this.environmentService.getRestEndpoint('paymentRequests') + '/change-status/valid-to-pending',
      JSON.stringify(paymentRequests),
      { headers }
    );
  }

  changeStatusFromFilters(filters: PaymentRequestFilters, status: string): Observable<any> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http.post(
      this.environmentService.getRestEndpoint('paymentRequests') + '/change-status/filters/' + status,
      this.converter.fromObjtoJSON(filters),
      { headers }
    );
  }

  changeLatestPaymentDate(paymentRequestId: number, newDate: Date): Observable<any> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http.post(
      this.environmentService.getRestEndpoint('paymentRequestsChangeLatestPaymentDate') + '/' + paymentRequestId,
      JSON.stringify(newDate),
      { headers }
    );
  }
}
