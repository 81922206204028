<div class="transit-title margin-top-20 row">
  <div class="col-md-6 padding-left-0">Stock location transit times</div>
  <div class="col-md-6 text-right padding-right-0">
    <mat-form-field>
      <input matInput placeholder="Search Warehouse" #filterInput [(ngModel)]="filterText" />
    </mat-form-field>
  </div>
</div>

<div class="row">
  <div class="col-md-12 box-shadow-none mat-elevation-z8">
    <div class="row transit-time-row" *ngIf="newTransitTime">
      <div class="col-xs-8">
        <mat-form-field class="width-100per">
          <app-mat-select filterKey="description" placeholder="Destination" [(ngModel)]="newTransitTime.destination">
            <ng-container>
              <mat-option [value]="null">None</mat-option>
              <mat-option *appMatSelectFor="let warehouse of availableWarehouses" [value]="warehouse">
                {{ warehouse.description }}
              </mat-option>
            </ng-container>
          </app-mat-select>
        </mat-form-field>
      </div>

      <div class="col-xs-2">
        <mat-form-field class="width-100per">
          <input
            type="number"
            matInput
            min="0"
            max="999"
            maxlength="3"
            placeholder="Transit Days"
            [(ngModel)]="newTransitTime.transitTimeWorkingDays"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          />
        </mat-form-field>
      </div>

      <div class="col-xs-2">
        <button
          [disabled]="!newTransitTime.destination"
          class="btn-transit-time bigger-button"
          matTooltip="Save"
          (click)="saveNewTransitTime()"
        >
          <i class="fa fa-floppy-o padding-bottom-1 margin-right-3" aria-hidden="true"></i>Save
        </button>
      </div>
    </div>

    <div class="row">
      <mat-table [dataSource]="dataSource" class="transit-time-table">
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

        <ng-container matColumnDef="destination">
          <mat-header-cell class="destination-column" *matHeaderCellDef> Destination </mat-header-cell>
          <mat-cell class="destination-column" *matCellDef="let t">
            <p>{{ t.destination.code + ' - ' + t.destination.name }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="country">
          <mat-header-cell class="code-column" *matHeaderCellDef> Country </mat-header-cell>
          <mat-cell class="code-column" *matCellDef="let t">
            <p>{{ t.destination.marketplace }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="transitTimeWorkingDays">
          <mat-header-cell class="quantity-column-title" *matHeaderCellDef> Transit Time </mat-header-cell>
          <mat-cell class="quantity-column" *matCellDef="let t">
            <div *ngIf="!isEditTransitTime || editingDestinationId !== t.destination.id">
              <p>{{ t.transitTimeWorkingDays }}</p>
            </div>
            <mat-form-field *ngIf="isEditTransitTime && editingDestinationId === t.destination.id">
              <input
                matInput
                type="number"
                min="0"
                max="999"
                maxlength="3"
                [(ngModel)]="t.transitTimeWorkingDays"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              />
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="edit">
          <mat-header-cell class="actions-edit-column" *matHeaderCellDef>
            <button class="bigger-button btn-transit-time margin-right-3 margin-bottom-10" matTooltip="Create" (click)="addTransitTime()">
              <i class="fa fa-plus" aria-hidden="true"></i> New
            </button>
          </mat-header-cell>
          <mat-cell *matCellDef="let t" class="actions-edit-column">
            <button
              matTooltip="Edit"
              matTooltipPosition="below"
              *ngIf="!isEditTransitTime || editingDestinationId !== t.destination.id"
              [disabled]="isEditTransitTime && editingDestinationId !== t.destination.id"
              class="btn-transit-time"
              (click)="edit(t)"
            >
              <i class="fa fa-edit padding-bottom-1" aria-hidden="true"></i>
            </button>
            <button
              matTooltip="Save"
              matTooltipPosition="below"
              *ngIf="isEditTransitTime && editingDestinationId === t.destination.id"
              class="btn-transit-time"
              (click)="saveEdit(t)"
            >
              <i class="fa fa-save padding-bottom-1" aria-hidden="true"></i>
            </button>

            <button
              matTooltip="Delete"
              matTooltipPosition="below"
              class="btn-transit-time"
              [disabled]="isEditTransitTime"
              (click)="deleteTransitTime(t)"
            >
              <i class="fa fa-trash padding-bottom-1" aria-hidden="true"></i>
            </button>
          </mat-cell>
        </ng-container>
      </mat-table>
    </div>
  </div>
  <div class="col-xs-1"></div>
</div>
