import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';
import { Supplier } from './supplier-model';

@JsonObject('SupplierDiscount')
export class SupplierDiscount extends BaseObject {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('productEan', String, true) productEan: string = undefined;
  @JsonProperty('supplier', Supplier, false) supplier: Supplier = undefined;
  @JsonProperty('discountedPrice', Number, true) discountedPrice: number = undefined;
  @JsonProperty('validFrom', String, true) validFrom: string = undefined;
  @JsonProperty('validTo', String, true) validTo: string = undefined;
  @JsonProperty('valid', Boolean, true) valid: boolean = undefined;
}
