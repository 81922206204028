import { JsonObject, JsonProperty } from 'json2typescript';
import { isNil, isNilty } from '../../core/utils.service';
import { DateConverter, fromDateToFiltersString } from '../date-converter';
import { MatChipsData } from '../mat-chips-data-model';
import { PaginationAndSorting } from './pagination-and-sorting-model';

@JsonObject('PaymentRequestFilters')
export class PaymentRequestFilters extends PaginationAndSorting {
  @JsonProperty('paymentRequestId', String, true) paymentRequestId: string = undefined;
  @JsonProperty('createdAt', DateConverter, true) createdAt: Date = undefined;
  @JsonProperty('supplier', String, true) supplier: string = undefined;
  @JsonProperty('documentNumbers', [String], true) documentNumbers: string[] = undefined;
  @JsonProperty('shipments', [String], true) shipments: string[] = undefined;
  @JsonProperty('status', String, true) status: string = undefined;
  @JsonProperty('latestPaymentDate', DateConverter, true) latestPaymentDate: Date = undefined;
  @JsonProperty('latestPickupDate', DateConverter, true) latestPickupDate: Date = undefined;
  @JsonProperty('createdBy', String, true) createdBy: string = undefined;
  @JsonProperty('hasAdjustment', Boolean, true) hasAdjustment: boolean = undefined;
}

export const paymentRequestFiltersMap = (
  paymentRequestId: string,
  createdAt: Date,
  supplier: string,
  documentNumbers: string[],
  shipments: string[],
  status: string,
  latestPaymentDate: Date,
  latestPickupDate: Date,
  createdBy: string,
  hasAdjustment: string
): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (!isNilty(paymentRequestId)) {
    map.push(new MatChipsData('Payment Request Id', paymentRequestId));
  }
  if (!isNil(createdAt)) {
    map.push(new MatChipsData('Created At', fromDateToFiltersString(createdAt)));
  }
  if (!isNilty(supplier)) {
    map.push(new MatChipsData('Supplier', supplier));
  }
  if (!isNilty(documentNumbers)) {
    map.push(new MatChipsData('Document Numbers', documentNumbers.join(',')));
  }
  if (!isNilty(shipments)) {
    map.push(new MatChipsData('Shipments', shipments.join(',')));
  }
  if (!isNilty(status)) {
    map.push(new MatChipsData('Status', status));
  }
  if (!isNil(latestPaymentDate)) {
    map.push(new MatChipsData('Latest Payment Date', fromDateToFiltersString(latestPaymentDate)));
  }
  if (!isNil(latestPickupDate)) {
    map.push(new MatChipsData('Latest Pickup Date', fromDateToFiltersString(latestPickupDate)));
  }
  if (!isNilty(createdBy)) {
    map.push(new MatChipsData('Created By', createdBy));
  }
  if (!isNilty(hasAdjustment)) {
    map.push(new MatChipsData('Has Adjustment', hasAdjustment));
  }

  return map;
};
