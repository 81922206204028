import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject } from 'rxjs';
import { AuthorizationService } from '../../../core/authorization.service';
import { LotEditService } from '../../../container/lot-edit-section/lot-edit.service';
import { isNilty } from '../../../core/utils.service';
import { ShipmentFilters } from '../../../models/filters/shipment-filters-model';
import { ShipmentItem } from '../../../models/shipment-item-model';
import { addCheckers } from '../../checkers.service';
import { ImportExportService } from '../../import-export.service';
import { ShipmentsService } from '../../shipments.service';
import { Permissions } from '../../../models/permission-model';
import { MatDialog } from '@angular/material/dialog';
// eslint-disable-next-line max-len
import { PoaItemHistoryModalComponent } from '../../single-po-details/poa-history-modal/poa-item-history-modal/poa-item-history-modal.component';

@Component({
  selector: 'app-single-shipment-items-table',
  templateUrl: './single-shipment-items-table.component.html',
  styleUrls: ['./single-shipment-items-table.component.css'],
})
export class SingleShipmentItemsTableComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @Output() showCartonsEvent = new EventEmitter<void>();

  @Output() isEditingShippedQuantitiesChanged = new EventEmitter<boolean>();

  @Output() refresh = new EventEmitter<void>();

  @Input() shipmentId: number;

  @Input() set exportSubject(sub: Subject<string>) {
    sub.subscribe((format: string) => {
      this.export(format);
    });
  }

  @Input() set loadShipmentItems(it: Subject<ShipmentFilters>) {
    it.subscribe((filters: ShipmentFilters) => {
      this.applyFilter(filters);
    });
  }

  @Input() set splitShipmentInput(sub: Subject<ShipmentFilters>) {
    sub.subscribe((filters: ShipmentFilters) => {
      this.splitShipment(filters);
    });
  }

  bulkActionsActive = false;

  shipmentItems: ShipmentItem[] = [];
  filters: ShipmentFilters;

  dataShipmentItems = [];

  dataSource: MatTableDataSource<ShipmentItem> = new MatTableDataSource<ShipmentItem>([]);

  mainChecker = false;

  displayedColumns = [
    'check',
    'title',
    'ean',
    'actualEan',
    'poId',
    'vendor',
    'brand',
    'submittedQuantity',
    'requestedQuantity',
    'confirmedQuantity',
    'shippedQuantity',
    'submittedValue',
    'requestedValue',
    'confirmedValue',
    'shippedValue',
    'purchasePriceUnit',
    'confirmedPurchasePrice',
    'virtualConfirmedPurchasePrice',
    'lot',
  ];

  isEditingShippedQuantities = false;
  editingShippedId: number;
  oldShippedQuantity: number;

  resultsNumber: Observable<number>;

  constructor(
    private shipmentsService: ShipmentsService,
    private lotEditService: LotEditService,
    private snackBar: MatSnackBar,
    private importExportService: ImportExportService,
    private authorizationService: AuthorizationService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.resultsNumber = this.shipmentsService.resultsNumber;

    this.authorizationService.hasPermission(Permissions.ManualShippedUpdate).subscribe((hasManualShippedUpdate: boolean) => {
      if (hasManualShippedUpdate) {
        this.displayedColumns.push('edit');
      }
    });
  }

  loadRows() {
    this.shipmentsService.getSingleFilteredShipmentItems(this.filters, this.shipmentId).subscribe((shipmentItemsResp: ShipmentItem[]) => {
      this.shipmentItems = shipmentItemsResp;
      this.dataShipmentItems = addCheckers(shipmentItemsResp, this.lotEditService.selectedShipmentItems, this.mainChecker);
      this.dataSource.data = this.dataShipmentItems;
    });
  }

  loadPage() {
    this.filters.pageNumber = this.paginator.pageIndex;
    this.filters.pageSize = this.paginator.pageSize;
    this.loadRows();
  }

  onSort() {
    this.filters.sortBy = this.sort.active;
    this.filters.sortDirection = this.sort.direction;
    this.loadRows();
  }

  applyFilter(filters: ShipmentFilters) {
    this.filters = filters;
    this.paginator.pageIndex = 0;
    this.resetCheckers();
    this.loadRows();
  }

  onSelected(value: boolean, selectedShipmentItem: any) {
    const selectedIndex = this.dataShipmentItems.indexOf(selectedShipmentItem);
    this.dataShipmentItems[selectedIndex].checked = value;
    if (value !== this.mainChecker) {
      this.lotEditService.addShipmentItemIdToSelected(this.dataShipmentItems[selectedIndex].id);
    } else {
      this.lotEditService.removeShipmentItemIdFromSelected(this.dataShipmentItems[selectedIndex].id);
    }
    this.bulkActionsActive = value || this.mainChecker;
  }

  toggleAll(value: boolean) {
    this.mainChecker = value;
    for (const i of this.dataShipmentItems) {
      i.checked = value;
    }
    this.lotEditService.selectedShipmentItems = [];
    this.bulkActionsActive = value;
  }

  resetCheckers() {
    this.lotEditService.selectedShipmentItems = [];
    this.mainChecker = false;
    this.bulkActionsActive = false;
  }

  editShippedQuantities(s: ShipmentItem) {
    this.isEditingShippedQuantities = !this.isEditingShippedQuantities;
    this.changeIsEditShippingQuantities();
    this.editingShippedId = this.isEditingShippedQuantities ? s.id : undefined;
    this.oldShippedQuantity = isNilty(s.shippedQuantity) ? 0 : s.shippedQuantity;
  }

  keydownEvent(event: KeyboardEvent, si: ShipmentItem) {
    const excludedKeys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete'];

    if (event.key === 'Enter') {
      this.saveShippedQuantities(si);
    } else if (excludedKeys.indexOf(event.key) === -1) {
      const regexp = new RegExp('[0-9]');
      if (!regexp.test(event.key)) {
        event.preventDefault();
      }
    }
  }

  saveShippedQuantities(s: ShipmentItem) {
    if (s.shippedQuantity !== this.oldShippedQuantity) {
      this.shipmentsService.updateShippedQuantities(s).subscribe((resp: ShipmentItem) => {
        if (isNilty(resp)) {
          this.snackBar.open('Error during update.', '')._dismissAfter(2000);
          this.discardChanges(s);
        } else {
          this.updateTableRow(resp);
          this.snackBar.open('Shipped quantity updated.', '')._dismissAfter(2000);
        }
      });
    } else {
      this.snackBar.open('No changes.', '')._dismissAfter(2000);
      this.discardChanges(s);
    }
    this.isEditingShippedQuantities = false;
    this.changeIsEditShippingQuantities();
    this.editingShippedId = undefined;
    this.oldShippedQuantity = undefined;
  }

  discardChanges(s: ShipmentItem) {
    s.shippedQuantity = this.oldShippedQuantity;
    this.isEditingShippedQuantities = false;
    this.changeIsEditShippingQuantities();
    this.editingShippedId = undefined;
    this.oldShippedQuantity = undefined;
  }

  updateTableRow(s: ShipmentItem) {
    const updatedIndex = this.shipmentItems.findIndex((it) => it.id === s.id);
    if (!isNilty(updatedIndex) && updatedIndex !== -1) {
      this.shipmentItems[updatedIndex] = s;
      this.dataShipmentItems = addCheckers(this.shipmentItems, this.lotEditService.selectedShipmentItems, this.mainChecker);
      this.dataSource.data = this.dataShipmentItems;
    }
  }

  changeIsEditShippingQuantities() {
    this.isEditingShippedQuantitiesChanged.next(this.isEditingShippedQuantities);
  }

  export(format: string) {
    this.importExportService
      .exportShipmentItems(
        this.shipmentsService.selectedShipmentItems,
        format,
        this.bulkActionsActive ? this.mainChecker : true,
        this.filters
      )
      .subscribe(() => {});
  }

  refreshSplitShipment() {
    this.refresh.emit();
  }

  splitShipment(filters: ShipmentFilters) {
    this.shipmentsService
      .splitShipment(this.lotEditService.selectedShipmentItems, this.shipmentId, this.mainChecker, filters)
      .subscribe(() => {
        this.resetCheckers();
        this.refreshSplitShipment();
      });
  }

  showPoItemHistory(shipmentItem: ShipmentItem) {
    this.dialog.open(PoaItemHistoryModalComponent, {
      disableClose: true,
      data: { poi: shipmentItem.purchaseOrderItem, po: shipmentItem.purchaseOrderItem.purchaseOrder },
    });
  }
}
