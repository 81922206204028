import { PaginationAndSorting } from './pagination-and-sorting-model';
import { Vendor } from '../vendor-model';
import { MatChipsData } from '../mat-chips-data-model';
import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter, fromDateToFiltersString } from '../date-converter';

@JsonObject('LotFilters')
export class LotFilters extends PaginationAndSorting {
  @JsonProperty('dateFrom', DateConverter, true) dateFrom: Date = undefined;
  @JsonProperty('dateTo', DateConverter, true) dateTo: Date = undefined;
  @JsonProperty('po', String, true) po: string = undefined;
  @JsonProperty('vendor', Number, true) vendor: number = undefined;
  @JsonProperty('status', String, true) status: string = undefined;
}

export const lotFiltersMap = (dateFrom?: Date, dateTo?: Date, po?: string, vendor?: Vendor, status?: string): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (dateFrom !== null && dateFrom !== undefined) {
    map.push(new MatChipsData('Date From', fromDateToFiltersString(dateFrom)));
  }
  if (dateTo !== null && dateTo !== undefined) {
    map.push(new MatChipsData('Date To', fromDateToFiltersString(dateTo)));
  }
  if (po !== null && po !== undefined) {
    map.push(new MatChipsData('PO', po));
  }
  if (vendor !== null && vendor !== undefined) {
    map.push(new MatChipsData('Vendor', vendor.code));
  }
  if (status !== null && status !== undefined) {
    map.push(new MatChipsData('Status', status));
  }
  return map;
};
