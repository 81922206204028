import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ChangeCarrierPropertiesInput, Shipment } from '../../../models/shipment-model';
import { FormGroup } from '@angular/forms';
import { ShipmentsService } from '../../shipments.service';
import { EditPackagingModalComponent } from '../edit-packaging-modal/edit-packaging-modal.component';
import { EditCarrierModalComponent } from '../edit-carrier-modal/edit-carrier-modal.component';
import { isNilty } from '../../../core/utils.service';
import { AuthorizationService } from '../../../core/authorization.service';
import { Permissions } from '../../../models/permission-model';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-single-shipment-accordion',
  templateUrl: './single-shipment-accordion.component.html',
  styleUrls: ['./single-shipment-accordion.component.css'],
})
export class SingleShipmentAccordionComponent implements OnInit {
  @Input() shipment: Shipment;
  @Output() reload = new EventEmitter<void>();

  hasShipmentManagement: Observable<boolean>;

  constructor(private shipmentsService: ShipmentsService, private dialog: MatDialog, private authorizationService: AuthorizationService) {}

  ngOnInit() {
    this.hasShipmentManagement = this.authorizationService.hasPermission(Permissions.ShipmentManagement);
  }

  editPackaging() {
    const dialogRef = this.dialog.open(EditPackagingModalComponent, {
      width: '350px',
      data: {
        pallets: this.shipment.numberOfPallets,
        cartons: this.shipment.numberOfCartons,
        weight: this.shipment.weight,
        ddt: this.shipment.ddtNumber,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result: FormGroup) => {
      if (result !== undefined) {
        const pallets = result.value.pallets;
        const cartons = result.value.cartons;
        const weight = result.value.weight;
        const ddt = result.value.ddt;
        this.shipmentsService.changePackingProperties(this.shipment.id, pallets, cartons, weight, ddt).subscribe(() => {
          this.reload.emit();
        });
      }
    });
  }

  editCarrier() {
    const dialogRef = this.dialog.open(EditCarrierModalComponent, {
      width: '350px',
      data: {
        hasTransit: !!this.shipment.transitWarehouse,
        shipmentId: this.shipment.id,
        formData: {
          contract: this.shipment.contract,
          trackingNumber: this.shipment.trackingNumber,
          expectedDeliveryDate: this.shipment.expectedDeliveryDate,
          deliveryDate: this.shipment.deliveryDate,
          incomingDate: this.shipment.incomingDate,
          preparationDays: this.shipment.preparationDays,
        },
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result: ChangeCarrierPropertiesInput) => {
      if (!isNilty(result)) {
        this.shipmentsService.changeCarrierProperties(this.shipment.id, result).subscribe(() => {
          this.reload.emit();
        });
      }
    });
  }
}
