import { Observable, Subject, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserPermissions } from '../models/user-permissions-model';
import { ConverterService } from './converter.service';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../environment.service';

@Injectable()
export class AuthorizationService {
  constructor(private http: HttpClient, private converter: ConverterService, private environmentService: EnvironmentService) { }

  isLotEditMode = new Subject<boolean>();
  currentUserMail = '';

  private permissionsSubject = new Subject<string[]>();

  private gettingPermissions = false;

  permissions: string[];

  getPermissions(): Observable<string[]> {
    if (this.permissions) {
      return of(this.permissions);
    }

    if (!this.gettingPermissions) {
      this.gettingPermissions = true;
      return this.http.get(this.environmentService.getPonchRestEndpoint('userPermissions') + '?application=' +
        this.environmentService.application, {}).pipe(
          map((resp: UserPermissions) => {
            const currRole: UserPermissions = this.converter.fromJSONtoObj(resp, UserPermissions);
            this.currentUserMail = currRole.email;
            this.permissions = currRole.permissions;
            this.permissionsSubject.next(this.permissions);
            this.gettingPermissions = false;
            return currRole.permissions;
          })
        );
    } else {
      return this.permissionsSubject;
    }
  }

  hasPermission(permission: string): Observable<boolean> {
    return this.getPermissions().pipe(
      map((p) => {
        if (p.includes(permission)) {
          return true;
        }
      })
    );
  }

  hasPermissions(permissions: string[]): Observable<boolean> {
    return this.getPermissions().pipe(map((p) => p.some((permission) => permissions.includes(permission))));
  }
}
