import { Component, OnDestroy, OnInit } from '@angular/core';

import { InventoryService } from '../inventory.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-stock-locations',
  templateUrl: './stock-locations.component.html',
  styleUrls: ['./stock-locations.component.css'],
})
export class StockLocationsComponent implements OnInit, OnDestroy {
  constructor(private inventoryService: InventoryService) {}

  ngOnInit() {}

  ngOnDestroy() {
    if (environment.envName !== 'mock') {
      this.inventoryService.stockLocations = [];
    }
  }
}
