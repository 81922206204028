import { AfterViewInit, Component, OnInit, Output } from '@angular/core';
import { Vendor, VendorsFromLotOutput } from '../../../models/vendor-model';
import { VendorsService } from '../../../container/configuration/vendors/vendors.service';
import { LotsService } from '../../lots.service';
import { Subject } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { shipFiltersMap, ShipmentFilters } from '../../../models/filters/shipment-filters-model';
import { MatChipsData } from '../../../models/mat-chips-data-model';
import { isNilty } from '../../../core/utils.service';

@Component({
  selector: 'app-single-shipment-detail-filters',
  templateUrl: './single-shipment-detail-filters.component.html',
  styleUrls: ['./single-shipment-detail-filters.component.css'],
})
export class SingleShipmentDetailFiltersComponent implements OnInit, AfterViewInit {
  @Output() onFilter = new Subject<ShipmentFilters>();

  vendors: Vendor[] = [];
  filterForm: FormGroup;

  public openFilter = false;
  filtersMap: MatChipsData[] = [];

  constructor(private vendorsService: VendorsService, private lotsService: LotsService) {}

  ngOnInit() {
    this.filterForm = new FormGroup({
      vendor: new FormControl(),
      po: new FormControl(),
      ean: new FormControl(),
    });

    if (this.lotsService.currentLotId === undefined) {
      if (this.vendorsService.vendors === undefined) {
        this.vendorsService.getVendors().subscribe((resp: Vendor[]) => {
          this.vendors = resp;
        });
      } else {
        this.vendors = this.vendorsService.vendors;
      }
    } else {
      if (this.vendorsService.lotEditVendors === undefined) {
        this.vendorsService.getVendorsFromLot(this.lotsService.currentLotId).subscribe((vendorsResp: VendorsFromLotOutput) => {
          this.vendors = vendorsResp.vendors;
        });
      } else {
        this.vendors = this.vendorsService.lotEditVendors;
      }
    }
  }

  ngAfterViewInit() {
    this.updateFiltersMap();
  }

  onSubmitFilter() {
    this.updateFiltersMap();
    const filters = new ShipmentFilters();
    filters.ean = !isNilty(this.filterForm.value.ean) ? this.filterForm.value.ean : undefined;
    filters.po = !isNilty(this.filterForm.value.po) ? this.filterForm.value.po.split('\n') : undefined;
    filters.vendorId = this.filterForm.value.vendor ? this.filterForm.value.vendor.id : undefined;
    this.onFilter.next(filters);
  }

  resetFilters() {
    this.filterForm.reset();
    this.updateFiltersMap();
    this.onFilter.next(new ShipmentFilters());
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeevent() {
    this.openFilter = false;
  }

  updateFiltersMap() {
    this.filtersMap = shipFiltersMap(
      this.filterForm.value.shipment,
      null,
      this.filterForm.value.ean === '' ? null : this.filterForm.value.ean,
      this.filterForm.value.origin,
      this.filterForm.value.supplier,
      this.filterForm.value.po ? this.filterForm.value.po.split('\n') : null,
      this.filterForm.value.vendor,
      this.filterForm.value.destination,
      this.filterForm.value.status,
      null,
      null,
      null,
      null
    );
  }
}
