<div class="row">
  <ng-container *ngIf="!dataSource || dataSource?.data.length === 0">
    <div class="col-md-12 small-table-title text-center">
      <h4>There are no adjustments</h4>
    </div>
  </ng-container>

  <ng-container *ngIf="dataSource?.data.length > 0">
    <div class="col-md-12 small-table-title border-bottom-title">
      <h4>Adjustments</h4>
    </div>

    <div class="col-md-12 fixed-table-5-rows padding-right-0 padding-left-0">
      <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-40">
        <mat-table [dataSource]="dataSource">

          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef class="code-column">Type</mat-header-cell>
            <mat-cell *matCellDef="let a" class="code-column">
              <p>{{ a.type.description }}</p>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="value">
            <mat-header-cell class="value-column-title" *matHeaderCellDef>Value</mat-header-cell>
            <mat-cell class="value-column" *matCellDef="let a">
              <p>{{ a.value | myCurrency }}</p>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="causal">
            <mat-header-cell *matHeaderCellDef>Causal</mat-header-cell>
            <mat-cell *matCellDef="let a">
              <p>{{ a.causal }}</p>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions" stickyEnd>
            <mat-header-cell class="icon-column center" *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell class="icon-column" *matCellDef="let a">
              <button class="box-shadow-none actions-button btn-fixed" matTooltip="Delete adjustment"
                (click)="delete(a.id)" [disabled]="!isEditable">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
    </div>
  </ng-container>
</div>
