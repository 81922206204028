import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { MainRemittancesComponent } from './main-remittances.component';
import { MainRemittancesListComponent } from './main-remittances-list/main-remittances-list.component';
import { MainRemittancesListDetailsComponent } from './main-remittances-details/main-remittances-list-details.component';

const configurationRoutes: Routes = [
  {
    path: '',
    component: MainRemittancesComponent,
    children: [
      { path: '', component: MainRemittancesListComponent, pathMatch: 'full' },
      { path: 'details', component: MainRemittancesListDetailsComponent },
      // {path: ':id', component: SingleRemittanceDetailComponent}
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(configurationRoutes)],
  exports: [RouterModule],
  providers: [],
})
export class MainRemittancesRoutingModule {}
