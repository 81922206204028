<app-main-shipments-list-filters (onFilter)="applyFilter($event)"></app-main-shipments-list-filters>

<div class="bulk-actions-div">
  <mat-toolbar class="bar-custom">
    <div class="container">
      <div class="row">
        <div class="col-md-2">
          <button (click)="showDetails()" class="box-shadow-none btn-custom">Show details</button>
        </div>

        <div class="col-md-10">
          <button
            [disabled]="!bulkActionsActive"
            [matMenuTriggerFor]="exportShipmentsChoice"
            class="box-shadow-none btn-custom margin-right-6"
          >
            <i class="fa fa-external-link margin-right-6" aria-hidden="true"></i>Export
          </button>

          <mat-menu #exportShipmentsChoice="matMenu">
            <button mat-menu-item [disabled]="!bulkActionsActive" [matMenuTriggerFor]="exportShipmentHeaderChoice">Export headers</button>
            <mat-menu #exportShipmentHeaderChoice="matMenu">
              <button mat-menu-item (click)="export('excel', true)">Save as Excel</button>
              <button mat-menu-item (click)="export('csv', true)">Save as CSV</button>
            </mat-menu>

            <button mat-menu-item [disabled]="!bulkActionsActive" [matMenuTriggerFor]="exportShipmentItemsChoice">Export items</button>
            <mat-menu #exportShipmentItemsChoice="matMenu">
              <button mat-menu-item (click)="export('excel', false)">Save as Excel</button>
              <button mat-menu-item (click)="export('csv', false)">Save as CSV</button>
            </mat-menu>
          </mat-menu>

          <button
            *ngIf="hasShipmentManagement | async"
            [disabled]="!bulkActionsActive"
            (click)="merge()"
            class="box-shadow-none btn-custom margin-right-6"
          >
            <i class="fa fa-compress" aria-hidden="true"></i> Merge
          </button>

          <button
            *ngIf="hasChangeShipmentPaymentStatus | async"
            [disabled]="!bulkActionsActive"
            [matMenuTriggerFor]="changePaymentStatusMenu"
            class="box-shadow-none btn-custom payment-status-button margin-right-6"
          >
            Change Payment Status <i class="fa fa-angle-down sel-icon" aria-hidden="true"></i>
          </button>
          <mat-menu #changePaymentStatusMenu="matMenu">
            <button mat-menu-item *ngFor="let s of paymentStatus" (click)="changePaymentStatus(s.code)">{{ s.description }}</button>
          </mat-menu>

          <button
            *ngIf="hasShipmentManagement | async"
            [disabled]="!bulkActionsActive"
            (click)="changeIncomingDate()"
            class="box-shadow-none btn-custom margin-right-6"
          >
            Change Incoming Date
          </button>
        </div>
      </div>
    </div>
  </mat-toolbar>
</div>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <hr class="divisor-title" style="width: calc(100% - 17rem)" />
      <div class="square" style="right: 17rem"></div>
      <h2 class="po-element-custom title-custom">Shipments</h2>
    </div>
  </div>
</div>

<div class="container table-outer">
  <mat-paginator #paginator [length]="resultsNumber | async" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()">
  </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="check" sticky>
        <mat-header-cell *matHeaderCellDef class="checker-col">
          <mat-checkbox (change)="toggleAll($event.checked)" [ngModel]="mainChecker"></mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let spo; let i = index" class="checker-col">
          <mat-checkbox (change)="onSelected($event.checked, spo)" [ngModel]="spo.checked"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="week">
        <mat-header-cell class="week-column" *matHeaderCellDef mat-sort-header="week">Week</mat-header-cell>
        <mat-cell class="week-column" *matCellDef="let ship">
          <p>{{ ship.week }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lot">
        <mat-header-cell class="lot-select-column" *matHeaderCellDef> Lot </mat-header-cell>
        <mat-cell class="lot-select-column" *matCellDef="let ship">
          <ng-container *ngIf="ship?.lots && ship?.lots.length !== 0">
            <mat-select [value]="(ship?.lots)[0].id">
              <mat-option *ngFor="let lot of ship?.lots" [value]="lot.id">{{ lot.name }}</mat-option>
            </mat-select>
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="shipmentCode" sticky>
        <mat-header-cell class="shipment-code-column" *matHeaderCellDef mat-sort-header="shipmentCode"> Code </mat-header-cell>
        <mat-cell
          class="shipment-code-column"
          *matCellDef="let ship"
          [matTooltip]="ship ? ship.shipmentCode : ''"
          matTooltipPosition="below"
        >
          <a [routerLink]="['/shipments', ship.id]">{{ ship.shipmentCode }}</a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="orderId" sticky>
        <mat-header-cell class="shipment-order-id-column" *matHeaderCellDef mat-sort-header="orderId"> Order ID </mat-header-cell>
        <mat-cell
          class="shipment-order-id-column"
          *matCellDef="let ship"
          [matTooltip]="ship ? ship.orderId : ''"
          matTooltipPosition="below"
        >
          {{ ship.orderId }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="statusCheck" sticky>
        <mat-header-cell class="shipment-status-check-column" *matHeaderCellDef> Status Check </mat-header-cell>
        <mat-cell class="shipment-status-check-column" *matCellDef="let ship">
          <span class="fa fa-circle online-icon" [ngClass]="'status-check-color-' + ship.statusCheck.toLowerCase()"></span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="origin" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="origin" class="supplier-column"> Supplier </mat-header-cell>
        <mat-cell class="supplier-column" *matCellDef="let ship">
          <p>{{ ship.origin?.supplier?.name }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="extra">
        <mat-header-cell class="extra-flag-column" *matHeaderCellDef> Extra </mat-header-cell>
        <mat-cell class="extra-flag-column" *matCellDef="let ship">
          <i [ngClass]="ship.isExtra | booleanIconPipe" aria-hidden="true"></i>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="crossUkBorder">
        <mat-header-cell class="extra-flag-column" *matHeaderCellDef> Cross UK </mat-header-cell>
        <mat-cell class="extra-flag-column" *matCellDef="let ship">
          <i [ngClass]="ship.crossUkBorder | booleanIconPipe" aria-hidden="true"></i>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell class="shipment-status-column" *matHeaderCellDef mat-sort-header="status"> Status </mat-header-cell>
        <mat-cell class="shipment-status-column" *matCellDef="let ship">{{ ship.status?.name }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="paymentStatus">
        <mat-header-cell class="shipment-status-column" *matHeaderCellDef mat-sort-header="paymentStatus"> Payment status </mat-header-cell>
        <mat-cell class="shipment-status-column" *matCellDef="let ship">{{ ship.paymentStatus?.description }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="backorder">
        <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="backorder"> Backorder </mat-header-cell>
        <mat-cell class="boolean-column" *matCellDef="let ship">
          <p><i [ngClass]="ship.backorder | booleanIconPipe" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="category">
        <mat-header-cell *matHeaderCellDef mat-sort-header="category" class="category-column"> Category </mat-header-cell>
        <mat-cell class="category-column" *matCellDef="let ship" matTooltip="{{ ship.category?.name }}">
          <p>{{ ship.category?.name }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="totalAcceptedQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="totalAcceptedQuantity">
          Accepted <br />
          quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let ship">
          <p>{{ ship.totalAcceptedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="totalAcceptedValue">
        <mat-header-cell *matHeaderCellDef mat-sort-header="totalAcceptedValue" class="value-column-title">
          Accepted <br />
          value
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let ship">
          <p>{{ ship.totalAcceptedValue | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="totalAcceptedPurchasePrice">
        <mat-header-cell *matHeaderCellDef mat-sort-header="totalAcceptedPurchasePrice" class="value-column-title">
          Accepted <br />
          price
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let ship">
          <p>{{ ship.totalAcceptedPurchasePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="totalVirtualAcceptedPurchasePrice">
        <mat-header-cell *matHeaderCellDef mat-sort-header="totalVirtualAcceptedPurchasePrice" class="value-column-title">
          Virtual Accepted <br />
          price
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let ship">
          <p>{{ ship.totalVirtualAcceptedPurchasePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="totalShippedQuantity">
        <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="totalShippedQuantity">
          Shipped <br />
          quantity
        </mat-header-cell>
        <mat-cell class="quantity-column" *matCellDef="let ship">
          <p>{{ ship.totalShippedQuantity }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="totalShippedValue">
        <mat-header-cell *matHeaderCellDef mat-sort-header="totalShippedValue" class="value-column-title">
          Shipped <br />
          value
        </mat-header-cell>
        <mat-cell class="value-column" *matCellDef="let ship">
          <p>{{ ship.totalShippedValue | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="totalPurchasePrice">
        <mat-header-cell *matHeaderCellDef mat-sort-header="totalPurchasePrice" class="value-column-title">
          Shipped <br />
          price
        </mat-header-cell>
        <mat-cell *matCellDef="let ship" class="value-column">
          <p>{{ ship.totalPurchasePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="totalVirtualPurchasePrice">
        <mat-header-cell *matHeaderCellDef mat-sort-header="totalVirtualPurchasePrice" class="value-column-title">
          Virtual Shipped <br />
          price
        </mat-header-cell>
        <mat-cell *matCellDef="let ship" class="value-column">
          <p>{{ ship.totalVirtualPurchasePrice | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="margin">
        <mat-header-cell *matHeaderCellDef mat-sort-header="margin" class="value-column-title"> Margin </mat-header-cell>
        <mat-cell *matCellDef="let ship" class="value-column">
          <p>{{ ship.margin | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="percentMargin">
        <mat-header-cell *matHeaderCellDef mat-sort-header="percentMargin" class="value-column-title"> Percent margin </mat-header-cell>
        <mat-cell *matCellDef="let ship" class="value-column">
          <p>{{ ship.percentMargin | percentageFromDecimal }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="virtualMargin">
        <mat-header-cell *matHeaderCellDef mat-sort-header="virtualMargin" class="value-column-title"> Virtual Margin </mat-header-cell>
        <mat-cell *matCellDef="let ship" class="value-column">
          <p>{{ ship.virtualMargin | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="virtualPercentMargin">
        <mat-header-cell *matHeaderCellDef mat-sort-header="virtualPercentMargin" class="value-column-title">
          Virtual Percent margin
        </mat-header-cell>
        <mat-cell *matCellDef="let ship" class="value-column">
          <p>{{ ship.virtualPercentMargin | percentageFromDecimal }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="vendor">
        <mat-header-cell *matHeaderCellDef mat-sort-header="vendor" class="vendor-column"> Vendor </mat-header-cell>
        <mat-cell *matCellDef="let ship" class="vendor-column">
          <p>{{ ship.vendor?.code }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="destination">
        <mat-header-cell class="destination-code-column" *matHeaderCellDef mat-sort-header="destination"> Destination </mat-header-cell>
        <mat-cell class="destination-code-column" *matCellDef="let ship">
          <p>{{ ship.destination?.code }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="latestDeliveryDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="latestDeliveryDate" class="date-column"> Latest delivery date </mat-header-cell>
        <mat-cell *matCellDef="let ship" class="date-column">{{ ship.latestDeliveryDate }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="latestPickupDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="latestPickupDate" class="date-column"> Latest pickup date </mat-header-cell>
        <mat-cell *matCellDef="let ship" class="date-column">{{ ship.latestPickupDate }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="requestForPayment">
        <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="requestForPayment">
          Request for payment
        </mat-header-cell>
        <mat-cell class="boolean-column" *matCellDef="let ship">
          <p><i [ngClass]="ship.requestForPayment | booleanIconPipe" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="requestForPaymentDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="requestForPaymentDate" class="date-column">
          Request payment date
        </mat-header-cell>
        <mat-cell *matCellDef="let ship" class="date-column">{{ ship.requestForPaymentDate }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="payment">
        <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="payment"> Payment </mat-header-cell>
        <mat-cell class="boolean-column" *matCellDef="let ship">
          <p><i [ngClass]="ship.payment | booleanIconPipe" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="paymentDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="paymentDate" class="date-column"> Payment date </mat-header-cell>
        <mat-cell *matCellDef="let ship" class="date-column">{{ ship.paymentDate }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="numberOfCartons">
        <mat-header-cell *matHeaderCellDef mat-sort-header="numberOfCartons" class="quantity-column-title"> Boxes </mat-header-cell>
        <mat-cell *matCellDef="let ship" class="quantity-column">
          <p>{{ ship.numberOfCartons }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="numberOfPallets">
        <mat-header-cell *matHeaderCellDef mat-sort-header="numberOfPallets" class="quantity-column-title"> Pallets </mat-header-cell>
        <mat-cell *matCellDef="let ship" class="quantity-column">
          <p>{{ ship.numberOfPallets }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="weight">
        <mat-header-cell *matHeaderCellDef mat-sort-header="weight" class="quantity-column-title"> Weight </mat-header-cell>
        <mat-cell *matCellDef="let ship" class="quantity-column">
          <p>{{ ship.weight }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="shipmentDate">
        <mat-header-cell class="date-column" *matHeaderCellDef mat-sort-header="shipmentDate"> Shipped at </mat-header-cell>
        <mat-cell class="date-column" *matCellDef="let ship">{{ ship.shipmentDate }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="asnCode">
        <mat-header-cell class="asn-column" *matHeaderCellDef mat-sort-header="asnCode"> ASN Code </mat-header-cell>
        <mat-cell class="asn-column" *matCellDef="let ship" [matTooltip]="ship ? ship.asnCode : ''" matTooltipPosition="below">
          <p>{{ ship.asnCode ? ship.asnCode : '' }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="sentAsn">
        <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="sentAsn"> ASN sent </mat-header-cell>
        <mat-cell class="boolean-column" *matCellDef="let ship">
          <p><i [ngClass]="ship.sentAsn | booleanIconPipe" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="invoiceNumber">
        <mat-header-cell class="ddt-column" *matHeaderCellDef mat-sort-header="invoiceNumber"> Invoice Number </mat-header-cell>
        <mat-cell
          class="ddt-column"
          *matCellDef="let ship"
          [matTooltip]="ship?.invoice ? ship.invoice?.invoiceNumber : ''"
          matTooltipPosition="below"
        >
          <p>{{ ship?.invoice ? ship.invoice?.invoiceNumber : '' }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="carrierContract">
        <mat-header-cell class="code-column" *matHeaderCellDef mat-sort-header="carrierName"> Carrier </mat-header-cell>
        <mat-cell class="code-column" [matTooltip]="ship?.contract?.name" *matCellDef="let ship">
          <p>{{ ship?.contract?.name }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="trackingNumber">
        <mat-header-cell class="tracking-column" *matHeaderCellDef mat-sort-header="trackingNumber"> Tracking number </mat-header-cell>
        <mat-cell class="tracking-column" [matTooltip]="ship.trackingNumber" *matCellDef="let ship">
          <p>{{ ship.trackingNumber }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="expectedDeliveryDate">
        <mat-header-cell class="date-column" *matHeaderCellDef mat-sort-header="expectedDeliveryDate">
          Expected delivery date
        </mat-header-cell>
        <mat-cell class="date-column" *matCellDef="let ship">{{ ship.expectedDeliveryDate }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="earliestDeliveryDate">
        <mat-header-cell class="date-column" *matHeaderCellDef mat-sort-header="earliestDeliveryDate">
          Earliest delivery date
        </mat-header-cell>
        <mat-cell class="date-column" *matCellDef="let ship">{{ ship.earliestDeliveryDate }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="deliveryDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="deliveryDate" class="date-column"> Delivery date </mat-header-cell>
        <mat-cell *matCellDef="let ship" class="date-column">{{ ship.deliveryDate }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="ddtNumber">
        <mat-header-cell class="ddt-column" *matHeaderCellDef mat-sort-header="ddtNumber"> DDT Number </mat-header-cell>
        <mat-cell class="ddt-column" *matCellDef="let ship" [matTooltip]="ship ? ship.ddtNumber : ''" matTooltipPosition="below">
          <p>{{ ship.ddtNumber ? ship.ddtNumber : '' }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="hasTransit">
        <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="hasTransit"> Has Transit </mat-header-cell>
        <mat-cell class="boolean-column" *matCellDef="let ship">
          <p><i [ngClass]="!ship.origin?.supplier.shipsToAmazon | booleanIconPipe" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ukIntercoInvoice">
        <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="ukIntercoInvoice">
          Requires Uk Interco Invoice
        </mat-header-cell>
        <mat-cell class="boolean-column" *matCellDef="let ship">
          <p><i [ngClass]="ship.ukIntercoInvoice | booleanIconPipe" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ukIntercoInvoiceValidData">
        <mat-header-cell class="boolean-column" *matHeaderCellDef mat-sort-header="ukIntercoInvoiceValidData">
          Valid UK Interco Data
        </mat-header-cell>
        <mat-cell class="boolean-column" *matCellDef="let ship">
          <p><i [ngClass]="ship.ukIntercoInvoiceValidData | booleanIconPipe" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ukIntercoInvoiceStatus">
        <mat-header-cell class="shipment-status-column" *matHeaderCellDef mat-sort-header="ukIntercoInvoiceStatus">
          UK Interco Status
        </mat-header-cell>
        <mat-cell class="shipment-status-column" *matCellDef="let ship">
          <p>{{ ship.ukIntercoInvoiceStatus?.description }}</p>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
