import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { FulfilmentKpi } from '../../../models/fulfilment-kpi';
import { Spo } from '../../../models/spo-model';

@Component({
  selector: 'app-single-spo-kpi',
  templateUrl: './single-spo-kpi.component.html',
  styleUrls: ['./single-spo-kpi.component.css'],
})
export class SingleSpoKpiComponent {
  @Input() kpiData: Observable<FulfilmentKpi>;
  @Input() spo: Spo;
}
