import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { ImportExportService } from '../../../../shared/import-export.service';
import { LotsService } from '../../../../shared/lots.service';
import { ENTITIES } from '../../../../entities';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-suppliers-availabilities-bulk-actions',
  templateUrl: './supplier-availabilities-bulk-actions.component.html',
  styleUrls: ['./supplier-availabilities-bulk-actions.component.css'],
})
export class SupplierAvailabilitiesBulkActionsComponent implements OnInit {
  @ViewChild('getFile', { static: true }) getFile: ElementRef;
  currentFileUpload: File;

  @Input() bulkActionsActive: boolean;

  @Output() onDelete = new Subject<void>();
  @Output() onExport = new Subject<string>();

  constructor(
    private dialog: MatDialog,
    private lotsService: LotsService,
    public snackBar: MatSnackBar,
    private importExportService: ImportExportService
  ) {}

  ngOnInit() {}

  exportExcel(format: string) {
    this.onExport.next(format);
  }

  importExcel() {
    this.getFile.nativeElement.click();
  }

  fileChanged(event) {
    this.currentFileUpload = event.target.files[0];

    this.snackBar.open('Uploading', '')._dismissAfter(3000);

    this.importExportService.uploadFile(this.currentFileUpload, ENTITIES.LSA_RESPONSE, this.lotsService.currentLotId).subscribe(() => {});
  }
}
