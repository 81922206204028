import { Component, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { Vendor } from '../../../../models/vendor-model';
import { MatChipsData } from '../../../../models/mat-chips-data-model';
import { VendorsService } from '../../../configuration/vendors/vendors.service';
import { RemittanceService } from '../remittance.service';
import { RemittanceFilters, remittanceFiltersMap } from '../../../../models/filters/remittance-filters-model';
import { isNilty } from '../../../../core/utils.service';

@Component({
  selector: 'app-main-remittances-filters',
  templateUrl: './main-remittances-filters.component.html',
  styleUrls: ['./main-remittances-filters.component.css'],
})
export class MainRemittancesFiltersComponent implements OnInit {
  constructor(private vendorsService: VendorsService, private remittanceService: RemittanceService) {}

  vendors: Vendor[] = [];

  remittanceDetailsCodes: string[] = [];

  @Output() onFilter = new Subject<RemittanceFilters>();

  filterForm: FormGroup;

  public openFilter = false;

  filtersMap: MatChipsData[] = [];

  ngOnInit() {
    this.filterForm = new FormGroup({
      vendor: new FormControl(),
      paymentNumber: new FormControl(),
      dateFrom: new FormControl(),
      dateTo: new FormControl(),
      invoiceNumber: new FormControl(),
      code: new FormControl(),
    });

    if (!isNilty(this.remittanceService.remittanceFilters)) {
      this.setFilters();
    }

    this.vendorsService.getVendors().subscribe((vendorsResp: Vendor[]) => {
      this.vendors = vendorsResp;
      this.updateFiltersMap();
    });

    this.remittanceService.getRemittanceDetailsCodes().subscribe((resp: string[]) => {
      this.remittanceDetailsCodes = resp;
      this.updateFiltersMap();
    });
  }

  private setFilters() {
    this.filterForm.controls.paymentNumber.setValue(this.remittanceService.remittanceFilters.paymentNumber);
    this.filterForm.controls.vendor.setValue(this.remittanceService.remittanceFilters.vendorId);
    this.filterForm.controls.dateFrom.setValue(this.remittanceService.remittanceFilters.dateFrom);
    this.filterForm.controls.dateTo.setValue(this.remittanceService.remittanceFilters.dateTo);
    this.filterForm.controls.invoiceNumber.setValue(this.remittanceService.remittanceFilters.invoiceNumber);
    this.filterForm.controls.code.setValue(this.remittanceService.remittanceFilters.code);
    this.updateFiltersMap();
  }

  private saveFiltersToService() {
    this.remittanceService.remittanceFilters.paymentNumber = this.filterForm.controls.paymentNumber.value;
    this.remittanceService.remittanceFilters.vendorId = this.filterForm.controls.vendor.value;
    this.remittanceService.remittanceFilters.dateFrom = this.filterForm.controls.dateFrom.value;
    this.remittanceService.remittanceFilters.dateTo = this.filterForm.controls.dateTo.value;
    this.remittanceService.remittanceFilters.invoiceNumber = this.filterForm.controls.invoiceNumber.value;
    this.remittanceService.remittanceFilters.code = this.filterForm.controls.code.value;
  }

  onSubmitFilter() {
    this.saveFiltersToService();
    this.onFilter.next(this.remittanceService.remittanceFilters);
    this.updateFiltersMap();
  }

  resetFilters() {
    this.filterForm.reset();
    this.saveFiltersToService();
    this.onFilter.next(this.remittanceService.remittanceFilters);
    this.updateFiltersMap();
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeEvent() {
    this.openFilter = false;
  }

  private updateFiltersMap() {
    const vendor = this.vendors.find((it) => it.id === this.filterForm.controls.vendor.value);
    this.filtersMap = remittanceFiltersMap(
      this.filterForm.value.paymentNumber,
      isNilty(vendor) ? undefined : vendor.code,
      this.filterForm.value.dateFrom,
      this.filterForm.value.dateTo,
      this.filterForm.value.invoiceNumber,
      this.filterForm.value.code
    );
  }
}
