<mat-toolbar class="transit-toolbar">
  <span class="padding-left-100">
    <mat-form-field>
      <app-mat-select
        filterKey="description"
        [(ngModel)]="selectedSupplierId"
        name="supplier"
        placeholder="Select a supplier"
        (ngModelChange)="onSelectedSupplier($event)"
      >
        <mat-option [value]="null">None</mat-option>
        <mat-option *appMatSelectFor="let supplier of suppliers" [value]="supplier.id">
          {{ supplier.description }}
        </mat-option>
      </app-mat-select>
    </mat-form-field>

    <mat-form-field class="margin-left-20">
      <app-mat-select
        filterKey="description"
        [(ngModel)]="selectedStockLocationId"
        name="stockLocation"
        placeholder="Select a Stock Location"
        (ngModelChange)="onSelectedStockLocation($event)"
      >
        <mat-option [value]="null">None</mat-option>
        <mat-option *appMatSelectFor="let sl of stockLocations" [value]="sl.id">
          {{ sl.description }}
        </mat-option>
      </app-mat-select>
    </mat-form-field>

    <button
      class="btn-transit-time margin-left-20"
      matTooltip="Search"
      (click)="onSearch()"
      [disabled]="!selectedSupplierId || !selectedStockLocationId"
    >
      <i class="fa fa-search" aria-hidden="true"></i>
    </button>

    <span class="col-md-4"></span>
  </span>
</mat-toolbar>

<div class="container">
  <div class="row">
    <div class="col-md-2"></div>

    <div class="col-md-8">
      <div class="padding-top-10" *ngIf="supplierTransitTime || stockLocationsTransitTimes">
        <ng-container *ngIf="!supplierTransitTime">
          <div class="row">
            <div class="transit-title">
              The selected supplier does not have a default configuration
              <button class="btn-transit-time" matTooltip="Create" (click)="createSupplierTransitTimes()">
                <i class="fa fa-plus" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </ng-container>

        <div *ngIf="supplierTransitTime" class="supplier-transit-time row">
          <div class="row-fluid">
            <div class="transit-title">{{ supplierTransitTime.supplier.name }} supplier configuration</div>
          </div>

          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <input matInput type="number" [(ngModel)]="supplierTransitTime.palletThreshold" placeholder="Pallet threshold" />
              </mat-form-field>
            </div>

            <div class="col-md-8">
              <div class="row">
                <div class="col-md-1" *ngFor="let country of supplierTransitTime.transitTimes">
                  <mat-form-field class="transit-form">
                    <input
                      matInput
                      type="number"
                      min="0"
                      max="999"
                      maxlength="3"
                      [(ngModel)]="country.transitTimeWorkingDays"
                      [placeholder]="country.countryCode"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="col-md-2 padding-top-10">
              <button class="btn-transit-time" matTooltip="Create" (click)="saveSupplierTransitTimes()">
                <i class="fa fa-floppy-o" aria-hidden="true"></i>
              </button>
              <button class="btn-transit-time" matTooltip="Discard changes" (click)="resetSupplierTransitTime()">
                <i class="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>

        <div *ngIf="selectedStockLocationId && !stockLocationTransitTime" class="row">
          <div class="transit-title">
            The selected stock location does not have a default configuration
            <button class="btn-transit-time" matTooltip="Create" (click)="createStockLocationTransitTimes()">
              <i class="fa fa-plus" aria-hidden="true"></i>
            </button>
          </div>
        </div>

        <div *ngIf="selectedStockLocationId && stockLocationTransitTime" class="supplier-transit-time row">
          <div class="row-fluid">
            <div class="transit-title">{{ stockLocationTransitTime.stockLocation.name }} default configuration</div>
          </div>

          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <input matInput type="number" [(ngModel)]="stockLocationTransitTime.palletThreshold" placeholder="Pallet threshold" />
              </mat-form-field>
            </div>

            <div class="col-md-1"></div>

            <div class="col-md-7">
              <div class="row">
                <div class="col-md-2" *ngFor="let country of stockLocationTransitTime.transitTimes">
                  <mat-form-field class="transit-form">
                    <input
                      matInput
                      type="number"
                      min="0"
                      max="999"
                      maxlength="3"
                      [(ngModel)]="country.transitTimeWorkingDays"
                      [placeholder]="country.countryCode"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="col-md-2 padding-top-10">
              <button class="btn-transit-time" matTooltip="Create" (click)="saveDefaultStockLocationTransitTime()">
                <i class="fa fa-floppy-o" aria-hidden="true"></i>
              </button>
              <button class="btn-transit-time" matTooltip="Discard changes" (click)="resetDefaultStockLocationTransitTime()">
                <i class="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="row-fluid padding-top-20" *ngIf="selectedStockLocationId">
          <app-stock-locations-transit-times
            [_transitTimes]="stockLocationsTransitTimes"
            (saveTransitTime)="saveStockLocationTransitTime($event)"
            (delete)="deleteStockLocationTransitTime($event)"
          >
          </app-stock-locations-transit-times>
        </div>
      </div>
    </div>
  </div>
</div>
