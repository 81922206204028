import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { InventoryService } from '../../inventory.service';
import { Router } from '@angular/router';
import { ImportExportService } from '../../../../shared/import-export.service';
import { Observable } from 'rxjs';
import { ProductsFilters } from '../../../../models/filters/products-filters-model';
import { ProductsCatalog } from '../../../../models/products-catalog-model';
import { HttpClient } from '@angular/common/http';
import { ENTITIES } from '../../../../entities';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ExtraQuantityModalComponent } from '../extra-quantity-modal/extra-quantity-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.css'],
})
export class ProductsListComponent implements OnInit {
  @ViewChild('getFile', { static: false }) getFile: ElementRef;
  currentFileUpload: File;

  dataSource: MatTableDataSource<ProductsCatalog>;

  displayedColumns = [
    'ean',
    'upc',
    'sku',
    'title',
    'brand',
    'category',
    'season',
    'hsCode',
    'articleCode',
    'size',
    'colorCode',
    'color',
    'status',
    'stockBalance',
    'wholesalePrice',
    'retailPrice',
    'purchasePrice',
  ];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  isCatalog: boolean;
  totalExtraQuantity: boolean;

  constructor(
    private inventoryService: InventoryService,
    private router: Router,
    public snackBar: MatSnackBar,
    private http: HttpClient,
    private dialog: MatDialog,
    private importExportService: ImportExportService
  ) {}

  ngOnInit() {
    this.resultsNumber = this.inventoryService.resultsNumber;
    if (this.inventoryService.productsFilters === null) {
      this.inventoryService.productsFilters = new ProductsFilters();
    }
    this.inventoryService.getFilteredProducts(this.inventoryService.productsFilters).subscribe((products: ProductsCatalog[]) => {
      this.dataSource = new MatTableDataSource<ProductsCatalog>(products);
    });
  }

  loadPage() {
    this.inventoryService.productsFilters.pageNumber = this.paginator.pageIndex;
    this.inventoryService.productsFilters.pageSize = this.paginator.pageSize;

    this.inventoryService.getFilteredProducts(this.inventoryService.productsFilters).subscribe((products: ProductsCatalog[]) => {
      this.dataSource = new MatTableDataSource<ProductsCatalog>(products);
    });
  }

  onSort() {
    this.inventoryService.productsFilters.sortBy = this.sort.active;
    this.inventoryService.productsFilters.sortDirection = this.sort.direction;
    this.inventoryService.getFilteredProducts(this.inventoryService.productsFilters).subscribe((products: ProductsCatalog[]) => {
      this.dataSource = new MatTableDataSource(products);
    });
  }

  onFilter(filters) {
    console.log(filters);
    this.inventoryService.getFilteredProducts(filters).subscribe((prodResp: ProductsCatalog[]) => {
      this.paginator.pageIndex = 0;
      this.dataSource.data = prodResp;
    });
  }

  import(isCatalog: boolean) {
    this.isCatalog = isCatalog;
    if(!isCatalog) {
      const dialogRef = this.dialog.open(ExtraQuantityModalComponent, {
        width: '700px'
      });
      dialogRef.afterClosed().subscribe((result: string) => {
        this.totalExtraQuantity = result === 'total';
        this.getFile.nativeElement.click();
      });
    } else {
      this.getFile.nativeElement.click();
    }
  }

  fileChanged(event) {
    this.currentFileUpload = event.target.files[0];
    const extension: any[] = this.currentFileUpload.name.split('.');
    if (extension[extension.length - 1].toLowerCase() === 'xlsx' && this.isCatalog) {
      this.snackBar.open('Uploading')._dismissAfter(3000);
      this.importExportService.uploadFile(this.currentFileUpload, ENTITIES.CATALOG).subscribe(() => {});
    } else if(extension[extension.length - 1].toLowerCase() === 'csv' && !this.isCatalog) {
      this.snackBar.open('Uploading')._dismissAfter(3000);
      const entity =  this.totalExtraQuantity ? ENTITIES.TOTAL_EXTRA_QUANTITY : ENTITIES.INCREMENTAL_EXTRA_QUANTITY;
      this.importExportService.uploadFile(this.currentFileUpload, entity).subscribe(() => {});
    } else {
      const subTitle = this.isCatalog ? 'Allowed format .xlsx' : 'Allowed format .csv';
      this.snackBar.open(subTitle, 'Wrong File Extension')._dismissAfter(4000);
    }
  }
}
