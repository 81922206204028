import { Injectable, ApplicationRef } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable()
export class AmbraAutoUpdaterService {
  constructor(appRef: ApplicationRef, updates: SwUpdate) {
    console.log('Subscribing to application updates for autorefresh...');
    updates.available.subscribe((event) => {
      console.log('Application was updated, need to refresh!');
      updates.activateUpdate().then(() => document.location.reload());
    });

    console.log('Configuring application update polling');
    const appIsStable$ = appRef.isStable.pipe(first((isStable) => isStable === true));
    const everyMinute$ = interval(60 * 1000);
    const everyMinuteOnceAppIsStable$ = concat(appIsStable$, everyMinute$);

    everyMinuteOnceAppIsStable$.subscribe(() => {
      updates.checkForUpdate();
    });
  }
}
