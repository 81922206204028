import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Vendor } from '@models/vendor-model';
import { VendorCentralAccountsService } from '../../vendor-central-accounts.service';

@Component({
  selector: 'app-vendors-dialog',
  templateUrl: './vendors-dialog.component.html',
  styleUrls: ['./vendors-dialog.component.css'],
})
export class VendorsDialogComponent implements OnInit {
  dataSource: MatTableDataSource<Vendor>;

  displayedColumns = ['code', 'name', 'country'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    public dialogRef: MatDialogRef<VendorsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public vendorCentralAccountService: VendorCentralAccountsService
  ) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.data.vendorCentralAccount.vendors);
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
