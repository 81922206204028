import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PublicationRuleService } from '../publication-rule.service';
import { JsonRule, PublicationRule } from '../../../../models/publication-rule';
import { GenericConfirmationModalComponent } from '../../../modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { Supplier } from '../../../../models/supplier-model';
import { Vendor } from '../../../../models/vendor-model';
import { VendorsService } from '../../vendors/vendors.service';
import { Status } from '../../../../models/publication-rule-status-model';
import { isNilty } from '../../../../core/utils.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-publication-rule-edit-edit',
  templateUrl: './publication-rule-edit.component.html',
  styleUrls: ['./publication-rule-edit.component.css'],
})
export class PublicationRuleEditComponent implements OnInit {
  rules: PublicationRule[] = [];
  editMode = false;
  currentRuleId: number;
  currentPublicationRule = new PublicationRule();
  jsonRule = new JsonRule();
  publicationRuleForm: FormGroup;

  suppliers: Supplier[] = [];
  vendors: Vendor[];
  status: Status[];
  strategies: string[];

  choice = '';

  disabledSave = false;

  constructor(
    private publicationRuleService: PublicationRuleService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private vendorsService: VendorsService
  ) {}

  ngOnInit(): void {
    this.publicationRuleForm = new FormGroup({
      type: new FormControl('', Validators.required),
      status: new FormControl('', Validators.required),
      childId: new FormControl('', Validators.required),
      qtyThreshold: new FormControl('', Validators.required),
      unitCostThreshold: new FormControl('', Validators.required),
      priceVariation: new FormControl('', Validators.required),
      strategy: new FormControl('', Validators.required),
      strategyPercentage: new FormControl('', Validators.required),
      sendFlatFile: new FormControl(false),
    });

    this.route.params.subscribe((params: Params) => {
      this.currentRuleId = +params['id'];
      this.editMode = params['id'] != null;
      if (this.editMode) {
        this.publicationRuleService.getRule(this.currentRuleId).subscribe((publicationRule: PublicationRule) => {
          this.currentPublicationRule = publicationRule;
          this.jsonRule = publicationRule.rule;
          this.changeFormValues(this.currentPublicationRule, this.jsonRule);
          this.choice = this.publicationRuleForm.value.strategy;
        });
      }
    });
    this.rules = this.publicationRuleService.publicationRules;

    this.vendorsService.getVendors().subscribe((vendors: Vendor[]) => {
      this.vendors = vendors;
    });

    this.publicationRuleService.getStatus().subscribe((status: Status[]) => {
      this.status = status;
    });

    this.publicationRuleService.getStrategies().subscribe((strategies: string[]) => {
      this.strategies = strategies;
    });
  }

  changeFormValues(publicationRule: PublicationRule, jsonRule: JsonRule) {
    this.publicationRuleForm.setValue({
      type: publicationRule.type,
      status: publicationRule.status,
      childId: jsonRule.childId,
      strategy: !isNilty(jsonRule.strategy) ? jsonRule.strategy : 'Markup',
      strategyPercentage: !isNilty(jsonRule.strategyPercentage) ? jsonRule.strategyPercentage : '10.0',
      qtyThreshold: jsonRule.qtyThreshold,
      unitCostThreshold: jsonRule.unitCostThreshold,
      sendFlatFile: !isNilty(jsonRule.sendFlatFile) ? jsonRule.sendFlatFile : false,
      priceVariation: !isNilty(jsonRule.priceVariation) ? jsonRule.priceVariation : '0.0',
    });
  }

  onSubmit() {
    if ((this.editMode = true)) {
      this.currentPublicationRule.id = this.currentRuleId;
    } else {
      this.currentPublicationRule.id = null;
    }

    this.currentPublicationRule.type = this.publicationRuleForm.value.type;
    this.currentPublicationRule.status = this.publicationRuleForm.value.status;
    this.jsonRule.childId = this.publicationRuleForm.value.childId;
    this.jsonRule.strategy = this.publicationRuleForm.value.strategy;
    this.jsonRule.strategyPercentage = this.publicationRuleForm.value.strategyPercentage;
    this.jsonRule.qtyThreshold = this.publicationRuleForm.value.qtyThreshold;
    this.jsonRule.unitCostThreshold = this.publicationRuleForm.value.unitCostThreshold;
    this.jsonRule.sendFlatFile = this.publicationRuleForm.value.sendFlatFile ? this.publicationRuleForm.value.sendFlatFile : false;
    this.jsonRule.priceVariation = this.publicationRuleForm.value.priceVariation;
    this.currentPublicationRule.rule = this.jsonRule;
    this.publicationRuleService.postRule(this.currentPublicationRule).subscribe(() => {
      this.router.navigate(['configuration', 'publication-rules']);
    });
  }

  onDelete() {
    const message = 'Deleting Publication Rule ';
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '350px',
      data: message,
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.publicationRuleService.deleteRule(this.currentRuleId).subscribe(() => {
          console.log('Publication Rule deleted.');
          this.router.navigate(['configuration', 'publication-rules']);
        });
      } else {
        console.log('Publication Rule not deleted.');
      }
    });
  }

  onDisableSaveButton(value: boolean) {
    this.disabledSave = value;
  }

  onCancel() {
    this.router.navigate(['configuration', 'publication-rules']);
  }
}
