import { Injectable } from '@angular/core';
import { EnvironmentService } from '../environment.service';

@Injectable()
export class AuthenticationService {
  constructor(private environmentService: EnvironmentService) { }

  login(): void {
    sessionStorage.removeItem('token');
    window.location.href = this.environmentService.ponchBaseApiUrl + 'login?callback=' +
      btoa(window.location.href) + '&application=' + this.environmentService.application;
  }

  logout(): void {
    sessionStorage.removeItem('token');
    window.location.href = this.environmentService.ponchBaseApiUrl + 'logout?callback=' +
      btoa(window.location.href) + '&application=' + this.environmentService.application;
  }

  getAuthorizationHeader(): string {
    const token = sessionStorage.getItem('token');
    if (!token) {
      this.login();
    }
    return token;
  }
}
