import { NgModule } from '@angular/core';

import { MainShipmentsListComponent } from './main-shipments-list/main-shipments-list.component';
import { MainShipmentsListFiltersComponent } from './main-shipments-list-filters/main-shipments-list-filters.component';
import { MainShipmentsDetailsComponent } from './main-shipments-details/main-shipments-details.component';
import { MainShipmentsRoutingModule } from './main-shipments-routing.module';
import { SharedModule } from '../../../shared.module';
import { MainShipmentsComponent } from './main-shipments.component';
import { SingleShipmentDetailsModule } from '../../../shared/single-shipment-detail/single-shipment-details.module';

@NgModule({
  declarations: [MainShipmentsComponent, MainShipmentsListComponent, MainShipmentsListFiltersComponent, MainShipmentsDetailsComponent],
  imports: [SharedModule, MainShipmentsRoutingModule, SingleShipmentDetailsModule],
  providers: [],
  entryComponents: [],
})
export class MainShipmentsModule {}
