<mat-toolbar id="subsectionMenu" color="accent">
  <span class="fill-remaining-space text-center">
    <button class="btn-inventory" [routerLink]="['/inventory/publications']" routerLinkActive="active">
      <i class="fa fa-pencil-square-o padding-bottom-1" aria-hidden="true"></i>
      Publications
    </button>
    <button class="btn-inventory" [routerLink]="['/inventory/products']" routerLinkActive="active">
      <i class="fa fa-list-ul padding-bottom-1" aria-hidden="true"></i>
      Products
    </button>
    <button class="btn-inventory" [routerLink]="['/inventory/suppliers']" routerLinkActive="active">
      <i class="fa fa-user padding-bottom-1" aria-hidden="true"></i>
      Suppliers
    </button>
    <button class="btn-inventory" [routerLink]="['/inventory/stock-locations']" routerLinkActive="active">
      <i class="fa fa-cube padding-bottom-1" aria-hidden="true"></i>
      Stock Locations
    </button>
    <button class="btn-inventory" [routerLink]="['/inventory/carriers']" routerLinkActive="active">
      <i class="fa fa-truck padding-bottom-1" aria-hidden="true"></i>
      Carriers
    </button>
  </span>
</mat-toolbar>
