import { JsonObject, JsonProperty } from 'json2typescript';
import { isNilty } from '../../core/utils.service';
import { Category } from '../category-model';
import { Contract } from '../contract.model';
import { DateConverter, fromDateToFiltersString } from '../date-converter';
import { MatChipsData } from '../mat-chips-data-model';
import { Vendor } from '../vendor-model';
import { PaginationAndSorting } from './pagination-and-sorting-model';

@JsonObject('ClaimHeaderFilters')
export class ClaimHeaderFilters extends PaginationAndSorting {
  @JsonProperty('invoiceNumber', String, true) invoiceNumber: string = undefined;
  @JsonProperty('invoiceDateStart', DateConverter, true) invoiceDateStart: Date = undefined;
  @JsonProperty('invoiceDateEnd', DateConverter, true) invoiceDateEnd: Date = undefined;
  @JsonProperty('remittanceDateStart', DateConverter, true) remittanceDateStart: Date = undefined;
  @JsonProperty('remittanceDateEnd', DateConverter, true) remittanceDateEnd: Date = undefined;
  @JsonProperty('vendor', Number, true) vendor: number = undefined;
  @JsonProperty('vendorChild', Number, true) vendorChild: number = undefined;
  @JsonProperty('supplier', String, true) supplier: string = undefined;
  @JsonProperty('carrierContracts', [Number], true) carrierContracts: number[] = undefined;
  @JsonProperty('trackingNumber', String, true) trackingNumber: string = undefined;
  @JsonProperty('scStatus', [String], true) scStatus: string[] = undefined;
  @JsonProperty('pcStatus', [String], true) pcStatus: string[] = undefined;
  @JsonProperty('disputeId', String, true) disputeId: string = undefined;
  @JsonProperty('caseId', String, true) caseId: string = undefined;
  @JsonProperty('deliveryDate', DateConverter, true) deliveryDate: Date = undefined;
  @JsonProperty('hasPod', Boolean, true) hasPod: boolean = undefined;
  @JsonProperty('hasRemittance', Boolean, true) hasRemittance: Date = undefined;
  @JsonProperty('hasProbableNC', Boolean, true) hasProbableNC: boolean = undefined;
  @JsonProperty('debitNoteYear', Number, true) debitNoteYear: number = undefined;
  @JsonProperty('debitNoteMonth', Number, true) debitNoteMonth: number = undefined;
  @JsonProperty('countryOfDestination', String, true) countryOfDestination: string = undefined;
  @JsonProperty('category', Number, true) category: number = undefined;
  @JsonProperty('ean', String, true) ean: string = undefined;
  @JsonProperty('po', String, true) po: string = undefined;
  @JsonProperty('settlement', Boolean, true) settlement: boolean = undefined;
  @JsonProperty('botEnabled', Boolean, true) botEnabled: boolean = undefined;
  @JsonProperty('dueDateFrom', DateConverter, true) dueDateFrom: Date = undefined;
  @JsonProperty('dueDateTo', DateConverter, true) dueDateTo: Date = undefined;
}

export const claimHeaderFiltersMap = (
  invoiceNumber: string,
  invoiceDateStart: Date,
  invoiceDateEnd: Date,
  remittanceDateStart: Date,
  remittanceDateEnd: Date,
  vendor: Vendor,
  supplier: string,
  carrierContracts: Contract[],
  trackingNumber: string,
  scStatus: string[],
  pcStatus: string[],
  disputeId: string,
  caseId: string,
  deliveryDate: Date,
  hasPod: string,
  hasRemittance: string,
  hasProbableNC: string,
  debitNoteYear: string,
  debitNoteMonth: string,
  countryOfDestination: string,
  category: Category,
  ean: string,
  po: string,
  settlement: string,
  botEnabled: string,
  dueDateFrom: Date,
  dueDateTo: Date
): MatChipsData[] => {
  const map: MatChipsData[] = [];
  if (!isNilty(invoiceNumber)) {
    map.push(new MatChipsData('Invoice Number', invoiceNumber));
  }
  if (invoiceDateStart) {
    map.push(new MatChipsData('Invoice Date Start', fromDateToFiltersString(invoiceDateStart)));
  }
  if (invoiceDateEnd) {
    map.push(new MatChipsData('Invoice Date End', fromDateToFiltersString(invoiceDateEnd)));
  }
  if (remittanceDateStart) {
    map.push(new MatChipsData('Remittance Date Start', fromDateToFiltersString(invoiceDateStart)));
  }
  if (remittanceDateEnd) {
    map.push(new MatChipsData('Remittance Date End', fromDateToFiltersString(remittanceDateEnd)));
  }
  if (!isNilty(vendor)) {
    map.push(new MatChipsData('Vendor', vendor.code));
  }
  if (!isNilty(supplier)) {
    map.push(new MatChipsData('Supplier', supplier));
  }
  if (!isNilty(carrierContracts)) {
    map.push(new MatChipsData('Carrier Contracts', carrierContracts.map((contract) => contract.name).join()));
  }
  if (!isNilty(trackingNumber)) {
    map.push(new MatChipsData('Tracking Number', trackingNumber));
  }
  if (!isNilty(scStatus)) {
    map.push(new MatChipsData('SC Status', scStatus.join()));
  }
  if (!isNilty(pcStatus)) {
    map.push(new MatChipsData('PC Status', pcStatus.join()));
  }
  if (!isNilty(disputeId)) {
    map.push(new MatChipsData('Dispute ID', disputeId));
  }
  if (!isNilty(caseId)) {
    map.push(new MatChipsData('Case ID', caseId));
  }
  if (!isNilty(deliveryDate)) {
    map.push(new MatChipsData('Delivery Date', fromDateToFiltersString(deliveryDate)));
  }
  if (!isNilty(hasPod)) {
    map.push(new MatChipsData('Has Pod', hasPod));
  }
  if (!isNilty(hasRemittance)) {
    map.push(new MatChipsData('Has Remittance', hasRemittance));
  }
  if (!isNilty(hasProbableNC)) {
    map.push(new MatChipsData('Has Probable NC', hasProbableNC));
  }
  if (!isNilty(debitNoteYear)) {
    map.push(new MatChipsData('Debit Note Year', debitNoteYear));
  }
  if (!isNilty(debitNoteMonth)) {
    map.push(new MatChipsData('Debit Note Month', debitNoteMonth));
  }
  if (!isNilty(countryOfDestination)) {
    map.push(new MatChipsData('Country Of Destination', countryOfDestination));
  }
  if (!isNilty(category)) {
    map.push(new MatChipsData('Category', category.name));
  }
  if (!isNilty(ean)) {
    map.push(new MatChipsData('EAN', ean));
  }
  if (!isNilty(po)) {
    map.push(new MatChipsData('PO', po));
  }
  if (!isNilty(settlement)) {
    map.push(new MatChipsData('Settlement 2022', settlement));
  }
  if (!isNilty(botEnabled)) {
    map.push(new MatChipsData('Bot Enabled', botEnabled));
  }
  if (dueDateFrom != null) {
    map.push(new MatChipsData('From Due Date', fromDateToFiltersString(dueDateFrom)));
  }
  if (dueDateTo != null) {
    map.push(new MatChipsData('To Due Date', fromDateToFiltersString(dueDateTo)));
  }

  return map;
};
