<div class="row">
  <ng-container *ngIf="!dataSource || dataSource?.data.length === 0">
    <div class="col-md-12 small-table-title text-center">
      <h4>No claims found</h4>
    </div>
  </ng-container>

  <div class="col-md-12 small-table-title border-bottom-title" [ngClass]="{ 'display-none': !dataSource || dataSource?.data.length === 0 }">
    <h4>Claims</h4>
  </div>

  <div class="col-md-12 fixed-table-5-rows padding-right-0 padding-left-0" *ngIf="dataSource && dataSource?.data.length > 0">
    <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-40">
      <mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="invoiceNumber">
          <mat-header-cell *matHeaderCellDef class="invoice-number-column">Remittance Number</mat-header-cell>
          <mat-cell *matCellDef="let c" class="invoice-number-column">
            <p>{{ c.invoiceNumber }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="invoiceDate">
          <mat-header-cell class="date-column" *matHeaderCellDef>Remittance Date</mat-header-cell>
          <mat-cell class="date-column" *matCellDef="let c">
            <p>{{ c.remittanceDetail?.remittance?.paymentDate }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="suffix">
          <mat-header-cell class="suffix-column" *matHeaderCellDef>Suffix</mat-header-cell>
          <mat-cell class="suffix-column" *matCellDef="let c">
            <p>{{ c.suffix }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell class="status-column" *matHeaderCellDef>Status</mat-header-cell>
          <mat-cell class="status-column" *matCellDef="let c">
            <p>{{ c.status.description }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="disputeId">
          <mat-header-cell class="dispute-id-column" *matHeaderCellDef>Dispute ID</mat-header-cell>
          <mat-cell class="dispute-id-column" *matCellDef="let c">
            <p>{{ c.disputeId }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="disputeBy">
          <mat-header-cell class="quantity-column-title" *matHeaderCellDef>Dispute By</mat-header-cell>
          <mat-cell class="quantity-column" *matCellDef="let c">
            <p>{{ getDisputeBy(c) }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="caseIds">
          <mat-header-cell class="case-ids-column" *matHeaderCellDef>Case IDs</mat-header-cell>
          <mat-cell class="case-ids-column" *matCellDef="let c">
            <p>{{ c.caseIds | join }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="openedBy">
          <mat-header-cell class="date-column" *matHeaderCellDef>Opened By</mat-header-cell>
          <mat-cell class="date-column" *matCellDef="let c">
            <p>{{ getOpenedBy(c) }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="amountPaid">
          <mat-header-cell class="value-column-title" *matHeaderCellDef>Amount</mat-header-cell>
          <mat-cell class="value-column" *matCellDef="let c">
            <p>{{ c.amountPaid | myCurrency }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="claimQuantity">
          <mat-header-cell class="quantity-column-title" *matHeaderCellDef>Claim Quantity</mat-header-cell>
          <mat-cell class="quantity-column" *matCellDef="let c">
            <p>{{ claimQuantity(c) }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="hasRemittance">
          <mat-header-cell class="vendor-column" *matHeaderCellDef>Has Remittance</mat-header-cell>
          <mat-cell class="boolean-column" *matCellDef="let c">
            <p><i [ngClass]="c.remittanceDetail | booleanIconPipe" aria-hidden="true"></i></p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="disputeCreatedByBot">
          <mat-header-cell class="vendor-column" *matHeaderCellDef>Dispute Created<br />by Bot</mat-header-cell>
          <mat-cell class="boolean-column" *matCellDef="let c">
            <p><i [ngClass]="c.disputeCreatedByBot | booleanIconPipe" aria-hidden="true"></i></p>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="caseCreatedByBot">
          <mat-header-cell class="vendor-column" *matHeaderCellDef>Case Created<br />by Bot</mat-header-cell>
          <mat-cell class="boolean-column" *matCellDef="let c">
            <p><i [ngClass]="c.caseCreatedByBot | booleanIconPipe" aria-hidden="true"></i></p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="edit" stickyEnd>
          <mat-header-cell *matHeaderCellDef class="single-line-edit-column">
            <p>Edit claim</p>
          </mat-header-cell>
          <mat-cell class="single-line-edit-column" *matCellDef="let ch">
            <p *ngIf="ch.code.endsWith('C')">
              <button class="box-shadow-none actions-button btn-fixed" matTooltip="Edit claim" (click)="edit(ch)">
                <i class="fa fa-edit" aria-hidden="true"></i>
              </button>
            </p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="showDetails" stickyEnd>
          <mat-header-cell *matHeaderCellDef class="single-line-edit-column">
            <p>Show Details</p>
          </mat-header-cell>
          <mat-cell class="single-line-edit-column" *matCellDef="let ch">
            <p *ngIf="ch.code.endsWith('C')">
              <button class="box-shadow-none actions-button btn-fixed" matTooltip="Show details" (click)="showDetails(ch.id)">
                <i class="fa fa-external-link" aria-hidden="true"></i>
              </button>
            </p>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>
</div>
