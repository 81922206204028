import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Po } from '../../models/po-model';
import { ImportExportService } from '../import-export.service';
import { PoItemWithLot } from '../../models/po-item-with-lot-model';
import { PosService } from '../pos.service';
import { LotEditKpi } from '../../models/lot-edit-kpi';
import { Observable } from 'rxjs';
import { LotsService } from '../lots.service';
import { PoFilters } from '../../models/filters/po-filters-model';
import { Location } from '@angular/common';
import { addCheckers } from '../checkers.service';
import { AuthorizationService } from '../../core/authorization.service';
import { Permissions } from '../../models/permission-model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { PoaHistoryModalComponent } from './poa-history-modal/poa-history-modal.component';

@Component({
  selector: 'app-single-po-details',
  templateUrl: './single-po-details.component.html',
  styleUrls: ['./single-po-details.component.css'],
})
export class SinglePoDetailsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  poId: number;

  po: Po = new Po();

  dataPoItems = [];

  dataSource;

  mainChecker = false;

  filters = new PoFilters();

  bulkActionsActive = false;

  kpiData: Observable<LotEditKpi>;

  hasLotManagement: Observable<boolean>;

  displayedColumns = [
    'check',
    'title',
    'ean',
    'brand',
    'category',
    'sku',
    'backorder',
    'expectedDate',
    'submittedQuantity',
    'acceptedQuantity',
    'lastPoaQuantity',
    'obsoleteWithAccepted',
    'unitCost',
    'totalCost',
    'lotName',
  ];

  resultsNumber: Observable<number>;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private posService: PosService,
    private importExportService: ImportExportService,
    private authorizationService: AuthorizationService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.kpiData = this.posService.kpiData;
    this.resultsNumber = this.posService.resultsNumber;
    this.route.params.subscribe((param: Params) => {
      this.poId = param['id'];
      this.posService.getPo(+this.poId).subscribe((po: Po) => {
        this.filters = new PoFilters();
        this.po = po;
        this.resetCheckers();
        this.dataSource = new MatTableDataSource([]);
        this.loadPoItems(this.filters);
      });
    });

    this.hasLotManagement = this.authorizationService.hasPermission(Permissions.LotManagement);
  }

  loadPoItems(filters: PoFilters) {
    filters.purchaseOrder = this.po.poId;
    this.posService.getFilteredPoItems(filters).subscribe((poItems: PoItemWithLot[]) => {
      this.dataPoItems = addCheckers(poItems, this.posService.selectedPoItems, this.mainChecker);
      this.dataSource.data = this.dataPoItems;
    });
  }

  loadPage() {
    this.filters.pageNumber = this.paginator.pageIndex;
    this.filters.pageSize = this.paginator.pageSize;
    this.loadPoItems(this.filters);
  }

  onSort() {
    this.filters.sortBy = this.sort.active;
    this.filters.sortDirection = this.sort.direction;
    this.loadPoItems(this.filters);
  }

  applyFilter(filters: PoFilters) {
    this.filters = filters;
    this.filters.purchaseOrder = this.po.poId;
    this.paginator.pageIndex = 0;
    this.resetCheckers();
    this.loadPoItems(this.filters);
  }

  goBack() {
    this.location.back();
  }

  onSelected(value: boolean, selectedPoItem: any) {
    const selectedIndex = this.dataPoItems.indexOf(selectedPoItem);
    this.dataPoItems[selectedIndex].checked = value;

    if (value !== this.mainChecker) {
      this.posService.addPoItemIdToSelected(this.dataPoItems[selectedIndex].id);
    } else {
      this.posService.removePoItemIdFromSelected(this.dataPoItems[selectedIndex].id);
    }
    this.bulkActionsActive = value || this.mainChecker;
  }

  toggleAll(value: boolean) {
    this.mainChecker = value;
    this.posService.mainChecker = this.mainChecker;
    for (const i of this.dataPoItems) {
      i.checked = value;
    }
    this.posService.selectedPoItems = [];
    this.bulkActionsActive = value;
  }

  exportPoItems(format: string) {
    const selectedPoItemsIds = this.posService.selectedPoItems;

    this.importExportService.exportPoItemsToExcel(selectedPoItemsIds, format, this.mainChecker, this.filters).subscribe((resp) => {
      console.log(resp);
    });
  }

  resetCheckers() {
    this.posService.selectedPoItems = [];
    this.mainChecker = false;
    this.bulkActionsActive = false;
  }

  openPoaHistory() {
    this.dialog.open(PoaHistoryModalComponent, {
      disableClose: true,
      data: { poId: this.poId },
    });
  }

  downloadEdi() {
    window.open(this.po.ediUrl, '_blank');
  }
}
