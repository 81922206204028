import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { VendorsService } from '@container/configuration/vendors/vendors.service';
import { VendorCentralAccount } from '@models/vendor-central-account-model';
import { Vendor } from '@models/vendor-model';
import { VendorCentralAccountsService } from '@container/configuration/vendor-central-accounts/vendor-central-accounts.service';
import { isNilty } from '@core/utils.service';
import { GenericConfirmationModalComponent } from '@container/modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-vendor-central-accounts-edit',
  templateUrl: './vendor-central-accounts-edit.component.html',
  styleUrls: ['./vendor-central-accounts-edit.component.css'],
})
export class VendorCentralAccountsEditComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private vendorService: VendorsService,
    private vendorCentralAccountsService: VendorCentralAccountsService,
    private dialog: MatDialog
  ) {}

  vendors: Vendor[];

  vendorCentralAccountForm: FormGroup;
  vendorCentralAccountId: number;
  editMode = false;
  currentVendorAccount = new VendorCentralAccount();

  ngOnInit(): void {
    this.vendorCentralAccountForm = new FormGroup({
      name: new FormControl('', Validators.required),
      botEnabled: new FormControl(),
      vendors: new FormControl([]),
    });

    this.route.params.subscribe((params: Params) => {
      this.vendorCentralAccountId = +params['id'];
      this.editMode = params['id'] != null;
      if (this.editMode) {
        this.vendorCentralAccountsService.getVendorCentralAccount(this.vendorCentralAccountId).subscribe((v: VendorCentralAccount) => {
          this.currentVendorAccount = v;
          this.changeFormValue(this.currentVendorAccount);
          this.setVendors();
        });
      }
    });

    this.vendorService.getVendors().subscribe((vendors: Vendor[]) => {
      this.vendors = vendors;
      this.setVendors();
    });
  }

  setVendors() {
    if (!isNilty(this.currentVendorAccount.vendors) && !isNilty(this.vendors)) {
      this.vendorCentralAccountForm.controls['vendors'].patchValue(
        this.currentVendorAccount.vendors.map((vendor) => this.vendors?.find((it) => it.code === vendor.code))
      );
    }
  }

  changeFormValue(vendorAccount: VendorCentralAccount) {
    this.vendorCentralAccountForm.setValue({
      name: vendorAccount.name ? vendorAccount.name : null,
      botEnabled: vendorAccount.botEnabled,
      vendors: vendorAccount.vendors,
    });
  }

  onBack() {
    this.router.navigate(['configuration', 'vendor-central-accounts']);
  }

  onSubmit() {
    if (this.editMode) {
      this.currentVendorAccount.id = this.vendorCentralAccountId;
    } else {
      this.currentVendorAccount.id = null;
    }
    this.currentVendorAccount.name = this.vendorCentralAccountForm.value.name;
    this.currentVendorAccount.botEnabled = this.vendorCentralAccountForm.value.botEnabled;
    this.currentVendorAccount.vendors = this.vendorCentralAccountForm.value.vendors;

    this.vendorCentralAccountsService.saveVendorCentralAccount(this.currentVendorAccount.toDTO()).subscribe((resp: any) => {
      this.onBack();
    });
  }

  onDelete() {
    const message = 'Deleting vendor central account ' + this.currentVendorAccount.name;
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '350px',
      data: message,
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.vendorCentralAccountsService.deleteVendor(this.vendorCentralAccountId).subscribe(() => {
          console.log('Vendor central account deleted.');
          this.router.navigate(['configuration', 'vendor-central-accounts']);
        });
      } else {
        console.log('Vendor central account not deleted.');
      }
    });
  }
}
