import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { isNilty } from '@core/utils.service';
import { Vendor } from '@models/vendor-model';
import { LotConfigurationsService } from '../lot-configurations.service';
import { VendorsService } from '../../vendors/vendors.service';
import { LotConfiguration } from '@models/lot-configuration-model';
import { Supplier } from '@models/supplier-model';
import { InventoryService } from '@container/inventory/inventory.service';
import { GenerateOrderedConfigurationDto } from '@models/lot-configuration-dto-model';
import { GenericConfirmationModalComponent } from '@modal/generic-confirmation-modal/generic-confirmation-modal.component';

@Component({
  selector: 'app-lot-configuration-edit',
  templateUrl: './lot-configuration-edit.component.html',
  styleUrls: ['./lot-configuration-edit.component.scss'],
})
export class LotConfigurationEditComponent implements OnInit {
  lotConfigurationForm: FormGroup;
  generateOrderedConfigurations: GenerateOrderedConfigurationDto[] = [];
  editMode = false;
  lotConfigurationId: number;
  currentLotConfiguration = new LotConfiguration();
  vendors: Vendor[];
  suppliers: Supplier[];

  newGenerateOrderedConfiguration: GenerateOrderedConfigurationDto = undefined;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private vendorService: VendorsService,
    private inventoryService: InventoryService,
    private lotConfigurationsService: LotConfigurationsService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.lotConfigurationForm = new FormGroup({
      name: new FormControl('', Validators.required),
      lotName: new FormControl('PES${DD}${MM}${YYYY}', Validators.required),
      cronExpression: new FormControl('0 0 7 ? * MON,TUE,WED,THU,FRI *', Validators.required),
      vendors: new FormControl([], Validators.required),
      thresholdValue: new FormControl(0, Validators.required),
      notIntegratedSuppliers: new FormControl([]),
      disabled: new FormControl(false, Validators.required),
      generateOrderedIterations: new FormControl(0, Validators.required),
      generateOrderedDecreseMarginPerIteration: new FormControl(0, Validators.required),
    });

    this.route.params.subscribe((params: Params) => {
      this.lotConfigurationId = +params['id'];
      this.editMode = params['id'] != null;
      if (this.editMode) {
        this.lotConfigurationsService.getLotConfiguration(this.lotConfigurationId).subscribe((lotConfiguration: LotConfiguration) => {
          this.currentLotConfiguration = lotConfiguration;
          this.changeFormValues(this.currentLotConfiguration);
          this.setVendors();
          this.setSuppliers();
        });
        this.lotConfigurationsService
          .getGenerateOrderedConfigurations(this.lotConfigurationId)
          .subscribe((r: GenerateOrderedConfigurationDto[]) => {
            this.generateOrderedConfigurations = r;
          });
      }
    });

    this.vendorService.getVendors().subscribe((vendors: Vendor[]) => {
      this.vendors = vendors;
      this.setVendors();
    });

    this.inventoryService.getAllSuppliers().subscribe((suppliers: Supplier[]) => {
      this.suppliers = suppliers;
      this.setSuppliers();
    });
  }

  setVendors() {
    if (!isNilty(this.currentLotConfiguration.vendors) && !isNilty(this.vendors)) {
      this.lotConfigurationForm.controls['vendors'].patchValue(
        this.currentLotConfiguration.vendors.map((vendor) => this.vendors?.find((it) => it.code === vendor.code))
      );
    }
  }

  setSuppliers() {
    if (!isNilty(this.currentLotConfiguration.notIntegratedSuppliers) && !isNilty(this.suppliers)) {
      this.lotConfigurationForm.controls['notIntegratedSuppliers'].patchValue(
        this.currentLotConfiguration.notIntegratedSuppliers.map((supplier) => this.suppliers.find((it) => it.code === supplier.code))
      );
    }
  }

  changeFormValues(lotConfiguration: LotConfiguration) {
    this.lotConfigurationForm.setValue({
      name: lotConfiguration.name,
      lotName: lotConfiguration.lotName,
      cronExpression: lotConfiguration.cronExpression,
      vendors: lotConfiguration.vendors,
      thresholdValue: lotConfiguration.thresholdValue,
      notIntegratedSuppliers: lotConfiguration.notIntegratedSuppliers,
      disabled: lotConfiguration.disabled,
      generateOrderedIterations: lotConfiguration.generateOrderedIterations,
      generateOrderedDecreseMarginPerIteration: lotConfiguration.generateOrderedDecreaseMarginPerIteration,
    });
  }

  onBack() {
    this.router.navigate(['configuration', 'lot-configurations']);
  }

  onSubmit() {
    if (this.editMode) {
      this.currentLotConfiguration.id = this.lotConfigurationId;
    } else {
      this.currentLotConfiguration.id = null;
    }

    this.currentLotConfiguration.name = this.lotConfigurationForm.value.name;
    this.currentLotConfiguration.lotName = this.lotConfigurationForm.value.lotName;
    this.currentLotConfiguration.cronExpression = this.lotConfigurationForm.value.cronExpression;
    this.currentLotConfiguration.vendors = this.lotConfigurationForm.value.vendors;
    this.currentLotConfiguration.thresholdValue = this.lotConfigurationForm.value.thresholdValue;
    this.currentLotConfiguration.notIntegratedSuppliers = this.lotConfigurationForm.value.notIntegratedSuppliers;
    this.currentLotConfiguration.disabled = this.lotConfigurationForm.value.disabled;
    this.currentLotConfiguration.generateOrderedIterations = this.lotConfigurationForm.value.generateOrderedIterations;
    this.currentLotConfiguration.generateOrderedDecreaseMarginPerIteration =
      this.lotConfigurationForm.value.generateOrderedDecreseMarginPerIteration;

    this.lotConfigurationsService
      .saveLotConfiguration(this.currentLotConfiguration.toDTO(this.generateOrderedConfigurations))
      .subscribe((resp: any) => {
        this.onBack();
      });
  }
}
