<h2 mat-dialog-title>Send documents</h2>

<div class="container">
  <div class="row">
    <div class="col-md-12">
      This will send the selected documents for Shipment {{ this.shipment.orderId }} to Supplier {{ this.shipment.origin.supplier.code }} to
      the following emails:
    </div>
    <div class="col-md-12">
      <i>{{ this.shipment.origin.supplier.shipmentDocumentationReceivers }}</i>
    </div>
  </div>

  <div class="row padding-top-15">
    <div class="col-md-12" *ngFor="let d of documents">
      <span [matTooltip]="d.available ? null : 'This document has not been generated yet.'">
        <mat-checkbox [(ngModel)]="d.selected" [disabled]="!d.available"> {{ d.label }}</mat-checkbox>
      </span>
    </div>
  </div>
</div>

<mat-dialog-actions>
  <button class="btn-product margin-right-38" (click)="sendDocuments()">Send</button>
  <button class="btn-product" [mat-dialog-close]="false">Close</button>
</mat-dialog-actions>
