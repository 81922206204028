import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';
import { DeliveryWindowConstraintDTO } from './delivery-window-constraint-dto-model';

@JsonObject('GenerateOrderedConfigurationDto')
export class GenerateOrderedConfigurationDto {
  @JsonProperty('id', Number, true) id = -1;
  @JsonProperty('margin', Number, true) margin: number = undefined;
  @JsonProperty('virtualMargin', Number, true) virtualMargin: number = undefined;
  @JsonProperty('virtualNetMargin', Number, true) virtualNetMargin: number = undefined;
  @JsonProperty('generateOrderedIterations', Number, true) generateOrderedIterations: number = undefined;
  @JsonProperty('generateOrderedDecreaseMarginPerIteration', Number, true) generateOrderedDecreaseMarginPerIteration: number = undefined;
}

@JsonObject('LotConfigurationDTO')
export class LotConfigurationDTO extends BaseObject {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('lotName', String, true) lotName: string = undefined;
  @JsonProperty('cronExpression', String, true) cronExpression: string = undefined;
  @JsonProperty('vendors', [Number], true) vendors: number[] = undefined;
  @JsonProperty('deliveryWindowConstraints', [DeliveryWindowConstraintDTO], true) deliveryWindowConstraints: DeliveryWindowConstraintDTO[] =
    [];
  @JsonProperty('thresholdValue', Number, true) thresholdValue: number = undefined;
  @JsonProperty('notIntegratedSuppliers', [Number], true) notIntegratedSuppliers: number[] = undefined;
  @JsonProperty('disabled', Boolean, true) disabled: boolean = undefined;
  @JsonProperty('generateOrderedIterations', Number, true) generateOrderedIterations: number = undefined;
  @JsonProperty('generateOrderedDecreaseMarginPerIteration', Number, true) generateOrderedDecreaseMarginPerIteration: number = undefined;
  @JsonProperty('generateOrderedConfigurations', [GenerateOrderedConfigurationDto], true)
  generateOrderedConfigurations: GenerateOrderedConfigurationDto[] = [];
}
