import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';
import { RemittanceDetailNoInvoice } from './remittance-detail-no-invoice-model';

@JsonObject('InvoiceNoShipment')
export class InvoiceNoShipment extends BaseObject {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('invoiceNumber', String, false) invoiceNumber: string = undefined;
  @JsonProperty('senderVatNumber', String, true) senderVatNumber: string = undefined;
  @JsonProperty('receiverVatNumber', String, true) receiverVatNumber: string = undefined;
  @JsonProperty('amazonStatus', String, true) amazonStatus: string = undefined;
  @JsonProperty('paymentTerms', String, true) paymentTerms: string = undefined;
  @JsonProperty('billToGln', String, true) billToGln: string = undefined;
  @JsonProperty('billToEntity', String, true) billToEntity: string = undefined;
  @JsonProperty('billToStreet', String, true) billToStreet: string = undefined;
  @JsonProperty('billToCity', String, true) billToCity: string = undefined;
  @JsonProperty('billToPostCode', String, true) billToPostCode: string = undefined;
  @JsonProperty('billToCountryCode', String, true) billToCountryCode: string = undefined;
  @JsonProperty('invoiceDate', String, true) invoiceDate: string = undefined;
  @JsonProperty('sendingDate', String, true) sendingDate: string = undefined;
  @JsonProperty('dueDate', String, true) dueDate: string = undefined;
  @JsonProperty('sentToAmazon', Boolean, true) sentToAmazon: boolean = undefined;
  @JsonProperty('probableNC', Boolean, true) probableNC: boolean = undefined;
  @JsonProperty('settlement', Boolean, true) settlement: boolean = undefined;
  @JsonProperty('manual', Boolean, true) manual: boolean = undefined;
  @JsonProperty('remittanceDetails', [RemittanceDetailNoInvoice], true) remittanceDetails: RemittanceDetailNoInvoice[] = undefined;
}
