// eslint-disable-next-line max-len
import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from 'json2typescript/index';
import { isNilty } from '../core/utils.service';
import { Shipment } from './shipment-model';

@JsonObject('ShipmentDocument')
export class ShipmentDocument {
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('label', String, true) label: string = undefined;
  @JsonProperty('urlProperty', String, true) urlProperty: string = undefined;

  available = true;
  selected = true;

  isAvailable(shipment: Shipment) {
    this.available = !isNilty(shipment[this.urlProperty]);
    if (!isNilty(shipment.origin.supplier.defaultDocuments)) {
      this.selected = this.available && shipment.origin.supplier.defaultDocuments.some((it) => it.name === this.name);
    } else {
      this.selected = this.available;
    }
  }
}

@JsonObject('SendShipmentDocumentInput')
export class SendShipmentDocumentInput {
  @JsonProperty('shipmentId', Number, true) shipmentId: number = undefined;
  @JsonProperty('documents', [String], true) documents: string[] = undefined;
}

@JsonConverter
export class ShipmentDocumentConverter implements JsonCustomConvert<ShipmentDocument[]> {
  jsonConvert: JsonConvert;

  constructor() {
    this.jsonConvert = new JsonConvert();
  }

  serialize(documents: ShipmentDocument[]): string[] {
    return documents.map((it) => it.name);
  }

  deserialize(value: string): ShipmentDocument[] {
    return this.jsonConvert.deserialize(value, ShipmentDocument) as ShipmentDocument[];
  }
}
