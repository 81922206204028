import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-incoming-date-modal',
  templateUrl: './incoming-date-modal.component.html',
  styleUrls: ['./incoming-date-modal.component.css'],
})
export class IncomingDateModalComponent {
  constructor(public dialogRef: MatDialogRef<IncomingDateModalComponent>) {}

  newIncomingDate: Date;

  onClose() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close(this.newIncomingDate);
  }
}
