<mat-toolbar class="lot-edit-bulk-actions">
  <div class="container">
    <div class="row">
      <div class="col-xs-10"></div>

      <div class="col-xs-2 text-right">
        <button
          [disabled]="!bulkActionsActive"
          [matMenuTriggerFor]="exportPoItemsChoice"
          class="box-shadow-none btn-custom"
          style="float: right;"
        >
          Export PO-Items
        </button>
        <mat-menu #exportPoItemsChoice="matMenu">
          <button mat-menu-item (click)="exportPoItems('excel')">Save as Excel</button>
          <button mat-menu-item (click)="exportPoItems('csv')">Save as CSV</button>
        </mat-menu>
      </div>
    </div>
  </div>
</mat-toolbar>
