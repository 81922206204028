import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthorizationService } from '../core/authorization.service';
import { map } from 'rxjs/operators';
import { Permissions } from '../models/permission-model';

@Injectable()
export class AdminAuthGuardService implements CanActivate {
  constructor(private router: Router, private authorizationService: AuthorizationService) {}

  canActivate() {
    return this.authorizationService.hasPermissions(
        [Permissions.Configuration, Permissions.Administration, Permissions.VendorAccountManagement]
      ).pipe(
      map((admin) => {
        if (admin) {
          return true;
        } else {
          this.router.navigate(['/']);
          return false;
        }
      })
    );
  }
}
