import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';
import { Category } from './category-model';
import { Contract } from './contract.model';
import { DateConverter } from './date-converter';
import { InvoiceNoShipment } from './invoice-no-shipment-model';
import { Lot } from './lot-model';
import { Status, StatusConverter } from './status-model';
import { StockLocation } from './stock-location-model';
import { SupplierClaim } from './supplier-claim-model';
import { Vendor } from './vendor-model';
import { Warehouse } from './warehouse-model';

@JsonObject('ShipmentLot')
export class ShipmentLot {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('name', String, false) name: string = undefined;
}

@JsonObject('ShipmentStatus')
export class ShipmentStatus {
  @JsonProperty('code', String, true) code: string = undefined;
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('value', Number, true) value: number = undefined;
}

@JsonObject('Shipment')
export class Shipment extends BaseObject {
  @JsonProperty('id', Number, false) id: number = undefined;
  @JsonProperty('shipmentCode', String, false) shipmentCode: string = undefined;
  @JsonProperty('orderId', String, true) orderId: string = undefined;
  @JsonProperty('origin', StockLocation, false)
  origin: StockLocation = undefined;
  @JsonProperty('destination', Warehouse, false)
  destination: Warehouse = undefined;
  @JsonProperty('shipmentDate', String, false) shipmentDate: string = undefined;
  @JsonProperty('status', ShipmentStatus, false) status: ShipmentStatus = undefined;
  @JsonProperty('paymentStatus', StatusConverter, false)
  paymentStatus: Status = undefined;
  @JsonProperty('backorder', Boolean, true) backorder: boolean = undefined;

  @JsonProperty('lots', [Lot], true) lots: Lot[] = undefined;
  @JsonProperty('invoice', InvoiceNoShipment, true)
  invoice: InvoiceNoShipment = undefined;

  @JsonProperty('latestReadyDate', String, true) latestReadyDate: string = undefined;
  @JsonProperty('earliestDeliveryDate', String, true) earliestDeliveryDate: string = undefined;
  @JsonProperty('latestDeliveryDate', String, true) latestDeliveryDate: string = undefined;
  @JsonProperty('latestPickupDate', String, true) latestPickupDate: string = undefined;
  @JsonProperty('asnCreationDate', String, true) asnCreationDate: string = undefined;

  @JsonProperty('week', Number, true) week: number = undefined;
  @JsonProperty('isExtra', Boolean, true) isExtra: boolean = undefined;
  @JsonProperty('crossUkBorder', Boolean, true) crossUkBorder: boolean = undefined;

  @JsonProperty('category', Category, true) category: Category = undefined;

  @JsonProperty('totalSubmittedQuantity', Number, true)
  totalSubmittedQuantity: number = undefined;
  @JsonProperty('totalSubmittedValue', Number, true)
  totalSubmittedValue: number = undefined;

  @JsonProperty('totalRequestedQuantity', Number, true)
  totalRequestedQuantity: number = undefined;
  @JsonProperty('totalRequestedValue', Number, true)
  totalRequestedValue: number = undefined;

  @JsonProperty('totalAcceptedQuantity', Number, true)
  totalAcceptedQuantity: number = undefined;
  @JsonProperty('totalAcceptedValue', Number, true)
  totalAcceptedValue: number = undefined;
  @JsonProperty('totalAcceptedPurchasePrice', Number, true)
  totalAcceptedPurchasePrice: number = undefined;
  @JsonProperty('totalVirtualAcceptedPurchasePrice', Number, true)
  totalVirtualAcceptedPurchasePrice: number = undefined;

  @JsonProperty('totalShippedQuantity', Number, true)
  totalShippedQuantity: number = undefined;
  @JsonProperty('totalShippedValue', Number, true)
  totalShippedValue: number = undefined;
  @JsonProperty('totalPurchasePrice', Number, true)
  totalPurchasePrice: number = undefined;
  @JsonProperty('totalVirtualPurchasePrice', Number, true)
  totalVirtualPurchasePrice: number = undefined;

  @JsonProperty('margin', Number, true) margin: number = undefined;
  @JsonProperty('percentMargin', Number, true) percentMargin: number = undefined;
  @JsonProperty('virtualMargin', Number, true) virtualMargin: number = undefined;
  @JsonProperty('virtualPercentMargin', Number, true) virtualPercentMargin: number = undefined;

  @JsonProperty('numberOfPallets', Number, true)
  numberOfPallets: number = undefined;
  @JsonProperty('numberOfCartons', Number, true)
  numberOfCartons: number = undefined;
  @JsonProperty('weight', Number, true) weight: number = undefined;

  @JsonProperty('asnCode', String, true) asnCode: string = undefined;
  @JsonProperty('sentToNav', Boolean, true) sentToNav: boolean = undefined;
  @JsonProperty('statusHandledByBridget', Boolean, true) statusHandledByBridget: boolean = undefined;

  @JsonProperty('requestForPayment', Boolean, true)
  requestForPayment: boolean = undefined;
  @JsonProperty('requestForPaymentDate', String, true)
  requestForPaymentDate: string = undefined;
  @JsonProperty('payment', Boolean, true) payment: boolean = undefined;
  @JsonProperty('paymentDate', String, true) paymentDate: string = undefined;

  @JsonProperty('importedPackingList', Boolean, true)
  importedPackingList: boolean = undefined;

  @JsonProperty('ddtNumber', String, true) ddtNumber: string = undefined;
  @JsonProperty('ddtXlsxS3Url', String, true) ddtXlsxS3Url: string = undefined;
  @JsonProperty('ddtPdfS3Url', String, true) ddtPdfS3Url: string = undefined;

  @JsonProperty('contract', Contract, true) contract: Contract = undefined;
  @JsonProperty('carrierName', String, true) carrierName: string = undefined;
  @JsonProperty('trackingNumber', String, true) trackingNumber: string = undefined;
  @JsonProperty('expectedDeliveryDate', DateConverter, true) expectedDeliveryDate: Date = undefined;
  @JsonProperty('deliveryDate', DateConverter, true) deliveryDate: Date = undefined;
  @JsonProperty('incomingDate', DateConverter, true) incomingDate: Date = undefined;

  @JsonProperty('vendor', Vendor, true) vendor: Vendor = undefined;
  @JsonProperty('sentAsn', Boolean, true) sentAsn: boolean = undefined;
  @JsonProperty('labelUrl', String, true) labelUrl: string = undefined;
  @JsonProperty('transitWarehouse', StockLocation, true)
  transitWarehouse: StockLocation = undefined;

  @JsonProperty('podUrls', [String], true) podUrls: string[] = undefined;
  @JsonProperty('preparationDays', Number, true) preparationDays: number = undefined;
  @JsonProperty('cartonsXlsxUrl', String, true) cartonsXlsxUrl: string = undefined;
  @JsonProperty('cartonsCsvUrl', String, true) cartonsCsvUrl: string = undefined;
  @JsonProperty('packingListUrl', String, true) packingListUrl: string = undefined;

  @JsonProperty('ukIntercoInvoice', Boolean, true) ukIntercoInvoice: boolean = undefined;
  @JsonProperty('ukIntercoInvoiceValidData', Boolean, true) ukIntercoInvoiceValidData: boolean = undefined;
  @JsonProperty('ukIntercoInvoiceStatus', StatusConverter, true) ukIntercoInvoiceStatus: Status = undefined;
  @JsonProperty('ukIntercoInvoiceNumber', String, true) ukIntercoInvoiceNumber: string = undefined;

  @JsonProperty('statusCheck', String, true) statusCheck: string = undefined;
  @JsonProperty('documentsSentToSupplier', Boolean, true) documentsSentToSupplier: boolean = undefined;

  @JsonProperty('supplierClaims', [SupplierClaim], true) supplierClaims: SupplierClaim[] = undefined;

  @JsonProperty('outboundStatus', String, true) outboundStatus: string = undefined;
  @JsonProperty('logisticPartner', String, true) logisticPartner: string = undefined;
  @JsonProperty('automaticManagement', Boolean, true) automaticManagement: boolean = undefined;
  @JsonProperty('isDirect', Boolean, true) isDirect: boolean = undefined;
}

@JsonObject('ChangeCarrierPropertiesInput')
export class ChangeCarrierPropertiesInput {
  @JsonProperty('shipmentId', Number, true) shipmentId: number = undefined;
  @JsonProperty('contract', Contract, true) contract: Contract = undefined;
  @JsonProperty('trackingNumber', String, true) trackingNumber: string = undefined;
  @JsonProperty('expectedDeliveryDate', DateConverter, true) expectedDeliveryDate: Date = undefined;
  @JsonProperty('deliveryDate', DateConverter, true) deliveryDate: Date = undefined;
  @JsonProperty('incomingDate', DateConverter, true) incomingDate: Date = undefined;
  @JsonProperty('preparationDays', Number, true) preparationDays: number = undefined;
}
@JsonObject('checkTrackingNumberInput')
export class CheckTrackingNumberInput {
  @JsonProperty('shipmentId', Number, true) shipmentId: number = undefined;
  @JsonProperty('trackingNumber', String, true) trackingNumber: string = undefined;

  constructor(shipmentId: number, trackingNumber: string) {
    this.shipmentId = shipmentId;
    this.trackingNumber = trackingNumber;
  }
}
