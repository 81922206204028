import { JsonObject, JsonProperty } from 'json2typescript';
import { JobExecutionStatus } from './job-execution-status';

export enum OrderedFromSpoGenerationStep {
  STARTING = 'STARTING',
  FETCHING_DESTINATIONS = 'FETCHING_DESTINATIONS',
  OPTIMIZING_DESTINATIONS = 'OPTIMIZING_DESTINATIONS',
  UPDATING_POS = 'UPDATING_POS',
}

export enum DestinationOptimizationStatus {
  FETCHING_SPO_ITEMS = 'FETCHING_SPO_ITEMS',
  OPTIMIZING = 'OPTIMIZING',
  UPDATING_SPOS = 'UPDATING_SPOS',
}

@JsonObject('GenerateOrderedFromSpoJobStatus')
export class GenerateOrderedFromSpoJobStatus extends JobExecutionStatus {
  @JsonProperty('generationStatus', String, true) generationStatus: string = undefined;
  @JsonProperty('totalDestinations', Number, true) totalDestinations: number = undefined;
  @JsonProperty('destinationIndex', Number, true) destinationIndex: number = undefined;
  @JsonProperty('destinationOptimizationStatus', String, true) destinationOptimizationStatus: string = undefined;
}
