import { VendorCentralAccountsService } from './../../../../configuration/vendor-central-accounts/vendor-central-accounts.service';
import { Component, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatChipsData } from '@models/mat-chips-data-model';
import { Subject } from 'rxjs';
import { isNilty } from '@core/utils.service';
import { Status } from '@models/status-model';
import { DisputeBotLogService } from '../dispute-bot-log.service';
import { DisputeBotLogFilters, disputeBotLogFiltersMap } from '@models/filters/dispute-bot-filters-model';
import { VendorCentralAccount } from '@models/vendor-central-account-model';

@Component({
  selector: 'app-dispute-bot-logs-filters',
  templateUrl: './dispute-bot-logs-filters.component.html',
  styleUrls: ['./dispute-bot-logs-filters.component.css'],
})
export class DisputeBotLogsFiltersComponent implements OnInit {
  constructor(private disputeBotLogService: DisputeBotLogService, private vendorCentralAccountsService: VendorCentralAccountsService) {}

  filterForm: FormGroup;
  public openFilter = false;

  filtersMap: MatChipsData[] = [];
  vendorAccounts: VendorCentralAccount[] = [];
  actionsType: Status[] = [];
  statusType: Status[] = [];

  @Output() onFilter = new Subject<DisputeBotLogFilters>();

  ngOnInit(): void {
    this.filterForm = new FormGroup({
      vendorCentralAccount: new FormControl(),
      invoiceNumber: new FormControl(),
      dateFrom: new FormControl(),
      dateTo: new FormControl(),
      actionType: new FormControl(),
      statusType: new FormControl(),
      disputeId: new FormControl(),
      caseId: new FormControl(),
    });

    if (isNilty(this.disputeBotLogService.disputeBotLogFilters)) {
      this.filterForm.patchValue(this.disputeBotLogService.disputeBotLogFilters);
      this.updateFiltersMap();
    }

    this.vendorCentralAccountsService
      .getVendorAccountAccounts()
      .subscribe((vendorAccounts: VendorCentralAccount[]) => (this.vendorAccounts = vendorAccounts));
    this.disputeBotLogService.getDisputeBotLogActionsType().subscribe((actions: Status[]) => (this.actionsType = actions));
    this.disputeBotLogService.getDisputeBotLogStatusType().subscribe((status: Status[]) => (this.statusType = status));
  }

  onSubmitFilter() {
    this.saveFiltersToService();
    this.updateFiltersMap();
    this.onFilter.next(this.disputeBotLogService.disputeBotLogFilters);
  }

  resetFilters() {
    this.filterForm.reset();
    this.saveFiltersToService();
    this.updateFiltersMap();
    this.onFilter.next(this.disputeBotLogService.disputeBotLogFilters);
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeevent() {
    this.openFilter = false;
  }

  saveFiltersToService() {
    this.disputeBotLogService.disputeBotLogFilters.invoiceNumber = this.filterForm.value.invoiceNumber;
    this.disputeBotLogService.disputeBotLogFilters.vendorCentralAccount = !isNilty(this.filterForm.controls.vendorCentralAccount.value)
      ? this.filterForm.controls.vendorCentralAccount.value.name
      : null;
    this.disputeBotLogService.disputeBotLogFilters.dateFrom = this.filterForm.value.dateFrom;
    this.disputeBotLogService.disputeBotLogFilters.dateTo = this.filterForm.value.dateTo;
    this.disputeBotLogService.disputeBotLogFilters.actionType = this.filterForm.value.actionType;
    this.disputeBotLogService.disputeBotLogFilters.statusType = this.filterForm.value.statusType;
    this.disputeBotLogService.disputeBotLogFilters.disputeId = this.filterForm.value.disputeId;
    this.disputeBotLogService.disputeBotLogFilters.caseId = this.filterForm.value.caseId;
  }

  updateFiltersMap() {
    this.filtersMap = disputeBotLogFiltersMap(
      this.filterForm.value.vendorCentralAccount,
      this.filterForm.value.invoiceNumber,
      this.filterForm.value.dateFrom,
      this.filterForm.value.dateTo,
      this.filterForm.value.actionType,
      this.filterForm.value.statusType,
      this.filterForm.value.disputeId,
      this.filterForm.value.caseId
    );
  }
}
