<ng-template #lastStepTimer>
  <div class="col-md-3 text-right">{{ lastStepMinutes }}:{{ lastStepSeconds }}</div>
</ng-template>

<button class="btn-lot-edit-menu" [matMenuTriggerFor]="inProgress">
  <i
    class="fa fa-spinner margin-right-6"
    [ngStyle]="{ transform: iconRotation }"
    aria-hidden="true"
    *ngIf="jobStatus?.status !== JOB_STATUS.TROUBLE"
  ></i>
  <i class="fa fa-exclamation-triangle" aria-hidden="true" *ngIf="jobStatus?.status === JOB_STATUS.TROUBLE"></i>
  Ordered From LSA
</button>
<mat-menu class="in-progress-mat-menu" #inProgress="matMenu">
  <ng-container *ngIf="!jobStatus">
    <div class="col-md-8 status"><h4>Waiting for worker response</h4></div>
  </ng-container>
  <ng-container *ngIf="jobStatus">
    <div class="container progress-content">
      <div class="row">
        <div class="col-md-12 title">ORDERED FROM LSA</div>
        <div class="col-md-12">Started at: {{ jobStatus?.startedAt }}</div>
      </div>

      <div class="row padding-top-10" *ngIf="jobStatus.status === JOB_STATUS.TROUBLE">
        <div class="col-md-8 trouble">The job seems to be stuck!</div>
        <div class="col-md-8 trouble padding-top-10" *ngIf="stuckRetry">
          <button class="btn-product" (click)="retry()">Retry</button>
        </div>
      </div>

      <div class="row padding-top-10" *ngIf="jobStatus.status === JOB_STATUS.STARTING">
        <div class="col-md-12">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="col-md-8 status">STARTING</div>
      </div>

      <div class="row padding-top-10" *ngIf="jobStatus.status === JOB_STATUS.RUNNING">
        <ng-container [ngSwitch]="jobStatus.generationStatus">
          <ng-container *ngSwitchCase="GENERATION_STEP.STARTING">
            <app-indeterminate-job-status [currentStepIndex]="1" [totalSteps]="TOTAL_STEPS" label="Starting"></app-indeterminate-job-status>
            <ng-container *ngTemplateOutlet="lastStepTimer"></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="GENERATION_STEP.FETCHING_SPO_ITEMS">
            <app-indeterminate-job-status
              [currentStepIndex]="2"
              [totalSteps]="TOTAL_STEPS"
              label="Fetching SpoItems"
            ></app-indeterminate-job-status>
            <ng-container *ngTemplateOutlet="lastStepTimer"></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="GENERATION_STEP.RETRIEVING_COMMODITIES">
            <div class="col-md-12">
              <mat-progress-bar [value]="(jobStatus.retrievedCommodities / jobStatus.totalSpoItems) * 100"></mat-progress-bar>
            </div>
            <div class="col-md-9 status">Retrieving commodities (3/{{ TOTAL_STEPS }})</div>
            <ng-container *ngTemplateOutlet="lastStepTimer"></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="GENERATION_STEP.PREPARING_MODEL">
            <app-indeterminate-job-status
              [currentStepIndex]="4"
              [totalSteps]="TOTAL_STEPS"
              label="Preparing Model"
            ></app-indeterminate-job-status>
            <ng-container *ngTemplateOutlet="lastStepTimer"></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="GENERATION_STEP.OPTIMIZING">
            <app-indeterminate-job-status
              [currentStepIndex]="5"
              [totalSteps]="TOTAL_STEPS"
              label="Optimizing"
            ></app-indeterminate-job-status>
            <ng-container *ngTemplateOutlet="lastStepTimer"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="GENERATION_STEP.MAXIMIZATION_EXECUTED">
            <app-indeterminate-job-status
              [currentStepIndex]="6"
              [totalSteps]="TOTAL_STEPS"
              label="Maximization Executed"
            ></app-indeterminate-job-status>
            <ng-container *ngTemplateOutlet="lastStepTimer"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="GENERATION_STEP.UPDATING_SPOS">
            <app-indeterminate-job-status
              [currentStepIndex]="7"
              [totalSteps]="TOTAL_STEPS"
              label="Updating SPOs"
            ></app-indeterminate-job-status>
            <ng-container *ngTemplateOutlet="lastStepTimer"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="GENERATION_STEP.UPDATING_CATALOGS">
            <app-indeterminate-job-status
              [currentStepIndex]="8"
              [totalSteps]="TOTAL_STEPS"
              label="Updating Catalogs"
            ></app-indeterminate-job-status>
            <ng-container *ngTemplateOutlet="lastStepTimer"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="GENERATION_STEP.UPDATING_POS">
            <app-indeterminate-job-status
              [currentStepIndex]="9"
              [totalSteps]="TOTAL_STEPS"
              label="Updating POs"
            ></app-indeterminate-job-status>
            <ng-container *ngTemplateOutlet="lastStepTimer"></ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</mat-menu>
