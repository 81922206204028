import { Component, OnInit } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { AuthorizationService } from '../../../core/authorization.service';
import { Permissions } from '../../../models/permission-model';

@Component({
  selector: 'app-configuration-menu',
  templateUrl: './configuration-menu.component.html',
  styleUrls: ['./configuration-menu.component.css'],
})
export class ConfigurationMenuComponent implements OnInit {
  subscription: Subscription;
  hasAdministration: Observable<boolean>;
  hasConfiguration: Observable<boolean>;
  hasVendorAdministration: Observable<boolean>;

  constructor(private authorizationService: AuthorizationService) {}

  ngOnInit() {
    this.hasAdministration = this.authorizationService.hasPermission(Permissions.Administration);
    this.hasConfiguration = this.authorizationService.hasPermission(Permissions.Configuration);
    this.hasVendorAdministration = this.authorizationService.hasPermissions(
      [Permissions.Administration, Permissions.VendorAccountManagement]
    );
  }
}
