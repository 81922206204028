import { Component, OnInit } from '@angular/core';
import { LotsService } from '../../../../shared/lots.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Lot } from '../../../../models/lot-model';
import { FulfilmentService } from '../../../../shared/fulfilment.service';
import { FulfilmentKpi } from '../../../../models/fulfilment-kpi';

@Component({
  selector: 'app-warehouse-fulfilment-kpi',
  templateUrl: './warehouse-fulfilment-kpi.component.html',
  styleUrls: ['./warehouse-fulfilment-kpi.component.css'],
})
export class WarehouseFulfilmentKpiComponent implements OnInit {
  constructor(private lotsService: LotsService, private router: Router, private fulfilmentService: FulfilmentService) {}

  currentLot: Observable<Lot>;

  kpiData: Observable<FulfilmentKpi>;

  ngOnInit() {
    this.kpiData = this.fulfilmentService.kpiData;
    this.currentLot = this.lotsService.lotSubject;
  }

  goBack() {
    this.router.navigate(['/lots']);
  }
}
