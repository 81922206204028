import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { isNilty } from '../../../core/utils.service';
import { InvoiceFilters } from '../../../models/filters/invoice-filters-model';
import { Invoice } from '../../../models/invoice-model';
import { addCheckers, onSelectedChecker } from '../../../shared/checkers.service';
import { InvoiceService } from './invoice.service';

@Component({
  selector: 'app-main-invoices',
  templateUrl: './main-invoices.component.html',
  styleUrls: ['./main-invoices.component.css'],
})
export class MainInvoicesComponent implements OnInit {
  dataSource: MatTableDataSource<Invoice>;

  displayedColumns = [
    'check',
    'invoiceNumber',
    'invoiceDate',
    'asnCode',
    'shipmentCode',
    'supplier',
    'destination',
    'vendor',
    'senderVatNumber',
    'receiverVatNumber',
    'amazonStatus',
    'paymentTerms',
    'billToEntity',
    'billToCity',
    'billToCountryCode',
    'sendingDate',
    'dueDate',
    'remittanceNumber',
    'remittanceDate',
    'sentToAmazon',
    'probableNC',
  ];

  mainChecker = false;

  bulkActionsActive = false;

  dataInvoices: any[] = [];

  invoice: Invoice = new Invoice();

  dataInvoiceItem: any[];
  selectedInvoiceItems: number[] = [];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private invoiceService: InvoiceService, private snackBar: MatSnackBar, private dialog: MatDialog) {}

  ngOnInit() {
    this.resultsNumber = this.invoiceService.resultsNumber;

    if (isNilty(this.invoiceService.invoiceFilters)) {
      this.invoiceService.invoiceFilters = new InvoiceFilters();
    }

    this.resetCheckers();
    this.dataSource = new MatTableDataSource([]);
    this.loadInvoices();
  }

  loadInvoices() {
    this.invoiceService.getFilteredInvoices(this.invoiceService.invoiceFilters).subscribe((inv: Invoice[]) => {
      this.dataInvoices = addCheckers(inv, this.invoiceService.selectedInvoices, this.mainChecker);
      this.dataSource.data = this.dataInvoices;
    });
  }

  loadPage() {
    this.invoiceService.invoiceFilters.pageNumber = this.paginator.pageIndex;
    this.invoiceService.invoiceFilters.pageSize = this.paginator.pageSize;
    this.loadInvoices();
  }

  onSort() {
    this.invoiceService.invoiceFilters.sortBy = this.sort.active;
    this.invoiceService.invoiceFilters.sortDirection = this.sort.direction;
    this.loadInvoices();
  }

  onSelected(value: boolean, selectedSpo: any) {
    this.bulkActionsActive = onSelectedChecker(
      value,
      selectedSpo,
      this.dataInvoices,
      this.mainChecker,
      this.invoiceService.selectedInvoices
    );
  }

  toggleAll(value: boolean) {
    this.mainChecker = value;
    for (const i of this.dataInvoices) {
      i.checked = value;
    }

    this.invoiceService.selectedInvoices = [];
    this.bulkActionsActive = value;
  }

  onFilter(filters: InvoiceFilters) {
    this.resetCheckers();
    this.paginator.pageIndex = 0;
    this.invoiceService.invoiceFilters = filters;
    this.loadInvoices();
  }

  resetCheckers() {
    this.invoiceService.selectedInvoices = [];
    this.mainChecker = false;
    this.bulkActionsActive = false;
  }

  sendInvoices() {
    if (this.mainChecker) {
      this.invoiceService.sendInvoices(this.invoiceService.selectedInvoices, this.invoiceService.invoiceFilters, this.mainChecker);
    } else {
      this.invoiceService
        .sendInvoices(this.invoiceService.selectedInvoices, this.invoiceService.invoiceFilters, this.mainChecker)
        .subscribe(() => {
          this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
          return 'Done';
        });
    }
  }

  getRemittanceNumber(invoice: Invoice) {
    const remittanceDetail = invoice.remittanceDetails.find((rd) => rd.code === 'FT VEND');
    if (remittanceDetail) {
      return remittanceDetail.remittance.paymentNumber;
    }
    return null;
  }

  getRemittanceDate(invoice: Invoice) {
    const remittanceDetail = invoice.remittanceDetails.find((rd) => rd.code === 'FT VEND');
    if (remittanceDetail) {
      return remittanceDetail.remittance.paymentDate;
    }
    return null;
  }
}
