import { Component, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { isNilty } from '../../../../core/utils.service';
import { InvoiceFilters, invoiceFiltersMap } from '../../../../models/filters/invoice-filters-model';
import { MatChipsData } from '../../../../models/mat-chips-data-model';
import { Vendor } from '../../../../models/vendor-model';
import { VendorsService } from '../../../configuration/vendors/vendors.service';
import { InvoiceService } from '../invoice.service';

@Component({
  selector: 'app-main-invoices-filters',
  templateUrl: './main-invoices-filters.component.html',
  styleUrls: ['./main-invoices-filters.component.css'],
})
export class MainInvoicesFiltersComponent implements OnInit {
  constructor(private vendorsService: VendorsService, private invoiceService: InvoiceService) {}

  vendors: Vendor[] = [];

  @Output() onFilter = new Subject<InvoiceFilters>();

  filterForm: FormGroup;

  public openFilter = false;

  filtersMap: MatChipsData[] = [];

  countries = ['BE', 'DE', 'ES', 'FR', 'GB', 'IT', 'NL', 'PL', 'SE', 'UK'];

  ngOnInit() {
    this.filterForm = new FormGroup({
      vendor: new FormControl(),
      vendorCountry: new FormControl(),
      asn: new FormControl(),
      po: new FormControl(),
      invoiceNumber: new FormControl(),
      dueDate: new FormControl(),
      remittanceNumber: new FormControl(),
      remittanceDateStart: new FormControl(),
      remittanceDateEnd: new FormControl(),
      sentToAmazon: new FormControl(),
      hasProbableNC: new FormControl(),
      pastDueDate: new FormControl(),
      ean: new FormControl(),
      orderId: new FormControl()
    });

    if (isNilty(this.invoiceService.invoiceFilters)) {
      this.filterForm.patchValue(this.invoiceService.invoiceFilters);
    }

    this.vendorsService.getVendors().subscribe((vendorsResp: Vendor[]) => {
      this.vendors = vendorsResp;
      this.setVendor();
    });
  }

  setVendor() {
    if (this.invoiceService.invoiceFilters !== null && this.invoiceService.invoiceFilters.vendor !== null) {
      this.filterForm.controls['vendor'].patchValue(this.vendors.find((it) => it.id === this.invoiceService.invoiceFilters.vendor));
      this.updateFiltersMap();
    }
  }

  saveFiltersToService() {
    this.invoiceService.invoiceFilters.vendorCountry = this.filterForm.controls.vendorCountry.value;
    this.invoiceService.invoiceFilters.vendor = !isNilty(this.filterForm.controls.vendor.value)
      ? this.filterForm.controls.vendor.value.id
      : undefined;
    this.invoiceService.invoiceFilters.asn = this.filterForm.controls.asn.value;
    this.invoiceService.invoiceFilters.po = this.filterForm.controls.po.value;
    this.invoiceService.invoiceFilters.invoiceNumber = this.filterForm.controls.invoiceNumber.value;
    this.invoiceService.invoiceFilters.dueDate = this.filterForm.controls.dueDate.value;
    this.invoiceService.invoiceFilters.remittanceNumber = this.filterForm.controls.remittanceNumber.value;
    this.invoiceService.invoiceFilters.remittanceDateStart = this.filterForm.controls.remittanceDateStart.value;
    this.invoiceService.invoiceFilters.remittanceDateEnd = this.filterForm.controls.remittanceDateEnd.value;
    this.invoiceService.invoiceFilters.sentToAmazon = this.filterForm.controls.sentToAmazon.value;
    this.invoiceService.invoiceFilters.hasProbableNC = this.filterForm.controls.hasProbableNC.value;
    this.invoiceService.invoiceFilters.pastDueDate = this.filterForm.controls.pastDueDate.value;
    this.invoiceService.invoiceFilters.ean = this.filterForm.controls.ean.value;
    this.invoiceService.invoiceFilters.orderId = this.filterForm.controls.orderId.value;
  }

  onSubmitFilter() {
    this.saveFiltersToService();
    this.onFilter.next(this.invoiceService.invoiceFilters);
    this.updateFiltersMap();
  }

  resetFilters() {
    this.filterForm.reset();
    this.invoiceService.invoiceFilters = new InvoiceFilters();
    this.onFilter.next(this.invoiceService.invoiceFilters);
    this.updateFiltersMap();
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeevent() {
    this.openFilter = false;
  }

  updateFiltersMap() {
    this.filtersMap = invoiceFiltersMap(
      this.invoiceService.invoiceFilters.vendorCountry,
      this.filterForm.controls.vendor.value,
      this.invoiceService.invoiceFilters.asn,
      this.invoiceService.invoiceFilters.po,
      this.invoiceService.invoiceFilters.invoiceNumber,
      this.invoiceService.invoiceFilters.dueDate,
      this.invoiceService.invoiceFilters.remittanceNumber,
      this.invoiceService.invoiceFilters.remittanceDateStart,
      this.invoiceService.invoiceFilters.remittanceDateEnd,
      this.invoiceService.invoiceFilters.sentToAmazon,
      this.invoiceService.invoiceFilters.hasProbableNC,
      this.invoiceService.invoiceFilters.pastDueDate,
      this.invoiceService.invoiceFilters.ean,
      this.invoiceService.invoiceFilters.orderId

    );
  }
}
