<app-single-shipment-detail-filters (onFilter)="applyFilters($event)"></app-single-shipment-detail-filters>

<div [ngClass]="{ 'is-not-in-lot': isNotInLot }">
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <hr class="divisor-title" style="width: calc(100% - 70rem)" />
        <div class="square" style="right: 70rem"></div>
        <h2 class="po-element-custom title-custom">Shipment: {{ shipment.shipmentCode }}</h2>
      </div>
    </div>
  </div>

  <app-single-shipment-detail-kpi [shipment]="shipment" [kpiData]="kpiData" (reload)="reload()"> </app-single-shipment-detail-kpi>

  <app-single-shipment-accordion [shipment]="shipment" (reload)="reload()"></app-single-shipment-accordion>

  <div class="padding-top-15">
    <mat-toolbar class="lot-edit-bulk-actions">
      <div class="container">
        <div class="row">
          <div class="col-md-2">
            <button class="box-shadow-none btn-custom actions-opener" [matMenuTriggerFor]="exportMenu">
              Export <i class="fa fa-angle-down margin-left-6" aria-hidden="true"></i>
            </button>

            <mat-menu #exportMenu="matMenu">
              <button mat-menu-item class="box-shadow-none" [matMenuTriggerFor]="exportShipmentItemsChoice">
                <i class="fa fa-list margin-right-6" aria-hidden="true"></i>Export Items
              </button>
              <mat-menu #exportShipmentItemsChoice="matMenu">
                <button mat-menu-item (click)="export('excel')">Save as Excel</button>
                <button mat-menu-item (click)="export('csv')">Save as CSV</button>
              </mat-menu>

              <button
                mat-menu-item
                class="box-shadow-none"
                [disabled]="!shipment.importedPackingList || !shipment.asnCode"
                [matMenuTriggerFor]="exportCartonsChoice"
              >
                <i class="fa fa-cube margin-right-6" aria-hidden="true"></i>Export cartons
              </button>
              <mat-menu #exportCartonsChoice="matMenu">
                <button mat-menu-item class="box-shadow-none" [matMenuTriggerFor]="exportCartonsSend">
                  <i class="fa fa-envelope margin-right-6" aria-hidden="true"></i>
                  Send
                </button>
                <mat-menu #exportCartonsSend="matMenu">
                  <button mat-menu-item (click)="exportCartons('excel')">Excel</button>
                  <button mat-menu-item (click)="exportCartons('csv')">Csv</button>
                </mat-menu>

                <button mat-menu-item class="box-shadow-none" [matMenuTriggerFor]="exportCartonsDownload">
                  <i class="fa fa-download margin-right-6" aria-hidden="true"></i>
                  Download
                </button>
                <mat-menu #exportCartonsDownload="matMenu">
                  <button mat-menu-item [disabled]="!shipment.cartonsXlsxUrl" (click)="downloadCartonsExcel()">Excel</button>
                  <button mat-menu-item [disabled]="!shipment.cartonsCsvUrl" (click)="downloadCartonsCsv()">Csv</button>
                </mat-menu>
              </mat-menu>

              <button
                mat-menu-item
                class="box-shadow-none"
                [disabled]="!shipment.importedPackingList"
                [matMenuTriggerFor]="exportCartonItemsChoice"
              >
                <i class="fa fa-cubes margin-right-6" aria-hidden="true"></i>Export carton items
              </button>
              <mat-menu #exportCartonItemsChoice="matMenu">
                <button mat-menu-item (click)="exportCartonItems('excel')">Save as Excel</button>
                <button mat-menu-item (click)="exportCartonItems('csv')">Save as CSV</button>
              </mat-menu>

              <button *ngIf="shipment.packingListUrl" mat-menu-item class="box-shadow-none" (click)="downloadPackingList()">
                <i class="fa fa-archive margin-right-6" aria-hidden="true"></i>Download packing list
              </button>

              <button *ngIf="shipment.asnCode" mat-menu-item class="box-shadow-none" (click)="downloadSSCCBarcodes()">
                <i class="fa fa-barcode margin-right-6" aria-hidden="true"></i>Download SSCC Barcodes
              </button>

              <button *ngIf="shipment.asnCode" mat-menu-item class="box-shadow-none" (click)="downloadLQLabels()">
                <i class="fa fa-barcode margin-right-6" aria-hidden="true"></i>Download LQ Labels
              </button>

              <button mat-menu-item class="box-shadow-none" (click)="downloadLabels()" *ngIf="shipment.labelUrl">
                <i class="fa fa-tags margin-right-6" aria-hidden="true"></i> Download labels
              </button>

              <button mat-menu-item class="box-shadow-none" (click)="downloadSSCCAndLDVLabels()" *ngIf="shipment.labelUrl">
                <i class="fa fa-tags margin-right-6" aria-hidden="true"></i> Download SSCC and LDV labels
              </button>

              <button mat-menu-item class="box-shadow-none" *ngIf="shipment.ukIntercoInvoiceNumber" [matMenuTriggerFor]="intercoMenu">
                <i class="fa fa-building margin-right-6" aria-hidden="true"></i>Interco
              </button>
              <mat-menu #intercoMenu="matMenu">
                <button *ngIf="shipment.ukIntercoInvoiceNumber" mat-menu-item class="box-shadow-none" (click)="exportIntercoInvoice()">
                  <i class="fa fa-download margin-right-6" aria-hidden="true"></i>
                  Download Interco Invoice
                </button>
                <button *ngIf="shipment.ukIntercoInvoiceNumber" mat-menu-item class="box-shadow-none" (click)="exportExportDeclaration()">
                  <i class="fa fa-download margin-right-6" aria-hidden="true"></i>
                  Download Export Declaration
                </button>
              </mat-menu>
            </mat-menu>
          </div>

          <div class="col-md-2">
            <button class="box-shadow-none btn-custom actions-opener" [matMenuTriggerFor]="manageShipmentMenu">
              Manage <i class="fa fa-angle-down margin-left-6" aria-hidden="true"></i>
            </button>

            <mat-menu #manageShipmentMenu="matMenu">
              <button
                *ngIf="((hasShipmentManagement | async) || (hasShipmentCreation | async)) && !shipment.category"
                mat-menu-item
                class="box-shadow-none"
                (click)="setCategory()"
              >
                Set Category
              </button>

              <button
                *ngIf="(hasShipmentManagement | async) || (hasCancelShipment | async)"
                [disabled]="shipment.statusHandledByBridget"
                mat-menu-item
                class="box-shadow-none"
                [matMenuTriggerFor]="changeStatusMenuNew"
              >
                Change status <i class="fa sel-icon" aria-hidden="true"></i>
              </button>
              <mat-menu #changeStatusMenuNew="matMenu">
                <button mat-menu-item *ngFor="let s of shipmentStatus" (click)="changeStatus(s.code)">{{ s.name }}</button>
              </mat-menu>

              <button
                *ngIf="hasChangeShipmentPaymentStatus | async"
                mat-menu-item
                class="box-shadow-none"
                [matMenuTriggerFor]="changePaymentStatusMenuNew"
              >
                Change payment status <i class="fa sel-icon" aria-hidden="true"></i>
              </button>
              <mat-menu #changePaymentStatusMenuNew="matMenu">
                <button mat-menu-item *ngFor="let s of shipmentPaymentStatus" (click)="changePaymentStatus(s.code)">
                  {{ s.description }}
                </button>
              </mat-menu>

              <button
                *ngIf="hasShipmentManagement | async"
                mat-menu-item
                class="box-shadow-none"
                [disabled]="shipment.importedPackingList"
                (click)="shipAll()"
              >
                <i class="fa fa-list-alt margin-right-6" aria-hidden="true"></i> Ship All
              </button>

              <button mat-menu-item class="box-shadow-none" *ngIf="isSendableToBridget" (click)="sendToBridget()">
                <i class="fa fa-truck fa-flip-horizontal margin-right-6" aria-hidden="true"></i> Ship via Bridget
              </button>

              <button mat-menu-item class="box-shadow-none" *ngIf="isDeletableFromBridget" (click)="deleteFromBridget()">
                <i class="fa fa-truck fa-flip-horizontal margin-right-6" aria-hidden="true"></i> Delete from Bridget
              </button>

              <button *ngIf="hasShipmentManagement | async" mat-menu-item class="box-shadow-none" (click)="resetShippedQuantities()">
                <i class="fa fa-undo margin-right-6" aria-hidden="true"></i> Restore Shipped
              </button>

              <button
                *ngIf="hasPackingListImport | async"
                mat-menu-item
                class="box-shadow-none"
                [disabled]="isShipped"
                (click)="importFile()"
              >
                <i class="fa fa-cubes margin-right-6" aria-hidden="true"></i> Import PackingList
              </button>

              <button
                *ngIf="(hasPackingListImport | async) && !shipment?.origin?.supplier?.sendSSCC"
                mat-menu-item
                class="box-shadow-none"
                [disabled]="isShipped"
                (click)="singleCarton()"
              >
                <i class="fa fa-cube margin-right-6" aria-hidden="true"></i> SingleCarton
              </button>

              <button *ngIf="hasShipmentManagement | async" mat-menu-item class="box-shadow-none" (click)="splitShipment()">
                <i class="fa fa-columns margin-right-6" aria-hidden="true"></i> Split Shipment
              </button>

              <button
                *ngIf="(hasInvoiceManagement | async) && canGenerateManualInvoice"
                mat-menu-item
                class="box-shadow-none"
                (click)="generateManualInvoice()"
              >
                <i class="fa fa-file margin-right-6" aria-hidden="true"></i> Generate manual invoice
              </button>

              <button
                *ngIf="showCreateUkIntercoInvoice() && (hasShipmentManagement | async)"
                mat-menu-item
                class="box-shadow-none"
                [disabled]="!shipment.ukIntercoInvoiceValidData"
                (click)="createUkIntercoInvoice()"
              >
                <i class="fa fa-columns margin-right-6" aria-hidden="true"></i> Create Uk Interco Invoice
              </button>

              <button *ngIf="!shipment.sentToNav && (hasResendToNav | async)" mat-menu-item class="box-shadow-none" (click)="sendToNav()">
                <i class="fa fa-repeat margin-right-6" aria-hidden="true"></i> Send to NAV
              </button>

              <button *ngIf="shipment.sentToNav && (hasResendToNav | async)" mat-menu-item class="box-shadow-none" (click)="resendToNav()">
                <i class="fa fa-repeat margin-right-6" aria-hidden="true"></i> Resend to NAV
              </button>

              <button
                *ngIf="shipment.logisticPartner == 'TWS' && shipment.outboundStatus != 'SENT' && (hasShipmentManagement | async)"
                mat-menu-item
                class="box-shadow-none"
                (click)="sendOutbound()"
              >
                <i class="fa fa-repeat margin-right-6" aria-hidden="true"></i> Force send outbound
              </button>

              <button
                *ngIf="!shipment.automaticManagement && (hasShipmentManagement | async)"
                mat-menu-item
                class="box-shadow-none"
                (click)="automaticManagementMarginDialog()"
              >
                <i class="fa fa-magic margin-right-6" aria-hidden="true"></i> Automatic Management
              </button>

              <input #getFile type="file" style="display: none" onclick="this.value = null" (change)="fileChanged($event)" />
            </mat-menu>
          </div>

          <div class="col-md-2">
            <button
              class="box-shadow-none btn-custom actions-opener"
              [matMenuTriggerFor]="manageASNMenu"
              [disabled]="!shipment.importedPackingList"
            >
              ASN <i class="fa fa-angle-down margin-left-6" aria-hidden="true"></i>
            </button>

            <mat-menu #manageASNMenu="matMenu">
              <button mat-menu-item class="box-shadow-none" (click)="generateAsnMarginDialog()">
                <i class="fa fa-paper-plane margin-right-6" aria-hidden="true"></i>{{ shipment.asnCode ? 'Send ASN' : 'Generate ASN' }}
              </button>

              <button mat-menu-item class="box-shadow-none" [disabled]="!shipment?.asnCode" (click)="printASNBarcode()">
                <i class="fa fa-barcode margin-right-6" aria-hidden="true"></i>Print ASN barcode
              </button>

              <button mat-menu-item class="box-shadow-none" [disabled]="!isCancelableAsn" (click)="cancelAsn()">
                <i class="fa fa-trash margin-right-6" aria-hidden="true"></i>Cancel ASN
              </button>
            </mat-menu>
          </div>

          <div class="col-md-2">
            <button
              class="box-shadow-none btn-custom actions-opener"
              [matMenuTriggerFor]="manageDDTMenu"
              [disabled]="
                shipment.numberOfCartons == null ||
                shipment.numberOfPallets == null ||
                shipment.weight == null ||
                shipment.asnCode == null ||
                shipment.asnCode == '' ||
                shipment.ddtNumber == null ||
                shipment.ddtNumber == ''
              "
            >
              DDT <i class="fa fa-angle-down margin-left-6" aria-hidden="true"></i>
            </button>

            <mat-menu #manageDDTMenu="matMenu">
              <button
                mat-menu-item
                class="box-shadow-none"
                [disabled]="
                  shipment.numberOfCartons == null ||
                  shipment.numberOfPallets == null ||
                  shipment.weight == null ||
                  shipment.asnCode == null ||
                  shipment.asnCode == '' ||
                  shipment.ddtNumber == null ||
                  shipment.ddtNumber == ''
                "
                (click)="generateDdt()"
              >
                <i class="fa fa-truck margin-right-6" aria-hidden="true"></i> Generate
              </button>

              <button
                mat-menu-item
                class="box-shadow-none"
                *ngIf="!(shipment.ddtXlsxS3Url | isNullOrUndefinedOrEmptyString)"
                [matMenuTriggerFor]="exportDdtChoice"
              >
                <i class="fa fa-envelope margin-right-6" aria-hidden="true"></i> Send
              </button>
              <mat-menu #exportDdtChoice="matMenu">
                <button mat-menu-item (click)="exportDdt('excel')">Excel</button>
                <button mat-menu-item [disabled]="shipment.ddtPdfS3Url == null || shipment.ddtPdfS3Url == ''" (click)="exportDdt('pdf')">
                  Pdf
                </button>
              </mat-menu>

              <button
                mat-menu-item
                class="box-shadow-none"
                *ngIf="
                  !(shipment.ddtXlsxS3Url | isNullOrUndefinedOrEmptyString) || !(shipment.ddtPdfS3Url | isNullOrUndefinedOrEmptyString)
                "
                [matMenuTriggerFor]="downloadDdtChoice"
              >
                <i class="fa fa-download margin-right-6" aria-hidden="true"></i> Download
              </button>
              <mat-menu #downloadDdtChoice="matMenu">
                <button mat-menu-item [disabled]="shipment.ddtXlsxS3Url == null || shipment.ddtXlsxS3Url == ''" (click)="printXlsx()">
                  Excel
                </button>
                <button mat-menu-item [disabled]="shipment.ddtPdfS3Url == null || shipment.ddtPdfS3Url == ''" (click)="printPdf()">
                  Pdf
                </button>
              </mat-menu>
            </mat-menu>
          </div>

          <div class="col-md-2">
            <button
              class="box-shadow-none btn-custom actions-opener"
              [disabled]="!shipment.importedPackingList || !shipment.contract || !shipment.sentAsn"
              (click)="sendToSupplier()"
            >
              <i class="fa fa-paper-plane margin-right-6" aria-hidden="true"></i>Send to Supplier
            </button>
          </div>

          <div class="col-md-2 text-right padding-left-0">
            <button class="refresh-btn margin-right-6" (click)="refresh()"><i class="fa fa-refresh" aria-hidden="true"></i></button>
            <button class="btn-custom back-button-small" (click)="goBack()" matTooltip="Back">
              <i class="fa fa-chevron-left margin-right-3" aria-hidden="true"></i> Back
            </button>
          </div>
        </div>
      </div>
    </mat-toolbar>
  </div>

  <mat-tab-group #cartonsMatGroup class="container" id="cartonsMatGroup">
    <mat-tab label="Shipment items">
      <div class="table-outer">
        <app-single-shipment-items-table
          [loadShipmentItems]="loadShipmentItems"
          [exportSubject]="exportSubject"
          [shipmentId]="shipmentId"
          [splitShipmentInput]="splitShipmentSubject"
          (refresh)="refresh()"
        >
        </app-single-shipment-items-table>
      </div>
    </mat-tab>
    <mat-tab label="Cartons">
      <div class="table-outer">
        <app-single-shipment-cartons
          [printBarcode]="!!shipment.asnCode"
          [loadCartons]="loadShipmentItems"
          [shipmentId]="shipmentId"
        ></app-single-shipment-cartons>
      </div>
    </mat-tab>
    <mat-tab label="Pallets" [disabled]="!shipment.numberOfPallets || shipment.numberOfPallets === 0">
      <div class="table-outer">
        <app-single-shipment-pallets [loadPallets]="loadShipmentItems" [shipmentId]="shipmentId"> </app-single-shipment-pallets>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
