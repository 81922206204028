import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lot-configurations',
  templateUrl: './lot-configurations.component.html',
  styleUrls: ['./lot-configurations.component.css'],
})
export class LotConfigurationsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
