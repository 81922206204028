<div [ngClass]="{ 'black-panel': openFilter, panel: !openFilter }">
  <mat-expansion-panel class="mat-elevation-z8 filter-position" [attr.expanded]="openFilter" (opened)="openEvent()" (closed)="closeevent()">
    <mat-expansion-panel-header class="filt-custom">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-9">
            <mat-chip-list>
              <mat-chip *ngFor="let f of filtersMap">{{ f.filter }}</mat-chip>
            </mat-chip-list>
          </div>
          <div class="col-md-2">
            <mat-panel-title class="filter-title"> Filters </mat-panel-title>
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>

    <div class="row filters-row">
      <form [formGroup]="filterForm" (submit)="onSubmitFilter()">
        <div class="col-md-12 text-blue margin-top-5 border-bottom-orange">
          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="invoiceNumber" placeholder="Invoice Number" />
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select filterKey="description" formControlName="vendor" id="vendor" placeholder="Vendor">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="vendor" *appMatSelectFor="let vendor of vendors">{{ vendor.description }}</mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <mat-select formControlName="vendorCountry" placeholder="Vendor Country">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option
                    *ngFor="let country of countries"
                    [value]="country"
                    style="font-family: 'Titillium Web', sans-serif !important; font-size: 12px"
                  >
                    {{ country }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="po" placeholder="PO" />
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="asn" placeholder="ASN" />
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="dueDate" [matDatepicker]="dueDate" placeholder="Due Date" />
                <mat-datepicker-toggle matSuffix [for]="dueDate"></mat-datepicker-toggle>
                <mat-datepicker #dueDate></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="remittanceNumber" placeholder="Remittance Number" />
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Remittance Date range</mat-label>
                <mat-date-range-input [rangePicker]="remittanceDate">
                  <input matStartDate formControlName="remittanceDateStart" placeholder="Start date" />
                  <input matEndDate formControlName="remittanceDateEnd" placeholder="End date" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="remittanceDate"></mat-datepicker-toggle>
                <mat-date-range-picker #remittanceDate></mat-date-range-picker>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <mat-select id="sentToAmazon" formControlName="sentToAmazon" placeholder="Sent to Amazon">
                  <mat-option [value]="null">All</mat-option>
                  <mat-option [value]="'true'">True</mat-option>
                  <mat-option [value]="'false'">False</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <mat-select id="hasProbableNC" formControlName="hasProbableNC" placeholder="Has Probable NC">
                  <mat-option [value]="null">All</mat-option>
                  <mat-option [value]="'true'">True</mat-option>
                  <mat-option [value]="'false'">False</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <mat-select id="pastDueDate" formControlName="pastDueDate" placeholder="Past Due Date">
                  <mat-option [value]="null">All</mat-option>
                  <mat-option [value]="'true'">True</mat-option>
                  <mat-option [value]="'false'">False</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>                
                <textarea matInput formControlName="ean" placeholder="EAN"></textarea>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <textarea matInput formControlName="orderId" placeholder="Order ID"></textarea>
              </mat-form-field>
            </div>

            <div class="col-md-1"></div>
          </div>

          <div class="col-md-12 border-top-white">
            <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-2 center">
                <button type="submit" class="box-shadow-none btn-custom" style="float: right">Filter</button>
              </div>
              <div class="col-md-2 center">
                <button type="button" (click)="resetFilters()" class="box-shadow-none btn-custom">Reset</button>
              </div>
              <div class="col-md-4"></div>
            </div>
          </div>
        </div>
      </form>

      <mat-divider></mat-divider>
    </div>
  </mat-expansion-panel>
</div>
