import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { PublicationStatusPipe } from '../../shared/my-pipes';
import { CarrierEditComponent } from './carriers/carrier-edit/carrier-edit.component';
import { CarriersListComponent } from './carriers/carriers-list/carriers-list.component';
import { CarriersComponent } from './carriers/carriers.component';
import { CarriersService } from './carriers/carriers.service';
import { InventoryMenuComponent } from './inventory-menu/inventory-menu.component';
import { InventoryRoutingModule } from './inventory-routing.module';
import { InventoryComponent } from './inventory.component';
import { ActivePublicationsTableComponent } from './products/product-details/active-publications-table/active-publications-table.component';
import { CatalogItemsTableComponent } from './products/product-details/catalog-items-table/catalog-items-table.component';
// eslint-disable-next-line
import { PriceHistoryTableComponent } from './products/product-details/catalog-items-table/price-history-table/price-history-table.component';
import { StockItemsTableComponent } from './products/product-details/catalog-items-table/stock-items-table/stock-items-table.component';
import { KeepaDetailsAccordionComponent } from './products/product-details/keepa-details-accordion/keepa-details-accordion.component';
import { LastPosTableComponent } from './products/product-details/last-pos-table/last-pos-table.component';
import { ProductDetailsComponent } from './products/product-details/product-details.component';
import { SupplierDiscountProductComponent } from './products/product-details/supplier-discount-product/supplier-discount-product.component';
import { ProductsEditComponent } from './products/products-edit/products-edit.component';
import { ProductsFiltersComponent } from './products/products-list/products-filters/products-filters.component';
import { ProductsListComponent } from './products/products-list/products-list.component';
import { ProductsComponent } from './products/products.component';
import { PublicationsFiltersComponent } from './publications/publications-filters/publications-filters.component';
import { PublicationsComponent } from './publications/publications.component';
import { StockItemsComponent } from './stock-items/stock-items.component';
import { StockLocationsEditComponent } from './stock-locations/stock-locations-edit/stock-locations-edit.component';
// eslint-disable-next-line
import { StockLocationsListFiltersComponent } from './stock-locations/stock-locations-list/stock-location-list-filters/stock-locations-list-filters.component';
import { StockLocationsListComponent } from './stock-locations/stock-locations-list/stock-locations-list.component';
import { StockLocationsComponent } from './stock-locations/stock-locations.component';
import { SupplierDiscountComponent } from './supplier-discount/supplier-discount.component';
import { SupplierProductsFiltersComponent } from './supplier-products/supplier-products-filters/supplier-products-filters.component';
import { SupplierProductsComponent } from './supplier-products/supplier-products.component';
import { MapEditComponent } from './suppliers/supplier-edit/map-edit/map-edit.component';
import { SupplierEditComponent } from './suppliers/supplier-edit/supplier-edit.component';
import { SuppliersBulkActionsComponent } from './suppliers/suppliers-list/suppliers-bulk-actions/suppliers-bulk-actions.component';
import { SuppliersListComponent } from './suppliers/suppliers-list/suppliers-list.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { ExtraQuantityModalComponent } from './products/extra-quantity-modal/extra-quantity-modal.component';
import { PublicationApprovedHistoryComponent } from './publications/publication-approved-history/publication-approved-history.component';

@NgModule({
  declarations: [
    InventoryComponent,
    InventoryMenuComponent,
    ProductsComponent,
    SuppliersComponent,
    StockLocationsComponent,
    SupplierProductsComponent,
    SupplierProductsFiltersComponent,
    ProductsFiltersComponent,
    SuppliersBulkActionsComponent,
    StockItemsComponent,
    PublicationsComponent,
    PublicationApprovedHistoryComponent,
    SuppliersListComponent,
    SupplierEditComponent,
    MapEditComponent,
    ProductsListComponent,
    ProductsEditComponent,
    PublicationsFiltersComponent,
    StockLocationsListComponent,
    StockLocationsListFiltersComponent,
    StockLocationsEditComponent,
    ProductDetailsComponent,
    CatalogItemsTableComponent,
    LastPosTableComponent,
    ActivePublicationsTableComponent,
    KeepaDetailsAccordionComponent,
    StockItemsTableComponent,
    PriceHistoryTableComponent,
    SupplierDiscountComponent,
    SupplierDiscountProductComponent,
    CarriersComponent,
    CarriersListComponent,
    CarrierEditComponent,
    ExtraQuantityModalComponent,
  ],
  imports: [SharedModule, InventoryRoutingModule],
  providers: [PublicationStatusPipe, CarriersService],
})
export class InventoryModule {}
