<app-claim-headers-list-filters (onFilter)="onFilter($event)"></app-claim-headers-list-filters>

<div>
  <mat-toolbar class="bar-custom">
    <div class="container">
      <div class="row">
        <div class="col-md-2">
          <button class="box-shadow-none btn-custom" routerLink="details">Show claims</button>
        </div>
        <div class="col-md-2">
          <button [disabled]="!bulkActionsActive" class="box-shadow-none btn-custom" (click)="downloadLetter()">Download letter</button>
        </div>
        <div class="col-md-2">
          <button
            [disabled]="!bulkActionsActive"
            [matMenuTriggerFor]="changeBotStatusMenu"
            class="box-shadow-none btn-custom payment-status-button margin-right-6"
          >
            Activate/Deactive BOT <i class="fa fa-angle-down sel-icon" aria-hidden="true"></i>
          </button>
          <mat-menu #changeBotStatusMenu="matMenu">
            <button mat-menu-item (click)="enableDisableBot('Enable')">Activate</button>
            <button mat-menu-item (click)="enableDisableBot('Disable')">Deactivate</button>
          </mat-menu>
        </div>
      </div>
    </div>
  </mat-toolbar>
</div>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <hr class="divisor-title" />
      <div class="square"></div>
      <h2 class="po-element-custom title-custom">Claim Headers</h2>
    </div>
  </div>
</div>

<div class="container table-outer">
  <mat-paginator [length]="resultsNumber | async" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()"> </mat-paginator>

  <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
    <mat-table multiTemplateDataRows [dataSource]="dataSource" matSort (matSortChange)="onSort()">
      <ng-container matColumnDef="check" sticky>
        <mat-header-cell *matHeaderCellDef class="checker-col">
          <mat-checkbox (change)="toggleAll($event.checked)" [checked]="selectedMainChecker"></mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let ch" class="checker-col">
          <mat-checkbox (change)="onSelected($event.checked, ch)" [checked]="ch.checked"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="invoiceNumber" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="invoiceNumber" class="invoice-number-column"> Invoice Number </mat-header-cell>
        <mat-cell *matCellDef="let ch" class="invoice-number-column">
          <a *ngIf="ch.invoice" [routerLink]="['/invoices', ch.invoice.id]">{{ ch.invoiceNumber }}</a>
          <p *ngIf="!ch.invoice">{{ ch.invoiceNumber }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="invoiceDate" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header="invoiceDate" class="date-column"> Invoice Date </mat-header-cell>
        <mat-cell *matCellDef="let ch" class="date-column">
          <p>{{ ch.invoice?.invoiceDate }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="statusShortageClaim">
        <mat-header-cell *matHeaderCellDef mat-sort-header="statusShortageClaim" class="status-column">
          Shortage Claim Status
        </mat-header-cell>
        <mat-cell *matCellDef="let ch" class="status-column">
          <p>{{ ch.statusShortageClaim.description }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="probableNC">
        <mat-header-cell *matHeaderCellDef mat-sort-header="probableNC" class="boolean-column"> Probable NC </mat-header-cell>
        <mat-cell *matCellDef="let ch" class="boolean-column">
          <p><i [ngClass]="ch.invoice?.probableNC | booleanIconPipe" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="remainingShortageClaim">
        <mat-header-cell *matHeaderCellDef class="remaining-column-title"> Remaining Shortage Claim </mat-header-cell>
        <mat-cell *matCellDef="let ch" class="remaining-column">
          <p>{{ computeRemaining(ch, 'SC') | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="statusPriceClaim">
        <mat-header-cell *matHeaderCellDef mat-sort-header="priceClaimStatus" class="status-column"> Price Claim Status </mat-header-cell>
        <mat-cell *matCellDef="let ch" class="status-column">
          <p>{{ ch.statusPriceClaim.description }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="remainingPriceClaim">
        <mat-header-cell *matHeaderCellDef class="remaining-column-title"> Remaining Price Claim </mat-header-cell>
        <mat-cell *matCellDef="let ch" class="remaining-column">
          <p>{{ computeRemaining(ch, 'PC') | myCurrency }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="vendor">
        <mat-header-cell *matHeaderCellDef class="vendor-column"> Vendor </mat-header-cell>
        <mat-cell *matCellDef="let ch" class="vendor-column">
          <p *ngIf="ch.invoice?.shipment?.vendor?.parent">
            {{ ch.invoice?.shipment?.vendor?.code }} - {{ ch.invoice?.shipment?.vendor?.name }}
          </p>
          <p *ngIf="ch.invoice?.shipment?.vendor?.parent === false">
            {{ ch.invoice?.shipment?.vendor?.parentVendor?.code }} - {{ ch.invoice?.shipment?.vendor?.parentVendor?.name }}
          </p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="shipmentCode">
        <mat-header-cell *matHeaderCellDef class="shipment-code-column"> Shipment Code </mat-header-cell>
        <mat-cell *matCellDef="let ch" class="shipment-code-column">
          <a [routerLink]="['/shipments', ch.invoice?.shipment?.id]">{{ ch.invoice?.shipment?.shipmentCode }}</a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="carrier">
        <mat-header-cell *matHeaderCellDef mat-sort-header="carrier" class="carrier-column"> Carrier </mat-header-cell>
        <mat-cell *matCellDef="let ch" class="carrier-column">
          <p>{{ ch.invoice?.shipment?.contract?.name }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="supplier">
        <mat-header-cell *matHeaderCellDef mat-sort-header="supplier" class="supplier-column"> Supplier </mat-header-cell>
        <mat-cell *matCellDef="let ch" class="supplier-column">
          <p>{{ ch.invoice?.shipment?.origin?.supplier?.name }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="trackingNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header="trackingNumber" class="tracking-number-column">
          Tracking Number
        </mat-header-cell>
        <mat-cell *matCellDef="let ch" class="tracking-number-column">
          <p>{{ ch.invoice?.shipment?.trackingNumber }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="deliveryDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header="deliveryDate" class="date-column"> Delivery Date </mat-header-cell>
        <mat-cell *matCellDef="let ch" class="date-column">
          <p>{{ ch.invoice?.shipment?.deliveryDate | myDate }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="hasPod">
        <mat-header-cell class="boolean-column" *matHeaderCellDef>Has POD</mat-header-cell>
        <mat-cell class="boolean-column" *matCellDef="let c">
          <p><i [ngClass]="c.invoice?.shipment?.podUrls?.length > 0 | booleanIconPipe" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="settlement2022">
        <mat-header-cell class="boolean-column text-center" *matHeaderCellDef>Settlement 2022</mat-header-cell>
        <mat-cell class="boolean-column" *matCellDef="let c">
          <p><i [ngClass]="c.settlement | booleanIconPipe" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="export">
        <mat-header-cell class="actions-show-column" *matHeaderCellDef></mat-header-cell>
        <mat-cell class="actions-show-column" *matCellDef="let c">
          <button class="btn-product btn-fixed" style="width: 110px !important; float: right" (click)="export(c)">Export</button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="hasBot">
        <mat-header-cell class="boolean-column" *matHeaderCellDef>Activated BOT</mat-header-cell>
        <mat-cell class="boolean-column" *matCellDef="let ch">
          <p><i [ngClass]="ch.botEnabled | booleanIconPipe" aria-hidden="true"></i></p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="activeSingleBot">
        <mat-header-cell class="actions-show-column" *matHeaderCellDef></mat-header-cell>
        <mat-cell class="actions-show-column" *matCellDef="let ch">
          <button
            *ngIf="!ch.botEnabled; else disabledBot"
            class="btn-product btn-fixed"
            style="width: 110px !important; float: right"
            (click)="enableDisableSingleBot(ch, 'Enable')"
          >
            BOT ON
          </button>
          <ng-template #disabledBot>
            <button
              class="btn-product btn-fixed"
              style="width: 110px !important; float: right"
              (click)="enableDisableSingleBot(ch, 'Disable')"
            >
              BOT OFF
            </button>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="showDetails" stickyEnd>
        <mat-header-cell class="details-column center" *matHeaderCellDef>Claims history </mat-header-cell>
        <mat-cell class="details-column" *matCellDef="let ch">
          <p>
            <i
              [ngClass]="selectedRow?.id === ch.id ? 'fa fa-chevron-circle-up' : 'fa fa-chevron-circle-down'"
              class="expand-icon"
              (click)="toggle(ch)"
            ></i>
          </p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <mat-cell class="background-light" *matCellDef="let ch" [attr.colspan]="displayedColumns.length">
          <div class="element-detail" [@detailExpand]="ch.id == selectedRow?.id ? 'expanded' : 'collapsed'">
            <div class="margin-lr-0 padding-top-10 padding-bottom-10 width-100per">
              <app-claims-table [claims]="ch.claims" (claimEdited)="claimEdited()"></app-claims-table>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns" [class.example-expanded-row]="selectedRow?.id === row.id"> </mat-row>

      <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="collapsed-detail-row"></mat-row>
    </mat-table>
  </div>
</div>
