import { Component, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-shipments-list-details-bulk-actions',
  templateUrl: './shipments-list-details-bulk-actions.component.html',
  styleUrls: ['./shipments-list-details-bulk-actions.component.css'],
})
export class ShipmentsListDetailsBulkActionsComponent {
  @Input() bulkActionsActive: boolean;

  @Output() onShipmentItemsExport = new Subject<string>();

  constructor(public snackBar: MatSnackBar) {}

  exportShipmentItems(format: string) {
    this.onShipmentItemsExport.next(format);
  }
}
