<mat-toolbar class="bar-custom">
  <div class="container">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <button class="btn-custom" [routerLink]="['new']">New Carrier</button>
      </div>
    </div>
  </div>
</mat-toolbar>

<div class="container table-outer">
  <div class="col-md-2"></div>

  <div class="col-md-8">
    <div class="table-header">
      <mat-form-field>
        <input matInput class="text-uppercase" (keyup)="applyFilter($event.target.value)" placeholder="Search" />
      </mat-form-field>
    </div>

    <mat-paginator #paginator [length]="resultsNumber | async" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()">
    </mat-paginator>

    <div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
      <mat-table #listingTable [dataSource]="dataSource" matSort (matSortChange)="onSort()">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef class="name-column" mat-sort-header="name"> Name </mat-header-cell>
          <mat-cell *matCellDef="let carrier" class="name-column"> {{ carrier.name }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="contracts">
          <mat-header-cell *matHeaderCellDef> Contracts </mat-header-cell>
          <mat-cell *matCellDef="let carrier">
            <ng-container *ngFor="let contract of carrier.contracts; let last = last">
              <span> {{ contract.supplier.code }} </span>
              <span *ngIf="!last"> - </span>
            </ng-container>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="trackingMore">
          <mat-header-cell *matHeaderCellDef> Trackingmore </mat-header-cell>
          <mat-cell *matCellDef="let carrier">
            <p>
              <i [ngClass]="carrier.trackingMore | booleanIconPipe" aria-hidden="true"></i>
            </p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="edit">
          <mat-header-cell *matHeaderCellDef class="edit-column"> </mat-header-cell>
          <mat-cell *matCellDef="let carrier" class="edit-column">
            <button class="btn-product btn-fixed float-right" [routerLink]="['edit', carrier.id]">Edit</button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>
</div>
