<mat-accordion multi="true">
  <mat-expansion-panel class="keepa-exp-panel" id="keepa-exp">
    <mat-expansion-panel-header class="keepa-details-header">
      <mat-panel-title class="keepa-details-title">
        <div class="row" style="width: 100%;">
          <div class="col-md-4 exp-panel-label">
            <i class="fa fa-amazon margin-right-6" aria-hidden="true"></i>
            Keepa Details
          </div>

          <div class="col-md-4">Last update: {{ keepaDetails.updatedAt }}</div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="keepa-properties-panel">
      <ng-container *ngIf="keepaDetails">
        <div class="row">
          <h2 class="accordion-section-label">Category tree</h2>
          <hr class="accordion-hr" />
        </div>

        <div class="row padding-left-15 padding-bottom-15" *ngIf="categoryTree">
          <div class="col-md-12">
            <ng-container *ngFor="let c of categoryTree; let i = index">
              <span class="margin-right-20">{{ c }}</span>
              <span class="margin-right-20" *ngIf="i !== categoryTree.length - 1">
                <i class="fa fa-caret-right" aria-hidden="true"></i>
              </span>
            </ng-container>
          </div>
        </div>

        <div class="row">
          <h2 class="accordion-section-label">Prices</h2>
          <hr class="accordion-hr" />
        </div>

        <div class="row padding-left-15 padding-bottom-15">
          <div class="col-md-2 accordion-light-border-bottom accordion-light-border-right"></div>
          <div class="col-md-10">
            <div class="row">
              <div class="col-md-2 accordion-light-border-bottom" *ngFor="let m of marketplaces">
                <b>{{ m.toUpperCase() }}</b>
              </div>
            </div>
          </div>

          <div class="col-md-2 accordion-light-border-right"><b>Amazon price</b></div>
          <div class="col-md-10 height-20">
            <div class="row">
              <div class="col-md-2" *ngFor="let m of marketplaces">
                {{ keepaDetails[m + 'AmazonPrice'] | myCurrency }}
              </div>
            </div>
          </div>

          <div class="col-md-2 accordion-light-border-right"><b>New price</b></div>
          <div class="col-md-10 height-20">
            <div class="row">
              <div class="col-md-2" *ngFor="let m of marketplaces">
                {{ keepaDetails[m + 'NewPrice'] | myCurrency }}
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="keepaDetails.productData">
          <h2 class="accordion-section-label">Product Data</h2>
          <hr class="accordion-hr" />
        </div>
        <div class="row padding-left-15 padding-bottom-15">
          <div class="col-md-6 padding-bottom-5" *ngIf="keepaDetails.productData?.title">
            <b>Title:</b> {{ keepaDetails.productData.title }}
          </div>
          <div class="col-md-6 padding-bottom-5" *ngIf="keepaDetails.productData?.description">
            <b>Description:</b> {{ keepaDetails.productData.description }}
          </div>

          <div class="col-md-3 padding-bottom-5" *ngFor="let key of keys">
            <b>{{ key | fromCamelCase }}:</b> {{ keepaDetails.productData[key] }}
          </div>
        </div>
      </ng-container>
    </div>
  </mat-expansion-panel>
</mat-accordion>
