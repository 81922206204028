/* eslint-disable */
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Permission')
export class Permission {
  @JsonProperty('id', String, false) id: string = undefined;
  @JsonProperty('name', String, false) name: string = undefined;
  @JsonProperty('application', String, false) application: string = undefined;
}

export enum Permissions {
  Administration = 'administration',
  LotManagement = 'lot_management',
  FulfillmentCreation = 'fulfillment_creation',
  ShipmentCreation = 'shipment_creation',
  ShipmentManagement = 'shipment_management',
  CancelShipments = 'cancel_shipments',
  ChangeShipmentPaymentStatus = 'change_shipment_payment_status',
  PoStateChange = 'po_state_change',
  InvoiceManagement = 'invoice_management',
  SupplierManagement = 'supplier_management',
  CatalogUpdate = 'catalog_update',
  SupplierThresholdUpdate = 'supplier_threshold_update',
  PackingListImport = 'packing_list_import',
  Configuration = 'configuration',
  DeductionManagement = 'deduction_management',
  ManualShippedUpdate = 'manual_shipped_update',
  PaymentManagement = 'payment_management',
  ChangePaymentRequestStatus = 'change_payment_request_status',
  ResendToNav = 'resend_to_nav',
  SendPoaSubmitted = 'send_poa_submitted',
  VendorAccountManagement = 'vendor_account_management',
}
