import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { GenericConfirmationModalComponent } from '../../../../modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { ImportExportService } from '../../../../../shared/import-export.service';
import { LotsService } from '../../../../../shared/lots.service';
import { ENTITIES } from '../../../../../entities';
import { Permissions } from '../../../../../models/permission-model';
import { AuthorizationService } from '../../../../../core/authorization.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SpoStatus } from '../../../../../models/spo-model';
import { CommonsService } from '../../../../../shared/commons.service';
import { Lot } from '../../../../../models/lot-model';
import { isNilty } from '../../../../../core/utils.service';
import { GenericErrorModalComponent } from '../../../../modal/generic-error-modal/generic-error-modal.component';

@Component({
  selector: 'app-spo-bulk-actions',
  templateUrl: './spo-bulk-actions.component.html',
  styleUrls: ['./spo-bulk-actions.component.css'],
})
export class SpoBulkActionsComponent implements OnInit {
  @ViewChild('getSpoResp', { static: true }) getSpoResp: ElementRef;
  @ViewChild('getUpdateOrdered', { static: true }) getUpdateOrdered: ElementRef;
  @ViewChild('getSpoGenerate', { static: true }) getSpoGenerate: ElementRef;
  currentFileUpload: File;

  @Input() bulkActionsActive: boolean;
  @Input() currentLot: Lot;

  @Output() onMerge = new Subject<void>();
  @Output() onDelete = new Subject<void>();
  @Output() onExport = new Subject<string>();
  @Output() onGenerateShipments = new Subject<void>();

  @Output() onSendSpos = new Subject<void>();

  @Output() onSetPayment = new Subject<boolean>();
  @Output() onChangeStatus = new Subject<number>();

  hasFulfillmentCreation: Observable<boolean>;

  spoStatusArray: SpoStatus[];

  constructor(
    private dialog: MatDialog,
    private lotsService: LotsService,
    public snackBar: MatSnackBar,
    private importExportService: ImportExportService,
    private authorizationService: AuthorizationService,
    private commonsService: CommonsService
  ) {}

  ngOnInit() {
    this.commonsService.getSpoStatuses().subscribe((r) => (this.spoStatusArray = r.filter((it) => it.setFromFrontend)));
    this.hasFulfillmentCreation = this.authorizationService.hasPermission(Permissions.FulfillmentCreation);
  }

  delete() {
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '350px',
      data: 'Deleting SPOs.',
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.onDelete.next();
      } else {
        console.log('Rows not deleted.');
      }
    });
  }

  exportExcel(format: string) {
    this.onExport.next(format);
  }

  uploadSpoResponse(event) {
    this.fileChanged(event, ENTITIES.SPO_IMPORT_CONFIRM);
  }

  uploadSpoGenerate(event) {
    this.fileChanged(event, ENTITIES.SPO_IMPORT_GENERATE);
  }

  uploadUpdateOrdered(event) {
    this.fileChanged(event, ENTITIES.UPDATE_ORDERED);
  }

  importSpoResponse() {
    this.getSpoResp.nativeElement.click();
  }

  importSpoGenerate() {
    this.getSpoGenerate.nativeElement.click();
  }

  importUpdateOrdered() {
    this.getUpdateOrdered.nativeElement.click();
  }

  fileChanged(event, entity: string) {
    this.currentFileUpload = event.target.files[0];

    this.snackBar.open('Uploading', '')._dismissAfter(3000);

    this.importExportService.uploadFile(this.currentFileUpload, entity, this.lotsService.currentLotId).subscribe(() => {
      this.currentFileUpload = undefined;
    });
  }

  setPayment(value: boolean) {
    this.onSetPayment.next(value);
  }

  generateShipments() {
    if (isNilty(this.currentLot.firstShipmentGenerate)) {
      this.dialog.open(GenericErrorModalComponent, {
        data: 'To generate Shipments from single SPOs you must first call Create Shipment on the Lot.',
      });
      return;
    }

    this.dialog
      .open(GenericConfirmationModalComponent, { width: '400px', data: 'This will drop and regenerate Shipments for selected SPOs.' })
      .afterClosed()
      .subscribe((resp: boolean) => {
        if (resp) {
          this.onGenerateShipments.next();
        }
      });
  }

  changeStatus(code: number) {
    this.onChangeStatus.next(code);
  }

  sendSpos() {
    this.onSendSpos.next();
  }

  merge() {
    this.dialog
      .open(GenericConfirmationModalComponent, {
        width: '350px',
        data: 'Merging SPOs.',
      })
      .afterClosed()
      .subscribe((r: boolean) => {
        if (r) {
          this.onMerge.next();
        }
      });
  }
}
