import { JsonObject, JsonProperty } from 'json2typescript';
import { Carrier } from './carrier.model';
import { Supplier } from './supplier-model';

@JsonObject('Contract')
export class Contract {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('name', String, true) name: string = undefined;
  @JsonProperty('carrier', Carrier, true) carrier: Carrier = undefined;
  @JsonProperty('supplier', Supplier, true) supplier: Supplier = undefined;
}
