import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { VendorCentralAccount } from '@models/vendor-central-account-model';
import { VendorCentralAccountsService } from '@container/configuration/vendor-central-accounts/vendor-central-accounts.service';
import { Observable } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { VendorsDialogComponent } from './vendors-dialog/vendors-dialog.component';

@Component({
  selector: 'app-vendor-central-accounts-list',
  templateUrl: './vendor-central-accounts-list.component.html',
  styleUrls: ['./vendor-central-accounts-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class VendorCentralAccountsListComponent implements OnInit {
  dataSource: MatTableDataSource<VendorCentralAccount>;

  vendorCentralAccounts: VendorCentralAccount[];

  resultsNumber: Observable<number>;

  displayedColumns = ['name', 'hasBot', 'showVendors', 'edit'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filterInput', { static: true }) filterInput: ElementRef;

  constructor(private vendorCentralAccountsService: VendorCentralAccountsService, private dialog: MatDialog) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource<VendorCentralAccount>([]);
    this.loadVendorCentralAccount();
  }

  loadVendorCentralAccount() {
    this.vendorCentralAccountsService.getVendorAccountAccounts().subscribe((resp: VendorCentralAccount[]) => {
      this.dataSource.data = resp;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  showVendors(vendorCentralAccount: VendorCentralAccount) {
    const dialogRef = this.dialog.open(VendorsDialogComponent, {
      height: '80%',
      minWidth: '85vw',
      data: { vendorCentralAccount },
    });
  }
}
