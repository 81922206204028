import { Component, OnInit, Output } from '@angular/core';
import { VendorsService } from '../../../configuration/vendors/vendors.service';
import { Warehouse } from '../../../../models/warehouse-model';
import { Vendor, VendorsFromLotOutput } from '../../../../models/vendor-model';
import { Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { PosService } from '../../../../shared/pos.service';
import { WarehousesService } from '../../../configuration/warehouses/warehouses.service';
import { LotsService } from '../../../../shared/lots.service';
import { PoFilters } from '../../../../models/filters/po-filters-model';
import { LotEditService } from '../../lot-edit.service';
import { MatChipsData } from '../../../../models/mat-chips-data-model';
import { Category } from '../../../../models/category-model';
import { CategoriesService } from '../../../configuration/categories/categories.service';
import { Brand } from '../../../../models/brand-model';
import { InventoryService } from '../../../inventory/inventory.service';
import { isNilty } from '../../../../core/utils.service';
import {
  createLotEditPoFiltersFormGroup,
  setBrandOnPoFilters,
  setCategoryOnPoFilters,
  setDestinationOnPoFilters,
  setLatestDeliveryOnPoFilters,
  setVendorsOnPoFilters,
  updatePoFiltersMap,
} from '../../../../shared/po-filters-handler';

@Component({
  selector: 'app-po-filters',
  templateUrl: './po-filters.component.html',
  styleUrls: ['./po-filters.component.css'],
})
export class PoFiltersComponent implements OnInit {
  constructor(
    private posService: PosService,
    private vendorsService: VendorsService,
    private warehousesService: WarehousesService,
    private lotsService: LotsService,
    private lotEditService: LotEditService,
    private categoriesService: CategoriesService,
    private inventoryService: InventoryService
  ) {}

  filters: PoFilters;

  warehouses: Warehouse[] = [];

  vendors: Vendor[];
  parents: Vendor[];

  categories: Category[] = [];

  brandList: Brand[] = [];
  brands: Subject<Brand[]> = new Subject();

  @Output() onPoFilter = new Subject<PoFilters>();

  filterForm: FormGroup;
  public openFilter = false;

  filtersMap: MatChipsData[] = [];

  ngOnInit() {
    this.filterForm = createLotEditPoFiltersFormGroup();

    if (!isNilty(this.lotEditService.poFilters)) {
      this.filterForm.patchValue(this.lotEditService.poFilters);
      this.filtersMap = setLatestDeliveryOnPoFilters(this.lotEditService.poFilters, this.filterForm);
    }

    if (this.vendorsService.lotEditVendors === undefined) {
      this.vendorsService.getVendorsFromLot(this.lotsService.currentLotId).subscribe((vendorsResp: VendorsFromLotOutput) => {
        this.vendors = vendorsResp.vendors;
        this.parents = vendorsResp.parents;
        this.filtersMap = setVendorsOnPoFilters(this.vendors, this.parents, this.lotEditService.poFilters, this.filterForm);
      });
    } else {
      this.vendors = this.vendorsService.lotEditVendors;
      this.parents = this.vendorsService.lotEditParentVendors;
      this.filtersMap = setVendorsOnPoFilters(this.vendors, this.parents, this.lotEditService.poFilters, this.filterForm);
    }

    if (this.warehousesService.lotEditWarehouses === undefined) {
      this.warehousesService.getWarehousesFromLot(this.lotsService.currentLotId).subscribe((warehouses: Warehouse[]) => {
        this.warehouses = warehouses;
        this.filtersMap = setDestinationOnPoFilters(this.warehouses, this.lotEditService.poFilters, this.filterForm);
      });
    } else {
      this.warehouses = this.warehousesService.lotEditWarehouses;
      this.filtersMap = setDestinationOnPoFilters(this.warehouses, this.lotEditService.poFilters, this.filterForm);
    }

    this.categoriesService.getCategories('', 10000, 0).subscribe((c: Category[]) => {
      this.categories = c;
      this.filtersMap = setCategoryOnPoFilters(this.categories, this.lotEditService.poFilters, this.filterForm);
    });

    const brandString = this.lotEditService.poFilters ? this.lotEditService.poFilters.brand : undefined;

    this.inventoryService.getBrands(brandString).subscribe((brands: Brand[]) => {
      this.brands.next(brands);
      this.brandList = brands;
      this.filtersMap = setBrandOnPoFilters(this.brandList, this.lotEditService.poFilters, this.filterForm);
    });
  }

  saveFiltersToLotEditService() {
    this.lotEditService.savePoFiltersToService(this.filterForm, this.lotsService.currentLotId);
  }

  onSubmitFilter() {
    this.saveFiltersToLotEditService();
    this.filtersMap = updatePoFiltersMap(this.lotEditService.poFilters, this.filterForm);
    this.onPoFilter.next(this.lotEditService.poFilters);
  }

  resetFilters() {
    this.filterForm.reset();
    this.filtersMap = updatePoFiltersMap(this.lotEditService.poFilters, this.filterForm);
    this.saveFiltersToLotEditService();
    this.onPoFilter.next(this.lotEditService.poFilters);
  }

  openEvent() {
    if (this.openFilter === true) {
      this.openFilter = false;
    } else {
      this.openFilter = true;
    }
  }

  closeEvent() {
    this.openFilter = false;
  }

  selectedParent(event) {
    const parent = event.value;
    if (parent != null) {
      this.filterForm.controls['vendors'].setValue(null);
    }
  }

  selectedChildren(event) {
    const children = event.value;
    if (children != null) {
      this.filterForm.controls['parent'].setValue(null);
    }
  }

  filterBrands(filterString: { filterText: string; filterKey: string }) {
    this.inventoryService.getBrands(filterString.filterText).subscribe((resp: Brand[]) => {
      this.brands.next(resp);
      this.brandList = resp;
    });
  }

  vendorChange(event) {
    if (event.value.indexOf(null) !== -1) {
      this.filters.vendors = [];
    }
  }
}
