<div [ngClass]="{ 'black-panel': openFilter, panel: !openFilter }">
  <mat-expansion-panel class="filter-position" [attr.expanded]="openFilter" (opened)="openEvent()" (closed)="closeevent()">
    <mat-expansion-panel-header class="filt-custom">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-9">
            <mat-chip-list>
              <mat-chip *ngFor="let f of filtersMap">{{ f.filter }}</mat-chip>
            </mat-chip-list>
          </div>
          <div class="col-md-2">
            <mat-panel-title class="filter-title"> Filters </mat-panel-title>
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>

    <div class="row" style="padding-top: 15px">
      <form [formGroup]="filterForm" (submit)="onSubmitFilter()">
        <div class="container border-bottom-orange col-md-12 text-blue margin-top-5">
          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="paymentRequestId" id="paymentRequestId" placeholder="Payment Request Id" />
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="createdAt" [matDatepicker]="createdAt" placeholder="Created At" />
                <mat-datepicker-toggle matSuffix [for]="createdAt"></mat-datepicker-toggle>
                <mat-datepicker #createdAt></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select filterKey="description" formControlName="supplier" id="supplier" placeholder="Supplier">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="supplier.code" *appMatSelectFor="let supplier of suppliers">
                    {{ supplier.description }}
                  </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <textarea matInput formControlName="documentNumbers" id="documentNumbers" placeholder="Payment Document Number"></textarea>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <textarea matInput formControlName="shipments" id="shipments" placeholder="Shipments Order ID"></textarea>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select filterKey="status" formControlName="status" id="status" placeholder="Status">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="status.code" *appMatSelectFor="let status of paymentRequestStatus">
                    {{ status.description }}
                  </mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="latestPaymentDate" [matDatepicker]="latestPaymentDate" placeholder="Latest Payment Date" />
                <mat-datepicker-toggle matSuffix [for]="latestPaymentDate"></mat-datepicker-toggle>
                <mat-datepicker #latestPaymentDate></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="latestPickupDate" [matDatepicker]="latestPickupDate" placeholder="Latest Pickup Date" />
                <mat-datepicker-toggle matSuffix [for]="latestPickupDate"></mat-datepicker-toggle>
                <mat-datepicker #latestPickupDate></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <input matInput formControlName="createdBy" id="createdBy" placeholder="Created By" />
              </mat-form-field>
            </div>

            <div class="col-md-2">
              <mat-form-field>
                <app-mat-select formControlName="hasAdjustment" placeholder="Has Adjustment">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="true">True</mat-option>
                  <mat-option [value]="false">False</mat-option>
                </app-mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-md-12 border-top-white">
          <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-2 center">
              <button class="btn-custom padding-left-right-70" type="submit">Filter</button>
            </div>
            <div class="col-md-2 center">
              <button class="btn-custom padding-left-right-70" type="button" (click)="resetFilters()">Reset</button>
            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
</div>
