export class ShipmentsDetailsKpi {
  submittedQuantity = 0;
  submittedValue = 0;

  requestedQuantity = 0;
  requestedValue = 0;

  acceptedQuantity = 0;
  acceptedValue = 0;

  shippedQuantity = 0;
  shippedValue = 0;
}
