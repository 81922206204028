<ng-container *ngFor="let prioritySupplier of prioritySuppliers">
  <div class="row predicate-row" [ngClass]="prioritySupplier.isEdit ? 'edit-predicate-row' : ''">
    <ng-container *ngIf="!prioritySupplier.isEdit">
      <div class="col-md-9">
        {{ addSupplierDescription(prioritySupplier) }}
      </div>
      <div class="col-md-3 text-right">
        <button class="btn-custom smaller-icon-button" matTooltip="Edit" (click)="prioritySupplier.isEdit = true">
          <i class="fa fa-pencil" aria-hidden="true"></i>
        </button>
        <button class="btn-custom smaller-icon-button" matTooltip="Delete" (click)="deletePrioritySupplier(prioritySupplier)">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="prioritySupplier.isEdit">
      <div class="col-md-4">
        <mat-form-field>
          <app-mat-select [(ngModel)]="prioritySupplier.supplierId" filterKey="description" placeholder="Supplier" [multiple]="false">
            <mat-option *ngFor="let supplier of suppliers" [value]="supplier.id">{{ supplier.description }} </mat-option>
          </app-mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field>
          <input
            matInput
            placeholder="Priority"
            [(ngModel)]="prioritySupplier.priority"
            (keydown)="setPriorityValue($event)"
            (input)="setPriorityValue($event)"
            type="number"
          />
        </mat-form-field>
      </div>
      <div class="col-md-4 text-right padding-top-15">
        <button
          class="btn-custom smaller-icon-button"
          matTooltip="Confirm"
          [disabled]="!prioritySupplier.supplierId || disabledConfirmButton"
          (click)="confirmPrioritySupplier(prioritySupplier)"
        >
          <i class="fa fa-check" aria-hidden="true"></i>
        </button>
        <button class="btn-custom smaller-icon-button" matTooltip="Delete" (click)="deletePrioritySupplier(prioritySupplier)">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </button>
      </div>
    </ng-container>
  </div>
</ng-container>

<div class="row predicate-row clickable-predicate-row" matTooltip="Add new predicate" (click)="addPrioritySupplier()">
  <div class="col-md-12 text-right">Add supplier</div>
</div>
