<h1 mat-dialog-title>Error!</h1>

<div mat-dialog-content>
  {{ errorString }}
</div>

<div mat-dialog-actions>
  <span style="width: 100%; text-align: center;">
    <button mat-button [mat-dialog-close]="null">Ok</button>
  </span>
</div>
