<div class="lot-kpi">
  <div class="container">
    <div class="col-md-4 background-blue text-white height-box font-row">
      <h4 class="h-padding-box"><b>Order ID: </b> {{ spo?.orderId }}</h4>
      <p>
        <b class="margin-right-6">Status: </b> {{ spo?.spoStatus | spoStatusPipe }} <b class="margin-left-20">Extra: </b
        ><i [ngClass]="spo?.isExtra | booleanIconPipe" aria-hidden="true"></i> <b class="margin-left-20">Payment: </b
        ><i [ngClass]="spo?.payment | booleanIconPipe" aria-hidden="true"></i> <b class="margin-left-20">Cross UK border: </b
        ><i [ngClass]="spo?.crossUkBorder | booleanIconPipe" aria-hidden="true"></i>
      </p>
      <p><b>Supplier: </b> {{ spo?.supplier?.code + ' - ' + spo?.supplier?.name }}</p>
      <p><b>Destination: </b> {{ spo?.destination?.code + ' - ' + spo?.destination?.name }}</p>
      <p><b>Parent vendor: </b> {{ spo?.vendor?.code + ' - ' + spo?.vendor?.name }}</p>

      <p class="key-table-fixed">
        <b class="margin-right-6">Lot: </b> {{ spo?.lot?.name }} <b class="margin-left-6">Latest pickup: </b> {{ spo?.latestPickupDate }}
      </p>

      <p><b>Delivery window: </b> {{ (spo?.earliestDeliveryDate ? spo?.earliestDeliveryDate : '') + ' - ' + spo?.latestDeliveryDate }}</p>
    </div>

    <div class="col-md-2 background-light-blue text-white height-box font-row">
      <h4 class="h-padding-box">
        <b>Requested</b>
      </h4>
      <p><b>Quantity: </b> {{ (kpiData | async)?.requestedQuantity }}</p>
      <p><b>Value: </b> {{ (kpiData | async)?.requestedValue | myCurrency }}</p>
      <p><b>Purchase price: </b> {{ (kpiData | async)?.requestedPurchasePrice | myCurrency }}</p>
      <p><b>Virtual Purchase price: </b> {{ (kpiData | async)?.virtualRequestedPurchasePrice | myCurrency }}</p>
    </div>

    <div class="col-md-2 background-blue text-white height-box font-row">
      <h4 class="h-padding-box">
        <b>Confirmed</b>
      </h4>
      <p><b>Quantity: </b> {{ (kpiData | async)?.acceptedQuantity }}</p>
      <p><b>Value: </b> {{ (kpiData | async)?.acceptedValue | myCurrency }}</p>
      <p><b>Purchase price: </b> {{ (kpiData | async)?.acceptedPurchasePrice | myCurrency }}</p>
      <p><b>Virtual Purchase price: </b> {{ (kpiData | async)?.virtualAcceptedPurchasePrice | myCurrency }}</p>
    </div>

    <div class="col-md-2 background-light-blue text-white height-box font-row">
      <h4 class="h-padding-box">
        <b>Ordered</b>
      </h4>
      <p><b>Quantity: </b> {{ (kpiData | async)?.orderedQuantity }}</p>
      <p><b>Value: </b> {{ (kpiData | async)?.orderedValue | myCurrency }}</p>
      <p><b>Purchase price: </b> {{ (kpiData | async)?.orderedPurchasePrice | myCurrency }}</p>
      <p><b>Virtual Purchase price: </b> {{ (kpiData | async)?.virtualOrderedPurchasePrice | myCurrency }}</p>
    </div>

    <div class="col-md-2 background-blue text-white height-box font-row">
      <h4 class="h-padding-box">
        <b>Margin</b>
      </h4>
      <p><b>Value: </b> {{ (kpiData | async)?.margin | myCurrency }}</p>
      <p><b>Percent: </b> {{ (kpiData | async)?.percentMargin | percentageFromDecimal }}</p>
      <p><b>Virtual Value: </b> {{ (kpiData | async)?.virtualMargin | myCurrency }}</p>
      <p><b>Virtual Percent: </b> {{ (kpiData | async)?.virtualPercentMargin | percentageFromDecimal }}</p>
    </div>
  </div>
</div>
