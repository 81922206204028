import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('UpdateShippedInputModel')
export class UpdateShippedInputModel {
  @JsonProperty('shipmentItemId', Number, false) shipmentItemId: number = undefined;
  @JsonProperty('newQuantity', Number, false) newQuantity: number = undefined;

  constructor(shipmentItemId: number, newQuantity: number) {
    this.shipmentItemId = shipmentItemId;
    this.newQuantity = newQuantity;
  }
}
