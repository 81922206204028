<div class="row">
  <div class="col-md-2 padding-top-11">
    <button class="box-shadow-none btn-custom" (click)="toggleDetailsView()">
      <i class="fa fa-address-card margin-right-6" aria-hidden="true"></i> View SPOItems
    </button>
  </div>
  <div class="col-md-10">
    <mat-paginator [length]="resultsNumber | async" [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" (page)="loadPage()">
    </mat-paginator>
  </div>
</div>

<div class="table-container mat-elevation-z8 box-shadow-none margin-bottom-60">
  <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSort()">
    <ng-container matColumnDef="check" sticky>
      <mat-header-cell *matHeaderCellDef class="checker-col">
        <mat-checkbox (change)="toggleAll($event.checked)" [ngModel]="mainChecker"></mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let spo; let i = index" class="checker-col">
        <mat-checkbox (change)="onSelected($event.checked, spo)" [ngModel]="spo.checked"></mat-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="ean" sticky>
      <mat-header-cell class="ean-column" *matHeaderCellDef mat-sort-header="ean"> EAN </mat-header-cell>
      <mat-cell class="ean-column" *matCellDef="let spo">
        <a [routerLink]="['/inventory/products', spo.product?.id, 'details']">{{ spo.product.ean }}</a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="poCode" sticky>
      <mat-header-cell class="po-code-column" *matHeaderCellDef mat-sort-header="poCode"> PO Code </mat-header-cell>
      <mat-cell class="po-code-column" *matCellDef="let spo">
        <p>{{ spo.purchaseOrderItem.purchaseOrder.poId }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="latestDate" sticky>
      <mat-header-cell class="date-column" *matHeaderCellDef mat-sort-header="latestDate"> Latest date </mat-header-cell>
      <mat-cell class="date-column" *matCellDef="let spo">
        <p>{{ spo.purchaseOrderItem.purchaseOrder.latestDate }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actualEan">
      <mat-header-cell class="ean-column" *matHeaderCellDef mat-sort-header="actualEan"> Actual EAN </mat-header-cell>
      <mat-cell class="ean-column" *matCellDef="let spo">{{ spo.actualEan }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="title">
      <mat-header-cell class="title-column" *matHeaderCellDef mat-sort-header="title"> Title </mat-header-cell>
      <mat-cell class="title-column" *matCellDef="let spo">
        <p>{{ spo.product.title }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="purchasePrice">
      <mat-header-cell class="value-column-title-small" *matHeaderCellDef mat-sort-header="purchasePrice"> Purchase price </mat-header-cell>
      <mat-cell class="value-column-small" *matCellDef="let spo">
        <p>{{ spo.purchasePrice | myCurrency }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="requestedQuantity">
      <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="requestedQuantity">
        Requested quantity
      </mat-header-cell>
      <mat-cell class="quantity-column" *matCellDef="let spo">
        <p>{{ spo.requestedQuantity }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="confirmedQuantity">
      <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="confirmedQuantity">
        Confirmed quantity
      </mat-header-cell>
      <mat-cell class="quantity-column" *matCellDef="let spo">
        <p>{{ spo.confirmedQuantity }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="orderedQuantity">
      <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="orderedQuantity">
        Ordered <br />
        quantity
      </mat-header-cell>
      <mat-cell class="quantity-column" *matCellDef="let spo">
        <p>{{ spo.orderedQuantity }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="margin">
      <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="margin"> Margin </mat-header-cell>
      <mat-cell class="value-column" *matCellDef="let spo">
        <p>{{ spo.margin | myCurrency }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="percentMargin">
      <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="percentMargin">Margin %</mat-header-cell>
      <mat-cell class="quantity-column" *matCellDef="let spo">
        <p>{{ spo.percentMargin | percentageFromDecimal }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="virtualMargin">
      <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="margin"> Virtual Margin </mat-header-cell>
      <mat-cell class="value-column" *matCellDef="let spo">
        <p>{{ spo.virtualMargin | myCurrency }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="virtualPercentMargin">
      <mat-header-cell class="quantity-column-title" *matHeaderCellDef mat-sort-header="virtualPercentMargin"
        >Virtual Margin %</mat-header-cell
      >
      <mat-cell class="quantity-column" *matCellDef="let spo">
        <p>{{ spo.virtualPercentMargin | percentageFromDecimal }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="requestedValue">
      <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="requestedValue">
        Requested <br />
        value
      </mat-header-cell>
      <mat-cell class="value-column" *matCellDef="let spo">
        <p>{{ spo.requestedValue | myCurrency }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="confirmedValue">
      <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="confirmedValue">
        Confirmed <br />
        value
      </mat-header-cell>
      <mat-cell class="value-column" *matCellDef="let spo">
        <p>{{ spo.confirmedValue | myCurrency }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="confirmedPurchasePrice">
      <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="confirmedPurchasePrice">
        Confirmed <br />
        purchase price
      </mat-header-cell>
      <mat-cell class="value-column" *matCellDef="let spo">
        <p>{{ spo.confirmedPurchasePrice | myCurrency }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="virtualConfirmedPurchasePrice">
      <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="virtualConfirmedPurchasePrice">
        Virtual Confirmed <br />
        purchase price
      </mat-header-cell>
      <mat-cell class="value-column" *matCellDef="let spo">
        <p>{{ spo.virtualConfirmedPurchasePrice | myCurrency }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="orderedValue">
      <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="orderedValue">
        Ordered <br />
        value
      </mat-header-cell>
      <mat-cell class="value-column" *matCellDef="let spo">
        <p>{{ spo.orderedValue | myCurrency }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="orderedPurchasePrice">
      <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="orderedPurchasePrice">
        Ordered <br />
        purchase price
      </mat-header-cell>
      <mat-cell class="value-column" *matCellDef="let spo">
        <p>{{ spo.orderedPurchasePrice | myCurrency }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="virtualOrderedPurchasePrice">
      <mat-header-cell class="value-column-title" *matHeaderCellDef mat-sort-header="virtualOrderedPurchasePrice">
        Virtual Ordered <br />
        purchase price
      </mat-header-cell>
      <mat-cell class="value-column" *matCellDef="let spo">
        <p>{{ spo.virtualOrderedPurchasePrice | myCurrency }}</p>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="obsoleteWithAccepted">
      <mat-header-cell class="bigger-boolean-column" *matHeaderCellDef> Obsolete with accepted </mat-header-cell>
      <mat-cell class="bigger-boolean-column" *matCellDef="let spo">
        <i [ngClass]="spo?.purchaseOrderItem?.obsoleteWithAcceptedGreaterThanZero | booleanIconPipe" aria-hidden="true"></i>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>
