import { Component, OnInit, ViewChild } from '@angular/core';

import { PoItem } from '../../../../models/po-item-model';
import { LotEditKpi } from '../../../../models/lot-edit-kpi';
import { ImportExportService } from '../../../../shared/import-export.service';
import { LotsService } from '../../../../shared/lots.service';
import { LotEditService } from '../../lot-edit.service';
import { Observable } from 'rxjs';
import { PoFilters } from '../../../../models/filters/po-filters-model';
import { PosService } from '../../../../shared/pos.service';
import { addCheckers } from '../../../../shared/checkers.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-po-details',
  templateUrl: './po-details.component.html',
  styleUrls: ['./po-details.component.css'],
})
export class PoDetailsComponent implements OnInit {
  dataSource;

  kpiData = new LotEditKpi();

  displayedColumns = [
    'check',
    'poId',
    'vendor',
    'destination',
    'orderedOn',
    'earliestDate',
    'latestDate',
    'title',
    'ean',
    'sku',
    'brand',
    'backorder',
    'expectedDate',
    'submittedQuantity',
    'acceptedQuantity',
    'lastPoaQuantity',
    'obsoleteWithAccepted',
    'unitCost',
    'totalCost',
  ];

  mainChecker = false;

  bulkActionsActive = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  dataPoItems: any[] = [];

  constructor(
    private lotsService: LotsService,
    private lotEditService: LotEditService,
    private poService: PosService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private importExportService: ImportExportService
  ) {}

  ngOnInit() {
    this.lotEditService.initPoFilters(this.lotsService.currentLotId);
    this.resultsNumber = this.poService.resultsNumber;

    this.lotsService.getLot().subscribe(() => {
      this.lotEditService.poFilters.lotId = this.lotsService.currentLotId;

      this.resetPagination();
      this.resetCheckers();
      this.dataSource = new MatTableDataSource([]);
      this.loadPoItems(this.lotEditService.poFilters);
    });
  }

  loadPoItems(filters: PoFilters) {
    this.poService.getFilteredPoItems(filters).subscribe((poItems: PoItem[]) => {
      this.dataPoItems = addCheckers(poItems, this.lotEditService.selectedPoItems, this.mainChecker);
      this.dataSource.data = this.dataPoItems;
    });
  }

  loadPage() {
    this.lotEditService.poFilters.pageNumber = this.paginator.pageIndex;
    this.lotEditService.poFilters.pageSize = this.paginator.pageSize;
    this.loadPoItems(this.lotEditService.poFilters);
  }

  onSort() {
    this.lotEditService.poFilters.sortBy = this.sort.active;
    this.lotEditService.poFilters.sortDirection = this.sort.direction;
    this.loadPoItems(this.lotEditService.poFilters);
  }

  onSelected(value: boolean, selectedPoItem: any) {
    const selectedIndex = this.dataPoItems.indexOf(selectedPoItem);
    this.dataPoItems[selectedIndex].checked = value;
    if (value !== this.mainChecker) {
      this.lotEditService.addPoItemIdToSelected(this.dataPoItems[selectedIndex].id);
    } else {
      this.lotEditService.removePoItemIdFromSelected(this.dataPoItems[selectedIndex].id);
    }
    this.bulkActionsActive = value || this.mainChecker;
  }

  toggleAll(value: boolean) {
    this.mainChecker = value;
    this.lotEditService.poItemsMainChecker = this.mainChecker;
    for (const i of this.dataPoItems) {
      i.checked = value;
    }

    this.poService.selectedPoItems = [];
    this.bulkActionsActive = value;
  }

  applyFilter(filters: PoFilters) {
    this.paginator.pageIndex = 0;
    this.dataPoItems = [];
    this.resetCheckers();
    this.loadPoItems(filters);
  }

  exportPoItems(format: string) {
    this.importExportService
      .exportPoItemsToExcel(this.lotEditService.selectedPoItems, format, this.mainChecker, this.lotEditService.poFilters)
      .subscribe((resp) => {
        console.log(resp);
      });
  }

  resetCheckers() {
    this.lotEditService.selectedPoItems = [];
    this.mainChecker = false;
    this.bulkActionsActive = false;
  }

  resetPagination() {
    this.lotEditService.poFilters.sortBy = undefined;
    this.lotEditService.poFilters.sortDirection = undefined;
    this.lotEditService.poFilters.pageNumber = 0;
    this.lotEditService.poFilters.pageSize = 10;
  }
}
