import { Component, OnInit, ViewChild } from '@angular/core';
import { LotConfigurationsService } from '../lot-configurations.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { LotConfiguration } from '../../../../models/lot-configuration-model';

@Component({
  selector: 'app-lot-configurations-list',
  templateUrl: './lot-configurations-list.component.html',
  styleUrls: ['./lot-configurations-list.component.css'],
})
export class LotConfigurationsListComponent implements OnInit {
  dataSource: MatTableDataSource<LotConfiguration>;

  displayedColumns = ['name', 'lotName', 'cronExpression', 'vendors', 'generateOrderedIterations', 'decreaseMargin', 'disabled', 'edit'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private lotConfigurationsService: LotConfigurationsService) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource([]);

    this.lotConfigurationsService.getLotConfigurations().subscribe((lotConfigurations) => {
      this.dataSource.data = lotConfigurations;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
}
