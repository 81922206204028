import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SpoFilters } from '../../../../models/filters/spo-filters-model';
import { Spo } from '../../../../models/spo-model';
import { addCheckers } from '../../../../shared/checkers.service';
import { ImportExportService } from '../../../../shared/import-export.service';
import { LotsService } from '../../../../shared/lots.service';
import { SposService } from '../../../../shared/spos.service';
import { GenericConfirmationModalComponent } from '../../../modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { LotEditService } from '../../lot-edit.service';
import { Lot } from '../../../../models/lot-model';

@Component({
  selector: 'app-spo',
  templateUrl: './spo.component.html',
  styleUrls: ['./spo.component.css'],
})
export class SpoComponent implements OnInit {
  dataSource: MatTableDataSource<Spo>;

  displayedColumns = [
    'check',
    'spoCode',
    'orderId',
    'vendor',
    'destination',
    'supplier',
    'extra',
    'crossUkBorder',
    'earliestDeliveryDate',
    'latestDeliveryDate',
    'latestPickupDate',
    'requestedQuantity',
    'confirmedQuantity',
    'orderedQuantity',
    'requestedValue',
    'requestedPurchasePrice',
    'virtualRequestedPurchasePrice',
    'confirmedValue',
    'confirmedPurchasePrice',
    'virtualConfirmedPurchasePrice',
    'orderedValue',
    'orderedPurchasePrice',
    'virtualOrderedPurchasePrice',
    'margin',
    'virtualConfirmedMargin',
    'virtualConfirmedPercentMargin',
    'status',
  ];

  mainChecker = false;

  bulkActionsActive = false;

  dataSpo: any[] = [];

  currentLot: Lot;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  resultsNumber: Observable<number>;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
    private sposService: SposService,
    private lotsService: LotsService,
    private lotEditService: LotEditService,
    private importExportService: ImportExportService
  ) {}

  ngOnInit() {
    this.resultsNumber = this.sposService.resultsNumber;
    this.lotsService.getLot().subscribe((r) => (this.currentLot = r));

    if (this.lotEditService.spoFilters === undefined) {
      this.lotEditService.spoFilters = new SpoFilters();
    }

    this.resetCheckers();
    this.dataSource = new MatTableDataSource<Spo>([]);
    this.loadSpos(this.lotEditService.spoFilters);
  }

  loadSpos(filters: SpoFilters) {
    this.sposService.getFilteredSpos(this.lotsService.currentLotId, filters).subscribe((spos: Spo[]) => {
      this.dataSpo = addCheckers(spos, this.lotEditService.selectedSpos, this.mainChecker);
      this.dataSource.data = this.dataSpo;
    });
  }

  loadPage() {
    this.lotEditService.spoFilters.pageNumber = this.paginator.pageIndex;
    this.lotEditService.spoFilters.pageSize = this.paginator.pageSize;
    this.loadSpos(this.lotEditService.spoFilters);
  }

  onSort() {
    this.lotEditService.spoFilters.sortBy = this.sort.active;
    this.lotEditService.spoFilters.sortDirection = this.sort.direction;
    this.loadSpos(this.lotEditService.spoFilters);
  }

  onSelected(value: boolean, selectedSpo: any) {
    const selectedIndex = this.dataSpo.indexOf(selectedSpo);
    this.dataSpo[selectedIndex].checked = value;
    if (value !== this.mainChecker) {
      this.lotEditService.addSpoIdToSelected(this.dataSpo[selectedIndex].id);
    } else {
      this.lotEditService.removeSpoIdFromSelected(this.dataSpo[selectedIndex].id);
    }
    this.bulkActionsActive = value || this.mainChecker;
  }

  toggleAll(value: boolean) {
    this.mainChecker = value;
    this.lotEditService.sposMainChecker = this.mainChecker;
    for (const i of this.dataSpo) {
      i.checked = value;
    }

    this.lotEditService.selectedSpos = [];
    this.bulkActionsActive = value;
  }

  onFilter(filters: SpoFilters) {
    this.paginator.pageIndex = 0;
    this.resetCheckers();
    this.loadSpos(filters);
  }

  resetCheckers() {
    this.lotEditService.selectedSpos = [];
    this.mainChecker = false;
    this.bulkActionsActive = false;
  }

  delete() {
    this.sposService
      .deleteSpos(this.lotEditService.selectedSpos, this.lotEditService.spoFilters, this.mainChecker, this.lotsService.currentLotId)
      .subscribe((resp: boolean) => {
        if (resp) {
          this.loadSpos(this.lotEditService.spoFilters);
        } else {
          return;
        }
      });
  }

  merge() {
    this.sposService
      .mergeSpos(this.lotEditService.selectedSpos, this.lotEditService.spoFilters, this.mainChecker, this.lotsService.currentLotId)
      .subscribe(() => this.loadSpos(this.lotEditService.spoFilters));
  }

  export(format: string) {
    this.importExportService
      .exportSpos(this.lotEditService.selectedSpos, format, this.lotEditService.spoFilters, this.mainChecker, this.lotsService.currentLotId)
      .subscribe(() => {});
  }

  setPayment(value: boolean) {
    return this.sposService
      .setSposPayment(
        this.lotEditService.selectedSpos,
        this.lotEditService.spoFilters,
        this.mainChecker,
        this.lotsService.currentLotId,
        value
      )
      .subscribe(() => {
        this.onFilter(this.lotEditService.spoFilters);
      });
  }

  openDetails(spoId) {
    this.router.navigate(['lot-edit', this.lotsService.currentLotId, 'spo', spoId]);
  }

  generateShipment() {
    this.sposService
      .generateShipmentsFromSpos(
        this.lotEditService.selectedSpos,
        this.lotEditService.spoFilters,
        this.mainChecker,
        this.lotsService.currentLotId
      )
      .subscribe(() => {
        this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
        return 'Done';
      });
  }

  changeStatus(code: number) {
    this.sposService
      .changeSpoStatus(code, this.lotEditService.selectedSpos, this.lotEditService.spoFilters, this.mainChecker)
      .subscribe((changed: boolean) => {
        if (changed) {
          this.resetCheckers();
          this.loadSpos(this.lotEditService.spoFilters);
        }
      });
  }

  sendSpos() {
    this.matDialog
      .open(GenericConfirmationModalComponent, {
        data:
          'You are about to send ' +
          this.paginator.length +
          ' SPOs to their Suppliers. Note that only configured Suppliers will receive the orders.',
      })
      .afterClosed()
      .subscribe((resp: boolean) => {
        if (resp) {
          this.sposService
            .sendSpos(this.lotEditService.selectedSpos, this.lotEditService.spoFilters, this.mainChecker, this.lotsService.currentLotId)
            .subscribe(() => {
              this.snackBar.open('Request sent.' + status, 'CLOSE')._dismissAfter(2000);
            });
        }
      });
  }
}
