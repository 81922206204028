import { Component, Input, OnInit, Output } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { AuthorizationService } from '../../../../../core/authorization.service';
import { Permissions } from '../../../../../models/permission-model';

@Component({
  selector: 'app-po-details-bulk-actions',
  templateUrl: './po-details-bulk-actions.component.html',
  styleUrls: ['./po-details-bulk-actions.component.css'],
})
export class PoDetailsBulkActionsComponent implements OnInit {
  @Input() bulkActionsActive: boolean;

  @Output() onPoItemsExport = new Subject<string>();

  hasLotManagement: Observable<boolean>;

  constructor(private authorizationService: AuthorizationService) {}

  ngOnInit() {
    this.hasLotManagement = this.authorizationService.hasPermission(Permissions.LotManagement);
  }

  exportPoItems(format: string) {
    this.onPoItemsExport.next(format);
  }
}
