import { Component, Input, OnInit } from '@angular/core';
import { MarginConstraint } from '@models/publication-rule';

@Component({
  selector: 'app-margin-constraints-edit',
  templateUrl: './margin-constraints-edit.component.html',
  styleUrls: ['./margin-constraints-edit.component.css'],
})
export class MarginConstraintsEditComponent {
  @Input() constraints: MarginConstraint[];

  panelOpenState = false;

  addConstraint() {
    const c = new MarginConstraint();
    c.margin = 10;
    c.predicates = [];
    c.marginEnabled = true;
    this.constraints.push(c);
  }

  deleteConstraint(constraint: MarginConstraint) {
    const index = this.constraints.findIndex((it) => it === constraint);
    this.constraints.splice(index, 1);
  }
}
